import Image010 from "./Images/docImage10.png";
import { useNavigate } from "react-router-dom";
import { Button } from "@ssce/ui-shared-library";
import { Nav, Image, Flex } from "./Styles/ReturnNavigation.styles";

export const ReturnNavigation = () => {
  const navigate = useNavigate();

  return (
    <Nav>
      <Flex>
        <Image src={Image010} alt="s" /> <h3>Data Mesh</h3>
      </Flex>
      <Flex>
        <Button
          id={"goBackToMarketplace"}
          variant="secondary"
          size="medium"
          onClick={() => {
            navigate(-1);
          }}
        >
          Go Back to Marketplace
        </Button>
      </Flex>
    </Nav>
  );
};
