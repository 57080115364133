import { Projects } from "src/common/dataTypes";
import {
  ActiveStepDiv,
  ActiveStepTwoDiv,
  ButtonsDiv,
  ButtonsInnerDiv,
  Description,
  Form,
  InputPublishLabel,
  MenuItems,
  PublishManagementContainer,
  Refresh,
  SelectContainer,
  SelectDescription,
  SelectLogo,
  SelectTitle,
  StyledFormHelperText,
  StyledMenuItem,
  StyledRefreshIcon,
  Title,
} from "../publishApi.styles";

export const UserAccessManagement = ({
  projectID,
  projects,
  projectError,
  setProjectError,
  onFormChange,
  fetchProjectDetails,
  addProjectDetails,
}) => {
  return (
    <PublishManagementContainer>
      <SelectContainer>
        <ActiveStepTwoDiv>
          <Title>User Access Management</Title>
          <Description>
            Access to a resource or service is granted to users by including
            them in projects, which are subsequently assigned to the respective
            resources.
          </Description>
        </ActiveStepTwoDiv>
      </SelectContainer>
      <ActiveStepDiv>
        <ActiveStepDiv>
          <SelectContainer>
            <ActiveStepTwoDiv>
              <SelectTitle>Choose Projects</SelectTitle>
              <SelectDescription>
                Please use the drop-down to the right to select a project. Only
                one project can be selected.
              </SelectDescription>
            </ActiveStepTwoDiv>
            <MenuItems>
              <Form error={projectError}>
                <InputPublishLabel>Choose Project</InputPublishLabel>
                <SelectLogo
                  labelId="publish-api-project"
                  id="publish-api-project"
                  value={projectID}
                  fullWidth
                  label="Choose Project"
                  onChange={(e) => {
                    onFormChange("projectID", e.target.value);
                    addProjectDetails(e.target.value);
                    setProjectError(false);
                  }}
                >
                  {projects.map((item: Projects) => (
                    <StyledMenuItem
                      key={item.projectId}
                      value={item.projectId}
                      id={item.name}
                    >
                      {item.name}{" "}
                    </StyledMenuItem>
                  ))}
                </SelectLogo>
                <StyledFormHelperText>
                  Don’t see the project you want here? Click here to view and
                  manage all of your projects.
                </StyledFormHelperText>
              </Form>
            </MenuItems>
          </SelectContainer>
          <ButtonsDiv>
            <ButtonsInnerDiv>
              <Refresh
                id={"refresh"}
                onClick={() => fetchProjectDetails()}
                endIcon={<StyledRefreshIcon />}
              >
                Refresh
              </Refresh>
            </ButtonsInnerDiv>
          </ButtonsDiv>
        </ActiveStepDiv>
      </ActiveStepDiv>
    </PublishManagementContainer>
  );
};
