import { IconCheckbox, Button, FABButton } from "@ssce/ui-shared-library";
import {
  CardContainer,
  CardWrapper,
  TextWrapper,
  ImageContainer,
  BackgroundImage,
  Image,
  Title,
  DescriptionText,
  SubTitle,
  CardChipRow,
  Chip,
  RequiredTitle,
  RequiredServicesSection,
  ServiceItemWrapper,
  ServiceItemImage,
  ActionContainer,
  chipStyles,
} from "./WorkflowCatalog.styles";

type ServiceInfo = {
  serviceImage?: string;
  serviceText?: string;
  link?: string;
};
type DocumentationCardProps = {
  service?: string;
  title: string;
  tags?: string[];
  description?: string;
  subTitle?: string;
  backgroundImage?: string;
  onClick?: () => void;
  image?: string;
  services?: ServiceInfo[];
  isAlgoRiskReport?: boolean;
  inCarousel?: boolean;
  onCreateClick: () => void;
};

export default function WorkflowCatalogCard({
  image,
  service,
  title,
  tags = [],
  description,
  subTitle,
  backgroundImage = "",
  services,
  onCreateClick,
  inCarousel = false,
}: DocumentationCardProps) {
  return (
    <CardWrapper inCarousel={inCarousel}>
      {inCarousel && <Title inCarousel={true}>{title}</Title>}
      <CardContainer backgroundImage={backgroundImage}>
        {backgroundImage ? (
          <BackgroundImage backgroundImage={backgroundImage} />
        ) : (
          image && (
            <ImageContainer>
              <Image src={image} alt={service} />{" "}
            </ImageContainer>
          )
        )}

        <TextWrapper>
          {!inCarousel && <Title>{title}</Title>}
          {subTitle && <SubTitle> {subTitle}</SubTitle>}
          <CardChipRow inCarousel={inCarousel}>
            {tags.map((tag, index) => {
              const { backgroundColor, textColor } =
                chipStyles[index % chipStyles.length] as {
                  backgroundColor: string;
                  textColor: string;
                };
              return (
                <Chip
                  key={index}
                  backgroundColor={backgroundColor}
                  textColor={textColor}
                >
                  {tag}
                </Chip>
              );
            })}
          </CardChipRow>

          {description && (
            <DescriptionText inCarousel={inCarousel}>
              {description}
            </DescriptionText>
          )}
          <RequiredTitle inCarousel={inCarousel}>
            Required Services
          </RequiredTitle>
          <RequiredServicesSection>
            {services?.map((service, index) => (
              <ServiceItemWrapper key={index}>
                {service.serviceImage && (
                  <ServiceItemImage
                    src={service.serviceImage}
                    alt={service.serviceText}
                  />
                )}
                {service.serviceText && (
                  <Button
                    id={service.serviceText}
                    variant="text"
                    onClick={() => {
                      localStorage.setItem("activeMenuItem", "Catalog");
                      window.location.href = service.link || "/";
                    }}
                    size="medium"
                  >
                    {service.serviceText}
                  </Button>
                )}
              </ServiceItemWrapper>
            ))}
          </RequiredServicesSection>
          <ActionContainer>
            <FABButton onClick={onCreateClick} id={"createWorkflow"}>
              Create Workflow
            </FABButton>
            {!inCarousel && (
              <IconCheckbox onClick={() => {}} icon={"favorite"} />
            )}
          </ActionContainer>
        </TextWrapper>
      </CardContainer>
    </CardWrapper>
  );
}
