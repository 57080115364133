import { SnackbarProvider } from "notistack";
import { Provider as ReduxProvider } from "react-redux";
import AuthComponent from "./components/auth/auth";
import configureStore from "./redux/store";
import "./theme.css";

declare global {
  interface Window {
    _env_: {
      REACT_APP_MIDDLEWARE_URL: string;
    };
  }
}

function App() {
  const store = configureStore();
  return (
    <ReduxProvider {...{ store }}>
        <SnackbarProvider dense preventDuplicate>
          <AuthComponent />
        </SnackbarProvider>
    </ReduxProvider>
  );
}

export default App;
