import { useState } from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Users from "../../../../assets/Users.png";
import CheckCircle from "../../../../assets/CheckCircle.png";
import CatalogAlgoRiskOverview from "./CatalogAlgoRiskOverView";
import { Grid, Button } from "@ssce/ui-shared-library";
import DocumentationCard from "../../Documentation/DocumentationCard";
import { useNavigate } from "react-router-dom";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";
import AlgoRiskImage from "../../../../assets/AlgoRisk.png";

const CatalogAlgoRiskReport = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState("1");

  const handleChange = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={AlgoRiskImage}
        title={"SS&C Risk Service"}
        contents={
          "This cost-effective risk service leverages your existing holdings data housed within the SS&C platform. It delivers sophisticated financial risk analytics through reports or an intuitive web-based dashboard. ​"
        }
        tags={[
          {
            label: "Risk Management",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Analytics Service",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="default"
          id={"createInstance"}
          onClick={() =>
            navigate("/public-catalog/ss&c-algorithmics-risk-reports/provision")
          }
        >
          Create Instance
        </Button>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChange}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Technical Specifications" value="3" />
            <Tab label="Documentation" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "2.5rem", padding: 0 }}>
          <CatalogAlgoRiskOverview />
        </TabPanel>

        <TabPanel value="2" sx={{ marginTop: "2.5rem", padding: 0 }}>
          <div
            style={{ display: "flex", gap: "4.5rem", flexDirection: "column" }}
          >
            <div
              style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
            >
              <Typography
                variant="h4"
                fontSize="2.125rem"
                fontWeight={600}
                component="div"
                style={{
                  color: "#0A85C7",
                  lineHeight: "2.25rem",
                  padding: "0.625rem 0",
                }}
              >
                Pricing Plans and Features
              </Typography>

              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "1.813rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1.5rem",
                      alignItems: "flex-start",
                      width: "40%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontSize="1.25rem"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Risk Analytics include
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        <ul>
                          <li>Exposure, Long Exposure, Short Exposure</li>
                          <li>
                            Value-at-Risk (VaR) – in monetary units and as a
                            percent of portfolio value
                          </li>
                          <li>
                            Expected Shortall (also know as Conditional Tail
                            Expectation)
                          </li>
                          <li>Decomposed VaR</li>
                          <li>Scenario P&L</li>
                          <li>Delta, Gamma, Vega, Theta</li>
                          <li>PV01, CR01</li>
                          <li>
                            Duration, dollar duration, adjusted duration,
                            macaulay duration, OAS duration, key rate duration
                          </li>
                        </ul>
                      </Typography>
                    </div>
                  </div>
                  <div style={{ width: "20%" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontSize="1.25rem"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Aggregations include
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        {" "}
                        <ul>
                          <li>Country</li>
                          <li>Currency</li>
                          <li>Asset Type</li>
                          <li>Region</li>
                          <li>Scenario P&L</li>
                          <li>Credit Rating</li>
                        </ul>
                      </Typography>
                    </div>
                  </div>
                  <div style={{}}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontSize="1.25rem"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Stress Test Scenario Sets
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        <ul>
                          <li>Equity Scenarios</li>
                          <li>Interest Rate Scenarios</li>
                          <li>Credit Spread Scenarios</li>
                          <li>Foreign Exchange Scenarios</li>
                          <li>Commodity Scenarios</li>
                          <li>Volatility Scenarios</li>
                        </ul>
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                >
                  Plan Options & Details
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "3rem",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1.813rem",
                    }}
                  >
                    <Card
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginBottom: "1.25rem",
                        gap: "2.625rem",
                        padding: "0.625rem 0",
                        flex: "100%",
                        flexWrap: "wrap",
                        boxShadow: "none",
                        position: "relative",
                      }}
                    >
                      <Card
                        sx={{
                          ":hover": { bgcolor: "#C0EBF8" },
                          width: "18.438rem",
                          padding: "1.5rem",
                          boxShadow:
                            "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                          border: "0.063rem solid #CBD9E1",
                          borderRadius: "0.25rem",
                          paddingBottom: "0.625rem",
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 1 auto",
                          alignSelf: "self-end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            width: "80%",
                            marginTop: "1.25rem",
                          }}
                        >
                          <img
                            src={Users}
                            alt="SS&C logo"
                            style={{
                              width: "1.813rem",
                              height: "1.25rem",
                              marginRight: "0.625rem",
                              marginLeft: "1.875rem",
                              marginTop: "0.5rem",
                            }}
                          ></img>
                          <div>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                              align="left"
                              fontWeight={500}
                              lineHeight={1}
                              style={{
                                fontSize: "2.125rem",
                                display: "inline-block",
                              }}
                            >
                              Standard
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="div"
                          align="left"
                          fontWeight={500}
                          lineHeight={1.5}
                          style={{
                            marginTop: "0",
                            fontSize: "1rem",
                            marginLeft: "1.875rem",
                          }}
                        >
                          Perfect for getting started
                        </Typography>
                        <List>
                          <ListItem>
                            <img
                              src={CheckCircle}
                              alt="CSV Risk Report."
                              style={{ marginRight: "0.625rem" }}
                            ></img>
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              CSV Risk Reports
                            </Typography>
                          </ListItem>
                        </List>
                      </Card>

                      <Card
                        sx={{
                          ":hover": {
                            bgcolor: "#C0EBF8",
                          },
                          width: "18.438rem",
                          padding: "1.5rem",
                          boxShadow:
                            "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                          border: "0.063rem solid #CBD9E1",
                          borderRadius: "0.25rem",
                          paddingBottom: "0.625rem",
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 1 auto",
                          alignSelf: "self-end",
                        }}
                      >
                        <div>
                          <Chip
                            label="Popular"
                            sx={{
                              "&": {
                                position: "absolute",
                                top: 25,
                                marginLeft: "10rem",
                                padding: "0.25rem 0.625rem",
                                fontFamily: "Roboto Medium",
                                color: "#F6FDFF",
                                fontSize: "0.75rem",
                                backgroundColor: "#3CC13B",
                              },
                            }}
                            color="success"
                          />
                        </div>
                        <div style={{ display: "flex", marginTop: "1.25rem" }}>
                          <img
                            src={Users}
                            alt="SS&C logo"
                            style={{
                              width: "1.813rem",
                              height: "1.25rem",
                              marginRight: "0.625rem",
                              marginLeft: "0.625rem",
                              marginTop: "0.5rem",
                            }}
                          ></img>
                          <div>
                            <Typography
                              gutterBottom
                              fontWeight={600}
                              lineHeight={1}
                              style={{
                                fontSize: "2.125rem",
                                display: "inline-block",
                              }}
                            >
                              Premium
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="div"
                          align="left"
                          fontWeight={500}
                          lineHeight={1.5}
                          style={{
                            marginTop: "0",
                            fontSize: "1rem",
                            marginLeft: "1.875rem",
                          }}
                        >
                          Perfect for getting started
                        </Typography>
                        <List>
                          <ListItem>
                            <img
                              src={CheckCircle}
                              alt="CSV Risk Report."
                              style={{ marginRight: "0.625rem" }}
                            ></img>
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              CSV Risk Reports
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <img
                              src={CheckCircle}
                              alt="Risk Dashboard Access"
                              style={{ marginRight: "0.625rem" }}
                            ></img>
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              Risk Dashboard Access
                            </Typography>
                          </ListItem>
                        </List>
                      </Card>
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="3" sx={{ marginTop: "2.5rem", padding: 0 }}>
          <div
            style={{ display: "flex", gap: "4.5rem", flexDirection: "column" }}
          >
            <div
              style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
            >
              <Typography
                variant="h4"
                fontSize="2.125rem"
                fontWeight={600}
                component="div"
                style={{
                  color: "#0A85C7",
                  lineHeight: "2.25rem",
                  padding: "0.625rem 0",
                }}
              >
                Technical Specifications
              </Typography>
              <div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem 0",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontSize="1.25rem"
                    style={{ color: "#02273C", lineHeight: "1.5rem" }}
                  >
                    Storage capacity
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ color: "#000", lineHeight: "1.5rem" }}
                  >
                    Specify the amount of storage space provided by the product,
                    whether it's in gigabytes (GB), terabytes (TB), or any other
                    applicable unit of measurement. If there are different
                    storage tiers or options available, outline them and their
                    corresponding capacities.
                  </Typography>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem 0",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontSize="1.25rem"
                    style={{ color: "#02273C", lineHeight: "1.5rem" }}
                  >
                    Processing power
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ color: "#000", lineHeight: "1.5rem" }}
                  >
                    If the product involves computational capabilities, such as
                    virtual machines or server instances, describe the
                    processing power offered. This can be mentioned in terms of
                    CPU cores, clock speed, or any other relevant metric.
                  </Typography>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem 0",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontSize="1.25rem"
                    style={{ color: "#02273C", lineHeight: "1.5rem" }}
                  >
                    Bandwidth or data transfer limits
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ color: "#000", lineHeight: "1.5rem" }}
                  >
                    If the product involves data transfer or network usage,
                    provide information about the allocated bandwidth or any
                    limitations on data transfer. Mention if there are any
                    tiered plans or additional costs for exceeding the allocated
                    limits.
                  </Typography>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem 0",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontSize="1.25rem"
                    style={{ color: "#02273C", lineHeight: "1.5rem" }}
                  >
                    Scalability
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ color: "#000", lineHeight: "1.5rem" }}
                  >
                    If the product offers scalability options, explain how users
                    can scale up or down their capacity based on their needs.
                    This could involve expanding storage, adding more processing
                    power, or adjusting bandwidth allowances.
                  </Typography>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem 0",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontSize="1.25rem"
                    style={{ color: "#02273C", lineHeight: "1.5rem" }}
                  >
                    Performance guarantees
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ color: "#000", lineHeight: "1.5rem" }}
                  >
                    If there are any performance guarantees associated with the
                    product, such as minimum response times or uptime SLAs
                    (Service Level Agreements), mention them in this section.
                  </Typography>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem 0",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontSize="1.25rem"
                    style={{ color: "#02273C", lineHeight: "1.5rem" }}
                  >
                    Data redundancy and backup
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ color: "#000", lineHeight: "1.5rem" }}
                  >
                    Highlight any features or mechanisms in place to ensure data
                    redundancy, backup, or disaster recovery. This can include
                    details about automatic backups, replication, and data
                    integrity measures.
                  </Typography>
                </div>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem 0",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontSize="1.25rem"
                    style={{ color: "#02273C", lineHeight: "1.5rem" }}
                  >
                    Limitations or restrictions
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ color: "#000", lineHeight: "1.5rem" }}
                  >
                    If there are any specific limitations or restrictions
                    regarding capacity, such as maximum file sizes, maximum
                    number of concurrent users, or any other relevant
                    constraints, clearly outline them.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value="4" sx={{ padding: "2.5rem 0 0 0", display: "flex" }}>
          <div style={{ position: "relative", height: "31.25rem" }}>
            <Typography
              variant="h3"
              color="primary"
              marginBottom={"1.25rem"}
              style={{ fontSize: "2.125rem", fontWeight: 600 }}
            >
              Documentation
            </Typography>
            <Grid>
              <DocumentationCard
                image={require("../../../../assets/DatameshCatalog/AlgoLogo.png")}
                service={"Getting Started"}
                title={"Data Mesh - An Introduction"}
                date={"10/01/2024"}
              />
            </Grid>
          </div>
        </TabPanel>
      </TabContext>
    </>
  );
};
export default CatalogAlgoRiskReport;
