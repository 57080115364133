import * as React from "react";
import { Button } from "@ssce/ui-shared-library";
import DownloadIcon from "@mui/icons-material/Download";
import Grid from "src/components/main/subcomponents/Grid/Grid";
import {
  Title,
  SubTitle,
  CardTop,
  CardContainer,
  CardTitle,
  CardContents,
  CurrencyContainer,
  Card,
  CardBottom,
  CardBottomLabel,
  CardBottomValue,
  NextPaymentDue,
  PaymentDueDate,
} from "./dummyPlanDetails.styles";

export const DummyPlanDetails: React.FC<{
  isRiskThinking?: boolean;
  nextPaymentDueDate?: string;
}> = ({ isRiskThinking, nextPaymentDueDate }) => {
  return (
    <>
      <Title>Overview</Title>
      <SubTitle>Plan Details</SubTitle>
      <Grid>
        <Card>
          <CardTop>
            <CardContainer>
              <CardTitle>My Plan</CardTitle>
              <Button
                onClick={() => {}}
                variant="default"
                isDisabled
                size="small"
                id="premium"
              >
                {isRiskThinking ? "Small" : "Premium"}
              </Button>
              <CardContents>
                You can manage your plan by changing it or adjusting your funds.
              </CardContents>
            </CardContainer>
            {!isRiskThinking && <CurrencyContainer></CurrencyContainer>}
          </CardTop>
          <CardBottom>
            {!isRiskThinking && (
              <CardBottomLabel>Total funds in Plan &nbsp;</CardBottomLabel>
            )}
            {!isRiskThinking && <CardBottomValue>4</CardBottomValue>}
            <Button variant="default" id="managePlan" onClick={() => {}}>
              Manage Plan
            </Button>
          </CardBottom>
        </Card>
        <Card>
          <CardTop>
            <CardContainer>
              <NextPaymentDue>Next Payment Due</NextPaymentDue>
              <PaymentDueDate>
                {nextPaymentDueDate ? nextPaymentDueDate : "Dec 31, 2024"}
              </PaymentDueDate>
            </CardContainer>
          </CardTop>
          <CardBottom>
            <Button
              variant={"text"}
              size={"medium"}
              id="downloadLastInvoice"
              onClick={() => {}}
            >
              Download Last Invoice
              <DownloadIcon />
            </Button>
            <Button
              variant="secondary"
              size={"medium"}
              onClick={() => {}}
              id="viewBilling"
            >
              View Billing
            </Button>
          </CardBottom>
        </Card>
      </Grid>
    </>
  );
};
