import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { Button } from "@ssce/ui-shared-library";
import ConstructionPage from "./ConstructionPage.png";

//ripped from ErrorPage. TODO: Create and import typography from shared library

type ComingSoonPageProps = {
    setActiveMenuItem: (value: string) => void;
    };

export const ComingSoonPage = ({setActiveMenuItem}: ComingSoonPageProps) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          marginLeft: "0%",
          textAlign: "center",
          marginTop: "2%",
          display: "flex",
          flex: "100%",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "40%", display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h1"
            color="#0A85C7"
            marginBottom={"1rem"}
            alignSelf="center"
            textAlign="center"
            style={{
              fontSize: "2.125rem",
              marginTop: "3.125rem",
              fontWeight: 700,
            }}
          >
            This page is coming soon!
          </Typography>
          <Typography
            variant="h1"
            color="#355263"
            fontWeight={500}
            paddingBottom={2}
            fontFamily="Roboto Medium"
            fontSize={20}
            textAlign="center"
            alignSelf="center"
          >
            We’re sorry but the page you requested is currently unavailable.
            Let’s help get you turned around!
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "100%",
              paddingTop: "8%",
              gap: "5%",
            }}
          >
            <Button
              variant="secondary"
              onClick={() => {
                navigate(-1);
              }}
              id="goBack"
            >
              Go Back To The Previous Page
            </Button>
            <Button
              variant="default"
              onClick={() => {
                setActiveMenuItem("Dashboard");
                navigate("/dashboard");
              }}
              id="toDashboard"
            >
              Take Me To The Dashboard
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "3.75rem" }}>
          <img
            src={ConstructionPage}
            alt="Error401"
            width={500}
            height={500}
          ></img>
        </div>
      </div>
    </>
  );
};
