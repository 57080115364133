import * as React from "react";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link } from "@mui/material";
import { baseUrl } from "../../../constants";
import Grid from "src/components/main/subcomponents/Grid/Grid";
import DocumentationCard from "./DocumentationCard";
import { NumberOfFilters } from "./Styles/Documentation.styles";

const CodeSamples: React.FC = () => {
  const [items, setItems] = useState<any>([]);
  const [searching, setSearching] = useState<any>("");
  const [scrollTop, setScrollTop] = useState<any>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = React.useState([""]);

  useEffect(() => {
    fetch(`${baseUrl}/api/codesamples`)
      .then((response) => response.json())
      .then((data) => {
        setItems(data);
      });
  }, []);
  const optionsList = [
    {
      key: 1,
      name: "Function",
    },
    {
      key: 2,
      name: "Industry",
    },
    {
      key: 3,
      name: "Java",
    },
    {
      key: 4,
      name: "Python",
    },
    {
      key: 5,
      name: "Node JS",
    },
  ];

  const toggling = () => setIsOpen(!isOpen);
  const handleChangeForSearch = (e: { target: { value: any } }) => {
    setSearching(e.target.value);
  };
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 31,
    height: 18,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(0.563rem)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(0.75rem)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 0.125rem 0.25rem 0 rgb(0 35 11 / 20%)",
      width: 15.5,
      height: 15.5,
      borderRadius: 8,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClearAll = () => {
    setChecked([]);
  };
  //searching
  const filtered = items.filter((item: { title: string }) =>
    item.title.toLowerCase().includes(searching.toLowerCase()),
  );

  //Virtual Scrolling
  const rowHeight = 30;
  const limit = 30;
  const startNode = Math.ceil(scrollTop / rowHeight);
  const visibleData = filtered.slice(0, startNode + limit);
  return (
    <>
      <div>
        <Typography
          variant="h1"
          color="primary"
          marginBottom={"1.25rem"}
          style={{ fontSize: "2.125rem", marginLeft: "1.25rem" }}
        >
          Code Samples
        </Typography>
        <div
          style={{
            marginLeft: "1.25rem",
            marginTop: "1.25rem",
            display: "flex",
            flex: "100%",
            flexWrap: "wrap",
            gap: "100%",
          }}
        >
          <Typography style={{ fontSize: "1rem", marginBottom: "3.125rem" }}>
            Explore our code samples library to find code snippets or examples
            that demonstrate how to use specific programming languages,
            frameworks, libraries, or APIs to perform specific tasks or
            functions.
          </Typography>
        </div>
        <div
          style={{
            marginBottom: "1.25rem",
            display: "flex",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              sx={{
                m: 1,
                width: "12.75rem",
                marginLeft: "1.25rem",
                marginRight: "0.938rem",
                color: "#0A85C7",
                borderRadius: "0.25rem",
                position: "relative",
              }}
            >
              <Button variant="default" id={"FILTERS"} onClick={toggling}>
                <NumberOfFilters>{checked.length - 1}</NumberOfFilters>
                Filters
                <ExpandMoreRoundedIcon />
              </Button>
            </Stack>
            {isOpen && (
              <List
                sx={{
                  zIndex: "1",
                  width: "100%",
                  maxWidth: "12.813rem",
                  backgroundColor: "#fff",
                  top: "4.375rem",
                  marginleft: "1.25rem",
                  position: "absolute",
                }}
              >
                {optionsList.map((value: { key: number; name: any }) => {
                  const labelId = `checkbox-list-label-${value.name}`;

                  return (
                    <ListItem key={value.name} disablePadding>
                      <ListItemButton
                        role={undefined}
                        id={value.name}
                        onClick={handleToggle(value.name)}
                        dense
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(value.name) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={` ${value.name}`} />
                      </ListItemButton>
                    </ListItem>
                  );
                })}
              </List>
            )}
          </div>
          <Box
            component="form"
            sx={{
              m: 1,
              width: "100%",
              marginLeft: "0.625rem",
              marginRight: 5,
              color: "#0A85C7",
              borderRadius: "0.25rem",
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="searchChange"
              placeholder="Search"
              variant="outlined"
              onChange={handleChangeForSearch}
              sx={{
                width: "100%",
                borderRadius: "0.5rem",
                marginRight: "0.625rem",
                border: "0.063rem solid #B6C7D1",
              }}
              InputProps={{
                style: {
                  height: "3.063rem",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ color: "grey", fontSize: "1.438rem" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </div>
        <div style={{ marginLeft: "1.25rem" }}>
          {checked.map((item) => {
            if (item !== "") {
              return (
                <Stack
                  direction="row"
                  spacing={1}
                  style={{ display: "inline-block" }}
                >
                  <Chip
                    label={item}
                    style={{
                      backgroundColor: "#DFE9EF",
                      fontSize: "0.875rem",
                      color: "#355263",
                      marginRight: "0.313rem",
                    }}
                  />
                </Stack>
              );
            }
            return null;
          })}
        </div>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          style={{
            marginLeft: "51.25rem",
            marginBottom: "1.875rem",
          }}
        >
          <Typography style={{ color: "#0A85C7", fontSize: "0.75rem" }}>
            Must contain All Filters Selected
          </Typography>
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
          />
        </Stack>
        <Box
          sx={{
            typography: "body1",
            "& > :not(style) + :not(style)": {
              ml: 2,
            },
          }}
          style={{ marginLeft: "1.25rem" }}
          id={"clearAllFilters"}
          onClick={handleClearAll}
        >
          <Link href="#">Clear all filters &#62;&#62; </Link>
        </Box>
        <div
          style={{
            display: "flex",
            marginLeft: "0.625rem",
            marginBottom: "1.875rem",
            gap: "1.25rem",
          }}
        >
          <Typography
            style={{
              color: "#90A8B5",
              fontSize: "0.875rem",
              fontWeight: "500",
              marginLeft: "1.25rem",
              marginTop: "1.25rem",
              width: "25rem",
            }}
          >
            A total of <b>{visibleData.length}</b> result
          </Typography>
          <Typography
            variant="body1"
            color="primary"
            style={{
              fontSize: "0.938rem",
              marginLeft: "21.875rem",
              marginTop: "1.563rem",
              width: "3.125rem",
            }}
          >
            Sort by
          </Typography>
          <FormControl
            sx={{ m: 5, minWidth: 140 }}
            size="small"
            style={{
              marginLeft: "0.063rem",
              marginTop: "0.625rem",
              borderRadius: "0.25rem",
              border: "0.063rem solid #0A85C7",
            }}
          >
            <Select
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              style={{ color: "'#0A85C7'" }}
            >
              <MenuItem value={10} style={{ color: "'#0A85C7'" }}>
                Newest
              </MenuItem>
              <MenuItem value={20} style={{ color: "'#0A85C7'" }}>
                Oldest
              </MenuItem>
              <MenuItem value={30} style={{ color: "'#0A85C7'" }}>
                Alphabetical
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <Grid
          onScroll={(e) => {
            setScrollTop(e.currentTarget.scrollTop + rowHeight);
          }}
        >
          {visibleData.map((item) => {
            return (
              <DocumentationCard
                image={item.logo}
                service={item.service}
                title={item.title}
                date={item.date}
                tags={item.tags}
              />
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default CodeSamples;
