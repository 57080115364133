import PublishApiReview from "../PublishApiReview/publishApiReview";

export const Review = ({
  publishApi,
  publishError,
  imageList,
  currentSelection,
  serviceName,
}) => {
  return (
    <PublishApiReview
      {...publishApi}
      publishError={publishError}
      imageList={imageList}
      currentSelection={currentSelection}
      serviceName={serviceName}
    />
  );
};
