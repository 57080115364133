import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";
import Region from "../Regions";
import { useNavigate } from "react-router-dom";
import Carousel from "../../subcomponents/Carousel";
import CardView from "../CardView";
import NorthAmerica from "../../../../assets/NorthAmerica.png";
import CatalogBenefits from "../CatalogBenefits";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import AccessibilityOutlinedIcon from "@mui/icons-material/AccessibilityOutlined";
import CatalogKeyFeatures from "../CatalogKeyFeatures";
import SightlinePricing from "./SightlinePricing";
import CatalogSubHeading from "../CatalogSubHeading";
import Grid from "@mui/material/Grid";
import Sightline01 from "../../../../assets/Sightline/sightline01.png";
import Sightline02 from "../../../../assets/Sightline/sightline02.png";
import Sightline03 from "../../../../assets/Sightline/sightline03.png";
import Sightline04 from "../../../../assets/Sightline/sightline04.png";
import Sightline05 from "../../../../assets/Sightline/sightline05.png";

const CARD_DATA = [
  {
    id: 1,
    logo: "/api/assets/datamesh.png",
    serviceName: "SS&C Everywhere Data Mesh",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "The SS&C Everywhere Data Mesh platform provides an interface for users to centralize their data to make it more accessible to the rapidly changing needs of a user’s organization.",
    tags: {
      function: "Cloud Platform",
      industry: "Cross-Industry",
      serviceType: "Data Service",
    },
  },
  {
    id: 2,
    logo: "/api/assets/algoriskreport.png",
    serviceName: "SS&C Algorithmics Risk Reports",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "Generate world-class risk reports leveraging award and access powerful reporting dashboards preintegrated with your data.",
    tags: {
      function: "Risk Management",
      industry: "Cross-Industry",
      serviceType: "Analytics Service",
    },
  },
];

const SightlineOverView: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateTo = (name: string) => {
    if (name === "SS&C Everywhere Data Mesh") {
      return navigate("/public-catalog/data-mesh");
    }
    if (name === "SS&C Algorithmics Risk Reports") {
      return navigate("/public-catalog/ss&c-algorithmics-risk-reports");
    }
  };

  return (
    <>
      <div className="flex-col gap3rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Overview
          </Typography>
          <div>
            <Typography
              variant="h6"
              fontSize="1.25rem"
              sx={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              About This Service
            </Typography>
            <Typography
              variant="body1"
              component={"div"}
              sx={{ color: "#000", lineHeight: "1.5rem" }}
            >
              <div style={{ display: "flex", maxWidth: "90%" }}>
                <ul>
                  <li>
                    {" "}
                    Self-serve, scalable web-based BI platform that adapts to
                    change and streamlines data challenges.{" "}
                  </li>
                  <li>
                    {" "}
                    Effortlessly integrates and displays information from
                    various sources into a centralized model.{" "}
                  </li>
                  <li>
                    {" "}
                    No-code tools for business users to easily manipulate, join,
                    and analyze data.{" "}
                  </li>
                  <li>
                    {" "}
                    Real-time, secure, self-service access to granular data for
                    confident, data-driven decisions.{" "}
                  </li>
                  <li>
                    {" "}
                    Automated data flows for increased speed, accuracy, and
                    scalability.{" "}
                  </li>
                </ul>
              </div>
            </Typography>

            <Carousel
              imageList={[
                {
                  src: Sightline01,
                  alt: "Sightline01",
                },
                {
                  src: Sightline02,
                  alt: "Sightline02",
                },
                {
                  src: Sightline03,
                  alt: "Sightline03",
                },
                {
                  src: Sightline04,
                  alt: "Sightline04",
                },
                {
                  src: Sightline05,
                  alt: "Sightline05",
                },
              ]}
            />
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <GppGoodOutlinedIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Enhanced Decision-Making",
              description:
                "Sightline's real-time, secure, self-service access to granular data enables businesses to make confident, data-driven decisions quickly and efficiently.",
            },

            {
              icon: (
                <DataThresholdingOutlinedIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Simplified Data Integration",
              description:
                "The platform consolidates data from various sources, harmonizes, and validates it into a unified source of truth, eliminating the complexity of fragmented processes.",
            },
            {
              icon: (
                <AccessibilityOutlinedIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "User-Friendly Customization",
              description:
                "With no-code tools, business users can effortlessly manipulate, join, and analyze data to create custom views and reports, breaking free from rigid reporting systems.",
            },
            {
              icon: (
                <TrendingUpIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Scalable and Adaptable",
              description:
                "Sightline's flexible architecture can complement or replace existing systems, streamlining data flows for speed, accuracy, and scalability, while adapting to the organization's specific needs.",
            },
          ]}
        />
        <CatalogKeyFeatures
          childCount={2}
          listItems={[
            {
              heading: "Flexible No-code Tools",
              description:
                "Allows business users to easily manipulate, join, and analyze data to create custom views and reports without requiring technical expertise.",
            },
            {
              heading: "Real-time Access",
              description:
                "Provides instant, secure, self-service access to granular data within hours, enabling confident, data-driven decisions.",
            },
            {
              heading: "Automated Flows",
              description:
                "Streamlines data flows for increased speed, accuracy, and scalability in data processing and reporting.",
            },
            {
              heading: "Customizable Dashboards",
              description:
                "Tailor visuals for quick, insightful drill-downs, enhancing data analysis and decision-making.",
            },
          ]}
        />
        <SightlinePricing title={"Available Pricing Plans"} />
        <div className={"flex-col gap3rem"}>
          <CatalogSubHeading title={"Region Availability"} />
          <div className={"flex-col gap1rem"}>
            <CatalogSubHeading
              title={"Available Regions"}
              fontSize="1.25rem"
              color="#02273C"
            />
            <div>
              <Grid container spacing={0}>
                <Grid item xs={6} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} md={6}>
                      <li className="regionListli">{"North America"}</li>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <img
                    alt={"northAmerica"}
                    width={400}
                    height={200}
                    src={NorthAmerica}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="specificRegionDetils">
            <CatalogSubHeading
              title={"Specific Region Details"}
              fontSize="1.25rem"
              color="#02273C"
            />

            <div>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Region />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Region Details
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    North America: This region provides low-latency access to
                    customers located in the United States and Canada. It offers
                    high availability and scalability, making it an ideal choice
                    for enterprise customers with large-scale workloads.
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Local Compliance
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    Fully compliant with regulatory requirements, including:
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{
                      color: "#577687",
                      lineHeight: "1.5rem",
                      paddingLeft: 2,
                    }}
                  >
                    <ul>
                      <li>GDPR</li>
                      <li>ISO 27000</li>
                      <li>SOC-2</li>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Typography
          variant="h4"
          fontSize="2.125rem"
          fontWeight={600}
          component="div"
          sx={{ color: "#0A85C7", lineHeight: "1.875rem" }}
        >
          Related Products
        </Typography>
        <div style={{ maxWidth: "80%" }}>
          <CardView
            variant="simple"
            data={CARD_DATA}
            handleNavigateTo={handleNavigateTo}
          />
          <Button
            variant="text"
            id={"viewMore"}
            onClick={() => {
              navigate("/public-catalog");
            }}
          >
            View More
          </Button>
        </div>
      </div>
    </>
  );
};

export default SightlineOverView;
