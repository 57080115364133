import styled from "styled-components";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import KeyboardDoubleArrowLeftRoundedIcon from "@mui/icons-material/KeyboardDoubleArrowLeftRounded";
import { fadeIn, fadeInFromUp } from "src/globalAnimations.styles";
import { NavLink } from "react-router-dom";

export const StyledImage = styled.img<{ minimized: boolean }>`
  visibility: ${(props) => (props.minimized ? "hidden" : "visible")};
  width: 100%;
  min-height: 6.25rem;
`;

export const StyledNav = styled.nav<{ minimized: boolean }>`
  width: ${(props) => (props.minimized ? "3.125rem" : "max(14vw, 12.5rem)")};
  transition: width 0.2s;
  box-shadow: 0 -0.3125rem 0.625rem 0.0625rem rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 3rem;
  z-index: 2;
  background: var(--neutralWhite);
  @media screen and (max-width: 768px) {
    display: none;
  }
  overflow: auto;
  height: 95%;
`;

export const StyledNavWidth = styled.div<{ minimized: boolean }>`
  width: ${(props) => (props.minimized ? "3.125rem" : "max(22vw, 22.5rem)")};
  position: relative;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Collapse = styled.div<{ isActive: boolean }>`
  display: ${(props) => (props.isActive ? "flex" : "none")};
  flex-direction: column;
`;

export const StyledExpandSubItemsIcon = styled(ExpandLessRoundedIcon)<{
  isActive: boolean;
}>`
  color: ${(props) => (props.isActive ? "var(--primary)" : "black")};
  transform: ${(props) => (props.isActive ? "rotate(0deg)" : "rotate(180deg)")};
`;

export const StyledArrowForwardIosIcon = styled.div<{ isActive: boolean }>`
  height: 2.1875rem;
  width: 1.5625rem;
  border-bottom: 0.0625rem solid
    ${(props) => (props.isActive ? "var(--primary)" : "black")};
  border-left: 0.0625rem solid
    ${(props) => (props.isActive ? "var(--primary)" : "black")};
  position: relative;
  top: -0.625rem;
`;

export const StyledExpandIcon = styled(KeyboardDoubleArrowLeftRoundedIcon)<{
  minimized: boolean;
}>`
  color: var(--primary);
  transform: ${(props) =>
    props.minimized ? "rotate(180deg)" : "rotate(0deg)"};
  float: right;
  width: ${(props) => (props.minimized ? "100%" : "none")};
  cursor: pointer;
`;

export const SubItemWrapper = styled.div`
  padding-left: 3.125rem;
  display: flex;
  align-items: center;

  &:hover {
    background: #f0f0f0;
  }

  svg {
    vertical-align: bottom;
  }
`;

export const Title = styled.div`
  padding-left: 1.25rem;
`;

export const TitleWrapper = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  font-weight: ${(props) => (props.isActive ? "800" : "500")};
  color: ${(props) => (props.isActive ? "var(--primary)" : "black")};
`;

export const StyledNavLink = styled(NavLink)<{ isActive?: boolean }>`
  color: ${(props) => (props.isActive ? "var(--primary)" : "black")};
  text-decoration: none;
  align-items: center;

  display: flex;
  justify-content: space-around;
  font-size: 1rem;
  padding: 0 0.625rem;
  height: 3.125rem;

  svg {
    vertical-align: bottom;
  }

  &:hover {
    background: #f0f0f0;
  }
`;

export const StyledSubNavLink = styled(NavLink)<{ isActive?: boolean }>`
  width: 90%;
  color: ${(props) => (props.isActive ? "var(--primary)" : "black")};
  float: right;
  text-decoration: none;

  &:hover {
    background: #f0f0f0;
  }
`;

export const MobileWrapper = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--neutralWhite);

  overflow: scroll;
  padding: 0;
  font-weight: 800;

  li {
    width: 100%;
    color: var(--neutralBlack);
    text-decoration: none;
    padding-top: 1.25rem 0;
    border-top: 0.006rem dashed var(--neutralBlack);
  }

  a {
    width: 100%;
    color: var(--neutralBlack);
    text-decoration: none;
    padding-top: 1.25rem 0;
    line-height: 3;
  }
`;

export const MobileNavModal = styled.section`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 4rem;
  padding: 0 2rem;

  left: 0;
  animation: ${fadeIn} 0.2s ease-in-out;
  animation-fill-mode: forwards;

  @media screen and (min-width: 768px) {
    display: none;
  }
`;

export const MobileSlider = styled.li`
  display: flex;
  flex-direction: column;
  font-weight: 100;

  div {
    animation: ${fadeInFromUp} 0.2s ease-in-out;
    border-top: 0.006rem solid rgb(179, 179, 179);
    width: 100%;
    text-align: left;
    padding-left: 3.125rem;
  }
`;

export const SidebarIcon = styled.div`
  color: var(--primary);
  vertical-align: text-bottom;
  margin-left: 1.25rem;
  pointer-events: none;
`;

export const SliderLi = styled.li`
  padding: 10px 0;
`;
