import styled from "styled-components";
import moment from "moment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import OpenInNewIcon from "@mui/icons-material/OpenInNew";

type DocumentationCardProps = {
  service?: string;
  title: string;
  date?: string;
  tags?: string[];
  description?: string;
  link?: string;
  subTitle?: string;
  backgroundImage?: string;
  onClick?: () => void;
  image?: string;
  openInNew?: boolean;
};

const CardContainer = styled.div<{ backgroundImage: string }>`
  display: flex;
  gap: 1.25rem;
  height: ${(props) => props.backgroundImage && "100%"};
`;

const CardWrapper = styled.div`
  box-shadow: 0px 1px 5px 0px var(--neutral400);

  position: relative;
  height: 13rem;
  border-radius: 0.625rem;

  &:hover {
    box-shadow: 0px 1px 4px 0px var(--neutral800);
    background: var(--azure50);
    transition: all 0.5s;
    cursor: pointer;
    transform: scale(1.025);

    svg {
      color: var(--primary);
    }
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem 1.25rem 0 0;
`;

const Description = styled.div`
  font-size: 0.875rem;
  overflow: auto;
`;

const ImageContainer = styled.div`
  align-self: center;
  padding-left: 1.25rem;
`;

const BackgroundImage = styled.div<{ backgroundImage: string }>`
  height: 100%;
  width: 100%;
  max-width: 8.5rem;
  background-image: url(${(props) =>
    props.backgroundImage ? props.backgroundImage : ""});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Image = styled.img`
  width: 4.6875rem;
  height: 100%;
`;

const Title = styled.h6`
  font-size: 1.25rem;
  line-height: 1.25;
  margin: 0 1.25rem 0 0;
  padding: 0;
`;

const SubTitle = styled.div`
  padding: 0.5rem 0;
  font-weight: 100;
`;

const LightText = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  color: #6b6b6b;
`;

const Sup = styled.sup`
  font-family: "Roboto", sans-serif;
  font-size: 0.75rem;
  color: #6b6b6b;
`;

const CardChipRow = styled.div`
  display: flex;
  gap: 0.625rem;
  padding: 0.625rem 0;
  height: 100%;
  flex-wrap: wrap;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
`;

const Chip = styled.div`
  padding: 0.375rem 0.75rem;
  border-radius: 6249.938rem;
  font-size: 0.75em;
  background-color: #cbd9e1;
  color: #02273c;
`;

const StyledArrowForwardIcon = styled(ArrowForwardIcon)`
  color: var(--neutral600);
  position: absolute;
  top: 2rem;
  right: 0.75rem;
`;

const StyledOpenInNewIcon = styled(OpenInNewIcon)`
  color: var(--neutral600);
  position: absolute;
  top: 2rem;
  right: 0.75rem;
`;

export default function DocumentationCard({
  image,
  service,
  title,
  date,
  tags,
  description,
  subTitle,
  backgroundImage = "",
  onClick,
  openInNew,
}: DocumentationCardProps) {
  return (
    <CardWrapper onClick={onClick}>
      {openInNew ? <StyledOpenInNewIcon /> : <StyledArrowForwardIcon />}

      <CardContainer backgroundImage={backgroundImage}>
        {backgroundImage ? (
          <BackgroundImage backgroundImage={backgroundImage} />
        ) : (
          image && (
            <ImageContainer>
              <Image src={image} alt={service} />{" "}
            </ImageContainer>
          )
        )}

        <TextWrapper>
          <Sup>{service}</Sup>
          <Title>{title}</Title>
          {date && (
            <LightText>
              {" "}
              Last Update: {moment(date).format("MM/DD/YYYY")}
            </LightText>
          )}
          {subTitle && <SubTitle> {subTitle}</SubTitle>}
          {description && <Description> {description}</Description>}
        </TextWrapper>
      </CardContainer>

      <div>
        <CardChipRow>
          {tags &&
            tags.map((tag, index) => {
              if (tag) return <Chip key={index}>{tag}</Chip>;
              return null;
            })}
        </CardChipRow>
      </div>
    </CardWrapper>
  );
}
