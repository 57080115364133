import * as React from "react";
import { useState, useEffect } from "react";
import {
  useProvisionDataMeshHooks,
  fundList,
} from "../ProvisionService/provisionDataMeshHooks";
import { InstanceNameProvision } from "../ProvisionService/provisionDataMeshComponents";
import {
  SelectRegionProvision,
  UserManagementProvision,
  SolutionConfigurationProvision,
  ReviewBillingProvision,
  ProvisionInstanceButton,
} from "../ProvisionService/provisionGeneralComponents";
import { ProvisionSuccessfulPage } from "../ProvisionService/provisionSuccessfulPage";
import { ErrorNotification } from "@ssce/ui-shared-library";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";
import CatalogSubHeading from "../CatalogSubHeading";
import Divider from "@mui/material/Divider";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";
import TableauImage from "../../../../assets/tableau.png";

type TableFundList = {
  id: number;
}[];

const TableauProvision: React.FC = () => {
  const {
    createInstances,
    fetchCatalogID,
    setInstanceName,
    setProjectId,
    setIsLoading,
    hasError,
    catalogId,
    instanceName,
    projectId,
    projects,
    hasProjectIdError,
    hasInstanceNameError,
    isLoading,
  } = useProvisionDataMeshHooks();

  const [isProvisionSuccessful, setIsProvisionSuccessful] = useState(false);

  const [selectedRegion, setSelectedRegion] = React.useState("NorthAmerica");

  const [_, setAddFundList] = React.useState([]);
  const [tableFundList] = React.useState<TableFundList>([]);

  const handleProvisionInstance = async () => {
    const success = await createInstances({
      instanceName,
      projectId,
      catalogId,
      applicationName: "tableau",
      region: selectedRegion,
    });
    let successAsBool = success as unknown as boolean;
    setIsLoading(false);
    setIsProvisionSuccessful(!!successAsBool);
  };

  useEffect(() => {
    fetchCatalogID("tableau");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let updateData: any = [...fundList];
    tableFundList.forEach((item) => {
      updateData.forEach((a, index) => {
        if ((item.id, a.id === item.id)) updateData.splice(index, 1);
      });
    });

    setAddFundList(updateData);
  }, [tableFundList]);

  const onCreateAnotherInstance = () => {
    setIsProvisionSuccessful(false);
    setInstanceName("");
    setSelectedRegion("NorthAmerica");
    setProjectId("");
  };

  return (
    <>
      <div className="algo-performance-provision-container">
        {hasError && <ErrorNotification />}
        {!isProvisionSuccessful ? (
          !isLoading ? (
            <>
              <>
                <div className="catalogSuperset">
                  <CatalogSubHeading
                    title={"Provision Service"}
                    sx={{ padding: "0.625rem 0" }}
                  />
                  <div>
                    <Divider />
                  </div>
                </div>

                <MyServicesCardHeader
                  imageSource={TableauImage}
                  title={"Tableau"}
                  contents={
                    "Tableau is a leading data visualization tool that enables users to transform raw data into interactive and shareable dashboards. It connects seamlessly to various data sources, allowing for real-time data analysis and visualization. Users can create a wide range of visualizations, from simple charts to complex graphs, without extensive technical expertise. Tableau's intuitive drag-and-drop interface makes it accessible for both beginners and advanced users. Widely used in business intelligence, it facilitates data-driven decision-making by presenting data in a clear and visually appealing manner."
                  }
                  tags={[
                    {
                      label: "BI",
                      style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
                    },
                    {
                      label: "Cross-Industry",
                      style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
                    },
                    {
                      label: "Data & Analytics",
                      style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
                    },
                  ]}
                ></MyServicesCardHeader>
              </>

              <InstanceNameProvision
                value={instanceName}
                hasInstanceNameError={hasInstanceNameError}
                handleInstanceNameChange={(value: string) =>
                  setInstanceName(value)
                }
              />
              <SelectRegionProvision
                selectedRegion={selectedRegion}
                handleRegionChange={(value: string) => setSelectedRegion(value)}
                regions={[{ label: "North America", value: "NorthAmerica" }]}
              />
              <UserManagementProvision
                handleProjectChange={(projectId: string) =>
                  setProjectId(projectId)
                }
                projects={projects}
                projectId={projectId}
                hasProjectIdError={hasProjectIdError}
              />
              <SolutionConfigurationProvision isGlobal={false} />
              <ReviewBillingProvision />
              <ProvisionInstanceButton
                handleProvisionInstance={handleProvisionInstance}
              />
            </>
          ) : (
            <LoadingAnimation />
          )
        ) : (
          <ProvisionSuccessfulPage
            onCreateAnotherInstance={onCreateAnotherInstance}
          />
        )}
      </div>
    </>
  );
};

export default TableauProvision;
