import * as React from "react";
import { useState, useEffect } from "react";
import { LoadingAnimation } from "../subcomponents/LoadingAnimation";
import Grid from "../subcomponents/Grid/Grid";
import {
  ServiceCard,
  Chip,
  ServiceCardFooter,
  SimpleCard,
  H5,
} from "@ssce/ui-shared-library";

type CardViewProps = {
  data: any[];
  variant?: "simple" | "service";
  filterLetter?: string;
  searchValue?: string;
  minMax?: string;
  handleNavigateTo?: (id: string, serviceName: string) => void;
  onButtonClick?: () => void;
  onFavoriteClick?: () => void;
  children?: React.ReactNode;
  ribbonLineHeight?: number;
};

type CardType = {
  logo: string;
  serviceName: string;
  catalogId: string;
  ribbon: string;
  plan: string;
  majorVersion: number;
  minorVersion: number;
  subtitle: string;
  shortDescription: string;
  instanceName?: string;
  title: string;
  tags: { function: string; industry: string; serviceType: string };
  children?: React.ReactNode;
  ribbonLineHeight?: string;
  onButtonClick?: () => void;
};

const CardView: React.FC<CardViewProps> = ({
  data = [],
  searchValue,
  filterLetter = "All",
  handleNavigateTo,
  variant,
  ribbonLineHeight,
  onButtonClick,
  minMax = "21.25rem",
}) => {
  const [filteredItems, setFilteredItems] = useState(data);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (filteredItems.length && data.length) {
      setIsLoaded(true);
    }
  }, [filteredItems, data]);

  useEffect(() => {
    let filtered = data;
    if (searchValue) {
      filtered = data.filter((item: { serviceName: string }) =>
        item.serviceName.toLowerCase().includes(searchValue.toLowerCase()),
      );
    }
    setFilteredItems(filtered);
  }, [data, searchValue]);
  const filteredCards =
    filterLetter === "All"
      ? filteredItems
      : filteredItems.filter((letter: { serviceName: string }) =>
          letter.serviceName.toLowerCase().startsWith(filterLetter),
        );

  if (variant === "simple") {
    return (
      <Grid minMax={minMax}>
        {filteredCards.map((item: CardType) => (
          <SimpleCard
            key={item.catalogId}
            className={`card-size ${
              item.serviceName ===
              "Riskthinking.AI: Beyond Hindsight - Physical Risk Analysis"
                ? "custom-riskthinking-logo"
                : [
                      "Tableau",
                      "Microsoft SQL Server 2019",
                      "Oracle Database 23ai",
                      "Microsoft Power BI",
                    ].includes(item.serviceName)
                  ? "custom-general-logo"
                  : ""
            }`}
            onClick={() => {
              handleNavigateTo &&
                handleNavigateTo(item.serviceName, item.catalogId);
            }}
            id={item.catalogId}
            img={item.logo}
            subtitle={<H5>{item.title || item.serviceName}</H5>}
            desc={item.shortDescription || item.instanceName}
            ribbon={item.ribbon}
            ribbonLineHeight={ribbonLineHeight}
          />
        ))}
      </Grid>
    );
  }

  return !isLoaded ? (
    <LoadingAnimation />
  ) : (
    <>
      {filteredItems.length < 1 && "No results found"}
      <Grid minMax={minMax}>
        {filteredCards.map((item: CardType) => {
          const versionSubtitle =
            (item.majorVersion ?? item.minorVersion) &&
            "v" + item.majorVersion + "." + item.minorVersion;
          const tags = [
            <Chip tag="function" width="none">
              {item.tags.function}
            </Chip>,
            <Chip tag="industry" width="none">
              {item.tags.industry}
            </Chip>,
            <Chip tag="service type" width="none">
              {item.tags.serviceType}
            </Chip>,
          ];

          return (
            <ServiceCard
              key={item.catalogId}
              ribbonLineHeight={ribbonLineHeight}
              id={item.catalogId}
              logo={item.logo}
              title={
                <div className={item.ribbon && "card-title"}>
                  {item.title || item.serviceName}
                </div>
              }
              ribbon={item.ribbon}
              subtitle={versionSubtitle ?? " "}
              tags={tags}
            >
              <div className="card-wrapper">
                <div className="card-desc">
                  {item.shortDescription || <>&nbsp;</>}
                </div>

                <div className="card-footer" data-testid={"serviceCardFooter"}>
                  <ServiceCardFooter
                    onButtonClick={() => {
                      onButtonClick
                        ? onButtonClick()
                        : handleNavigateTo &&
                          handleNavigateTo(item.serviceName, item.catalogId);
                    }}
                    buttonText="Get Started"
                  />
                </div>
              </div>
            </ServiceCard>
          );
        })}
      </Grid>
    </>
  );
};

export default CardView;
