import {
  Heading,
  Subheading,
  P,
  Image,
  Center,
  GrayHeading,
  Link,
} from "../../Styles/Documentation.styles";
import { OnThisPageHelper } from "../../OnThisPageHelper";

import S1 from "../../Images/S1.png";
import S2 from "../../Images/S2.png";
import S3 from "../../Images/S3.png";
import S5 from "../../Images/S5.png";
import S6 from "../../Images/S6.png";
import S7 from "../../Images/S7.png";
import S4 from "../../Images/S4.png";
import S8 from "../../Images/S8.png";
import S9 from "../../Images/S9.png";
const anchorTagsArr = [
  {
    subHeadingString: "What is a Data Product?",
    id: "1",
    items: [
      {
        subHeadingString: "Go to Data Mesh",
        id: "2",
      },
      {
        subHeadingString: `Click on "Data Product"`,
        id: "3",
      },
      {
        subHeadingString: `Tap "Create Data Product"`,
        id: "4",
      },
      {
        subHeadingString: "Select a Query",
        id: "5",
      },
      {
        subHeadingString: "Output Configurations",
        id: "6",
      },
      {
        subHeadingString: "Parameter Configurations",
        id: "7",
      },
      {
        subHeadingString: "Add Documentation",
        id: "9",
      },
      {
        subHeadingString: "Review",
        id: "10",
      },
      {
        subHeadingString: "Finalize",
        id: "11",
      },
    ],
  },
];

export const HowToCreateDataProduct = () => {
  return (
    <>
      <OnThisPageHelper anchorTagsArr={anchorTagsArr} />
      <Heading>How to Create a New Data Product</Heading>
      <i id="1">Last Updated: June 2024</i>
      <Subheading>What is a Data Product?</Subheading>
      <P>
        Data Products make well defined queries accessible secure APIs for end
        users. They allow intergration with other systems, platforms, and
        processes.
      </P>

      <P id="2"> Let's go over the steps in creating your first Data Product</P>

      <GrayHeading>Step 1 - Go to Data Mesh</GrayHeading>
      <P>
        Log into the
        <Link
          href="https://ssce-dm-kc-stg2l-marketplace-lb01.ssnc-corp.cloud/datamesh/"
          target="_blank"
        >
          Data Mesh
        </Link>
        site, ensure you already have your user credentials ready.
      </P>
      <Center>
        <Image sizeInRem={44} src={S1} />
      </Center>
      <br id="3" />
      <GrayHeading>Step 2 - Click on “Data Product” in the Sidebar</GrayHeading>
      <P>
        This will open up a list of previously created queries. If you have not
        used the query editor before you can do so by visiting
        <Link href="https://ssce-dm-kc-stg2l-marketplace-lb01.ssnc-corp.cloud/datamesh/query-editor">
          here.
        </Link>
      </P>
      <Center>
        <Image sizeInRem={44} src={S2} />
      </Center>
      <br id="4" />
      <GrayHeading>
        Step 3 - Tap the “Create Data Product” Button Near the Top of Your
        Screen
      </GrayHeading>
      <Center>
        <Image sizeInRem={22} src={S3} />
      </Center>
      <br id="5" />
      <GrayHeading>
        Step 4 - Scroll Down and Select a Query of Your Choice.
      </GrayHeading>
      <Center>
        <Image sizeInRem={44} src={S7} />
      </Center>
      <br id="6" />
      <GrayHeading>Step 5 - Select Your Output Configurations</GrayHeading>
      <P>
        An output configuration is a set of instructions that determines what
        information is produced by a program or system. It defines the content
        of the output, not how it's presented.
      </P>
      <Center>
        <Image sizeInRem={44} src={S4} />
      </Center>
      <br id="7" />
      <GrayHeading>Step 6 - Select Your Parameter Configurations</GrayHeading>
      <P>
        Parameter configurations are like settings for a machine, while outputs
        are the results it produces. You define parameters (color, temperature)
        before running a program, and the program uses those settings to
        generate its output (finished product). Outputs are what you get, and
        parameter configurations tell the system how to get it.
      </P>
      <Center>
        <Image sizeInRem={44} src={S5} />
      </Center>
      <br id="8" />
      <GrayHeading>Step 7 - Add your Documentation</GrayHeading>
      <P>
        Documenting configurations is crucial because it ensures everyone's on
        the same page. Without clear instructions, someone using the system
        might choose the wrong settings, leading to unexpected or incorrect
        output.
      </P>
      <Center>
        <Image sizeInRem={44} src={S6} />
      </Center>
      <br id="9" />
      <GrayHeading>Step 8 - Review your Data Product</GrayHeading>
      <P>
        Be sure to check for any mistakes and ensure your product is good to go.
      </P>
      <Center>
        <Image sizeInRem={44} src={S8} />
      </Center>
      <br id="10" />
      <GrayHeading>Step 9 - Finally, hit the Create Button. </GrayHeading>
      <P>
        Congratulations! You have now successfully created your own data product
        and are now ready to view in
        <Link href="https://ssce-dm-kc-stg2l-marketplace-lb01.ssnc-corp.cloud/">
          Marketplace!
        </Link>
      </P>
      <Center>
        {" "}
        <Image sizeInRem={44} src={S9} />
      </Center>
    </>
  );
};
