import {
  AlgoRiskCopy,
  IntroDataMeshPage,
  EverywhereCopy,
} from "./CopyText";
import { DocumentationContentWrapper } from "./Styles/Documentation.styles";

type CopyDisplayOptions =
  | "announcing-the-launch-of-ss&c-algo-risk-reports-solution-in-everywhere-marketplace"
  | "introducing-the-ss&c-everywhere-data-mesh"
  | "ss&c-everywhere"

type DocumentationContentProps = {
  copyToDisplay: CopyDisplayOptions;
};

const showDocumentationCopy = (copyToDisplay) => {
  if (
    copyToDisplay ===
    "announcing-the-launch-of-ss&c-algo-risk-reports-solution-in-everywhere-marketplace"
  ) {
    return <AlgoRiskCopy />;
  }
  if (copyToDisplay === "introducing-the-ss&c-everywhere-data-mesh") {
    return <IntroDataMeshPage />;
  }
  if (copyToDisplay === "ss&c-everywhere") {
    return <EverywhereCopy />;
  }
};

const DocumentationContent = ({ copyToDisplay }: DocumentationContentProps) => {
  return (
    <DocumentationContentWrapper>
      {showDocumentationCopy(copyToDisplay)}
    </DocumentationContentWrapper>
  );
};

export default DocumentationContent;
