import { AuthConsumer } from "./authProvider";
import { LoadingAnimation } from "../../components/main/subcomponents/LoadingAnimation";

export const Callback = () => (
  <AuthConsumer>
    {({ signinRedirectCallback }) => {
      signinRedirectCallback();
      return <LoadingAnimation />;
    }}
  </AuthConsumer>
);
