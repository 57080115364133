import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Sightline.css";

const SightlineTechnicalSpecifications: React.FC = () => {
  const RenderTechDetails = ({ heading, desc }) => {
    return (
      <div>
        <div className="flex-col">
          <Typography
            variant="h6"
            fontSize="1.25rem"
            sx={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {heading}
          </Typography>
        </div>
        <div className="flex-col padding1rem">
          <Typography
            variant="body1"
            sx={{ color: "#000", lineHeight: "1.5rem" }}
          >
            {desc}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex-col gap4rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Technical Specifications
          </Typography>
          <RenderTechDetails
            heading={"Automated Flows"}
            desc={
              "Streamlines data flows for increased speed, accuracy, and scalability. This automation reduces manual efforts and enhances data processing efficiency."
            }
          />
          <RenderTechDetails
            heading={"Collaborative Analytics"}
            desc={
              "Promotes teamwork by ensuring shared data integrity. Business users can collaborate on data analysis and reporting, fostering a unified approach to data-driven decision-making."
            }
          />
          <RenderTechDetails
            heading={"Customizable Dashboards"}
            desc={
              "Offers tailored visuals for quick and insightful drill-downs. Users can customize dashboards to suit their specific analytical needs and preferences."
            }
          />
          <RenderTechDetails
            heading={"Real-time Access"}
            desc={
              "Enables instant, secure, self-service access to granular data within hours. This ensures confident, data-driven decisions with up-to-date information."
            }
          />
          <RenderTechDetails
            heading={"Flexible No-code Tools"}
            desc={
              "Provides business users with no-code tools to easily manipulate, join, and analyze data. Users can create custom views and reports without the need for technical expertise."
            }
          />

          <RenderTechDetails
            heading={"Effortless Data Integration"}
            desc={
              "Consolidate data from various sources into a unified model, ensuring harmonized and validated data for accurate reporting. This feature simplifies data challenges by centralizing information."
            }
          />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default SightlineTechnicalSpecifications;
