import React from "react";
import { ApiKeysContent } from "./apiKeys";
import { useApiKeysHooks } from "./apiKeysHooks";

const ApiKeys: React.FC = () => {
  const hookProps = useApiKeysHooks();
  return <ApiKeysContent {...hookProps} />;
};

export default ApiKeys;
