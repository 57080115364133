// import './Minicard.css';
import * as React from "react";
import { Progress } from "../subcomponents/Progress";
import Manage from "../../../assets/PC-Setting.svg";
import Open from "../../../assets/PC-Open.svg";

const DWMinicard = (props) => {
  return (
    <>
      <div className={"minicard"}>
        <h1 style={{ fontSize: "1.563rem" }}>{props.h1}</h1>
        <h3 style={{ marginTop: "0.188rem" }}> {props.h3}</h3>
        <p>{props.p}</p>
        <Progress value={props.val} showTypography={false} />
        <span>{props.span}</span>
        <div className={"icons-container"}>
          <div>
            <img alt={"Manage"} src={Manage} className={"PC-manage-icon"}></img>
            <img alt={"Open"} src={Open}></img>
          </div>
          <div className={"icons-des-container"}>
            <p className={"PC-manage-text"}>Manage</p>
            <p>Open</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default DWMinicard;
