export const baseUrl = "";
export const tableauUrl =
  (window as any)._env_?.REACT_APP_TABLEAU_URL ||
  process.env.REACT_APP_TABLEAU_URL;
const keycloakUrl =
  (window as any)._env_?.REACT_APP_AUTH_URL || process.env.REACT_APP_AUTH_URL;
const keycloakClientID =
  (window as any)._env_?.REACT_APP_IDENTITY_CLIENT_ID ||
  process.env.REACT_APP_IDENTITY_CLIENT_ID;
export const dataMeshRedirectURL = window.location.origin;
export const supersetDashboardURL =
  (window as any)._env_?.REACT_APP_SUPERSET_REDIRECT_URL ||
  process.env.REACT_APP_SUPERSET_REDIRECT_URL;

export const IDENTITY_CONFIG = {
  authority: keycloakUrl, //(string): The URL of the OIDC provider.
  client_id: keycloakClientID, //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: window.location.origin + process.env.REACT_APP_REDIRECT_URL, //The URI of your client application to receive a response from the OIDC provider.
  silent_redirect_uri:
    window.location.origin + process.env.REACT_APP_SILENT_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri:
    window.location.origin + process.env.REACT_APP_LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
  response_type: "code", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  response_mode: "query",
  automaticSilentRenew: true,
  silentRequestTimeoutInSeconds: 30,
  grantType: "password",
  monitorSession: true,
  loadUserInfo: false,
  scope: "openid offline_access profile email ssce-mktplc-ui-scope", //(string, default: 'openid'): The scope being requested from the OIDC provider.
};

// export const IAM_BASE_URL = 'https://ssce-iam-dev-02.ssnc-corp.cloud';

//export const IAM_BASE_URL = `public`;

//export const USER_ENDPOINT_BASE_URL = 'api/mktplc-user-management/v0';

export const USER_ENDPOINT_BASE_URL = `/users/api/mktplc-user-management/v0`;

//export const USER_ENDPOINT_BASE_URL = 'https://ssce-iam-dev-02.ssnc-corp.cloud/v1/iam';

export const PUBLIC_CATALOG_APPLICATION_NAME = {
  riskReport: "arr_asset_mgmt",
  performanceReport: "arr_performance",
};

export const PERMISSION_LIST = {
  USERS_READ: "Marketplace:Users:read",
  USERS_WRITE: "Marketplace:Users:write",
  USERS_EXECUTE: "Marketplace:Users:execute",
  PROJECTS_READ: "Marketplace:Projects:read",
  PROJECTS_WRITE: "Marketplace:Projects:write",
  PROJECTS_EXECUTE: "Marketplace:Projects:execute",
  ROLES_READ: "Marketplace:Roles:read",
  ROLES_WRITE: "Marketplace:Roles:write",
  ROLES_EXECUTE: "Marketplace:Roles:execute",
  API_KEYS_READ: "Marketplace:Instance-API-Keys:read",
  API_KEYS_EXECUTE: "Marketplace:Instance-API-Keys:execute",
};
