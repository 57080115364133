import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import { CurrentViewContext } from "../../../ComponentWithContext";
import { Button } from "@ssce/ui-shared-library";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

const PostgresqlReport: React.FC = () => {
  const navigate = useNavigate();
  const { viewData } = useContext(CurrentViewContext);
  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/Postgresql.png"}
        title={"PostgreSQL"}
        subTitle={`PostgreSQL, ${viewData.region}`}
        contents={
          "PostgreSQL is a highly reliable and feature-rich open-source object-relational database system known for its robust performance and extensive capabilities. It supports advanced data types, complex queries, and ACID compliance, making it well-suited for handling diverse and complex workloads. Additionally, PostgreSQL offers a wide range of extensions and customization options, enabling developers to tailor the database to specific needs and integrate seamlessly with various applications and systems."
        }
        tags={[
          {
            label: "Data Management",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Database",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs title={"PostgreSQL"} instanceName={"PostgresSQL"} />
    </>
  );
};
export default PostgresqlReport;
