import * as React from "react";
import { useContext } from "react";
import { Button } from "@ssce/ui-shared-library";
import { useNavigate } from "react-router-dom";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { CurrentViewContext } from "../../../ComponentWithContext";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";

const MSAlgoRiskReport: React.FC = () => {
  const navigate = useNavigate();
  const { viewData } = useContext(CurrentViewContext);
  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/algoriskreport.png"}
        title={viewData.instanceName}
        subTitle={
          viewData.serviceName === "arr_performance"
            ? "SS&C Performance Service"
            : "SS&C Risk Service"
        }
        contents={
          viewData.serviceName === "arr_performance"
            ? "This cost-effective service leverages your existing holdings data within the SS&C platform. It delivers accurate performance, returns, and return contributions metrics through reports or an intuitive web-based dashboard. ​"
            : "This cost-effective risk service leverages your existing holdings data housed within the SS&C platform. It delivers sophisticated financial risk analytics through reports or an intuitive web-based dashboard. ​"
        }
        tags={[
          {
            label: "Risk Management",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Analytics Service",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs
        title={
          viewData.serviceName === "arr_performance" ? "Performance" : "Risk"
        }
        instanceName={viewData.instanceName}
        subscriptionName={
          viewData.serviceName === "arr_performance"
            ? "SS&C Algorithmics Performance Reports"
            : "Algo Risk Report - Asset Management"
        }
        reportTableName="New CSV Report"
      />
    </>
  );
};
export default MSAlgoRiskReport;
