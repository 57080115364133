import styled from "styled-components";

export const Title = styled.h4`
  color: var(--primary);
  font-size: 2.125rem;
  font-weight: 600;
`;

export const SubTitle = styled.h5`
  font-size: 1.75rem;
  padding-bottom: 0;
  margin: 0;
  font-weight: 300;
`;

export const Italics = styled.i`
  font-size: 0.75rem;
`;

export const LightText = styled.div`
  color: var(--neutral700);
  padding-top: 1.25rem;
  padding-right: 7rem;
`;

export const LightSubHeading = styled.div`
  color: var(--neutral600);
  font-weight: 600;
`;

export const SmallButton = styled.button`
  all: unset;
  color: white;
  display: inline-block;
  background: var(--neutral700);
  font-weight: 500;
  font-size: 1.5rem;
  padding: 0.625rem 1.25rem;
  border-radius: 0.25rem;
`;

export const Wrapper = styled.div`
  display: flex;
  padding-bottom: 3.125rem;
  & > div {
    flex: 2;
  }
`;

export const Flex = styled.div`
  display: flex;
  padding-bottom: 1.25rem;
  justify-content: space-between;
`;

export const BannerWrapper = styled.div`
  margin-top: 1.25rem;
  margin-bottom: 6rem;
`;

export const PricePoint = styled.div`
  font-size: 3rem;
  padding-right: 3rem;
`;

export const Sup = styled.sup`
  font-size: 0.5em;
`;
