import { useState, useEffect, useCallback } from "react";
import { Button } from "@ssce/ui-shared-library";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Alert from "@mui/material/Alert";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useParams } from "react-router-dom";
import { baseUrl } from "../../../../constants";
import PCAccessRequest from "./AccessRequest/PCAccessRequest";
import PCOverview from "./PrivateCatalogOverview/PCOverview";
import "../PrivateCatalog.css";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";
import PCAccessRequestSuccess from "./AccessRequest/PCAccessRequestSuccess";
import { TryItNow } from "../../Documentation/TryItNow";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

interface PCDatameshServicesProps {
  handleBreadCrumbNickName: any;
  handleBreadCrumbClick?: any;
}

const PCDatameshServices = ({
  handleBreadCrumbNickName,
  handleBreadCrumbClick,
}: PCDatameshServicesProps) => {
  const navigate = useNavigate();

  const [value, setValue] = useState("1");
  const [items, setItems] = useState<any>("");
  const [openRequestAccess, setRequestAccess] = useState(false);
  const [openRequestAccessSuccess, setRequestAccessSuccess] = useState(false);
  const [addMyServices, setAddMyServices] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleORequestAccess = () => setRequestAccess(!openRequestAccess);

  const { catalogId } = useParams();

  const getServiceData = useCallback(() => {
    setLoading(true);
    axios
      .get(
        `${baseUrl}/api/mktplc-catalog-api/v0/private/services/applications/data_mesh/catalogs/${catalogId}`,
      )
      .then((res) => {
        setLoading(false);
        setItems(res.data);
        handleBreadCrumbNickName(res.data.serviceName);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [setLoading, setItems, catalogId]);

  useEffect(() => {
    getServiceData();
  }, [getServiceData]);

  //handleChange tabs
  const handleChange = (_, newValue: string) => {
    setValue(newValue);
  };

  const handleAddMyServices = (catalogId: string) => {
    axios
      .post(
        `${baseUrl}/api/mktplc-catalog-api/v0/private/services/add-to-my-service/${catalogId}`,
        {},
      )
      .then((res) => {
        console.log({ responseData: res.data });
        getServiceData();
        setAddMyServices(!addMyServices);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const data = [items];

  return (
    <>
      {data.map(
        (item: {
          catalogId: any;
          serviceId: string;
          serviceName: string;
          applicationName: string;
          catalogType: string;
          majorVersion: number;
          minorVersion: number;
          shortDescription: any;
          longDescription: any;
          productId: string;
          logo: any;
          businessUnit: string;
          labels: { banner: string };
          tags: { function: string; industry: string; serviceType: string };
          region: { us: { regionDetails: string; localCompliance: string[] } };
          hasAccess: boolean;
          hasAddedTo: boolean;
        }) => {
          return (
            <>
              {loading ? (
                <LoadingAnimation />
              ) : (
                <div>
                  <MyServicesCardHeader
                    imageSource={item.logo}
                    title={item.serviceName}
                    subTitle={`This API is private to your organization ${item.longDescription}`}
                    contents={
                      "Apache Superset is an open-source data exploration and visualization platform that enables you to create interactive and shareable dashboards. Whether you're a data analyst, data scientist, or business user, Superset provides a user-friendly interface for exploring and visualizing your data."
                    }
                    tags={[
                      {
                        label: item.tags ? item.tags.function : "",
                        style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
                      },
                      {
                        label: item.tags ? item.tags.industry : "",
                        style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
                      },
                      {
                        label: item.tags ? item.tags.serviceType : "",
                        style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
                      },
                    ]}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {!item.hasAccess && !item.hasAddedTo ? (
                        <Button
                          variant="default"
                          id={"requestAccess"}
                          onClick={handleORequestAccess}
                        >
                          Request Access
                        </Button>
                      ) : item.hasAccess && !item.hasAddedTo ? (
                        <>
                          <Button
                            variant="default"
                            id={"addToMyServices"}
                            onClick={() => {
                              handleAddMyServices(item.catalogId);
                              setRequestAccessSuccess(
                                !openRequestAccessSuccess,
                              );
                            }}
                          >
                            Add to My Services
                          </Button>
                        </>
                      ) : item.hasAccess &&
                        item.hasAddedTo &&
                        !openRequestAccessSuccess ? (
                        <>
                          <div style={{ display: "flex", gap: "0.625rem" }}>
                            <Button
                              variant="default"
                              id={"viewToMyServices"}
                              onClick={() => {
                                handleBreadCrumbClick("", "my-services");
                                navigate("/my-services");
                              }}
                            >
                              View in My Services
                            </Button>
                            <Stack sx={{}} spacing={2}>
                              <Alert
                                icon={<CheckCircleIcon fontSize="inherit" />}
                                severity="success"
                                className="pc-success-alert"
                              >
                                Already in My Services
                              </Alert>
                            </Stack>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </MyServicesCardHeader>
                  <Box
                    sx={{
                      typography: "body1",
                      marginLeft: "1.25rem",
                    }}
                  >
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList
                          onChange={handleChange}
                          aria-label="lab API tabs example"
                        >
                          <Tab label="Overview" value="1" />
                          <Tab label="Documentation" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel
                        value="1"
                        sx={{ marginTop: "4.5rem", padding: 0 }}
                      >
                        <PCOverview data={items} />
                      </TabPanel>
                      <TabPanel
                        value="2"
                        sx={{ marginTop: "4.5rem", padding: 0 }}
                      >
                        <TryItNow
                          catalogId={item.catalogId}
                          productId={item.productId}
                          productName={item.serviceName}
                        />
                      </TabPanel>
                    </TabContext>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    ></div>
                  </Box>
                  {openRequestAccess && <PCAccessRequest />}
                </div>
              )}
              <PCAccessRequestSuccess
                handleBreadCrumbClick={handleBreadCrumbClick}
                openRequestAccessSuccess={openRequestAccessSuccess}
                setOpenRequestAccessSuccess={setRequestAccessSuccess}
              />
            </>
          );
        },
      )}
    </>
  );
};

export default PCDatameshServices;
