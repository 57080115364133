import { useState, useEffect, FC } from "react";
import Typography from "@mui/material/Typography";
import { Button as MUIButton } from "@mui/material";
import { Button } from "@ssce/ui-shared-library";
import IconButton from "@mui/material/IconButton";
import ModalC from "@mui/material/Modal";
import { Table, Column } from "@ssce/ui-shared-library";
import FilterPopover from "../../../filter-popover/FilterPopover";
import { Modal } from "../Modal";
import ProjectsDetils from "./ProjectsDetils";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetchProjects,
  fetchResources,
  setSelectProjects,
  setShowHideProjectsDetails,
  setShowCreateProject,
  setProjectOverviewDetailsLoading,
} from "../../../../redux/projectsManagement/actions";
import { useDispatch } from "react-redux";
import CreateProjects from "./CreateProjects";
import Acl from "../../subcomponents/Acl";
import { PERMISSION_LIST } from "../../../../constants";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";

const mapStateToProps = (state: AppState) => ({
  projects: state.projectsManagement.projectsList,
  isLoading: state.projectsManagement.isLoading,
  showDetails: state.projectsManagement.showDetails,
  resourcesList: state.projectsManagement.resourcesList,
  showCreateProject: state.projectsManagement.showCreateProject,
});

const mapDispatchToProps = {
  fetchProjects,
  fetchResources,
  setSelectProjects,
  setShowHideProjectsDetails,
  setShowCreateProject,
  setProjectOverviewDetailsLoading,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const ProjectsTab: FC<Props> = ({
  fetchProjects,
  fetchResources,
  setSelectProjects,
  setShowHideProjectsDetails,
  setShowCreateProject,
  setProjectOverviewDetailsLoading,
  isLoading,
  projects,
  showDetails,
  resourcesList,
  showCreateProject,
}) => {
  const dispatch = useDispatch();
  const [letterFilter, setLetterFilterValue] = useState("");
  const [resourcesFilter, setResourcesFilterValue] = useState<string[]>([]);

  useEffect(() => {
    fetchProjects();
    fetchResources();
  }, [fetchProjects, fetchResources]);

  const filteredData = () => {
    let filteredArray = projects;

    filteredArray = filteredArray.filter(
      (r) => r.name.toLowerCase().startsWith(letterFilter.toLowerCase()) && r,
    );

    if (resourcesFilter.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        let s = false;
        resourcesFilter.forEach((f) => {
          r.instances.forEach((item) => {
            if (item.instanceName === f) s = true;
          });
        });
        return s;
      });
    }

    return filteredArray;
  };
  const columns: Column[] = [
    {
      title: "Project Name",
      dataIndex: "name",
      sorter: true,
    },
    {
      title: "Description",
      dataIndex: "description",
      sorter: true,
    },
  ];

  if (isLoading) return <LoadingAnimation />;
  return (
    <>
      <div
        style={{
          marginTop: "0.625rem",
          display: "flex",
          flex: "100%",
          flexWrap: "wrap",
          gap: "100%",
          marginBottom: "3.125rem",
        }}
      >
        <div
          style={{ display: "flex", flex: "100%", flexWrap: "wrap", gap: "1%" }}
        >
          <Typography
            variant="h3"
            marginBottom={"1.25rem"}
            style={{ fontSize: "1.563rem", color: "#02273C" }}
          >
            Projects
          </Typography>
          <Typography
            style={{
              color: "#90A8B5",
              fontSize: "0.875rem",
              fontWeight: "500",
              marginTop: "0.5rem",
              width: "25rem",
            }}
          >
            A total of <b style={{ color: "#0468B1" }}>{projects.length}</b>
          </Typography>
        </div>
        <Modal
          showModal={showDetails}
          setShowModal={() => {
            dispatch(setShowHideProjectsDetails(false));
          }}
        >
          <ProjectsDetils />
        </Modal>

        <ModalC
          open={showCreateProject}
          onClose={() => setShowCreateProject(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <CreateProjects />
        </ModalC>
        <Acl permission={PERMISSION_LIST.PROJECTS_WRITE}>
          <div
            style={{
              marginBottom: "1.25rem",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button
              id={"createProject"}
              onClick={() => setShowCreateProject(true)}
            >
              + CREATE PROJECT
            </Button>
          </div>
        </Acl>
        <div
          style={{
            marginBottom: "1.25rem",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              marginTop: 10,
            }}
          >
            <IconButton
              key={"ALL"}
              aria-label="delete"
              size="small"
              onClick={() => setLetterFilterValue("")}
              id={"All"}
            >
              <span
                style={
                  "" === letterFilter
                    ? {
                        color: "#0A85C7",
                        textDecoration: "underline",
                        margin: "0.625rem",
                      }
                    : { color: "#B6C7D1", margin: "0.625rem" }
                }
              >
                {"ALL"}
              </span>
            </IconButton>

            {[
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
            ].map((l) => (
              <IconButton
                key={l}
                aria-label="delete"
                size="small"
                onClick={() => setLetterFilterValue(l)}
                id={l}
              >
                <span
                  style={
                    l === letterFilter
                      ? { color: "#0A85C7", textDecoration: "underline" }
                      : { color: "#B6C7D1" }
                  }
                >
                  {l}
                </span>
              </IconButton>
            ))}
            <div style={{ margin: "0.313rem 0 0.313rem 0.938rem" }}>
              <FilterPopover
                filterName={"Resources:"}
                labels={resourcesList.map((i) => i.instanceName)}
                onApplyClk={(list) => {
                  setResourcesFilterValue(list);
                }}
                clearFilter={resourcesFilter.length === 0}
              />
            </div>
          </div>
          {(letterFilter || resourcesFilter.length > 0) && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <MUIButton
                variant="text"
                sx={{ "&:hover": { color: "#33BDE9" }, color: "#0A85C7" }}
                id={"clearAllFilters"}
                onClick={() => {
                  setLetterFilterValue("");
                  setResourcesFilterValue([]);
                }}
                style={{
                  fontWeight: 800,
                  textDecoration: "underline",
                  marginRight: "0.625rem",
                }}
              >
                Clear All Filters{" "}
              </MUIButton>{" "}
            </div>
          )}
        </div>
        <Table
          id={"table"}
          showSearchFilter
          data={filteredData()}
          columns={columns}
          showPagination
          onRowClick={(row) => {
            dispatch(setSelectProjects(row));
            dispatch(setShowHideProjectsDetails(true));
            dispatch(setProjectOverviewDetailsLoading(true));
          }}
        />
      </div>
    </>
  );
};

export default connector(ProjectsTab);
