import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CatalogSubHeading from "./CatalogSubHeading";

export const CatalogKeyFeatures: React.FC<{
  title?: string;
  listItems: { heading?: string; description?: string; moreContent?: any }[];
  childCount?: number;
}> = ({ title = "Key Features", listItems, childCount = 3 }) => {
  const renderListItem = () => {
    return listItems.map((item, index) => {
      return (
        <Grid item xs={12 / childCount} md={12 / childCount}>
          <Grid container direction="row" alignItems="flex-start">
            <Grid item xs={2} md={2}>
              <Typography
                variant="h4"
                component={"span"}
                fontSize="2.125rem"
                sx={{ color: "#75909F", lineHeight: "2.25rem" }}
              >
                {String("0" + ++index).slice(-2)}
              </Typography>
            </Grid>
            <Grid item xs={8} md={8}>
              <Typography
                variant="h6"
                sx={{
                  color: "#02273C",
                  lineHeight: "1.5rem",
                  marginBottom: "1rem",
                }}
              >
                {item.heading}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  color: "#02273C",
                  lineHeight: "1.5rem",
                  fontSize: "1rem",
                }}
              >
                {item.description}
              </Typography>
              {item.moreContent}
            </Grid>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" gap={"3rem"}>
        <CatalogSubHeading title={title} />
        <Grid container spacing={2}>
          {renderListItem()}
        </Grid>
      </Grid>
    </>
  );
};

export default CatalogKeyFeatures;
