import React from "react";
import Divider from "@mui/material/Divider";
import CatalogSubHeading from "../CatalogSubHeading";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";
export const ProvisionRiskthinkingHeader: React.FC = () => {
  return (
    <>
      <div className="catalogSuperset">
        <CatalogSubHeading
          title={"Provision Service"}
          style={{ padding: "0.625rem 0" }}
        />
        <div>
          <Divider />
        </div>
      </div>
      <MyServicesCardHeader
        imageSource={"/api/assets/RiskthinkingAI.png"}
        title={"VELO Physical Risk Exposure Firm Level Reports & Data"}
        contents={
          "Climate-related physical risk reports and data for listed public companies and proprietary assets to enable a deeper understanding of your physical risk exposures.​"
        }
        tags={[
          {
            label: "Risk Management",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Web Application",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      ></MyServicesCardHeader>
    </>
  );
};
