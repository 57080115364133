import React from "react";
import Divider from "@mui/material/Divider";
import { Button } from "@ssce/ui-shared-library";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./provisionSuccessful.css";

export const ProvisionSuccessfulPage: React.FC<{
  onCreateAnotherInstance: () => void;
}> = ({ onCreateAnotherInstance }) => {
  const navigate = useNavigate();
  return (
    <div className={"provisionSuccessfulContainer"}>
      <div className={"title"}>Provision Service</div>
      <Divider className={"divider"} />
      <CheckCircleIcon sx={{ fontSize: 158 }} className={"checkmark"} />
      <div className={"successTitle"}>Success!</div>
      <div className={"successSubtext"}>
        Instance created successfully! Please note that your instance is
        currently undergoing provisioning, which may take some time. Please
        allow up to 30 minutes for the provisioning process to complete.
      </div>
      <div className={"successBoldTitle"}>What would you like to do next?</div>
      <div className={"buttonContainer"}>
        <Button
          variant="secondary"
          id="backToCatalog"
          onClick={() => navigate("/public-catalog")}
        >
          Go back to Catalog
        </Button>
        <Button
          variant="default"
          id="goToMyServices"
          onClick={() => navigate("/my-services")}
        >
          Go to My Services
        </Button>
        <Button
          variant="text"
          className="link"
          id="createAnotherInstance"
          onClick={onCreateAnotherInstance}
        >
          Create Another Instance of this service
        </Button>
      </div>
    </div>
  );
};
