import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../../constants";

type yourListingsArrType = {
  id: string;
  serviceName: string;
  version: string;
  status: string;
  applicationName: string;
  tags: {
    function: string;
    industry: string;
    serviceType: string;
  };
  labels: {
    banner: string;
    promotion: string;
  };
  createdAt: string;
}[];

export const useManageListingHooks = () => {
  let yourListingsArr: yourListingsArrType = [
    {
      id: "",
      serviceName: "",
      version: "",
      status: "",
      applicationName: "",
      tags: {
        function: "",
        industry: "",
        serviceType: "",
      },
      labels: {
        banner: "",
        promotion: "",
      },
      createdAt: "",
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const [pendingAPIs, setPendingAPIs] = useState([]);
  const [yourListings, setYourListings] = useState(yourListingsArr);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    getPendingYourListingApis();
  }, []);

  const getPendingYourListingApis = () => {
    setIsLoading(true);
    axios
      .get(
        `${baseUrl}/api/mktplc-catalog-api/v0/private/services/all/manage-listings`,
      )
      .then((res) => {
        setPendingAPIs(res.data.pendingAPIs);
        setYourListings(res.data.yourListings);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        setHasError(true);
      });
  };

  return {
    isLoading,
    hasError,
    pendingAPIs,
    yourListings,
  };
};
