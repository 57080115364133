import axios from "axios";
import { baseUrl } from "../constants";

type eventDataType = any;

const usePostEventCollection = (
  event: string,
  endpoint = "/api/event-collector/v1/insert",
) => {
  const postEvent = (eventData: eventDataType) => {
    const data = {
      event,
      "event_data": eventData,
    };

    if (endpoint && data) {
      axios.post(`${baseUrl}${endpoint}`, data)?.catch(() => {});
    }
  };

  return {
    postEvent,
  };
};
export default usePostEventCollection;
