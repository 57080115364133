import { Reducer } from "redux";
import { UserManagementAction, UserManagementActionType } from "./actions";
import { createDefaultState, UserManagementState } from "./state";

type UserManagementReducer = Reducer<UserManagementState, UserManagementAction>;
const reducer: UserManagementReducer = (
  state = createDefaultState(),
  action,
) => {
  switch (action.type) {
    case UserManagementActionType.SET_USER:
      return { ...state, usersList: action.payload };
    case UserManagementActionType.SELECT_USER:
      return { ...state, selectedUser: action.payload };
    case UserManagementActionType.SHOW_HIDE_USER_DETAILS:
      return { ...state, showDetails: action.payload };
    case UserManagementActionType.SET_USER_OVERVIEW_DETAILS:
      return { ...state, userOverviewDetails: action.payload };
    case UserManagementActionType.SET_USER_ROLES:
      return { ...state, assignedRoles: action.payload };
    case UserManagementActionType.SET_USER_PROJECTS:
      return { ...state, assignedProjects: action.payload };
    case UserManagementActionType.SET_CURRENT_USER:
      return { ...state, overviewData: action.payload };
    case UserManagementActionType.SET_USER_INFO:
      return { ...state, userInfo: action.payload };
    case UserManagementActionType.SET_LOADING:
      return { ...state, isLoading: action.payload };
    case UserManagementActionType.SET_DETAILS_LOADING:
      return { ...state, isDetailsLoading: action.payload };
    default: {
      return state;
    }
  }
};

export default reducer;
