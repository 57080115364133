
import { useEffect } from "react";
import { Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

const BreadcrumbTitle = styled(Breadcrumbs)`
  position: relative;
  top: 0;
  padding: 0.625rem 0 0.625rem 0.313rem;
  text-decoration: none;
`

const Active = styled.div`
  color: var(--primary);
  font-family: "Roboto", sans-serif !important;
  text-decoration: none;
  font-weight: 800;
`

const Inactive = styled(Link)`
  font-family: "Roboto", sans-serif !important;
  text-decoration: none;
  color: var(--gray);
`

type Props = {
  name: any;
  onClick?: any;
  handleBreadCrumbNickName: (name: string) => void;
};

const acronymsFromUrlPath = ["api", "ss&c", "sql"];

export const BreadcrumbBuilder: React.FC<Props> = ({
  name,
  onClick,
  handleBreadCrumbNickName,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("/riskthinking")) {
      handleBreadCrumbNickName(
        "VELO Physical Risk Exposure Firm Level Reports & Data",
      );
    } else if (pathname.includes("/msftsql")) {
      handleBreadCrumbNickName("Microsoft SQL Server 2019");
    } else if (pathname.includes("/powerbi")) {
      handleBreadCrumbNickName("Microsoft Power BI");
    } else if (pathname.includes("/kafka")) {
      handleBreadCrumbNickName("Apache Kafka");
    } else if (pathname.includes("/nifi")) {
      handleBreadCrumbNickName("Apache Nifi");
    } else if (pathname.includes("/postgresql")) {
      handleBreadCrumbNickName("PostgreSQL");
    } else if (pathname.includes("/ss&c-algorithmics-risk-reports")) {
      handleBreadCrumbNickName("SS&C Risk Service");
    } else if (pathname.includes("/ss&c-algorithmics-performance-reports")) {
      handleBreadCrumbNickName("SS&C Performance Service");
    } else if (
      pathname.includes("/my-services/") ||
      pathname.includes("/private-api-catalog/") ||
      pathname.includes("/user-access-management")
    ) {
      // just to Skip the handleBreadCrumbNickName call with "" value
    } else {
      handleBreadCrumbNickName("");
    }
  }, [pathname]);

  const crumbs = name?.slice(0, -1);
  const last = name?.slice(-1).shift();

  const capitlizedLetter = (word) => {
    let arr = word.split("-");

    arr.forEach((wordInPath: string, index: number) => {
      if (acronymsFromUrlPath.includes(wordInPath)) {
        arr[index] = wordInPath.toUpperCase();
      }
    });

    return arr
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  if (!name[1]?.title) {
    return (
      <BreadcrumbTitle>
        <Active>{capitlizedLetter(name[0].title)}</Active>
      </BreadcrumbTitle>
    );
  } else {
    return (
      <BreadcrumbTitle aria-label="breadcrumb">
        {crumbs.map((obj: { link: string; title: string }) => {
          return (
            <Inactive
              color="inherit"
              to={obj.link}
              onClick={onClick}
            >
              {capitlizedLetter(obj.title)}
            </Inactive>
          );
        })}
        <Active>{capitlizedLetter(last.title)}</Active>
      </BreadcrumbTitle>
    );
  }
};
