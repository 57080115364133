import {
  ActiveStepDiv,
  ActiveStepTwoDiv,
  Description,
  InfoColumn,
  InfoContainer,
  MenuItems,
  PublishContainerTags,
  SelectContainer,
  SelectDescription,
  SelectDropdown,
  SelectTitle,
  StyledMenuItem,
  Title,
} from "../publishApi.styles";

export const TagsSection = ({
  functionTag,
  industryTag,
  serviceTypeTag,
  onFormChange,
}) => {
  return (
    <PublishContainerTags>
      <InfoContainer>
        <InfoColumn>
          <Title>Tags</Title>
          <Description>
            By assigning tags to your API, you enhance its discoverability in
            the API Private Catalog, making it easier for users to find and use.
          </Description>
        </InfoColumn>
      </InfoContainer>
      <ActiveStepDiv>
        <ActiveStepDiv>
          <SelectContainer>
            <ActiveStepTwoDiv>
              <SelectTitle>Select Function</SelectTitle>
              <SelectDescription>
                Describes the primary purpose or use case of the product.
              </SelectDescription>
            </ActiveStepTwoDiv>
            <MenuItems>
              <SelectDropdown
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={functionTag}
                fullWidth
                onChange={(e) => {
                  onFormChange("functionTag", e.target.value);
                }}
              >
                <StyledMenuItem value={"Accounting"}>Accounting</StyledMenuItem>
                <StyledMenuItem value={"Asset Servicing"}>
                  Asset Servicing
                </StyledMenuItem>
                <StyledMenuItem value={"Automation"}>Automation</StyledMenuItem>
                <StyledMenuItem value={"Cloud Platform"}>
                  Cloud Platform
                </StyledMenuItem>
                <StyledMenuItem value={"Data & Analytics"}>
                  Data & Analytics
                </StyledMenuItem>
                <StyledMenuItem value={"Performance Management"}>
                  Performance Management
                </StyledMenuItem>
                <StyledMenuItem value={"Risk Management"}>
                  Risk Management
                </StyledMenuItem>
                <StyledMenuItem value={"Regulatory Reporting"}>
                  Regulatory Reporting
                </StyledMenuItem>
              </SelectDropdown>
            </MenuItems>
          </SelectContainer>
          <SelectContainer>
            <ActiveStepTwoDiv>
              <SelectTitle>Select Industry</SelectTitle>
              <SelectDescription>
                Indicates the specific industry or sector for which the product
                is tailored.
              </SelectDescription>
            </ActiveStepTwoDiv>
            <MenuItems>
              <SelectDropdown
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={industryTag}
                fullWidth
                onChange={(e) => {
                  onFormChange("industryTag", e.target.value);
                }}
              >
                <StyledMenuItem value={"Asset Managers"}>
                  Asset Managers
                </StyledMenuItem>
                <StyledMenuItem value={"Banking"}>Banking</StyledMenuItem>
                <StyledMenuItem value={"Corporates"}>Corporates</StyledMenuItem>
                <StyledMenuItem value={"Health"}>Health</StyledMenuItem>
                <StyledMenuItem value={"Hedge Funds"}>
                  Hedge Funds
                </StyledMenuItem>
                <StyledMenuItem value={"Insurance"}>Insurance</StyledMenuItem>
                <StyledMenuItem value={"Private Markets"}>
                  Private Markets
                </StyledMenuItem>
                <StyledMenuItem value={"Retirement"}>Retirement</StyledMenuItem>
                <StyledMenuItem value={"Wealth Management"}>
                  Wealth Management
                </StyledMenuItem>
                <StyledMenuItem value={"Cross-Industry"}>
                  Cross-Industry
                </StyledMenuItem>
              </SelectDropdown>
            </MenuItems>
          </SelectContainer>
          <SelectContainer>
            <ActiveStepTwoDiv>
              <SelectTitle>Select Service Type</SelectTitle>
              <SelectDescription>
                Specifies the underlying technology or service category of the
                product.
              </SelectDescription>
            </ActiveStepTwoDiv>
            <MenuItems>
              <SelectDropdown
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={serviceTypeTag}
                fullWidth
                onChange={(e) => {
                  onFormChange("serviceTypeTag", e.target.value);
                }}
              >
                <StyledMenuItem value={"API"}>API</StyledMenuItem>
                <StyledMenuItem value={"Digital Worker"}>
                  Digital Worker
                </StyledMenuItem>
                <StyledMenuItem value={"Data Feed"}>Data Feed</StyledMenuItem>
                <StyledMenuItem value={"Web Application"}>
                  Web Application
                </StyledMenuItem>
              </SelectDropdown>
            </MenuItems>
          </SelectContainer>
        </ActiveStepDiv>
      </ActiveStepDiv>
    </PublishContainerTags>
  );
};
