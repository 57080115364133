import { FC, useState, useEffect } from "react";
import { connect, ConnectedProps, useSelector, useDispatch } from "react-redux";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MobileNav from "./Nav/MobileNav";
import { MarketplaceState } from "../../redux/state";
import { useNavigate } from "react-router-dom";
import { fetchUserInfo } from "../../redux/userManagement/actions";
import { dataMeshRedirectURL } from "../../constants";
import { HeaderNav } from "@ssce/ui-shared-library";
import styled from "styled-components";
import axios from "axios";
import { baseUrl } from "../../constants";

const HamburgerMenu = styled.button`
  all: unset;
  height: 100%;
  padding-left: 1rem;
  color: white;
  @media (min-width: 769px) {
    display: none;
  }
`;

const Wrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
  * {
    height: 2.25rem !important;
    align-content: center;
  }
`;

const MobileWrapper = styled.div`
  height: 3rem;
  @media (min-width: 768px) {
    display: none;
  }
`;

const Container = styled.div`
  position: fixed;
  z-index: 6;
  width: 100%;
  background: var(--primary);
`;

const mapStateToProps = (_state: MarketplaceState) => ({
  firstname: _state.user.username,
  userToken: _state.user.token,
  email: _state.user.email,
});

const connector = connect(mapStateToProps);
type Props = ConnectedProps<typeof connector>;

const TopBar: FC<Props> = ({ firstname, userToken }) => {
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const userManagement = useSelector(
    (_state: any) => _state.userManagement.userInfo,
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserInfo());
  }, [dispatch]);

  useEffect(() => {
    if (sessionStorage.getItem("fresh_login") === "true") {
      const data = {
        event: "login",
        event_data: { into: "marketplace" },
      };

      axios
        .post(`${baseUrl}/api/event-collector/v1/insert`, data)
        .then(() => {
          sessionStorage.removeItem("fresh_login");
        })
        .catch(() => {});
    }
  }, [userToken]);

  const navigate = useNavigate();

  return (
    <Container>
      <MobileWrapper>
        <HamburgerMenu
          onClick={() => {
            setMobileMenuIsOpen(!mobileMenuIsOpen);
          }}
        >
          {mobileMenuIsOpen ? <CloseIcon /> : <MenuIcon />}{" "}
        </HamburgerMenu>
        {mobileMenuIsOpen && (
          <MobileNav setMobileMenuIsOpen={setMobileMenuIsOpen} />
        )}
      </MobileWrapper>
      <Wrapper>
        <HeaderNav
          isMarketplace
          toAppUrl={
            dataMeshRedirectURL +
            "/datamesh/" +
            "?t=" +
            userToken +
            "&idp=" +
            sessionStorage.getItem("idp_hint")
          }
          name={firstname as string}
          client={userManagement.organisation}
          onLogout={() => navigate("/logout")}
        />
      </Wrapper>
    </Container>
  );
};

export default connector(TopBar);
