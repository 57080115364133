import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

export default function PermissionTable({ data }: { data: any }) {
  return (
    <>
      <div
        style={{
          marginLeft: 0,
          display: "flex",
          flexDirection: "row",
          alignSelf: "flex-end",
          justifyContent: "flex-start",
          gap: "10%",
        }}
      >
        <TableContainer
          style={{
            marginBottom: "1.25rem",
            marginLeft: "0.625rem",
            marginTop: "0.625rem",
            maxWidth: 420,
            border: "none",
          }}
        >
          <div>
            <Table
              sx={{ maxWidth: 1040, borderBottom: "0.063rem primary" }}
              size="small"
              aria-label="a dense table"
            >
              <TableHead>
                <TableRow style={{ backgroundColor: "#0A85C7" }}>
                  <TableCell
                    style={{
                      color: "#F6FDFF",
                      boxShadow:
                        "inset 0 -0.125rem 0 #075E8D, inset -0.125rem 0 0 #B3D9EE",
                    }}
                    align="left"
                  >
                    Module
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#F6FDFF",
                      boxShadow:
                        "inset 0 -0.125rem 0 #075E8D, inset -0.125rem 0 0 #B3D9EE",
                    }}
                    align="left"
                  >
                    Read
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#F6FDFF",
                      boxShadow:
                        "inset 0 -0.125rem 0 #075E8D, inset -0.125rem 0 0 #B3D9EE",
                    }}
                    align="left"
                  >
                    Write
                  </TableCell>
                  <TableCell
                    style={{
                      color: "#F6FDFF",
                      boxShadow:
                        "inset 0 -0.125rem 0 #075E8D, inset -0.125rem 0 0 #B3D9EE",
                    }}
                    align="left"
                  >
                    Execute
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(
                  (item: {
                    module: string;
                    read: boolean;
                    write: boolean;
                    execute: boolean;
                  }) => (
                    <TableRow
                      key={item.module}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        borderLeft: "0.125rem solid #C0EBF8",
                      }}
                    >
                      <TableCell
                        align="left"
                        style={{
                          padding: "0.625rem 1.875rem",
                          border: "0.006rem solid #C0EBF8",
                          boxShadow:
                            "inset 0 -0.063rem 0 #C0EBF8, inset -0.063rem 0 0 #C0EBF8",
                        }}
                      >
                        <b>{item.module.split(":")[0]}:</b>
                        <div>{item.module.split(":")[1]}</div>
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "0.625rem 1.875rem",
                          border: "0.006rem solid #C0EBF8",
                          boxShadow:
                            "inset 0 -0.063rem 0 #C0EBF8, inset -0.063rem 0 0 #C0EBF8",
                          textAlign: "center",
                        }}
                      >
                        {item.read ? (
                          <CheckIcon sx={{ color: "#0A85C7" }} />
                        ) : (
                          <ClearIcon />
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "0.625rem 1.875rem",
                          border: "0.006rem solid #C0EBF8",
                          boxShadow:
                            "inset 0 -0.063rem 0 #C0EBF8, inset -0.063rem 0 0 #C0EBF8",
                          textAlign: "center",
                        }}
                      >
                        {item.write ? (
                          <CheckIcon sx={{ color: "#0A85C7" }} />
                        ) : (
                          <ClearIcon />
                        )}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          padding: "0.625rem 1.875rem",
                          border: "0.006rem solid #C0EBF8",
                          boxShadow:
                            "inset 0 -0.063rem 0 #C0EBF8, inset -0.063rem 0 0 #C0EBF8",
                          textAlign: "center",
                        }}
                      >
                        {item.execute ? (
                          <CheckIcon sx={{ color: "#0A85C7" }} />
                        ) : (
                          <ClearIcon />
                        )}
                      </TableCell>
                    </TableRow>
                  ),
                )}
                {data.length === 0 && (
                  <TableRow
                    key={"No_record"}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      align="left"
                      colSpan={100}
                      style={{
                        border: "0.006rem solid #C0EBF8",
                        boxShadow:
                          "inset 0 -0.063rem 0 #C0EBF8, inset -0.063rem 0 0 #C0EBF8",
                      }}
                    >
                      {"No Record found"}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </div>
    </>
  );
}
