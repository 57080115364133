import { useState, useCallback } from "react";
import axios from "axios";
import { mapApiToUI } from "./workflowViewerMapper";
import { baseUrl } from "../../../../constants";
import moment from "moment";
import { RunParameters } from "../runNowModal";
import dayjs from "dayjs";

export type Status =
  | "success"
  | "failed"
  | "pending"
  | "queued"
  | "unknown"
  | "running";

interface NodeData {
  Icon: any;
  id: string;
  runTime: number;
  tasks: string[];
  createdAt: string;
  status: string;
  title: string;
  position: {
    x: number;
    y: number;
  };
  icon: string;
}

interface Position {
  x: number;
  y: number;
}

interface Node {
  id: string;
  type: string;
  position: Position;
  data: NodeData;
}

export type NodeAPI = {
  createdAt: string;
  failedAt?: string;
  icon: string;
  id: string;
  position: { x: number; y: number };
  runTime: number;
  status: string;
  tasks: string[];
  title: string;
};

export interface Edge {
  animated?: boolean;
  id: string;
  markerEnd?: { type: string; color: string };
  source: string;
  status: Status;
  style?: { stroke: string };
  target: string;
}

export interface Run {
  dag_id: string;
  dag_run_id: string;
  start_date: string;
  state: Status;
}

interface Workflow {
  id: string;
  createdAt: string;
  status: string;
  runningTime: number;
  startTime?: string;
  activeStep: string[];
  errors: any[];
  runs: Run[];
  nodes: Node[];
  edges: Edge[];
}

export interface WorkflowAPIResponse {
  activeStep: string[];
  createdAt: string;
  dag_id: string;
  errors: string[];
  id: string;
  runEndDate: string;
  runStartDate: string;
  runTime: number;
  run_id: string;
  runs: Run[];
  status: string;
  type: string;
  viewer: {
    height: string;
    nodes: NodeAPI[];
    edges: Edge[];
  };
}

export interface WorkflowInstance {
  catalogId: string;
  dagId: string;
  instanceId: string;
  instanceName: string;
  lastRun: string;
  lastRunId: string;
  region: string;
  scheduled: string;
  solutionName: string;
  status: Status;
  version: string;
}

const DEFAULT_ERROR_MESSAGE =
  "Oops! Something went wrong, please try again later.";

export const useWorkflowViewerHook = () => {
  const [currentWorkflow, setCurrentWorkflow] = useState<Workflow>();
  const [runTimes, setRunTimes] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [workflowTableData, setWorkflowTableData] = useState<
    WorkflowInstance[]
  >([]);
  const [dagList, setDagList] = useState<Run[]>([]);
  const [runParameters, setRunParameters] = useState<RunParameters>();
  const [latestDagId, setLatestDagId] = useState<string>("");
  const [isRunning, setIsRunning] = useState<boolean>(false);

  const getRunParameters = async (instanceId: string) => {
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/mktplc-catalog-api/v0/public/solutions/run_params/${instanceId}`,
      );
      setRunParameters(data);
    } catch (e) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
    }
  };

  const fetchWorkflows = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await axios.get(
        `${baseUrl}/api/mktplc-catalog-api/v0/public/solutions/all/instances`,
      );
      setRunTimes(
        data
          .map((c: WorkflowInstance) => c.lastRun)
          .sort((a, b) => {
            if (moment(a).valueOf() > moment(b).valueOf()) return -1;
            if (moment(a).valueOf() < moment(b).valueOf()) return 1;
            else return 0;
          }),
      );
      setIsLoading(false);
      setErrorMessage(undefined);
      setWorkflowTableData(data);
      return data;
    } catch (e) {
      setIsLoading(false);
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      return [];
    }
  }, []);

  const fetchRunDetails = async (
    workflowId: string,
    jobId: string = latestDagId,
    isPolling?: boolean,
  ): Promise<string[]> => {
    setIsLoading(!isPolling);
    setIsRunning(true);

    try {
      const response = await axios.get(
        `${baseUrl}/workflow-engine/api/workflow/graph/${workflowId}${jobId ? "/" + jobId : ""}`,
      );

      const { runs } = response.data;
      const updatedRunTimes = runs
        .map((run: Run) => run.start_date)
        .sort((a, b) => moment(b).valueOf() - moment(a).valueOf());

      setCurrentWorkflow(mapApiToUI(response.data));
      setDagList(runs);
      setLatestDagId(runs[runs.length - 1].dag_run_id);
      setRunTimes(updatedRunTimes);
      setErrorMessage(undefined);

      if (
        response.data.status === "failed" ||
        response.data.status === "success"
      ) {
        setIsRunning(false);
      }

      return updatedRunTimes;
    } catch (e) {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const runJob = async (workflowId: string, jobParameters: RunParameters) => {
    try {
      setIsLoading(true);
      setIsRunning(true);
      const { data } = await axios.post(
        `${baseUrl}/workflow-engine/api/workflow/${workflowId}/runs`,
        {
          job_run_id: `manual__${dayjs().format()}`,
          logical_date: dayjs().format(),
          execution_date: dayjs().format(),
          conf: jobParameters,
          note: "string",
        },
      );
      const updatedRunTimes = await fetchRunDetails(
        workflowId,
        data.content.dag_run_id,
      );
      setIsLoading(false);
      setErrorMessage(undefined);
      return updatedRunTimes;
    } catch {
      setErrorMessage(DEFAULT_ERROR_MESSAGE);
      setIsLoading(false);
    }
  };

  return {
    fetchWorkflows,
    currentWorkflow,
    isLoading,
    errorMessage,
    runTimes,
    runParameters,
    workflowTableData,
    dagList,
    fetchRunDetails,
    getRunParameters,
    runJob,
    isRunning,
  };
};
