export enum TabType {
  SERVICES,
  FEATURED_SERVICES,
  MY_SERVICES,
  SINGLE_SERVICE,
  DOCUMENTATION,
  CATALOG,
  DATASERVICES,
  NODERED,
  BILLING,
  SUPPORT,
  SETTINGS,
}

export const createDefaultState = (): TabType => TabType.SERVICES;
