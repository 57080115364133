import { AppThunk } from "../store";
import axios from "axios";
import { enqueueSnackbar } from "../snackbars/actions";
import { NotificationLevel } from "../snackbars/notification-level";
import { UserData, Roles, Permissions, Projects } from "../../common/dataTypes";
import { USER_ENDPOINT_BASE_URL } from "../../constants";

export enum RoleManagementActionType {
  SET_ROLE = "SET_ROLE",
  SET_ROLE_LOADING = "SET_ROLE_LOADING",
  SELECT_ROLE = "SELECT_ROLE",
  SHOW_HIDE_ROLE_DETAILS = "SHOW_HIDE_ROLE_DETAILS",
  SHOW_HIDE_CREATE_ROLE = "SHOW_HIDE_CREATE_ROLE",
  SHOW_HIDE_DUPLICATE_ROLE = "SHOW_HIDE_DUPLICATE_ROLE",
  SHOW_HIDE_EDIT_ROLE_NAME = "SHOW_HIDE_EDIT_ROLE_NAME",
  SHOW_HIDE_EDIT_ROLE_DESCRIPTION = "SHOW_HIDE_EDIT_ROLE_DESCRIPTION",
  SET_ROLE_DETAILS = "SET_ROLE_DETAILS",
  SET_ROLE_DETAILS_LOADING = "SET_ROLE_DETAILS_LOADING",
  SET_ROLE_PERMISSIONS = "SET_ROLE_PERMISSIONS",
  SET_PERMISSIONS = "SET_PERMISSIONS",
  SET_ROLE_USERS = "SET_ROLE_USERS",
  SET_ROLE_PROJECTS = "SET_ROLE_PROJECTS",
  SET_PROJECTS = "SET_PROJECTS",
}

export const fetchRoles = (): AppThunk => (dispatch) => {
  dispatch(setLoadingRoles(true));
  axios
    .get(`${USER_ENDPOINT_BASE_URL}/roles`)
    .then((response) => {
      dispatch(setRoles(response.data.groups))
      dispatch(setLoadingRoles(false));
})
    .catch(() =>
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve Roles`,
        ),
      ),
    );
};

export const fetchRolesOverview =
  (id: number): AppThunk =>
  (dispatch) => {
    
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/roles/${id}`)
      .then((response) => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(setRolesOverviewDetails(response.data))
        
  })
      .catch(() => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(setShowHideRolesDetails(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve Role info`,
          ),
        );
      });
  };

export const deleteRoleById =
  (id: number): AppThunk =>
  (dispatch) => {
    dispatch(setRolesOverviewDetailsLoading(true));
    axios
      .delete(`${USER_ENDPOINT_BASE_URL}/roles/${id}`)
      .then(() => {
        dispatch(fetchRoles());
        dispatch(setShowHideRolesDetails(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Role successfully removed",
          ),
        );
      })
      .catch(() => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to delete Role`,
          ),
        );
      });
  };

export const fetcRolePermissions =
  (id: number): AppThunk =>
  (dispatch) => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/roles/${id}/permissions`)
      .then((response) =>{
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(setRolePermissions(response.data.permissions))    
        })
      .catch(() => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve Role Permissions`,
          ),
        );
      });
  };

export const updateRolePermissions =
  (id: number, payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setRolesOverviewDetailsLoading(true));
    axios
      .post(`${USER_ENDPOINT_BASE_URL}/roles/${id}/permissions`, payload)
      .then(() => {
        dispatch(fetcRolePermissions(id));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Role Permissions are updated.",
          ),
        );
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to updated Role Permissions.`,
          ),
        );
      });
  };

export const deleteRolePermissions =
  (id: number, payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setRolesOverviewDetailsLoading(true));
    axios
      .delete(`${USER_ENDPOINT_BASE_URL}/roles/${id}/permissions`, {
        data: payload,
      })
      .then(() => {
        dispatch(fetcRolePermissions(id));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Permission successfully removed from role",
          ),
        );
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to updated Role Permissions`,
          ),
        );
      });
  };

export const fetcPermissions = (): AppThunk => (dispatch) => {
  axios
    .get(`${USER_ENDPOINT_BASE_URL}/permissions`)
    .then((response) => dispatch(setPermissions(response.data.permissions)))
    .catch(() => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve Role Permissions`,
        ),
      );
    });
};

export const fetcRoleUsers =
  (id: number): AppThunk =>
  (dispatch) => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/roles/${id}/users`)
      .then((response) => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(setRoleUsers(response.data.users))
      })
      .catch(() => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve Role users`,
          ),
        );
      });
  };

export const updateRoleUsers =
  (id: number, payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setRolesOverviewDetailsLoading(true));
    axios
      .post(`${USER_ENDPOINT_BASE_URL}/roles/${id}/users`, payload)
      .then(() => {
        dispatch(fetcRoleUsers(id));
        dispatch(
          enqueueSnackbar(NotificationLevel.SUCCESS, "Role Users are updated."),
        );
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to updated Role Users.`,
          ),
        );
      });
  };

export const deleteRoleUsers =
  (id: number, payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setRolesOverviewDetailsLoading(true));
    axios
      .delete(`${USER_ENDPOINT_BASE_URL}/roles/${id}/users`, { data: payload })
      .then(() => {
        dispatch(fetcRoleUsers(id));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "User successfully removed from role",
          ),
        );
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to updated Role Users`,
          ),
        );
      });
  };

export const fetcRoleProjects =
  (id: number): AppThunk =>
  (dispatch) => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/roles/${id}/projects`)
      .then((response) => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(setRoleProjects(response.data.projects))
      })
      .catch(() => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve Role projects`,
          ),
        );
      });
  };

export const fetcProjects = (): AppThunk => (dispatch) => {
  axios
    .get(`${USER_ENDPOINT_BASE_URL}/projects`)
    .then((response) => dispatch(setProjects(response.data.projects)))
    .catch(() => {
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve projects`,
        ),
      );
    });
};

export const updateRoleProjects =
  (id: number, payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setRolesOverviewDetailsLoading(true));
    axios
      .post(`${USER_ENDPOINT_BASE_URL}/roles/${id}/projects`, payload)
      .then(() => {
        dispatch(fetcRoleProjects(id));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Role Projects are updated.",
          ),
        );
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to updated Role Projects.`,
          ),
        );
      });
  };

export const deleteRoleProjects =
  (id: number, payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setRolesOverviewDetailsLoading(true));
    axios
      .delete(`${USER_ENDPOINT_BASE_URL}/roles/${id}/projects`, {
        data: payload,
      })
      .then(() => {
        dispatch(fetcRoleProjects(id));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Project successfully removed from role",
          ),
        );
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to updated Role Projects`,
          ),
        );
      });
  };

export const createRole =
  (payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setRolesOverviewDetailsLoading(true));
    axios
      .post(`${USER_ENDPOINT_BASE_URL}/roles`, payload)
      .then(() => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(fetchRoles());
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Role successfully created.",
          ),
        );
        dispatch(setShowCreateRole(false));
      })
      .catch(() => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to create Role.`,
          ),
        );
      });
  };

export const duplicateRole =
  (payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setRolesOverviewDetailsLoading(true));
    axios
      .post(`${USER_ENDPOINT_BASE_URL}/roles`, payload)
      .then(() => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(fetchRoles());
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Role successfully duplicated",
          ),
        );
        dispatch(setShowDuplicateRole(false));
      })
      .catch(() => {
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to duplicate Role.`,
          ),
        );
      });
  };

export const editRoleById =
  (id: number, payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setRolesOverviewDetailsLoading(true));
    axios
      .put(`${USER_ENDPOINT_BASE_URL}/roles/${id}`, payload)
      .then(() => {
        dispatch(fetchRolesOverview(id));
        //dispatch(fetchRoles());
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Role details are updated.",
          ),
        );
      })
      .catch(() => {
        dispatch(fetchRolesOverview(id));
        dispatch(setShowEditRoleName(false));
        dispatch(setShowEditRoleDesc(false));
        dispatch(setRolesOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to update Role details.`,
          ),
        );
      });
  };

export const setLoadingRoles = (setLoading: boolean) =>
  ({
    type: RoleManagementActionType.SET_ROLE_LOADING,
    payload: setLoading,
  }) as const;

export const setRoles = (UserData: Roles[]) =>
  ({
    type: RoleManagementActionType.SET_ROLE,
    payload: UserData,
  }) as const;

export const setSelectRoles = (selectedUser: Roles) =>
  ({
    type: RoleManagementActionType.SELECT_ROLE,
    payload: selectedUser,
  }) as const;

export const setShowHideRolesDetails = (showDetails: boolean) =>
  ({
    type: RoleManagementActionType.SHOW_HIDE_ROLE_DETAILS,
    payload: showDetails,
  }) as const;

export const setShowCreateRole = (showCreateRole: boolean) =>
  ({
    type: RoleManagementActionType.SHOW_HIDE_CREATE_ROLE,
    payload: showCreateRole,
  }) as const;

export const setShowDuplicateRole = (showDuplicateRole: boolean) =>
  ({
    type: RoleManagementActionType.SHOW_HIDE_DUPLICATE_ROLE,
    payload: showDuplicateRole,
  }) as const;

export const setShowEditRoleName = (showEditRoleName: boolean) =>
  ({
    type: RoleManagementActionType.SHOW_HIDE_EDIT_ROLE_NAME,
    payload: showEditRoleName,
  }) as const;

export const setShowEditRoleDesc = (showEditRoleDesc: boolean) =>
  ({
    type: RoleManagementActionType.SHOW_HIDE_EDIT_ROLE_DESCRIPTION,
    payload: showEditRoleDesc,
  }) as const;

export const setRolesOverviewDetails = (userOverviewDetails: Roles) =>
  ({
    type: RoleManagementActionType.SET_ROLE_DETAILS,
    payload: userOverviewDetails,
  }) as const;

  
export const setRolesOverviewDetailsLoading = (setLoading: boolean) =>
  ({
    type: RoleManagementActionType.SET_ROLE_DETAILS_LOADING,
    payload: setLoading,
  }) as const;

  
export const setRolePermissions = (permissions: Permissions[]) =>
  ({
    type: RoleManagementActionType.SET_ROLE_PERMISSIONS,
    payload: permissions,
  }) as const;

export const setPermissions = (permissions: Permissions[]) =>
  ({
    type: RoleManagementActionType.SET_PERMISSIONS,
    payload: permissions,
  }) as const;

export const setRoleUsers = (users: UserData[]) =>
  ({
    type: RoleManagementActionType.SET_ROLE_USERS,
    payload: users,
  }) as const;

export const setRoleProjects = (projects: Projects[]) =>
  ({
    type: RoleManagementActionType.SET_ROLE_PROJECTS,
    payload: projects,
  }) as const;

export const setProjects = (projects: Projects[]) =>
  ({
    type: RoleManagementActionType.SET_PROJECTS,
    payload: projects,
  }) as const;

export type RolesManagementAction =
  | ReturnType<typeof setRoles>
  | ReturnType<typeof setLoadingRoles> 
  | ReturnType<typeof setRolesOverviewDetailsLoading> 
  | ReturnType<typeof setSelectRoles>
  | ReturnType<typeof setShowHideRolesDetails>
  | ReturnType<typeof setShowCreateRole>
  | ReturnType<typeof setShowDuplicateRole>
  | ReturnType<typeof setShowEditRoleName>
  | ReturnType<typeof setShowEditRoleDesc>
  | ReturnType<typeof setRolesOverviewDetails>
  | ReturnType<typeof setRolePermissions>
  | ReturnType<typeof setPermissions>
  | ReturnType<typeof setRoleUsers>
  | ReturnType<typeof setRoleProjects>
  | ReturnType<typeof setProjects>;
