import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Msftsql.css";
import MsftsqlPricing from "./MsftsqlPricing";

const MsftsqlPlansAndFeatures: React.FC = () => {
  return (
    <>
      <div className={"gap4rem flex-col"}>
        <div className={"gap1rem flex-col"}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Pricing Plans and Features
          </Typography>
          <div className={"gap2rem flex-row"}>
            <div className={"gap1rem flex-col"}>
              <Typography
                variant="h6"
                fontSize="1.25rem"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                Microsoft SQL Server 2019 includes
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                {" "}
                <ul>
                  <li>
                    Integrates with Apache Spark and HDFS, allowing for scalable
                    and unified big data analytics.
                  </li>
                  <li>
                    Improves query performance with enhancements like adaptive
                    joins and table variable deferred compilation.
                  </li>
                  <li>
                    Provides in-memory processing for transaction-heavy
                    workloads, significantly boosting performance.
                  </li>
                  <li>
                    Protects sensitive data with Always Encrypted, ensuring
                    encryption at rest and in transit.
                  </li>
                  <li>
                    Optimizes large-scale data analytics and reduces storage
                    footprint with Columnstore indexes.
                  </li>
                  <li>
                    Ensures minimal downtime and robust disaster recovery with
                    Always On Availability Groups.
                  </li>
                  <li>
                    Supports graph-based data modeling with node and edge tables
                    for complex hierarchical analysis.
                  </li>
                  <li>
                    Integrates R and Python for in-database machine learning and
                    analytics.
                  </li>
                  <li>
                    Runs natively on Windows and Linux, and supports deployment
                    on Docker containers for flexibility.
                  </li>
                  <li>
                    Includes advanced security features like Transparent Data
                    Encryption (TDE) and dynamic data masking.
                  </li>
                </ul>
              </Typography>
            </div>
          </div>
          <MsftsqlPricing title={"Plan Options & Details"} />
        </div>
      </div>
    </>
  );
};

export default MsftsqlPlansAndFeatures;
