import * as React from "react";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { Button } from "@ssce/ui-shared-library";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { Link } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { baseUrl } from "../../../constants";
import Grid from "src/components/main/subcomponents/Grid/Grid";
import DocumentationCard from "./DocumentationCard";
import { NumberOfFilters } from "./Styles/Documentation.styles";

const Regulatory: React.FC = () => {
  const [value, setValue] = useState("1");
  const [items, setItems] = useState<any>([]);
  const [searching, setSearching] = useState<any>("");
  const [scrollTop, setScrollTop] = useState<any>(0);
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = React.useState([""]);
  const [date, setDate] = React.useState<Dayjs | null>(
    dayjs("2023-08-01T21:11:54"),
  );

  useEffect(() => {
    fetch(`${baseUrl}/api/apidocs`)
      .then((response) => response.json())
      .then((data) => {
        setItems(data);
      });
  }, []);
  const optionsList = [
    {
      key: 1,
      name: "Cross Industry",
    },
    {
      key: 2,
      name: "Cloud Platform",
    },
    {
      key: 3,
      name: "Data Services",
    },
    {
      key: 4,
      name: "ReDoc",
    },
    {
      key: 5,
      name: "Swagger",
    },
    {
      key: 6,
      name: "PostMan",
    },
  ];
  const handleChange = (_, newValue: string) => {
    setValue(newValue);
  };
  const handleChangeDate = (newValue: Dayjs | null) => {
    setDate(newValue);
  };

  const toggling = () => setIsOpen(!isOpen);
  const handleChangeForSearch = (e: { target: { value: any } }) => {
    setSearching(e.target.value);
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 31,
    height: 18,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(0.563rem)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(0.75rem)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 0.125rem 0.25rem 0 rgb(0 35 11 / 20%)",
      width: 15.5,
      height: 15.5,
      borderRadius: 8,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleDelete = (i: any) => {
    checked.filter((item) => item !== i);
  };

  const handleClearAll = () => {
    setChecked([]);
  };
  //searching
  const filtered = items.filter((item: { title: string }) =>
    item.title.toLowerCase().includes(searching.toLowerCase()),
  );

  //Virtual Scrolling
  const rowHeight = 30;
  const limit = 30;
  const startNode = Math.ceil(scrollTop / rowHeight);
  const visibleData = filtered.slice(0, startNode + limit);

  return (
    <>
      <Card sx={{ marginLeft: "0.625rem", height: 200, boxShadow: "none" }}>
        <CardContent>
          <div style={{ display: "flex", marginTop: "1.25rem" }}>
            <Typography
              variant="h1"
              fontSize="2.125rem"
              fontWeight={600}
              component="div"
              align="left"
              lineHeight={1}
              color="#0468B1"
              style={{ marginRight: "1rem", marginTop: "0.313rem" }}
            >
              Regulatory
            </Typography>
          </div>
          <Typography
            variant="body1"
            fontSize="1rem"
            fontWeight={400}
            component="div"
            align="left"
            lineHeight={1.5}
            color="#000000"
            style={{
              width: "50.188rem",
              marginTop: "1.563rem",
            }}
          >
            Explore our regulatory library to find important information on
            legal and industry- specific requirements, rules, standards, and
            policies.
          </Typography>
        </CardContent>
      </Card>
      <Box sx={{ width: "100%", typography: "body1", marginLeft: "0.625rem" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              id={"tabList"}
              onChange={handleChange}
              aria-label="lab API tabs example"
              style={{ marginLeft: "1.25rem" }}
            >
              <Tab label="Index of Release Notes" value="1" />
              <Tab label="Recent Product Changes" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1" style={{}}>
            <div>
              <Typography
                variant="h3"
                marginBottom={"1.25rem"}
                style={{ fontSize: "1.563rem", color: "#02273C" }}
              >
                Index of Release Notes
              </Typography>

              <div style={{ marginBottom: "1.25rem", display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                      m: 1,
                      width: "12.75rem",
                      marginRight: "0.938rem",
                      color: "#0A85C7",
                      borderRadius: "0.25rem",
                      position: "relative",
                    }}
                  >
                    <Button variant="default" id={"FILTERS"} onClick={toggling}>
                      <NumberOfFilters>{checked.length - 1}</NumberOfFilters>
                      Filters
                      <ExpandMoreRoundedIcon />
                    </Button>
                  </Stack>
                  {isOpen && (
                    <List
                      sx={{
                        width: "100%",
                        maxWidth: 205,
                        bgcolor: "background.paper",
                        position: "absolute",
                        marginTop: "4.375rem",
                        zIndex: "9999",
                      }}
                    >
                      {optionsList.map((value: { key: number; name: any }) => {
                        const labelId = `checkbox-list-label-${value.name}`;

                        return (
                          <ListItem key={value.name} disablePadding>
                            <ListItemButton
                              id={"handleToggle"}
                              role={undefined}
                              onClick={handleToggle(value.name)}
                              dense
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={checked.indexOf(value.name) !== -1}
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{ "aria-labelledby": labelId }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={labelId}
                                primary={` ${value.name}`}
                              />
                            </ListItemButton>
                          </ListItem>
                        );
                      })}
                    </List>
                  )}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack
                      spacing={3}
                      sx={{
                        m: 1,
                        color: "#0A85C7",
                        borderRadius: "0.313rem",
                        width: "12.75rem",
                        border: "#B6C7D1",
                      }}
                    >
                      <DesktopDatePicker
                        label="Date range"
                        value={date}
                        onChange={handleChangeDate}
                      />
                    </Stack>
                  </LocalizationProvider>
                  <Box
                    component="form"
                    sx={{
                      m: 1,
                      width: "39.625rem",
                      marginLeft: "0.625rem",
                      marginRight: 5,
                      color: "#0A85C7",
                      borderRadius: "0.25rem",
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <TextField
                      id="outlined-basic"
                      placeholder="Search by name"
                      variant="outlined"
                      onChange={handleChangeForSearch}
                      sx={{
                        width: "38.125rem",
                        borderRadius: "0.5rem",
                        marginRight: "0.625rem",
                        border: "0.063rem solid #B6C7D1",
                      }}
                      InputProps={{
                        style: {
                          height: "3.063rem",
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              style={{ color: "grey", fontSize: "1.438rem" }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </div>
              </div>
              <div style={{ marginLeft: "12.5rem" }}>
                {checked.map((item) => {
                  return item !== "" ? (
                    <Stack
                      direction="row"
                      spacing={1}
                      style={{ display: "inline-block" }}
                    >
                      <Chip
                        label={item}
                        style={{
                          backgroundColor: "#DFE9EF",
                          fontSize: "0.875rem",
                          color: "#355263",
                          marginRight: "0.313rem",
                        }}
                        onDelete={handleDelete}
                      />
                    </Stack>
                  ) : (
                    <div />
                  );
                })}
              </div>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                style={{
                  marginLeft: "47.5rem",
                  marginBottom: "1.875rem",
                }}
              >
                <Typography style={{ color: "#0A85C7", fontSize: "0.75rem" }}>
                  Search Result Must contain All Filters Selected
                </Typography>
                <AntSwitch
                  defaultChecked
                  inputProps={{ "aria-label": "ant design" }}
                />
              </Stack>
              <Box
                sx={{
                  typography: "body1",
                  "& > :not(style) + :not(style)": {
                    ml: 2,
                  },
                }}
                id={"clearAllFilters"}
                onClick={handleClearAll}
              >
                <Link href="#">Clear all filters &#62;&#62; </Link>
              </Box>
              <div
                style={{
                  display: "flex",
                  marginBottom: "1.875rem",
                  gap: "1.25rem",
                }}
              >
                <Typography
                  style={{
                    color: "#90A8B5",
                    fontSize: "0.875rem",
                    fontWeight: "500",
                    marginTop: "1.25rem",
                    width: "25rem",
                  }}
                >
                  A total of <b>{visibleData.length}</b> result
                </Typography>
                <Typography
                  variant="body1"
                  color="primary"
                  style={{
                    fontSize: "0.938rem",
                    marginLeft: "27.5rem",
                    marginTop: "1.563rem",
                    width: "3.125rem",
                  }}
                >
                  Sort by
                </Typography>
                <FormControl
                  sx={{ m: 5, minWidth: 140 }}
                  size="small"
                  style={{
                    marginLeft: "0.063rem",
                    marginTop: "0.625rem",
                    borderRadius: "0.25rem",
                    border: "0.063rem solid #0A85C7",
                  }}
                >
                  <Select
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    style={{ color: "'#0A85C7'" }}
                  >
                    <MenuItem value={10} style={{ color: "'#0A85C7'" }}>
                      Newest
                    </MenuItem>
                    <MenuItem value={20} style={{ color: "'#0A85C7'" }}>
                      Oldest
                    </MenuItem>
                    <MenuItem value={30} style={{ color: "'#0A85C7'" }}>
                      Alphabetical
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              <Grid
                onScroll={(e) => {
                  setScrollTop(e.currentTarget.scrollTop + rowHeight);
                }}
              >
                {visibleData.map(
                  (item: {
                    logo: any;
                    service: string;
                    title: any;
                    date: any;
                    tags: any[];
                  }) => {
                    return (
                      <DocumentationCard
                        image={item.logo}
                        service={item.service}
                        title={item.title}
                        date={item.date}
                        tags={item.tags}
                      />
                    );
                  },
                )}
              </Grid>
            </div>
          </TabPanel>
          <TabPanel value="2" style={{ display: "flex" }}>
            <div
              style={{
                width: "50rem",
              }}
            >
              <div>
                <Typography
                  variant="h3"
                  marginBottom={"1.25rem"}
                  style={{ fontSize: "1.563rem", color: "#02273C" }}
                >
                  Recent Regulatory Updates
                </Typography>
                <Typography
                  style={{
                    fontSize: "1rem",
                    marginBottom: "3.125rem",
                    width: "47.313rem",
                  }}
                >
                  The following release notes cover the most recent changes over
                  the last 60 days. For a comprehensive list of product-specific
                  release notes, click on the index of the Release Notes tab
                  above.
                </Typography>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default Regulatory;
