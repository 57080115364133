import {
  WorkflowViewerTableContainer,
  HeaderContainer,
  Header,
} from "./workflowViewerTable.styles";
import { Table, Chip, ErrorNotification } from "@ssce/ui-shared-library";
import moment from "moment";
import { Link } from "react-router-dom";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";
import {
  useWorkflowViewerHook,
  WorkflowInstance,
} from "../WorkflowViewerContent/workflowViewerHook";
import { useEffect } from "react";

type Direction = "desc" | "asc";

export const WorkflowViewerTable = () => {
  const { isLoading, errorMessage, fetchWorkflows, workflowTableData } =
    useWorkflowViewerHook();

  useEffect(() => {
    fetchWorkflows();
  }, [fetchWorkflows]);

  const columns = [
    {
      title: "Workflow",
      dataIndex: "instanceName",
      sorter: true,
      render: (cell: string, row: WorkflowInstance) => {
        return (
          <Link
            to={`/my-workflows/${row.dagId}`}
            state={{ workflowName: cell, instanceId: row.instanceId }}
            style={{
              textDecoration: "underline",
              textDecorationColor: "var(--primary)",
              cursor: "pointer",
            }}
          >
            <div style={{ color: "var(--primary)" }}>{cell}</div>
          </Link>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "solutionName",
      sorter: true,
    },
    {
      title: "Last Run",
      dataIndex: "lastRun",
      sorter: true,
      render: (cell: string) => {
        return moment(cell).format("MMM DD, YYYY h:mma");
      },
      defaultSortOrder: "desc" as Direction,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      render: (cell: string) => {
        return <Chip tag={cell?.toLowerCase()}>{cell}</Chip>;
      },
    },
    {
      title: "Schedule",
      dataIndex: "scheduled",
      sorter: true,
    },
  ];
  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <>
      {errorMessage && <ErrorNotification message={errorMessage} />}
      <WorkflowViewerTableContainer>
        <HeaderContainer>
          <Header>My Workflows</Header>
        </HeaderContainer>
        <Table
          id={"table"}
          columns={columns}
          data={workflowTableData}
          title={"Workflows"}
          showSearchFilter
        />
      </WorkflowViewerTableContainer>
    </>
  );
};
