import { Reducer } from "redux";
import {
  ProjectsManagementAction,
  ProjectsManagementActionType,
} from "./actions";
import { createDefaultState, ProjectsManagementState } from "./state";

type ProjectsManagementReducer = Reducer<
  ProjectsManagementState,
  ProjectsManagementAction
>;
const reducer: ProjectsManagementReducer = (
  state = createDefaultState(),
  action,
) => {
  switch (action.type) {
    case ProjectsManagementActionType.SET_PROJECTS:
      return { ...state, projectsList: action.payload };
    case ProjectsManagementActionType.SET_PROJECTS_LOADING:
      return { ...state, isLoading: action.payload };
    case ProjectsManagementActionType.SET_PROJECT_DETAILS_LOADING:
      return { ...state, isProjectDetailsLoading: action.payload };
    case ProjectsManagementActionType.SELECT_PROJECTS:
      return { ...state, selectedProject: action.payload };
    case ProjectsManagementActionType.SET_PROJECT_DETAILS:
      return { ...state, projectsOverviewDetails: action.payload };
    case ProjectsManagementActionType.SHOW_HIDE_PROJECTS_DETAILS:
      return { ...state, showDetails: action.payload };
    case ProjectsManagementActionType.SET_PROJECT_RESOURCES:
      return { ...state, projectResources: action.payload };
    case ProjectsManagementActionType.SET_PROJECT_ROLES:
      return { ...state, projectRoles: action.payload };
    case ProjectsManagementActionType.SET_PROJECT_USERS:
      return { ...state, projectUsers: action.payload };
    case ProjectsManagementActionType.SET_RESOURCES:
      return { ...state, resourcesList: action.payload };
    case ProjectsManagementActionType.SHOW_HIDE_CREATE_PROJECT:
      return { ...state, showCreateProject: action.payload };
    case ProjectsManagementActionType.SHOW_HIDE_EDIT_PROJECT_DESCRIPTION:
      return { ...state, showEditProjectDesc: action.payload };
    case ProjectsManagementActionType.SHOW_HIDE_EDIT_PROJECT_NAME:
      return { ...state, showEditProjectName: action.payload };
    case ProjectsManagementActionType.SHOW_HIDE_DUPLICATE_PROJECT:
      return { ...state, showDuplicateProject: action.payload };
    default: {
      return state;
    }
  }
};

export default reducer;
