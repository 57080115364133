import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";
import Region from "../Regions";
import { useNavigate } from "react-router-dom";
import CardView from "../CardView";
import NorthAmerica from "../../../../assets/NorthAmerica.png";
import CatalogBenefits from "../CatalogBenefits";
import BoltIcon from "@mui/icons-material/Bolt";
import InsightsIcon from "@mui/icons-material/InsightsOutlined";
import SecurityIcon from "@mui/icons-material/SecurityOutlined";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CatalogKeyFeatures from "../CatalogKeyFeatures";
import PowerBIPricing from "./PowerBIPricing";
import CatalogSubHeading from "../CatalogSubHeading";
import Grid from "@mui/material/Grid";

const CARD_DATA = [
  {
    id: 1,
    logo: "/api/assets/datamesh.png",
    serviceName: "SS&C Everywhere Data Mesh",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "The SS&C Everywhere Data Mesh platform provides an interface for users to centralize their data to make it more accessible to the rapidly changing needs of a user’s organization.",
    tags: {
      function: "Cloud Platform",
      industry: "Cross-Industry",
      serviceType: "Data Service",
    },
  },
  {
    id: 2,
    logo: "/api/assets/algoriskreport.png",
    serviceName: "SS&C Algorithmics Risk Reports",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "Generate world-class risk reports leveraging award and access powerful reporting dashboards preintegrated with your data.",
    tags: {
      function: "Risk Management",
      industry: "Cross-Industry",
      serviceType: "Analytics Service",
    },
  },
];

const PowerBIOverView: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateTo = (name: string) => {
    if (name === "SS&C Everywhere Data Mesh") {
      return navigate("/public-catalog/data-mesh");
    }
    if (name === "SS&C Algorithmics Risk Reports") {
      return navigate("/public-catalog/ss&c-algorithmics-risk-reports");
    }
  };

  return (
    <>
      <div className="flex-col gap3rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Overview
          </Typography>
          <div>
            <Typography
              variant="h6"
              fontSize="1.25rem"
              sx={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              About This Service
            </Typography>
            <Typography
              variant="body1"
              component={"div"}
              sx={{ color: "#000", lineHeight: "1.5rem" }}
            >
              <div style={{ display: "flex", maxWidth: "90%" }}>
                <ul>
                  <li>
                    {" "}
                    Microsoft Power BI is a business analytics tool that enables
                    users to create interactive reports and dashboards by
                    connecting to various data sources.{" "}
                  </li>
                  <li>
                    {" "}
                    It allows businesses to visualize and analyze their data in
                    real-time, offering customizable dashboards to track key
                    metrics and performance indicators.{" "}
                  </li>
                  <li>
                    {" "}
                    Microsoft Power BI integrates with multiple platforms,
                    including databases, cloud services, and Excel, providing a
                    comprehensive solution for data analysis.{" "}
                  </li>
                  <li>
                    {" "}
                    The tool offers powerful data modeling and transformation
                    capabilities, ensuring clean and actionable insights through
                    Power Query.{" "}
                  </li>
                  <li>
                    {" "}
                    With natural language queries and AI-driven insights,
                    Microsoft Power BI makes it easier for users to ask
                    questions of their data and get quick, visual answers.{" "}
                  </li>
                </ul>
              </div>
            </Typography>
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <InsightsIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Data-Driven Insights",
              description:
                "Microsoft Power BI enables businesses to uncover meaningful insights from their data through rich visualizations and interactive reports, empowering better decision-making and strategic planning.",
            },

            {
              icon: (
                <BoltIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Faster Reporting",
              description:
                "With Microsoft Power BI’s automated data refresh and seamless integration with numerous data sources, businesses can create and access real-time reports faster, improving operational efficiency.",
            },

            {
              icon: (
                <TrendingUpIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Improved Collaboration",
              description:
                "By sharing dashboards and reports across teams, Microsoft Power BI enhances collaboration and ensures everyone has access to the latest data, leading to more aligned and productive teams.",
            },

            {
              icon: (
                <SecurityIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Enhanced Data Security",
              description:
                "Microsoft Power BI includes robust security features such as data encryption, role-based access controls, and compliance certifications, reducing risks and protecting sensitive business data.",
            },
          ]}
        />

        <CatalogKeyFeatures
          childCount={2}
          listItems={[
            {
              heading: "Data Integration",
              description:
                "Microsoft Power BI connects seamlessly with hundreds of data sources, allowing organizations to integrate data from cloud services, databases, and spreadsheets for unified analysis and reporting.",
            },
            {
              heading: "Interactive Visualizations",
              description:
                "Enables the creation of dynamic and interactive reports with rich visualizations, offering a deeper understanding of data trends, patterns, and insights through real-time interaction.",
            },
            {
              heading: "AI-Powered Insights",
              description:
                "Leverages AI to help users discover hidden insights, ask questions of their data in natural language, and predict outcomes, empowering smarter, data-driven decision-making.",
            },
            {
              heading: "Role-Based Dashboards",
              description:
                "Provides customizable, role-specific dashboards, ensuring that each user has access to relevant metrics and insights tailored to their responsibilities, enhancing productivity and collaboration.",
            },
          ]}
        />

        <PowerBIPricing title={"Available Pricing Plans"} />
        <div className={"flex-col gap3rem"}>
          <CatalogSubHeading title={"Region Availability"} />
          <div className={"flex-col gap1rem"}>
            <CatalogSubHeading
              title={"Available Regions"}
              fontSize="1.25rem"
              color="#02273C"
            />
            <div>
              <Grid container spacing={0}>
                <Grid item xs={6} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} md={6}>
                      <li className="regionListli">{"North America"}</li>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <img
                    alt={"northAmerica"}
                    width={400}
                    height={200}
                    src={NorthAmerica}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="specificRegionDetils">
            <CatalogSubHeading
              title={"Specific Region Details"}
              fontSize="1.25rem"
              color="#02273C"
            />

            <div>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Region />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Region Details
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    North America: This region provides low-latency access to
                    customers located in the United States and Canada. It offers
                    high availability and scalability, making it an ideal choice
                    for enterprise customers with large-scale workloads.
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Local Compliance
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    Fully compliant with regulatory requirements, including:
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{
                      color: "#577687",
                      lineHeight: "1.5rem",
                      paddingLeft: 2,
                    }}
                  >
                    <ul>
                      <li>GDPR</li>
                      <li>ISO 27000</li>
                      <li>SOC-2</li>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Typography
          variant="h4"
          fontSize="2.125rem"
          fontWeight={600}
          component="div"
          sx={{ color: "#0A85C7", lineHeight: "1.875rem" }}
        >
          Related Products
        </Typography>
        <div style={{ maxWidth: "80%" }}>
          <CardView
            variant="simple"
            data={CARD_DATA}
            handleNavigateTo={handleNavigateTo}
          />
          <Button
            variant="text"
            id={"viewMore"}
            onClick={() => {
              navigate("/public-catalog");
            }}
          >
            View More
          </Button>
        </div>
      </div>
    </>
  );
};

export default PowerBIOverView;
