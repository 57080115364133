import { Button } from "@ssce/ui-shared-library";
import { FC, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import moment from "moment";
import ConfirmModal from "../ConfirmModal";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";

import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetchProjectsOverview,
  fetchProjectResources,
  fetchProjectsRoles,
  fetchProjectsUsers,
  setShowEditProjectDesc,
  setShowEditProjectName,
  deleteProjectById,
  editProjectById,
  setShowDuplicateProject,
  duplicateProject,
} from "../../../../redux/projectsManagement/actions";
import Acl from "../../subcomponents/Acl";
import { PERMISSION_LIST } from "../../../../constants";

const mapStateToProps = (state: AppState) => ({
  selectedProject: state.projectsManagement.selectedProject,
  projectData: state.projectsManagement.projectsOverviewDetails,
  showEditProjectDesc: state.projectsManagement.showEditProjectDesc,
  showDuplicateProject: state.projectsManagement.showDuplicateProject,
  projectResources: state.projectsManagement.projectResources,
  projectRoles: state.projectsManagement.projectRoles,
});

const mapDispatchToProps = {
  fetchProjectsOverview,
  deleteProjectById,
  fetchProjectResources,
  fetchProjectsRoles,
  fetchProjectsUsers,
  setShowEditProjectDesc,
  setShowEditProjectName,
  editProjectById,
  setShowDuplicateProject,
  duplicateProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const OverviewInfo: FC<Props> = ({
  selectedProject,
  showEditProjectDesc,
  showDuplicateProject,
  projectResources,
  projectRoles,
  fetchProjectsOverview,
  projectData,
  deleteProjectById,
  fetchProjectResources,
  fetchProjectsRoles,
  fetchProjectsUsers,
  setShowEditProjectDesc,
  setShowEditProjectName,
  editProjectById,
  setShowDuplicateProject,
  duplicateProject,
}) => {
  const [op, setOp] = useState(false);
  const openThis = () => setOp(true);
  const closeThis = () => setOp(false);

  const [duplicateProjectname, setDuplicateProjectname] = useState("");
  const [error, setError] = useState(false);

  const [description, setDescription] = useState(projectData.description);

  const handleEditSave = () => {
    let payload = { name: projectData.name, description: description };
    setShowEditProjectDesc(false);
    editProjectById(selectedProject.projectId, payload);
  };

  useEffect(() => {
    setShowEditProjectDesc(false);
  }, [setShowEditProjectDesc]);

  useEffect(() => {
    setDescription(projectData.description);
  }, [projectData, showEditProjectDesc]);

  const handleProjectDuplicate = () => {
    setError(false);
    const payload = {
      name: duplicateProjectname,
      description: projectData.description,
      roles: projectRoles.map((item) => {
        return { id: item.id };
      }),
      resources: projectResources.map((item) => {
        return { id: item.instanceId };
      }),
    };

    if (duplicateProjectname.trim().length === 0) {
      setError(true);
      return false;
    }

    duplicateProject(payload);
  };

  useEffect(() => {
    fetchProjectsOverview(selectedProject.projectId);
    fetchProjectResources(selectedProject.projectId);
    fetchProjectsRoles(selectedProject.projectId);
    fetchProjectsUsers(selectedProject.projectId);
  }, [
    fetchProjectsOverview,
    fetchProjectResources,
    fetchProjectsRoles,
    fetchProjectsUsers,
    selectedProject.projectId,
  ]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "flex-end",
          justifyContent: "flex-start",
          gap: "1%",
        }}
      >
        <ConfirmModal
          open={showDuplicateProject}
          title={"Please Confirm"}
          onRequestCancel={() => setShowDuplicateProject(false)}
          onRequestConfirm={handleProjectDuplicate}
        >
          <>
            <Typography
              style={{
                margin: 2,
                fontSize: 20,
                fontWeight: 500,
                lineHeight: "1.5rem",
              }}
            >
              You are{" "}
              <span style={{ textDecoration: "underline", fontWeight: 900 }}>
                duplicating
              </span>{" "}
              <span style={{ fontWeight: 700, color: "#0468B1" }}>
                {projectData.name}{" "}
              </span>{" "}
              After duplicating this project you will find it within your{" "}
              <span style={{ fontWeight: 900 }}>Projects</span> table where you
              can then customize it.{" "}
              <span style={{ fontWeight: 900 }}>
                Please confirm to continue.
              </span>
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "0.625rem 0 1.25rem 0",
                gap: "0.5rem",
              }}
            >
              <TextField
                style={{ background: "#F6FDFF", width: 200 }}
                id="outlined-helperText"
                label="Project Name"
                inputProps={{ maxLength: 40 }}
                defaultValue=""
                size="small"
                error={error}
                value={duplicateProjectname}
                helperText={error ? "Project Name is required." : ""}
                onChange={(e) => {
                  setDuplicateProjectname(e.target.value);
                }}
              />
              <Typography
                style={{
                  color: "#02273C",
                  fontFamily: "Roboto Light",
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: "1.5rem",
                }}
              >
                Please add a project name above
              </Typography>
            </div>
          </>
        </ConfirmModal>
        <ConfirmModal
          open={op}
          title={"Please Confirm"}
          onRequestCancel={closeThis}
          onRequestConfirm={() => deleteProjectById(selectedProject.projectId)}
        >
          <Typography
            style={{
              margin: 2,
              fontSize: 20,
              fontWeight: 500,
              lineHeight: "1.5rem",
            }}
          >
            You are{" "}
            <span style={{ textDecoration: "underline", fontWeight: 900 }}>
              removing
            </span>{" "}
            the{" "}
            <span style={{ fontWeight: 700, color: "#0468B1" }}>
              {projectData.name}
            </span>{" "}
            from the platform. This action cannot be undone and you will need to
            recreate the project to add it back if you change your mind.{" "}
            <span style={{ fontWeight: 900 }}>
              Are you sure you want to continue?
            </span>
          </Typography>
        </ConfirmModal>
      </div>
      <div style={{ marginBottom: 30 }}>
        <Typography
          style={{
            letterSpacing: 0.15,
            fontWeight: 300,
            fontSize: 16,
            color: "#02273C",
          }}
        >
          Description
          <Acl permission={PERMISSION_LIST.PROJECTS_WRITE}>
            {!showEditProjectDesc && (
              <CreateIcon
                sx={{
                  "&:hover": { color: "#0A85C7" },
                  cursor: "pointer",
                  color: "#02273C",
                }}
                style={{ height: 19, width: 19, marginLeft: 10 }}
                id={"editProject"}
                onClick={() => {
                  setShowEditProjectName(false);
                  setShowEditProjectDesc(true);
                }}
              />
            )}
          </Acl>
        </Typography>
        {!showEditProjectDesc && (
          <div style={{ display: "flex", width: "90%" }}>
            {" "}
            <Typography style={{ fontWeight: 600, fontSize: 14 }}>
              {projectData.description}
            </Typography>
          </div>
        )}
        {showEditProjectDesc && (
          <div style={{ display: "flex" }}>
            <TextField
              sx={{ width: "100%", height: 104, background: "#F6FDFF" }}
              id="outlined-helperText"
              minRows={3}
              multiline={true}
              background-color="white"
              value={description}
              inputProps={{ maxLength: 200 }}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />

            <div
              style={{
                display: "flex",
                padding: "0.625rem 0.625rem 0 0.625rem",
                columnGap: "0.625rem",
                alignItems: "end",
              }}
            >
              <CheckIcon
                sx={{
                  "&:hover": { color: "#0A85C7" },
                  width: 34,
                  height: 34,
                  cursor: "pointer",
                }}
                id={"editSave"}
                onClick={handleEditSave}
              />
              <CloseIcon
                id={"closeProject"}
                sx={{
                  "&:hover": { color: "#0A85C7" },
                  width: 34,
                  height: 34,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowEditProjectDesc(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div>
        <Typography
          style={{
            letterSpacing: 0.15,
            fontWeight: 300,
            fontSize: 16,
            color: "#02273C",
          }}
        >
          Date Added
        </Typography>

        <Typography style={{ fontWeight: 600, fontSize: 14, marginBottom: 30 }}>
          {moment(projectData.createdTimestamp).format("lll")}
        </Typography>
      </div>
      <div
        style={{
          justifyContent: "center",
          width: "100%",
          display: "flex",
          gap: "4%",
        }}
      >
        <Acl permission={PERMISSION_LIST.PROJECTS_WRITE}>
          <Button
            onClick={() => {
              setShowDuplicateProject(true);
              setDuplicateProjectname("");
            }}
            id={"duplicateProject"}
            variant="default"
          >
            Duplicate Project
          </Button>
        </Acl>
        <Acl permission={PERMISSION_LIST.PROJECTS_EXECUTE}>
          <Button onClick={openThis} id={"deleteProject"} status="alert">
            Delete Project
          </Button>
        </Acl>
      </div>
    </>
  );
};

export default connector(OverviewInfo);
