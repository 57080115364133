import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Card from "@mui/material/Card";
import CatalogSubHeading from "./CatalogSubHeading";

export const CatalogRelevantResources: React.FC<{
  title?: string;
  listItems: {
    icon: React.ReactNode;
    heading?: string;
    description?: string;
  }[];
}> = ({ title = "Relevant Resources", listItems }) => {
  const rendercards = () => {
    return listItems.map((item) => {
      return (
        <Grid item xs={4} md={4} key={item.heading}>
          <Card
            sx={{
              minHeight: 210,
              padding: "2rem 1rem 1rem 1rem",
              borderRadius: "0.5rem",
              boxShadow:
                "0rem 0.0625rem 0.3125rem 0rem rgba(2, 39, 60, 0.20), 0rem 0.1875rem 0.0625rem 0rem rgba(2, 39, 60, 0.12), 0rem 0.125rem 0.125rem 0rem rgba(2, 39, 60, 0.14)",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap={1}
            >
              <Grid item xs={2} md={2}>
                {item.icon}
              </Grid>
              <Grid item xs={8} md={8}>
                <Typography
                  variant="h6"
                  style={{ color: "#02273C", lineHeight: "1.5rem" }}
                >
                  {item.heading}
                </Typography>
              </Grid>
              <Grid item xs={1} md={1}>
                <OpenInNewIcon />
              </Grid>
            </Grid>
            <Typography
              variant="body1"
              style={{
                color: "#02273C",
                lineHeight: "1.5rem",
                padding: "1rem",
              }}
            >
              {item.description}
            </Typography>
          </Card>
        </Grid>
      );
    });
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" gap={"3rem"}>
        {title && <CatalogSubHeading title={title} />}
        <Grid container spacing={2}>
          {rendercards()}
        </Grid>
      </Grid>
    </>
  );
};

export default CatalogRelevantResources;
