import * as React from "react";
import Box from "@mui/material/Box";
import { Table, Button } from "@ssce/ui-shared-library";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CompanyModal from "./companyModal";
import {
  companyList,
  CompanyListData,
} from "../ProvisionService/provisionDataMeshHooks";

interface RiskthinkingSelectedCompTableProps {
  rows: CompanyListData[];
  updateRows: (rows: CompanyListData[]) => void;
}

const RiskthinkingSelectedCompTable: React.FC<
  RiskthinkingSelectedCompTableProps
> = ({ rows, updateRows }) => {
  const [selectedRows, setSelectedRows] = React.useState<readonly number[]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const handleDeleteRow = (rowId?: number) => {
    const updatedData = rowId
      ? rows.filter((row) => row.id !== rowId)
      : rows.filter((row) => !selectedRows.includes(row.id));
    setSelectedRows([]);
    updateRows(updatedData);
  };

  const handleCheckboxClick = (checked) => {
    setSelectedRows(checked);
  };

  const handleAddCompanies = (companies: CompanyListData[]) => {
    const updatedData = Array.from(new Set([...rows, ...companies]));
    updateRows(updatedData);
    setIsModalOpen(false);
  };

  const getUniqueValues = (
    data: CompanyListData[],
    key: keyof CompanyListData,
  ) => {
    return Array.from(new Set(data.map((item) => item[key]))).map((value) => ({
      label: String(value),
      value: String(value),
    }));
  };

  const sectorFilters = getUniqueValues(rows, "sector");
  const headquartersFilters = getUniqueValues(rows, "headquarters");

  return (
    <Box sx={{ width: "100%", marginTop: "-6rem" }}>
      {rows.length > 0 && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            variant="secondary"
            className="MS-AlgoRisk-csv-download-button"
            id={"delete"}
            onClick={() => handleDeleteRow()}
            isDisabled={selectedRows.length === 0}
          >
            Delete <DeleteForeverIcon sx={{ marginLeft: "0.625rem" }} />
          </Button>

          <Button
            id={"addCompanies"}
            variant="default"
            className="Riskthinking-add-company-button"
            onClick={() => setIsModalOpen(true)}
          >
            Add Companies
          </Button>
        </div>
      )}
      <Table
        title={"Selected Companies"}
        data={rows}
        id={"Table Title"}
        showSearchFilter
        showPagination
        showCheckboxes
        onCheckboxChange={(checked) => {
          handleCheckboxClick(checked);
        }}
        emptyTableContent={
          <div className="openModalButtonContainer">
            <p className="tableMobalText">
              Nothing to display. Use the button below to start adding companies
              to this table.
            </p>
            <Button
              id="addCompaniesButton"
              onClick={() => setIsModalOpen(true)}
              size="medium"
            >
              Add Companies
            </Button>
          </div>
        }
        columns={[
          {
            title: "Company Name",
            dataIndex: "name",
            sorter: true,
          },
          {
            title: "Sector",
            dataIndex: "sector",
            sorter: true,
            filters: sectorFilters,
            showFilterOnLeft: true,
            onFilter: (value, record) => record.sector === value,
          },
          {
            title: "Headquarters",
            dataIndex: "headquarters",
            sorter: true,
            filters: headquartersFilters,
            onFilter: (value, record) => record.headquarters === value,
          },
          {
            title: "Action",
            dataIndex: "id",
            render: (id) => (
              <ClearIcon
                sx={{ color: "var(--primary)", cursor: "pointer" }}
                onClick={() => handleDeleteRow(id)}
              />
            ),
          },
        ]}
      />
      <CompanyModal
        open={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        handleAddCompanies={handleAddCompanies}
        companyList={companyList}
        addedCompanies={rows.map((row) => row.id)}
      />
    </Box>
  );
};

export default RiskthinkingSelectedCompTable;
