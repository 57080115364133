import TextField from "@mui/material/TextField";
import { Alert } from "@mui/material";
import background from "./background.png";
import { FC, useEffect, useState } from "react";
import axios from "axios";
import LoginLoader from "./loader_form";
import LoginHeader from "./LoginHeader";
import styled from "styled-components";
import { Button } from "@ssce/ui-shared-library";

const LoginScreen = styled.section`
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url(${background});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom right;
`;

const LoginContainer = styled.div`
  max-height: 80vh;
  overflow: auto;
  width: 25rem;
  padding: 4rem;
  background-color: #fff;
  box-shadow: 0 0.938rem 1.875rem 0.625rem rgba(0, 0, 0, 0.2);
  background: var(--background);

  h4 {
    font-size: 2.125rem;
    font-weight: 600 !important;
    font-family: var(--typefaceFamily);
    margin-bottom: 2.25rem;
    line-height: var(--typefaceLineheightMd);
    margin-top: 2rem;
    margin-bottom: 3.625rem;
    letter-spacing: normal;
    color: var(--typefaceColorDark);
  }
`;

const StyledTextField = styled(TextField)`
  margin-top: 0.875rem !important;

  label {
    font-size: 1.5rem;
    line-height: normal;
    color: rgba(0, 0, 0, 0.6);
    background-color: var(--background);
    padding-right: 0.25rem;
    font-weight: bold;
    font-family: var(--typefaceFamily);
    letter-spacing: normal;
  }

  fieldset {
    border-radius: 0.25rem;
  }

  p {
    margin: 0.25rem 0 0 0.125rem;
    letter-spacing: normal;
  }
`;

const LoginButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
`;

const Subheading = styled.p`
  font-size: 0.938rem;
  color: var(--typefaceColorDark);
`;
interface LoginFormProps {
  signinRedirect: (params?: {
    extraQueryParams: { kc_idp_hint: string };
  }) => void;
}

const LoginForm: FC<LoginFormProps> = (props) => {
  const [userName, setUserName] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [validEmail, setValidEmail] = useState(false);

  const continueClickHandler = () => {
    let idp_hint;

    try {
      idp_hint = userName.split("@")[1];
    } catch {
      setInvalid(true);
      return;
    }

    sessionStorage.setItem("domain_name", String(idp_hint));
    let url = `/public/iam/domain/${idp_hint}`;
    setInvalid(false);
    setLoading(true);

    axios
      .get(url)
      .then((res) => {
        let idp_alias_name = res.data.idpAliasName || "";
        sessionStorage.setItem("fresh_login", "true");
        props.signinRedirect({
          extraQueryParams: { kc_idp_hint: idp_alias_name },
        });
      })
      .catch((err) => {
        const errorMessage = !err.response
          ? "Server is down"
          : `${err.response.status}: ${err.response.statusText}`;
        setErrorMessage(`Error: ${errorMessage}`);
        setInvalid(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    setValidEmail(
      /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,16}$/.test(userName),
    );
  }, [userName]);

  const onKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      event.preventDefault();
      continueClickHandler();
    }
  };

  if (loading)
    return (
      <LoginScreen>
        {" "}
        <LoginContainer>
          <LoginHeader />
          <LoginLoader showLoader={loading} />{" "}
        </LoginContainer>
      </LoginScreen>
    );
  return (
    <LoginScreen>
      <LoginContainer>
        <LoginHeader />
        <h4>Welcome to SS&C Everywhere!</h4>
        <Subheading>Please enter your company email to login</Subheading>
        <StyledTextField
          id="outlined-required"
          label={"Email"}
          defaultValue=""
          helperText="Enter company provided email to be directed to your company portal"
          InputLabelProps={{ shrink: true }}
          autoComplete={""}
          onChange={(e) => setUserName(e.target.value)}
          error={invalid}
          autoFocus
          variant="outlined"
          inputProps={{ onKeyPress: onKeyPress }}
        />
        {invalid && <Alert severity={"error"}>{errorMessage}</Alert>}
        <LoginButtonWrapper>
          <Button
            id={"CONTINUE"}
            isDisabled={!validEmail}
            onClick={() => {
              localStorage.setItem("activeMenuItem", "Dashboard");
              continueClickHandler();
            }}
          >
            CONTINUE
          </Button>
        </LoginButtonWrapper>
      </LoginContainer>
    </LoginScreen>
  );
};

export default LoginForm;
