import styled from "styled-components";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button as MUIButton } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import RefreshIcon from "@mui/icons-material/Refresh";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Chip from "@mui/material/Chip";
import ErrorIcon from "@mui/icons-material/Error";
import FileUploadIcon from "@mui/icons-material/FileUpload";

const UploadButtonFirst = styled(MUIButton)`
  font-weight: 500;
  font-size: 0.75rem;
  padding: 0.2rem 0rem !important;
  font-family: "Roboto Medium";
  color: white !important;

  label {
    font-weight: 500;
    font-size: 0.75rem;
    border-radius: 0.25rem;
    padding: 0.5rem 1rem;
    font-family: "Roboto Medium";
    background-color: #0a85c7;
    color: #f6fdff;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background: var(--blue700);
      transform: scale(1.05);
    }
  }
`;

const UploadAnImage = styled(FileUploadIcon)`
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.7rem;
`;

const StyledStepper = styled(Stepper)``;

const StyledStep = styled(Step)`
  & .MuiStepLabel-root .Mui-completed {
    color: #3cc13b;
  }
`;

const StyledStepLabel = styled(StepLabel)``;

const StyledCloudUploadIcon = styled(CloudUploadIcon)`
  width: 3.625rem !important;
  height: 3.625rem !important;
  color: #0979b5;
`;

const StyledRefreshIcon = styled(RefreshIcon)``;

const StyledFormHelperText = styled(FormHelperText)``;

const Refresh = styled(MUIButton)`
  font-weight: 700;
  font-size: 0.875rem;
  padding: 0.75rem 1.5rem;
  text-decoration: underline;
  font-family: "Roboto Bold";
`;

const FlexContainer = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  margin-left: 0;
  width: calc(100% - 2.5rem);
`;

const PublishGeneralContainer = styled.div`
  display: flex;
  gap: 4.5rem;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  overflow: auto;
  padding: 0.313rem 3.125rem 0.313rem 0.625rem;
  display: flex;
  flex-direction: column;
  gap: 1.3125rem;
`;

const Span = styled.span`
  color: #02273c;
`;

const ActiveStepDiv = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  width: 100%;
`;

const ActiveStepTwoDiv = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  width: 100%;
`;

const PublishContainer = styled.div`
  display: flex;
  gap: 5rem;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  border: 0.063rem solid #b6c7d1;
`;

const TextContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  width: 100%;
`;

const Title = styled(Typography)`
  font-variant: h6;
  text-align: left;
  font-size: 1.25rem !important;
  font-weight: 700 !important;
  color: #0a85c7 !important;
  line-height: 1.5rem !important;
`;

const Description = styled(Typography)`
  font-variant: body1;
  text-align: left;
  font-size: 1rem;
  font-family: "Roboto Light" !important;
  font-weight: 300;
  color: #02273c;
  line-height: 1.5rem !important;
`;

const Text = styled(TextField)`
  width: 100%;
  border-radius: 0.25rem;
`;

const TextContainerTwo = styled.div`
  display: flex;
  gap: 2.5rem;
  flex-direction: column;
  width: 100%;
`;

const InfoContainer = styled.div`
  display: flex;
  gap: 5rem;
  width: 100%;
`;

const InfoColumn = styled.div`
  display: flex;
  gap: 3rem;
  flex-direction: column;
  width: 100%;
`;

const PreviewContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: center;
`;

const PreviewTitle = styled.div`
  font-variant: body1;
  text-align: left;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  font-family: "Roboto Medium" !important;
  color: #0a85c7;
  line-height: 1.5rem;
`;

const LogoPreview = styled.img`
  width: 10.438rem;
  height: 7.5rem;
`;

const PreviewContainerOne = styled.div`
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  align-items: center;
`;

const SelectLogo = styled(Select)`
  border-radius: 0.25rem;
  max-width: 32rem;
`;

const UploadContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
  padding: 1rem 0;
  width: 32.375rem;
  background-color: #ebf8fd;
  border: 0.063rem dashed #5badd9;
  border-radius: 0.25rem;
`;

const InfoColumnTwo = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  align-items: center;
`;

const DragAndDropContainer = styled(Typography)`
  font-variant: h6;
  text-align: left;
  font-size: 1rem;
  font-weight: 700;
  font-family: "Roboto Bold" !important;
  line-height: 1.5rem;
  color: #02273c;
`;

const SelectFile = styled.div`
  font-size: 1rem;
  font-weight: 700;
  font-family: "Roboto Bold" !important;
  line-height: 1.5rem;
  color: #0a85c7 !important;
  margin-left: 0.313rem !important;
  display: inline-flex;
`;

const Label = styled(InputLabel)`
  label {
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    font-family: "Roboto Medium";
    background-color: #0a85c7;
    color: #f6fdff;
    border-radius: 0.25rem;
    display: none;
    gap: 1rem;
    cursor: pointer;
  }
`;

const InputPublishLabel = styled(InputLabel)``;

const SimpleInput = styled(Input)`
  display: none !important;
`;

const UploadButton = styled(MUIButton)`
  font-weight: 500;
  font-size: 0.75rem;
  padding: 0.5rem 1rem;
  font-family: "Roboto Medium";

  label {
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.5rem 1rem;
    font-family: "Roboto Medium";
    background-color: #0a85c7;
    color: #f6fdff;
    border-radius: 0.25rem;
    display: none;
    gap: 1rem;
    cursor: pointer;
  }
`;

const FormatInfo = styled.div`
  font-size: 0.75rem;
  padding: 0.313rem;
  font-weight: 400;
  line-height: 1rem;
  color: #02273c;
`;

const PublishContainerTags = styled.div`
  display: flex;
  gap: 4rem;
  flex-direction: column;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  border: 0.063rem solid #b6c7d1;
`;

const SelectContainer = styled.div`
  display: flex;
  gap: 5rem;
`;

const SelectTitle = styled.div`
  font-size: 1rem;
  font-weight: 700 !important;
  line-height: 1.5rem !important;
  color: #577687 !important;
`;

const SelectDescription = styled.div`
  font-size: 1rem !important;
  font-family: "Roboto Light" !important;
  font-weight: 300;
  line-height: 1.5rem !important;
  color: #02273c !important;
`;

const MenuItems = styled.div`
  width: 100%;
`;

const StyledMenuItem = styled(MenuItem)``;

const SelectDropdown = styled(Select)`
  width: 100%;
  border-radius: 0.25rem;
`;

const PublishManagementContainer = styled.div`
  display: flex;
  gap: 4rem;
  flex-direction: column;
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  border: 0.063rem solid #b6c7d1;
`;

const Form = styled(FormControl)`
  margin: 1;
  min-width: 100%;
`;

const ButtonsDiv = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ButtonsInnerDiv = styled.div`
  display: flex;
  gap: 0.625rem;
  align-items: center;
`;

const SecondActiveStep = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const DetailsContainer = styled.div`
  display: flex;
  gap: 4rem;
  flex-direction: column;
`;

const DetailsInnerContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
`;

const DetailsLogoContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

const Division = styled.div``;

const SecondImage = styled.img`
  height: 6.25rem;
  width: 8.563rem;
`;

const ServiceOuterDiv = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding-top: 1.25rem;
`;

const ServiceInnerDiv = styled.div`
  display: flex;
  gap: 1rem;
`;

const ServiceName = styled(Typography)`
  font-variant: h4;
  text-align: left;
  font-size: 2.125rem;
  font-weight: 600;
  line-height: 2.25rem;
  color: #0979b5;
`;

const ChipOne = styled(Chip)`
  background-color: #e7f3f9 !important;
  color: #0a85c7 !important;
`;

const ChipTwo = styled(Chip)`
  background-color: #efe6fd !important;
  color: #6200ee !important;
`;

const ChipThree = styled(Chip)`
  background-color: #ffe5f0 !important;
  color: #d72f77 !important;
`;

const ApiTitle = styled(Typography)`
  font-variant: h6;
  text-align: left;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5rem;
  color: #355263;
`;

const ApiDescription = styled(Typography)`
  font-variant: body1;
  text-align: left;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem;
  color: #02273c;
  padding: 0.625rem 0;
`;

const ProjectContainer = styled.div`
  padding: 1rem 1.5rem;
  border-radius: 0.25rem;
  border: 0.063rem solid #b6c7d1;
`;

const CustomContainer = styled.div`
  margin-left: 0;
`;

const CustomTableHead = styled(TableHead)``;

const CustomTableBody = styled(TableHead)``;

const CustomTableContainer = styled.div`
  margin-bottom: 1.25rem;
  margin-left: 0.625rem;
  margin-top: 0.625rem;
  border: none;
  box-shadow: none;
`;

const StyledTableContainer = styled(TableContainer)``;

const CustomTable = styled(Table)`
  size: small;
  aria-label: "a dense table";
  border-bottom: 0.063rem solid primary;
  box-shadow: none;
`;

const TableRowHeader = styled(TableRow)`
  background-color: #0a85c7;
  height: 3rem;
  box-shadow: none;
`;

const CustomTableCell = styled(TableCell)`
  align: left;
  color: black;
  box-shadow:
    inset 0 -0.125rem 0 #075e8d,
    inset -0.125rem 0 0 #b3d9ee;
  align-items: left;
  padding: 0.625rem 1.875rem;
  border: 0.006rem solid #c0ebf8;
  text-align: left;
  box-shadow: none;
`;

const CustomTableRow = styled(TableRow)`
  border-left: 0.125rem solid #c0ebf8;
  box-shadow: none;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 3rem 0;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ErrorBox = styled.div`
  display: flex;
  position: relative;
  width: 39.063rem;
  gap: 0.5rem;
  padding: 0.625rem 1rem;
  align-items: center;
  background-color: #fac1c1;
  border: 0.063rem solid #fac1c1;
  border-radius: 0.25rem;
`;

const CustomErrorIcon = styled.div`
  width: 1.5rem;
  height: 1.5rem;
  margin-bottom: auto;
  color: #f03737;
`;

const StyledErrorIcon = styled(ErrorIcon)``;

const ErrorText = styled.div`
  display: flex;
  flex-direction: column;
`;

const ErrorTitle = styled(Typography)`
  font-size: 1rem;
  color: #651717;
`;

const ErrorMessage = styled(Typography)`
  font-size: 1rem;
  color: #651717;
`;

export {
  Stepper,
  UploadButtonFirst,
  Label,
  UploadAnImage,
  Division,
  StyledTableContainer,
  StyledCloudUploadIcon,
  StyledRefreshIcon,
  StyledFormHelperText,
  StyledMenuItem,
  SimpleInput,
  StyledStepper,
  StyledStep,
  StyledStepLabel,
  FlexContainer,
  PublishGeneralContainer,
  InnerContainer,
  Span,
  ActiveStepDiv,
  ActiveStepTwoDiv,
  PublishContainer,
  TextContainer,
  Title,
  Description,
  Text,
  TextContainerTwo,
  InfoContainer,
  InfoColumn,
  PreviewContainer,
  PreviewTitle,
  LogoPreview,
  PreviewContainerOne,
  SelectLogo,
  UploadContainer,
  InfoColumnTwo,
  DragAndDropContainer,
  SelectFile,
  InputPublishLabel,
  UploadButton,
  FormatInfo,
  PublishContainerTags,
  SelectContainer,
  SelectTitle,
  SelectDescription,
  MenuItems,
  SelectDropdown,
  PublishManagementContainer,
  Form,
  ButtonsDiv,
  ButtonsInnerDiv,
  Refresh,
  SecondActiveStep,
  DetailsContainer,
  DetailsInnerContainer,
  DetailsLogoContainer,
  SecondImage,
  ServiceOuterDiv,
  ServiceInnerDiv,
  ServiceName,
  ChipOne,
  ChipTwo,
  ChipThree,
  ApiTitle,
  ApiDescription,
  ProjectContainer,
  CustomContainer,
  CustomTableContainer,
  CustomTable,
  CustomTableCell,
  CustomTableRow,
  TableRowHeader,
  CustomTableBody,
  CustomTableHead,
  ButtonContainer,
  ErrorContainer,
  ErrorBox,
  CustomErrorIcon,
  StyledErrorIcon,
  ErrorText,
  ErrorTitle,
  ErrorMessage,
};
