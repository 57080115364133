import * as React from "react";
import { Button } from "@ssce/ui-shared-library";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent";
import { useNavigate } from "react-router-dom";

const TaxBrightlineReport: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/ssnc.png"}
        title={"Tax Brightline"}
        subTitle={`SS&C Tax Brightline`}
        contents={
          "SS&C Tax Brightline is a comprehensive, technology-powered solution designed to simplify tax compliance, reporting, and analytics for fund managers. By providing real-time data and analytics, it enables managers to optimize their investment strategies and manage their tax exposure year-round, ensuring compliance in a constantly evolving tax landscape."
        }
        tags={[
          {
            label: "Tax Compliance",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Web Application",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs
        title={"Tax Brightline"}
        reportTableName="New CSV Report"
        instanceName={"SS&C Tax Brightline"}
      />
    </>
  );
};
export default TaxBrightlineReport;
