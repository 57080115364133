import * as React from "react";
import { useState, useEffect } from "react";
import { ProvisionSupersetHeader } from "./ProvisionSupersetHeader";
import { useProvisionDataMeshHooks } from "../ProvisionService/provisionDataMeshHooks";
import {
  InstanceNameProvision,
  SelectRegionProvision,
  UserManagementProvision,
  SetupBillingProvision,
  ProvisionInstanceButton,
} from "../ProvisionService/provisionDataMeshComponents";
import { ProvisionSuccessfulPage } from "../ProvisionService/provisionSuccessfulPage";
import { ErrorNotification } from "@ssce/ui-shared-library";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";

const regions = [
  { label: "UK", value: "UK" },
  { label: "CentralUS", value: "CentralUS" },
];

const CatalogSupersetProvision: React.FC = () => {
  const {
    createInstances,
    fetchCatalogID,
    setInstanceName,
    setProjectId,
    setIsLoading,
    hasError,
    catalogId,
    instanceName,
    projectId,
    projects,
    hasProjectIdError,
    hasInstanceNameError,
    isLoading,
  } = useProvisionDataMeshHooks();

  const [isProvisionSuccessful, setIsProvisionSuccessful] = useState(false);
  const [plan, setPlan] = useState("Large");
  const [billing, setBilling] = useState("Quarterly");

  const [selectedRegion, setSelectedRegion] = React.useState("UK");

  const handleProvisionInstance = async () => {
    const success = await createInstances({
      instanceName,
      projectId,
      catalogId,
      applicationName: "superset",
      region: selectedRegion,
    });
    let successAsBool = success as unknown as boolean;
    setIsLoading(false);
    setIsProvisionSuccessful(!!successAsBool);
  };

  useEffect(() => {
    fetchCatalogID("superset");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateAnotherInstance = () => {
    setIsProvisionSuccessful(false);
    setInstanceName("");
    setSelectedRegion("UK");
    setProjectId("");
  };

  return (
    <>
      <div className="provisionContainer" style={{}}>
        {hasError && <ErrorNotification />}
        {!isProvisionSuccessful ? (
          !isLoading ? (
            <>
              <ProvisionSupersetHeader />
              <InstanceNameProvision
                value={instanceName}
                hasInstanceNameError={hasInstanceNameError}
                handleInstanceNameChange={(value: string) =>
                  setInstanceName(value)
                }
              />
              <SelectRegionProvision
                selectedRegion={selectedRegion}
                handleRegionChange={(value: string) => setSelectedRegion(value)}
                regions={regions}
              />
              <UserManagementProvision
                handleProjectChange={(projectId: string) =>
                  setProjectId(projectId)
                }
                projects={projects}
                projectId={projectId}
                hasProjectIdError={hasProjectIdError}
              />

              <SetupBillingProvision
                plan={plan}
                handlePlanChange={(value: string) => setPlan(value)}
                handleBillingChange={(value: string) => setBilling(value)}
                billing={billing}
              />
              <ProvisionInstanceButton
                handleProvisionInstance={handleProvisionInstance}
              />
            </>
          ) : (
            <LoadingAnimation />
          )
        ) : (
          <ProvisionSuccessfulPage
            onCreateAnotherInstance={onCreateAnotherInstance}
          />
        )}
      </div>
    </>
  );
};

export default CatalogSupersetProvision;
