import {
  Description,
  Division,
  PublishContainer,
  TextContainer,
  Title,
  Text,
} from "../publishApi.styles";

export const DescriptionInput = ({ description, onFormChange }) => {
  return (
    <PublishContainer>
      <TextContainer>
        <Division>
          <Title>Description</Title>
          <Description>
            Kindly provide a description for your API; if a description was
            already provided when the API data product was created in Data Mesh,
            it will be displayed below for your review and potential
            modification.
          </Description>
        </Division>
        <Division>
          <Text
            multiline
            rows={5}
            id="instance-name"
            placeholder="Type Here.."
            value={description}
            onChange={(e) => {
              onFormChange("description", e.target.value);
            }}
          />
        </Division>
      </TextContainer>
    </PublishContainer>
  );
};
