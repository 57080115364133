import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";

interface Props {
  children: any;
  name: string;
  iconHeader?: React.ReactNode;
  iconHeaderClickhandler?: (event: React.SyntheticEvent) => void;
  ligthMode?: boolean;
}

const ControlledAccordions: React.FC<Props> = ({
  children,
  name,
  iconHeader,
  iconHeaderClickhandler,
  ligthMode,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (_, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      sx={{
        "&": { borderBottom: "0.063rem solid #B6C7D1" },
        "&.Mui-expanded": { margin: "0" },
      }}
      style={
        ligthMode
          ? { boxShadow: "none" }
          : { backgroundColor: "transparent", boxShadow: "none" }
      }
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ color: "#0468B1", margin: "0.125rem , 0" }}
          />
        }
        aria-controls="panel1bh-content"
        id="panel1bh-header"
        sx={{
          "&.Mui-expanded": { minHeight: "0" },
          "& .MuiAccordionSummary-content": { margin: 0 },
          "& .MuiAccordionSummary-content.Mui-expanded": { margin: 0 },
        }}
      >
        {iconHeader ? (
          <IconButton
            sx={{ color: "black" }}
            aria-label="delete"
            onClick={iconHeaderClickhandler}
          >
            {iconHeader}
          </IconButton>
        ) : null}
        <Typography
          variant="subtitle1"
          sx={{
            margin: "0.625rem 1.25rem",
            color: "#0979B5",
            fontFamily: "Roboto Medium",
            fontSize: 14,
          }}
        >
          {name}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

export default ControlledAccordions;
