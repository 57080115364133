import {
  Heading,
  Subheading,
  P,
  ListItem,
  Bold,
} from "../../Styles/Documentation.styles";
import { OnThisPageHelper } from "../../OnThisPageHelper";

const anchorTagsArr = [
  { id: "1", subHeadingString: "Connect to Your Data with Ease" },
  { id: "2", subHeadingString: "ChatGPT-powered Query Assistance" },
  { id: "3", subHeadingString: "Publish as an API" },
];

export const PowerOfChatGPTCopy = () => {
  return (
    <>
      <OnThisPageHelper anchorTagsArr={anchorTagsArr} />
      <Heading>
        SS&C Everywhere Introduces Data Mesh: Making SQL Queries More Intuitive
        with the Power of ChatGPT
      </Heading>
      <i>Last Updated: Aug 2024</i>
      <P>
        At SS&C Technologies, we're always striving to make complex tasks
        simpler for our users. Today, we're thrilled to unveil a brand new
        feature as part of our Data Mesh offering on the SS&C Everywhere
        platform: the integration of chatGPT into the query editor. This feature
        is designed to aid users, even those without extensive technical
        expertise, to write complex SQL queries across multiple data sources.
        Let's dive into how this exciting new tool works.
      </P>
      <Subheading id="1">Connect to Your Data with Ease</Subheading>
      <P>
        Our Data Mesh offering makes it straightforward to connect to all your
        data sources, ensuring a seamless experience from the outset. All you
        need to do is choose the data source you'd like to connect to, and
        you're ready to start querying.
      </P>
      <Subheading id="2">ChatGPT-powered Query Assistance</Subheading>
      <P>
        Once you've connected your data, our integrated chatGPT will assist you
        in the query editor step.{" "}
      </P>
      <P>
        Look for the <Bold>chatGPT feature</Bold> drawer on the right-hand side
        of the application. It provides a chatbox interface, and if you're ever
        in doubt, you can click on "learn more" to access resources like this
        blog to help you out.
      </P>
      <P>Here's how it works:</P>
      <ul>
        <ListItem>
          <Bold>Data Safety is Our Priority: </Bold>
          Before we delve deeper, it's essential to highlight a crucial aspect
          of our chatGPT integration. This feature never looks at your actual
          data. It only accesses the database, table, and column names. Your
          data integrity and privacy are always preserved.
        </ListItem>
        <ListItem>
          <Bold>Business Terminology to SQL: </Bold>
          Instead of wrangling with SQL's syntax, all you need to do is ask in
          regular business terms. For instance, "Show me the sales figures from
          the North region for the last quarter." The platform then converts
          your ask into an SQL query by understanding the available tables and
          columns.
        </ListItem>
        <ListItem>
          <Bold>Instant Feedback: </Bold>
          Once chatGPT has prepared the SQL statement based on your ask, you can
          review it. If it's what you were looking for, simply click "Yes" to
          paste the query into the editor and execute it. If it's not quite
          right, click "No". This feedback mechanism is not just user-friendly
          but also helps us continually refine and enhance the chatGPT prompts,
          ensuring an ever-improving user experience.
        </ListItem>
      </ul>
      <Subheading id="3">Publish as an API</Subheading>
      <P>
        Once you're satisfied with your query, the Data Mesh offering allows you
        to publish the query as an API. This is perfect for business users who
        need instant access to specific datasets without delving into the
        nitty-gritty of SQL.
      </P>
      <P>
        The SS&C Everywhere platform is always evolving to bring more power and
        simplicity to our users. With the integration of chatGPT into our Data
        Mesh offering, we're confident that even users without SQL expertise can
        navigate, query, and utilize their data more efficiently than ever. Give
        it a try today, and experience the future of data querying!
      </P>
    </>
  );
};
