import check_circle from "../../../../assets/check_circle.png";
import { DualButtons } from "../DualButtons/DualButtons";
import { useNavigate } from "react-router-dom";

export const Success = (props) => {
  const navigate = useNavigate();
  const h1 = props.h1 || "Success!";
  const h2 =
    props.h2 ||
    "Instance created successfully! Please note that your instance is currently undergoing provisioning, which may take some time. Please allow up to 30 minutes for the provisioning process to complete.";
  const h3 = props.h3;
  const showDualButtons = props.showDualButtons !== false;

  return (
    <>
      <div className="success-content">
        <img src={check_circle} alt="success" />
        <div>
          <h4>{h1}</h4>
          <h5>{h2}</h5>
          {h3 && <h3>{h3}</h3>}
        </div>
        {props.alert && props.alert}
      </div>
      {showDualButtons && (
        <DualButtons
          leftBtnTxt="Go back to Catalog"
          leftOnClick={() => {
            navigate("/catalog");
          }}
          rightBtnTxt="Go to My Services"
          rightOnClick={() => {
            if (props.rightOnClick) props.rightOnClick();
            else navigate("/my-services");
          }}
        />
      )}
    </>
  );
};
