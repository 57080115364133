import { FC } from "react";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Button } from "@ssce/ui-shared-library";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

type Props = {
  setActiveMenuItem: (value: string) => void;
};

const SuccessMessage: FC<Props> = ({ setActiveMenuItem }: Props) => {
  const navigate = useNavigate();

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "1.5rem",
          flexDirection: "column",
          marginLeft: "0",
          width: "calc(100% - 2.5rem)",
        }}
      >
        <div
          style={{ display: "flex", gap: "4.5rem", flexDirection: "column" }}
        >
          <div
            style={{
              display: "flex",
              gap: "2.25rem",
              flexDirection: "column",
              paddingTop: "9.375rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <CheckCircleIcon
                sx={{ width: "9.875rem", height: "9.875rem", color: "#37B036" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
                flexDirection: "column",
                justifyContent: "center",
                justifyItems: "center",
              }}
            >
              <Typography
                variant="body1"
                fontSize="2.125rem"
                fontWeight={400}
                component="div"
                align="left"
                lineHeight={"2.25rem"}
                textAlign={"center"}
                color="#0A85C7"
              >
                Your API has been published
              </Typography>
              <Typography
                variant="body1"
                fontSize="1.25rem"
                fontWeight={400}
                component="div"
                align="left"
                lineHeight={"1.5rem"}
                textAlign={"center"}
                color="#02273C"
              >
                Your users can now see this API in the Private API Catalog or
                within their My Services tabs for those associated with a
                project.
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "1rem",
              padding: "3rem 0",
            }}
          >
            <Button
              variant="secondary"
              id={"returnToManageListings"}
              onClick={() => {
                navigate("/manage-listings");
              }}
            >
              Return to Manage Listings
              <KeyboardReturnIcon />
            </Button>

            <Button
              variant="default"
              id={"viewApiCatalog"}
              onClick={() => {
                navigate("/private-api-catalog");
                setActiveMenuItem("Catalog");
              }}
            >
              View API in Catalog
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessMessage;
