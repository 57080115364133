import { ReturnNavigation } from "./ReturnNavigation";
import { Wrapper, NavItem, Nav} from "./Styles/CompoundDocumentationNav.styles";

interface CompoundDocumentationNavProps {
  onBlogClick: (blog: any) => void;
}

export const CompoundDocumentationNav = ({
  onBlogClick,
}: CompoundDocumentationNavProps) => {
  return (
    <Wrapper>
      <ReturnNavigation />
      <Nav>
        <NavItem
          onClick={() => {
            onBlogClick(null);
          }}
        >
          Getting Started
        </NavItem>
        <NavItem
          onClick={() => {
            onBlogClick(1);
          }}
        >
          Connecting Your Data
        </NavItem>
        <NavItem
          onClick={() => {
            onBlogClick(2);
          }}
        >
          Transforming Your Queries into APIs
        </NavItem>
        <NavItem
          onClick={() => {
            onBlogClick(3);
          }}
        >
          Mastering Queries with SS&C Technology
        </NavItem>
        <NavItem
          onClick={() => {
            onBlogClick(4);
          }}
        >
           SQL Queries and the Power of ChatGPT
        </NavItem>
        <NavItem
          onClick={() => {
            onBlogClick(5);
          }}
        >
          How to Create a Data Product
        </NavItem>
      </Nav>
    </Wrapper>
  );
};
