import * as React from "react";
import { useState } from "react";
import { Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./AlgoPerformanceReport.css";
import { useNavigate } from "react-router-dom";
import AlgoPerformanceOverView from "./AlgoPerformanceOverView";
import AlgoPerformanceTechnicalSpecifications from "./AlgoPerformanceTechnicalSpecifications";
import AlgoPerformancePlansAndFeatures from "./AlgoPerformancePlansAndFeatures";
import { DocumentationCard } from "../../Documentation";
import styled from "styled-components";
import AlgoRiskImage from "../../../../assets/AlgoRisk.png";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

const DocumentationCardWrapper = styled.div`
  width: 33%;
`;

const AlgoPerformanceReport: React.FC = () => {
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={AlgoRiskImage}
        title={"SS&C Performance Service"}
        contents={
          "This cost-effective service leverages your existing holdings data within the SS&C platform. It delivers accurate performance, returns, and return contributions metrics through reports or an intuitive web-based dashboard."
        }
        tags={[
          {
            label: "Risk Management",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Analytics Service",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() =>
            navigate(
              "/public-catalog/ss&c-algorithmics-performance-reports/provision",
            )
          }
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Technical Specifications" value="3" />
            <Tab label="Documentation" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "2.5rem", padding: 0 }}>
          <AlgoPerformanceOverView />
        </TabPanel>

        <TabPanel value="2" sx={{ marginTop: "2.5rem", padding: 0 }}>
          <AlgoPerformancePlansAndFeatures />
        </TabPanel>
        <TabPanel value="3" sx={{ marginTop: "2.5rem", padding: 0 }}>
          <AlgoPerformanceTechnicalSpecifications />
        </TabPanel>
        <TabPanel value="4">
          <DocumentationCardWrapper>
            <DocumentationCard
              key={1}
              backgroundImage={AlgoRiskImage}
              title={"Introducing Algo Risk Reports"}
              description={
                "SS&C Technologies, a name synonymous with pioneering innovation, introduces the SS&C Everywhere Data Mesh."
              }
              subTitle={" "}
              openInNew
              onClick={() => {
                navigate(
                  "/documentation/announcing-the-launch-of-ss&c-algo-risk-reports-solution-in-everywhere-marketplace",
                );
              }}
            />
          </DocumentationCardWrapper>
        </TabPanel>
      </TabContext>
      <div className="flex-row justifyContent-center gap1rem">
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() =>
            navigate(
              "/public-catalog/ss&c-algorithmics-performance-reports/provision",
            )
          }
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </div>
    </>
  );
};
export default AlgoPerformanceReport;
