import * as React from "react";
import Typography from "@mui/material/Typography";
import "./TaxBrightline.css";
import TaxBrightlinePricing from "./TaxBrightlinePricing";

const TaxBrightlinePlansAndFeatures: React.FC = () => {
  return (
    <>
      <div className={"gap4rem flex-col"}>
        <div className={"gap1rem flex-col"}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Pricing Plans and Features
          </Typography>
          <div className={"gap2rem flex-row"}>
            <div className={"gap1rem flex-col"}>
              <Typography
                variant="h6"
                fontSize="1.25rem"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                SS&C Tax Brightline includes
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                {" "}
                <ul>
                  <li>Investor-level tax compliance reporting.</li>
                  <li>Intelligent automation for accuracy.</li>
                  <li>Tax-aware strategy development tools.</li>
                  <li>End-to-end integrated platform.</li>
                  <li>Expert tax team support.</li>
                  <li>Real-time tax data and analytics.</li>
                  <li>Licensed software deployment option.</li>
                  <li>Comprehensive outsourced tax services.</li>
                </ul>
              </Typography>
            </div>
          </div>
          <TaxBrightlinePricing title={"Plan Options & Details"} />
        </div>
      </div>
    </>
  );
};

export default TaxBrightlinePlansAndFeatures;
