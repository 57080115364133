import { Modal } from "@ssce/ui-shared-library";
import validator from "@rjsf/validator-ajv8";
import React, { useRef } from "react";
import styled from "styled-components";
import { Form } from "@rjsf/mui";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    subtitle2: {
      fontSize: "0.875rem",
    },
  },
});

const RunNowModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  background-color: var(--neutralBackground);
`;

const uiSchema = {
  "ui:submitButtonOptions": {
    norender: true,
  },
};

export type RunParameters = {
  [key: string]: any;
};

type RunNowModalProps = {
  onModalClose: () => void;
  runJob: (jobParams: RunParameters) => void;
  runParameters: RunParameters;
};

const RunNowModal: React.FC<RunNowModalProps> = ({
  onModalClose,
  runJob,
  runParameters,
}) => {
  const formRef = useRef<any>(null);

  const handleSubmit = (data: any) => {
    runJob(data.formData);
  };

  const handleError = (errors: any) => {
    console.log("Form errors:", errors);
  };

  return (
    <Modal
      title={"Run Job"}
      onClose={onModalClose}
      onPrimaryButtonClick={() => {
        if (formRef.current) {
          formRef.current.submit();
        }
      }}
      isOpen={true}
      showFooter
      saveButtonText={"Run"}
    >
      <RunNowModalContainer>
        <ThemeProvider theme={theme}>
          <Form
            ref={formRef}
            schema={runParameters}
            uiSchema={uiSchema}
            validator={validator}
            onSubmit={handleSubmit}
            onError={handleError}
          />
        </ThemeProvider>
      </RunNowModalContainer>
    </Modal>
  );
};

export default RunNowModal;
