import { useState, useEffect } from "react";
import axios from "axios";
import { USER_ENDPOINT_BASE_URL } from "../../../../constants";
import { ApiKey } from "./apiKeys";
import moment from "moment";
import { useValidation } from "@ssce/ui-shared-library";

export type NewApiKey = {
  key: string;
  value: string;
};

export const useApiKeysHooks = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [newApiKey, setNewApiKey] = useState<NewApiKey>();
  const [hasApiKeyNameError, setHasApiKeyNameError] = useState("");
  const [apiKeyName, setApiKeyName] = useState<string>("");

  const { validateForm, errors } = useValidation();
  const regex = /^[a-zA-Z 0-9_-]*$/;
  const validation = [
    {
      apiKeyName: [
        (value) => {
          if (!value || value.trim().length === 0) {
            return "Key name is required.";
          }
          if (!value?.match(regex)) {
            return "The name should only include letters, numbers, underscores, and dashes.";
          }
        },
      ],
    },
  ];

  useEffect(() => {
    getApiKeys();
  }, []);

  const getApiKeys = () => {
    setIsLoading(true);
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/keys`)
      .then((res) => {
        setApiKeys(res.data.keys);
        setIsLoading(false);
        setIsError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  };
  const createApiKey = () => {
    const payload = {
      keyName: apiKeyName,
      createDate: moment().format("YYYY-MM-DD"),
      expiryDate: moment().add(1, "years").format("YYYY-MM-DD"),
    };
    setHasApiKeyNameError("");
    const isValid = validateForm({ apiKeyName: apiKeyName }, validation);
    if (isValid) {
      axios
        .post(`${USER_ENDPOINT_BASE_URL}/keys`, {
          ...payload,
        })
        .then((res) => {
          getApiKeys();
          setApiKeyName("");
          setIsError(false);
          setNewApiKey({ key: res.data.keyName, value: res.data.keyValue });
        })
        .catch((error) => {
          if (error.response.status === 409) {
            setHasApiKeyNameError("Key name already exists");
          } else {
            setIsError(true);
          }
          setIsLoading(false);
        });
    }
  };
  const deleteApiKey = (id: string, name: string) => {
    axios
      .delete(`${USER_ENDPOINT_BASE_URL}/keys/${id}/${name}`)
      .then(() => {
        getApiKeys();
        setIsError(false);
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  };
  useEffect(() => {
    errors?.apiKeyName?.length > 0 &&
      setHasApiKeyNameError(errors?.apiKeyName[0]);
  }, [errors]);
  return {
    deleteApiKey,
    createApiKey,
    setApiKeyName,
    isLoading,
    isError,
    apiKeys,
    newApiKey,
    hasApiKeyNameError,
    apiKeyName,
  };
};
