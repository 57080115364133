import React, { useMemo } from "react";
import ReactFlow from "reactflow";
import "../../../../../../node_modules/reactflow/dist/style.css";
import { Node } from "../Nodes";
import {
  getAlgoRiskNodes,
  getAlgoRiskEdges,
} from "./workflowAlgoRiskReportComponents";
import {
  getAlgoPerformanceNodes,
  getAlgoPerformanceEdges,
} from "./workflowPerformanceReportComponents";

export const WorkflowViewerContent: React.FC<{
  widthOfPanel: any;
  isAlgoRiskReport: any;
}> = ({ widthOfPanel, isAlgoRiskReport }) => {
  const nodes = isAlgoRiskReport
    ? getAlgoRiskNodes(widthOfPanel / 2)
    : getAlgoPerformanceNodes(widthOfPanel / 5);
  const edges = isAlgoRiskReport
    ? getAlgoRiskEdges()
    : getAlgoPerformanceEdges();

  const nodeTypes = useMemo(() => ({ node: Node }), []);
  return (
    <div
      className="nowheel"
      style={{
        position: "relative",
        height: "93.75rem",
        backgroundColor: "#F1F8FB",
        width: "100%",
      }}
    >
      <ReactFlow
        zoomOnDoubleClick={false}
        panOnScroll={true}
        panOnDrag={false}
        zoomOnScroll={false}
        nodes={nodes as any}
        edges={edges}
        nodeTypes={nodeTypes}
      />
    </div>
  );
};
