import * as React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentViewContext } from "../../../ComponentWithContext";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import { Button } from "@ssce/ui-shared-library";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

const TarvosReport: React.FC = () => {
  const navigate = useNavigate();
  const { viewData } = useContext(CurrentViewContext);

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/tarvos.png"}
        title={"Tarvos LLM-aaS"}
        subTitle={`Tarvos, ${viewData.region}`}
        contents={
          "Tarvos is a cutting-edge Large Language Model (LLM) as a service, hosted securely within your internal infrastructure, giving you full control over your data. With Tarvos, your data never leaves your environment, ensuring the highest standards of privacy while unlocking insights, automating processes, and enhancing decision-making."
        }
        tags={[
          {
            label: "BI",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs title={"Tarvos"} instanceName={"Tarvos"} />
    </>
  );
};
export default TarvosReport;
