import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";

interface Props {
  children: any;
  name: string;
  email: string;
  iconHeader?: React.ReactNode;
  halfWidth?: boolean;
  iconHeaderClickhandler?: (event: React.SyntheticEvent) => void;
}

const ControlledAccordions: React.FC<Props> = ({
  children,
  name,
  email,
  iconHeader,
  iconHeaderClickhandler,
  halfWidth = true,
}) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleChange = (panel: string) => (_, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Accordion
        expanded={expanded === name}
        onChange={handleChange(name)}
        sx={{
          "&": {
            borderTop: halfWidth ? "0 solid #B6C7D1" : "0.063rem solid #B6C7D1",
          },
          "&.Mui-expanded": { margin: "0" },
        }}
        style={{
          backgroundColor: "transparent",
          boxShadow: "none",
          width: halfWidth ? "47%" : "100%",
        }}
      >
        <AccordionSummary
          style={{ backgroundColor: "#F6FDFF" }}
          expandIcon={
            <ExpandMoreIcon
              style={{ color: "#0468B1", margin: "0.125rem , 0" }}
            />
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            "& .MuiAccordionSummary-content": { margin: 0 },
            "& .MuiAccordionSummary-content.Mui-expanded": { margin: 0 },
          }}
        >
          {iconHeader ? (
            <IconButton
              sx={{ color: "black" }}
              aria-label="delete"
              id={"delete"}
              onClick={iconHeaderClickhandler}
            >
              {iconHeader}
            </IconButton>
          ) : null}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginRight: "0.125rem",
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                color: "#0979B5",
                margin: 0,
                marginLeft: 2,
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              {name}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                width: "100%",
                textDecoration: "underline",
                color: "#577687",
                margin: 0,
                marginLeft: 2,
                fontWeight: 500,
                fontSize: 14,
              }}
            >
              {email}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ backgroundColor: " #F6FDFF" }}>
          {children}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default ControlledAccordions;
