import React from "react";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import styled from "styled-components";

const Contents = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
`;
const SubTitle = styled.div`
  color: #02273c;
  font-weight: 600;
  font-size: 1.25rem;
`;
const Title = styled.div`
  font-size: 2.125rem;
  font-weight: 600;
  line-height: 1;
  color: #0468b1;
  display: flex;
`;
const MyServicesCardHeaderContainer = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  grid-column-gap: 1rem;
  grid-template-columns: max-content 1fr;
  margin: 1rem;
`;
const Image = styled.img`
  width: 10rem;
  grid-row: 1/5;
  align-self: center;
`;
export const MyServicesCardHeader: React.FC<{
  imageSource: string;
  title: string;
  subTitle?: string;
  contents: string;
  tags?: { label: string; style: any }[];
  children?: any;
}> = ({ imageSource, contents, title, subTitle, tags, children }) => {
  return (
    <MyServicesCardHeaderContainer>
      <Image src={imageSource} alt={`${title} logo`}></Image>
      <Title>
        {title}
        <Stack
          sx={{ marginLeft: "1rem" }}
          spacing={1}
          direction="row"
          color="primary"
        >
          {tags?.map((tag) => (
            <Chip key={tag.label} label={tag.label} style={tag.style} />
          ))}
        </Stack>
      </Title>
      <SubTitle>{subTitle}</SubTitle>
      <Contents>{contents}</Contents>
      <div style={{ display: "flex", columnGap: "1rem" }}>{children}</div>
    </MyServicesCardHeaderContainer>
  );
};
