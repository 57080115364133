import { useState } from "react";
import { NavLink } from "react-router-dom";
import CardTravelTwoToneIcon from "@mui/icons-material/CardTravelTwoTone";
import LanTwoToneIcon from "@mui/icons-material/LanTwoTone";
import PaymentTwoToneIcon from "@mui/icons-material/PaymentTwoTone";
import InsertDriveFileTwoToneIcon from "@mui/icons-material/InsertDriveFileTwoTone";
import ContactSupportTwoToneIcon from "@mui/icons-material/ContactSupportTwoTone";
import DataThresholdingTwoToneIcon from "@mui/icons-material/DataThresholdingTwoTone";
import SettingsIcon from "@mui/icons-material/Settings";
import DashboardTwoToneIcon from "@mui/icons-material/DashboardTwoTone";
import TopicTwoToneIcon from "@mui/icons-material/TopicTwoTone";
import { baseUrl } from "src/constants";
import {
  MobileWrapper,
  MobileNavModal,
  MobileSlider,
  SidebarIcon,
  SliderLi,
} from "./Nav.styles";

type mobileNavProps = {
  setMobileMenuIsOpen: (value: boolean) => void;
};

const MobileNav = ({ setMobileMenuIsOpen }: mobileNavProps) => {
  const [catalogSliderIsVisible, setCatalogSliderIsVisible] = useState(false);
  const [settingsSliderIsVisible, setSettingsSliderIsVisible] = useState(false);

  return (
    <MobileNavModal>
      <MobileWrapper>
        <NavLink
          to="/dashboard"
          data-menu-code="Dashboard"
          id={"Dashboard"}
          onClick={() => {
            setMobileMenuIsOpen(false);
          }}
        >
          <SidebarIcon>
            <DashboardTwoToneIcon /> Dashboard
          </SidebarIcon>
        </NavLink>
        <NavLink
          to="/my-services"
          data-menu-code="myservices"
          id={"myServices"}
          onClick={() => {
            setMobileMenuIsOpen(false);
          }}
        >
          <SidebarIcon>
            <CardTravelTwoToneIcon /> My Services
          </SidebarIcon>
        </NavLink>
        <SliderLi>
          <div
            onClick={() => {
              setCatalogSliderIsVisible(!catalogSliderIsVisible);
            }}
          >
            <SidebarIcon>
              <span id="slider">
                <TopicTwoToneIcon /> Catalog
              </span>
            </SidebarIcon>
          </div>
        </SliderLi>
        <MobileSlider>
          {catalogSliderIsVisible && (
            <>
              <NavLink
                to="/public-catalog"
                data-menu-code="dataconnectivity"
                id={"publicCatalog"}
                onClick={() => {
                  setMobileMenuIsOpen(false);
                }}
              >
                <div>Public Catalog</div>
              </NavLink>
              <NavLink
                to="/private-api-catalog"
                data-menu-code="dataconnectivity"
                id={"privateApiCatalog"}
                onClick={() => {
                  setMobileMenuIsOpen(false);
                }}
              >
                <div>Private API Catalog</div>
              </NavLink>
              <NavLink
                to="/digital-workers"
                data-menu-code="dataconnectivity"
                id={"digitalWorkers"}
                onClick={() => {
                  setMobileMenuIsOpen(false);
                }}
              >
                <div>Digital Workers</div>
              </NavLink>
            </>
          )}
        </MobileSlider>

        <NavLink
          to="/my-workflows"
          data-menu-code="my-workflows"
          id={"workflowEditor"}
        >
          <SidebarIcon>
            <LanTwoToneIcon /> Workflow Editor
          </SidebarIcon>
        </NavLink>

        <NavLink
          to={`${baseUrl}/datamesh/`}
          data-menu-code="datamesh"
          id={"dataMesh"}
          target="_blank"
        >
          <SidebarIcon>
            <DataThresholdingTwoToneIcon /> Data Mesh
          </SidebarIcon>
        </NavLink>

        <NavLink
          to="/documentation"
          data-menu-code="documentation"
          id={"documentation"}
          onClick={() => {
            setMobileMenuIsOpen(false);
          }}
        >
          <SidebarIcon>
            <InsertDriveFileTwoToneIcon />
            Documentation
          </SidebarIcon>
        </NavLink>

        <NavLink
          to="/billing"
          data-menu-code="billing"
          id={"billing"}
          onClick={() => {
            setMobileMenuIsOpen(false);
          }}
        >
          <SidebarIcon>
            <PaymentTwoToneIcon /> Billing
          </SidebarIcon>
        </NavLink>

        <NavLink
          to="/support"
          data-menu-code="support"
          id={"support"}
          onClick={() => {
            setMobileMenuIsOpen(false);
          }}
        >
          <SidebarIcon>
            <ContactSupportTwoToneIcon /> Support
          </SidebarIcon>
        </NavLink>
        <div>
          <SliderLi
            data-menu-code="dataconnectivity"
            id={"settings"}
            onClick={() => {
              setSettingsSliderIsVisible(!settingsSliderIsVisible);
            }}
          >
            <SidebarIcon>
              <span id="slider">
                <SettingsIcon /> Settings
              </span>
            </SidebarIcon>
          </SliderLi>

          <MobileSlider>
            {settingsSliderIsVisible && (
              <>
                <NavLink
                  to="/user-access-management"
                  data-menu-code="dataconnectivity"
                  id={"userAccessManagement"}
                  onClick={() => {
                    setMobileMenuIsOpen(false);
                  }}
                >
                  <div>User Access Management</div>
                </NavLink>
                <NavLink
                  to="/manage-listings"
                  data-menu-code="dataconnectivity"
                  id={"manageCatalogListings"}
                  onClick={() => {
                    setMobileMenuIsOpen(false);
                  }}
                >
                  <div>Manage Listings</div>
                </NavLink>
                <NavLink
                  to="/apikeys"
                  data-menu-code="dataconnectivity"
                  id={"apiKeys"}
                  onClick={() => {
                    setMobileMenuIsOpen(false);
                  }}
                >
                  <div>API Keys</div>
                </NavLink>
              </>
            )}
          </MobileSlider>
        </div>
      </MobileWrapper>
    </MobileNavModal>
  );
};

export default MobileNav;
