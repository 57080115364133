import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Nifi.css";

const NifiTechnicalSpecifications: React.FC = () => {
  const RenderTechDetails = ({ heading, desc }) => {
    return (
      <div>
        <div className="flex-col">
          <Typography
            variant="h6"
            fontSize="1.25rem"
            sx={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {heading}
          </Typography>
        </div>
        <div className="flex-col padding1rem">
          <Typography
            variant="body1"
            sx={{ color: "#000", lineHeight: "1.5rem" }}
          >
            {desc}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex-col gap4rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Technical Specifications
          </Typography>
          <RenderTechDetails
            heading={"Flow-Based Programming"}
            desc={
              "NiFi allows users to create complex workflows using a simple drag-and-drop interface, leveraging flow-based programming. This capability enables real-time data ingestion, routing, transformation, and system integration."
            }
          />
          <RenderTechDetails
            heading={"Data Provenance"}
            desc={
              "NiFi tracks data provenance, offering complete lineage from origin to destination. This feature ensures transparency and auditability, making it easier to understand data flow and debug issues."
            }
          />
          <RenderTechDetails
            heading={"Back Pressure Control"}
            desc={
              "NiFi employs back pressure control to manage data flow, preventing bottlenecks in the system. This ensures smooth operation even under heavy load, by regulating the rate at which data is ingested and processed."
            }
          />
          <RenderTechDetails
            heading={"Security and Compliance"}
            desc={
              "NiFi supports robust security mechanisms, including SSL/TLS encryption, role-based access control, and auditing. These features help organizations maintain compliance with industry standards and protect sensitive data."
            }
          />
          <RenderTechDetails
            heading={"Integration Capabilities"}
            desc={
              "NiFi easily integrates with a wide range of data sources and destinations, including databases, cloud services, and messaging systems. Its flexible connectors and processors facilitate seamless data flow across different environments."
            }
          />
          <RenderTechDetails
            heading={"Real-Time Analytics"}
            desc={
              "NiFi supports real-time analytics by enabling continuous data processing and routing. This allows organizations to gain immediate insights and react swiftly to changes in their data landscape."
            }
          />
          <RenderTechDetails
            heading={"Scalability and Flexibility"}
            desc={
              "NiFi is designed to scale both horizontally and vertically, adapting to growing data volumes and processing needs. Its flexible architecture ensures that it can handle diverse use cases across various industries."
            }
          />
          <RenderTechDetails
            heading={"Fault Tolerance"}
            desc={
              "NiFi's fault-tolerant architecture ensures high availability and resilience. By replicating data across nodes and supporting automatic recovery, NiFi minimizes downtime and data loss in the event of failures."
            }
          />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default NifiTechnicalSpecifications;
