import { FC } from "react";
import { Carousel as MUICarousel } from "react-responsive-carousel";
import "./Carousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Carousel: FC<{ imageList: { alt: string; src: string }[] }> = ({
  imageList,
}) => {
  const renderImage = () => {
    return imageList.map(({ alt, src }) => {
      return (
        <div className="carousleSlideHolder">
          <img alt={alt} className="carousleSlideImage" src={src} />
        </div>
      );
    });
  };
  return (
    <>
      <MUICarousel className="carousleContainer">{renderImage()}</MUICarousel>
    </>
  );
};

export default Carousel;
