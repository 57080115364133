import { useState, useEffect, useMemo } from "react";
import { jwtDecode } from "jwt-decode";

import { GetUserToken } from "../../../../redux/user/selectors";

export const useAclHooks = (permission: string | undefined) => {
  const [allow, setAllow] = useState<boolean>(false);
  const token = GetUserToken();
  const userRolesList = useMemo(() => {
    const decoded = jwtDecode(token);
    return decoded?.["resource_access"]?.["ssce-mktplc-backend"]?.roles || [];
  }, [token]);

  useEffect(() => {
    setAllow(() =>
      permission === undefined ? true : userRolesList.includes(permission),
    );
  }, [permission, userRolesList]);

  return allow;
};
