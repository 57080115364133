import styled from "styled-components";

export const Nav = styled.nav`
  width: 100%;
  height: 3.75rem;
  position: fixed;
  top: 0;
  left: 0;
  padding-top: 3.75rem;
  background: #edf4f8;
  display: flex;
  z-index: 5;
  box-shadow: 0px 8px 10px 0px #02273c33;
  align-items: center;

  cursor: pointer;
  justify-content: space-between;
`;

export const Image = styled.img`
  height: 50px;
  width: auto;
`;

export const Flex = styled.div`
  display: flex;
  gap: 1rem;
  padding-left: 1rem;

  button {
    margin-right: 1rem;
  }
`;
