import {
  UserData,
  UserOverViewData,
  Roles,
  Projects,
  OverViewData,
  UserInfo,
} from "../../common/dataTypes";

export type UserManagementState = UserManagement;

export interface UserManagement {
  usersList: UserData[];
  selectedUser: UserData;
  userOverviewDetails: UserOverViewData;
  showDetails: Boolean;
  assignedRoles: Roles[];
  assignedProjects: Projects[];
  overviewData: OverViewData;
  userInfo: UserInfo;
  isLoading: boolean;
  isDetailsLoading:boolean;
}

export const createDefaultState = (): UserManagementState => {
  return {
    isLoading: false,
    isDetailsLoading:false,
    usersList: [],
    showDetails: false,
    userOverviewDetails: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      createdTimestamp: 0,
      organisation: "",
    },
    overviewData: {
      id: "",
      firstName: "",
      lastName: "",
      name: "",
      email: "",
      createdTimestamp: 0,
      lastLoginTimestamp: "",
      organisation: "",
      groups: [],
      projects: [],
    },
    userInfo: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      createdTimestamp: 0,
      organisation: "",
      lastLoginTimestamp: "",
      name: "",
      clientId: "",
    },
    selectedUser: {
      id: "",
      firstName: "",
      lastName: "",
      email: "",
      createdTimestamp: 0,
    },
    assignedRoles: [],
    assignedProjects: [],
  };
};
