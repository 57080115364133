import * as React from "react";
import { useState, useEffect } from "react";
import {
  useProvisionDataMeshHooks,
  companyList,
} from "../ProvisionService/provisionDataMeshHooks";
import {
  InstanceNameProvision,
  SelectRegionProvision,
  UserManagementProvision,
  SolutionConfigurationProvision,
  ProvisionInstanceButton,
} from "../ProvisionService/provisionDataMeshComponents";
import { ProvisionSuccessfulPage } from "../ProvisionService/provisionSuccessfulPage";
import { ErrorNotification } from "@ssce/ui-shared-library";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";
import { ProvisionRiskthinkingHeader } from "./ProvisionRiskthinkingHeader";

type TableFundList = {
  id: number;
}[];

const RiskthinkingProvision: React.FC = () => {
  const {
    createInstances,
    fetchCatalogID,
    setInstanceName,
    setProjectId,
    setIsLoading,
    hasError,
    catalogId,
    instanceName,
    projectId,
    projects,
    hasProjectIdError,
    hasInstanceNameError,
    isLoading,
  } = useProvisionDataMeshHooks();

  const [isProvisionSuccessful, setIsProvisionSuccessful] = useState(false);

  const [selectedRegion, setSelectedRegion] = React.useState("NorthAmerica");

  const [addFundList, setAddFundList] = React.useState([]);
  const [tableFundList, setTableFundList] = React.useState<TableFundList>([]);

  const handleProvisionInstance = async () => {
    const success = await createInstances({
      instanceName,
      projectId,
      catalogId,
      applicationName: "risk_thinking",
      region: selectedRegion,
    });
    let successAsBool = success as unknown as boolean;
    setIsLoading(false);
    setIsProvisionSuccessful(!!successAsBool);
  };

  useEffect(() => {
    fetchCatalogID("risk_thinking");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let updateData: any = [...companyList];
    tableFundList.forEach((item) => {
      updateData.forEach((a, index) => {
        if ((item.id, a.id === item.id)) updateData.splice(index, 1);
      });
    });

    setAddFundList(updateData);
  }, [tableFundList]);

  const onCreateAnotherInstance = () => {
    setIsProvisionSuccessful(false);
    setInstanceName("");
    setSelectedRegion("Global");
    setProjectId("");
  };

  return (
    <>
      <div className="algo-performance-provision-container">
        {hasError && <ErrorNotification />}
        {!isProvisionSuccessful ? (
          !isLoading ? (
            <>
              <>
                <ProvisionRiskthinkingHeader />
              </>

              <InstanceNameProvision
                value={instanceName}
                hasInstanceNameError={hasInstanceNameError}
                handleInstanceNameChange={(value: string) =>
                  setInstanceName(value)
                }
              />
              <SelectRegionProvision
                selectedRegion={selectedRegion}
                handleRegionChange={(value: string) => setSelectedRegion(value)}
                regions={[{ label: "Global", value: "Global" }]}
              />
              <UserManagementProvision
                handleProjectChange={(projectId: string) =>
                  setProjectId(projectId)
                }
                projects={projects}
                projectId={projectId}
                hasProjectIdError={hasProjectIdError}
                hideButtons={true}
              />
              <SolutionConfigurationProvision
                addFundList={addFundList}
                tableFundList={tableFundList}
                // @ts-ignore
                updateSelectedFund={(data) =>
                  setTableFundList((item) => [...item, ...data])
                }
                updateRows={(data) => setTableFundList(data)}
                isGlobal={true}
              />
              {/* <ReviewBillingProvision isRiskthinking={true} /> */}
              <ProvisionInstanceButton
                handleProvisionInstance={handleProvisionInstance}
              />
            </>
          ) : (
            <LoadingAnimation />
          )
        ) : (
          <ProvisionSuccessfulPage
            onCreateAnotherInstance={onCreateAnotherInstance}
          />
        )}
      </div>
    </>
  );
};

export default RiskthinkingProvision;
