import styled from "styled-components";
import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";

export const CardContainer = styled.div<{ backgroundImage: string }>`
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 1.25rem;
  height: ${(props) => props.backgroundImage && "100%"};
`;

export const CardWrapper = styled.div<{ inCarousel: boolean }>`
  width: 100%;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.313rem 0.625rem 0.063rem rgba(0, 0, 0, 0.2);
  background-color: ${(props) =>
    props.inCarousel === true ? "#06496D" : "#ebf8fd"};
  position: relative;
  &:hover {
    transform: scale(1.025);
    box-shadow: 0rem 0.313rem 0.625rem 0.063rem rgba(0, 0, 0, 0.3);
    svg {
      color: var(--primary);
    }
  }
`;

export const FullWidthWrapper = styled.div`
  width: 100%;
  grid-column: 1 / -1;
  display: flex;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.625rem;
  width: 50%;
  margin-left: 40%;
`;

export const ImageContainer = styled.div`
  align-self: center;
  padding-left: 1.25rem;
`;

export const BackgroundImage = styled.div<{ backgroundImage: string }>`
  height: 90%;
  width: 100%;
  width: 40%;
  max-width: 37.5rem;
  background-image: url(${(props) =>
    props.backgroundImage ? props.backgroundImage : ""});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
`;

export const Image = styled.img`
  width: 4.688rem;
`;

export const Title = styled.h6<{ inCarousel?: boolean }>`
  font-family: "Roboto", sans-serif;
  font-size: ${(props) => (props.inCarousel ? "2rem" : "1.2rem")};
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0;
  margin: 0;
  color: ${(props) => (props.inCarousel ? "#B3D9EE" : "#02273C")};
  margin: ${(props) => (props.inCarousel ? "1rem 2rem 0.5rem 2rem" : "0")};
`;

export const DescriptionText = styled.div<{ inCarousel?: boolean }>`
  font-family: "Roboto", sans-serif;
  font-size: 0.9rem;
  color: ${(props) => (props.inCarousel ? "#B3D9EE" : "#02273C")};
  padding-top: 0.938rem;
`;

export const SubTitle = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: 0.6rem;
  color: #355263;
`;

export const CardChipRow = styled.div<{ inCarousel?: boolean }>`
  display: flex;
  gap: 0.625rem;
  padding: 0.625rem 0;
  height: ${(props) => (props.inCarousel ? "80%" : "100%")};
  flex-wrap: wrap;
  padding-right: 1rem;
`;

export const Chip = styled.div<{ backgroundColor: string; textColor: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.875rem;
  border-radius: 1rem;
  font-size: 0.75rem;
  background-color: ${(props) => props.backgroundColor};
  color: ${(props) => props.textColor};
  font-weight: bold;
  white-space: nowrap;
  box-sizing: border-box;
  line-height: 0.2;
  text-align: center;
`;

export const chipStyles = [
  { backgroundColor: "#E7F3F9", textColor: "#0A85C7" },
  { backgroundColor: "#EFE6FD", textColor: "#6200EE" },
  { backgroundColor: "#FFE5F0", textColor: "#D72F77" },
];

export const RequiredTitle = styled.h4<{ inCarousel?: boolean }>`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  padding: 0;
  margin: 1rem 0.5rem 2rem 0;
  color: ${(props) => (props.inCarousel ? "#B3D9EE" : "#355263")};
`;

export const RequiredServicesSection = styled.div`
  display: flex;
  flex-direction: row;
  gap: 3rem;
`;

export const ServiceItemWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 1rem;
  margin-top: -1.5rem;
`;

export const ServiceItemImage = styled.img`
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
`;

export const ActionContainer = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1.25rem;
  justify-content: space-between;
  margin: 1.2rem;
`;

export const WorkflowCatalogText = styled.div`
  line-height: 1.5rem !important;
`;

export const WorkflowCatalogIcons = styled.div`
  width: 4.875rem !important;
  height: 4.875rem !important;
`;

export const WorkflowCatalogSetupContainer = styled.div`
  display: flex !important;
  gap: 3rem !important;
  flex-direction: column !important;
`;

export const WorkflowCatalogSetupHeader = styled.div`
  display: flex !important;
  align-items: center !important;
  gap: 2rem !important;
`;

export const WorkflowCatalogSetupHeaderText = styled.div`
  line-height: 1.5rem !important;
`;

export const WorkflowCatalogSetupHeaderImage = styled.img`
  max-width: 100% !important;
  height: auto !important;
`;

export const WorkflowCatalogSetupOptionsContainer = styled.div`
  display: flex !important;
  gap: 1.5rem !important;
  flex-direction: row !important;
`;

export const WorkflowCatalogSetupOption = styled.div`
  display: flex;
  gap: 4.375rem;
`;

export const WorkflowCatalogOptionDetails = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  flex-direction: column;
`;

export const WorkflowCatalogSubtitle = styled.h4`
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 1.3rem;
  padding: 0;
  margin-top: 3rem;
  color: #02273c;
`;

export const WorkflowCatalogCarousel1 = styled(ResponsiveCarousel)`
  .carousel .slide {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    height: 100%;
    margin-bottom: 3rem;
    background-color: #06496d;
    border-radius: 0.5rem;
    img {
      width: 3.125rem;
      background-color: white;
      border-radius: 1.563rem;
      vertical-align: top;
      border: 0;
    }
  }
  .carousel .control-dots .dot {
    width: 1rem;
    height: 1rem;
    background-color: #06496d;
  }
`;
