import { FC, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OverviewInfo from "./OverviewInfo";
import RoleManagement from "./RoleManagement";
import Projects from "./UserProjects";
import PersonIcon from "@mui/icons-material/Person";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";
import { fetchUserOverview } from "../../../../redux/userManagement/actions";

const mapStateToProps = (state: AppState) => ({
  selectedUser: state.userManagement.selectedUser,
  isDetailsLoading: state.userManagement.isDetailsLoading,
});

const mapDispatchToProps = {
  fetchUserOverview,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const UserDetails: FC<Props> = ({
  selectedUser,
  isDetailsLoading,
  fetchUserOverview,
}) => {
  const [value, setValue] = useState("1");
  const handleChange = (_, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    fetchUserOverview(selectedUser.id);
  }, [fetchUserOverview, selectedUser.id]);

  if (isDetailsLoading) return <LoadingAnimation />;
  return (
    <>
      <div style={{ display: "flex" }}>
        <PersonIcon
          style={{
            color: "#0A85C7",
            fontSize: 32,
            marginTop: 8,
            marginLeft: 20,
          }}
        />
        <h1
          style={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 34,
            lineHeight: 0,
            textAlign: "left",
            color: "#0A85C7",
            marginLeft: 10,
          }}
        >
          {selectedUser.firstName} {selectedUser.lastName}
        </h1>
      </div>
      <Box sx={{ width: "100%", height: 700, typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              style={{ marginLeft: "0" }}
            >
              <Tab label="Overview" value="1" />
              <Tab label="Role Management" value="2" />
              <Tab label="Project Information" value="3" />
            </TabList>
          </Box>
          <TabPanel
            value="1"
            style={{
              height: "46.875rem",
              overflowY: "auto",
              display: value === "1" ? "block" : "none",
            }}
          >
            <OverviewInfo />
          </TabPanel>
          <TabPanel
            value="2"
            style={{
              height: "46.875rem",
              overflowY: "auto",
            }}
          >
            <RoleManagement />
          </TabPanel>
          <TabPanel
            value="3"
            style={{
              height: "46.875rem",
              overflowY: "auto",
            }}
          >
            <Projects />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default connector(UserDetails);
