import {
  Heading,
  Subheading,
  P,
  ListItem,
  Image,
  Center,
} from "../../Styles/Documentation.styles";

import Image001 from "../../Images/Computer.png";
import PublishApiImage from "../../Images/PublishApiForm.png";
import CustomizeAPIImage from "../../Images/CustomizeAPI.png";
import Image002 from "../../Images/docImage2.png";
import { OnThisPageHelper } from "../../OnThisPageHelper";

const anchorTagsArr = [
  {
    id: "1",
    subHeadingString:
      "Turning Queries into APIs: The Path to Data Democratization",
  },
  {
    id: "2",
    subHeadingString: "Business Users: The Final Touch for a Perfect API",
  },
  { id: "3", subHeadingString: "Your Data, Your Control" },
  { id: "4", subHeadingString: "Conclusion: Elevate Your Data Game" },
];

export const TransformingQueriesCopy = () => {
  return (
    <>
      <OnThisPageHelper anchorTagsArr={anchorTagsArr} />
      <Heading>
        Publishing Your Data: Transforming Queries into Accessible APIs
      </Heading>
      <i>Last Updated: Aug 2024</i>
      <Center>
        <Image src={Image001} />
      </Center>
      <P>
        Welcome back to our Data Mesh series. We've journeyed through
        connection, job scheduling, and querying. Now, let's venture into the
        final and most empowering step: Publishing. With the SS&C Everywhere
        Data Mesh, your refined data insights can be seamlessly converted into
        robust APIs, opening the door for business users.
      </P>
      <Subheading id="1">
        Turning Queries into APIs: The Path to Data Democratization
      </Subheading>
      <ul>
        <ListItem>
          Select a Query: Choose a previously-defined query as your basis, or
          let your creativity run wild by crafting a new one right within the
          publishing section.
        </ListItem>
        <ListItem>
          Tailor Your API: Define default values for each input parameter,
          decide which parameters are available for users, and highlight any
          fields containing PII or sensitive data to ensure downstream users are
          informed.
        </ListItem>
        <ListItem>
          Craft Your API's Identity: Name your API, provide an insightful
          description, assign a support contact, and control versions to ensure
          clarity and support for end-users.
        </ListItem>
        <ListItem>
          Launch to Marketplace: With a simple click, push your API to the
          marketplace, making it discoverable by business users.
        </ListItem>
      </ul>
      <Center>
        <Image src={PublishApiImage} sizeInRem={30} border />
      </Center>
      <Subheading id="2">
        Business Users: The Final Touch for a Perfect API
      </Subheading>
      <P>
        Upon entering the marketplace, business users will find your data
        product awaiting final touches in the “Manage Listings” Settings of the
        SS&C Everywhere Marketplace:
      </P>
      <ul>
        <ListItem>
          Permissions & Accessibility: Define who gets to see or access the API,
          ensuring data is in the right hands.
        </ListItem>
        <ListItem>
          Make it Shine: Enhance the API's visibility by adding tags, subtitles,
          comprehensive descriptions, and even a logo. The goal? Making the API
          discoverable and resonant with users' business needs.
        </ListItem>
        <ListItem>
          Access & Integration: Business users can request access and, upon
          approval, integrate these APIs seamlessly into their daily operations.
        </ListItem>
      </ul>
      <P>
        <Center>
          <Image src={CustomizeAPIImage} sizeInRem={30} border />
        </Center>
      </P>
      <Subheading id="3">Your Data, Your Control</Subheading>
      <P>
        While the world of data governance can be complex and multi-faceted, one
        principle stands unwavering: data privacy. Rest assured that every API
        you publish remains private to your organization. No sharing amongst
        clients. Your data stays within your domain, always.
      </P>
      <Center>
        <Image src={Image002} sizeInRem={15} />
      </Center>
      <Subheading id="4">Conclusion: Elevate Your Data Game</Subheading>
      <P>
        Publishing with SS&C Everywhere’s Data Mesh is more than just sharing
        data; it's about empowering your entire organization with refined,
        accessible, and meaningful insights. From data connectors to business
        users, we've ensured every step is intuitive, secure, and value-driven.
      </P>
      <P>
        Join us as we continue to transform the data landscape, one query, and
        one API at a time.
      </P>
      <P>
        <i>
          Continue the journey of discovery, integration, and empowerment with
          the SS&C Everywhere Data Mesh tutorials.{" "}
        </i>
      </P>
    </>
  );
};
