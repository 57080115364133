import * as React from "react";
import Typography from "@mui/material/Typography";
import "./TaxBrightline.css";

const TaxBrightlineTechnicalSpecifications: React.FC = () => {
  const RenderTechDetails = ({ heading, desc }) => {
    return (
      <div>
        <div className="flex-col">
          <Typography
            variant="h6"
            fontSize="1.25rem"
            sx={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {heading}
          </Typography>
        </div>
        <div className="flex-col padding1rem">
          <Typography
            variant="body1"
            sx={{ color: "#000", lineHeight: "1.5rem" }}
          >
            {desc}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex-col gap4rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Technical Specifications
          </Typography>
          <RenderTechDetails
            heading={"Real-time Data Processing"}
            desc={
              "Processes and updates tax data in real-time to provide the most current insights for investment decision-making. This ensures that fund managers always have access to the latest information, allowing for more timely and accurate strategic adjustments."
            }
          />

          <RenderTechDetails
            heading={"Advanced Analytics Engine"}
            desc={
              "Utilizes a powerful analytics engine to perform complex calculations and generate detailed tax reports and forecasts. This engine can handle a wide range of tax scenarios, providing deep insights that help optimize tax strategies and enhance investment performance."
            }
          />

          <RenderTechDetails
            heading={"Intelligent Automation"}
            desc={
              "Automates routine tax compliance tasks, reducing manual effort and minimizing the risk of errors in reporting. The system's automation capabilities extend to data entry, validation, and report generation, freeing up valuable time for tax professionals to focus on strategic activities."
            }
          />

          <RenderTechDetails
            heading={"Scalable Architecture"}
            desc={
              "Built on a scalable platform that can handle the needs of small to large fund management organizations. This architecture allows the system to grow with your business, ensuring it can support increasing data volumes and more complex tax scenarios as your needs evolve."
            }
          />

          <RenderTechDetails
            heading={"Secure Data Management"}
            desc={
              "Employs robust security measures, including encryption and access controls, to protect sensitive tax data. The platform adheres to industry best practices and compliance standards, ensuring that your data is secure from unauthorized access and breaches."
            }
          />

          <RenderTechDetails
            heading={"User-friendly Interface"}
            desc={
              "Features an intuitive interface designed for ease of use, allowing quick access to key tax data and functions. The interface is customizable, enabling users to tailor their dashboards and workflows to their specific needs, enhancing productivity and user satisfaction."
            }
          />

          <RenderTechDetails
            heading={"Customizable Reporting"}
            desc={
              "Offers customizable reporting options to meet the specific needs and preferences of different fund managers. Reports can be tailored to various regulatory requirements and investor demands, providing flexibility and precision in tax reporting."
            }
          />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default TaxBrightlineTechnicalSpecifications;
