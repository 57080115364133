import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Msftsql.css";

const MsftsqlTechnicalSpecifications: React.FC = () => {
  const RenderTechDetails = ({ heading, desc }) => {
    return (
      <div>
        <div className="flex-col">
          <Typography
            variant="h6"
            fontSize="1.25rem"
            sx={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {heading}
          </Typography>
        </div>
        <div className="flex-col padding1rem">
          <Typography
            variant="body1"
            sx={{ color: "#000", lineHeight: "1.5rem" }}
          >
            {desc}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex-col gap4rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Technical Specifications
          </Typography>
          <RenderTechDetails
            heading={"Maximum Database Size"}
            desc={
              "Supports databases up to 524 petabytes, providing ample space for large-scale applications."
            }
          />

          <RenderTechDetails
            heading={"Maximum Memory Utilization"}
            desc={
              "Enterprise edition can utilize up to the operating system limit, optimizing performance for high-memory environments."
            }
          />

          <RenderTechDetails
            heading={"CPU Support"}
            desc={
              "Can utilize up to 48 cores per instance on Standard edition and unlimited cores on Enterprise edition, maximizing processing power."
            }
          />

          <RenderTechDetails
            heading={"Storage Formats"}
            desc={
              "Supports both rowstore and columnstore formats, allowing for optimized storage and performance depending on workload requirements."
            }
          />

          <RenderTechDetails
            heading={"Partitioning"}
            desc={
              "Offers table and index partitioning to enhance query performance and manageability on large tables."
            }
          />

          <RenderTechDetails
            heading={"Data Compression"}
            desc={
              "Includes row and page-level compression, reducing storage requirements and improving I/O performance."
            }
          />

          <RenderTechDetails
            heading={"Backup and Restore"}
            desc={
              "Supports differential, incremental, and full backups, as well as backup encryption, ensuring data safety and recovery."
            }
          />

          <RenderTechDetails
            heading={"Replication Types"}
            desc={
              "Provides multiple replication options, including snapshot, transactional, and merge replication for data distribution and redundancy."
            }
          />

          <RenderTechDetails
            heading={"Transaction Isolation Levels"}
            desc={
              "Supports multiple isolation levels, including read uncommitted, read committed, repeatable read, serializable, and snapshot for fine-tuned concurrency control."
            }
          />

          <RenderTechDetails
            heading={"Network Protocols"}
            desc={
              "Supports multiple network protocols such as TCP/IP, Named Pipes, and Shared Memory, offering flexibility in connectivity options."
            }
          />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default MsftsqlTechnicalSpecifications;
