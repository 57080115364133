import { useRef, useEffect, useCallback } from "react";

export const DragAndDrop = ({ handleDragAndDrop, children }) => {
  const ref: { current: any } = useRef(null);

  const handleDragOverEvent = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDropEvent = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      handleDragAndDrop(e);
    },
    [handleDragAndDrop],
  );

  useEffect(() => {
    const currentRef = ref.current;

    if (currentRef) {
      currentRef.addEventListener("dragover", handleDragOverEvent);
      currentRef.addEventListener("drop", handleDropEvent);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("dragover", handleDragOverEvent);
        currentRef.removeEventListener("drop", handleDropEvent);
      }
    };
  }, [handleDragOverEvent, handleDropEvent]);

  return <div ref={ref}>{children}</div>;
};

export default DragAndDrop;
