import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";
import { Success } from "../../subcomponents/Success/Success";
import "./CatalogAlgoRiskReport.css";

const CatalogAlgoRiskSuccess: React.FC = () => {
  return (
    <>
      <Typography className="catalog-algo-risk-success-title">
        Provision Service
      </Typography>
      <Success h3="What would you like to do next?" />
      <Button
        variant="text"
        className="success-link"
        id="createNewInstance"
        onClick={() => {}}
      >
        Create Another Instance of this service
      </Button>
    </>
  );
};
export default CatalogAlgoRiskSuccess;
