import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Sightline.css";
import SightlinePricing from "./SightlinePricing";

const SightlinePlansAndFeatures: React.FC = () => {
  return (
    <>
      <div className={"gap4rem flex-col"}>
        <div className={"gap1rem flex-col"}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Pricing Plans and Features
          </Typography>
          <div className={"gap2rem flex-row"}>
            <div className={"gap1rem flex-col"}>
              <Typography
                variant="h6"
                fontSize="1.25rem"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                SS&C Sightline includes
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                {" "}
                <ul>
                  <li>Easily manipulate and analyze data.</li>
                  <li>Build any report on demand.</li>
                  <li>Consolidate and harmonize data sources.</li>
                  <li>Instant, secure, self-service data access.</li>
                  <li>Streamline data flows efficiently.</li>
                  <li>Foster teamwork with shared data.</li>
                  <li>Tailor visuals for quick insights.</li>
                  <li>Complement or replace existing architecture.</li>
                  <li>Effortlessly build any report.</li>
                  <li>No-code tools for custom reports..</li>
                </ul>
              </Typography>
            </div>
          </div>
          <SightlinePricing title={"Plan Options & Details"} />
        </div>
      </div>
    </>
  );
};

export default SightlinePlansAndFeatures;
