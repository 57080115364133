import * as React from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { Button } from "@ssce/ui-shared-library";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import DocArrow from "../../../../../assets/DocArrow.png";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CardMedia from "@mui/material/CardMedia";
import Image1 from "../../../../../assets/img1.png";
import Image2 from "../../../../../assets/img2.png";
import Image3 from "../../../../../assets/img3.png";
import PrivateAPIHeader from "../Header/PrivateAPIHeader";
import PCAccessRequestError from "../AccessRequest/PCAccessRequestError";
import DataMeshAPI from "../../../../../assets/DatameshAPI.png";
import PCAPICard from "../../../../../assets/PCcard.png";
import "./PCOverview.css";
import Grid from "../../../../main/subcomponents/Grid/Grid";

type Props = {
  data: any;
};

const PCOverview: React.FC<Props> = ({ data }) => {
  const [openRequestAccess, setRequestAccess] = useState(false);
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleAChange = (panel: string) => (_, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleORequestAccess = () => setRequestAccess(!openRequestAccess);

  const catalogData = [data];

  return (
    <>
      {catalogData.map(
        (item: {
          catalogId: any;
          serviceID: string;
          serviceName: string;
          applicationName: string;
          catalogType: string;
          majorVersion: number;
          minorVersion: number;
          shortDescription: any;
          longDescription: any;
          logo: any;
          businessUnit: string;
          labels: { banner: string };
          tags: { function: string; industry: string; serviceType: string };
          region: { us: { regionDetails: string; localCompliance: string[] } };
          hasAccess: boolean;
          hasAddedTo: boolean;
        }) => {
          return (
            <>
              <div className="PC-overview-container">
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  color="primary"
                >
                  Service Overview
                </Typography>
                <div className="PC-service-overview">
                  <div className="PC-service-overview-region">
                    <div className="PC-overview-central">
                      <Typography
                        variant="h4"
                        fontSize="1.5rem"
                        fontWeight={600}
                        component="div"
                        lineHeight={2}
                      >
                        Region Availability
                      </Typography>
                      <Typography variant="h6" fontSize="1rem" lineHeight={4}>
                        Central US
                      </Typography>
                      <img
                        alt={"centralUS"}
                        style={{ width: "90%" }}
                        src="/api/assets/CentralUS.png"
                      ></img>
                    </div>
                    <div className="PC-overview-central">
                      <Typography
                        variant="h4"
                        fontSize="1.5rem"
                        fontWeight={600}
                        component="div"
                        lineHeight={2}
                      >
                        Region/Location Details
                      </Typography>
                      <Typography
                        variant="body1"
                        fontSize="1.125rem"
                        lineHeight={2}
                      >
                        This product is currently hosted in one region:{" "}
                        <b>Central US</b>
                      </Typography>
                      <Typography
                        variant="body1"
                        fontSize="1rem"
                        lineHeight={2}
                      >
                        This product provides love-latency access to customers
                        located in central United States. It offers high
                        availability and scalability, making it an ideal choice
                        for enterprise customers with large-scale workloads.
                      </Typography>
                    </div>
                    <div className="PC-overview-central">
                      <Typography
                        variant="h4"
                        fontSize="1.5rem"
                        fontWeight={600}
                        component="div"
                        lineHeight={2}
                      >
                        Compliance
                      </Typography>
                      <Typography
                        variant="body1"
                        fontSize="1.125rem"
                        lineHeight={2}
                      >
                        Fully compliant with regulatory requirements, including:
                        <ul>
                          <li>
                            <b>ISO</b>
                          </li>
                          <li>
                            <b>ISO 27000</b>
                          </li>
                          <li>
                            <b>SOC-2</b>
                          </li>
                        </ul>
                      </Typography>
                      <Typography
                        variant="body1"
                        fontSize="1.125rem"
                        lineHeight={2}
                      ></Typography>
                    </div>
                  </div>
                </div>
                <div className="PC-API-service">
                  <img
                    alt={"datameshApi"}
                    src={DataMeshAPI}
                    className="PC-API-service-logo"
                  ></img>
                  <div className="PC-API-service-des">
                    <Typography
                      variant="h4"
                      fontSize="2.125rem"
                      fontWeight={600}
                      component="div"
                      color="primary"
                      lineHeight={4}
                    >
                      API Service Information
                    </Typography>
                    <Typography variant="body1" fontSize="1rem" lineHeight={2}>
                      This API was published from an instance of the SS&C
                      Everywhere Data Mesh used by someone in your organization.
                      This API retrieved data from your firms own sources of
                      data and is private to your organization; please exercise
                      caution when using this API and handling any data it
                      returns. You can start using this API and handling anyb
                      data in returns. You can start using this API by
                      requesting an API key in the Settings above or use
                      existing Data Mesh API key if you already have one. Please
                      refer to the API documentation for a more comprehensive
                      guide as to the usage, responses, data formats, and error
                      codes used by this service
                    </Typography>
                  </div>
                </div>
                <div className="PC-billing">
                  <div className="PC-billing-info">
                    <Typography
                      variant="h4"
                      fontSize="2.125rem"
                      fontWeight={600}
                      component="div"
                      color="primary"
                      lineHeight={4}
                    >
                      Billing Information
                    </Typography>
                    <Typography variant="body1" fontSize="1rem" lineHeight={2}>
                      This service is billed based on the amount of usage and
                      storage used. Usage is measured by the number of API
                      requests made, while storage is measured by the amount of
                      data stored in the system. The cost for each usage and
                      storage unit is determined by your pricing plan, which can
                      be found on the Data Mesh listing page. You will receive a
                      monthly invoice based on your usage and storage, which
                      will be charged your designated payment method.
                    </Typography>
                  </div>
                  <img
                    alt={"PCApiCard"}
                    src={PCAPICard}
                    className="PC-billing-logo"
                  ></img>
                </div>
                <PrivateAPIHeader />
                <div
                  style={{
                    display: "flex",
                    gap: "2rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontSize="2.125rem"
                    fontWeight={600}
                    component="div"
                    color="primary"
                  >
                    Relevant Resources
                  </Typography>
                  <Grid minMax="12.5rem">
                    <Card className="PC-resouce-cards">
                      <CardMedia
                        component="img"
                        className="PC-resource-img"
                        image={Image1}
                      />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CardContent>
                          <div>
                            <div className="PC-resource-text-header">
                              <div>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="div"
                                  align="left"
                                  fontWeight={500}
                                  lineHeight={1}
                                  fontSize="1.25rem"
                                >
                                  Getting Started
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="div"
                                  align="left"
                                  fontWeight={400}
                                  lineHeight={1}
                                  fontSize="1rem"
                                >
                                  FAQ
                                </Typography>
                              </div>
                              <div>
                                <img src={DocArrow} alt="arrow"></img>
                              </div>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                variant="body1"
                                component="div"
                                align="left"
                                fontWeight={400}
                                lineHeight={2}
                                fontSize="0.875rem"
                              >
                                Dive in easily with our 'Getting Started
                                Documentation and FAQ
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                      </Box>
                    </Card>
                    <Card className="PC-resouce-cards">
                      <CardMedia
                        component="img"
                        className="PC-resource-img"
                        image={Image2}
                      />
                      <Box>
                        <CardContent>
                          <div>
                            <div className="PC-resource-text-header">
                              <div>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="div"
                                  align="left"
                                  fontWeight={500}
                                  lineHeight={1}
                                  fontSize="1.25rem"
                                >
                                  Requirements
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="div"
                                  align="left"
                                  fontWeight={400}
                                  lineHeight={1}
                                  fontSize="1rem"
                                >
                                  Technical
                                </Typography>
                              </div>
                              <div>
                                <img src={DocArrow} alt="arrow"></img>
                              </div>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                variant="body1"
                                component="div"
                                align="left"
                                fontWeight={400}
                                lineHeight={2}
                                fontSize="0.875rem"
                              >
                                Outlines the essential specifications and tools
                                necessary to effectively use our platform.
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                      </Box>
                    </Card>
                    <Card className="PC-resouce-cards">
                      <CardMedia
                        component="img"
                        className="PC-resource-img"
                        image={Image3}
                      />
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <CardContent>
                          <div>
                            <div className="PC-resource-text-header">
                              <div>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="div"
                                  align="left"
                                  fontWeight={500}
                                  lineHeight={1}
                                  fontSize="1.25rem"
                                >
                                  Regulatory
                                </Typography>
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="div"
                                  align="left"
                                  fontWeight={400}
                                  lineHeight={1}
                                  fontSize="1rem"
                                >
                                  Compliance
                                </Typography>
                              </div>
                              <div>
                                <img src={DocArrow} alt="arrow"></img>
                              </div>
                            </div>
                            <div>
                              <Typography
                                gutterBottom
                                variant="body1"
                                component="div"
                                align="left"
                                fontWeight={400}
                                lineHeight={2}
                                fontSize="0.875rem"
                              >
                                Browse information on laws, regulations,
                                guidelines, and standards effortlessly.
                              </Typography>
                            </div>
                          </div>
                        </CardContent>
                      </Box>
                    </Card>
                  </Grid>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {!item.hasAccess ? (
                      <Button
                        variant="default"
                        id={"requestAccess"}
                        onClick={handleORequestAccess}
                      >
                        Request Access
                      </Button>
                    ) : null}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "1.5rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h4"
                      fontSize="1.5rem"
                      fontWeight={600}
                      component="div"
                      style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                    >
                      FAQ
                    </Typography>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Accordion
                        expanded={expanded === "panel1"}
                        id={"panel1"}
                        onChange={handleAChange("panel1")}
                        sx={{
                          "&": { borderBottom: "0.063rem solid #B6C7D1" },
                          "&.Mui-expanded": { margin: "0" },
                        }}
                        style={{
                          boxShadow: "none",
                          backgroundColor: "inherit",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                          }
                          aria-controls="panel1a-content"
                          id="faq-1"
                          sx={{ padding: 0 }}
                        >
                          <Typography variant="h6">
                            How do I access my data?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            Use of this API requires an API key which can be
                            obtained in the Settings tab. Refer to the API
                            Documentation tab to best understand how to drive
                            this API and browse code samples, quick start
                            guides, and release notes.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel2"}
                        id={"panel2"}
                        onChange={handleAChange("panel2")}
                        sx={{
                          "&": { borderBottom: "0.063rem solid #B6C7D1" },
                          "&.Mui-expanded": { margin: "0" },
                        }}
                        style={{
                          boxShadow: "none",
                          backgroundColor: "inherit",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            expanded === "panel2" ? <RemoveIcon /> : <AddIcon />
                          }
                          aria-controls="panel1a-content"
                          id="faq-2"
                          sx={{ padding: 0 }}
                        >
                          <Typography variant="h6">
                            Can anyone else access my data?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            This is a private API published from a Data Mesh
                            instance owned by your organization. Users from
                            other organizations will not be able to view or
                            access this service. To best protect access your
                            data, ensure that you handle any responses from this
                            API with care and never share your API key with
                            anyone.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel3"}
                        id={"panel3"}
                        onChange={handleAChange("panel3")}
                        sx={{
                          "&": { borderBottom: "0.063rem solid #B6C7D1" },
                          "&.Mui-expanded": { margin: "0" },
                        }}
                        style={{
                          boxShadow: "none",
                          backgroundColor: "inherit",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            expanded === "panel3" ? <RemoveIcon /> : <AddIcon />
                          }
                          aria-controls="panel1a-content"
                          id="faq-3"
                          sx={{ padding: 0 }}
                        >
                          <Typography variant="h6">
                            What is the difference between Marketplace and Data
                            Mesh?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            The Data Mesh is a core service offering on the
                            Marketplace that allows users to federate their
                            organization’s sources of data and publish access
                            points to it for others in their organization to
                            make use of. The marketplace is a service catalog in
                            which users belonging to an organization can view
                            Data Mesh APIs that have been made available, as
                            well as other products and services which may make
                            use of data feeds from the Data Mesh platform.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "panel4"}
                        id={"panel4"}
                        onChange={handleAChange("panel4")}
                        sx={{
                          "&": { borderBottom: "0.063rem solid #B6C7D1" },
                          "&.Mui-expanded": { margin: "0" },
                        }}
                        style={{
                          boxShadow: "none",
                          backgroundColor: "inherit",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            expanded === "panel4" ? <RemoveIcon /> : <AddIcon />
                          }
                          aria-controls="panel1a-content"
                          id="faq-4"
                          sx={{ padding: 0 }}
                        >
                          <Typography variant="h6">
                            How do I get started?
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            If you are able to view this page, then you are able
                            to request access and start using this API today! If
                            you do not yet have an instance of the Data Mesh,
                            please visit the Data Mesh catalog listing on the
                            Marketplace to purchase a subscription.
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                  {openRequestAccess && <PCAccessRequestError />}
                </div>
              </div>
            </>
          );
        },
      )}
    </>
  );
};

export default PCOverview;
