import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";
import Region from "../Regions";
import RocketIcon from "@mui/icons-material/Rocket";
import BoltIcon from "@mui/icons-material/Bolt";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import SecurityIcon from "@mui/icons-material/Security";
import WavingHandOutlinedIcon from "@mui/icons-material/WavingHandOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Carousel from "../../subcomponents/Carousel";
import SupersetConnectDB from "../../../../assets/Superset/SupersetConnectDB.png";
import SupersetCreateChart from "../../../../assets/Superset/SupersetCreateChart.png";
import SupersetDashboard from "../../../../assets/Superset/SupersetDashboard.png";
import SupersetDashboardDetails from "../../../../assets/Superset/SupersetDashboardDetails.png";
import CatalogBenefits from "../CatalogBenefits";
import CatalogKeyFeatures from "../CatalogKeyFeatures";
import CatalogFAQ from "../CatalogFAQ";
import CatalogRelevantResources from "../CatalogRelevantResources";
import Grid from "@mui/material/Grid";
import CatalogSubHeading from "../CatalogSubHeading";
import CardView from "../CardView";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import AllRegion from "../../../../assets/AllRegion.png";

const CARD_DATA = [
  {
    id: 1,
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/SS%26C_Technologies_logo.svg/1200px-SS%26C_Technologies_logo.svg.png",
    serviceName: "SS&C Investment Portfolio",
    minorVersion: 0,
    majorVersion: 1,
    ribbon: "Popular",
    shortDescription:
      "Quick and streamlined access to positions, transactions, and more from your Geneva DB or managed by GlobeOp",
    tags: {
      function: "Cloud Platform",
      industry: "Cross-Industry",
      serviceType: "Data Service",
    },
    plan: "Free Trial Available",
  },
  {
    id: 2,
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/SS%26C_Technologies_logo.svg/1200px-SS%26C_Technologies_logo.svg.png",
    title: "SS&C Advent Genesis",
    minorVersion: 2,
    majorVersion: 1,
    shortDescription:
      "Environmental, Social, and Governance rating data to better understand your investments",
    plan: "Starts at $0.55/hour",
    ribbon: "Experimental",
    tags: {
      function: "Cloud Platform",
      industry: "Banking",
      serviceType: "Accounting",
    },
  },
];

const CatalogSupersetOverView: React.FC = () => {
  const regionList = (data) => {
    return data.map((item) => {
      return (
        <Grid item xs={6} md={6}>
          <li className="regionListli">{item}</li>
        </Grid>
      );
    });
  };

  const moreinformation = (data) => {
    return data.map((item) => {
      return (
        <Grid item xs={4} md={4}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            gap={2}
          >
            <Typography
              variant="h6"
              fontSize="1.25rem"
              style={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              {item.title}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              {item.description}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <>
      <div className="catalogSupersetContainer">
        <div className="catalogSuperset">
          <CatalogSubHeading title={"Overview"} />
          <div>
            <div className="catalogSupersetOverviewTitle">
              <CatalogSubHeading
                title={"About This Service"}
                fontSize="1.25rem"
                color="#02273C"
              />
            </div>
            <div className="catalogSupersetOverviewTitle">
              <Typography
                variant="body1"
                component={"div"}
                sx={{ color: "#000", lineHeight: "1.5rem" }}
              >
                <ul>
                  <li>
                    Apache Superset stands out as an open-source powerhouse in
                    the realm of data exploration and visualization. Tailored
                    for a diverse audience, including data analysts, scientists,
                    and business users, Superset empowers individuals to
                    construct dynamic, shareable dashboards with utmost ease.
                    Its user-friendly interface serves as a gateway to unlocking
                    the potential insights within your data. Whether you're
                    delving into intricate datasets or seeking to convey
                    compelling narratives through visuals, Superset's
                    capabilities extend to users of varying technical
                    proficiencies. This platform enables interactive
                    exploration, facilitating ad-hoc queries, efficient
                    filtering, and detailed drill-downs into specific data
                    points. In essence, Apache Superset is a versatile and
                    accessible tool, providing the means to transform raw data
                    into meaningful insights through intuitive and interactive
                    visualizations.{" "}
                  </li>
                  <li>
                    {" "}
                    Superset is fast, lightweight, intuitive, and loaded with
                    options that make it easy for users of all skill sets to
                    explore and visualize their data, from simple line charts to
                    highly detailed geospatial charts.{" "}
                  </li>
                </ul>
              </Typography>
            </div>
            <Carousel
              imageList={[
                { src: SupersetDashboard, alt: "SupersetDashboard" },
                { src: SupersetCreateChart, alt: "SupersetCreateChart" },
                { src: SupersetConnectDB, alt: "SupersetConnectDB" },
                {
                  src: SupersetDashboardDetails,
                  alt: "SupersetDashboardDetails",
                },
              ]}
            />
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <BoltIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Powerful Yet Easy to Use",
              description:
                "Drag and drop to create robust charts and tables. This eliminates the need for complex coding or technical skills.",
            },
            {
              icon: (
                <RocketIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Advanced Capabilities",
              description:
                "Write custom SQL queries, browse database metadata, use Jinja templating, and more.",
            },
            {
              icon: (
                <DataUsageIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Database Integration",
              description:
                "Seamlessly integrates with a range of data sources, spanning relational databases and big data platforms",
            },
            {
              icon: (
                <TrendingUpIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Scalability",
              description:
                "Designed to scale, making it suitable for handling large datasets and growing data needs.",
            },
            {
              icon: (
                <LightbulbIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Modern",
              description:
                "Provides continuous innovation through flexible feature flag access.",
            },
            {
              icon: (
                <SecurityIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Security",
              description:
                "Robust security features allow administrators to control access to data and dashboards based on user roles and permissions.",
            },
          ]}
        />
        <CatalogKeyFeatures
          listItems={[
            {
              heading: "Rich visualizations and dashboards",
              description:
                "Superset provides a versatile visualization experience with 40+ pre-installed visualizations, supporting drag-and-drop simplicity and advanced SQL queries. It enables interactive dashboard creation with Jinja templating, dashboard filters, and CSS templates for seamless customization to match your brand's unique aesthetics.",
            },
            {
              heading: " Data Exploration",
              description:
                "Superset allows users to explore and analyze data with features like ad-hoc querying, filtering, and drilling down into specific data points. Users can write SQL queries directly within the platform. This feature facilitates real-time exploration without the need for predefined queries.",
            },
            {
              heading: "Wide Range of Data Sources",
              description:
                "Superset stands out for its remarkable versatility, seamlessly supporting a diverse array of data sources. From traditional relational databases to cutting-edge big data platforms like Apache Druid and Apache Spark, and well-established data warehouses such as Amazon Redshift and Google BigQuery.",
            },
            {
              heading: "Data Optimization",
              description:
                "Superset optimizes data exploration with swift load times through data caching and empowers users with a dedicated semantic layer for seamless SQL data transformations. These features contribute to an efficient and precise data exploration experience.",
            },
            {
              heading: "Modern architecture",
              description:
                "Superset is lightweight and highly scalable, leveraging the power of your existing data infrastructure without requiring yet another ingestion layer. It can be horizontally scaled by deploying multiple instances across distributed environments. Also, enjoy access to new functionalities through feature flags",
            },
            {
              heading: "Security and Access Control",
              description:
                "RBAC in Superset allows administrators to define roles and assign specific permissions to users based on their roles. This ensures that users only have access to the data and dashboards that are relevant to their roles, maintaining a secure and controlled environment.",
            },
          ]}
        />
        <div className="catalogSupersetContainer alignItemsCenter">
          <Button
            variant="default"
            id="viewFullFeatureBreakdown"
            onClick={() => {}}
          >
            View Full Feature Breakdown
          </Button>
        </div>
        <div className="catalogSupersetContainer">
          <CatalogSubHeading title={"Available Pricing Plans"} />
          <div className="catalogSupersetPricingPlan">
            <CatalogSubHeading
              title={"Apache Superset is a free open-source tool"}
              fontSize="1.25rem"
              color="#02273C"
            />
            <Typography
              variant="body1"
              fontSize="1rem"
              sx={{ color: "#000", lineHeight: "1.5rem" }}
            >
              Apache Superset emerges as a dynamic and adaptable business
              intelligence solution, available at no cost as an open-source
              tool, ensuring usability for organizations regardless of their
              scale. With a diverse range of features, Superset fosters a
              welcoming community where users can find support and actively
              engage in contributing to its ongoing development.
            </Typography>
          </div>
        </div>
        <div className="catalogSupersetContainer">
          <CatalogSubHeading title={"Region Availability"} />
          <div className="regionAvailabilityContainer">
            <div className="catalogSuperset">
              <CatalogSubHeading
                title={"Available Regions"}
                fontSize="1.25rem"
                color="#02273C"
              />
              <div style={{ display: "flex" }}>
                <Grid container spacing={0}>
                  <Grid item xs={8} md={8}>
                    <Grid container spacing={0}>
                      {regionList([
                        "North America",
                        "South America",
                        "Europe",
                        "Asia",
                        "Africa",
                        "Oceania",
                        "Middle East",
                        "Caribbean",
                        "Central America",
                        "South Pacific",
                      ])}
                    </Grid>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <div style={{ marginRight: "0.938rem" }}>
                      <img
                        alt={"allRegion"}
                        width={400}
                        height={200}
                        src={AllRegion}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="specificRegionDetils">
              <CatalogSubHeading
                title={"Specific Region Details"}
                fontSize="1.25rem"
                color="#02273C"
              />

              <div style={{ display: "flex", gap: "2.813rem" }}>
                <Grid container spacing={2}>
                  <Grid item xs={4} md={4}>
                    <Region />
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography
                      variant="body1"
                      fontSize="1rem"
                      fontWeight={700}
                      component="div"
                      sx={{
                        color: "#02273C",
                        lineHeight: "1.5rem",
                        paddingBottom: 1,
                      }}
                    >
                      Region Details
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="1rem"
                      fontWeight={400}
                      component="div"
                      sx={{ color: "#577687", lineHeight: "1.5rem" }}
                    >
                      North America: This region provides low-latency access to
                      customers located in the United States and Canada. It
                      offers high availability and scalability, making it an
                      ideal choice for enterprise customers with large-scale
                      workloads.
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <Typography
                      variant="body1"
                      fontSize="1rem"
                      fontWeight={700}
                      component="div"
                      style={{
                        color: "#02273C",
                        lineHeight: "1.5rem",
                        paddingBottom: 8,
                      }}
                    >
                      Local Compliance
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="1rem"
                      fontWeight={400}
                      component="div"
                      sx={{ color: "#577687", lineHeight: "1.5rem" }}
                    >
                      Fully compliant with regulatory requirements, including:
                      <ul>
                        <li>GDPR</li>
                        <li>ISO 27000</li>
                        <li>SOC-2</li>
                      </ul>
                    </Typography>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="catalogSupersetContainer">
              <CatalogSubHeading title={"More Information"} />
              <Grid container spacing={2}>
                {moreinformation([
                  {
                    title: "Compatibility",
                    description:
                      "To use Apache Superset effectively, users need to ensure compatibility on both the server and client sides, as well as ensuring efficient network access. Regularly checking the official Superset documentation for the latest compatibility requirements is essential, as these may evolve with new releases or updates.",
                  },
                  {
                    title: "Security Information",
                    description:
                      "Authentication and authorization in Superset is handled by Flask AppBuilder (FAB), an application development framework built on top of Flask. FAB provides authentication, user management, permissions and roles.",
                  },
                  {
                    title: "Support Information",
                    description:
                      "Apache Superset prioritizes customer satisfaction by offering robust and responsive customer support, ensuring access to assistance and guidance throughout their experience with the platform. Also, enjoy vibrant community of users and contributors, receive continuous improvement, updates, and support.",
                  },
                ])}
              </Grid>
            </div>
            <CatalogFAQ
              listItems={[
                {
                  id: "panel1",
                  heading: "How big of a dataset can Superset handle?",
                  description:
                    "Superset provides a versatile visualization experience with 40+ pre-installed visualizations, supporting drag-and-drop simplicity and advanced SQL queries. It enables interactive dashboard creation with Jinja templating, dashboard filters, and CSS templates for seamless customization to match your brand's unique aesthetics.",
                },
                {
                  id: "panel2",
                  heading:
                    "What are the computing specifications required to run Superset?",
                  description:
                    "The specs of your Superset installation depend on how many users you have and what their activity is, not on the size of your data. Superset admins in the community have reported 8GB RAM, 2vCPUs as adequate to run a moderately-sized instance. To develop Superset, e.g., compile code or build images, you may need more power. \n \n Monitor your resource usage and increase or decrease as needed. Note that Superset usage has a tendency to occur in spikes, e.g., if everyone in a meeting loads the same dashboard at once. \n \n Superset's application metadata does not require a very large database to store it, though the log file grows over time.",
                },
                {
                  id: "panel3",
                  heading: "Can I join / query multiple tables at one time?",
                  description:
                    "Not in the Explore or Visualization UI. A Superset SQLAlchemy datasource can only be a single table or a view. \n \nWhen working with tables, the solution would be to create a table that contains all the fields needed for your analysis, most likely through some scheduled batch process. \n \nA view is a simple logical layer that abstracts an arbitrary SQL queries as a virtual table. This can allow you to join and union multiple tables and to apply some transformation using arbitrary SQL expressions. The limitation there is your database performance, as Superset effectively will run a query on top of your query (view). A good practice may be to limit yourself to joining your main large table to one or many small tables only, and avoid using GROUP BY where possible as Superset will do its own GROUP BY and doing the work twice might slow down performance. \n \nWhether you use a table or a view, performance depends on how fast your database can deliver the result to users interacting with Superset. \n \nHowever, if you are using SQL Lab, there is no such limitation. You can write SQL queries to join multiple tables as long as your database account has access to the tables.",
                },
                {
                  id: "panel4",
                  heading: "How do I create my own visualization?",
                  childNode: (
                    <>
                      We recommend reading the instructions in
                      <Button
                        onClick={() =>
                          window.open(
                            "https://superset.apache.org/docs/contributing/creating-viz-plugins/",
                            "Plugins",
                          )
                        }
                        id="createVisualizationPlugins"
                      >
                        Creating Visualization Plugins.
                      </Button>
                    </>
                  ),
                },
              ]}
            />
            <Button
              variant="text"
              id={"openFullFAQ"}
              onClick={() =>
                window.open(
                  "https://superset.apache.org/docs/frequently-asked-questions/",
                  "openFullFAQ",
                )
              }
            >
              Open Full FAQ <OpenInNewIcon />
            </Button>

            <CatalogRelevantResources
              listItems={[
                {
                  icon: (
                    <WavingHandOutlinedIcon
                      color="primary"
                      sx={{
                        width: "3.5rem",
                        padding: "0.625rem",
                        height: "3.5rem",
                        borderRadius: "6.25rem",
                        backgroundColor: "#A1E1F5",
                      }}
                    />
                  ),
                  heading: "Introduction",
                  description:
                    "Welcome to Apache Superset, a modern, enterprise-ready business intelligence web application.",
                },
                {
                  icon: (
                    <LibraryBooksOutlinedIcon
                      color="primary"
                      sx={{
                        width: "3.5rem",
                        padding: "0.625rem",
                        height: "3.5rem",
                        borderRadius: "6.25rem",
                        backgroundColor: "#A1E1F5",
                      }}
                    />
                  ),
                  heading: "Quickstart",
                  description:
                    "Ready to give Apache Superset a try? Set up Superset on your local machine in 5 simple steps.",
                },
                {
                  icon: (
                    <AccountCircleIcon
                      color="primary"
                      sx={{
                        width: "3.5rem",
                        padding: "0.625rem",
                        height: "3.5rem",
                        borderRadius: "6.25rem",
                        backgroundColor: "#A1E1F5",
                      }}
                    />
                  ),
                  heading: "Community Support",
                  description:
                    "Get involved in our welcoming, fast growing community!",
                },
              ]}
            />

            <div className="catalogSupersetRelatedProducts">
              <CatalogSubHeading title={"Related Products"} />
              <Typography
                fontSize="1rem"
                component="div"
                style={{ marginTop: "1.25rem", marginBottom: "1.25rem" }}
              >
                Discover similar products and recommendations to enhance your
                shopping experience.
              </Typography>
              <CardView
                variant="simple"
                data={CARD_DATA}
                handleNavigateTo={() => {}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalogSupersetOverView;
