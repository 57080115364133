import React, { FC } from "react";
import Typography from "@mui/material/Typography";
import WarningIcon from "@mui/icons-material/Warning";
import TextField from "@mui/material/TextField";
import { Button } from "@ssce/ui-shared-library";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";
import { ApiKeysTable } from "./apiKeysTable";
import "./apiKeys.css";
import { ErrorNotification } from "@ssce/ui-shared-library";
import { NewApiKey } from "./apiKeysHooks";
import { useAclHooks } from "../../subcomponents/Acl/hooks";
import { PERMISSION_LIST } from "../../../../constants";

export type ApiKey = {
  id: string;
  keyName: string;
  createDate: string;
  expiryDate: string;
};

const ApiKeyHeader: FC = () => {
  return (
    <div className={"apiKeyHeader"}>
      <Typography
        variant="body1"
        fontSize="1rem"
        fontWeight={400}
        component="div"
        align="left"
        lineHeight={"1.5rem"}
        color="#577687"
      >
        Create and Manage your API keys
      </Typography>
    </div>
  );
};
const CreateApiKey: FC<{
  onChange: (e: any) => void;
  onClick: (e: React.MouseEvent) => void;
  hasApiKeyNameError: string;
  apiKeyName: string;
}> = ({ onChange, onClick, hasApiKeyNameError, apiKeyName }) => {
  return (
    <div className={"createApiKey"}>
      <Typography
        variant="h6"
        fontSize="1.25rem"
        fontWeight={500}
        component="div"
        align="left"
        lineHeight={"1.5rem"}
        fontFamily={"Roboto Medium"}
        color="#0A85C7"
      >
        Create API Key
      </Typography>

      <div className={"createNewApiKey"}>
        <TextField
          id="apikeyName"
          required
          error={hasApiKeyNameError !== ""}
          helperText={
            hasApiKeyNameError !== ""
              ? hasApiKeyNameError
              : "The name should only include letters, numbers, underscores, and dashes."
          }
          label="Name"
          placeholder="Enter name..."
          value={apiKeyName}
          onChange={onChange}
          sx={{
            "& .MuiOutlinedInput-root": { borderRadius: "0.25rem" },
            "& .MuiFormHelperText-root": { marginLeft: 0, marginRight: 0 },
            width: "27.688rem",
          }}
        />

        <Button
          variant="default"
          onClick={onClick}
          id={"createNewApiKey"}
          className={"createNewApiKeyButton"}
        >
          Create New API Key
        </Button>
      </div>
      <div className={"createApiKeyWarningContainer"}>
        <div className={"warningIcon"}>
          <WarningIcon sx={{ color: "#E7AA12" }} />
        </div>
        <div className={"revokeText"}>
          <Typography style={{ fontSize: 14, color: "#6B4F08" }}>
            This will revoke your current key
          </Typography>
        </div>
      </div>
    </div>
  );
};

type Props = {
  createApiKey: () => void;
  setApiKeyName: (e) => void;
  deleteApiKey: (id: string, name: string) => void;
  isLoading: boolean;
  isError: boolean;
  apiKeys: ApiKey[];
  newApiKey?: NewApiKey;
  hasApiKeyNameError: string;
  apiKeyName: string;
};

export const ApiKeysContent: FC<Props> = ({
  createApiKey,
  setApiKeyName,
  deleteApiKey,
  hasApiKeyNameError,
  apiKeyName,
  isError,
  isLoading,
  newApiKey,
  apiKeys,
}) => {
  return (
    <>
      {isError && <ErrorNotification />}
      <div className={"apiiKeysContainer"}>
        <ApiKeyHeader />

        <div className={"apiKeysContent"}>
          {useAclHooks(PERMISSION_LIST.API_KEYS_EXECUTE) && (
            <div className={"createApiKeyContainer"}>
              <CreateApiKey
                onChange={(e) => {
                  setApiKeyName(e.target.value);
                }}
                onClick={createApiKey}
                hasApiKeyNameError={hasApiKeyNameError}
                apiKeyName={apiKeyName}
              />
            </div>
          )}
          {isLoading ? (
            <LoadingAnimation />
          ) : (
            <ApiKeysTable
              apiKeys={apiKeys}
              newApiKey={newApiKey}
              onCopyClick={deleteApiKey}
            />
          )}
        </div>
      </div>
    </>
  );
};
