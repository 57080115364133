import {
  Heading,
  Subheading,
  P,
  ListItem,
  Bold,
  Image,
  Center,
} from "../../Styles/Documentation.styles";
import QueryEditorImage from "../../Images/QueryEditor.png";
import QueryEditorWithDataSourcesImage from "../../Images/QueryEditorWithDataSources.png";
import ChatGPTAndDataSourcesImage from "../../Images/ChatGPTAndDataSources.png";
import { OnThisPageHelper } from "../../OnThisPageHelper";

const anchorTagsArr = [
  { id: "1", subHeadingString: "The Power of Starburst™" },
  { id: "2", subHeadingString: "All Your Data, One Panel" },
  { id: "3", subHeadingString: "Joining Across Technologies" },
  { id: "4", subHeadingString: "ChatGPT: A Tailored Conversation" },
  { id: "5", subHeadingString: "Data Privacy" },
  { id: "6", subHeadingString: "Conclusion" },
];

export const MasteringQueriesCopy = () => {
  return (
    <>
      <OnThisPageHelper anchorTagsArr={anchorTagsArr} />
      <Heading>
        Mastering Queries with SS&C Technology's Data Mesh: Uniting Data Like
        Never Before!
      </Heading>
      <i>Last Updated: Aug 2024</i>
      <P>
        Welcome back to our journey through SS&C Technology's Data Mesh. Having
        covered the process of connecting to your data and setting up jobs and
        persistence targets, we now approach one of the most exhilarating
        aspects of data management: <Bold>Querying!</Bold>
      </P>
      <Center>
        <Image src={QueryEditorImage} sizeInRem={35} border />
      </Center>
      <Subheading id="1">The Power of Starburst™</Subheading>
      <P>
        SS&C Everywhere’s Data Mesh comes equipped with a query editor which
        provides a seamless user experience and robust functionalities. This
        editor makes querying not just efficient, but also intuitive.
      </P>
      <Subheading id="2">All Your Data, One Panel</Subheading>
      <P>
        To the left of the editor, you'll find a panel displaying all your
        connected database types. Dive into:
      </P>
      <ul>
        <ListItem>
          <Bold>Schemas:</Bold> Organizational containers housing tables.
        </ListItem>
        <ListItem>
          <Bold>Tables:</Bold> Your individual datasets.
        </ListItem>
      </ul>
      <P>
        No matter the technology backing each database, they're all there, ready
        to be explored.
      </P>
      <Center>
        <Image src={QueryEditorWithDataSourcesImage} sizeInRem={35} border />
      </Center>
      <Subheading id="3">
        Joining Across Technologies: A Seamless Experience
      </Subheading>
      <P>
        Perhaps the most compelling feature is the ability to join various data
        sources. Imagine being able to merge data from an SQL database with a
        NoSQL source, or an Iceberg big data environment. With the SS&C
        Everywhere Data Mesh, boundaries are blurred and you can unite data from
        disparate sources effortlessly.
      </P>
      <Subheading id="4">ChatGPT: A Tailored Conversation</Subheading>
      <P>
        On the right side of the app, there's more magic awaiting! Pull out the
        drawer, and you're greeted by ChatGPT.
      </P>
      <P>
        The ChatGPT integration into SS&C Everywhere is not just any chatbot;
        it's pre-primed with knowledge of all your schemas and table columns.
        This means you can:
      </P>
      <ul>
        <ListItem>
          <Bold>Get Query Suggestions:</Bold> Unsure how to start? ChatGPT can
          suggest initial queries based on your data.
        </ListItem>
        <ListItem>
          <Bold>Iterative Feedback:</Bold> Modify and refine your queries in
          real-time, with guidance tailored to your specific data sources.
        </ListItem>
      </ul>
      <Center>
        <Image src={ChatGPTAndDataSourcesImage} sizeInRem={35} border />
      </Center>
      <Subheading id="5">Data Privacy: Our Foremost Priority</Subheading>
      <P>
        While ChatGPT is a powerhouse of knowledge, we ensure utmost data
        security. We only feed it the names of tables and columns, never the
        actual data. This ensures that while you benefit from its expertise,
        your data remains private and protected.
      </P>
      <Subheading id="6">Conclusion: Data Management, Reimagined!</Subheading>
      <P>
        With SS&C Technology's Data Mesh, you're not just managing data; you're
        weaving it into a coherent tapestry, regardless of its origin. Between
        the might of the Starburst query editor and the personalized assistance
        of ChatGPT, you have everything you need to performantly access data
        from across your business.
      </P>
      <P>
        Stay connected, and keep an eye out for our upcoming guides. Remember,
        with SS&C's Data Mesh, the data possibilities are endless.
      </P>
      <P>
        <i>
          For further guidance or any queries, our SS&C Everywhere platform and
          team are always at your disposal. Here's to data done right!
        </i>
      </P>
    </>
  );
};
