export type MyservicesState = Myservices;

export interface Myservices {
  isLoading: boolean;
  items:any[]
  hasError: boolean;
  isDeleteLoading:boolean;
}

export const createDefaultMyservicesState = (): MyservicesState => {
  return {
    isLoading:false,
    isDeleteLoading:false,
    items:[],
    hasError:false
  };
};
