import React, { useRef } from "react";
import { Handle } from "reactflow";
import {
  Title,
  SubTitle,
  StatusText,
  IconContainer,
} from "../WorkflowViewerContent/workflowViewerContent.styles";

export const Node = (props) => {
  const [_, setForceRender] = React.useState(false);
  const ref = useRef(null);
  React.useEffect(() => {
    setForceRender((prevState) => !prevState);
  }, []);
  const { Icon, title, subTitle } = props.data;
  return (
    <div
      className={"nodrag"}
      style={{
        display: "grid",
        gridTemplateColumns: "max-content 1fr",
        height: "100%",
        width: "17.188rem",
        border: "0.063rem solid #90a8b5",
        borderRadius: "0.5rem",
        padding: "1rem",
        gridColumnGap: "0.625rem",
        position: "relative",
        backgroundColor: "#f6fdff",
      }}
      ref={ref}
    >
      {props.targetPosition !== "None" && (
        <Handle type="target" position={props.targetPosition} />
      )}
      <IconContainer>{Icon}</IconContainer>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
      </div>
      <div style={{ gridColumn: "1/-1", paddingTop: "0.313rem" }}>
        <StatusText status={"inactive"}>Task Not Configured</StatusText>
      </div>
      {props.sourcePosition !== "None" && (
        <Handle type="source" position={props.sourcePosition} id="a" />
      )}
    </div>
  );
};
