import * as React from "react";

import Box from "@mui/material/Box";
import { Button } from "@ssce/ui-shared-library";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import AccessRequest from "../../../../../assets/AccessRequest.png";
import Alert from "@mui/material/Alert";
import "./PCAccessRequest.css";

const PCAccessRequestError: React.FC = () => {
  const [open, setOpen] = React.useState(true);

  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "0.125rem solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-container">
          <div className="modal-content">
            <div className="top-line">
              <p className="access-request-header">Access Request</p>
              <img
                className="access-request-img"
                alt={"accessRequest"}
                src={AccessRequest}
              ></img>
            </div>
            <Alert severity="error" className="pc-approval-error">
              <Typography className="unsuccessful-header">
                Unsucccessful
              </Typography>
              <Typography className="unsuccessful-text">
                <b>We're sorry but your request was unsuccessful.</b> Please try
                again. If the issue persist, contact Customer Care.
              </Typography>
              <br></br>
            </Alert>
          </div>
          <Box className="ar-button-container">
            <Stack
              spacing={2}
              direction="row"
              style={{
                width: "15.625rem",
                marginLeft: "auto",
                paddingTop: "0.625rem",
              }}
            >
              <Button
                id={"getSupport"}
                variant="secondary"
                onClick={handleClose}
              >
                Get Support
              </Button>
              <Button id={"close"} variant="default" onClick={handleClose}>
                Close
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default PCAccessRequestError;
