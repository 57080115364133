import { Roles, Permissions, UserData, Projects } from "../../common/dataTypes";

export type RolesManagementState = RolesManagement;

export interface RolesManagement {
  roleList: Roles[];
  isLoading:boolean;
  isRoleDetailsLoading:boolean;
  selectedRole: Roles;
  roleOverviewDetails: Roles;
  showDetails: Boolean;
  showCreateRole: boolean;
  showEditRoleName: boolean;
  showEditRoleDesc: boolean;
  showDuplicateRole: boolean;
  rolePermissions: Permissions[];
  roleUsers: UserData[];
  roleProjects: Projects[];
  permissionsList: Permissions[];
  projectsList: Projects[];
}

export const createDefaultState = (): RolesManagementState => {
  return {
    roleList: [],
    isLoading:false,
    isRoleDetailsLoading:false,
    showDetails: false,
    showCreateRole: false,
    showEditRoleName: false,
    showEditRoleDesc: false,
    showDuplicateRole: false,
    roleOverviewDetails: {
      id: 0,
      name: "",
      description: "",
      permission: "",
      permissionTable: [],
    },
    selectedRole: {
      id: 0,
      name: "",
      description: "",
      permission: "",
      permissionTable: [],
    },
    rolePermissions: [],
    roleUsers: [],
    roleProjects: [],
    permissionsList: [],
    projectsList: [],
  };
};
