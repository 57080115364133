import * as React from "react";
import { useState } from "react";
import { Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Postgresql.css";
import { useNavigate } from "react-router-dom";
import PostgresqlOverView from "./PostgresqlOverView";
import PostgresqlTechnicalSpecifications from "./PostgresqlTechnicalSpecifications";
import PostgresqlPlansAndFeatures from "./PostgresqlPlansAndFeatures";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";
import PostgreSQLImage from "../../../../assets/Postgresql.png";

const Postgresql: React.FC = () => {
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={PostgreSQLImage}
        title={"PostgreSQL"}
        contents={
          "PostgreSQL is a highly reliable and feature-rich open-source object-relational database system known for its robust performance and extensive capabilities. It supports advanced data types, complex queries, and ACID compliance, making it well-suited for handling diverse and complex workloads. Additionally, PostgreSQL offers a wide range of extensions and customization options, enabling developers to tailor the database to specific needs and integrate seamlessly with various applications and systems."
        }
        tags={[
          {
            label: "Data Management",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Database",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() => navigate("/public-catalog/postgresql/provision")}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Technical Specifications" value="3" />
            <Tab label="Documentation" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <PostgresqlOverView />
        </TabPanel>

        <TabPanel value="2" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <PostgresqlPlansAndFeatures />
        </TabPanel>
        <TabPanel value="3" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <PostgresqlTechnicalSpecifications />
        </TabPanel>
        <TabPanel
          value="4"
          sx={{ padding: "4.5rem 0 0 0", display: "flex" }}
        ></TabPanel>
      </TabContext>
      <div className="flex-row  justifyContent-center gap1rem">
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() => navigate("/public-catalog/postgresql/provision")}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </div>
    </>
  );
};
export default Postgresql;
