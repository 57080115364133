export type UserState = User;

export interface User {
  loggedIn: boolean;
  token: string | null;
  username: string | null;
  email: string | null;
}

export const createDefaultState = (): UserState => {
  return {
    loggedIn: false,
    token: null,
    username: null,
    email: null,
  };
};
