import { AuthConsumer } from "./authProvider";
import { LoadingAnimation } from "../../components/main/subcomponents/LoadingAnimation";

export const Logout = () => (
  <AuthConsumer>
    {({ logout }) => {
      logout();
      return <LoadingAnimation />;
    }}
  </AuthConsumer>
);
