import * as React from "react";
import { useState } from "react";
import { Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Nifi.css";
import { useNavigate } from "react-router-dom";
import NifiOverView from "./NifiOverView";
import NifiTechnicalSpecifications from "./NifiTechnicalSpecifications";
import NifiPlansAndFeatures from "./NifiPlansAndFeatures";
import NifiImage from "../../../../assets/Nifi.png";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

const Nifi: React.FC = () => {
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={NifiImage}
        title={"Apache Nifi"}
        contents={
          "Apache NiFi is a powerful tool that automates cybersecurity, observability, event streams, and generative AI data pipelines and distribution for thousands of companies worldwide across every industry. By streamlining data flow management and ensuring real-time processing, NiFi enables organizations to gain deeper insights, enhance security measures, and drive innovation. Its scalable and flexible architecture adapts to diverse use cases, empowering businesses to stay ahead in a rapidly evolving digital landscape."
        }
        tags={[
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Web Application",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          width="12rem"
          id={"createInstance"}
          onClick={() => navigate("/public-catalog/nifi/provision")}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </MyServicesCardHeader>

      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Technical Specifications" value="3" />
            <Tab label="Documentation" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <NifiOverView />
        </TabPanel>
        <TabPanel value="2" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <NifiPlansAndFeatures />
        </TabPanel>
        <TabPanel value="3" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <NifiTechnicalSpecifications />
        </TabPanel>
        <TabPanel
          value="4"
          sx={{ padding: "4.5rem 0 0 0", display: "flex" }}
        ></TabPanel>
      </TabContext>
      <div className="flex-row justifyContent-center gap1rem">
        <Button
          width="12rem"
          id={"createInstance"}
          onClick={() => navigate("/public-catalog/nifi/provision")}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </div>
    </>
  );
};
export default Nifi;
