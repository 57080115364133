import { useState, useEffect, FC } from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button } from "@ssce/ui-shared-library";
import Accordion from "../Accordion";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmModal from "../ConfirmModal";
import { InstancesDetails } from "../../../../common/dataTypes";

import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetchProjectResources,
  fetchResources,
  updateProjectResources,
  deleteProjectResources,
} from "../../../../redux/projectsManagement/actions";
import Acl from "../../subcomponents/Acl";
import { useAclHooks } from "../../subcomponents/Acl/hooks";
import { PERMISSION_LIST } from "../../../../constants";

const mapStateToProps = (state: AppState) => ({
  selectedProject: state.projectsManagement.selectedProject,
  projectResources: state.projectsManagement.projectResources,
  resourcesList: state.projectsManagement.resourcesList,
});

const mapDispatchToProps = {
  fetchProjectResources,
  fetchResources,
  updateProjectResources,
  deleteProjectResources,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const ResourcesManagement: FC<Props> = ({
  selectedProject,
  projectResources,
  resourcesList,
  fetchResources,
  updateProjectResources,
  deleteProjectResources,
}) => {
  const [selectedResources, setSelectedResources] = useState<
    InstancesDetails[]
  >([]);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<InstancesDetails>({
    instanceId: "",
    instanceName: "",
    description: "",
    region: "",
    tags: "",
    serviceName: "",
    applicationName: "",
    catalogType: "",
    version: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDeleteClick = () => {
    handleClose();
    deleteProjectResources(selectedProject.projectId, [
      { id: deleteData.instanceId },
    ]);
  };

  const handleApplyClick = () => {
    let payload = selectedResources.map((item) => {
      return { id: item.instanceId };
    });
    setSelectedResources([]);
    updateProjectResources(selectedProject.projectId, payload);
  };

  useEffect(() => {
    fetchResources();
  }, [fetchResources]);

  const allowResourceDelete = useAclHooks(PERMISSION_LIST.PROJECTS_EXECUTE);
  const renderAssignedResource = () => {
    return projectResources.map((item) => {
      return (
        <Accordion
          key={item.instanceName}
          name={item.instanceName}
          iconHeader={allowResourceDelete && <DeleteForeverIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            handleOpen();
            setDeleteData(item);
          }}
        >
          <Typography
            style={{ color: "#0A85C7", fontSize: 12, marginLeft: 20 }}
          >
            Resource Information
          </Typography>
          <Typography
            style={{
              color: "#02273C",
              fontWeight: 400,
              fontSize: 14,
              marginLeft: 20,
            }}
          >
            {item.description}
          </Typography>
          <br></br>
        </Accordion>
      );
    });
  };

  const renderSelectedResources = () => {
    return selectedResources.map((item) => {
      return (
        <Accordion
          key={item.instanceName}
          name={item.instanceName}
          iconHeader={<ClearIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            setSelectedResources([
              ...selectedResources.filter(
                (r) => item.instanceId !== r.instanceId,
              ),
            ]);
          }}
        >
          <Typography
            style={{ color: "#0A85C7", fontSize: 12, marginLeft: 20 }}
          >
            Description
          </Typography>
          <Typography
            style={{
              color: "#02273C",
              fontWeight: 400,
              fontSize: 14,
              marginLeft: 20,
            }}
          >
            {item.description}
          </Typography>
          <br></br>
        </Accordion>
      );
    });
  };

  return (
    <div style={{ marginBottom: "5rem" }}>
      <div style={{ width: "100%" }}>
        <h1
          style={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 20,
            lineHeight: 0,
            textAlign: "left",
            color: "#02273C",
          }}
        >
          Assigned Resources
        </h1>
        <br></br>
        {renderAssignedResource()}
        <br></br>
      </div>
      <ConfirmModal
        open={open}
        title={"Please Confirm"}
        onRequestCancel={handleClose}
        onRequestConfirm={handleDeleteClick}
      >
        <Typography
          style={{
            margin: 2,
            fontSize: 20,
            fontWeight: 500,
            lineHeight: "1.5rem",
          }}
        >
          You are{" "}
          <span style={{ textDecoration: "underline", fontWeight: 900 }}>
            removing{" "}
          </span>{" "}
          the{" "}
          <span style={{ fontWeight: 700, color: "#0468B1" }}>
            {deleteData.instanceName}
          </span>{" "}
          from the {selectedProject.name}. You can re-add this{" "}
          {deleteData.instanceName} if you change your mind.
          <span style={{ fontWeight: 900 }}>
            Are you sure you want to continue?
          </span>
        </Typography>
      </ConfirmModal>
      <Acl permission={PERMISSION_LIST.PROJECTS_WRITE}>
        <div>
          <h1
            style={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 20,
              lineHeight: 0,
              textAlign: "left",
              color: "#02273C",
            }}
          >
            Assign Resources
          </h1>
          <br></br>
          <div
            style={{
              border: "0.063rem solid #CBD9E1",
              borderRadius: 4,
            }}
          >
            <Stack
              spacing={2}
              sx={{
                margin: 2,
                marginBottom: "0.188rem",
                width: 570,
                backgroundColor: "#F6FDFF",
              }}
            >
              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="medium"
                value={selectedResources}
                sx={{
                  "& .MuiChip-root": {
                    backgroundColor: "#B3D9EE",
                    color: "#075E8D",
                  },
                  "& .MuiChip-root .MuiChip-deleteIcon": { color: "#0A85C7" },
                }}
                onChange={(_, values) => {
                  setSelectedResources(values);
                }}
                options={resourcesList}
                isOptionEqualToValue={(option, value) =>
                  option.instanceId === value.instanceId
                }
                getOptionLabel={(option) => option.instanceName}
                renderInput={(params) => (
                  <TextField {...params} label="Resources" placeholder="" />
                )}
              />
            </Stack>
            <p
              style={{
                marginLeft: 20,
                marginTop: 0,
                color: "#577687",
                backgroundColor: "#EDF4F8",
                fontSize: 12,
              }}
            >
              Please use the dropdown box above or type in a Resources name to
              select it.
            </p>
            <p
              style={{
                margin: "1rem 1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 18,
                textAlign: "left",
                color: "#02273C",
              }}
            >
              Resources Addition Details
            </p>
            {renderSelectedResources()}
            <div
              style={{
                width: "85%",
                marginLeft: 20,
                marginBottom: 20,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {" "}
              <br></br>
              <Button
                id={"apply"}
                onClick={handleApplyClick}
                variant="default"
                size="medium"
              >
                Apply changes
              </Button>
              <Button
                id={"cancel"}
                onClick={() => setSelectedResources([])}
                variant="text"
                size="medium"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Acl>
    </div>
  );
};

export default connector(ResourcesManagement);
