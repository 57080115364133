import styled, { keyframes } from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import { slideIn, slideOut } from "src/globalAnimations.styles";

const animationTime = 0.3;

export const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.938rem;
`;

export const UtilContainer = styled.div`
  z-index: 3;
  top: 5vh;
  position: absolute;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 0 0 1.25rem 0;
  gap: 0.625rem;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const animateFromTop = keyframes`
  0% {
    top: 43.75rem;
    opacity: 0;
  }
  100% {
    top: 9.375rem;
    opacity: 1;
  }
`;

export const Wrapper = styled.div`
  margin-top: 3rem;
  height: 100%;
  position: sticky;
`;

export const LoadingWrapper = styled.div`
  height: 100vh;
`;

export const ServicesContainer = styled.div<{ isVisible: boolean }>`
  margin-right: 6.25rem;
  margin-left: 6.25rem;
  pointer-events: ${(props) => (!props.isVisible ? "auto" : "none")};
  display: ${(props) => (!props.isVisible ? "block" : "none")};
  animation: ${(props) => (!props.isVisible ? slideIn : slideOut)}
    ${animationTime}s forwards;
`;

export const CardViewWrapper = styled.div<{
  shouldAnimate: boolean;
}>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  height: max-content;
  gap: 1.25rem;
  padding: 8rem 0;
  width: 100%;
  animation: ${(props) => props.shouldAnimate && animateFromTop}
    ${animationTime}s ease-in-out forwards;
`;

export const CatalogResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSearchIcon = styled(SearchIcon)<{ isFocused: Boolean }>`
  color: ${(props) => (props.isFocused ? "var(--primary)" : "gray")};
`;
