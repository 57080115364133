import React from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AlgoRisk from "../../../../assets/AlgoRisk.png";

export const ProvisionAlgoRiskHeader: React.FC = () => {
  return (
    <>
      <div style={{ display: "flex", gap: "1.5rem", flexDirection: "column" }}>
        <Typography
          variant="h4"
          fontSize="2.125rem"
          fontWeight={600}
          component="div"
          style={{
            color: "#0A85C7",
            lineHeight: "2.25rem",
            padding: "0.625rem 0",
          }}
        >
          Provision Service
        </Typography>
        <div>
          <Divider />
        </div>
      </div>
      <Card sx={{ boxShadow: "none", backgroundColor: "inherit" }}>
        <CardContent sx={{ padding: 0 }}>
          <div style={{ display: "flex", marginTop: "1.25rem", gap: "0.5rem" }}>
            <img
              src={AlgoRisk}
              alt="SSNC"
              style={{
                width: "8.438rem",
                height: "7.813rem",
                marginRight: "1.25rem",
              }}
            ></img>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "1rem" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Stack
                  spacing={1}
                  direction="row"
                  color="primary"
                  style={{ marginTop: "0.313rem" }}
                >
                  <Typography
                    variant="h1"
                    fontSize="2.125rem"
                    fontWeight={600}
                    component="div"
                    align="left"
                    lineHeight={1}
                    color="#0468B1"
                    style={{ marginRight: "1rem", marginTop: "0.313rem" }}
                  >
                    SS&C Risk Service
                  </Typography>
                  <Chip
                    label={"Risk Management"}
                    style={{ backgroundColor: "#E7F3F9", color: "#0A85C7" }}
                  />
                  <Chip
                    label={"Cross-Industry"}
                    style={{ backgroundColor: "#EFE6FD", color: "#6200EE" }}
                  />
                  <Chip
                    label={"Analytics Service"}
                    style={{ backgroundColor: "#FFE5F0", color: "#D72F77" }}
                  />
                </Stack>
              </div>

              <Typography
                variant="body1"
                fontSize="1rem"
                fontWeight={400}
                component="div"
                align="left"
                lineHeight={1.5}
                color="#000000"
                style={{
                  width: "50.188rem",
                }}
              >
                This cost-effective risk service leverages your existing
                holdings data housed within the SS&C platform. It delivers
                sophisticated financial risk analytics through reports or an
                intuitive web-based dashboard. ​
              </Typography>
            </div>
          </div>

          {/* </> */}
        </CardContent>
      </Card>
    </>
  );
};
