import { Button } from "@ssce/ui-shared-library";
import Divider from "@mui/material/Divider";
import { FC, useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import CreateIcon from "@mui/icons-material/Create";
import ConfirmModal from "../ConfirmModal";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetchRolesOverview,
  deleteRoleById,
  editRoleById,
  setShowDuplicateRole,
  setShowEditRoleDesc,
  setShowEditRoleName,
  fetcRoleUsers,
  fetcRoleProjects,
  fetcRolePermissions,
  duplicateRole,
} from "../../../../redux/rolesManagement/actions";

import PermissionTable from "../PermissionTable";
import Acl from "../../subcomponents/Acl";
import { PERMISSION_LIST } from "../../../../constants";

const mapStateToProps = (state: AppState) => ({
  selectedRole: state.rolesManagement.selectedRole,
  roleData: state.rolesManagement.roleOverviewDetails,
  showEditRoleDesc: state.rolesManagement.showEditRoleDesc,
  roleUsers: state.rolesManagement.roleUsers,
  roleProjects: state.rolesManagement.roleProjects,
  rolePermissions: state.rolesManagement.rolePermissions,
  showDuplicateRole: state.rolesManagement.showDuplicateRole,
});

const mapDispatchToProps = {
  fetchRolesOverview,
  deleteRoleById,
  editRoleById,
  duplicateRole,
  setShowEditRoleName,
  setShowEditRoleDesc,
  fetcRoleUsers,
  fetcRoleProjects,
  fetcRolePermissions,
  setShowDuplicateRole,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const OverviewInfo: FC<Props> = ({
  selectedRole,
  showEditRoleDesc,
  roleProjects,
  rolePermissions,
  showDuplicateRole,
  duplicateRole,
  setShowEditRoleDesc,
  setShowEditRoleName,
  fetchRolesOverview,
  roleData,
  deleteRoleById,
  editRoleById,
  fetcRoleUsers,
  fetcRoleProjects,
  fetcRolePermissions,
  setShowDuplicateRole,
}) => {
  const [duplicateRolename, setDuplicateRolename] = useState("");

  const [op, setOp] = useState(false);
  const openThis = () => setOp(true);
  const closeThis = () => setOp(false);
  const [error, setError] = useState(false);

  const [description, setDescription] = useState(roleData.description);

  useEffect(() => {
    setShowEditRoleDesc(false);
  }, [setShowEditRoleDesc]);

  useEffect(() => {
    fetchRolesOverview(selectedRole.id);
    fetcRoleProjects(selectedRole.id);
    fetcRoleUsers(selectedRole.id);
    fetcRolePermissions(selectedRole.id);
  }, [
    fetchRolesOverview,
    fetcRoleProjects,
    fetcRoleUsers,
    selectedRole.id,
    fetcRolePermissions,
  ]);

  useEffect(() => {
    setDescription(roleData.description);
  }, [roleData, showEditRoleDesc]);

  const handleEditSave = () => {
    let payload = { name: roleData.name, description: description };
    setShowEditRoleDesc(false);
    editRoleById(selectedRole.id, payload);
  };

  const handleRoleDuplicate = () => {
    setError(false);
    const payload = {
      name: duplicateRolename,
      description: roleData.description,
      users: [],
      permissions: rolePermissions.map((item) => {
        return { id: item.id };
      }),
      projects: roleProjects.map((item) => {
        return { id: item.id };
      }),
    };

    if (duplicateRolename.trim().length === 0) {
      setError(true);
      return false;
    }

    duplicateRole(payload);
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignSelf: "flex-end",
          justifyContent: "flex-start",
          gap: "1%",
        }}
      >
        <ConfirmModal
          open={showDuplicateRole}
          title={"Please Confirm"}
          onRequestCancel={() => setShowDuplicateRole(false)}
          onRequestConfirm={handleRoleDuplicate}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "1.313rem",
            }}
          >
            <Typography
              style={{
                margin: 2,
                fontSize: 20,
                fontWeight: 500,
                lineHeight: "1.5rem",
              }}
            >
              You are{" "}
              <span style={{ textDecoration: "underline", fontWeight: 900 }}>
                duplicating
              </span>{" "}
              the{" "}
              <span style={{ fontWeight: 700, color: "#0468B1" }}>
                {roleData.name}
              </span>{" "}
              role. After duplicating this role you will find it within your{" "}
              <span style={{ fontWeight: 900 }}>Roles</span> table where you can
              then customize it.{" "}
              <span style={{ fontWeight: 900 }}>
                Please click confirm to continue
              </span>
            </Typography>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                margin: "0.625rem 0 1.25rem 0",
                gap: "0.5rem",
              }}
            >
              <TextField
                style={{ background: "#F6FDFF", width: 200 }}
                id="outlined-helperText"
                label="Role Name"
                inputProps={{ maxLength: 40 }}
                defaultValue=""
                size="small"
                error={error}
                value={duplicateRolename}
                helperText={error ? "Role Name is required." : ""}
                onChange={(e) => {
                  setDuplicateRolename(e.target.value);
                }}
              />
              <Typography
                style={{
                  color: "#02273C",
                  fontFamily: "Roboto Light",
                  fontSize: 14,
                  fontWeight: 500,
                  lineHeight: "1.5rem",
                }}
              >
                Please add a role name above
              </Typography>
            </div>
          </div>
        </ConfirmModal>
        <ConfirmModal
          open={op}
          title={"Please Confirm"}
          onRequestCancel={closeThis}
          onRequestConfirm={() => deleteRoleById(selectedRole.id)}
        >
          <Typography
            style={{
              margin: 2,
              fontSize: 20,
              fontWeight: 500,
              lineHeight: "1.5rem",
            }}
          >
            You are{" "}
            <span style={{ textDecoration: "underline", fontWeight: 900 }}>
              removing
            </span>{" "}
            the{" "}
            <span style={{ fontWeight: 700, color: "#0468B1" }}>
              {roleData.name}
            </span>{" "}
            role from the platform. This action cannot be undone and you will
            need to recreate the role to add it back if you change your mind.{" "}
            <span style={{ fontWeight: 900 }}>
              Are you sure you want to continue?
            </span>
          </Typography>
        </ConfirmModal>
      </div>
      <div>
        <Typography
          style={{
            letterSpacing: 0.15,
            fontWeight: 300,
            fontSize: 16,
            color: "#02273C",
          }}
        >
          Description
          <Acl permission={PERMISSION_LIST.ROLES_WRITE}>
            {!showEditRoleDesc && (
              <CreateIcon
                id={"create"}
                sx={{
                  "&:hover": { color: "#0A85C7" },
                  cursor: "pointer",
                  color: "#02273C",
                }}
                style={{ height: 19, width: 19, marginLeft: 10 }}
                onClick={() => {
                  setShowEditRoleName(false);
                  setShowEditRoleDesc(true);
                }}
              />
            )}
          </Acl>
        </Typography>
        <div style={{ marginBottom: 30 }}>
          {!showEditRoleDesc && (
            <div style={{ display: "flex", width: "90%" }}>
              {" "}
              <Typography style={{ fontWeight: 600, fontSize: 14 }}>
                {roleData.description}
              </Typography>
            </div>
          )}
          {showEditRoleDesc && (
            <div style={{ display: "flex" }}>
              <TextField
                sx={{ width: "100%", height: 104, background: "#F6FDFF" }}
                id="outlined-helperText"
                minRows={3}
                multiline={true}
                background-color="white"
                value={description}
                inputProps={{ maxLength: 200 }}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />

              <div
                style={{
                  display: "flex",
                  padding: "0.625rem 0.625rem 0 0.625rem",
                  columnGap: "0.625rem",
                  alignItems: "end",
                }}
              >
                <CheckIcon
                  sx={{
                    "&:hover": { color: "#0A85C7" },
                    width: 34,
                    height: 34,
                    cursor: "pointer",
                  }}
                  id={"editSave"}
                  onClick={handleEditSave}
                />
                <CloseIcon
                  sx={{
                    "&:hover": { color: "#0A85C7" },
                    width: 34,
                    height: 34,
                    cursor: "pointer",
                  }}
                  id={"editRole"}
                  onClick={() => {
                    setShowEditRoleDesc(false);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <Divider></Divider>
      <div>
        <p
          style={{
            letterSpacing: 0.15,
            fontWeight: 300,
            fontSize: 16,
            color: "#02273C",
          }}
        >
          Permissions & Scope
        </p>
        <div style={{ display: "flex", justifyContent: "center" }}>
          {" "}
          <PermissionTable data={roleData.permissionTable} />{" "}
        </div>
        <br></br>
      </div>

      <div
        style={{
          justifyContent: "center",
          width: "100%",
          marginBottom: "10%",
          display: "flex",
          gap: "4%",
        }}
      >
        <Acl permission={PERMISSION_LIST.ROLES_WRITE}>
          <Button
            onClick={() => {
              setShowDuplicateRole(true);
              setDuplicateRolename("");
            }}
            id={"duplicateRole"}
            variant="default"
          >
            Duplicate Role
          </Button>
        </Acl>
        <Acl permission={PERMISSION_LIST.ROLES_EXECUTE}>
          <Button onClick={openThis} id={"deleteRole"} status="alert">
            Delete Role
          </Button>
        </Acl>
      </div>
    </>
  );
};

export default connector(OverviewInfo);
