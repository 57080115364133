import * as React from "react";
import DWHeader from "./DWHeader";
import { Description } from "../subcomponents/Description";
import DWCarousel from "./DWCarousel";
import CardView from "../Catalog/CardView";

const DigitalWorkers: React.FC = () => {
  const data = [
    {
      catalogId: 1,
      logo: require("../../../assets/OttomationDG.png"),
      serviceName: "Digital Worker: Otto Mation",
      majorVersion: 1,
      minorVersion: 0,
      plan: "Free Trial Available",
      shortDescription:
        "Otto Mation is a digital assistant designed to proactively alert users about the status and completion of batch processes through email, MS Teams, and other communication channels.",
      tags: {
        function: "Tax Insights",
        industry: "Cross-Industry",
        serviceType: "Analytics Service",
      },
      ribbon: "Popular",
    },
    {
      catalogId: 2,
      logo: require("../../../assets/ReeseDG.png"),
      serviceName: "Digital Worker: Reese",
      majorVersion: 1,
      minorVersion: 0,
      shortDescription:
        "Reese Sources is a cloud management bot specializing in provisioning, overseeing, and analyzing the utilization of databases and other cloud infrastructure components.",
      ribbon: "Experimental",
      plan: "Free Trial Available",
      tags: {
        function: "Tax Insights",
        industry: "Cross-Industry",
        serviceType: "Analytics Service",
      },
    },
    {
      catalogId: 3,
      logo: require("../../../assets/ValDG.png"),
      serviceName: "Digital Worker: Val",
      majorVersion: 1,
      minorVersion: 0,
      shortDescription:
        "Val E. Dader is a digital expert focused on performing supplementary validations to ensure data is pristine and ready for downstream processes.",
      tags: {
        function: "Tax Insights",
        industry: "Cross-Industry",
        serviceType: "Analytics Service",
      },
    },
  ];

  const items = data;

  return (
    <>
      <DWHeader />
      <Description
        Typography="Your Blue Prism Environments"
        title="Your Blue Prism Environments"
      />
      <DWCarousel />
      <CardView data={items} />
    </>
  );
};
export default DigitalWorkers;
