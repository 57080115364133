import styled from "styled-components";

export const PageHeader = styled.div`
  color: var(--primary);
  font-size: 2rem;
  font-weight: 700;
  line-height: 20px;
  margin: 21px 0;
  text-align: left;
`;

export const Flex = styled.div<{ gap?: string }>`
  display: flex;
  gap: ${(props) => props.gap || "0"};
`;