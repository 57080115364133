import { useState, useEffect, FC } from "react";
import Typography from "@mui/material/Typography";
import { Button as MUIButton } from "@mui/material";
import { Button } from "@ssce/ui-shared-library";
import IconButton from "@mui/material/IconButton";
import ModalC from "@mui/material/Modal";
import { Table } from "@ssce/ui-shared-library";
import FilterPopover from "../../../filter-popover/FilterPopover";
import RolesDetails from "./RoleDetils";
import CreateRoles from "./CreateRoles";
import { Modal } from "../Modal";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetchRoles,
  setSelectRoles,
  setShowHideRolesDetails,
  setRolesOverviewDetailsLoading,
  setShowCreateRole,
} from "../../../../redux/rolesManagement/actions";
import { useDispatch } from "react-redux";
import Acl from "../../subcomponents/Acl";
import { PERMISSION_LIST } from "../../../../constants";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";

const mapStateToProps = (state: AppState) => ({
  roles: state.rolesManagement.roleList,
  isLoading: state.rolesManagement.isLoading,
  showDetails: state.rolesManagement.showDetails,
  showCreateRole: state.rolesManagement.showCreateRole,
});

const mapDispatchToProps = {
  fetchRoles,
  setSelectRoles,
  setShowHideRolesDetails,
  setRolesOverviewDetailsLoading,
  setShowCreateRole,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const RolesTab: FC<Props> = ({
  fetchRoles,
  setSelectRoles,
  setShowHideRolesDetails,
  setRolesOverviewDetailsLoading,
  setShowCreateRole,
  roles,
  isLoading,
  showDetails,
  showCreateRole,
}) => {
  const dispatch = useDispatch();
  const [letterFilter, setLetterFilterValue] = useState("");
  const [permissionFilter, setPermissionFilterValue] = useState<string[]>([]);

  useEffect(() => fetchRoles(), [fetchRoles]);

  const filteredData = () => {
    let filteredArray = roles;

    filteredArray = filteredArray.filter(
      (r) => r.name.toLowerCase().startsWith(letterFilter.toLowerCase()) && r,
    );

    if (permissionFilter.length > 0) {
      filteredArray = filteredArray.filter((r) => {
        let s = false;
        permissionFilter.forEach((f) => {
          r.permissionTable.forEach((item: any) => {
            if (item[f]) s = true;
          });
        });
        return s;
      });
    }
    return filteredArray;
  };
  if (isLoading) return <LoadingAnimation />;
  return (
    <>
      <div
        style={{
          marginTop: "0.625rem",
          display: "flex",
          flex: "100%",
          flexWrap: "wrap",
          gap: "100%",
          marginBottom: "3.125rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flex: "100%",
            flexWrap: "wrap",
            gap: "1%",
          }}
        >
          <Typography
            variant="h3"
            marginBottom={"1.25rem"}
            style={{ fontSize: "1.563rem", color: "#02273C" }}
          >
            Roles
          </Typography>
          <Typography
            style={{
              color: "#90A8B5",
              fontSize: "0.875rem",
              fontWeight: "500",
              marginTop: "0.5rem",
              width: "25rem",
            }}
          >
            A total of <b style={{ color: "#0468B1" }}>{roles.length}</b>
          </Typography>
        </div>
        <Modal
          showModal={showDetails}
          setShowModal={() => dispatch(setShowHideRolesDetails(false))}
        >
          <RolesDetails />
        </Modal>
        <ModalC open={showCreateRole} onClose={() => setShowCreateRole(false)}>
          <CreateRoles />
        </ModalC>
        <Acl permission={PERMISSION_LIST.ROLES_WRITE}>
          <div
            style={{
              marginBottom: "1.25rem",
              display: "flex",
              flexDirection: "row",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={() => setShowCreateRole(true)} id={"createRole"}>
              + CREATE ROLE
            </Button>
          </div>
        </Acl>
        <div
          style={{
            marginBottom: "1.25rem",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "row",
              marginTop: 10,
            }}
          >
            <IconButton
              key={"ALL"}
              aria-label="delete"
              size="small"
              id={"ALL"}
              onClick={() => setLetterFilterValue("")}
            >
              <span
                style={
                  "" === letterFilter
                    ? {
                        color: "#0A85C7",
                        textDecoration: "underline",
                        margin: "0.625rem",
                      }
                    : { color: "#B6C7D1", margin: "0.625rem" }
                }
              >
                {"ALL"}
              </span>
            </IconButton>
            {[
              "A",
              "B",
              "C",
              "D",
              "E",
              "F",
              "G",
              "H",
              "I",
              "J",
              "K",
              "L",
              "M",
              "N",
              "P",
              "Q",
              "R",
              "S",
              "T",
              "U",
              "V",
              "W",
              "X",
              "Y",
              "Z",
            ].map((l) => (
              <IconButton
                key={l}
                aria-label="delete"
                id={l}
                size="small"
                onClick={() => setLetterFilterValue(l)}
              >
                <span
                  style={
                    l === letterFilter
                      ? { color: "#0A85C7", textDecoration: "underline" }
                      : { color: "#B6C7D1" }
                  }
                >
                  {l}
                </span>
              </IconButton>
            ))}
            <div style={{ margin: "0.313rem 0 0.313rem 0.938rem" }}>
              <FilterPopover
                filterName={"Permissions:"}
                labels={["execute", "read", "write"]}
                onApplyClk={(list) => {
                  setPermissionFilterValue(list);
                }}
                clearFilter={permissionFilter.length === 0}
              />
            </div>
          </div>
          {(letterFilter || permissionFilter.length > 0) && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <MUIButton
                variant="text"
                id={"clearAllFilters"}
                onClick={() => {
                  setLetterFilterValue("");
                  setPermissionFilterValue([]);
                }}
                sx={{ "&:hover": { color: "#33BDE9" }, color: "#0A85C7" }}
                style={{
                  fontWeight: 800,
                  textDecoration: "underline",
                  marginRight: "0.625rem",
                }}
              >
                Clear All Filters{" "}
              </MUIButton>{" "}
            </div>
          )}
        </div>
        <Table
          id={"table"}
          showSearchFilter
          data={filteredData()}
          columns={[
            {
              title: "Role",
              dataIndex: "name",
              sorter: true,
              defaultSortOrder: "asc",
            },
            {
              title: "Description",
              dataIndex: "description",
              sorter: true,
            },
          ]}
          onRowClick={(row) => {
            dispatch(setSelectRoles(row));
            dispatch(setShowHideRolesDetails(true));
            dispatch(setRolesOverviewDetailsLoading(true));
          }}
          showPagination
        />
      </div>
    </>
  );
};

export default connector(RolesTab);
