import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { styled as MuiStyled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import DownloadPDF from "../../../../../../assets/DownloadPDF.png";
import PDF from "../../../../../../assets/PDF.png";
import styled from "styled-components";

const Title = styled.div`
  color: rgb(2, 39, 60);
  font-size: 1.875rem;
  font-weight: 500;
  margin-bottom: 2.8rem;
`;
const InvoiceTitle = styled.div`
  line-height: 1.25rem;
  color: #02273c;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 1.25rem;
  margin-right: 18.75rem;
`;
const DateLabel = styled.div`
  line-height: 1.25rem;
  color: #02273c;
  font-size: 1rem;
  font-weight: 400;
`;
const DateValue = styled.div`
  line-height: 1.25rem;
  color: #02273c;
  font-size: 1rem;
  font-weight: 600;
  margin-left: 0.625rem;
`;

export const DummyInvoices: React.FC = () => {
  const Item = MuiStyled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(2),

    color: theme.palette.text.secondary,
  }));

  return (
    <>
      <Box
        sx={{ width: "100%", marginBottom: "6.25rem", marginTop: "2.188rem" }}
      >
        <Title>Recent Invoices</Title>
        <Stack spacing={2}>
          <Item
            style={{
              display: "flex",
              width: "65.625rem",
              border: "0.063rem solid #CBD9E1",
              borderRadius: "0.25rem 0 0 0.25rem",
            }}
          >
            <img alt={"pdf"} src={PDF}></img>
            <InvoiceTitle>Invoice_12323.pdf</InvoiceTitle>
            <DateLabel>Date of invoice:</DateLabel>
            <DateValue>May 15th, 2024</DateValue>
            <img
              alt={"downloadPdf"}
              src={DownloadPDF}
              style={{ marginLeft: "15rem" }}
            ></img>
          </Item>
          <Item
            style={{
              display: "flex",
              width: "65.625rem",
              border: "0.063rem solid #CBD9E1",
              borderRadius: "0.25rem 0 0 0.25rem",
            }}
          >
            <img alt={"pdf"} src={PDF}></img>
            <InvoiceTitle>Invoice_13455.pdf</InvoiceTitle>
            <DateLabel>Date of invoice:</DateLabel>
            <DateValue>April 15th, 2024</DateValue>
            <img
              alt={"downloadPdf"}
              src={DownloadPDF}
              style={{ marginLeft: "15rem" }}
            ></img>
          </Item>
        </Stack>
      </Box>
    </>
  );
};
