import { useState, useEffect, FC } from "react";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button } from "@ssce/ui-shared-library";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Accordion from "../Accordion";
import AccordionUser from "./AccordionUsers";
import { Permissions, UserData, Projects } from "../../../../common/dataTypes";
import InfoIcon from "@mui/icons-material/Info";
import CreateIcon from "@mui/icons-material/Create";
import Tooltip from "@mui/material/Tooltip";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetcPermissions,
  fetcProjects,
  setShowCreateRole,
  createRole,
} from "../../../../redux/rolesManagement/actions";
import { fetchUsers } from "../../../../redux/userManagement/actions";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";

const mapStateToProps = (state: AppState) => ({
  permissionsList: state.rolesManagement.permissionsList,
  isRoleDetailsLoading: state.rolesManagement.isRoleDetailsLoading,
  users: state.userManagement.usersList,
  projectsList: state.rolesManagement.projectsList,
});

const mapDispatchToProps = {
  fetcPermissions,
  fetchUsers,
  fetcProjects,
  setShowCreateRole,
  createRole,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const CreateRoles: FC<Props> = ({
  permissionsList,
  users,
  projectsList,
  isRoleDetailsLoading,
  setShowCreateRole,
  fetcPermissions,
  fetchUsers,
  fetcProjects,
  createRole,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedPermissions, setSelectedPermissions] = useState<Permissions[]>(
    [],
  );
  const [selectedUser, setSelectedUser] = useState<UserData[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<Projects[]>([]);

  const handleNext = () => {
    if (name.trim().length === 0) {
      setError(true);
      return;
    }
    setError(false);
    if (activeStep !== steps.length - 1)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCnfm = () => {
    console.log(
      "selectedUser,selectedPermissions,selectedProjects",
      selectedUser,
      selectedPermissions,
      selectedProjects,
    );

    const payload = {
      name,
      description,
      users: selectedUser.map((item) => {
        return { id: item.id };
      }),
      permissions: selectedPermissions.map((item) => {
        return { id: item.id };
      }),
      projects: selectedProjects.map((item) => {
        return { id: item.projectId };
      }),
    };
    console.log("payload", payload);
    createRole(payload);
  };

  const handleBack = () => {
    if (activeStep !== 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    fetcPermissions();
    fetchUsers();
    fetcProjects();
  }, [fetcPermissions, fetcProjects, fetchUsers]);

  const renderSelectedPermissions = () => {
    return selectedPermissions.map((item) => {
      return (
        <Accordion
          key={item.name}
          name={item.name}
          iconHeader={<ClearIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            setSelectedPermissions([
              ...selectedPermissions.filter((r) => item.id !== r.id),
            ]);
          }}
        >
          <div style={{ padding: "0.5rem 0.5rem" }}>
            <Typography
              style={{
                color: "#0A85C7",
                fontSize: 12,
                marginBottom: "0.25rem",
              }}
            >
              Permission Explanation
            </Typography>
            <Typography
              style={{
                color: "#02273C",
                fontWeight: 400,
                fontSize: 14,
                margin: 0,
              }}
            >
              {item.description}
            </Typography>
          </div>
        </Accordion>
      );
    });
  };
  const renderAssignPermissions = () => {
    return (
      <div
        style={{
          border: "0.063rem solid #CBD9E1",
          width: 928,
          padding: "1rem 1.5rem",
          borderRadius: 4,
        }}
      >
        <Stack spacing={2} sx={{ width: 880, backgroundColor: "#F6FDFF" }}>
          <Autocomplete
            multiple
            id="create-role-assign-permissions"
            size="medium"
            value={selectedPermissions}
            sx={{
              "& .MuiChip-root": {
                backgroundColor: "#B3D9EE",
                color: "#075E8D",
              },
              "& .MuiChip-root .MuiChip-deleteIcon": { color: "#0A85C7" },
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, values) => {
              setSelectedPermissions(values);
            }}
            options={permissionsList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Permissions" placeholder="" />
            )}
          />
        </Stack>
        <p
          style={{
            color: "#577687",
            marginTop: 0,
            marginBottom: "1rem",
            fontSize: 12,
          }}
        >
          Please use the dropdown box above or type in a Permission name to
          select it.
        </p>
        <Typography
          variant="subtitle2"
          style={{
            marginBottom: "1rem",
            color: "#02273C",
            fontWeight: 500,
            fontSize: "1.125rem",
          }}
        >
          Permission Addition Details
        </Typography>
        {renderSelectedPermissions()}
      </div>
    );
  };

  const renderSelectedUsers = (props: { halfWidth: boolean }) => {
    return selectedUser.map((item) => {
      console.log(item);
      return (
        <AccordionUser
          {...props}
          key={item.id}
          email={item.email}
          name={`${item.firstName} ${item.lastName}`}
          iconHeader={<ClearIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            setSelectedUser([...selectedUser.filter((r) => item.id !== r.id)]);
          }}
        >
          <Typography style={{ color: "#0A85C7", fontSize: 12, marginLeft: 2 }}>
            Current Roles
          </Typography>
          <div
            style={{
              color: "#02273C",
              fontWeight: 400,
              fontSize: 14,
              marginLeft: 2,
            }}
          >
            {item.roles?.map((r, i, arr) => (
              <span key={r.id}>
                {" "}
                {r.name} {arr.length - 1 !== i ? "," : ""}{" "}
              </span>
            ))}
          </div>
          <br></br>
        </AccordionUser>
      );
    });
  };

  const renderAssignUsers = () => {
    return (
      <div
        style={{
          border: "0.063rem solid #CBD9E1",
          width: 928,
          padding: "1rem 1.5rem",
          borderRadius: 4,
        }}
      >
        <Stack spacing={2} sx={{ width: 880, backgroundColor: "#F6FDFF" }}>
          <Autocomplete
            multiple
            id="create-role-assign-users"
            size="medium"
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedUser}
            sx={{
              "& .MuiChip-root": {
                backgroundColor: "#B3D9EE",
                color: "#075E8D",
              },
              "& .MuiChip-root .MuiChip-deleteIcon": { color: "#0A85C7" },
            }}
            onChange={(_, values) => {
              setSelectedUser(values);
            }}
            options={users}
            getOptionLabel={(option) =>
              option.firstName + " " + option.lastName
            }
            renderInput={(params) => (
              <TextField {...params} label="Users" placeholder="" />
            )}
          />
        </Stack>
        <p
          style={{
            color: "#577687",
            marginTop: 0,
            marginBottom: "1rem",
            fontSize: 12,
          }}
        >
          Please use the dropdown box above or type in a Users name to select
          it.
        </p>
        <Typography
          variant="subtitle2"
          style={{
            marginBottom: "1rem",
            color: "#02273C",
            fontWeight: 500,
            fontSize: "1.125rem",
          }}
        >
          Users Addition Details
        </Typography>
        {renderSelectedUsers({ halfWidth: false })}
      </div>
    );
  };

  const renderSelectedProjects = () => {
    return selectedProjects.map((item) => {
      return (
        <Accordion
          key={item.name}
          name={item.name}
          iconHeader={<ClearIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            setSelectedProjects([
              ...selectedProjects.filter((r) => item.projectId !== r.projectId),
            ]);
          }}
        >
          <div style={{ padding: "0.5rem 3.75rem" }}>
            <Typography
              style={{
                color: "#0979B5",
                fontWeight: 500,
                fontSize: 12,
                marginBottom: "0.25rem",
              }}
            >
              Resources Managed
            </Typography>
            <div style={{ margin: 0, fontSize: 16 }}>
              {item.instances.map((i, r, arr) => (
                <span key={i.instanceId}>
                  X {i.instanceName} {arr.length - 1 !== r ? "," : ""}{" "}
                </span>
              ))}
            </div>
          </div>
        </Accordion>
      );
    });
  };

  const renderAssignProjects = () => {
    return (
      <div
        style={{
          border: "0.063rem solid #CBD9E1",
          width: 928,
          padding: "1rem 1.5rem",
          borderRadius: 4,
        }}
      >
        <Stack spacing={2} sx={{ width: 880, backgroundColor: "#F6FDFF" }}>
          <Autocomplete
            multiple
            id="create-role-assign-projects"
            size="medium"
            value={selectedProjects}
            sx={{
              "& .MuiChip-root": {
                backgroundColor: "#B3D9EE",
                color: "#075E8D",
              },
              "& .MuiChip-root .MuiChip-deleteIcon": { color: "#0A85C7" },
            }}
            isOptionEqualToValue={(option, value) =>
              option.projectId === value.projectId
            }
            onChange={(_, values) => {
              setSelectedProjects(values);
            }}
            options={projectsList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Projects" placeholder="" />
            )}
          />
        </Stack>
        <p
          style={{
            color: "#577687",
            marginTop: 0,
            marginBottom: "1rem",
            fontSize: 12,
          }}
        >
          Please use the dropdown box above or type in a Projects name to select
          it.
        </p>
        <Typography
          variant="subtitle2"
          style={{
            marginBottom: "1rem",
            color: "#02273C",
            fontWeight: 500,
            fontSize: "1.125rem",
          }}
        >
          Projects Addition Details
        </Typography>
        {renderSelectedProjects()}
      </div>
    );
  };

  const renderReview = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 18,
          width: 763,
          height: 507,
          overflow: "auto",
          padding: "0.625rem 0 0",
        }}
      >
        <div
          style={{
            height: 36,
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              color: "#0A85C7",
              fontSize: 34,
              fontWeight: 600,
              lineHeight: "2.25rem",
            }}
          >
            <>
              {name.length < 30 ? (
                name
              ) : (
                <Tooltip
                  title={<span style={{ fontSize: "1.125rem" }}>{name}</span>}
                >
                  <span>{name.substring(0, 30) + "..."}</span>
                </Tooltip>
              )}
            </>
          </Typography>
          <CreateIcon
            id={"create"}
            sx={{
              "&:hover": { color: "#0A85C7" },
              cursor: "pointer",
              color: "#02273C",
            }}
            style={{ width: 19, height: 19 }}
            onClick={() => setActiveStep(0)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 1.5rem",
            border: "0.063rem solid #CBD9E1",
            borderRadius: 4,
            width: 763,
            gap: 16,
          }}
        >
          <Typography
            variant="h6"
            style={{ fontSize: 18, lineHeight: "1.375rem", color: "#000000" }}
          >
            Permissions added
          </Typography>
          <div>{renderSelectedPermissions()}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 1.5rem",
            border: "0.063rem solid #CBD9E1",
            borderRadius: 4,
            width: 763,
            gap: 16,
          }}
        >
          <Typography
            variant="h6"
            style={{ fontSize: 18, lineHeight: "1.375rem", color: "#000000" }}
          >
            Users Added
          </Typography>
          <div
            style={{
              display: "flex",
              gap: "0.125rem 0.5rem",
              flexWrap: "wrap",
              columnGap: 10,
            }}
          >
            {renderSelectedUsers({ halfWidth: true })}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 1.5rem",
            border: "0.063rem solid #CBD9E1",
            borderRadius: 4,
            width: 763,
            gap: 16,
          }}
        >
          <Typography
            variant="h6"
            style={{ fontSize: 18, lineHeight: "1.375rem", color: "#000000" }}
          >
            Projects added
          </Typography>
          <div>{renderSelectedProjects()}</div>
        </div>
      </div>
    );
  };

  const showOptionDlg = activeStep !== 0 && activeStep !== steps.length - 1;
  const start = activeStep === 0;
  const end = activeStep === steps.length - 1;

  if (isRoleDetailsLoading)
    return (
      <Box sx={style}>
        <LoadingAnimation />
      </Box>
    );
  return (
    <Box sx={style}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "0.625rem 1.25rem",
            height: "4.438rem",
          }}
        >
          <ClearIcon
            id={"clearIcon"}
            onClick={() => setShowCreateRole(false)}
            style={{
              position: "absolute",
              right: "1.25rem",
              bottom: "1.25rem",
              top: "1.25rem",
              cursor: "pointer",
            }}
          />
        </div>
        <div>
          <div style={{ height: "5.5rem" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step
                  key={label}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "#3CC13B",
                    },
                  }}
                >
                  <StepLabel>
                    <span style={{ color: "black" }}>{label}</span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div
            style={{
              height: "29.125rem",
              overflowX: "hidden",
              padding: "0.313rem 3.125rem",
              display: "flex",
              flexDirection: "column",
              gap: 21,
            }}
          >
            {showOptionDlg && (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  gap: 8,
                  padding: "0.625rem 1rem",
                  height: "5.25rem",
                  width: "56.875rem",
                  alignItems: "center",
                  border: "0.063rem solid #0A85C7",
                  borderRadius: 4,
                }}
              >
                <div style={{ width: 24, height: 24, marginBottom: "auto" }}>
                  <InfoIcon sx={{ color: "#0A85C7" }} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ fontSize: 16, fontWeight: 700 }}>
                    Optional Step
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    This step in the process is optional and can be skipped for
                    now, with the option to add it later from the{" "}
                    <span style={{ fontWeight: 900 }}>Roles</span> page. To skip
                    this step just press the “Next” button at the bottom of this
                    modal.
                  </Typography>
                </div>
              </div>
            )}
            {activeStep === 0 && (
              <div
                style={{ display: "flex", flexDirection: "column", gap: 40 }}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: "#02273C",
                    fontWeight: 500,
                    fontSize: "1.25rem",
                    height: 24,
                    width: 63,
                  }}
                >
                  Details
                </Typography>
                <TextField
                  style={{ width: "100%", height: 56, background: "#F6FDFF" }}
                  id="outlined-helperText"
                  label="Role Name"
                  background-color="white"
                  value={name}
                  inputProps={{ maxLength: 40 }}
                  error={error}
                  helperText={error ? "Role Name is required." : ""}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", height: 104, background: "#F6FDFF" }}
                  id="outlined-helperText"
                  minRows={3}
                  multiline={true}
                  label="Description"
                  background-color="white"
                  value={description}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
            )}

            {activeStep === 1 && (
              <>
                <Typography
                  variant="h6"
                  style={{
                    color: "#02273C",
                    fontWeight: 500,
                    fontSize: "1.25rem",
                  }}
                >
                  Assign Permissions
                </Typography>
                {renderAssignPermissions()}
              </>
            )}
            {activeStep === 2 && (
              <>
                <Typography
                  variant="h6"
                  style={{
                    color: "#02273C",
                    fontWeight: 500,
                    fontSize: "1.25rem",
                  }}
                >
                  Assign Users
                </Typography>
                {renderAssignUsers()}
              </>
            )}
            {activeStep === 3 && (
              <>
                <Typography
                  variant="h6"
                  style={{
                    color: "#02273C",
                    fontWeight: 500,
                    fontSize: "1.25rem",
                  }}
                >
                  Assign Projects
                </Typography>
                {renderAssignProjects()}
              </>
            )}
            <div
              style={{
                height: 711,
                overflowY: "auto",
                padding: "0.125rem 3.125rem 2rem",
                display: "flex",
                flexDirection: "column",
                gap: 21,
              }}
            >
              {activeStep === 4 && (
                <>
                  {renderReview()}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 21,
                      width: 728,
                      height: "auto",
                      padding: "0.75rem 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 10,
                        width: 598,
                        height: 36,
                        padding: "0 0.625rem",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#0468B1",
                          fontWeight: 700,
                          fontSize: "2.125rem",
                        }}
                      >
                        Please Confirm
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 10,
                        width: 837,
                        height: 68,
                        padding: "0.625rem",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#000000",
                          fontWeight: 500,
                          fontSize: "1.25rem",
                        }}
                      >
                        After carefully reviewing the aforementioned details,
                        you may proceed to finalize by clicking the confirmation
                        button below.{" "}
                        <span style={{ fontWeight: 700 }}>
                          Please click “Confirm” to continue.
                        </span>
                      </Typography>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            background: "#DFE9EF",
            justifyContent: "flex-end",
            display: "flex",
            gap: "0.625rem",
            padding: "0.625rem 1.25rem",
          }}
        >
          {start && (
            <Button
              variant="secondary"
              id={"close"}
              onClick={() => setShowCreateRole(false)}
            >
              Close
            </Button>
          )}
          {!start && (
            <Button id={"back"} onClick={handleBack} variant="secondary">
              Back
            </Button>
          )}
          {!end && (
            <Button onClick={handleNext} variant="default" id={"next"}>
              Next
            </Button>
          )}
          {end && (
            <Button id={"confirm"} onClick={handleCnfm} variant="default">
              Confirm
            </Button>
          )}
        </div>
      </div>
    </Box>
  );
};

const steps = [
  "Details",
  "Assign Permissions",
  "Assign Users",
  "Assign Projects",
  "Review",
];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1028,
  height: 656,
  bgcolor: "#EDF4F8",
  border: "0 solid #000",
  boxShadow:
    "0 0.5rem 0.625rem rgba(2, 39, 60, 0.2), 0 0.375rem 1.875rem rgba(2, 39, 60, 0.12), 0 1rem 1.5rem rgba(2, 39, 60, 0.14)",
  p: 10,
  m: 2,
  lineHeight: 2,
  padding: "0 0 0 0",
};

export default connector(CreateRoles);
