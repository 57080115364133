import * as React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentViewContext } from "../../../ComponentWithContext";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import { Button } from "@ssce/ui-shared-library";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

const Riskthinking: React.FC = () => {
  const navigate = useNavigate();
  const { viewData } = useContext(CurrentViewContext);

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/RiskthinkingAI.png"}
        title={viewData.instanceName}
        subTitle={`riskthinking.AI`}
        contents={
          "Climate-related physical risk reports and data for listed public companies and proprietary assets to enable a deeper understanding of your physical risk exposures."
        }
        tags={[
          {
            label: "Risk Management",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Analytics Service",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs
        title={viewData.instanceName}
        instanceName={viewData.instanceName}
        subscriptionName="Riskthinking.AI: Beyond Hindsight – Physical Risk Analysis"
        isRiskThinking
        reportTableName={"New PDF Reports"}
      />
    </>
  );
};
export default Riskthinking;
