import { useState } from "react";
import CardView from "../Catalog/CardView";
import PCNoAPIs from "./Cards/PCNoAPIs";
import { ListTogglerValue } from "../subcomponents/CardFilterHeader/tableListToggler";
import { usePrivateCatalogHook } from "./PrivateCatalogHook";
import PCTableView from "./Cards/PCTableView";
import "./PrivateCatalog.css";
import { useNavigate } from "react-router-dom";
import { ErrorNotification } from "@ssce/ui-shared-library";
import { UtilContainer } from "./PrivateCatalog.styles";
import PrivateAPIHeader from "../PrivateCatalog/Cards/Header/PrivateAPIHeader";
import { CardFilterHeader } from "../subcomponents/CardFilterHeader";

const PrivateCatalog = () => {
  const { catalogs, hasError, isLoading } = usePrivateCatalogHook();

  const [searchValue, setSearchValue] = useState<any>("");
  const [listTogglerValue, setListTogglerValue] =
    useState<ListTogglerValue>("list");

  const navigate = useNavigate();

  const handleNavigateTo = (_, id: string) => {
    return navigate(`/private-api-catalog/${id}`);
  };

  const handleToggleChange = (toggleValue: ListTogglerValue) => {
    setListTogglerValue(toggleValue);
  };

  const handleChangeForSearch = (e: { target: { value: any } }) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      {hasError && <ErrorNotification />}
      {catalogs.length > 0 || isLoading ? (
        <div>
          <PrivateAPIHeader />
          <UtilContainer>
            <CardFilterHeader
              toggleValue={listTogglerValue}
              searchValue={searchValue}
              handleToggleChange={handleToggleChange}
              handleChangeForSearch={handleChangeForSearch}
            />
          </UtilContainer>

          {listTogglerValue === "list" && (
            <CardView
              data={catalogs}
              searchValue={searchValue}
              handleNavigateTo={handleNavigateTo}
            />
          )}
          {listTogglerValue === "table" && (
            <PCTableView
              searchValue={searchValue}
              catalogs={catalogs}
              handleNavigateTo={(data: { catalogId }) => {
                const { catalogId } = data;
                return navigate(`/private-api-catalog/${catalogId}`);
              }}
            />
          )}
        </div>
      ) : (
        <PCNoAPIs />
      )}
    </>
  );
};

export default PrivateCatalog;
