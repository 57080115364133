import { IconContainer } from "./workflowViewerContent.styles";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import ChecklistIcon from "@mui/icons-material/Checklist";
import JoinLeftIcon from "@mui/icons-material/JoinLeft";
import OutputIcon from "@mui/icons-material/Output";
import SourceIcon from "@mui/icons-material/Source";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const Database = (
  <IconContainer type={"database"}>
    <svg
      fill="#0A85C7"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M480-120q-151 0-255.5-46.5T120-280v-400q0-66 105.5-113T480-840q149 0 254.5 47T840-680v400q0 67-104.5 113.5T480-120Zm0-479q89 0 179-25.5T760-679q-11-29-100.5-55T480-760q-91 0-178.5 25.5T200-679q14 30 101.5 55T480-599Zm0 199q42 0 81-4t74.5-11.5q35.5-7.5 67-18.5t57.5-25v-120q-26 14-57.5 25t-67 18.5Q600-528 561-524t-81 4q-42 0-82-4t-75.5-11.5Q287-543 256-554t-56-25v120q25 14 56 25t66.5 18.5Q358-408 398-404t82 4Zm0 200q46 0 93.5-7t87.5-18.5q40-11.5 67-26t32-29.5v-98q-26 14-57.5 25t-67 18.5Q600-328 561-324t-81 4q-42 0-82-4t-75.5-11.5Q287-343 256-354t-56-25v99q5 15 31.5 29t66.5 25.5q40 11.5 88 18.5t94 7Z" />
    </svg>
  </IconContainer>
);
export const Cloud = (
  <IconContainer type={"cloud"}>
    <svg
      fill="#0A85C7"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M260-160q-91 0-155.5-63T40-377q0-78 47-139t123-78q25-92 100-149t170-57q117 0 198.5 81.5T760-520q69 8 114.5 59.5T920-340q0 75-52.5 127.5T740-160H520q-33 0-56.5-23.5T440-240v-206l-64 62-56-56 160-160 160 160-56 56-64-62v206h220q42 0 71-29t29-71q0-42-29-71t-71-29h-60v-80q0-83-58.5-141.5T480-720q-83 0-141.5 58.5T280-520h-20q-58 0-99 41t-41 99q0 58 41 99t99 41h100v80H260Zm220-280Z" />
    </svg>
  </IconContainer>
);
export const DoubleCheck = (
  <IconContainer type={"doubleCheck"}>
    <svg
      fill="#37B036"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M268-240 42-466l57-56 170 170 56 56-57 56Zm226 0L268-466l56-57 170 170 368-368 56 57-424 424Zm0-226-57-56 198-198 57 56-198 198Z" />
    </svg>
  </IconContainer>
);
export const Crop = (
  <IconContainer type={"crop"}>
    <svg
      fill="#02273C"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M680-40v-160H280q-33 0-56.5-23.5T200-280v-400H40v-80h160v-160h80v640h640v80H760v160h-80Zm0-320v-320H360v-80h320q33 0 56.5 23.5T760-680v320h-80Z" />
    </svg>
  </IconContainer>
);
export const Report = (
  <IconContainer type={"report"}>
    <svg
      fill="#0A85C7"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M320-480v-80h320v80H320Zm0-160v-80h320v80H320Zm-80 240h300q29 0 54 12.5t42 35.5l84 110v-558H240v400Zm0 240h442L573-303q-6-8-14.5-12.5T540-320H240v160Zm480 80H240q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h480q33 0 56.5 23.5T800-800v640q0 33-23.5 56.5T720-80Zm-480-80v-640 640Zm0-160v-80 80Z" />
    </svg>
  </IconContainer>
);
export const HourGlass = (
  <IconContainer type={"hourglass"}>
    <svg
      fill="#E7AA12"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M320-160h320v-120q0-66-47-113t-113-47q-66 0-113 47t-47 113v120ZM160-80v-80h80v-120q0-61 28.5-114.5T348-480q-51-32-79.5-85.5T240-680v-120h-80v-80h640v80h-80v120q0 61-28.5 114.5T612-480q51 32 79.5 85.5T720-280v120h80v80H160Z" />
    </svg>
  </IconContainer>
);
export const PowerIcon = (
  <IconContainer type={"powerIcon"}>
    <svg
      fill="#E7AA12"
      xmlns="http://www.w3.org/2000/svg"
      height="24"
      viewBox="0 -960 960 960"
      width="24"
    >
      <path d="M320-160h320v-120q0-66-47-113t-113-47q-66 0-113 47t-47 113v120ZM160-80v-80h80v-120q0-61 28.5-114.5T348-480q-51-32-79.5-85.5T240-680v-120h-80v-80h640v80h-80v120q0 61-28.5 114.5T612-480q51 32 79.5 85.5T720-280v120h80v80H160Z" />
    </svg>
  </IconContainer>
);
export const BuildCircle = (
  <IconContainer type={"cloud"}>
    <BuildCircleIcon sx={{ color: "var(--primary)" }} />
  </IconContainer>
);
export const Checklist = (
  <IconContainer type={"cloud"}>
    <ChecklistIcon sx={{ color: "var(--primary)" }} />
  </IconContainer>
);
export const JoinLeft = (
  <IconContainer type={"cloud"}>
    <JoinLeftIcon sx={{ color: "var(--primary)" }} />
  </IconContainer>
);
export const Output = (
  <IconContainer type={"cloud"}>
    <OutputIcon sx={{ color: "var(--primary)" }} />
  </IconContainer>
);
export const Source = (
  <IconContainer type={"cloud"}>
    <SourceIcon sx={{ color: "var(--primary)" }} />
  </IconContainer>
);
export const CheckCircle = (
  <IconContainer type={"cloud"}>
    <CheckCircleOutlineIcon sx={{ color: "var(--primary)" }} />
  </IconContainer>
);
