import React, { useState } from "react";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
import { DummyOverviewContent } from "./dummyOverviewContent";
import { DummyReportsTab } from "./dummyReportsTab";
import MyServiceInstanceManagement from "../../../myServiceInstanceManagement";

export const DummyCardTabs: React.FC<{
  title: string;
  instanceName: string;
  nextPaymentDueDate?: string;
  subscriptionName?: string;
  isRiskThinking?: boolean;
  reportTableName?: string;
}> = ({
  title,
  instanceName,
  subscriptionName,
  nextPaymentDueDate,
  isRiskThinking,
  reportTableName,
}) => {
  const [tablValue, setTabValue] = useState("1");

  const handleOnTabChange = (_, newValue: string) => {
    setTabValue(newValue);
  };
  return (
    <Box sx={{ typography: "body1", marginLeft: "1.063rem" }}>
      <TabContext value={tablValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabList"}
            onChange={handleOnTabChange}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Reports" value="2" />
            <Tab label="Manage Plan" value="3" />
            <Tab label="Billing" value="4" />
            <Tab label="Settings" value="5" />
            <Tab label="Documentation" value="6" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <DummyOverviewContent
            title={title}
            subscriptionName={subscriptionName}
            instanceName={instanceName}
            nextPaymentDueDate={nextPaymentDueDate}
            reportTableName={reportTableName}
            isRiskThinking={isRiskThinking}
          />
        </TabPanel>
        <TabPanel value="2">
          <DummyReportsTab
            title={title}
            reportTableName={reportTableName}
            isRiskThinking={isRiskThinking}
          />
        </TabPanel>
        <TabPanel value="3"></TabPanel>
        <TabPanel value="4"></TabPanel>
        <TabPanel value="5">
          <MyServiceInstanceManagement />
        </TabPanel>
        <TabPanel value="6"></TabPanel>
      </TabContext>
    </Box>
  );
};
