export const convertTypescriptToContent = (ts) => {
  try {
    const indent = () => `\u00A0\u00A0\u00A0\u00A0`;
    const [start, end] = ts.split("{");
    const tsWithoutBraceArray = end.split(";");
    return [
      `${start} {`,
      ...tsWithoutBraceArray.map((j, index) =>
        tsWithoutBraceArray.length === index + 1 ? j : `${indent()}${j};`,
      ),
    ];
  } catch {
    return ["An Error has occurred"];
  }
};
export const convertUseCaseJsonToContent = (json) => {
  try {
    const indent = (indentIndex) => {
      let result = "";
      for (let i = 0; i < indentIndex; i++) {
        result += `\u00A0\u00A0\u00A0\u00A0`;
      }
      return result;
    };
    const jsonWithoutStartingBrace = json[0] === "{" && json.slice(1);
    const jsonWithoutBraceArray = jsonWithoutStartingBrace.split("},");
    let indentIndex = 1;
    const prettifyJsonObject = (end) =>
      end.split(',"').map((e, index) => {
        if (index === 0) {
          return `${indent(indentIndex + 1)}"${e.slice(1)},`;
        }
        return `${indent(indentIndex + 1)}"${e},`;
      });
    return [
      "{",
      ...jsonWithoutBraceArray.reduce((acc, cv, index) => {
        if (cv.includes("[")) {
          const [start, end] = cv.split("[");
          const splitEnd = prettifyJsonObject(end);
          if (index === 0) {
            return [
              ...acc,
              `${indent(indentIndex)}${start} [{`,
              ...splitEnd,
              `${indent(indentIndex)}},`,
            ];
          }
          return [...acc, `${start} [`, ...splitEnd];
        }
        if (cv.includes("]")) {
          const [start, end] = cv.split("]");
          const splitStart = prettifyJsonObject(start.slice(0, -1));
          return [
            ...acc,
            `${indent(1)}{`,
            ...splitStart,
            `${indent(1)}${end}]`,
          ];
        }
        if (cv.includes("{")) {
          indentIndex = 1;
          const [start, end] = cv.split("{");
          const splitEnd = prettifyJsonObject(end);
          return [
            ...acc,
            `${indent(indentIndex)}{${start}`,
            ...splitEnd,
            `${indent(indentIndex)}},`,
          ];
        }
        return [...acc, `${indent(indentIndex)}${cv}},`];
      }, []),
      "}",
    ];
  } catch (e) {
    return ["An Error has occurred"];
  }
};
export const convertSchemaJsonToContent = (json) => {
  try {
    const indent = (indentIndex) => {
      let result = "";
      for (let i = 0; i < indentIndex; i++) {
        result += `\u00A0\u00A0\u00A0\u00A0`;
      }
      return result;
    };
    const jsonWithoutStartingBrace = json[0] === "{" && json.slice(1);
    const jsonWithoutBraceArray = jsonWithoutStartingBrace.split("},");
    let indentIndex = 1;
    return [
      "{",
      ...jsonWithoutBraceArray.reduce((acc, cv, index) => {
        if (cv.includes("[")) {
          const [start, end] = cv.split("[");
          if (index === 0) {
            indentIndex++;
            return [
              ...acc,
              `${indent(indentIndex - 1)}${start} [`,
              `${indent(indentIndex)}${end}},`,
            ];
          }
          return [...acc, `${start} [`, `${indent(indentIndex)}${end}`];
        }
        if (cv.includes("]")) {
          const [start, end] = cv.split("]");
          return [
            ...acc,
            `${indent(indentIndex)}${start}`,
            `${indent(indentIndex - 1)}],`,
            `${indent(1)}${end.slice(1).slice(0, -1)}`,
          ];
        }
        return [...acc, `${indent(indentIndex)}${cv}},`];
      }, []),
      "}",
    ];
  } catch {
    return ["An Error has occurred"];
  }
};
