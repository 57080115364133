import { AppThunk } from "../store";
import axios from "axios";

export enum UserActionType {
  SUCCESSFUL_LOGIN = "SUCCESSFUL_LOGIN",
  USER_LOGOUT = "USER_LOGOUT",
}

export const updateUserDetails: (token: string, username: string, email: string) => AppThunk =
  (token: string, username: string, email:string): AppThunk =>
  (dispatch) => {
    axios.defaults.headers.common["authorization"] = "Bearer " + token;
    dispatch(successfulLogin(token, username, email));
    //dispatch(enqueueSnackbar(NotificationLevel.SUCCESS,'Login success'));
  };

export const successfulLogin = (token: string, username: string, email:string) => ({
  type: UserActionType.SUCCESSFUL_LOGIN,
  payload: { token, username, email },
});

export const successfulLogout = () => ({
  type: UserActionType.USER_LOGOUT,
  payload: {},
});

export type UserAction =
  | ReturnType<typeof successfulLogin>
  | ReturnType<typeof successfulLogout>;
