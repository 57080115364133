import React from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import CatalogSubHeading from "./CatalogSubHeading";

export const CatalogFAQ: React.FC<{
  title?: string;
  listItems: {
    id: string;
    heading?: string;
    description?: string;
    childNode?: React.ReactNode;
  }[];
}> = ({ title = "FAQ", listItems }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleAChange = (panel: string) => (_, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const renderAccordion = () => {
    return listItems.map((item) => {
      return (
        <Grid item xs={12} md={12}>
          <Accordion
            expanded={expanded === item.id}
            id={item.id}
            onChange={handleAChange(item.id)}
            sx={{
              "&": { borderBottom: "0.063rem solid #B6C7D1" },
              "&.Mui-expanded": { margin: "0" },
            }}
            style={{ boxShadow: "none", backgroundColor: "inherit" }}
          >
            <AccordionSummary
              expandIcon={expanded === item.id ? <RemoveIcon /> : <AddIcon />}
              aria-controls={item.id + "-content"}
              id={"faq-" + item.id}
              sx={{ padding: 0 }}
            >
              <Typography variant="h6">{item.heading}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {item.description ? (
                <Typography style={{ whiteSpace: "pre-wrap" }}>
                  {item.description}
                </Typography>
              ) : (
                item.childNode
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      );
    });
  };

  return (
    <>
      <Grid container direction="column" justifyContent="center" gap={"3rem"}>
        <CatalogSubHeading title={title} fontSize="1.5rem" />
        <Grid direction="column" justifyContent="center" gap={"3rem"}>
          {renderAccordion()}
        </Grid>
      </Grid>
    </>
  );
};

export default CatalogFAQ;
