import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";
import Region from "../Regions";
import { useNavigate } from "react-router-dom";
import CardView from "../CardView";
import NorthAmerica from "../../../../assets/NorthAmerica.png";
import CatalogBenefits from "../CatalogBenefits";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import InsightsOutlinedIcon from "@mui/icons-material/InsightsOutlined";
import CatalogKeyFeatures from "../CatalogKeyFeatures";
import ChorusEverywherePricing from "./TaxBrightlinePricing";
import CatalogSubHeading from "../CatalogSubHeading";
import Grid from "@mui/material/Grid";

const CARD_DATA = [
  {
    id: 1,
    logo: "/api/assets/datamesh.png",
    serviceName: "SS&C Everywhere Data Mesh",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "The SS&C Everywhere Data Mesh platform provides an interface for users to centralize their data to make it more accessible to the rapidly changing needs of a user’s organization.",
    tags: {
      function: "Cloud Platform",
      industry: "Cross-Industry",
      serviceType: "Data Service",
    },
  },
  {
    id: 2,
    logo: "/api/assets/algoriskreport.png",
    serviceName: "SS&C Algorithmics Risk Reports",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "Generate world-class risk reports leveraging award and access powerful reporting dashboards preintegrated with your data.",
    tags: {
      function: "Risk Management",
      industry: "Cross-Industry",
      serviceType: "Analytics Service",
    },
    documentation: [
      {
        icon: "https://icon-library.com/images/android-settings-icon-png/android-settings-icon-png-21.jpg",
        name: "API Docs",
        url: "https://.....apidocs...",
      },
      {
        icon: "https://library.kissclipart.com/20181002/ekw/kissclipart-business-lines-of-credit-icon-clipart-credit-card-8488c94e93c3c555.png",
        name: "Price",
        url: "https://....price...",
      },
      {
        icon: "https://w7.pngwing.com/pngs/1017/780/png-transparent-exit-sign-information-fire-safety-exclamation-mark-blue-cdr-angle-thumbnail.png",
        name: "About",
        url: "https://...about...",
      },
    ],
  },
];

const TaxBrightlineOverView: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateTo = (name: string) => {
    if (name === "SS&C Everywhere Data Mesh") {
      return navigate("/public-catalog/data-mesh");
    }
    if (name === "SS&C Algorithmics Risk Reports") {
      return navigate("/public-catalog/ss&c-algorithmics-risk-reports");
    }
  };

  return (
    <>
      <div className="flex-col gap3rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Overview
          </Typography>
          <div>
            <Typography
              variant="h6"
              fontSize="1.25rem"
              sx={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              About This Service
            </Typography>
            <Typography
              variant="body1"
              component={"div"}
              sx={{ color: "#000", lineHeight: "1.5rem" }}
            >
              <div style={{ display: "flex", maxWidth: "90%" }}>
                <ul>
                  <li>
                    {" "}
                    Provides fund managers with year-round access to real-time
                    tax data and analytics, enabling informed investment
                    strategies and a clear understanding of tax impacts.{" "}
                  </li>
                  <li>
                    {" "}
                    Utilizes intelligent automation to streamline investor-level
                    analytics and meet all reporting obligations accurately and
                    efficiently.{" "}
                  </li>
                  <li>
                    {" "}
                    Leverages extensive tax data resources to develop tax-aware
                    strategies that enhance performance and differentiate
                    investment approaches.{" "}
                  </li>
                  <li>
                    {" "}
                    Features a fully integrated end-to-end platform powered by
                    intelligent automation technologies for optimal efficiency
                    and accuracy in tax-related processes.{" "}
                  </li>
                  <li>
                    {" "}
                    Supported by a highly experienced team with diversified
                    expertise in tax laws, operations, and technology
                    applications, acting as an extension of your back office.{" "}
                  </li>
                </ul>
              </div>
            </Typography>
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <AnalyticsOutlinedIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Real-time Data Access",
              description:
                "Provides fund managers with year-round access to real-time tax data and analytics, enabling informed investment strategies and a clear understanding of tax impacts.",
            },

            {
              icon: (
                <DescriptionOutlinedIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Streamlined Compliance",
              description:
                "Utilizes intelligent automation to streamline investor-level analytics and meet all reporting obligations accurately and efficiently.",
            },
            {
              icon: (
                <InsightsOutlinedIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Optimized Investment Strategies",
              description:
                "Leverages extensive tax data resources to develop tax-aware strategies that enhance performance and differentiate investment approaches.",
            },
            {
              icon: (
                <SettingsOutlinedIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Advanced Technology Integration",
              description:
                "Features a fully integrated end-to-end platform powered by intelligent automation technologies for optimal efficiency and accuracy in tax-related processes.",
            },
          ]}
        />
        <CatalogKeyFeatures
          childCount={2}
          listItems={[
            {
              heading: "Enhanced Performance",
              description:
                "Real-time data and analytics capabilities improve fund performance by providing insights into tax impacts and enabling strategic decision-making.",
            },
            {
              heading: "Accurate Reporting",
              description:
                "Intelligent automation ensures all reporting obligations are met with precision, reducing the risk of errors and enhancing compliance.",
            },
            {
              heading: "Operational Efficiency",
              description:
                "The end-to-end integrated platform and automation technologies streamline tax-related processes, saving time and resources.",
            },
            {
              heading: "Expert Support",
              description:
                "Backed by a team of highly experienced tax professionals, providing expert guidance and acting as an extension of your back office.",
            },
          ]}
        />
        <ChorusEverywherePricing title={"Available Pricing Plans"} />
        <div className={"flex-col gap3rem"}>
          <CatalogSubHeading title={"Region Availability"} />
          <div className={"flex-col gap1rem"}>
            <CatalogSubHeading
              title={"Available Regions"}
              fontSize="1.25rem"
              color="#02273C"
            />
            <div>
              <Grid container spacing={0}>
                <Grid item xs={6} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} md={6}>
                      <li className="regionListli">{"North America"}</li>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <img
                    alt={"northAmerica"}
                    width={400}
                    height={200}
                    src={NorthAmerica}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="specificRegionDetils">
            <CatalogSubHeading
              title={"Specific Region Details"}
              fontSize="1.25rem"
              color="#02273C"
            />

            <div>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Region />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Region Details
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    North America: This region provides low-latency access to
                    customers located in the United States and Canada. It offers
                    high availability and scalability, making it an ideal choice
                    for enterprise customers with large-scale workloads.
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Local Compliance
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    Fully compliant with regulatory requirements, including:
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{
                      color: "#577687",
                      lineHeight: "1.5rem",
                      paddingLeft: 2,
                    }}
                  >
                    <ul>
                      <li>GDPR</li>
                      <li>ISO 27000</li>
                      <li>SOC-2</li>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Typography
          variant="h4"
          fontSize="2.125rem"
          fontWeight={600}
          component="div"
          sx={{ color: "#0A85C7", lineHeight: "1.875rem" }}
        >
          Related Products
        </Typography>
        <div style={{ maxWidth: "80%" }}>
          <CardView
            variant="simple"
            data={CARD_DATA}
            handleNavigateTo={handleNavigateTo}
          />
          <Button
            variant="text"
            id={"viewMore"}
            onClick={() => {
              navigate("/public-catalog");
            }}
          >
            View More
          </Button>
        </div>
      </div>
    </>
  );
};

export default TaxBrightlineOverView;
