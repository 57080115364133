import React from "react";
import { Button } from "@ssce/ui-shared-library";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";

export const CatalogActionCell: React.FC<{
  data: any;
  handleActionClick?: (name: string) => void;
}> = ({ data, handleActionClick }) => {
  const navigate = useNavigate();

  return (
    <Stack
      spacing={1}
      alignItems="center"
      direction="column"
      color="primary"
      style={{ marginTop: "0.625rem" }}
    >
      <Button
        className="catalog-get-started-button"
        id={"getStarted"}
        size="medium"
        onClick={
          handleActionClick
            ? () => {
                console.log("handleActionClick", data);
                handleActionClick(data);
              }
            : () => {
                navigate(`/private-api-catalog/overview/${data.catalogId}`);
              }
        }
      >
        Get Started
      </Button>
    </Stack>
  );
};
