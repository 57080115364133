import * as React from "react";
import { useState, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { CurrentViewContext } from "../../../ComponentWithContext";
import "./Superset.css";
import CatalogSubHeading from "../../../Catalog/CatalogSubHeading";
import Grid from "@mui/material/Grid";
import WavingHandOutlinedIcon from "@mui/icons-material/WavingHandOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";
import ApiIcon from "@mui/icons-material/Api";
import CatalogRelevantResources from "../../../Catalog/CatalogRelevantResources";
import { useNavigate } from "react-router-dom";
import { supersetDashboardURL } from "../../../../../constants";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import { Button } from "@ssce/ui-shared-library";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import SummaryList from "../CardTemplate/SummaryList";
import MyServiceInstanceManagement from "../../myServiceInstanceManagement";

const Superset: React.FC = () => {
  const [value, setValue] = useState<string>("1");

  const { viewData } = useContext(CurrentViewContext);

  const navigate = useNavigate();

  const handleChange = (_, newValue: string) => {
    setValue(newValue);
  };

  const BILLING_SUMMARY_LIST_DATA = [
    {
      name: "Subscription ID",
      value: "2387-99746-2938462",
    },
    {
      name: "Subscription Name",
      value: "Apache Superset",
    },
    {
      name: "Instance Name",
      value: viewData.instanceName,
    },
    {
      name: "My Role",
      value: "Account Admin",
    },
    {
      name: "Status",
      value: "Active",
    },
    {
      name: "Plan",
      value: "Premium",
    },
    {
      name: "Current Billing Period",
      value: "09/30/2023 - 10/31/2024",
    },
  ];

  return (
    <>
      <>
        <MyServicesCardHeader
          imageSource={"/api/assets/superset.png"}
          title={viewData.instanceName}
          subTitle={`V${viewData.majorVersion}.${viewData.minorVersion}, ${viewData.instanceName}, ${viewData.region}`}
          contents={
            "Apache Superset is an open-source data exploration and visualization platform that enables you to create interactive and shareable dashboards. Whether you're a data analyst, data scientist, or business user, Superset provides a user-friendly interface for exploring and visualizing your data."
          }
          tags={[
            {
              label: viewData.tags.function,
              style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
            },
            {
              label: viewData.tags.industry,
              style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
            },
            {
              label: viewData.tags.serviceType,
              style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
            },
          ]}
        >
          <>
            <Button
              id={"opensuperset"}
              variant={"default"}
              onClick={() => window.open(supersetDashboardURL)}
            >
              Open Dashboard
              <OpenInNewIcon />
            </Button>
            <Button
              variant="secondary"
              id={"getSupport"}
              onClick={() => navigate("/support")}
            >
              Get Support
              <ContactSupportIcon />
            </Button>
          </>
        </MyServicesCardHeader>
        <Box>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                id={"tabList"}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Overview" value="1" />
                <Tab label="Settings" value="2" />
                <Tab label="Documentation" value="3" />
              </TabList>
            </Box>
            <TabPanel value="1" style={{ padding: "2.625rem 0" }}>
              <div className="myServiceSupersetOverview">
                <CatalogSubHeading title={"Overview"} />
                <CatalogSubHeading
                  title={"Instance Summary"}
                  color="#000000"
                  fontSize="1.5rem"
                />
                <SummaryList data={BILLING_SUMMARY_LIST_DATA} />
                <div></div>
              </div>
            </TabPanel>
            <TabPanel value="2" style={{ padding: "3.25rem 0" }}>
              <MyServiceInstanceManagement />
            </TabPanel>
            <TabPanel value="3" style={{ padding: "3.25rem 0" }}>
              <Grid
                container
                direction="column"
                justifyContent="center"
                gap={"3rem"}
              >
                <CatalogSubHeading title={"Documentation"} />
                <Typography
                  variant="body1"
                  component={"div"}
                  marginBottom={"1.25rem"}
                  className="documentationDescription"
                >
                  Explore the world of Apache Superset right here on our cloud
                  marketplace offerings. Whether you're eager to dive into the
                  documentation to get started or in search of valuable
                  resources, you can find all you need on Apache Superset's
                  official documentation site. For your convenience, here are
                  the direct links to access the documentation and uncover the
                  full potential of Apache Superset for your data exploration
                  and visualization requirements.
                </Typography>
                <CatalogRelevantResources
                  title=""
                  listItems={[
                    {
                      icon: (
                        <WavingHandOutlinedIcon
                          color="primary"
                          sx={{
                            width: "3.5rem",
                            padding: "0.625rem",
                            height: "3.5rem",
                            borderRadius: "6.25rem",
                            backgroundColor: "#A1E1F5",
                          }}
                        />
                      ),
                      heading: "Introduction",
                      description:
                        "Welcome to Apache Superset, a modern, enterprise-ready business intelligence web application.",
                    },
                    {
                      icon: (
                        <LibraryBooksOutlinedIcon
                          color="primary"
                          sx={{
                            width: "3.5rem",
                            padding: "0.625rem",
                            height: "3.5rem",
                            borderRadius: "6.25rem",
                            backgroundColor: "#A1E1F5",
                          }}
                        />
                      ),
                      heading: "Developer Tools",
                      description:
                        "Discover our developer tools and resources in the documentation to kickstart your journey.",
                    },
                    {
                      icon: (
                        <ApiIcon
                          color="primary"
                          sx={{
                            width: "3.5rem",
                            padding: "0.625rem",
                            height: "3.5rem",
                            borderRadius: "6.25rem",
                            backgroundColor: "#A1E1F5",
                          }}
                        />
                      ),
                      heading: "API Docs",
                      description:
                        "Superset's public REST API follows the OpenAPI specification, and is documented here.",
                    },
                  ]}
                />
                <div></div>
              </Grid>
            </TabPanel>
          </TabContext>
        </Box>
      </>
    </>
  );
};

export default Superset;
