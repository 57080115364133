import * as React from "react";
import { useState } from "react";
import { Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Msftsql.css";
import { useNavigate } from "react-router-dom";
import MsftsqlOverView from "./MsftsqlOverView";
import MsftsqlTechnicalSpecifications from "./MsftsqlTechnicalSpecifications";
import MsftsqlPlansAndFeatures from "./MsftsqlPlansAndFeatures";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";
import MsftsqlImage from "../../../../assets/Msftsql.png";

const Msftsql: React.FC = () => {
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={MsftsqlImage}
        title={"Microsoft SQL Server 2019"}
        contents={
          "Microsoft SQL Server 2019 is a relational database management system (RDBMS) that provides advanced capabilities for enterprise data management, big data integration, and intelligent performance tuning. It supports high availability and disaster recovery solutions, ensuring continuous business operations. Additionally, it offers comprehensive security features, including data encryption and access control, to protect sensitive information."
        }
        tags={[
          {
            label: "Data Management",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Analytics",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Web Server",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <>
          <Button
            variant="default"
            width="12rem"
            id={"createInstance"}
            onClick={() => navigate("/public-catalog/msftsql/provision")}
          >
            Create Instance
          </Button>
          <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
            Getting Started
          </Button>
        </>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Technical Specifications" value="3" />
            <Tab label="Documentation" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <MsftsqlOverView />
        </TabPanel>

        <TabPanel value="2" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <MsftsqlPlansAndFeatures />
        </TabPanel>
        <TabPanel value="3" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <MsftsqlTechnicalSpecifications />
        </TabPanel>
        <TabPanel
          value="4"
          sx={{ padding: "4.5rem 0 0 0", display: "flex" }}
        ></TabPanel>
      </TabContext>
      <div className="flex-row  justifyContent-center gap1rem">
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() => navigate("/public-catalog/msftsql/provision")}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </div>
    </>
  );
};
export default Msftsql;
