import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Region from "../Regions";
import { useNavigate } from "react-router-dom";
import CardView from "../CardView";
import NorthAmerica from "../../../../assets/NorthAmerica.png";
import CatalogBenefits from "../CatalogBenefits";
import SpeedIcon from "@mui/icons-material/SpeedOutlined";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import LinkIcon from "@mui/icons-material/LinkOutlined";
import LayersIcon from "@mui/icons-material/LayersClearOutlined";
import CatalogKeyFeatures from "../CatalogKeyFeatures";
import OraclePricing from "./OraclePricing";
import CatalogSubHeading from "../CatalogSubHeading";
import Grid from "@mui/material/Grid";

const CARD_DATA = [
  {
    id: 1,
    logo: "/api/assets/datamesh.png",
    serviceName: "SS&C Everywhere Data Mesh",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "The SS&C Everywhere Data Mesh platform provides an interface for users to centralize their data to make it more accessible to the rapidly changing needs of a user’s organization.",
    tags: {
      function: "Cloud Platform",
      industry: "Cross-Industry",
      serviceType: "Data Service",
    },
  },
  {
    id: 2,
    logo: "/api/assets/algoriskreport.png",
    serviceName: "SS&C Algorithmics Risk Reports",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "Generate world-class risk reports leveraging award and access powerful reporting dashboards preintegrated with your data.",
    tags: {
      function: "Risk Management",
      industry: "Cross-Industry",
      serviceType: "Analytics Service",
    },
  },
];

const OracleOverView: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateTo = (name: string) => {
    if (name === "SS&C Everywhere Data Mesh") {
      return navigate("/public-catalog/data-mesh");
    }
    if (name === "SS&C Algorithmics Risk Reports") {
      return navigate("/public-catalog/ss&c-algorithmics-risk-reports");
    }
  };

  return (
    <>
      <div className="flex-col gap3rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Overview
          </Typography>
          <div>
            <Typography
              variant="h6"
              fontSize="1.25rem"
              sx={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              About This Service
            </Typography>
            <Typography
              variant="body1"
              component={"div"}
              sx={{ color: "#000", lineHeight: "1.5rem" }}
            >
              <div style={{ display: "flex", maxWidth: "90%" }}>
                <ul>
                  <li>
                    {" "}
                    Oracle Database 23ai integrates AI directly into the
                    database, simplifying AI-driven applications.{" "}
                  </li>
                  <li>
                    {" "}
                    AI Vector Search enables natural language queries based on
                    conceptual content.{" "}
                  </li>
                  <li>
                    {" "}
                    JSON Relational Duality allows seamless work with JSON and
                    relational data models.{" "}
                  </li>
                  <li>
                    {" "}
                    Graph Relational Unification supports complex relationship
                    analysis with property graphs.{" "}
                  </li>
                  <li>
                    {" "}
                    The Globally Distributed Database ensures high availability
                    with RAFT-based automatic failover.{" "}
                  </li>
                  <li>
                    {" "}
                    Oracle True Cache enhances performance by maintaining
                    transactionally consistent data in memory.{" "}
                  </li>
                  <li>
                    {" "}
                    In-Database SQL Firewall protects against SQL injection
                    attacks.{" "}
                  </li>
                  <li>
                    {" "}
                    Free developer environments offer easy access to the latest
                    features without added costs.{" "}
                  </li>
                </ul>
              </div>
            </Typography>
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <SearchIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "AI Vector Search",
              description:
                "AI Vector Search allows users to perform semantic searches across various data types, such as documents, images, and relational data, based on conceptual content. This feature enables more accurate and relevant search results, especially when using natural language queries.",
            },
            {
              icon: (
                <LayersIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Flexibility",
              description:
                "JSON Relational Duality allows developers to work with both JSON and relational data models seamlessly within Oracle Database 23ai. This flexibility ensures that applications can handle modern data types without sacrificing data consistency or storage efficiency.",
            },
            {
              icon: (
                <LinkIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Unification",
              description:
                "Graph Relational Unification supports the analysis of complex relationships using property graphs. This feature is particularly useful for applications that need to navigate and analyze connections between data points, such as financial transactions or social networks.",
            },
            {
              icon: (
                <SpeedIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Enhanced Performance",
              description:
                "Oracle True Cache enhances application performance by maintaining transactionally consistent data in memory. This feature reduces the load on database servers and improves response times without requiring developers to manage cache manually.",
            },
          ]}
        />

        <CatalogKeyFeatures
          childCount={2}
          listItems={[
            {
              heading: "Simplified AI Integration",
              description:
                "Oracle Database 23ai integrates AI directly into the database, eliminating the need for separate AI platforms. This simplifies the development and deployment of AI-driven applications, ensuring they operate with the most current data.",
            },
            {
              heading: "Enhanced Security",
              description:
                "The In-Database SQL Firewall provides robust protection against unauthorized SQL activities, including SQL injection attacks. This built-in security feature helps safeguard critical data from both external threats and internal vulnerabilities.",
            },
            {
              heading: "High Availability and Compliance",
              description:
                "With the Globally Distributed Database and RAFT-based replication, Oracle Database 23ai ensures high availability and compliance with data residency regulations. This feature provides automatic failover and reduces downtime in case of disruptions.",
            },
            {
              heading: "Developer Accessibility",
              description:
                "Oracle Database 23ai offers free developer environments through the Always Free Autonomous Database. This allows developers to easily access and experiment with the latest features, reducing the barrier to entry for testing and innovation.",
            },
          ]}
        />
        <OraclePricing title={"Available Pricing Plans"} />
        <div className={"flex-col gap3rem"}>
          <CatalogSubHeading title={"Region Availability"} />
          <div className={"flex-col gap1rem"}>
            <CatalogSubHeading
              title={"Available Regions"}
              fontSize="1.25rem"
              color="#02273C"
            />
            <div>
              <Grid container spacing={0}>
                <Grid item xs={6} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} md={6}>
                      <li className="regionListli">{"North America"}</li>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <img
                    alt={"northAmerica"}
                    width={400}
                    height={200}
                    src={NorthAmerica}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="specificRegionDetils">
            <CatalogSubHeading
              title={"Specific Region Details"}
              fontSize="1.25rem"
              color="#02273C"
            />

            <div>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Region />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Region Details
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    North America: This region provides low-latency access to
                    customers located in the United States and Canada. It offers
                    high availability and scalability, making it an ideal choice
                    for enterprise customers with large-scale workloads.
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Local Compliance
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    Fully compliant with regulatory requirements, including:
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{
                      color: "#577687",
                      lineHeight: "1.5rem",
                      paddingLeft: 2,
                    }}
                  >
                    <ul>
                      <li>GDPR</li>
                      <li>ISO 27000</li>
                      <li>SOC-2</li>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Typography
          variant="h4"
          fontSize="2.125rem"
          fontWeight={600}
          component="div"
          sx={{ color: "#0A85C7", lineHeight: "1.875rem" }}
        >
          Related Products
        </Typography>
        <div style={{ maxWidth: "80%" }}>
          <CardView
            variant="simple"
            data={CARD_DATA}
            handleNavigateTo={handleNavigateTo}
          />
          <Link
            sx={{
              marginLeft: "1.875rem",
              fontWeight: 600,
              marginTop: "-1.875rem",
              cursor: "pointer",
            }}
            id={"viewMore"}
            onClick={() => {
              navigate("/public-catalog");
            }}
          >
            View More
          </Link>
        </div>
      </div>
    </>
  );
};

export default OracleOverView;
