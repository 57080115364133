import React from "react";
import "./leftSide.css";
import { Header } from "./title";
export { HealthEndpoint } from "./healthEndpoint";
export { SchemaEndpoint } from "./schemaEndpoint";
export { UseCaseEndpoint } from "./useCaseEndpoint";

export const ApiKey: React.FC = () => {
  return (
    <>
      <div style={{ marginBottom: "2rem" }}>
        <Header headerType={"apiKey"} />
      </div>
      <div className={"emptySection"}></div>
    </>
  );
};
