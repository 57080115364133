import React from "react";
import { Button } from "@ssce/ui-shared-library";
import { RequestExample, EditorTabContent } from "../rightSide";
import { EndpointData } from "../tryItNowHook";
import { TEST_HEALTH_ENDPOINT } from "../editorContentData";
import "./leftSide.css";
import { Header } from "./title";
import usePostEventCollection from "src/hooks/usePostEventCollection";
import "../tryItNow.css";

export const HealthEndpoint: React.FC<{
  data?: EndpointData;
  apiId: string;
  handleTryNowClick: () => void;
}> = ({ handleTryNowClick, data, apiId }) => {
  const { postEvent } = usePostEventCollection("output-api-click");

  const postAndTryNowClick = () => {
    const data = {
      apiName: "Health Endpoint",
      apiId: apiId,
      endpoint: "/health",
      endpointParameters: {},
    };

    postEvent(data);
    handleTryNowClick();
  };

  return (
    <>
      <div className={"healthEndpointContainer"} data-testid={"health"}>
        <Header headerType={"healthEndpoint"} />
        <div className={"grayBox"}>
          <div className={"smallTitle"}>Test Health Endpoint</div>
          <Button
            id={"healthEndpoint"}
            className={"tryNowButton"}
            onClick={() => postAndTryNowClick()}
          >
            Try It Now
          </Button>
          {data?.isLoading ? (
            <div className={"loadingBanner"}>Loading...</div>
          ) : (
            <>
              {data?.status && (
                <div
                  className={
                    data?.status === 200 ? "successBanner" : "errorBanner"
                  }
                >
                  {data?.status ?? "500"} {data?.statusText ?? "Server Error"}
                </div>
              )}
              {data?.response && (
                <EditorTabContent content={[data?.response]} />
              )}
            </>
          )}
        </div>
      </div>
      <RequestExample
        requestType={"GET"}
        url={"Health"}
        editorContent={TEST_HEALTH_ENDPOINT()}
      />
    </>
  );
};
