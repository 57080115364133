import React from "react";
import { AuthConsumer } from "./authProvider";
import LoginForm from "../main/Login/LoginForm";
import { cookieStorage } from "../../common/utils";

export const PrivateRoute = (props: { children: React.ReactElement }) => {
  return (
    <AuthConsumer>
      {({ isAuthenticated, signinRedirect }) => {
        if (!!props && isAuthenticated()) {
          return props.children;
        } else {
          console.log("cookie", cookieStorage.getItem("login"));
          if (cookieStorage.getItem("login") === "false") {
            //signinSilent({extraQueryParams: { kc_idp_hint: 'ssce-client-idp'}})
            const idp_hint = localStorage.getItem("idp_alias_name") || "";
            signinRedirect({ extraQueryParams: { kc_idp_hint: idp_hint } });
            return <div />;
          } else {
            return <LoginForm signinRedirect={signinRedirect} />;
          }
        }
      }}
    </AuthConsumer>
  );
};
