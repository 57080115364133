import React, { useState, Fragment } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DownloadIcon from "@mui/icons-material/Download";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import "./rightSide.css";

const CopyButton: React.FC<{ text: string }> = ({ text }) => (
  <div
    className={"copyContainer"}
    onClick={() => navigator.clipboard.writeText(text)}
  >
    <FileCopyIcon className={"copyIcon"} />
    <span>Copy</span>
  </div>
);

const EditorTabs: React.FC<{
  value: string;
  handleTabChange: (_, newValue: string) => void;
}> = ({ handleTabChange, value }) => {
  return (
    <Tabs
      value={value}
      onChange={handleTabChange}
      aria-label="basic tabs example"
    >
      <Tab label="cURL" value={"cURL"} />
    </Tabs>
  );
};
const DownloadButton: React.FC<{ content: string; isCsv?: boolean }> = ({
  content,
  isCsv,
}) => {
  const downloadTxtFile = () => {
    const element = document.createElement("a");
    const file = new Blob([content], {
      type: "text/plain;charset=utf-8",
    });
    element.href = URL.createObjectURL(file);
    element.download = isCsv ? "myFile.csv" : "myFile.txt";
    document.body.appendChild(element);
    element.click();
  };
  return (
    <div className={"copyContainer"} onClick={downloadTxtFile}>
      <DownloadIcon className={"copyIcon"} />
      <span className={"copyText"}>Download</span>
    </div>
  );
};
const removeCharEncoding = (content: string[]) =>
  content.map((c: string) => {
    return c.trim ? c.trim() : c;
  });
export const EditorTabContent: React.FC<{
  content: any;
  isCsv?: boolean;
  isJSON?: boolean;
}> = ({ content, isCsv, isJSON }) => {
  return (
    <div className={"editorContent"}>
      <div className={"downloadCopyContainer"}>
        <CopyButton
          text={
            isJSON
              ? JSON.stringify(content, null, 2)
              : removeCharEncoding(content).join("\n")
          }
        />
        <DownloadButton
          isCsv={isCsv}
          content={
            isJSON
              ? JSON.stringify(content, null, 2)
              : removeCharEncoding(content).join("\n")
          }
        />
      </div>
      {isJSON ? (
        <pre>
          <code>{JSON.stringify(content, null, 2)}</code>
        </pre>
      ) : (
        <div className="displayContent">
          {content.map((c: string, index: number) => (
            <Fragment key={c}>
              <span>{index + 1}</span>
              <span>{c}</span>
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

const RequestEditor: React.FC<{ content: any; defaultTab?: string }> = ({
  content,
  defaultTab,
}) => {
  const [value, setValue] = useState(defaultTab ?? "cURL");
  const handleTabChange = (_, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div className={"readOnlyEditor"}>
      <EditorTabs handleTabChange={handleTabChange} value={value} />
      <EditorTabContent content={content.cURL} />
    </div>
  );
};

const ResponseEditor: React.FC<{ content: any }> = ({ content }) => {
  return (
    <div className={"readOnlyEditor"}>
      <EditorTabContent content={content} isJSON />
    </div>
  );
};

export const UseCaseRequestExample: React.FC<{
  requestType: any;
  url: string;
  editorContent: any;
}> = ({ url, editorContent, requestType }) => {
  return (
    <div className={"requestExampleContainer"}>
      <div className={"rightTitle"}>Example Request</div>
      <div className={"requestContainer"}>
        <div className={"requestType"}>{requestType}</div>
        <div className={"requestUrl"}>{url}</div>
      </div>
      <RequestEditor content={editorContent.request} />
    </div>
  );
};

export const RequestExample: React.FC<{
  requestType: any;
  url: string;
  editorContent: any;
}> = ({ url, editorContent, requestType }) => {
  return (
    <div className={"requestExampleContainer"}>
      <div className={"rightTitle"}>Example Request</div>
      <div className={"requestContainer"}>
        <div className={"requestType"}>{requestType}</div>
        <div className={"requestUrl"}>{url}</div>
      </div>
      <RequestEditor content={editorContent.request} />
      <div className={"rightTitle"}>Example Response</div>
      <ResponseEditor content={editorContent.response} />
    </div>
  );
};
