import { useEffect, FC } from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import CreateIcon from "@mui/icons-material/Create";
import { Modal } from "../Modal";
import Accordion from "../Accordion";
import PermissionTable from "../PermissionTable";
import moment from "moment";

import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  setSelectUsers,
  setShowHideUsersDetails,
  fetchUsersMe,
} from "../../../../redux/userManagement/actions";
import OverviewDetails from "./OverviewDetails";
import Acl from "../../subcomponents/Acl";
import { PERMISSION_LIST } from "../../../../constants";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";

const mapStateToProps = (state: AppState) => ({
  overviewData: state.userManagement.overviewData,
  selectedUser: state.userManagement.selectedUser,
  showDetails: state.userManagement.showDetails,
  isLoading: state.userManagement.isLoading,
});

const mapDispatchToProps = {
  fetchUsersMe,
  setSelectUsers,
  setShowHideUsersDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const OverviewTab: FC<Props> = ({
  fetchUsersMe,
  overviewData,
  showDetails,
  isLoading,
  setShowHideUsersDetails,
  setSelectUsers,
}) => {
  useEffect(() => {
    fetchUsersMe();
  }, [fetchUsersMe]);

  const renderRoles = () => {
    return overviewData.groups.map((item) => {
      return (
        <Accordion key={item.name} name={item.name}>
          <Typography
            style={{
              fontSize: 12,
              marginBottom: 10,
              marginLeft: 20,
              color: "#0A85C7",
            }}
          >
            Description
          </Typography>
          <Typography style={{ fontWeight: 400, fontSize: 14, marginLeft: 20 }}>
            {item.description}
          </Typography>

          <br></br>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: 12,
              marginLeft: 20,
              color: "#0A85C7",
            }}
          >
            List of Permissions and Scope
          </Typography>
          <PermissionTable data={item.permissionTable} />
        </Accordion>
      );
    });
  };

  const renderProjects = () => {
    return overviewData.projects.map((item) => {
      return (
        <Accordion key={item.name} name={item.name}>
          <Typography
            style={{ color: "#0A85C7", fontSize: 12, margin: "0.5rem 1.25rem" }}
          >
            Description
          </Typography>
          <Typography
            style={{
              color: "#02273C",
              fontWeight: 400,
              fontSize: 14,
              marginLeft: 20,
              marginBottom: 16,
            }}
          >
            {item.description}
          </Typography>
          <Divider></Divider>
          <Typography
            style={{
              color: "#0979B5",
              fontWeight: 500,
              fontSize: 12,
              marginLeft: 20,
              marginTop: 16,
              marginBottom: 8,
            }}
          >
            Resources assigned to Project
          </Typography>
          <div style={{ marginLeft: 20, fontSize: 12 }}>
            {item.instances.map((i) => (
              <Typography style={{ marginBottom: 8 }} key={i.instanceName}>
                X {i.instanceName}
              </Typography>
            ))}
          </div>
        </Accordion>
      );
    });
  };

  if (isLoading) return <LoadingAnimation />;
  return (
    <>
      <div
        style={{
          flexDirection: "row",
          alignSelf: "flex-end",
          justifyContent: "flex-start",
          gap: "1%",
        }}
      >
        <div>
          <Typography
            style={{
              letterSpacing: 0.15,
              fontWeight: 100,
              fontSize: 24,
              paddingTop: "1.5rem",
              color: "#02273C",
            }}
          >
            Your User Details
          </Typography>
          <br></br>
          <Divider></Divider>
          <div style={{ paddingBottom: "1.5rem" }}></div>
          <br></br>
          <Typography
            style={{
              letterSpacing: 0.15,
              fontWeight: 900,
              fontSize: 24,
              color: "#02273C",
            }}
          >
            {overviewData.name}
          </Typography>
          <br></br>
          <Modal
            showModal={showDetails}
            setShowModal={() => {
              fetchUsersMe();
              setShowHideUsersDetails(false);
            }}
          >
            <OverviewDetails />
          </Modal>
          <Typography
            style={{
              letterSpacing: 0.15,
              fontWeight: 900,
              fontSize: 16,
              paddingBottom: "1.563rem",
              color: "#02273C",
            }}
          >
            {overviewData.email}
          </Typography>
          <div
            style={{
              paddingTop: 10,
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-end",
              justifyContent: "space-between",
              gap: "10%",
            }}
          >
            <div
              style={{
                lineHeight: "1.5rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
                flex: "1 0 0",
              }}
            >
              <Typography style={{ color: "grey" }}>Company</Typography>
              <Typography style={{ fontWeight: "600" }}>
                {overviewData.organisation}
              </Typography>
            </div>
            <div
              style={{
                lineHeight: "1.5rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
                flex: "1 0 0",
              }}
            >
              <Typography style={{ color: "grey" }}>Date Added</Typography>
              <Typography style={{ fontWeight: "600" }}>
                {moment(overviewData.createdTimestamp).format("lll")}
              </Typography>
            </div>
            <div
              style={{
                lineHeight: "1.5rem",
                display: "flex",
                flexDirection: "column",
                gap: "0.75rem",
                flex: "1 0 0",
              }}
            >
              <Typography style={{ color: "grey" }}>Last login</Typography>
              <Typography style={{ fontWeight: "600" }}>
                {moment(overviewData.lastLoginTimestamp).format("lll")}{" "}
              </Typography>
            </div>
          </div>
          <br></br>
          <Divider></Divider>
          <br></br>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignSelf: "flex-end",
              justifyContent: "flex-start",
              gap: "10%",
            }}
          >
            <div style={{ width: "90%", flex: "1 1 auto" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "1rem",
                  padding: "0.25rem 0",
                }}
              >
                <Typography style={{ color: "grey" }}>Roles</Typography>
                <Acl permission={PERMISSION_LIST.ROLES_WRITE}>
                  <CreateIcon
                    sx={{ "&:hover": { color: "#0A85C7" }, cursor: "pointer" }}
                    style={{ width: "1.5rem", height: "1.5rem" }}
                    id={"selectUsers"}
                    onClick={() => {
                      setSelectUsers(overviewData);
                      setShowHideUsersDetails(true);
                    }}
                  />
                </Acl>
              </div>
              <Divider></Divider>
              {renderRoles()}
            </div>
            <div style={{ width: "90%", flex: "1 1 auto" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0.25rem 0",
                }}
              >
                <Typography style={{ color: "grey" }}>Projects</Typography>
              </div>
              <Divider></Divider>
              {renderProjects()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connector(OverviewTab);
