import * as React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentViewContext } from "../../../ComponentWithContext";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { Button } from "@ssce/ui-shared-library";

const NifiReport: React.FC = () => {
  const navigate = useNavigate();
  const { viewData } = useContext(CurrentViewContext);

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/Nifi.png"}
        title="Apache Nifi"
        subTitle={`Apache Nifi, ${viewData.region}`}
        contents={
          "Apache NiFi is a powerful tool that automates cybersecurity, observability, event streams, and generative AI data pipelines and distribution for thousands of companies worldwide across every industry. By streamlining data flow management and ensuring real-time processing, NiFi enables organizations to gain deeper insights, enhance security measures, and drive innovation. Its scalable and flexible architecture adapts to diverse use cases, empowering businesses to stay ahead in a rapidly evolving digital landscape."
        }
        tags={[
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Web Application",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs title={"Apache Nifi"} instanceName={"Apache Nifi"} />
    </>
  );
};
export default NifiReport;
