import UnderConstructionPage from "../subcomponents/underConstructionPage";

const Support: React.FC = () => {
  return (
    <>
      <div style={{ marginLeft: "1.25rem" }}>
        <UnderConstructionPage
          title={"The Support Page is Under Construction"}
        />
      </div>
    </>
  );
};

export default Support;
