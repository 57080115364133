import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePublishApiHooks } from "./publishApiHooks";
import { PublishApiKeys } from "./PublishApiDetails/publishApiDetails";
import { ErrorNotification, Button } from "@ssce/ui-shared-library";
import {
  FlexContainer,
  PublishGeneralContainer,
  InnerContainer,
  ButtonContainer,
  Stepper,
} from "./publishApi.styles";
import { CurrentViewContext } from "../../../ComponentWithContext";
import { Review } from "./MainContent/review";
import { PublishApiForm } from "./MainContent/publishApiForm";
import { Loading } from "./MainContent/loading";
import SuccessMessage from "../SuccessMessage";

type publishApi = {
  description: string;
  functionTag: string;
  industryTag: string;
  serviceTypeTag: string;
  logoName: string;
  projectID: string;
  uploadImage: null | string;
};

export const steps = ["Details", "Review"];

const formData = new FormData();

type PublishApiContainerProps = {
  setActiveMenuItem: (value: string) => void;
};

const PublishApiContainer = ({
  setActiveMenuItem,
}: PublishApiContainerProps) => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [currentSelection, setCurrentSelection] = useState({
    name: "",
    description: "",
  });
  const [projectError, setProjectError] = useState(false);
  const [logoError, setLogoError] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);

  const {
    createPublishApi,
    uploadLogo,
    fetchProjectDetails,
    projects,
    imageList,
    isLoading,
    publishError,
    hasError,
  } = usePublishApiHooks();
  const [publishApi, setPublishApi] = useState<publishApi>({
    description: "",
    functionTag: "Accounting",
    industryTag: "Asset Managers",
    serviceTypeTag: "API",
    logoName: "",
    projectID: "",
    uploadImage: null,
  });

  const { viewData } = useContext(CurrentViewContext);
  const { serviceName } = viewData;
  const onFormChange = (key: PublishApiKeys, value: string) => {
    setPublishApi((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleChange = (event: any) => {
    if (event.target.files && event.target.files.length > 0) {
      const fileUploaded = event.target.files[0];

      const reader = new FileReader();
      reader.readAsDataURL(fileUploaded);
      reader.onload = () => {
        const result = reader.result as string;
        setPublishApi({
          ...publishApi,
          uploadImage: result,
          logoName: "",
        });
        setLogoError(false);
      };

      formData.append("logo", event.target.files[0]);
    }
  };

  const validateInput = () => {
    let isError;

    if (publishApi.projectID === "") {
      setProjectError(true);
      isError = true;
    }

    if (publishApi.logoName === "" && publishApi.uploadImage === null) {
      setLogoError(true);
      isError = true;
    }

    return isError;
  };

  const handleNext = async () => {
    setProjectError(false);

    if (validateInput()) return;

    if (activeStep !== steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      const urlData = {
        majorVersion: viewData.majorVersion,
        minorVersion: viewData.minorVersion,
        serviceName: viewData.serviceName,
      };
      const payload = {
        applicationName: viewData.applicationName,
        shortDescription: publishApi.description,
        projectId: publishApi.projectID,
        logo: publishApi.logoName,
        tags: {
          function: publishApi.functionTag,
          industry: publishApi.industryTag,
          serviceType: publishApi.serviceTypeTag,
        },
      };
      try {
        if (publishApi.uploadImage) {
          const uploadFileResult = (await uploadLogo(formData, urlData)) as {
            data: any;
          };
          payload.logo = uploadFileResult.data.detail;
        }

        const untypedResult = await createPublishApi(payload, urlData);

        const result = untypedResult as {
          success: boolean;
          error: string;
          data: any;
        };

        if (result.success) {
          setIsSuccessful(true);
        } else {
          console.error("API Publish Failed:", result.error);
        }
      } catch (error) {
        console.error("API Publish Failed:", error);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const addProjectDetails = (ID: string) => {
    let Tdata = projects.filter((item) => item.projectId === ID);
    setCurrentSelection({
      name: Tdata[0]?.name ?? "",
      description: Tdata[0]?.description ?? "",
    });
  };

  const isInitialStep = activeStep === 0;

  const renderCTAButtons = () => {
    return (
      <ButtonContainer>
        <Button
          variant="secondary"
          id={"back"}
          width={"16rem"}
          onClick={() =>
            isInitialStep ? navigate("/manage-listings") : handleBack()
          }
        >
          Back
        </Button>
        <Button
          variant="default"
          id={"next"}
          onClick={handleNext}
          width={"16rem"}
        >
          {isInitialStep ? "Next" : "Publish"}
        </Button>
      </ButtonContainer>
    );
  };
  if (isSuccessful)
    return <SuccessMessage setActiveMenuItem={setActiveMenuItem} />;

  return (
    <>
      <Stepper activeStep={activeStep} />
      {hasError && <ErrorNotification />}
      <FlexContainer>
        <Loading isLoading={isLoading} />
        <PublishGeneralContainer>
          <InnerContainer>
            {activeStep === 0 ? (
              <PublishApiForm
                publishApi={publishApi}
                onFormChange={onFormChange}
                imageList={imageList}
                fetchProjectDetails={fetchProjectDetails}
                addProjectDetails={addProjectDetails}
                serviceName={serviceName}
                projects={projects}
                handleChange={handleChange}
                projectError={projectError}
                logoError={logoError}
                setLogoError={setLogoError}
                setProjectError={setProjectError}
              />
            ) : activeStep === 1 ? (
              <Review
                publishApi={publishApi}
                publishError={publishError}
                imageList={imageList}
                currentSelection={currentSelection}
                serviceName={serviceName}
              />
            ) : null}
          </InnerContainer>
        </PublishGeneralContainer>
      </FlexContainer>
      {renderCTAButtons()}
    </>
  );
};

export default PublishApiContainer;
