import React, { FC, PropsWithChildren, useState, useEffect } from "react";
import AuthService from "./authService";
import { connect, ConnectedProps } from "react-redux";
import { updateUserDetails } from "../../redux/user/actions";
import { AppState } from "../../redux/store";
//@ts-ignore
const AuthContext = React.createContext<AuthService>(null);
export const AuthConsumer = AuthContext.Consumer;

const mapStateToProps = (state: AppState) => ({
  loggedIn: state.user.loggedIn,
  token: state.user.token,
});

const mapDispatchToProps = { updateUserDetails };
const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const AuthProvider: FC<PropsWithChildren<Props>> = (props) => {
  const [authService, setAuthService] = useState<AuthService>();
  const [isReady, setIsReady] = useState<boolean>(false);

  const { updateUserDetails } = props;

  useEffect(() => {
    setAuthService(new AuthService(updateUserDetails));
  }, [updateUserDetails, props.loggedIn]);

  useEffect(() => {
    //TODO - Added this to avoid loading the app before the token gets updated in axios , later this might need to be refactored
    const timer = setTimeout(() => setIsReady(true), 500);
    return () => clearTimeout(timer);
  }, [props.loggedIn]);

  return authService && isReady ? (
    <AuthContext.Provider value={authService}>
      {props.children}
    </AuthContext.Provider>
  ) : (
    <div />
  );
};

export default connector(AuthProvider);
