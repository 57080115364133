import { useState } from "react";
import { Modal, TextField } from "@ssce/ui-shared-library";
import { Flex } from "src/global.styles";
import {
  FileCopy as FileCopyIcon,
  Send as SendIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";

import {
  Anchor,
  AnchorList,
  Button,
  OnThisPageHelperWrapper,
  SubAnchor,
  ChipList,
  Tag,
  EmailBody,
  EmailListWrapper,
  EmailHeading,
  RedChip,
  RedChipList,
  StyledSnackbar,
} from "./Styles/OnThisPageHelper.styles";
import { Snackbar, Alert } from "@mui/material";

interface OnThisPageHelperProps {
  anchorTagsArr: { subHeadingString: string; id: string; items?: any[] }[];
}

declare global {
  interface Window {
    find: (args: any) => any;
  }
}

const copyURLToClipboard = () => {
  navigator.clipboard.writeText(window.location.href);
};

const isValidEmail = (email: string) =>
  email.includes("@") && email.includes(".");

const mappedEmails = (arrOfEmails, removeEmail) =>
  arrOfEmails.map((email) => {
    return (
      <>
        <Tag id={email} isValid={isValidEmail(email)}>
          {email}
          <div
            onClick={() => {
              removeEmail(email);
            }}
          >
            <CancelIcon />
          </div>
        </Tag>
      </>
    );
  });

export function OnThisPageHelper({ anchorTagsArr }: OnThisPageHelperProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalValue, setModalValue] = useState("");
  const [arrOfEmails, setArrOfEmails] = useState<string[]>([]);
  const [alertSnackbarIsOpen, setAlertSnackbarIsOpen] = useState(false);
  const [successSnackbarIsOpen, setSuccessSnackbarIsOpen] = useState(false);
  const [urlCopiedSnackbarIsOpen, setUrlCopiedSnackbarIsOpen] = useState(false);
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);

  const isValidModalValue =
    modalValue.includes("@") && modalValue.includes(".");
  const isInvalidModalValue =
    !modalValue.includes("@") || !modalValue.includes(".");

  const scrollToSubHeading = (subHeadingId) => {
    let subheadingElement = document.getElementById(subHeadingId);
    let subheadingElementAbove = subheadingElement?.previousElementSibling;

    if (subheadingElementAbove) {
      subheadingElementAbove.scrollIntoView();
      window.scrollBy(0, -60);
    }
  };

  // NOTE: We may reintroduce this feature in the next sprint or so

  //   const searchForWord = (searchValue: string) => {
  //     if (window.find(searchValue)) {
  //       window.find(searchValue);

  //       let selection = window.getSelection();
  //       if (selection && selection.rangeCount > 0) {
  //         let range = selection.getRangeAt(0);
  //         let parentElement = range.startContainer.parentElement;
  //         if (parentElement) {
  //           parentElement.scrollIntoView();
  //           window.scrollBy(0, -60);
  //         }
  //       }
  //     }
  //   };

  //   const [searchValue, setSearchValue] = useState("");

  const handleModalValueKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    let trimmedValue = modalValue.trim();
    const isValidKey = e.key === "," || e.key === "Enter";

    if (isValidKey && trimmedValue.length > 0) {
      setArrOfEmails([...arrOfEmails, modalValue]);
      setTimeout(() => {
        setModalValue("");
      }, 100);
    }
  };

  const removeEmail = (emailToRemove) => {
    setArrOfEmails(arrOfEmails.filter((email) => email !== emailToRemove));
  };

  const getEmailLength = () => {
    let currAmountOfValidEmails = arrOfEmails.filter((email) =>
      isValidEmail(email),
    ).length;

    if (isValidModalValue) {
      currAmountOfValidEmails++;
    }

    return currAmountOfValidEmails;
  };

  const findInvalidEmails = () => {
    const invalidEmails = arrOfEmails.filter(
      (email) => !email.includes("@") || !email.includes("."),
    );

    if (isInvalidModalValue && modalValue.length > 0) {
      invalidEmails.push(modalValue);
    }

    setInvalidEmails(invalidEmails);
    if (invalidEmails.length > 0) {
      setAlertSnackbarIsOpen(true);
    }
  };

  const clearModal = () => {
    setModalValue("");
    setArrOfEmails([]);
    setInvalidEmails([]);
  };

  const arrOfValidEmails = arrOfEmails.filter((email) => isValidEmail(email));

  const sendEmail = () => {
    if (arrOfEmails.length === 0 && !modalValue) return;

    const emailSubject = encodeURIComponent("Check out this blog at SS&C!");
    const emailBody = `Hello, I'd like to recommend a blog you may find interesting: ${encodeURIComponent(window.location.href)}`;

    let emailString = arrOfValidEmails.join(";");
    if (isValidModalValue) {
      emailString = `${emailString};${modalValue}`;
    }
    findInvalidEmails();
    if (!emailString) return setModalOpen(false);
    window.location.href = `mailto:${emailString}?subject=${emailSubject}&body=${emailBody}`;
    setSuccessSnackbarIsOpen(arrOfValidEmails.length > 0 || isValidModalValue);
    setModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
        title="Send Link via Email"
        saveButtonText="Send"
        onPrimaryButtonClick={() => {
          sendEmail();
        }}
        showFooter={true}
      >
        <EmailBody>
          Enter one or more email addresses to share this page. Use commas or
          press 'Enter' to separate multiple addresses. Emails you enter will be
          displayed below.
        </EmailBody>
        <TextField
          id={"emailInput"}
          label="Email"
          placeholder="Email Address"
          value={modalValue}
          onChange={(e) => setModalValue(e.target.value)}
          onKeyDown={handleModalValueKeyPress}
        />
        {arrOfEmails.length > 0 && (
          <EmailListWrapper>
            <EmailHeading>All Entered Emails</EmailHeading>
            <ChipList>{mappedEmails(arrOfEmails, removeEmail)}</ChipList>
          </EmailListWrapper>
        )}
      </Modal>

      <OnThisPageHelperWrapper>
        <Flex gap="0.5rem">
          <Button
            id={"copyIcon"}
            onClick={() => {
              copyURLToClipboard();
              setUrlCopiedSnackbarIsOpen(true);
            }}
          >
            <FileCopyIcon />
          </Button>
          <Button
            id={"sendIcon"}
            onClick={() => {
              clearModal();
              setModalOpen(true);
            }}
          >
            <SendIcon />
          </Button>
        </Flex>
        <h3>On this page</h3>
        {/* <TextField
        placeholder="search page..."
        value={searchValue}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            searchForWord(searchValue);
          }
        }}
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          startAdornment: <Search onClick={() => searchForWord(searchValue)} />,
        }}
      /> */}
        <Snackbar
          open={alertSnackbarIsOpen}
          autoHideDuration={6000}
          onClose={() => setAlertSnackbarIsOpen(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert severity="error" id={"errorAlert"}>
            <b>Error:</b> Your link failed to send to the following emails due
            to improper email formatting or the email not existing:{" "}
            <RedChipList>
              {invalidEmails.map((email) => (
                <RedChip isValid={false}>{email}</RedChip>
              ))}
            </RedChipList>
          </Alert>
        </Snackbar>

        <StyledSnackbar
          position={invalidEmails.length > 0}
          open={successSnackbarIsOpen}
          autoHideDuration={6000}
          onClose={() => setSuccessSnackbarIsOpen(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <Alert severity="success" id={"successAlert"}>
            <b>Success:</b> Your link is being sent to {getEmailLength()} email{" "}
            {getEmailLength() === 1 ? "address" : "addresses"}!
          </Alert>
        </StyledSnackbar>

        <AnchorList>
          {anchorTagsArr.map((anchor) =>
            anchor.items ? (
              <div key={anchor.id}>
                <>
                  <Anchor onClick={() => scrollToSubHeading(anchor.id)}>
                    {anchor.subHeadingString}
                  </Anchor>
                </>

                {anchor.items.map((item, key) => (
                  <SubAnchor
                    className={"subanchor" + anchor.id}
                    onClick={() => scrollToSubHeading(item.id)}
                  >
                    {key + 1}. {item.subHeadingString}
                  </SubAnchor>
                ))}
              </div>
            ) : (
              <Anchor onClick={() => scrollToSubHeading(anchor.id)}>
                {anchor.subHeadingString}
              </Anchor>
            ),
          )}
        </AnchorList>
      </OnThisPageHelperWrapper>
      <Snackbar
        open={urlCopiedSnackbarIsOpen}
        autoHideDuration={6000}
        onClose={() => setUrlCopiedSnackbarIsOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Alert severity="success">URL copied to clipboard!</Alert>
      </Snackbar>
    </>
  );
}
