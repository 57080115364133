import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@ssce/ui-shared-library";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 698,
  height: "auto",
  bgcolor: "#EDF4F8",
  border: "0 solid #000",
  boxShadow: 24,
  p: 10,
  m: 2,
  lineHeight: 2,
  padding: "3.125rem 0 0 0",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
};

type Props = {
  open: boolean;
  title: string;
  onRequestCancel: (params: any) => void;
  onRequestConfirm: (params: any) => void;
  children: any;
};

const ConfirmModal: FC<Props> = ({
  open,
  title,
  children,
  onRequestCancel,
  onRequestConfirm,
}) => {
  return (
    <Modal
      open={open}
      onClose={onRequestCancel}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ padding: "0 1.875rem" }}>
          <ClearIcon
            id={"clear"}
            onClick={onRequestCancel}
            style={{
              position: "absolute",
              marginLeft: "90%",
              top: 25,
              cursor: "pointer",
            }}
          />
        </Box>
        <Box sx={{ padding: "0 3.125rem 2rem 3.125rem" }}>
          <Typography
            style={{ color: "#0468B1", fontWeight: 900, fontSize: "2.125rem" }}
          >
            {title}
          </Typography>
          <br></br>
          {children}
        </Box>
        <Box
          sx={{
            padding: "0.625rem  0 0.625rem 6.25rem",
            backgroundColor: "#DFE9EF",
          }}
        >
          <div
            style={{
              width: "100%",
              paddingLeft: "52%",
              display: "flex",
              gap: "4%",
            }}
          >
            <Button id={"cancel"} onClick={onRequestCancel} variant="secondary">
              Cancel
            </Button>
            <Button id={"confirm"} onClick={onRequestConfirm} variant="default">
              Confirm
            </Button>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default ConfirmModal;
