import * as React from "react";
import { useState } from "react";
import { Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Tableau.css";
import { useNavigate } from "react-router-dom";
import TableauOverView from "./TableauOverView";
import TableauTechnicalSpecifications from "./TableauTechnicalSpecifications";
import TableauPlansAndFeatures from "./TableauPlansAndFeatures";
import TableauImage from "../../../../assets/tableau.png";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

const Tableau: React.FC = () => {
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={TableauImage}
        title={"Tableau"}
        contents={
          "Tableau is a leading data visualization tool that enables users to transform raw data into interactive and shareable dashboards. It connects seamlessly to various data sources, allowing for real-time data analysis and visualization. Users can create a wide range of visualizations, from simple charts to complex graphs, without extensive technical expertise. Tableau's intuitive drag-and-drop interface makes it accessible for both beginners and advanced users. Widely used in business intelligence, it facilitates data-driven decision-making by presenting data in a clear and visually appealing manner."
        }
        tags={[
          {
            label: "BI",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() => navigate("/public-catalog/tableau/provision")}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Technical Specifications" value="3" />
            <Tab label="Documentation" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <TableauOverView />
        </TabPanel>

        <TabPanel value="2" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <TableauPlansAndFeatures />
        </TabPanel>
        <TabPanel value="3" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <TableauTechnicalSpecifications />
        </TabPanel>
        <TabPanel
          value="4"
          sx={{ padding: "4.5rem 0 0 0", display: "flex" }}
        ></TabPanel>
      </TabContext>
      <div className="flex-row justifyContent-center gap1rem">
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() =>
            navigate("/public-catalog/ss&c-chorus-everywhere/provision")
          }
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </div>
    </>
  );
};
export default Tableau;
