import styled from "styled-components";

export const NumberOfFilters = styled.p`
  font-size: 1.25rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  text-align: center;
  align-self: center;
  background-color: var(--neutralWhite);
  color: var(--primary);
  border-radius: 5rem;
`;

export const DocumentationContentWrapper = styled.div`
  margin: 1rem 20rem 20rem 3rem;

  @media only screen and (max-width: 1240px) {
     margin: 2rem;
  }
`;

export const Heading = styled.h1`
  color: var(--primary);
  size: 2.125rem;
  line-height: 1;
`;

export const Subheading = styled.h2`
  size: 1.5rem;
  font-weight: 400;
`;

export const P = styled.p`
  font-weight: 400;
`;
export const ListItem = styled.li`
  font-weight: 400;
  padding-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const GrayHeading = styled.h3`
  color: var(--gray);
  font-size: 1.25rem;
`;

export const Bold = styled.div`
  display: inline;
  color: var(--primary);
  font-weight: 600;
`;

export const Link = styled.a`
  color: var(--primary);
  font-weight: 600;
  text-decoration: underline;   
  padding: 0 4px;
`;

export const Center = styled.div`
  display: flex;
  justify-content: center;
`;

export const Image = styled.img<{ sizeInRem?: number; border?: boolean }>`
  width: ${(props) => (props.sizeInRem ? `${props.sizeInRem}rem` : "20rem")};
  border: ${(props) => (props.border ? "1px solid black" : "none")};
  height: 100%;
`;
