import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import { Button } from "@ssce/ui-shared-library";
import ConstructionPage from "./ConstructionPage.png";

export const ErrorFallbackPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          marginLeft: "0%",
          textAlign: "center",
          marginTop: "2%",
          display: "flex",
          flex: "100%",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={{ width: "40%", display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h1"
            color="#0A85C7"
            marginBottom={"1rem"}
            alignSelf="center"
            textAlign="center"
            style={{ fontSize: "2.125rem", marginTop: "3.125rem" }}
          >
            Oops! Something went wrong!
          </Typography>
          <Typography
            variant="h1"
            color="#355263"
            fontWeight={500}
            fontFamily="Roboto Medium"
            fontSize={20}
            textAlign="center"
            alignSelf="center"
          >
            Let's help get you turned around!
          </Typography>
          <div style={{ padding: "1.5rem 0 3.875rem 0" }}>
            <Typography
              variant="h1"
              color="#355263"
              fontWeight={100}
              fontFamily="Roboto"
              fontSize={16}
              textAlign="center"
              margin="1.25rem"
              marginTop="0"
              lineHeight={"1.5rem"}
            >
              We're currently in the process of building a better, more
              user-friendly support experience for you. While we're working hard
              behind the scenes, please know that your feedback is essential to
              us. Please feel free to contact our customer service team to
              report this issue, or if you need immediate assistance at:
            </Typography>

            <a href={"mailto:SSCE_Tech_Support@sscinc.com"}>
              SSCE_Tech_Support@sscinc.com
            </a>

            <Typography
              variant="h1"
              color="#355263"
              fontWeight={100}
              fontFamily="Roboto"
              fontSize={16}
              textAlign="center"
              margin="2.5rem"
              marginTop="1.25rem"
              lineHeight={"1.5rem"}
            >
              We appreciate your patience and understanding, and we're excited
              to serve you better soon!
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "100%",
              gap: "5%",
            }}
          >
            <Button
              variant="default"
              onClick={() => {
                navigate("/");
                window.location.reload();
              }}
              id="toDashboard"
            >
              TAKE ME TO THE DASHBOARD
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "3.75rem" }}>
          <img
            src={ConstructionPage}
            alt="Error401"
            width={500}
            height={500}
          ></img>
        </div>
      </div>
    </>
  );
};
