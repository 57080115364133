import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Tarvos.css";

const TarvosTechnicalSpecifications: React.FC = () => {
  const RenderTechDetails = ({ heading, desc }) => {
    return (
      <div>
        <div className="flex-col">
          <Typography
            variant="h6"
            fontSize="1.25rem"
            sx={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {heading}
          </Typography>
        </div>
        <div className="flex-col padding1rem">
          <Typography
            variant="body1"
            sx={{ color: "#000", lineHeight: "1.5rem" }}
          >
            {desc}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex-col gap4rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Technical Specifications
          </Typography>
          <RenderTechDetails
            heading={"Data Connectivity"}
            desc={
              "Tarvos supports a wide range of data connections, including relational databases, cloud services, flat files, and APIs. This flexibility allows users to integrate data from multiple sources for comprehensive analysis."
            }
          />
          <RenderTechDetails
            heading={"Visualization Capabilities"}
            desc={
              "Tarvos offers extensive visualization options, such as bar charts, line graphs, scatter plots, heat maps, and geospatial maps. These visualizations can be customized and combined in dashboards for detailed data analysis."
            }
          />
          <RenderTechDetails
            heading={"Performance and Scalability"}
            desc={
              "Tarvos is designed to handle large datasets and complex queries with high performance. It supports horizontal scaling, allowing users to increase performance by adding more resources to the Tarvos server or cloud environment."
            }
          />
          <RenderTechDetails
            heading={"Real-time Data Processing"}
            desc={
              "Tarvos can process and visualize real-time data by connecting to live data sources and refreshing dashboards dynamically. This capability is essential for monitoring and analyzing time-sensitive information."
            }
          />
          <RenderTechDetails
            heading={"Security and Compliance"}
            desc={
              "Tarvos offers robust security features, including user authentication, role-based access control, and data encryption. It also supports compliance with data privacy regulations such as GDPR and HIPAA."
            }
          />
          <RenderTechDetails
            heading={"Advanced Analytics"}
            desc={
              "Tarvos provides advanced analytics functions, such as forecasting, clustering, and statistical analysis. Users can also create custom calculated fields and use predictive modeling to gain deeper insights from their data."
            }
          />
          <RenderTechDetails
            heading={"Data Preparation and Cleaning"}
            desc={
              "Tarvos includes data preparation tools for cleaning, transforming, and blending data from multiple sources. This feature helps ensure data quality and consistency before visualization and analysis."
            }
          />
          <RenderTechDetails
            heading={"Integration with External Tools"}
            desc={
              "Tarvos can integrate with various external tools and platforms, including R, Python, and machine learning frameworks, allowing users to perform advanced data analysis and build custom models directly within the Tarvos environment."
            }
          />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default TarvosTechnicalSpecifications;
