import React, { useEffect, useRef, useState } from "react";
import PublicCatalog from "../Catalog/publicCatalog";
import { baseUrl } from "../../../constants";
import { TextField } from "@mui/material";
import styled from "styled-components";
import { ServiceCards } from "./serviceCards";
import { slideIn, slideOut } from "src/globalAnimations.styles";
import SearchIcon from "@mui/icons-material/Search";

const animationTime = 0.3;

const Background = styled.div`
  height: 37.5rem;
  width: 85vw;
  z-index: 0;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
`;
const StyledSearchIcon = styled(SearchIcon)<{ isFocused: Boolean }>`
  color: ${(props) => (props.isFocused ? "var(--primary)" : "gray")};
`;

const ServicesContainer = styled.div<{ showPublicCatalog: boolean }>`
  margin-right: 6.25rem;
  margin-left: 6.25rem;
  pointer-events: ${(props) => (!props.showPublicCatalog ? "auto" : "none")};
  display: ${(props) => (!props.showPublicCatalog ? "block" : "none")};
  animation: ${(props) => (!props.showPublicCatalog ? slideIn : slideOut)}
    ${animationTime}s forwards;
  @media screen and (max-width: 796px) {
    margin-right: 0;
    margin-left: 0;
  }
`;

const BottomWrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  background: var(--neutralWhite);
  z-index: 5;
    width: 70%;
  padding: 1rem;

  @media (min-height: 768px) {
      bottom: 12rem;
  }
`;

type DashboardProps = {
  handleBreadCrumbNickName: (name: string) => void;
};

export const Dashboard: React.FC<DashboardProps> = ({
  handleBreadCrumbNickName,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [cards, setCards] = useState([]);
  const [showPublicCatalog, setShowPublicCatalog] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [value, setValue] = useState("");
  const [catalogs, setCatalogs] = useState([]);

  useEffect(() => {
    const handleScroll = (event) => {
      if (event.deltaY > 190) {
        setShowPublicCatalog(true);
        inputRef.current?.focus();
      }
    };

    window.addEventListener("wheel", handleScroll);

    return () => {
      window.removeEventListener("wheel", handleScroll);
    };
  }, []);

  useEffect(() => {
    fetch(`${baseUrl}/api/dashboard`)
      .then((response) => response.json())
      .then((data) => {
        setHasError(false);
        setCards(data);
      })
      .catch(() => {
        setHasError(true);
        setCards([]);
      });
  }, []);
  useEffect(() => {
    fetch(`${baseUrl}/api/catalog`)
      .then((response) => {
        return response.json();
      })
      .then((catalogs) => {
        setCatalogs(catalogs);
        setHasError(false);
      })
      .catch(() => setHasError(true));
  }, []);

  const handleBackgroundClick = () => {
    setShowPublicCatalog(false);
  };
  return (
    <div style={{ minHeight: "70vh" }}>
      <Background
        onClick={() => {
          handleBackgroundClick();
        }}
      />

      <ServicesContainer showPublicCatalog={showPublicCatalog}>
        <ServiceCards cards={cards} hasError={hasError} />
      </ServicesContainer>
      {showPublicCatalog ? (
        <PublicCatalog
          shouldAnimate={true}
          initCatalogs={catalogs}
          handleBreadCrumbNickName={handleBreadCrumbNickName}
        />
      ) : (
        <BottomWrapper>
          <TextField
            placeholder={"Search catalog..."}
            sx={{
              width: "100%",
            }}
            inputRef={inputRef}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            InputProps={{
              autoComplete: "off",
              endAdornment: <StyledSearchIcon isFocused={showPublicCatalog} />,
            }}
            onFocus={() => {
              setShowPublicCatalog(true);
              setTimeout(() => {
                if (inputRef.current) {
                  inputRef.current.focus();
                }
              });
            }}
          />
        </BottomWrapper>
      )}
    </div>
  );
};
