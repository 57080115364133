import { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import MarketplaceContent from "../main/MarketplaceContent";
import AuthProvider from "./authProvider";
import { AppRoutes } from "./routes";

const AuthComponent: FC = () => {
  let isLoginRequired = true;
  if (isLoginRequired) {
    return (
      <AuthProvider>
        <BrowserRouter children={AppRoutes} basename={"/"} />
      </AuthProvider>
    );
  } else {
    return (
      <BrowserRouter>
        <MarketplaceContent />
      </BrowserRouter>
    );
  }
};

export default AuthComponent;
