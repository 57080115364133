import { FC, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { Footer } from "@ssce/ui-shared-library";
import styled from "styled-components";
import { BreadcrumbBuilder } from "./subcomponents";
import { ErrorFallbackPage } from "../../errorFallbackPage";
import Nav from "./Nav/Nav";
import TopBar from "./TopBar";
import RoutedComponents from "./RoutedComponents";
import { useNavigate } from "react-router-dom";
import useNotifier from "../notifications/use-notifier";

const socialMediaLinks = {
  twitter: "https://twitter.com/ssctechnologies",
  LinkedIn: "https://www.linkedin.com/company/ss-c-technologies",
  Facebook: "https://www.facebook.com/pages/SSC-Technologies-Inc/191750415876",
  YouTube: "https://www.youtube.com/c/SsctechInc",
  Instagram: "https://www.instagram.com/ssctechnologies",
};
const copyRightText = "Copyright 2024 by SS&C Technologies, Inc.";

const MainContainer = styled.div`
  margin-top: 4rem;
  padding: 0 2rem;
  overflow: auto;
  height: calc(100vh - 4rem);
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--background);

  @media only screen and (min-width: 2800px) {
    margin: 0 8rem;

    &::-webkit-scrollbar {
      visibility: hidden;
    }
  }
`;

// Confirmed with Brittany. This is temporary until we get new UI designs
const FooterContainer = styled.div<{ hide: boolean }>`
  background: var(--background);
  @media screen and (max-height: 768px) {
    display: ${({ hide }) => (hide ? "none" : "block")};
  }

  * {
    vertical-align: middle;
    align-content: center;
  }
`;

const MainWrapper = styled.section`
  display: flex;
  overflow: hidden;
`;

const MarketplaceContent: FC = () => {
  const navigate = useNavigate();
  const activeMenuItemStorage =
    localStorage.getItem("activeMenuItem") ?? "Dashboard";
  const activeSubMenuItemStorage =
    localStorage.getItem("activeSubMenuItem") ?? "";

  const [activeSubMenuItem, setActiveSubMenuItem] = useState(
    activeSubMenuItemStorage,
  );
  const [activeMenuItem, setActiveMenuItem] = useState(activeMenuItemStorage);
  const [breadcrumbName, setBreadCrumbNickName] = useState("");
  const [currentPageTabValue, setCurrentPageTabValue] = useState("1");
  const [minimized, setMinimized] = useState(false);

  const toggleSideNav = () => {
    setMinimized(!minimized);
  };

  const { pathname } = useLocation();

  const ref = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    ref.current?.scrollTo(0, 0);
  }, [pathname, breadcrumbName]);

  const handleBreadCrumbClick = (_, obj: string) => {
    if (obj === "support") {
      return navigate(-1);
    }
    setBreadCrumbNickName("");
    setActiveSubMenuItem("");

    if (obj === "documentation") {
      return (
        setActiveMenuItem("Documentation"),
        localStorage.setItem("activeMenuItem", "Documentation")
      );
    }
    if (obj.includes("user")) {
      return (
        setCurrentPageTabValue("1"),
        setBreadCrumbNickName("Overview"),
        setActiveMenuItem("Settings"),
        localStorage.setItem("activeMenuItem", "Settings")
      );
    }
    if (obj.includes("workflow")) {
      return (
        setActiveMenuItem("Workflows"),
        localStorage.setItem("activeMenuItem", "Workflows")
      );
    }
    if (obj === "my-services") {
      return (
        setActiveMenuItem("My Services"),
        localStorage.setItem("activeMenuItem", "My Services")
      );
    }
    if (obj.includes("catalog")) {
      return (
        setActiveMenuItem("Catalog"),
        localStorage.setItem("activeMenuItem", "Catalog")
      );
    }
    if (obj === "manage-listings") {
      return (
        setActiveMenuItem("Settings"),
        localStorage.setItem("activeMenuItem", "Settings")
      );
    }
    setActiveMenuItem(obj);
    localStorage.setItem("activeMenuItem", obj);
  };

  const handleBreadCrumbNickName = (name) => {
    //For when we want the breadcrumb name to differ from the URL pathway (eg. Instead of showing a string of random id numbers from the URL, we want to show the instance name)
    setBreadCrumbNickName(name);
  };

  useEffect(() => {
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      if (pathname.includes("/private-api-catalog/")) {
        setBreadCrumbNickName("");
      }
    };

    if (pathname.includes("/user-access-management")) {
      setBreadCrumbNickName("Overview");
    }

    if (pathname.includes("/public-catalog")) {
      setActiveMenuItem("Catalog");
    }
    if (pathname.includes("/dashboard") || pathname === "/") {
      setActiveMenuItem("Dashboard");
    }
    if (pathname.includes("/documentation")) {
      setActiveMenuItem("Documentation");
    }

    window.addEventListener("popstate", onBackButtonEvent);

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, [pathname]);

  const splicedPath = pathname.split("/").filter((item) => item !== "");
  const firstBreadcrumbPath = splicedPath[0] || "Dashboard";
  const secondBreadcrumbPath = breadcrumbName
    ? breadcrumbName
    : splicedPath[0] === "private-api-catalog"
      ? ""
      : (splicedPath[1] ?? "");
  const thirdBreadcrumbPath = splicedPath[2];

  const getBreadcrumbInfo = () => {
    const breadcrumbPathArr = [
      {
        title: firstBreadcrumbPath,
        link: splicedPath[0],
      },
      {
        title: secondBreadcrumbPath,
        link: splicedPath[0] + "/" + splicedPath[1],
      },
    ];

    if (thirdBreadcrumbPath) {
      breadcrumbPathArr.push({
        title: thirdBreadcrumbPath,
        link: pathname,
      });
    }

    return breadcrumbPathArr;
  };

  useNotifier();

  return (
    <ErrorBoundary fallback={<ErrorFallbackPage />}>
      <TopBar />
      <MainWrapper ref={scrollRef}>
        <Nav
          minimized={minimized}
          toggleSideNav={toggleSideNav}
          activeSubMenuItem={activeSubMenuItem}
          activeMenuItem={activeMenuItem}
          setActiveSubMenuItem={setActiveSubMenuItem}
          setActiveMenuItem={setActiveMenuItem}
          handleBreadCrumbNickName={handleBreadCrumbNickName}
        />
        <MainContainer ref={ref} id="mainContainer">
          <div>
            {secondBreadcrumbPath && (
              <BreadcrumbBuilder
                handleBreadCrumbNickName={handleBreadCrumbNickName}
                onClick={(e) => {
                  handleBreadCrumbClick(e, splicedPath[0] ?? "");
                }}
                name={getBreadcrumbInfo()}
              />
            )}
            <RoutedComponents
              setActiveMenuItem={setActiveMenuItem}
              handleBreadCrumbNickName={handleBreadCrumbNickName}
              handleBreadCrumbClick={handleBreadCrumbClick}
              currentPageTabValue={currentPageTabValue}
              setCurrentPageTabValue={setCurrentPageTabValue}
            />
          </div>
          <FooterContainer hide={activeMenuItem === "Dashboard"}>
            <Footer
              socialMediaLinks={socialMediaLinks}
              copyRightText={copyRightText}
            />
          </FooterContainer>
        </MainContainer>
      </MainWrapper>
    </ErrorBoundary>
  );
};

export default MarketplaceContent;
