import React, { useRef, useEffect, useCallback } from "react";
import { styled } from "@mui/material/styles";
import { useSpring, animated } from "@react-spring/web";
import { Container } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const Background = styled(Container)`
  min-width: 100rem;
  min-height: 69.063rem;
  background: rgba(0, 0, 0, 0);
  position: fixed;
  display: flex;
  justify-content: flex-end;
  top: 0;
  z-index: 5;
`;

const ModalWrapper = styled(Container)`
  width: 50.625rem;
  height: 69.063rem;
  background: #edf4f8;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-end;
  position: relative;
  z-index: 1;
  box-shadow:
    0 1rem 1.5rem rgba(2, 39, 60, 0.14),
    0 0.375rem 1.875rem rgba(2, 39, 60, 0.12),
    0 0.5rem 0.625rem rgba(2, 39, 60, 0.2);
`;

const ModalContent = styled(Container)`
  overflow-y: auto;
  margin-top: -12.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  line-height: 0;
  color: #141414;
  min-width: 50.625rem;

  p {
    margin-bottom: 1rem;
  }
`;

export const Modal = ({
  showModal,
  setShowModal,
  children,
}: {
  showModal: any;
  setShowModal: any;
  children: any;
}) => {
  const modalRef = useRef() as React.MutableRefObject<HTMLInputElement>;

  const animation = useSpring({
    config: {
      duration: 250,
    },
    transform: showModal ? `translateX(-20%)` : `translateX(100%)`,
  });

  const closeModal = (e) => {
    if (modalRef.current === e.target) {
      setShowModal(false);
    }
  };

  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showModal) {
        setShowModal(false);
        console.log("I pressed");
      }
    },
    [setShowModal, showModal],
  );

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => document.removeEventListener("keydown", keyPress);
  }, [keyPress]);
  return (
    <>
      {showModal ? (
        <Background id={"closeModal"} onClick={closeModal} ref={modalRef}>
          <animated.div style={animation}>
            <ModalWrapper>
              <ModalContent>
                <div
                  style={{
                    position: "absolute",
                    right: "4.375rem",
                    top: "4.375rem",
                    cursor: "pointer",
                  }}
                  id={"closeModal"}
                  onClick={() => setShowModal(false)}
                >
                  <CloseIcon
                    style={{
                      color: "grey",
                      fontSize: 25,
                      marginTop: 10,
                      marginLeft: 20,
                    }}
                  />
                </div>
                {children}
              </ModalContent>
            </ModalWrapper>
          </animated.div>
        </Background>
      ) : null}
    </>
  );
};
