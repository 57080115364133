import React, { FC, useState, useRef } from "react";
import moment from "moment";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Typography from "@mui/material/Typography";
import { Button, Direction } from "@ssce/ui-shared-library";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import { baseUrl } from "../../../../constants";
import { enqueueSnackbar } from "src/redux/snackbars/actions";
import { NotificationLevel } from "src/redux/snackbars/notification-level";
import { AppThunk } from "src/redux/store";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";

type TagsStatusProps = {
  color: string;
  value: string;
  type: "status" | "tags";
};

const RenderTagsStatus: FC<TagsStatusProps> = ({ color, value, type }) => (
  <Typography
    color={color}
    sx={
      type === "status"
        ? {
            fontSize: "0.875rem",
            fontWeight: 700,
          }
        : {
            fontSize: "0.75rem",
            fontFamily: "Roboto Medium",
          }
    }
  >
    {value}
  </Typography>
);
type LableProps = {
  type: string;
  value: string;
};
const RenderLabels: FC<LableProps> = ({ type, value }) => (
  <>
    <Typography
      fontWeight={700}
      sx={{
        fontSize: "0.875rem",
        fontFamily: "Roboto Bold",
      }}
    >
      {type}
    </Typography>
    <Typography
      sx={{
        fontSize: "0.75rem",
      }}
    >
      {value}
    </Typography>{" "}
  </>
);

type Action = {
  name: string;
  onClick: (row: any) => void;
  id: string;
};

type ToolTipProps = {
  row: any;
  actions: Action[];
};

export const TooltipMenu: React.FC<ToolTipProps> = ({ row, actions }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown: React.KeyboardEventHandler<HTMLUListElement> = (
    event,
  ) => {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        id="composition-button"
        aria-controls={open ? "composition-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 3 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper className="tooltip-menu-popper-paper">
              <ClickAwayListener id={"clickAway"} onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id="action-menu"
                  aria-labelledby="action-button"
                  onKeyDown={handleListKeyDown}
                >
                  {actions.map(({ name, onClick, id }) => (
                    <MenuItem
                      id={id}
                      key={id}
                      onClick={async () => {
                        dispatch(onClick(row));
                        setOpen((prevOpen) => !prevOpen);
                      }}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const apiColumns = (onActionClick) => [
  {
    title: "Listing Name",
    dataIndex: "serviceName",
    sorter: true,
  },
  {
    title: "Owner",
    dataIndex: "owner",
    sorter: true,
  },
  {
    title: "Data Mesh Instance",
    dataIndex: "instanceName",
    sorter: true,
  },
  {
    title: "Region",
    dataIndex: "region",
    sorter: true,
    render: (cell) => (cell ? cell.toUpperCase() : ""),
  },
  {
    title: "Date Added",
    dataIndex: "createdAt",
    sorter: true,
    searchByValue: (cell) => moment(cell).format("ll"),
    render: (cell) => moment(cell).format("ll"),
  },
  {
    title: "Action",
    render: (_, row) => (
      <>
        {
          <Button
            variant="default"
            status="success"
            className="publishcatalogBtn"
            size="small"
            id={"publishToCatalog"}
            onClick={() => onActionClick(row)}
          >
            Publish to Catalog
          </Button>
        }
      </>
    ),
  },
];

const handleUnpublish =
  (row: any): AppThunk =>
  (dispatch) => {
    axios
      .delete(
        `${baseUrl}/api/mktplc-catalog-api/v0/private/services/unpublish/${row.serviceName}?version=1.0&application_name=data_mesh`,
      )
      .then(() => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Unpublishing in progress. This may take a few minutes.",
          ),
        );
      })
      .catch((error) => {
        console.log("error while unpublishing", error.response.data);
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            "Warning: Unable to unpublish product",
          ),
        );
      });
  };

const actions = [
  { name: "Unpublish", id: "unpublish", onClick: handleUnpublish },
];

export const listingColumns = (data) => {
  const functionFilter = data.reduce((acc, cv) => {
    if (acc.some((a) => a.value === cv.tags.function)) {
      return acc;
    } else {
      return [
        ...acc,
        {
          value: cv.tags.function,
          label: cv.tags.function,
        },
      ];
    }
  }, []);
  const serviceTypeFilter = data.reduce((acc, cv) => {
    if (acc.some((a) => a.value === cv.tags.serviceType)) {
      return acc;
    } else {
      return [
        ...acc,
        {
          value: cv.tags.serviceType,
          label: cv.tags.serviceType,
        },
      ];
    }
  }, []);
  const industryFilter = data.reduce((acc, cv) => {
    if (acc.some((a) => a.value === cv.tags.industry)) {
      return acc;
    } else {
      return [
        ...acc,
        {
          value: cv.tags.industry,
          label: cv.tags.industry,
        },
      ];
    }
  }, []);
  return [
    {
      title: "Actions",
      render: (_, row) => <TooltipMenu row={row} actions={actions} />,
    },
    {
      title: "Listing Name",
      dataIndex: "serviceName",
      defaultSortOrder: "asc" as Direction,
      sorter: true,
    },
    {
      title: "Version",
      dataIndex: "majorVersion",
      sorter: true,
      searchByValue: (_, row) => `${row.majorVersion}.${row.minorVersion}`,
      render: (_, row) => (
        <span>
          {row.majorVersion}.{row.minorVersion}
        </span>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: true,
      searchByValue: (status) => status,
      render: (status) => {
        const color = status === "Published" ? "#37B036" : "#E7AA12";
        return <RenderTagsStatus type="status" color={color} value={status} />;
      },
    },
    {
      title: "Owner",
      dataIndex: "applicationName",
      sorter: true,
    },
    {
      title: "Function",
      dataIndex: "tags.function",
      filters: functionFilter,
      onFilter: (filterValue, selectedData) => {
        return filterValue === selectedData.tags.function;
      },
      sorter: (a, b) => {
        if (a.tags.function > b.tags.function) {
          return 1;
        } else if (a.tags.function < b.tags.function) {
          return -1;
        } else return 0;
      },
      searchByValue: (_, row) => row?.tags?.function,
      render: (func) => (
        <RenderTagsStatus type="tags" color={"#0A85C7"} value={func} />
      ),
    },
    {
      title: "Industry",
      dataIndex: "tags.industry",
      filters: industryFilter,
      searchByValue: (_, row) => row?.tags?.industry,
      onFilter: (filterValue, selectedData) => {
        return filterValue === selectedData.tags.industry;
      },
      sorter: (a, b) => {
        if (a.tags.industry > b.tags.industry) {
          return 1;
        } else if (a.tags.industry < b.tags.industry) {
          return -1;
        } else return 0;
      },
      render: (industry) => (
        <RenderTagsStatus type="tags" color={"#6200EE"} value={industry} />
      ),
    },
    {
      title: "Service Type",
      dataIndex: "tags.serviceType",
      filters: serviceTypeFilter,
      searchByValue: (_, row) => row?.tags?.serviceType,
      onFilter: (filterValue, selectedData) => {
        return filterValue === selectedData.tags.serviceType;
      },
      sorter: (a, b) => {
        if (a.tags.serviceType > b.tags.serviceType) {
          return 1;
        } else if (a.tags.serviceType < b.tags.serviceType) {
          return -1;
        } else return 0;
      },
      render: (serviceType) => (
        <RenderTagsStatus type="tags" color={"#D72F77"} value={serviceType} />
      ),
    },
    {
      title: "Labels",
      dataIndex: "labels",
      sorter: (a, b) => {
        if (a.labels.banner > b.labels.banner) {
          return 1;
        } else if (a.labels.banner < b.labels.banner) {
          return -1;
        } else return 0;
      },
      render: (labels) => {
        if (labels?.banner)
          return <RenderLabels type={"Banner"} value={labels.banner} />;
        else if (labels?.promotion)
          return <RenderLabels type={"Promotion"} value={labels.promotion} />;
        else return <></>;
      },
    },
    {
      title: "Date Added",
      dataIndex: "createdAt",
      sorter: true,
      searchByValue: (cell) => moment(cell).format("ll"),
      render: (cell) => moment(cell).format("ll"),
    },
  ];
};
