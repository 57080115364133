import { useState } from "react";
import {
  Description,
  Division,
  FormatInfo,
  InfoColumn,
  InfoColumnTwo,
  InfoContainer,
  DragAndDropContainer,
  Label,
  LogoPreview,
  PreviewContainer,
  PreviewContainerOne,
  PreviewTitle,
  SelectFile,
  SelectLogo,
  SimpleInput,
  StyledCloudUploadIcon,
  StyledFormHelperText,
  StyledMenuItem,
  Title,
  UploadAnImage,
  Form,
  UploadButtonFirst,
  UploadContainer,
} from "../publishApi.styles";
import DragAndDrop from "./dragAndDrop";

const allowedFileTyped = [".jpeg", ".png", ".gif", ".jpg"];

export const LogoSection = ({
  logoName,
  logoError,
  imageList,
  uploadImage,
  onFormChange,
  handleChange,
  setLogoError,
}) => {
  const [imageSizeError, setImageSizeError] = useState("");

  const validateFileSize = (size) => {
    return Math.round(size / 1024) <= 5120;
  };

  const handleFileUpload = (e) => {
    setImageSizeError("");
    const { files } = e.dataTransfer ?? e.target;
    const fileType = "." + files[0].name.split(".").pop();

    if (files && files.length === 1 && allowedFileTyped.includes(fileType)) {
      validateFileSize(files[0].size)
        ? handleChange({ target: e.dataTransfer ?? e.target })
        : setImageSizeError(
            `"${files[0].name}" (${Math.round(files[0].size / 1024)} KB) exceeds maximum allowed upload size of 5120 KB.`,
          );
    } else {
      setImageSizeError(`Can not upload more than one Image file.`);
    }
  };

  const renderImageList = () =>
    Object.keys(imageList).map((key) => (
      <StyledMenuItem value={key}>{key}</StyledMenuItem>
    ));

  return (
    <InfoContainer>
      <InfoColumn>
        <Division>
          <Title>Logo</Title>
          <Description>
            Assigning a logo to your API enhances its visual recognition within
            the Private API Catalog, making it more easily distinguishable for
            users.
          </Description>
        </Division>
        <PreviewContainer>
          <PreviewTitle>Logo Preview</PreviewTitle>
          <LogoPreview
            alt={"imageList"}
            src={uploadImage ?? imageList[logoName]}
          />
        </PreviewContainer>
      </InfoColumn>
      <PreviewContainerOne>
        <Division>
          <Form error={logoError}>
            <SelectLogo
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={logoName}
              fullWidth
              error={logoError}
              onChange={(e) => {
                onFormChange("uploadImage", null);
                onFormChange("logoName", e.target.value);
                setLogoError(false);
                setImageSizeError("");
              }}
            >
              {renderImageList()}
            </SelectLogo>
            <StyledFormHelperText>
              You can choose to either select a previously uploaded image or
              upload a new one by clicking on the button below.
            </StyledFormHelperText>
          </Form>
        </Division>
        <DragAndDrop handleDragAndDrop={handleFileUpload}>
          <UploadContainer>
            <StyledCloudUploadIcon />
            <InfoColumnTwo>
              <DragAndDropContainer>
                Drag & drop files or
                <SelectFile>Select File</SelectFile>
              </DragAndDropContainer>
              <UploadButtonFirst>
                <Label>
                  <SimpleInput
                    inputProps={{
                      accept: allowedFileTyped,
                    }}
                    type="file"
                    onChange={handleFileUpload}
                  />
                  <UploadAnImage /> Upload an Image
                </Label>
              </UploadButtonFirst>
              <FormatInfo>Supported formats: JPEG, PNG, and GIF</FormatInfo>
            </InfoColumnTwo>
          </UploadContainer>
          <Form error={imageSizeError !== ""}>
            {imageSizeError && (
              <StyledFormHelperText>{imageSizeError}</StyledFormHelperText>
            )}
          </Form>
        </DragAndDrop>
      </PreviewContainerOne>
    </InfoContainer>
  );
};
