import { FC } from "react";

import { useAclHooks } from "./hooks";

interface Props {
  permission: string | undefined;
  children?: any;
}

const Acl: FC<Props> = ({ children, permission }): JSX.Element | null => {
  const allowAccess = useAclHooks(permission);

  return allowAccess ? <>{children}</> : null;
};

export default Acl;
