import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Postgresql.css";

const PostgresqlTechnicalSpecifications: React.FC = () => {
  const RenderTechDetails = ({ heading, desc }) => {
    return (
      <div>
        <div className="flex-col">
          <Typography
            variant="h6"
            fontSize="1.25rem"
            sx={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {heading}
          </Typography>
        </div>
        <div className="flex-col padding1rem">
          <Typography
            variant="body1"
            sx={{ color: "#000", lineHeight: "1.5rem" }}
          >
            {desc}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex-col gap4rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Technical Specifications
          </Typography>
          <RenderTechDetails
            heading={"Storage Engine"}
            desc={
              "PostgreSQL uses a Write-Ahead Logging (WAL) storage engine to ensure data integrity and durability."
            }
          />

          <RenderTechDetails
            heading={"Data Integrity"}
            desc={
              "PostgreSQL provides full ACID compliance, ensuring reliable transaction processing with atomicity, consistency, isolation, and durability."
            }
          />

          <RenderTechDetails
            heading={"Concurrency Control"}
            desc={
              "Implements Multi-Version Concurrency Control (MVCC) to allow multiple transactions to occur simultaneously without locking."
            }
          />

          <RenderTechDetails
            heading={"Replication"}
            desc={
              "Supports both synchronous and asynchronous replication for high availability and disaster recovery."
            }
          />

          <RenderTechDetails
            heading={"Indexing Options"}
            desc={
              "Offers various indexing methods, including B-tree, Hash, GiST, SP-GiST, GIN, and BRIN, to optimize query performance."
            }
          />

          <RenderTechDetails
            heading={"Partitioning"}
            desc={
              "Supports table partitioning, allowing large tables to be divided into smaller, more manageable pieces for improved query performance and maintenance."
            }
          />

          <RenderTechDetails
            heading={"Full-Text Search"}
            desc={
              "Includes native full-text search capabilities with support for stemming, ranking, and complex search queries."
            }
          />

          <RenderTechDetails
            heading={"Extensibility"}
            desc={
              "Allows for the creation of custom data types, operators, functions, and procedural languages, and supports extensions like PostGIS for geospatial data."
            }
          />

          <RenderTechDetails
            heading={"Foreign Data Wrappers (FDW)"}
            desc={
              "Enables integration with external data sources, allowing PostgreSQL to query data stored in other databases and systems."
            }
          />

          <RenderTechDetails
            heading={"Authentication and Security"}
            desc={
              "Supports multiple authentication methods, including MD5, SCRAM-SHA-256, and Kerberos, and offers SSL/TLS encryption for secure connections."
            }
          />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default PostgresqlTechnicalSpecifications;
