import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Oracle.css";
import OraclePricing from "./OraclePricing";

const OraclePlansAndFeatures: React.FC = () => {
  return (
    <>
      <div className={"gap4rem flex-col"}>
        <div className={"gap1rem flex-col"}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Pricing Plans and Features
          </Typography>
          <div className={"gap2rem flex-row"}>
            <div className={"gap1rem flex-col"}>
              <Typography
                variant="h6"
                fontSize="1.25rem"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                Oracle Database 23ai includes
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                {" "}
                <ul>
                  <li>
                    AI Vector Search enables semantic search across documents,
                    images, and relational data.
                  </li>
                  <li>
                    JSON Relational Duality supports seamless interaction with
                    both JSON and relational data models.
                  </li>
                  <li>
                    Graph Relational Unification facilitates complex
                    relationship analysis using property graphs.
                  </li>
                  <li>
                    Oracle True Cache maintains transactionally consistent data
                    in memory for faster performance.
                  </li>
                  <li>
                    In-Database SQL Firewall protects against SQL injection
                    attacks and unauthorized SQL activities.
                  </li>
                  <li>
                    Globally Distributed Database with RAFT ensures high
                    availability and automatic failover across regions.
                  </li>
                  <li>
                    Exadata System Software 24ai accelerates AI Vector Search by
                    processing data on storage servers.
                  </li>
                  <li>
                    OCI GoldenGate 23ai enables real-time vector replication
                    across heterogeneous data stores.
                  </li>
                  <li>
                    Oracle Select AI translates natural language queries into
                    SQL for easy database interaction.
                  </li>
                  <li>
                    Always Free Autonomous Database provides developers with
                    access to the latest database features.
                  </li>
                  <li>
                    Operational Property Graph allows querying of graph data
                    using standard SQL/PGQ syntax.
                  </li>
                  <li>
                    Oracle APEX platform supports low-code development, speeding
                    up the creation of AI-powered applications.
                  </li>
                </ul>
              </Typography>
            </div>
          </div>
          <OraclePricing title={"Plan Options & Details"} />
        </div>
      </div>
    </>
  );
};

export default OraclePlansAndFeatures;
