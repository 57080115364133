import * as React from "react";
import { Button } from "@ssce/ui-shared-library";
import { useNavigate } from "react-router-dom";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent";

const Msftsql: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/Sql.png"}
        title={"Microsoft SQL Server 2019"}
        subTitle="Msftsql"
        contents={
          "Microsoft SQL Server 2019 is a relational database management system (RDBMS) that provides advanced capabilities for enterprise data management, big data integration, and intelligent performance tuning. It supports high availability and disaster recovery solutions, ensuring continuous business operations. Additionally, it offers comprehensive security features, including data encryption and access control, to protect sensitive information."
        }
        tags={[
          {
            label: "Data Management",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Analytics",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Web Server",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs title={"MfstSQL"} instanceName={"MfstSQL"} />
    </>
  );
};
export default Msftsql;
