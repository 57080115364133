import { Carousel } from "react-responsive-carousel";
import "./CatalogAlgoRiskReport.css";
import AlgoRiskCharts from "../../../../assets/AlgoRiskCharts.png";
import AlgoRiskDistributionChart from "../../../../assets/AlgoRiskDistributionchart.png";
import AlgoRiskScenarioChart from "../../../../assets/AlgoRiskScenarioChart.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function CatalogAlgoRiskCarousle() {
  return (
    <>
      <Carousel className="catalog-algorisk-carousle">
        <div className="algorisk-slide-holder">
          <img
            alt={"algoRiskCharts"}
            className="algorisk-slide-image"
            src={AlgoRiskCharts}
          />
        </div>
        <div className="algorisk-slide-holder">
          <img
            alt={"AlgoRiskDistributionChart"}
            className="algorisk-slide-image"
            src={AlgoRiskDistributionChart}
          />
        </div>
        <div className="algorisk-slide-holder">
          <img
            alt={"AlgoRiskScenarioChart"}
            className="algorisk-slide-image"
            src={AlgoRiskScenarioChart}
          />
        </div>
      </Carousel>
    </>
  );
}
