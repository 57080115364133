import styled from "styled-components";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { Link } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";

export const DocumentationContainer = styled.div`
  width: 100%;
  margin-top: 0;
`;

export const MainTitle = styled(Typography)`
  color: var(--primary) !important;
  margin-bottom: 1.25rem;
  font-size: 2.125rem !important;
  margin-top: 5.625rem !important;
  font-weight: 900 !important;
  margin-bottom: 2.5rem !important;
  font-family: Roboto;
  line-height: 2.25rem;
  letter-spacing: 0;
  text-align: left;
`;

export const Section = styled.div`
  margin-bottom: 6.25rem;
  margin-top: 3.125rem;
`;

export const SubtitleSection = styled(Typography)`
  margin-bottom: 6.25rem;
  margin-top: -3.125rem !important;
`;

export const Subtitle = styled(Typography)`
  margin-bottom: 1.875rem !important;
  font-size: 1.5rem !important;
  font-weight: 500;
  color: #02273c;
  font-family: Roboto;
  line-height: 1.5rem;
  letter-spacing: 0.01125rem;
  text-align: left;
`;

export const Description = styled(Typography)`
  font-size: 1rem;
  max-width: 75%;
  text-align: justify;
  line-height: 1.5rem !important;
  font-family: Roboto;
  font-weight: 400;
  letter-spacing: 0.03125rem;
  color: rgba(2, 39, 60, 1);
`;

export const CardsGeneralSection = styled.div`
  display: flex;
  margin-top: -5rem;
  gap: 1.5625rem;
`;

export const StyledCard = styled(Card)`
  display: flex;
  height: 12.5rem;
  width: 21.875rem;
  border-radius: 0.5rem !important;
  box-shadow:
    0 0.125rem 0.125rem 0 #02273c24,
    0 0.1875rem 0.0625rem 0 #02273c1f,
    0 0.0625rem 0.3125rem 0 #02273c33;
`;

export const StyledCardMedia = styled(CardMedia)`
  width: 100%;
  height: 100%;
`;

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const Arrow = styled.div``;

export const CardSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledCardContent = styled(CardContent)`
  display: flex;
  padding: 1rem 0 1rem 0.3125rem;
`;

export const CardTitle = styled(Typography)`
  text-align: left;
  font-weight: 600 !important;
  font-size: 1.2rem;
  display: inline-block;
  font-family: Roboto;
  font-size: 1.25rem;
  line-height: 1.5rem !important;
  letter-spacing: 0.009375rem;
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
`;

export const CardDescription = styled(Typography)`
  margin-top: 0.625rem;
  text-align: left;
  font-weight: 400;
  margin-top: 0.313rem;
  width: 11.5625rem;
  font-family: Roboto;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.015625rem;
  color: rgba(2, 39, 60, 1);
`;

export const InfoLink = styled.div`
  margin-top: -3.125rem;
  margin-bottom: 3.125rem;
  font-size: 1.5rem;
  font-weight: 500;
`;

export const MoreArrow = styled.img`
  position: relative;
  top: 0.8125rem;
`;

export const StyledLink = styled(Link)`
  color: #33bde9 !important;
`;

export const GeneralContainer = styled.div`
  display: flex;
`;

export const DocArrowImg = styled.img`
  position: relative;
  top: -0.625rem;
  left: -0.625rem;
`;

export const StyledList = styled(List)``;
export const StyledListItem = styled(ListItem)``;
export const StyledListItemButton = styled(ListItemButton)``;
export const StyledListItemText = styled(ListItemText)``;
export const StyledTimeline = styled(Timeline)``;
export const StyledTimelineItem = styled(TimelineItem)``;
export const StyledTimelineSeparator = styled(TimelineSeparator)``;
export const StyledTimelineConnector = styled(TimelineConnector)``;
export const StyledTimelineContent = styled(TimelineContent)``;
export const StyledTimelineDot = styled(TimelineDot)``;
