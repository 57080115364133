import { Reducer } from "redux";
import { MyservicesAction, MyservicesActionType } from "./actions";
import { createDefaultMyservicesState, MyservicesState } from "./state";

type UserReducer = Reducer<MyservicesState, MyservicesAction>;
const reducer: UserReducer = (state = createDefaultMyservicesState(), action) => {
  switch (action.type) {
    case MyservicesActionType.SET_IS_LOADING: 
      return state.items.length !== 0 ? { ...state} : {...state, isLoading: action.payload };
    case MyservicesActionType.SET_IS_DELETE_LOADING: 
      return {...state, isDeleteLoading: action.payload };
    case MyservicesActionType.SET_MYSERVICES_DATA: 
      return { ...state, items: action.payload };

    default: {
      return state;
    }
  }
};

export default reducer;
