import { AuthConsumer } from "./authProvider";

export const SilentRenew = () => (
  <AuthConsumer>
    {({ signinSilentCallback }) => {
      signinSilentCallback();
      return <div />;
    }}
  </AuthConsumer>
);
