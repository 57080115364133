import Typography from "@mui/material/Typography";
import ConstructionPage from "../../../assets/ConstructionPage.png";
import { Button } from "@ssce/ui-shared-library";
import { useNavigate } from "react-router-dom";

const UnderConstructionPage: React.FC<{ title: string }> = ({ title }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          marginLeft: "0%",
          textAlign: "center",
          marginTop: "2%",
          display: "flex",
          flex: "100%",
          flexWrap: "wrap",
        }}
      >
        <div style={{ width: "40%", display: "flex", flexDirection: "column" }}>
          <Typography
            variant="h1"
            color="#0A85C7"
            marginBottom={"1rem"}
            textAlign="center"
            style={{ fontSize: "2.125rem", marginTop: "3.125rem" }}
          >
            {title}
          </Typography>
          <Typography
            variant="h1"
            color="#355263"
            fontWeight={500}
            fontFamily="Roboto Medium"
            fontSize={20}
            textAlign="center"
          >
            We're sorry but the page you requested is currently
            <br></br>unavailable. Let's help get you turned around!
          </Typography>
          <div style={{ padding: "1.5rem 0 3.875rem 0" }}>
            <Typography
              variant="h1"
              color="#355263"
              fontWeight={100}
              fontFamily="Roboto"
              fontSize={16}
              textAlign="center"
              margin="1.25rem"
              marginTop="0"
              lineHeight={"1.5rem"}
            >
              We're currently in the process of building a better, more
              user-friendly support experience for you. While we're working hard
              behind the scenes, please know that your concerns are essential to
              us. If you require immediate assistance or have pressing
              questions, feel free to contact our customer service team directly
              at:
            </Typography>

            <Typography
              variant="h1"
              fontFamily="Roboto"
              fontSize={16}
              textAlign="center"
              margin="1.25rem"
              marginTop="0"
              lineHeight={"1.5rem"}
            >
              <a href="mailto:SSCE_Tech_Support@sscinc.com">
                SSCE_Tech_Support@sscinc.com
              </a>
            </Typography>

            <Typography
              variant="h1"
              color="#355263"
              fontWeight={100}
              fontFamily="Roboto"
              fontSize={16}
              textAlign="center"
              margin="2.5rem"
              marginTop="0"
              lineHeight={"1.5rem"}
            >
              We appreciate your patience and understanding, and we're excited
              to serve you better soon!
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "100%",
              gap: "1rem",
            }}
          >
            <Button
              variant="secondary"
              onClick={() => navigate(-1)}
              id="backToPreviousPage"
            >
              GO BACK TO PREVIOUS PAGE
            </Button>
            <Button
              variant="default"
              onClick={() => navigate("/")}
              id="toHomepage"
            >
              TAKE ME TO THE HOMEPAGE
            </Button>
          </div>
        </div>
        <div style={{ marginTop: "3.75rem" }}>
          <img
            src={ConstructionPage}
            alt="Error401"
            width={500}
            height={500}
          ></img>
        </div>
      </div>
    </>
  );
};

export default UnderConstructionPage;
