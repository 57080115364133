import { useEffect, useState } from "react";
import axios from "axios";
import { baseUrl, dataMeshRedirectURL } from "src/constants";

export type EndpointData = {
  response: any;
  status?: number;
  statusText?: string;
  isLoading: boolean;
  type?: string;
  parameters?: any;
};
export type SchemaContentType = "JSON" | "Typescript";
export type UseCaseContentType = "JSON" | "CSV";
type OperandValue = {
  operator: string;
  value: string;
};
export type UseCaseTableData = {
  parameter: string;
  dataType: string;
  readOnlyOperandValue: OperandValue;
  editableOperandValue: OperandValue;
  description: string;
  optional: boolean;
};

const INITIAL_ENDPOINT_DATA = {
  response: undefined,
  status: undefined,
  statusText: "",
  isLoading: false,
  type: undefined,
  parameters: [],
};

const mapUseCaseApiToUi = (apiData): UseCaseTableData[] => {
  return apiData.map((data) => ({
    parameter: data.ColumnName,
    dataType: data.DataType,
    readOnlyOperandValue: {
      value: data.DefaultValue,
      operand: data.Operand,
    },
    editableOperandValue: { value: "", operand: "" },
    description: data.Description,
    optional: data.Optional,
  }));
};

export const useTryItNow = (catalogId, productId) => {
  const [useCaseTableParameters, setUseCaseTableParameters] = useState<
    UseCaseTableData[]
  >([]);
  const [healthEndpointData, setHealthEndpointData] = useState<EndpointData>(
    INITIAL_ENDPOINT_DATA,
  );
  const [useCaseData, setUseCaseData] = useState<EndpointData>(
    INITIAL_ENDPOINT_DATA,
  );
  const [schemaData, setSchemaData] = useState<EndpointData>(
    INITIAL_ENDPOINT_DATA,
  );
  const [instanceId, setInstanceId] = useState("");
  const [hasError, setHasError] = useState(false);
  useEffect(() => {
    axios
      .get(
        `${dataMeshRedirectURL}/output-api/view/custom-api/parameter-details/${productId}`,
      )
      .then((response) => {
        setUseCaseTableParameters(mapUseCaseApiToUi(response.data.data));
      })
      .catch(() => {
        setHasError(true);
      });
    axios
      .get(
        `${baseUrl}/api/mktplc-catalog-api/v0/private/services/all/my-services`,
      )
      .then((response) => {
        const instanceId = response.data.find(
          (resp) => resp.catalogId === catalogId,
        )?.instanceId;
        setInstanceId(instanceId);
      })
      .catch(() => setHasError(true));
  }, [catalogId, productId]);

  const getHealthEndpoint = async () => {
    try {
      setHealthEndpointData({
        ...INITIAL_ENDPOINT_DATA,
        isLoading: true,
      });
      const response = await axios.get(
        `${dataMeshRedirectURL}/output-api/health`,
      );

      setHealthEndpointData({
        response: response.data,
        status: response.status,
        statusText: response.statusText,
        isLoading: false,
      });
    } catch {
      setHasError(true);
      setHealthEndpointData(INITIAL_ENDPOINT_DATA);
    }
  };

  const getUseCaseEndpoint = async ({ limit, responseType, tableData }) => {
    const formattedParameters = [
      ...tableData
        .filter(
          (item) =>
            (item.editableOperandValue.value &&
              item.editableOperandValue.operand) ||
            (item.dataType === "string" && item.editableOperandValue.operand),
        )
        .map((item) => ({
          Parameters: item.parameter,
          DataType: item.dataType,
          Value: item.editableOperandValue.value,
          Operator: item.editableOperandValue.operand,
        })),
    ];

    try {
      setUseCaseData({
        ...INITIAL_ENDPOINT_DATA,
        isLoading: true,
      });

      const response = await axios.post(
        `${dataMeshRedirectURL}/output-api/view/custom-api/${productId}?responseType=${responseType}&limit=${limit}`,
        formattedParameters,
      );

      setUseCaseData({
        response: response.data ? response.data : response,
        status: response.status,
        statusText: response.statusText,
        isLoading: false,
        type: responseType,
        parameters: formattedParameters,
      });
    } catch (e) {
      setHasError(true);
      setUseCaseData(INITIAL_ENDPOINT_DATA);
    }
  };

  const getSchemaEndpoint = async (schemaType: string) => {
    try {
      setSchemaData({
        ...INITIAL_ENDPOINT_DATA,
        isLoading: true,
      });
      const response = await axios.get(
        `${dataMeshRedirectURL}/output-api/view/schema/explain/${productId}?responseType=${schemaType}`,
      );
      setSchemaData({
        response: response.data,
        status: response.status,
        statusText: response.statusText,
        isLoading: false,
        type: schemaType,
      });
    } catch {
      setHasError(true);
      setUseCaseData(INITIAL_ENDPOINT_DATA);
    }
  };
  return {
    hasError,
    healthEndpointData,
    useCaseData,
    schemaData,
    instanceId,
    getHealthEndpoint,
    getUseCaseEndpoint,
    getSchemaEndpoint,
    useCaseTableParameters,
  };
};
