import { FC, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Divider from "@mui/material/Divider";
import WarningIcon from "@mui/icons-material/Warning";
import { Table } from "@ssce/ui-shared-library";
import { CurrentViewContext } from "../../ComponentWithContext";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";
import { CardBanner } from "../../subcomponents/CardBanner";
import { ErrorNotification } from "@ssce/ui-shared-library";
import { listingColumns, apiColumns } from "./ManageListingUtils";
import { useManageListingHooks } from "./manageListingHooks";
import Alert from "@mui/material/Alert";
import "./manageListing.scss";

const ManageListingHomePage: FC = () => {
  const navigate = useNavigate();
  const { setViewData } = useContext(CurrentViewContext);
  const hookProps = useManageListingHooks();

  const { isLoading, pendingAPIs, yourListings, hasError } = hookProps;
  const onActionClick = (row) => {
    localStorage.setItem("serviceData", JSON.stringify(row));
    setViewData(row);
    navigate("/manage-listings/publish-api");
  };
  return (
    <>
      {hasError && <ErrorNotification />}
      <div className="manageListingContainer">
        <div className="manageListingDividerDiv">
          <Divider></Divider>
        </div>
        <div className="contentSection">
          <CardBanner
            cardLists={[
              {
                id: "100",
                title: "Getting Started",
                subTitle: "FAQ",
                descrition:
                  "Dive in easily with our Getting Started Documentation and FAQ.",

                onClick: () => {
                  navigate("/documentation/getting-started");
                },
              },
              {
                id: "101",
                title: "Requirements",
                subTitle: "Technical",
                descrition:
                  "Outlines the essential specifications and tools necessary to effectively use our platform.",
                onClick: () => {
                  navigate("/documentation/api-docs");
                },
              },
              {
                id: "102",
                title: "Regulatory",
                subTitle: "Compliance",
                descrition:
                  "Browse information on laws, regulations, guidelines, and standards effortlessly.",
                onClick: () => {
                  navigate("/documentation/regulatory");
                },
              },
            ]}
          />
          {isLoading ? <LoadingAnimation /> : <></>}
          <div
            className="tableContainer"
            style={{
              paddingTop: "1.5rem",
              display: !isLoading ? "block" : "none",
            }}
          >
            <Alert
              className="manageListingWarningAlert"
              severity="warning"
              iconMapping={{
                warning: <WarningIcon sx={{ color: "#E7AA12" }} />,
              }}
            >
              These APIs have been published to SS&C Everywhere Marketplace but
              not to the catalog yet. Please finish the publishing process
              below.
            </Alert>
            <div className="pendingApiTable" data-testid="pendingApiTable">
              <Table
                id={"Pending API Services"}
                title={"Pending API Listings"}
                columns={apiColumns(onActionClick)}
                data={pendingAPIs}
                showPagination
                showSearchFilter
              />
            </div>
            <div className="yourListingTable" data-testid="yourListingTable">
              <Table
                id={"Your Listings"}
                title={"Your Listings"}
                showSearchFilter
                columns={listingColumns(yourListings)}
                data={yourListings}
                showPagination
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageListingHomePage;
