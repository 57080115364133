import * as React from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import { Button as MUIButton } from "@mui/material";
import { Button } from "@ssce/ui-shared-library";
import PCAccessRequest from "./AccessRequest/PCAccessRequest";
import PCComputer from "../../../../assets/PCComputer.png";
import "../PrivateCatalog.css";

const PCNoAPIs: React.FC = () => {
  const [openRequestAccess, setRequestAccess] = useState(false);

  const handleORequestAccess = () => setRequestAccess(!openRequestAccess);
  return (
    <>
      <div className="PC-noAPIs" data-testid="private-api-no-apis">
        <div className="PC-noAPIs-info">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            color="primary"
            align="center"
          >
            No APIs available yet
          </Typography>
          <Typography variant="h6" lineHeight={2} align="center">
            That’s okay, it’s easy to get started! Please enjoy this free trial
            to preview the capabilities of Data Mesh.
          </Typography>
          <Typography
            variant="body1"
            fontSize="1rem"
            lineHeight={2}
            align="center"
          >
            Looks like you don't have any APIs published from this Data Mesh
            Instance. Just click on
            <b> "Request Access to Data Mesh"</b> below to get started or if you
            need a tutorial simply click on "Start Learning" to learn how
          </Typography>
          <Typography
            variant="h6"
            lineHeight={4}
            align="center"
            color="primary"
          >
            Create your first API today!
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MUIButton variant="outlined" className="PC-noAPIs-white-button">
              Start Learning
            </MUIButton>
            <Button
              className="PC-noAPIs-blue-button"
              variant="default"
              onClick={handleORequestAccess}
              id="requestDataMeshAccess"
            >
              Request Access to Data Mesh
            </Button>
          </div>
        </div>
        <img
          alt={"PCComputer"}
          src={PCComputer}
          className="PC-noAPIs-logo"
        ></img>
        {openRequestAccess && <PCAccessRequest />}
      </div>
    </>
  );
};
export default PCNoAPIs;
