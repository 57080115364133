import {
  Collapse,
  StyledExpandSubItemsIcon,
  StyledArrowForwardIosIcon,
  StyledNavLink,
  StyledSubNavLink,
  TitleWrapper,
  SubItemWrapper,
  Title,
} from "./Nav.styles";
import Acl from "../subcomponents/Acl";

type NavItemProps = {
  item: {
    title: string;
    to: string;
    icon: any;
    subMenu: {
      link: string;
      title: string;
      parent?: string;
      permission?: string;
    }[];
  };
  minimized: boolean;
  activeSubMenuItem: string;
  setActiveSubMenuItem: (arg0: string) => void;
  setActiveMenuItem: (arg0: string) => void;
  activeMenuItem: string;
  handleBreadCrumbNickName: (name: string) => void;
};

const NavItem = ({
  item,
  minimized,
  setActiveSubMenuItem,
  setActiveMenuItem,
  activeMenuItem,
  activeSubMenuItem,
  handleBreadCrumbNickName,
}: NavItemProps) => {
  return (
    <div>
      <StyledNavLink
        to={item.to}
        onClick={() => {
          handleBreadCrumbNickName("");
          setActiveMenuItem(item.title);
          localStorage.setItem("activeMenuItem", item.title);
          setActiveSubMenuItem(item.subMenu[0]?.title ?? "");
          localStorage.setItem(
            "activeSubMenuItem",
            item.subMenu[0]?.title ?? "",
          );
        }}
        target={item.title === "Data Mesh" ? "_blank" : ""}
      >
        <TitleWrapper
          isActive={activeMenuItem === (item?.subMenu[0]?.parent || item.title)}
        >
          <item.icon /> {!minimized && <Title>{item.title}</Title>}
        </TitleWrapper>
        {item.subMenu.length > 1 && !minimized && (
          <StyledExpandSubItemsIcon isActive={activeMenuItem === item.title} />
        )}
      </StyledNavLink>

      {item.subMenu.length > 1 && !minimized && (
        <Collapse isActive={activeMenuItem === item.title}>
          {item.subMenu.map((subItem) => {
            return (
              <Acl permission={subItem.permission} key={subItem.title}>
                <SubItemWrapper>
                  <StyledArrowForwardIosIcon
                    isActive={activeMenuItem === subItem.title}
                  />

                  <StyledSubNavLink
                    to={subItem.link}
                    isActive={activeSubMenuItem === subItem.title}
                    onClick={() => {
                      handleBreadCrumbNickName("");
                      setActiveSubMenuItem(subItem.title);
                    }}
                  >
                    {subItem.title}
                  </StyledSubNavLink>
                </SubItemWrapper>
              </Acl>
            );
          })}
        </Collapse>
      )}
    </div>
  );
};

export default NavItem;
