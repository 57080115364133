import {
  Heading,
  Subheading,
  P,
  ListItem,
  Bold,
} from "../Styles/Documentation.styles";
import { OnThisPageHelper } from "../OnThisPageHelper";

const anchorTagsArr = [
  { id: "1", subHeadingString: "A New Approach to Analytics:" },
  {
    id: "2",
    subHeadingString: "Why Choose the SS&C Algo Risk Reports Solution?",
  },
  {
    id: "3",
    subHeadingString: "Embracing the Future of Financial Risk Reporting:",
  },
];

export const AlgoRiskCopy = () => {
  return (
    <>
      <OnThisPageHelper anchorTagsArr={anchorTagsArr} />
      <Heading>
        Announcing the Launch of SS&C Algo Risk Reports Solution in the
        Everywhere Marketplace
      </Heading>
      <P>
        In the rapidly evolving world of financial risk management, the ability
        to quickly understand, assess, and act upon potential risks can be the
        differentiator between success and unforeseen challenges. Recognizing
        the need for more intuitive, efficient, and insightful risk reporting,
        we are thrilled to introduce the SS&C Algo Risk Reports solution—your
        gateway to sophisticated risk insights. This state-of-the-art solution
        is now seamlessly integrated into the SS&C Everywhere Marketplace,
        offering users an unparalleled experience in accessing and understanding
        their risk reports.
      </P>
      <P>
        The Algorithmics financial risk management software is at the heart of
        the offering. Our collaboration ensures that clients have not only the
        best-in-class risk management tools at their fingertips but also the
        convenience of a modern, cloud-based interface that's designed with the
        user in mind. By merging the power of Algorithmics with the innovative
        capabilities of the SS&C Everywhere Data Mesh, we have transformed the
        way risk reports are generated, accessed, and understood. Gone are the
        days of lengthy and complex implementation processes, ushering in a new
        era of streamlined, automated, and highly insightful risk management.
      </P>
      <Subheading id="1">A New Approach to Analytics:</Subheading>
      <P>
        In the realm of financial analytics reporting, professionals have long
        grappled with intricate systems and drawn-out implementation cycles.
        These tasks were often marked by numerous stages from data aggregation
        and cleansing to manual entry, rigorous checks, and recalibrations. This
        complexity didn’t just require significant time and manpower—it also
        introduced ample room for errors.
      </P>
      <P>
        The SS&C Everywhere Data Mesh is rewriting that narrative. This
        groundbreaking platform streamlines the entire risk reporting process,
        offering a more intuitive, automated, and efficient approach:
      </P>

      <ul>
        <ListItem>
          <Bold>Seamless Intergration:</Bold> The Data Mesh easily integrates
          with a myriad of SS&C solutions, third-party market data, and even
          accounting services, allowing for swift data aggregation.
        </ListItem>
        <ListItem>
          <Bold>Automated Processing:</Bold> The innovative connectors and
          transformers of the data mesh automate the data cleansing and
          standardization process, minimizing manual intervention and the
          associated risk of errors.
        </ListItem>
        <ListItem>
          <Bold>Instant Report Generation: </Bold> With a cleaner, more
          organized data flow, risk reports can be generated on demand and more
          quickly, providing timely insights.
        </ListItem>
        <ListItem>
          <Bold>Self-Service Access:</Bold> The integration with the SS&C
          Everywhere Marketplace empowers users to initiate new reports, view
          existing ones, and drill down for deeper insights—without the need for
          specialized training or additional software.
        </ListItem>
      </ul>
      <P>
        By minimizing manual touchpoints and maximizing automation, we're
        delivering a more agile, accurate, and actionable solution that truly
        meets the demands of today's dynamic financial landscape.
      </P>
      <Subheading id="2">
        Why Choose the SS&C Algo Risk Reports Solution?
      </Subheading>
      <P>
        In today's competitive financial landscape, it's often not enough to
        have just any risk reporting solution; you need the best. Here are the
        compelling reasons why the SS&C Algo Risk Reports solution stands out:
      </P>
      <ul>
        <ListItem>
          <Bold>Cost Efficiency: </Bold>Our modern cloud architecture and
          streamlined automation translate directly to savings. We pass these
          cost reductions onto our clients, ensuring that you gain powerful
          insights without straining your budget.
        </ListItem>
        <ListItem>
          <Bold>Deep-Dive Insights: </Bold>With the premier plan, you can
          unleash the full potential of the award-winning Algorithmics user
          interfaces. Dive deep into your risk profiles, understand every facet
          of your data, and make informed decisions faster than ever before.
        </ListItem>
        <ListItem>
          <Bold>Self-Service Flexibility: </Bold>You're in control. Initiate new
          reports, access existing ones, or explore further insights—all at your
          own pace and without the need to rely on external teams or wait for
          delivery schedules.
        </ListItem>
        <ListItem>
          <Bold>Seamless Integration: </Bold>Our solution effortlessly
          integrates with other SS&C services, from accounting to third-party
          market data. This means your risk reporting operates in harmony with
          other critical processes, ensuring consistent and reliable insights.
        </ListItem>
        <ListItem>
          <Bold>State-of-the-Art Technology: </Bold>We leverage the latest in
          technology, ensuring that your reports are not only accurate but also
          timely. In a world where market dynamics shift rapidly, having
          up-to-the-minute insights can be a game-changer.
        </ListItem>
        <ListItem>
          <Bold>Future-Proofing: </Bold>The SS&C Algo Risk Reports solution is
          not just about meeting today's needs; it's about preparing for
          tomorrow. Our continuous innovation ensures that as the world of
          financial risk evolves, our solution evolves with it, keeping you
          ahead of the curve.
        </ListItem>
      </ul>

      <P>
        Choosing the SS&C Algo Risk Reports solution is choosing a partner
        committed to your success. With us, you don't just get a product; you
        get a promise—a promise of precision, efficiency, and unparalleled
        insights.
      </P>
      <Subheading id="3">
        Embracing the Future of Financial Risk Reporting:
      </Subheading>
      <P>
        The ever-evolving financial landscape demands tools that aren't just
        reactive but proactive. In the SS&C Algo Risk Reports solution, we offer
        precisely that—a dynamic, intuitive, and efficient platform tailored to
        meet the unique challenges of today and tomorrow. By seamlessly merging
        the robust capabilities of Algorithmics with the innovative architecture
        of the SS&C Everywhere Data Mesh, we're redefining the standards for
        financial risk reporting.
      </P>
      <P>
        Our goal remains clear: empower our clients to make more informed
        decisions with confidence and clarity. With this solution, not only can
        you rapidly assess potential risks, but you also gain a strategic
        partner to navigate the complexities of the financial world.
      </P>
      <P>
        We invite you to experience the future of risk reporting. Dive into the
        SS&C Everywhere Marketplace, explore the myriad features of the SS&C
        Algo Risk Reports solution, and witness firsthand the transformative
        impact it can have on your organization.
      </P>
    </>
  );
};
