import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Sightline.css";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Users from "../../../../assets/Users.png";
import CheckCircle from "../../../../assets/CheckCircle.png";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";

type Props = {
  title: string;
};

const SightlinePricing: React.FC<Props> = ({ title }) => {
  return (
    <>
      <div className={"gap2rem flex-col"}>
        <Typography
          variant="h4"
          fontSize="2.125rem"
          fontWeight={600}
          component="div"
          sx={{ color: "#0A85C7", lineHeight: "2.25rem" }}
        >
          {title}
        </Typography>
        <div className={"gap3rem flex-col alignItems-center"}>
          <div className={"gap2rem flex-row"}>
            <div className="pricing-card-container">
              <Card
                sx={{
                  ":hover": { bgcolor: "#C0EBF8" },
                  width: "18.438rem",
                  padding: "1.5rem",
                  boxShadow:
                    "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                  border: "0.063rem solid #CBD9E1",
                  borderRadius: "0.25rem",
                  paddingBottom: "0.625rem",
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 auto",
                  alignSelf: "self-end",
                }}
              >
                <div className={"flex-row marginTop1rem"}>
                  <img
                    src={Users}
                    alt="SS&C logo"
                    className="pricing-standard-img"
                  ></img>
                  <div>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      align="left"
                      fontWeight={500}
                      lineHeight={1}
                      sx={{
                        fontSize: "2.125rem",
                        display: "inline-block",
                      }}
                    >
                      Standard
                    </Typography>
                  </div>
                </div>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  align="left"
                  fontWeight={500}
                  lineHeight={1.5}
                  sx={{
                    marginTop: "0",
                    fontSize: "1rem",
                    marginLeft: "1.875rem",
                  }}
                >
                  Perfect for getting started
                </Typography>
                <List>
                  <ListItem>
                    <img
                      src={CheckCircle}
                      alt="CSV Risk Report."
                      className="pricing-check-circle-img"
                    ></img>
                    <Typography
                      variant="body1"
                      fontSize="0.75rem"
                      fontWeight={500}
                      lineHeight={1}
                    >
                      Reports
                    </Typography>
                  </ListItem>
                </List>
              </Card>

              <Card
                sx={{
                  ":hover": {
                    bgcolor: "#C0EBF8",
                  },
                  width: "18.438rem",
                  padding: "1.5rem",
                  boxShadow:
                    "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                  border: "0.063rem solid #CBD9E1",
                  borderRadius: "0.25rem",
                  paddingBottom: "0.625rem",
                  display: "flex",
                  flexDirection: "column",
                  flex: "1 1 auto",
                  alignSelf: "self-end",
                }}
              >
                <div>
                  <Chip
                    label="Popular"
                    sx={{
                      "&": {
                        position: "absolute",
                        top: -5,
                        marginLeft: "10rem",
                        padding: "0.25rem 0.625rem",
                        fontFamily: "Roboto Medium",
                        color: "#F6FDFF",
                        fontSize: "0.75rem",
                        backgroundColor: "#3CC13B",
                      },
                    }}
                    color="success"
                  />
                </div>
                <div className={"flex-row marginTop1rem"}>
                  <img
                    src={Users}
                    alt="SS&C logo"
                    className="pricing-standard-img"
                  ></img>
                  <div>
                    <Typography
                      gutterBottom
                      fontWeight={600}
                      lineHeight={1}
                      sx={{
                        fontSize: "2.125rem",
                        display: "inline-block",
                      }}
                    >
                      Premium
                    </Typography>
                  </div>
                </div>
                <Typography
                  gutterBottom
                  variant="body2"
                  component="div"
                  align="left"
                  fontWeight={500}
                  lineHeight={1.5}
                  sx={{
                    marginTop: "0",
                    fontSize: "1rem",
                    marginLeft: "1.875rem",
                  }}
                >
                  Perfect for getting started
                </Typography>
                <List>
                  <ListItem>
                    <img
                      src={CheckCircle}
                      alt="CSV Risk Report."
                      className="pricing-check-circle-img"
                    ></img>
                    <Typography
                      variant="body1"
                      fontSize="0.75rem"
                      fontWeight={500}
                      lineHeight={1}
                    >
                      Reports
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <img
                      src={CheckCircle}
                      alt="Risk Dashboard Access"
                      className="pricing-check-circle-img"
                    ></img>
                    <Typography
                      variant="body1"
                      fontSize="0.75rem"
                      fontWeight={500}
                      lineHeight={1}
                    >
                      Dashboard Access
                    </Typography>
                  </ListItem>
                </List>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SightlinePricing;
