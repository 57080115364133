import styled, { keyframes } from "styled-components";

export const getStatusColor = (status) => {
  if (status === "success") return "#3CC13B";
  if (status === "failed") return "#F03737";
  if (status === "running") return "#0A85C7";
  if (!status || status === "pending") return "#B6C7D1";
  if (status === "blocked") return "#ffffff";
  return "#B6C7D1";
};

export const SvgContainer = styled.div`
  border: 0.188rem solid #0a85c7;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-self: end;
  column-gap: 0.625rem;
`;

export const ContactContainer = styled.div`
  margin: 0.625rem 0;
`;

export const Spacing = styled.div`
  margin: 1.25rem 0;
`;

export const StatusLabelContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
`;

export const ActionButtonsContainer = styled.div`
  display: flex;
  column-gap: 0.625rem;
`;

export const WorkflowContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const WorkflowViewerContainer = styled.div`
  border: 0.063rem solid #90a8b5;
`;

export const Footer = styled.div`
  grid-column: 1 / -1;
  justify-content: space-between;
  display: flex;
  margin-top: 0.625rem;
  align-items: center;
`;

export const ReportContainer = styled.div`
  background-color: #a1e1f5;
  width: 2.313rem;
  height: 2.313rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CloudContainer = styled.div`
  background-color: #a1e1f5;
  width: 2.313rem;
  height: 2.313rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const NodeContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  height: 100%;
  width: 17.188rem;
  border: 0.063rem solid #90a8b5;
  border-radius: 0.5rem;
  padding: 1rem;
  grid-column-gap: 0.625rem;
  position: relative;
  background-color: #f6fdff;
  &:hover,
  &:focus {
    border: 0.125rem solid #0a85c7;
  }
`;

export const IconContainer = styled.div<{ type?: string }>`
  background-color: ${(props) => {
    if (
      props.type === "cloud" ||
      props.type === "report" ||
      props.type === "database"
    )
      return "#A1E1F5";
    if (props.type === "doubleCheck") return "#DDF5E0";
    if (props.type === "crop") return "#CBD9E1";
    if (props.type === "hourglass") return "#FBF4DB";
    if (props.type === "connection") return "#E2D7FD";
    if (props.type === "persistence") return "#F5DEEA";
  }};
  width: 2.313rem;
  height: 2.313rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.span`
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.25rem;
  padding-right: 0.625rem;
`;

export const StatusText = styled.span<{ status?: string; type?: string }>`
  font-size: ${(props) => (props.type === "big" ? "1rem" : "0.75rem")};
  line-height: 1rem;
  color: ${(props) => {
    if (props.status === "running") return "#0A85C7";
    if (props.status === "success") return "#3CC13B";
    if (props.status === "failed") return "#F03737";
    if (props.status === "blocked") return "#F03737";
    if (props.status === "inactive") return "#75909F";
  }};
`;

export const StatusIcon = styled.div<{ type?: string; status: string }>`
  width: ${(props) => (props.type === "small" ? "0.75rem" : "1.125rem")};
  height: ${(props) => (props.type === "small" ? "0.75rem" : "1.125rem")};
  background-color: ${(props) => {
    return getStatusColor(props.status);
  }};
  border: ${(props) => {
    if (props.status === "blocked") {
      return "0.188rem solid #F03737";
    }
    if (props.status === "running") return "0.125rem solid #A1E1F5";
    else return "unset";
  }};
`;

export const SubTitle = styled.span`
  font-size: 0.875rem;
  line-height: 1rem;
`;

export const StatusContainer = styled.div`
  display: flex;
  column-gap: 0.313rem;
  justify-self: end;
`;

export const StatusLabel = styled.div`
  font-size: 1rem;
  margin-left: 0.313rem;
  margin-right: 1.25rem;
  line-height: 1.5rem;
`;

export const ExecutionLogContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f1f8fb;
  height: 93.75rem;
  width: 100%;
  padding: 1.563rem;
  overflow: scroll;
`;

const dash = keyframes`
  to {
    stroke-dashoffset: 0;
  }`;

export const AnimatedStepEdge = styled.path`
  stroke: #000;
  strokewidth: 0.063rem;
  fill: none;
  stroke-dasharray: 12.5rem;
  stroke-dashoffset: 12.5rem;
  animation: ${dash} 2s ease-in-out infinite;
`;
