import React, { useState } from "react";
import AlgoPerformance from "../../../../assets/WorkflowCatalog/AlgoPerformanceLight.png";
import AlgoRisk from "../../../../assets/WorkflowCatalog/AlgoRiskLight.png";
import DataMesh from "../../../../assets/WorkflowCatalog/DataMeshLogo.png";
import AlgoLogo from "../../../../assets/WorkflowCatalog/AlgoLogo.png";
import Grid from "../../subcomponents/Grid/Grid";
import WorkflowCatalogCard from "./WorkflowCatalogCard";
import { TextField } from "@ssce/ui-shared-library";
import {
  WorkflowCatalogSubtitle,
  FullWidthWrapper,
} from "./WorkflowCatalog.styles";
import { baseUrl } from "src/constants";

export const GETTING_STARTED_DATA = [
  {
    image: AlgoPerformance,
    title: "SS&C Performance Workflow",
    description:
      "Delivers a seamless end-to-end performance solution from data acquisition and transformation to performance reporting and interactive",
    tags: ["Cloud Platform", "Financial", "Performance"],
    services: [
      {
        link: `${baseUrl}/datamesh/`,
        serviceImage: DataMesh,
        serviceText: "Data Mesh",
      },
      {
        link: "/public-catalog/algoperformance-report",
        serviceImage: AlgoLogo,
        serviceText: "SS&C Performance Service",
      },
    ],
  },
  {
    image: AlgoRisk,
    title: "SS&C Risk Workflow",
    description:
      "Delivers a seamless end-to-end performance solution from data acquisition and transformation to risk reporting and interactive dashboards",
    tags: ["Cloud Platform", "Cross-Industry", "Risk"],
    services: [
      {
        link: `${baseUrl}/datamesh/`,
        serviceImage: DataMesh,
        serviceText: "Data Mesh",
      },
      {
        link: "/public-catalog/ss&c-algorithmics-risk-reports",
        serviceImage: AlgoLogo,
        serviceText: "SS&C Risk Service",
      },
    ],
  },
];

const WorkflowCatalogCardView: React.FC<{
  onCreateClick: (isAlgoRisk: boolean) => void;
}> = ({ onCreateClick }) => {
  const [searching, setSearching] = useState<string>("");

  const handleChangeForSearch = (e: { target: { value: any } }) => {
    setSearching(e.target.value);
  };
  const filteredData = GETTING_STARTED_DATA.filter((item) =>
    item.title.toLowerCase().includes(searching.toLowerCase()),
  );
  return (
    <>
      <WorkflowCatalogSubtitle>All Workflows</WorkflowCatalogSubtitle>
      <TextField
        placeholder="Search..."
        value={searching}
        onChange={handleChangeForSearch}
      />
      <div>
        <Grid minMax="25rem">
          {filteredData.map((item, index) => (
            <FullWidthWrapper key={index}>
              <WorkflowCatalogCard
                backgroundImage={item.image}
                title={item.title}
                description={item.description}
                services={item.services}
                tags={item.tags}
                onCreateClick={() =>
                  onCreateClick(item.title === "SS&C Risk Workflow")
                }
                isAlgoRiskReport={item.title === "SS&C Risk Workflow"}
              />
            </FullWidthWrapper>
          ))}
        </Grid>
      </div>
    </>
  );
};

export default WorkflowCatalogCardView;
