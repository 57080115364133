import * as React from "react";
import { useState, useEffect } from "react";
import { useProvisionDataMeshHooks } from "../ProvisionService/provisionDataMeshHooks";
import { InstanceNameProvision } from "../ProvisionService/provisionDataMeshComponents";
import {
  SelectRegionProvision,
  UserManagementProvision,
  SolutionConfigurationProvision,
  ReviewBillingProvision,
  ProvisionInstanceButton,
} from "../ProvisionService/provisionGeneralComponents";
import { ProvisionSuccessfulPage } from "../ProvisionService/provisionSuccessfulPage";
import { ErrorNotification } from "@ssce/ui-shared-library";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";
import CatalogSubHeading from "../CatalogSubHeading";
import Divider from "@mui/material/Divider";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";
import TarvosImage from "../../../../assets/Tarvos.png";

const TarvosProvision: React.FC = () => {
  const {
    createInstances,
    fetchCatalogID,
    setInstanceName,
    setProjectId,
    setIsLoading,
    hasError,
    catalogId,
    instanceName,
    projectId,
    projects,
    hasProjectIdError,
    hasInstanceNameError,
    isLoading,
  } = useProvisionDataMeshHooks();

  const [isProvisionSuccessful, setIsProvisionSuccessful] = useState(false);
  const [selectedRegion, setSelectedRegion] = React.useState("NorthAmerica");
  const handleProvisionInstance = async () => {
    const success = await createInstances({
      instanceName,
      projectId,
      catalogId,
      applicationName: "tarvos",
      region: selectedRegion,
    });
    let successAsBool = success as unknown as boolean;
    setIsLoading(false);
    setIsProvisionSuccessful(!!successAsBool);
  };

  useEffect(() => {
    fetchCatalogID("tarvos");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateAnotherInstance = () => {
    setIsProvisionSuccessful(false);
    setInstanceName("");
    setSelectedRegion("NorthAmerica");
    setProjectId("");
  };

  return (
    <>
      <div className="algo-performance-provision-container">
        {hasError && <ErrorNotification />}
        {!isProvisionSuccessful ? (
          !isLoading ? (
            <>
              <>
                <div className="catalogSuperset">
                  <CatalogSubHeading
                    title={"Provision Service"}
                    sx={{ padding: "0.625rem 0" }}
                  />
                  <div>
                    <Divider />
                  </div>
                </div>
                <MyServicesCardHeader
                  imageSource={TarvosImage}
                  title={"Tarvos LLM-aaS"}
                  contents={
                    "Tarvos is a cutting-edge Large Language Model (LLM) as a service, hosted securely within your internal infrastructure, giving you full control over your data. With Tarvos, your data never leaves your environment, ensuring the highest standards of privacy while unlocking insights, automating processes, and enhancing decision-making."
                  }
                  tags={[
                    {
                      label: "BI",
                      style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
                    },
                    {
                      label: "Cross-Industry",
                      style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
                    },
                    {
                      label: "Data & Analytics",
                      style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
                    },
                  ]}
                ></MyServicesCardHeader>
              </>

              <InstanceNameProvision
                value={instanceName}
                hasInstanceNameError={hasInstanceNameError}
                handleInstanceNameChange={(value: string) =>
                  setInstanceName(value)
                }
              />
              <SelectRegionProvision
                selectedRegion={selectedRegion}
                handleRegionChange={(value: string) => setSelectedRegion(value)}
                regions={[{ label: "North America", value: "NorthAmerica" }]}
              />
              <UserManagementProvision
                handleProjectChange={(projectId: string) =>
                  setProjectId(projectId)
                }
                projects={projects}
                projectId={projectId}
                hasProjectIdError={hasProjectIdError}
              />
              <SolutionConfigurationProvision isGlobal={false} />
              <ReviewBillingProvision />
              <ProvisionInstanceButton
                handleProvisionInstance={handleProvisionInstance}
              />
            </>
          ) : (
            <LoadingAnimation />
          )
        ) : (
          <ProvisionSuccessfulPage
            onCreateAnotherInstance={onCreateAnotherInstance}
          />
        )}
      </div>
    </>
  );
};

export default TarvosProvision;
