import * as React from "react";
import Typography from "@mui/material/Typography";
import ArticleIcon from "@mui/icons-material/Article";
import CableIcon from "@mui/icons-material/Cable";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import Computer from "../../../../assets/WorkflowCatalog/Computer.png";
import {
  WorkflowCatalogSetupContainer,
  WorkflowCatalogSetupHeader,
  WorkflowCatalogSetupHeaderText,
  WorkflowCatalogSetupHeaderImage,
  WorkflowCatalogSetupOptionsContainer,
  WorkflowCatalogSetupOption,
  WorkflowCatalogOptionDetails,
  WorkflowCatalogIcons,
  WorkflowCatalogText,
} from "./WorkflowCatalog.styles";

const WorkflowCatalogSetup: React.FC = () => {
  const workflowOptions = [
    {
      Icon: ArticleIcon,
      title: "Choose a Template",
      description:
        "Select from a range of workflow templates to quickly start automating your business processes. Each template is designed to be easily customizable to fit your specific operational needs.",
    },
    {
      Icon: CableIcon,
      title: "Plug in Services",
      description:
        "Integrate various services such as data management, analytics, or customer service platforms into your workflow to create a seamless operation that fits your business model.",
    },
    {
      Icon: DeviceHubIcon,
      title: "Configure Workflow",
      description:
        "Tailor your chosen workflow with specific parameters, decision points, and integrations to ensure it aligns perfectly with your business objectives and operational demands.",
    },
  ];

  return (
    <>
      <WorkflowCatalogSetupContainer>
        <WorkflowCatalogSetupHeader>
          <div style={{ flex: 1 }}>
            <Typography variant="h4" gutterBottom>
              What are Workflows?
            </Typography>
            <WorkflowCatalogSetupHeaderText>
              <Typography variant="body1">
                Workflows are pre-defined automation sequences that help
                organizations streamline their business processes. By
                integrating various services and configuring them to work
                together, workflows can reduce manual efforts, improve accuracy,
                and save time.
              </Typography>
            </WorkflowCatalogSetupHeaderText>
          </div>
          <div style={{ flex: 1 }}>
            <WorkflowCatalogSetupHeaderImage
              src={Computer}
              alt="Workflow Illustration"
            />
          </div>
        </WorkflowCatalogSetupHeader>
        <Typography variant="h5" component="div">
          How to Set Up a Workflow
        </Typography>
        <WorkflowCatalogSetupOptionsContainer>
          {workflowOptions.map((option, index) => (
            <WorkflowCatalogSetupOption key={index}>
              <WorkflowCatalogOptionDetails>
                <WorkflowCatalogIcons as={option.Icon} color="primary" />
                <WorkflowCatalogText>
                  <Typography variant="h6" align="center">
                    {option.title}
                  </Typography>
                  <Typography variant="body1" align="center">
                    {option.description}
                  </Typography>
                </WorkflowCatalogText>
              </WorkflowCatalogOptionDetails>
            </WorkflowCatalogSetupOption>
          ))}
        </WorkflowCatalogSetupOptionsContainer>
      </WorkflowCatalogSetupContainer>
    </>
  );
};

export default WorkflowCatalogSetup;
