import React, { useState, useEffect } from "react";
import "../../../../node_modules/reactflow/dist/style.css";
import {
  useWorkflowViewerHook,
  Run,
} from "./WorkflowViewerContent/workflowViewerHook";
import {
  WorkflowViewer as SharedWorkflowViewer,
  ErrorNotification,
} from "@ssce/ui-shared-library";
import { LoadingAnimation } from "../subcomponents/LoadingAnimation";
import { useParams, useLocation } from "react-router";
import RunNowModal from "./runNowModal";
import { useAppSelector } from "../../../redux/store";

type WorkflowViewerProps = {
  handleBreadCrumbNickName: (nickName: string) => void;
};

export const WorkflowViewer: React.FC<WorkflowViewerProps> = ({
  handleBreadCrumbNickName,
}) => {
  const clientId = useAppSelector((state: any) => {
    return state.userManagement.userInfo.clientId;
  });
  const [showRunJobModal, setShowRunJobModal] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isFetchingRunDetails, setIsFetchingRunDetails] =
    useState<boolean>(false);
  const { workflowId } = useParams();
  const location = useLocation();
  const { workflowName, instanceId } = location.state || {};
  const {
    currentWorkflow,
    isLoading,
    errorMessage,
    runTimes,
    dagList,
    runParameters,
    fetchRunDetails,
    getRunParameters,
    runJob,
    isRunning,
  } = useWorkflowViewerHook();
  useEffect(() => {
    if (clientId) {
      getRunParameters(instanceId);
      fetchRunDetails(workflowId as string);
    }
  }, [clientId]);
  const [selectedRun, setSelectedRun] = useState<string>(runTimes[0] ?? "");

  useEffect(() => {
    if (runTimes.length > 0 && !selectedRun) {
      setSelectedRun(runTimes[0] ?? "");
    }
  }, [runTimes]);

  useEffect(() => {
    handleBreadCrumbNickName(workflowName || "Workflow");
  }, [handleBreadCrumbNickName]);

  const getActiveStep = () => {
    if (!currentWorkflow || !Array.isArray(currentWorkflow.nodes)) return "";
    const activeNode = currentWorkflow.nodes.find(
      (node) => node.data.status === "running",
    );
    return activeNode ? activeNode.data.title : "";
  };
  const onRunChange = async (executionTime: string) => {
    const selectedRunId = dagList.find(
      (workflow: Run) => workflow?.start_date === executionTime,
    )?.dag_run_id;
    setIsFetchingRunDetails(true);
    await fetchRunDetails(workflowId as string, selectedRunId);
    setIsFetchingRunDetails(false);
    setSelectedRun(executionTime);
  };
  const workflowHeader = {
    onRunChange,
    selectedRun: selectedRun,
    currentStatus: currentWorkflow?.status,
    allRuns: runTimes,
    startTime: currentWorkflow?.startTime,
    runningTime: currentWorkflow?.runningTime,
    activeStep: getActiveStep(),
  };
  const filteredExecutionLogs = currentWorkflow
    ? currentWorkflow.nodes
        .map((node) => ({
          timeStamp: node.data.createdAt,
          statusMessage: `${node.data.title} is ${node.data.status} status`,
        }))
        .filter((error) => {
          return (
            error.timeStamp
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            error.statusMessage
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          );
        })
    : [].filter(
        (error: any) =>
          error.timeStamp?.toLowerCase().includes(searchValue.toLowerCase()) ||
          error.statusMessage.toLowerCase().includes(searchValue.toLowerCase()),
      );
  if (isLoading) {
    return <LoadingAnimation />;
  } else if (!currentWorkflow) {
    return <ErrorNotification message={"This workflow has not been run"} />;
  }
  return (
    <div>
      {showRunJobModal && runParameters && (
        <RunNowModal
          runParameters={runParameters}
          onModalClose={() => setShowRunJobModal(false)}
          runJob={async (jobParameters) => {
            setIsFetchingRunDetails(true);
            const updatedRunTimes = await runJob(
              workflowId as string,
              jobParameters,
            );
            setIsFetchingRunDetails(false);
            updatedRunTimes && setSelectedRun(updatedRunTimes[0] ?? "");
            setShowRunJobModal(false);
          }}
        />
      )}
      <SharedWorkflowViewer
        title={workflowName}
        fetchNodeDetails={(selectedRun) => {
          const workflow = dagList.find((workflow: Run) => {
            return (
              `${workflow.dag_id}+++${workflow.dag_run_id}` === selectedRun.id
            );
          });
          workflow &&
            fetchRunDetails(workflowId as string, workflow.dag_run_id, true);
        }}
        handleRunJob={() => {
          setShowRunJobModal(true);
        }}
        selectedRun={currentWorkflow}
        workflowHeader={workflowHeader}
        executionLogs={filteredExecutionLogs}
        onExecutionLogSearch={(searchValue) => setSearchValue(searchValue)}
        hasError={!!errorMessage}
        nodes={currentWorkflow.nodes}
        edges={currentWorkflow.edges}
        isFetchingRunDetails={isFetchingRunDetails}
        isRunning={isRunning}
      />
    </div>
  );
};
