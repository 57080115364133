import * as React from "react";
import Typography from "@mui/material/Typography";
import "./AlgoPerformanceReport.css";
import AlgoPerformancePricing from "./AlgoPerformancePricing";

const AlgoPerformancePlansAndFeatures: React.FC = () => {
  return (
    <>
      <div className={"gap4rem flex-col"}>
        <div className={"gap1rem flex-col"}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Pricing Plans and Features
          </Typography>
          <div className={"gap2rem flex-row"}>
            <div className={"gap1rem flex-col"}>
              <Typography
                variant="h6"
                fontSize="1.25rem"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                Risk Analytics include
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                <ul>
                  <li>Returns using time- and money-weighted methodologies</li>
                  <li>
                    Period returns over 1-day, 5-day, 10-day, MTD, QTD and YTD
                  </li>
                  <li>Contribution to Returns</li>
                  <li>
                    Ex-Post risk analytics including mean return, volatility of
                    return, beta, Information ratio, Sharpe ratio,
                    {<br />}
                    Maximum Drawdown
                  </li>
                  <li>Exposure – in monetary units and as a % of total fund</li>
                  <li>P&L</li>
                </ul>
              </Typography>
            </div>

            <div>
              <div className={"gap1rem flex-col"}>
                <Typography
                  variant="h6"
                  fontSize="1.25rem"
                  sx={{ color: "#02273C", lineHeight: "1.5rem" }}
                >
                  Stress Test Scenario Sets
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ color: "#02273C", lineHeight: "1.5rem" }}
                >
                  <ul>
                    <li>Asset Type</li>
                    <li>Sector</li>
                    <li>Rating</li>
                    <li>Region</li>
                  </ul>
                </Typography>
              </div>
            </div>
          </div>
          <AlgoPerformancePricing title={"Plan Options & Details"} />
        </div>
      </div>
    </>
  );
};

export default AlgoPerformancePlansAndFeatures;
