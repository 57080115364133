import * as React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Tableau from "../../../../../assets/tableau.png";
import { CurrentViewContext } from "../../../ComponentWithContext";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import { Button } from "@ssce/ui-shared-library";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

const TableauReport: React.FC = () => {
  const navigate = useNavigate();
  const { viewData } = useContext(CurrentViewContext);

  return (
    <>
      <MyServicesCardHeader
        imageSource={Tableau}
        title={"Tableau"}
        subTitle={`Tableau, ${viewData.region === "NorthAmerica" ? "North America" : viewData.region}`}
        contents={
          "Tableau is a leading data visualization tool that enables users to transform raw data into interactive and shareable dashboards. It connects seamlessly to various data sources, allowing for real-time data analysis and visualization. Users can create a wide range of visualizations, from simple charts to complex graphs, without extensive technical expertise. Tableau's intuitive drag-and-drop interface makes it accessible for both beginners and advanced users. Widely used in business intelligence, it facilitates data-driven decision-making by presenting data in a clear and visually appealing manner."
        }
        tags={[
          {
            label: "BI",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs title={"Tableau"} instanceName={"Tableau"} />
    </>
  );
};
export default TableauReport;
