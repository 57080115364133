import React, { useEffect } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import AllRegion from "../../../assets/AllRegion.png";
import Canada from "../../../assets/Canada.png";
import UK from "../../../assets/UK.png";
import Europe from "../../../assets/Europe.png";
import CentralUS from "../../../assets/CentralUS.png";
import NorthAmerica from "../../../assets/NorthAmerica.png";
import Global from "../../../assets/Global.png";

const availableRegions = {
  "All Regions": AllRegion,
  Canada: Canada,
  UK: UK,
  Europe: Europe,
  CentralUS: CentralUS,
  NorthAmerica: NorthAmerica,
  Global: Global,
};

type RegionProps = {
  selectedRegion?: string;
  regionList?: any;
};

export default function Region({ selectedRegion, regionList }: RegionProps) {
  const [regionImage, setRegionImage] = React.useState("NorthAmerica");
  const [regions, setRegions] = React.useState(availableRegions);
  const handleChange = ({ target }) => {
    const { value } = target;
    setRegionImage(value);
  };

  useEffect(() => {
    if (selectedRegion) {
      setRegionImage(selectedRegion);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (regionList) {
      setRegions(regionList);
      if (Object.keys(regionList).length === 1 && regionList.Global) {
        setRegionImage("Global");
      }
    }
  }, [regionList]);

  const isGlobalOnly = Object.keys(regions).length === 1 && regions.Global;

  return (
    <div className="container text-center">
      {!selectedRegion && !isGlobalOnly && (
        <>
          {" "}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={regionImage}
            size="small"
            onChange={handleChange}
            sx={{ backgroundColor: "#fff", minWidth: 250 }}
          >
            {Object.keys(regions).map((item) => (
              <MenuItem key={item} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText sx={{ fontSize: "0.75rem", color: "#355263" }}>
            {" "}
            Use the drop down field to learn more about a specific region.
          </FormHelperText>
        </>
      )}
      <img
        className="img-fluid"
        id="cars"
        src={regions[regionImage]}
        alt="region"
        style={{ width: "18.75rem", height: "11.25rem" }}
      />
    </div>
  );
}
