import * as React from "react";
import Box from "@mui/material/Box";
import { Table } from "@ssce/ui-shared-library";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import ClearIcon from "@mui/icons-material/Clear";
import { Button } from "@ssce/ui-shared-library";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

export default function EnhancedTable({ rows, updateRows, isGlobal }) {
  const [selected, setSelected] = React.useState<readonly string[]>([]);

  const handleDeleteRow = (id: number) => {
    const updatedData = rows.filter((row) => row.id !== id);
    const updatedSeletedData = selected.filter((s) => s !== id.toString());

    setSelected(updatedSeletedData);
    updateRows(updatedData);
  };

  const handleClick = (_, id: string) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: string) => selected.indexOf(id) !== -1;

  return (
    <Box sx={{ width: "100%" }}>
      <div style={{ display: "flex" }}>
        <Button
          variant="secondary"
          className="MS-AlgoRisk-csv-download-button"
          id={"delete"}
          onClick={() => {
            const updatedData = rows.filter(
              (row) => selected.indexOf(row.id) === -1,
            );
            updateRows(updatedData);
            setSelected([]);
          }}
          isDisabled={selected.length === 0}
        >
          Delete <DeleteForeverIcon />
        </Button>
      </div>
      <Paper sx={{ width: "100%", mb: 2, backgroundColor: "inherit" }}>
        <Table
          title={isGlobal ? "Selected Companies" : "Companies"}
          data={rows}
          id={"table"}
          showSearchFilter
          columns={
            isGlobal
              ? [
                  {
                    title: "Company",
                    dataIndex: "name",
                    sorter: true,
                    render: (name, row) => (
                      <>
                        <Checkbox
                          id={name}
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isSelected(row.id)}
                          sx={{
                            color: "#0A85C7",
                          }}
                        />
                        {name}
                      </>
                    ),
                  },
                  {
                    title: "Annual Fee",
                    dataIndex: "annualFee",
                    sorter: true,
                  },
                  {
                    title: "Action",
                    dataIndex: "id",
                    render: (id) => (
                      <ClearIcon
                        sx={{ color: "#0A85C7", cursor: "pointer" }}
                        id={id}
                        onClick={() => {
                          handleDeleteRow(id);
                        }}
                      />
                    ),
                  },
                ]
              : [
                  {
                    title: "Fund Name",
                    dataIndex: "name",
                    sorter: true,
                    render: (name, row) => (
                      <>
                        <Checkbox
                          id={name}
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isSelected(row.id)}
                        />
                        {name}
                      </>
                    ),
                  },
                  {
                    title: "FundType",
                    dataIndex: "fundType",
                    sorter: true,
                  },
                  {
                    title: "Net Assert Value",
                    dataIndex: "netAssetValue",
                    sorter: true,
                  },
                  {
                    title: "Annual Fee",
                    dataIndex: "annualFee",
                    sorter: true,
                  },
                  {
                    title: "Action",
                    dataIndex: "id",
                    render: (id) => (
                      <ClearIcon
                        sx={{ color: "#0A85C7", cursor: "pointer" }}
                        id={id}
                        onClick={() => {
                          handleDeleteRow(id);
                        }}
                      />
                    ),
                  },
                ]
          }
        />
      </Paper>
    </Box>
  );
}
