import React from "react";

export type SectionHeaderType =
  | "apiKey"
  | "healthEndpoint"
  | "useCaseEndpoint"
  | "schemaEndpoint";
export const SECTION_HEADERS = {
  apiKey: {
    title: "Try It Now",
    subTitle: "",
  },
  healthEndpoint: {
    title: "Health Endpoint",
    subTitle:
      "Verify API functionality by accessing the health-check endpoint.",
  },
  useCaseEndpoint: {
    title: "Use Case Endpoint",
    subTitle: "",
  },
  schemaEndpoint: {
    title: "Schema Endpoint",
    subTitle:
      "Knowing the structure of the data you're querying can aid in crafting effective queries and parsing results. The Schema Endpoint describes the shape, fields, and types of the data returned by your API.",
  },
};

export const Header: React.FC<{ headerType: SectionHeaderType }> = ({
  headerType,
}) => {
  const { title, subTitle } = SECTION_HEADERS[headerType];
  return (
    <>
      <div className={"title"}>{title}</div>
      <div className={"subTitle"}>{subTitle}</div>
    </>
  );
};
