import React from "react";
import Stack from "@mui/material/Stack";
import { Chip } from "@ssce/ui-shared-library";

export const TagCell: React.FC<{
  tags: { function: string; industry: string; serviceType: string };
}> = ({ tags }) => (
  <Stack spacing={1} alignItems="flex-start" direction="column">
    <Chip tag="function" width="none">
      {tags?.function}
    </Chip>
    <Chip tag="industry" width="none">
      {tags?.industry}
    </Chip>
    <Chip tag="service type" width="none">
      {tags?.serviceType}
    </Chip>
  </Stack>
);
