import React, { useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { SchemaContentType, EndpointData } from "../tryItNowHook";
import { SCHEMA_ENDPOINT } from "../editorContentData";
import { convertTypescriptToContent } from "../transpiler";
import { Button, Table } from "@ssce/ui-shared-library";
import { RequestExample, EditorTabContent } from "../rightSide";
import "./leftSide.css";
import { Header } from "./title";
import usePostEventCollection from "src/hooks/usePostEventCollection";

export const SchemaEndpoint: React.FC<{
  data?: EndpointData;
  apiId: string;
  handleTryNowClick: (schemaType: SchemaContentType) => void;
}> = ({ data, handleTryNowClick, apiId }) => {
  const [selectedSchemaType, setSelectedSchemaType] =
    useState<SchemaContentType>("JSON");

  const { postEvent } = usePostEventCollection("output-api-click");

  const handleTryItNow = (selectedSchemaType) => {
    const data = {
      apiName: "Schema Endpoint",
      apiId: apiId,
      endpoint: "/custom-api",
      endpointParameters: { selectedSchemaType },
    };

    postEvent(data);

    handleTryNowClick(selectedSchemaType);
  };

  const columns = [
    {
      title: "Parameter",
      render: () => <span className={"content"}>Output Type</span>,
    },
    {
      title: "Value",
      render: () => (
        <Select
          fullWidth
          onChange={(event) =>
            setSelectedSchemaType(event?.target.value as any)
          }
          value={selectedSchemaType}
        >
          <MenuItem value={"JSON"}>JSON</MenuItem>
          <MenuItem value={"TypeScript"}>TypeScript</MenuItem>
        </Select>
      ),
    },
  ];
  return (
    <>
      <div className={"schemaEndpointContainer"} data-testid={"schema"}>
        <Header headerType={"schemaEndpoint"} />
        <div className={"parameterContainer"}>
          <span className={"smallText"}>Query Parameters</span>
          <div className="outputTypeContainer">
            <div className="outputTypeLabel">
              <span className={"rotatedT"}>T</span>
              <div className={"boldText"}>Output Type</div>
            </div>
            <div>
              <div className={"grayText"}>String</div>
              <div className={"content"}>
                Default <span className={"smallGrayBox"}>JSON</span>
              </div>
              <div className="content">
                <span className={"smallGrayBox"}>JSON</span>
                <span className={"smallGrayBox"}>Typescript</span>
              </div>
            </div>
          </div>
        </div>
        <div className={"grayBox"}>
          <div className={"smallTitle"}>Test Schema Endpoint</div>
          <Table id={"testSchemaEndpoint"} columns={columns} data={[" "]} />
          <Button
            className={"tryNowButton"}
            onClick={() => handleTryItNow(selectedSchemaType)}
            id="tryitnow"
          >
            Try It Now
          </Button>
          {data?.isLoading ? (
            <div className={"loadingBanner"}>Loading...</div>
          ) : (
            <>
              {data?.status && (
                <div
                  className={
                    data?.status === 200 ? "successBanner" : "errorBanner"
                  }
                >
                  {data?.status ?? "500"} {data?.statusText ?? "Server Error"}
                </div>
              )}
              {data?.response && (
                <EditorTabContent
                  content={
                    data.type === "JSON"
                      ? data.response
                      : convertTypescriptToContent(data.response)
                  }
                  isJSON={data.type === "JSON"}
                />
              )}
            </>
          )}
        </div>
      </div>
      <div>
        <RequestExample
          requestType={"GET"}
          url={"/json/Authorize/ClientQuery/GetClientShor"}
          editorContent={SCHEMA_ENDPOINT(apiId)}
        />
      </div>
    </>
  );
};
