import { FC } from "react";
import { Card } from "@ssce/ui-shared-library";
import Typography from "@mui/material/Typography";
import DocArrow from "../../../../assets/DocArrow.png";
import Grid from "../../subcomponents/Grid/Grid";
import "./cardBanner.scss";

type CardList = {
  id: string;
  title: string;
  subTitle: string;
  descrition: string;
  onClick: () => void;
};

type Props = {
  cardLists: Array<CardList>;
};

export const CardBanner: FC<Props> = ({ cardLists }) => {
  return (
    <div className={"cardBannerContainer"}>
      <Grid>
        {cardLists.map(({ id, title, subTitle, descrition, onClick }) => {
          return (
            <Card id={id} onClick={onClick}>
              <div className={"cardContentContainer"}>
                <div className={"cardTextSection"}>
                  <div>
                    <Typography
                      gutterBottom
                      variant="h6"
                      lineHeight={1}
                      component="div"
                      className="title"
                    >
                      {title}
                      <div>
                        <img src={DocArrow} alt="arrow"></img>
                      </div>
                    </Typography>
                    <Typography
                      variant="h6"
                      component="div"
                      className="subTitle"
                    >
                      {subTitle}
                    </Typography>
                  </div>
                </div>
                <div>
                  <Typography lineHeight={1} className="descrition">
                    {descrition}
                  </Typography>
                </div>
              </div>
            </Card>
          );
        })}
      </Grid>
    </div>
  );
};
