import * as React from "react";
import Typography from "@mui/material/Typography";
import BarChartIcon from "@mui/icons-material/BarChart";
import ScienceIcon from "@mui/icons-material/Science";
import SensorsIcon from "@mui/icons-material/Sensors";
import PsychologyIcon from "@mui/icons-material/Psychology";
import CatalogBenefits from "../CatalogBenefits";
import Grid from "@mui/material/Grid";
import CatalogSubHeading from "../CatalogSubHeading";
import Global from "../../../../assets/Global.png";
import TuneIcon from "@mui/icons-material/Tune";
import GradingIcon from "@mui/icons-material/Grading";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import LanguageIcon from "@mui/icons-material/Language";

const RiskthinkingOverView: React.FC = () => {
  const regionList = (data) => {
    return data.map((item) => {
      return (
        <Grid item xs={6} md={6}>
          <li className="regionListli">{item}</li>
        </Grid>
      );
    });
  };

  const moreinformation = (data) => {
    return data.map((item) => {
      return (
        <Grid item xs={4} md={4}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="flex-start"
            gap={2}
          >
            <Typography
              variant="h6"
              fontSize="1.25rem"
              style={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              {item.title}
            </Typography>
            <Typography
              variant="body1"
              style={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              {item.description}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  };

  return (
    <>
      <div className="catalogSupersetContainer">
        <div className="catalogSuperset">
          <Typography
            variant="h4"
            fontSize="1.5rem"
            fontWeight={600}
            fontStyle={"italic"}
            component="div"
            style={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Powered by riskthinking.AI
          </Typography>
          <div>
            <div className="catalogSupersetOverviewTitle">
              <Typography
                variant="body1"
                component={"div"}
                sx={{ color: "#000", lineHeight: "1.5rem" }}
              >
                <p>
                  Institutional investors often struggle to source consistent,
                  company-level physical exposure data into their climate risk
                  assessment process. ​
                </p>
                <p>
                  With VELO Reports and Data powered by riskthinking.AI, access
                  comprehensive and consistent climate physical risk reports and
                  data for listed public companies and proprietary assets. VELO
                  enhances your due diligence process enabling a deeper
                  understanding of physical risk exposures from climate-related
                  events. ​
                </p>
                <p>
                  VELO is a must-have for roles overseeing risk, research,
                  compliance and portfolio management at pension funds, hedge
                  funds, insurance companies, asset management firms, banks and
                  mortgage lending institutions.​
                </p>
              </Typography>
            </div>
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <TuneIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              description:
                "Integrate comprehensive public companies’ physical risk insights into your investment due diligence process​",
            },
            {
              icon: (
                <PsychologyIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              description:
                "Leverage a consistent data aggregation and simulation-based methodology in your regulatory reporting streams ​",
            },
            {
              icon: (
                <GradingIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              description:
                "Review granular physical asset exposures of investee companies around the world​",
            },
            {
              icon: (
                <ScienceIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              description:
                "Integrate physical risk score indicators across climate scenario pathways, and identify asset stranding risks at various time horizons​",
            },
            {
              icon: (
                <CompareArrowsIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              description:
                "Compare the projected impacts of acute and chronic climate hazard types across peer-group companies. ​",
            },
            {
              icon: (
                <LanguageIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              description:
                "Access a detailed grid mapping of the Earth, aggregation of climate risk scenario outcomes and physical risk factor distributions across scenario pathways for acute and chronic hazard types ​",
            },
            {
              icon: (
                <SensorsIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              description:
                "Features large-scale stochastic data generation, machine learning-based identification of physical assets globally, with geo-location details​",
            },
            {
              icon: (
                <BarChartIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              description:
                "Physical risk factor distributions across scenario pathways for acute and chronic hazard types ​",
            },
          ]}
        />

        <div className="catalogSupersetContainer">
          <CatalogSubHeading title={"Region Availability"} />
          <div className="regionAvailabilityContainer">
            <div className="catalogSuperset">
              <CatalogSubHeading
                title={"Available Regions"}
                fontSize="1.25rem"
                color="#02273C"
              />
              <div style={{ display: "flex" }}>
                <Grid container spacing={26}>
                  <Grid item xs={8} md={8}>
                    <Grid container spacing={0}>
                      {regionList(["Global"])}
                    </Grid>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    <div style={{ marginRight: "0.938rem" }}>
                      <img
                        alt={"allRegion"}
                        width={400}
                        height={200}
                        src={Global}
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>

            <div className="catalogSupersetContainer">
              <CatalogSubHeading title={"More Information"} />
              <Grid container spacing={2}>
                {moreinformation([
                  {
                    title: "Support",
                    description:
                      "Customers are provided with a support email address to report any errors, inaccuracies or difficulties discovered in connection with this service. SS&C and Riskthinking.AI will classify all problems in good faith and target a response to inquiries between 1 - 5 business days depending on the urgency. This procedure is fully documented in the Customer Support and Service Level Agreement Policy. ",
                  },
                  {
                    title: "Security",
                    description:
                      "Riskthinking.AI is certified SOC2 Type 1 compliant with formal data loss prevention, monitoring, review and security protocols in place.",
                  },
                  {
                    title: "Compliance",
                    description:
                      "We maintain a high level of quality assurance that involves a rigorous process of validating data sources. We ensure that data is ingested, processed and utilized uniformly and traceably. All data is stored with license information. Every data pipeline is monitored and version controlled. Rigorous variable-level checks are applied to every dataset before they are ingested into our platform.",
                  },
                ])}
              </Grid>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiskthinkingOverView;
