import { FC, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Divider } from "@mui/material";
import Accordion from "../Accordion";
import { Projects } from "../../../../common/dataTypes";

import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import { fetchUserProjects } from "../../../../redux/userManagement/actions";

const mapStateToProps = (state: AppState) => ({
  assignedProjects: state.userManagement.assignedProjects,
  selectedUser: state.userManagement.selectedUser,
});

const mapDispatchToProps = {
  fetchUserProjects,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const UserProjectInformation: FC<Props> = ({
  assignedProjects,
  selectedUser,
  fetchUserProjects,
}) => {
  useEffect(
    () => fetchUserProjects(selectedUser.id),
    [fetchUserProjects, selectedUser.id],
  );

  const renderAssignedProjects = () => {
    return assignedProjects.map((item: Projects) => {
      return (
        <Accordion key={item.name} name={item.name}>
          <Typography
            style={{ color: "#0A85C7", fontSize: 12, margin: "0.5rem 1.25rem" }}
          >
            Description
          </Typography>
          <Typography
            style={{
              color: "#02273C",
              fontWeight: 400,
              fontSize: 14,
              marginLeft: 20,
              marginBottom: 16,
            }}
          >
            {item.description}
          </Typography>
          <Divider></Divider>
          <Typography
            style={{
              color: "#0979B5",
              fontWeight: 500,
              fontSize: 12,
              marginLeft: 20,
              marginTop: 16,
              marginBottom: 8,
            }}
          >
            Resources assigned to Project
          </Typography>
          <div style={{ marginLeft: 20, fontSize: 12 }}>
            {item.instances.map((i, r, arr) => (
              <Typography style={{ marginBottom: 8 }} key={i.instanceName}>
                X {i.instanceName} {arr.length - 1 !== r ? "," : ""}{" "}
              </Typography>
            ))}
          </div>
        </Accordion>
      );
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <h1
        style={{
          fontStyle: "normal",
          fontWeight: 600,
          fontSize: 20,
          lineHeight: 0,
          textAlign: "left",
          color: "#02273C",
        }}
      >
        Project Involvement
      </h1>
      <br></br>
      {renderAssignedProjects()}
      <br></br>
    </div>
  );
};

export default connector(UserProjectInformation);
