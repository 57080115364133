import {
  Heading,
  Subheading,
  P,
  ListItem,
  Bold,
  GrayHeading,
  Image,
  Center,
} from "../../Styles/Documentation.styles";
import { OnThisPageHelper } from "../../OnThisPageHelper";

import SS1 from "../../Images/C1.png";
import SS2 from "../../Images/C2.png";
import SS3 from "../../Images/C3.png";
import SS4 from "../../Images/C4.png";
import SS6 from "../../Images/C6.png";
import SS7 from "../../Images/C7.png";

const anchorTagsArr = [
  {
    subHeadingString: "Choose Your Data Source Type",
    id: "1",
  },
  {
    subHeadingString: "Connecting to Data Sources",
    id: "1",
    items: [
  {
    subHeadingString: "S3 Cloud Object Storage",
    id: "2",
  },
  {
    subHeadingString: "API",
    id: "3",
  },
  {
    subHeadingString: "SFTP",
    id: "4",
  },
  {
    subHeadingString: "noSQL Database",
    id: "5",
  },
  {
    subHeadingString: "SS&C Data sources",
    id: "6",
  },
  {
    subHeadingString: "GoGeneva",
    id: "7",
  },
  {
    subHeadingString: "SS&C BlueDoor",
    id: "9",
  }]
  },
];

export const ConnectingYourDataCopy = () => {
  return (
    <>
      <>
        <OnThisPageHelper anchorTagsArr={anchorTagsArr} />
      </>
      <Heading>
        Getting Started with SS&C Technology's Data Mesh: Connecting to Your
        Data
      </Heading>
      <i>Last Updated: Aug 2024</i>
      <P>
        Welcome to SS&C Technology's revolutionary Data Mesh offering, an
        integral part of the SS&C Everywhere platform. In this guide, we're
        diving deep into the very first step of the Data Mesh workflow:
        <Bold> Connecting to Your Data</Bold>
      </P>
      <Center>
        <Image src={SS1} sizeInRem={35} />
      </Center>
      <Subheading id="1">Choose Your Data Source Type</Subheading>
      <P>
        With the Data Mesh offering, you're in control. We support a variety of
        data source types to make your data connection seamless. Here are the
        types you can connect to:
      </P>
      <ul>
        <ListItem>S3 Cloud Object Storage</ListItem>
        <ListItem>API</ListItem>
        <ListItem>SFTP</ListItem>
        <ListItem>SQL Database</ListItem>
        <ListItem>NoSQL Database</ListItem>
        <ListItem>
          SS&C Data sources (specially tailored for our in-built data sources)
        </ListItem>
      </ul>
      <br />
      <Heading>Connecting to Data Sources </Heading>
      <Subheading id="2">S3 Cloud Object Storage</Subheading>
      <Center>
        <Image src={SS2} sizeInRem={35} />
      </Center>
      <P>
        The following is a step-by-step guide on how to use the SS&C Data Mesh
        to define a connection to a cloud object storage (COS) system.
      </P>
      <GrayHeading>
        Step 1: Navigate to the "New S3 Connection" Page
      </GrayHeading>
      <P>
        Ensure you're on the "Connections {">"} New S3 Connection" page within
        the SS&C Everywhere interface.
      </P>
      <GrayHeading>Step 2: Fill in the General S3 Setup Details</GrayHeading>
      <ListItem>
        <Bold>Protocol:</Bold> Select the desired protocol from the dropdown
        menu. This will typically be either "HTTP" or "HTTPS".
      </ListItem>
      <ListItem>
        <Bold>Port:</Bold> Enter the port number used for the connection.
        Standard ports for HTTP and HTTPS are 80 and 443, respectively. However,
        you should use the port provided by your cloud service provider or
        system administrator.
      </ListItem>
      <ListItem>
        <Bold>Server:</Bold> Input the server address or domain name of your
        cloud object storage. This will typically be a URL provided by your
        cloud service provider.
      </ListItem>
      <GrayHeading>Step 3: Enter Authentication Details</GrayHeading>
      <ListItem>
        <Bold>Access Key:</Bold> Enter the access key provided by your cloud
        service provider. This key is used to authenticate and authorize the
        connection to the cloud storage.
      </ListItem>
      <ListItem>
        <Bold>Secret Access Key:</Bold> Input the secret access key, which acts
        as a password alongside the access key. Ensure you keep this information
        confidential.
      </ListItem>
      <GrayHeading> Step 4: Additional Configuration (Optional)</GrayHeading>
      <P>
        <Bold>URL:</Bold> If your cloud service provider requires a specific
        endpoint URL or if you're connecting to a specific bucket or folder,
        input that URL here.
      </P>
      <GrayHeading>Step 5: Test the Connection</GrayHeading>
      <P>
        Click on the "Test Connection" button. This will attempt to establish a
        connection using the provided details. If successful, you'll receive a
        confirmation message. If there's an error, ensure all the entered
        details are correct and try again.
      </P>
      <GrayHeading>Step 6: Finalize the Connection</GrayHeading>
      <P>
        Once you've successfully tested the connection, click on the "Next"
        button to proceed with further configuration or the "Save" button to
        finalize the setup.
      </P>
      <Subheading id="3">API</Subheading>
      <Center>
        <Image src={SS3} sizeInRem={35} />
      </Center>
      <P>
        The following are step-by-step instructions on how to establish a new
        API connection using the SS&C Everywhere platform.
      </P>
      <GrayHeading>Step 1: Navigating to the API Connection Page:</GrayHeading>
      <P>
        Within the SS&C Everywhere platform, from the left-hand menu, click on
        Connect. Select Connections, then click on New API Connection.
      </P>
      <GrayHeading>Step 2: Setting Up Basic API Parameters:</GrayHeading>
      <ul>
        <ListItem>
          <Bold>Method:</Bold> Choose the HTTP method for your API request, such
          as GET, POST, PUT, DELETE, etc.
        </ListItem>
        <ListItem>
          <Bold>Endpoint URL:</Bold> Enter the complete URL for the API endpoint
          you're connecting to.
        </ListItem>
      </ul>
      <GrayHeading>
        Step 3: Configuring API Headers, Authentication, and Other Parameters:
      </GrayHeading>
      <P>Headers Tab:</P>
      <ul>
        <ListItem>Click on Headers. </ListItem>
        <ListItem>
          Input any necessary header keys and their corresponding values. For
          example, Content-Type, User-Agent, etc.{" "}
        </ListItem>
        <ListItem>
          Optionally, add a description for each header for clarity.{" "}
        </ListItem>
      </ul>
      <P>Auth Tab:</P>
      <ul>
        <ListItem>Click on Auth. </ListItem>
        <ListItem>
          Choose the type of authentication required for your API. (e.g., Basic
          Auth, Bearer Token, etc.)
        </ListItem>
        <ListItem>For Basic Auth: Enter your Username and Password.</ListItem>
      </ul>
      <P>Body Tab:</P>
      <ul>
        <ListItem>Click on Body. </ListItem>
        <ListItem>
          Input the body content of your API request, if required. This is
          commonly used for POST or PUT requests.
        </ListItem>
      </ul>
      <P>Params Tab:</P>
      <ul>
        <ListItem>Click on Params. </ListItem>
        <ListItem>
          Add any required query parameters and their values. For example,
          filters or specific data retrieval options.
        </ListItem>
        <ListItem>
          Optionally, include a description for each parameter.
        </ListItem>
      </ul>

      <P>Variables Tab:</P>
      <ul>
        <ListItem>Click on Variables. </ListItem>
        <ListItem>
          Set up dynamic variables to be used in your API request.
        </ListItem>
        <ListItem>
          Define each variable's Data Type and an Initial Value.
        </ListItem>
      </ul>

      <GrayHeading>Testing the API Connection:</GrayHeading>
      <P>
        Once you've set up the necessary parameters, click on the TEST
        CONNECTION button. This will attempt to establish a connection using the
        provided details. If successful, you'll receive a confirmation message.
        If there's an error, ensure all the entered details are correct and try
        again.
      </P>
      <GrayHeading>Finalize the Connection</GrayHeading>
      <P>
        Once you've successfully tested the connection, click on the "Next"
        button to proceed with further configuration or the "Save" button to
        finalize the setup.
      </P>

      <Subheading id="4">SFTP</Subheading>
      <Center>
        <Image src={SS4} sizeInRem={35} />
      </Center>
      <P>
        The following are step-by-step instructions on how to establish a new
        SFTP connection using the SS&C Everywhere platform.
      </P>
      <GrayHeading>Step 1: Navigating to the SFTP Connection Page:</GrayHeading>
      <ul>
        <ListItem>Open the SS&C Everywhere platform.</ListItem>
        <ListItem>From the left-hand menu, click on Connect.</ListItem>
        <ListItem>
          Select Connections, then click on New FTP Connection.
        </ListItem>
      </ul>
      <GrayHeading>Step 2: Setting Up SFTP Parameters:</GrayHeading>
      <ul>
        <ListItem>
          <Bold>SFTP (Secure File Transfer Protocol):</Bold> Ensure "SFTP" is
          selected from the dropdown to establish a secure connection..
        </ListItem>
        <ListItem>
          <Bold>Port:</Bold> Input the specific port number provided for your
          SFTP service. This is typically 22 for SFTP, but ensure you use the
          correct port designated for your server.
        </ListItem>
        <ListItem>
          <Bold>Host:</Bold> Enter the host address for your SFTP service, which
          can be an IP address or a domain name.
        </ListItem>
      </ul>
      <GrayHeading>Step 3: Choosing Authentication Mode:</GrayHeading>
      <P>
        You can authenticate using two methods: Normal (using a username and
        password) or using a Key File. Here's how to set up each method:
      </P>

      <P>Normal:</P>
      <ul>
        <ListItem>Select Normal from the dropdown menu</ListItem>
        <ListItem>
          <Bold>Username:</Bold> Input your SFTP password.{" "}
        </ListItem>
        <ListItem>
          <Bold>Password:</Bold> Input your SFTP account password. Ensure you
          keep this confidential.
        </ListItem>
      </ul>

      <P>Key File:</P>
      <ul>
        <ListItem>Select Key File from the dropdown menu</ListItem>
        <ListItem>
          <Bold>Key File:</Bold> Input your SFTP password.{" "}
        </ListItem>
        <ListItem>
          <Bold>Username:</Bold> Input your SFTP account password. Ensure you
          keep this confidential.
        </ListItem>
        <ListItem>
          (Optional) If your key file requires a passphrase, there will be an
          additional field to enter it.
        </ListItem>
      </ul>

      <GrayHeading>4. Testing the SFTP Connection:</GrayHeading>
      <ul>
        <ListItem>
          Once all necessary details are filled out, click on the TEST
          CONNECTION button.
        </ListItem>
        <ListItem>
          SS&C Everywhere will attempt to establish a connection to the SFTP
          server using the details provided.
        </ListItem>
        <ListItem>
          If the connection is successful, you'll receive a positive
          notification. Otherwise, an error message will inform you of any
          issues.
        </ListItem>
      </ul>

      <GrayHeading>5. Finalizing the Connection:</GrayHeading>
      <ul>
        <ListItem>
          After confirming that the test connection is successful, click the
          Next button to proceed with any additional settings or configuration.
        </ListItem>
        <ListItem>
          Follow subsequent prompts or additional fields as required.
        </ListItem>
        <ListItem>
          Click on a confirmation button, such as Finish, to complete the SFTP
          connection setup.
        </ListItem>
      </ul>

      <Subheading id="sql">SQL Database</Subheading>
      <P>
        Users can use the SQL database connectivity to directly connect to an
        existing structured database, such that live connections can be made in
        to client sources of data.
      </P>
      <P>
        To define a direct SQL database connection, a user should enter the
        following information:
      </P>

      <ul>
        <ListItem>
          Database Type: MySQL, PostgreSQL, Oracle, MSSQL, etc
        </ListItem>
        <ListItem>Host and Port: Database server details.</ListItem>
        <ListItem>Database Name: The name of your specific database.</ListItem>
        <ListItem>Username and Password: Authentication credentials.</ListItem>
      </ul>
      <Subheading id="5">NoSQL Database</Subheading>
      <P>
        Users can use the NoSQL database connectivity to directly connect to an
        existing unstructured database, such that live connections can be made
        into client sources of data.{" "}
      </P>
      <P>
        To define a direct NoSQL database connection, a user should enter the
        following information:
      </P>
      <ul>
        <ListItem>
          <Bold>Database Type:</Bold> MongoDB, Cassandra, CouchDB, etc
        </ListItem>

        <ListItem>
          <Bold>Connection String/URL:</Bold> Comprehensive URL containing
          server details and authentication.
        </ListItem>
      </ul>

      <Subheading id="6">SS&C Data sources</Subheading>
      <P>
        Our SS&C Data sources have been tailor-made for streamlined connection.
        With fewer input requirements, it's easier and quicker to connect.
      </P>
      <ul>
        <ListItem>
          <Bold>Data Source Name:</Bold> The specific name of the SS&C data
          source you want to access
        </ListItem>
        <ListItem>
          <Bold>Authentication Token (if needed): </Bold>Some sources may
          require this for secure access.
        </ListItem>
      </ul>
      <i>
        Note: New data sources will be added over time so ensure you check back
        to see if you can benefit from new additions!
      </i>

      <Center>
        <Image src={SS6} sizeInRem={35} />
      </Center>

      <Subheading id="7">6.1 GoGeneva</Subheading>
      <Center>
        <Image src={SS7} sizeInRem={35} />
      </Center>
      <P>
        This guide provides a step-by-step explanation on how to establish a
        data connection to the SS&C GoGeneva accounting service using the SS&C
        Data Mesh platform. If you do not have direct access to the SS&C
        GoGeneva platform, please reach out to your SS&C team to have this
        configured on your behalf.
      </P>
      <Subheading id="8">Navigating to the Connection Page:</Subheading>
      <P>
        From the left-hand menu, click on Connect to expand its options. Select
        Connections, then click on New SS&C Connection and navigate to the
        “GoGeneva” option.
      </P>

      <GrayHeading>Filling Out the GoGeneva Parameters:</GrayHeading>
      <ul>
        <ListItem>
          <Bold>Port:</Bold> Enter the specific port number provided for your
          GoGeneva account. This is usually provided by your system
          administrator or the SS&C GoGeneva support team.
        </ListItem>
        <ListItem>
          <Bold>Host:</Bold> Enter the host address for your GoGeneva account.
          This will typically be a URL or IP address provided to you.
        </ListItem>
        <ListItem>
          <Bold>Username:</Bold> Enter your GoGeneva account username. Ensure
          you have the necessary permissions to establish connections.
        </ListItem>
        <ListItem>
          <Bold>Password:</Bold> Enter your GoGeneva account password. Be sure
          to keep this confidential and avoid sharing it.
        </ListItem>
      </ul>

      <GrayHeading>Testing the Connection:</GrayHeading>
      <ul>
        <ListItem>
          Once you've filled out all the required fields, click on the TEST
          CONNECTION button
        </ListItem>
        <ListItem>
          The system will attempt to establish a connection using the details
          you've entered.
        </ListItem>
        <ListItem>
          If the connection is successful, you'll receive a notification
          confirming the same. If not, you'll be notified of any errors or
          issues.
        </ListItem>
      </ul>

      <GrayHeading>Finalizing the Connection:</GrayHeading>
      <P>
        After successfully testing the connection, click the Next button to
        proceed to the final steps. Follow any additional prompts or fill in any
        other required information. Click on Finish or a similar confirmation
        button to finalize the connection.
      </P>

      <GrayHeading>Troubleshooting:</GrayHeading>
      <ul>
        <ListItem>Ensure all details entered are correct.</ListItem>
        <ListItem>
          If you face issues, recheck the Port and Host details for accuracy.
        </ListItem>
        <ListItem>
          Ensure your account has the necessary permissions to connect.
        </ListItem>
        <ListItem>
          If problems persist, contact your system administrator or the SS&C
          GoGeneva support team
        </ListItem>
      </ul>

      <Subheading id={"9"}>6.2 SS&C BlueDoor</Subheading>
      <P>
        This guide provides a step-by-step explanation on how to establish a
        data connection to the SS&C BlueDoor service using the SS&C Data Mesh
        platform. If you do not have direct access to the SS&C BlueDoor
        platform, please reach out to your SS&C team to have this configured on
        your behalf.
      </P>

      <Subheading>Navigating to the Connection Page</Subheading>
      <P>
        From the left-hand menu, click on Connect to expand its options. Select
        Connections, then click on New SS&C Connection, and navigate to the
        “BlueDoor” option.
      </P>
      <Subheading>Filling Out the BlueDoor Parameters</Subheading>

      <GrayHeading>Troubleshooting:</GrayHeading>
      <ul>
        <ListItem>
          <Bold>Protocol</Bold>Select the communication protocol for your
          BlueDoor connection (e.g., HTTPS). The appropriate protocol is usually
          provided by the BlueDoor support team or your system administrator
        </ListItem>

        <ListItem>
          <Bold>Port</Bold> Enter the specific port number provided for your
          BlueDoor service. If you're unsure, refer to the BlueDoor
          documentation or ask your system administrator
        </ListItem>

        <ListItem>
          <Bold>Server</Bold> Enter the server address for your BlueDoor
          service. This typically resembles a URL or IP address.
        </ListItem>

        <ListItem>
          <Bold>Access Key</Bold> Input the unique access key provided to you
          for the BlueDoor connection. This key serves as a form of
          authentication to access the BlueDoor system.
        </ListItem>

        <ListItem>
          <Bold>Secret Access Key</Bold> Enter the secret access key associated
          with your BlueDoor account. Keep this key confidential to ensure the
          security of your data.
        </ListItem>

        <ListItem>
          <Bold>URL</Bold> Review the generated URL which is a combination of
          the Protocol, Server, and Port details you've entered. This URL is the
          endpoint you'll be connecting to.
        </ListItem>
      </ul>

      <Subheading>Testing the Connection:</Subheading>
      <ul>
        <ListItem>
          After populating all the required fields, click on the TEST CONNECTION
          button.
        </ListItem>
        <ListItem>
          The platform will attempt to establish a connection with the BlueDoor
          service using the details you've provided
        </ListItem>
        <ListItem>
          A successful test will yield a positive notification. If there's an
          issue, an error message will help pinpoint the problem
        </ListItem>
      </ul>

      <Subheading>Finalizing the Connection:</Subheading>
      <ul>
        <ListItem>
          Once you've verified the connection is successful, click on the Next
          button to move to any remaining setup steps.
        </ListItem>
        <ListItem>Follow any subsequent prompts or input requests</ListItem>
        <ListItem>
          When complete, click on a confirmation button like Finish to solidify
          the connection
        </ListItem>
      </ul>

      <Subheading>Troubleshooting:</Subheading>
      <ul>
        <ListItem>
          Double-check that all the entered details are correct.
        </ListItem>
        <ListItem>
          Confirm the Protocol, Port, and Server details are accurate.
        </ListItem>
        <ListItem>
          Ensure you have the appropriate permissions to connect
        </ListItem>
        <ListItem>
          If problems persist, contact your system administrator or the SS&C
          BlueDoor support team.
        </ListItem>
      </ul>

      <GrayHeading>Wrapping Up</GrayHeading>

      <P>
        Setting up a connection to your preferred data source is just the
        beginning. Once connected, you'll be able to harness the full power of
        Data Mesh by scheduling jobs to onboard data sources, querying across
        databases using Starburst, and publishing those queries as APIs for
        business users.
      </P>

      <P>
        Continue on for the next guide on "Querying Your Data with Starburst"!
        With SS&C's Data Mesh, your data is at your fingertips.
      </P>

      <i>
        If you have any queries or require support, reach out to our dedicated
        team through the SS&C Everywhere platform
      </i>
    </>
  );
};
