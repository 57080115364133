import { FC } from "react";
import { SecondActiveStep } from "../publishApi.styles";
import { DetailsSection } from "./detailsSection";
import { ErrorSection } from "./errorSection";
import { ProjectSection } from "./projectSection";

export type PublishApiKeys =
  | "description"
  | "functionTag"
  | "industryTag"
  | "serviceTypeTag"
  | "logoName"
  | "serviceName"
  | "currentSelection";

type Props = {
  description: string;
  functionTag: string;
  industryTag: string;
  serviceTypeTag: string;
  logoName: string;
  serviceName: string;
  imageList: ImageList;
  publishError: string;
  hasError: boolean;
  uploadImage: null | string;
  currentSelection: Selection;
};

type ImageList = {
  ssncLogo: string;
  dataMeshLogo: string;
};

type Selection = {
  name: string;
  description: string;
};

const PublishApiReview: FC<Props> = ({
  description,
  functionTag,
  industryTag,
  serviceTypeTag,
  logoName,
  uploadImage,
  imageList,
  serviceName,
  publishError,
  currentSelection,
}) => {
  return (
    <>
      <SecondActiveStep>
        <DetailsSection
          title="Preview Entered Details"
          description={description}
          logoName={logoName}
          uploadImage={uploadImage}
          imageList={imageList}
          serviceName={serviceName}
          functionTag={functionTag}
          industryTag={industryTag}
          serviceTypeTag={serviceTypeTag}
        />
        <ProjectSection
          projectName={currentSelection.name}
          projectDescription={currentSelection.description}
        />
        <ErrorSection publishError={publishError} />
      </SecondActiveStep>
    </>
  );
};

export default PublishApiReview;
