import { useState, useEffect, useRef } from "react";
import { WorkflowViewerContent } from "./WorkflowViewerContent";
import { WorkflowHeader } from "./WorkflowHeader";
import { ExecutionLog } from "./executionLog";
import {
  WorkflowContainer,
  WorkflowViewerContainer,
} from "./WorkflowViewerContent/workflowViewerContent.styles";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { MidSection } from "./midSection";

interface WorkflowViewerProps {
  workflowId: string;
}

export const DummyWorkflowViewer = ({ workflowId }: WorkflowViewerProps) => {
  const [_, setRerender] = useState(false);
  const ref: any = useRef(null);
  const scrollToTopRef: any = useRef(null);

  useEffect(() => {
    // ref.current?.scrollIntoView(true);
    // This is used to fix a weird bug where the ref is not set correctly on initial render
    setRerender((prevState) => !prevState);
  }, []);

  const [tabValue, setTabValue] = useState("workflowView");
  return (
    <WorkflowContainer ref={ref}>
      <>
        <WorkflowHeader
          isAlgoRiskReport={workflowId === ("algo-risk" as any)}
          workflowLastRun={""}
          handleSelectedRun={() => {}}
          runName={"New Workflow"}
        />
        <span ref={scrollToTopRef}></span>
        <MidSection />
        <WorkflowViewerContainer>
          <TabContext value={tabValue}>
            <TabList onChange={(_, value) => setTabValue(value)}>
              <Tab label={"Workflow View"} value={"workflowView"}></Tab>
              <Tab label={"Execution Log"} value={"executionLog"}></Tab>
            </TabList>
            <TabPanel sx={{ padding: "0" }} value={"workflowView"}>
              <WorkflowViewerContent
                isAlgoRiskReport={workflowId === ("algo-risk" as any)}
                widthOfPanel={ref.current?.offsetWidth}
              />
            </TabPanel>
            <TabPanel sx={{ padding: "0" }} value={"executionLog"}>
              <ExecutionLog />
            </TabPanel>
          </TabContext>
        </WorkflowViewerContainer>
      </>
    </WorkflowContainer>
  );
};
