import * as React from "react";
import { useState } from "react";
import { Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OverView from "./OverView";
import AlgoRiskImage from "../../../../assets/AlgoRisk.png";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

const AlgoPerformanceReport: React.FC = () => {
  const [value, setValue] = useState("1");

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={AlgoRiskImage}
        title={"SS&C Risk & Performance Service"}
        contents={
          "Take advantage of a bundled service of financial risk analytics and performance measurement reports delivered via accurate and timely reports or an interactive web-based dashboard. "
        }
        tags={[
          {
            label: "Risk Management",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Analytics Service",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() => {
            //navigate("/public-catalog/ss&c-algorithmics-performance-reports/provision")
          }}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Technical Specifications" value="3" />
            <Tab label="Documentation" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "2.5rem", padding: 0 }}>
          <OverView />
        </TabPanel>

        <TabPanel value="2" sx={{ marginTop: "2.5rem", padding: 0 }}></TabPanel>
        <TabPanel value="3" sx={{ marginTop: "2.5rem", padding: 0 }}></TabPanel>
        <TabPanel value="4"></TabPanel>
      </TabContext>
      <div className="flex-row justifyContent-center gap1rem">
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() => {
            //navigate("/public-catalog/ss&c-algorithmics-performance-reports/provision")
          }}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </div>
    </>
  );
};
export default AlgoPerformanceReport;
