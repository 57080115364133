import * as React from "react";
import { useState, useEffect, useContext } from "react";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import axios from "axios";
import { baseUrl } from "../../../../../constants";
import PCOverview from "../../../PrivateCatalog/Cards/PrivateCatalogOverview/PCOverview";
import "./PrivateAPI.css";
import { CurrentViewContext } from "../../../ComponentWithContext";
import PrivateApiSettingsTab from "./PrivateApiSettingsTab";
import { LoadingAnimation } from "../../../subcomponents/LoadingAnimation";
import { TryItNow } from "../../../Documentation/TryItNow";

const PrivateApiServices: React.FC = () => {
  const [value, setValue] = useState<string>("1");
  const [items, setItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  const { viewData } = useContext(CurrentViewContext);

  useEffect(() => {
    const getServiceData = () => {
      setLoading(true);
      axios
        .get(
          `${baseUrl}/api/mktplc-catalog-api/v0/private/services/applications/data_mesh/catalogs/${viewData.catalogId}`,
        )
        .then((res) => {
          setItems(res.data);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.log("error", error);
        });
    };

    getServiceData();
  }, [viewData.catalogId]);

  //handleChange tabs
  const handleChange = (_, newValue: string) => {
    setValue(newValue);
  };

  const data = [items];
  return (
    <>
      {loading ? <LoadingAnimation /> : <></>}
      <div style={{ display: !loading ? "block" : "none" }}>
        {data.map(
          (item: {
            catalogId: any;
            serviceID: string;
            serviceName: any;
            applicationName: string;
            catalogType: string;
            majorVersion: number;
            minorVersion: number;
            shortDescription: any;
            longDescription: any;
            productId: string;
            logo: any;
            businessUnit: string;
            labels: { banner: string };
            tags: { function: string; industry: string; serviceType: string };
            region: {
              us: { regionDetails: string; localCompliance: string[] };
            };
            hasAccess: boolean;
            hasAddedTo: boolean;
          }) => {
            return (
              <>
                <Card className="myservices-private-api">
                  <CardContent className="myservices-private-api-header">
                    <div className="myservices-private-api-header-div">
                      <img
                        className="myservices-private-api-logo"
                        src={item.logo}
                        alt="logo"
                      ></img>
                      <div className="myservices-private-api-container">
                        <div>
                          <Stack
                            spacing={1}
                            direction="row"
                            color="primary"
                            style={{ marginTop: "0.313rem" }}
                          >
                            <Typography
                              variant="h1"
                              fontSize="2.125rem"
                              fontWeight={600}
                              component="div"
                              align="left"
                              lineHeight={1}
                              color="#0468B1"
                            >
                              {item.serviceName}
                            </Typography>
                            <Chip
                              label={item.tags ? item.tags.function : ""}
                              className="myservices-private-api-function"
                            />
                            <Chip
                              label={item.tags ? item.tags.industry : ""}
                              className="myservices-private-api-industry"
                            />
                            <Chip
                              label={item.tags ? item.tags.serviceType : ""}
                              className="myservices-private-api-service-type"
                            />
                          </Stack>
                        </div>

                        <Typography
                          variant="body1"
                          fontSize="1rem"
                          fontWeight={700}
                          component="div"
                          align="left"
                          color="#000000"
                        >
                          This API is private to your organization
                        </Typography>
                        <Typography
                          variant="body1"
                          fontSize="1rem"
                          fontWeight={400}
                          component="div"
                          align="left"
                          lineHeight={1.5}
                          color="#000000"
                        >
                          {item.longDescription}
                        </Typography>
                      </div>
                    </div>
                  </CardContent>
                </Card>
                <Box className="myservices-private-api-content">
                  <TabContext value={value}>
                    <Box>
                      <TabList
                        id={"tabList"}
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="Overview" value="1" />
                        <Tab label="Documentation" value="2" />
                        <Tab label="Settings" value="3" />
                      </TabList>
                    </Box>
                    <TabPanel
                      value="1"
                      className="myservices-private-api-tab-panel"
                    >
                      <PCOverview data={items} />
                    </TabPanel>
                    <TabPanel
                      value="2"
                      className="myservices-private-api-tab-panel"
                    >
                      <TryItNow
                        catalogId={item.catalogId}
                        productId={item.productId}
                        productName={item.serviceName}
                      />
                    </TabPanel>
                    <TabPanel
                      value="3"
                      className="myservices-private-api-tab-panel"
                    >
                      <PrivateApiSettingsTab />
                    </TabPanel>
                  </TabContext>
                </Box>
              </>
            );
          },
        )}
      </div>
    </>
  );
};

export default PrivateApiServices;
