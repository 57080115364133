import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";
import styled from "styled-components";
import ConfirmModal from "../../main/Settings/ConfirmModal";
import { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteInstance } from "../../../redux/myServices/actions";
import { AppState } from "../../../redux/store";
import Alert from "@mui/material/Alert";
import ErrorIcon from "@mui/icons-material/Error";
import { CurrentViewContext } from "../ComponentWithContext";
import { LoadingAnimation } from "../subcomponents/LoadingAnimation";
import { useNavigate } from "react-router-dom";

const DeleteButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const ConfirmText = styled.span`
  font-family: Roboto Bold;
`;

const AlertMessageWrapper = styled(Alert)`
  background-color: #fac1c1 !important;
  font-size: 1rem !important;
  color: #712525 !important;
`;

const ContentWrapper = styled.div`
  display: flex;
  flexdirection: column;
  gap: 4rem;
`;

const MyServiceInstanceManagement = () => {
  const [open, setIsopen] = useState<boolean>(false);
  const { viewData } = useContext(CurrentViewContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(
    (state: AppState) => state.myservices.isDeleteLoading,
  );

  const DeleteInstaceApi = () => {
    setIsopen(false);
    dispatch(
      deleteInstance(viewData.serviceName, viewData.instanceId, () =>
        navigate("/my-services"),
      ),
    );
  };

  if (isLoading) return <LoadingAnimation />;
  return (
    <ContentWrapper>
      <div className="myservices-settings">
        <ConfirmModal
          open={open}
          title={"Delete Instance"}
          onRequestCancel={() => setIsopen(false)}
          onRequestConfirm={() => DeleteInstaceApi()}
        >
          <AlertMessageWrapper
            severity="error"
            iconMapping={{
              error: <ErrorIcon sx={{ color: "#f03737" }} />,
            }}
          >
            This action is permanent and cannot be undone. Deleting this
            instance will remove it from My Services.
          </AlertMessageWrapper>

          <Typography
            style={{
              margin: 3,
              fontSize: 20,
              fontWeight: 500,
              lineHeight: "4rem",
            }}
          >
            <ConfirmText>Please Confirm</ConfirmText> if you want to proceed.
          </Typography>
        </ConfirmModal>
        <div className="myservices-settings-content">
          <Typography className="myservices-setting-titles">
            Instance Management
          </Typography>
          <DeleteButtonWrapper>
            <Button
              onClick={() => setIsopen(true)}
              id={"deleteInstance"}
              status="alert"
            >
              Delete Instance
            </Button>
          </DeleteButtonWrapper>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default MyServiceInstanceManagement;
