import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import { Button } from "@ssce/ui-shared-library";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import AccessRequest from "../../../../../assets/AccessRequest.png";
import Alert from "@mui/material/Alert";
import "./PCAccessRequest.css";

const PCAccessRequestSuccess: React.FC<{
  openRequestAccessSuccess: boolean;
  setOpenRequestAccessSuccess: (value: boolean) => void;
  handleBreadCrumbClick: any;
}> = ({
  openRequestAccessSuccess,
  setOpenRequestAccessSuccess,
  handleBreadCrumbClick,
}) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenRequestAccessSuccess(false);
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "0.125rem solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={openRequestAccessSuccess}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-container">
          <div className="modal-content">
            <div className="top-line">
              <p className="access-request-header">Access Request</p>
              <img
                alt={"accessRequest"}
                className="access-request-img"
                src={AccessRequest}
              ></img>
            </div>
            <Alert severity="success" className="pc-request-success">
              <Typography className="approval-success-header">
                Success
              </Typography>
              <Typography className="add-myservices-text">
                <b>This API has been added to My Service.</b> You can now find
                on the My Services page under Private Catalog.
              </Typography>
            </Alert>
          </div>
          <Box className="ar-button-container">
            <Stack
              spacing={2}
              direction="row"
              style={{
                width: "20.625rem",
                marginLeft: "auto",
                paddingTop: "0.625rem",
              }}
            >
              <Button
                id={"close"}
                className="get-support-button"
                variant="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
              <Button
                className="access-request-close-button"
                variant="default"
                id={"continueToMyServices"}
                onClick={() => {
                  handleBreadCrumbClick("", "my-services");
                  navigate("/my-services");
                }}
              >
                Continue to My Services
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default PCAccessRequestSuccess;
