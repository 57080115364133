import * as React from "react";
import { useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Table, Button } from "@ssce/ui-shared-library";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { CompanyListData } from "../ProvisionService/provisionDataMeshHooks";
import CatalogSubHeading from "../CatalogSubHeading";
import { Checkbox, Switch } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  maxHeight: "90vh",
  minHeight: "60vh",
};

const tableContainerStyle = {
  flex: "1 1 auto",
  overflowY: "auto",
  paddingTop: "1rem",
};

const noNewCompanies = (list1: number[], list2: number[]): boolean => {
  const set1 = new Set(list1);
  const set2 = new Set(list2);
  return set1.size === set2.size && [...set1].every((id) => set2.has(id));
};

interface CompanyModalProps {
  open: boolean;
  handleClose: () => void;
  companyList: CompanyListData[];
  handleAddCompanies: (companies: CompanyListData[]) => void;
  addedCompanies: number[];
}

const CompanyModal: React.FC<CompanyModalProps> = ({
  open,
  handleClose,
  companyList,
  handleAddCompanies,
  addedCompanies,
}) => {
  const [selectedRows, setSelectedRows] = React.useState<number[]>(
    addedCompanies.map((company) => company),
  );
  const [displayData, setDisplayData] =
    React.useState<CompanyListData[]>(companyList);
  const [hideAddedCompanies, setHideAddedCompanies] =
    React.useState<boolean>(false);

  useEffect(() => {
    setDisplayData(
      hideAddedCompanies
        ? companyList.filter((item) => !addedCompanies.includes(item.id))
        : companyList,
    );
  }, [open]);

  const handleSelectCompany = (rowId) => {
    if (!selectedRows.includes(rowId)) {
      setSelectedRows([...selectedRows, rowId]);
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    }
  };

  const handleAddSelectedCompanies = () => {
    handleAddCompanies(companyList.filter((c) => selectedRows.includes(c.id)));
    setSelectedRows([]);
  };
  const handleHideAddedCompanies = () => {
    setDisplayData(
      hideAddedCompanies
        ? companyList
        : companyList.filter((item) => !addedCompanies.includes(item.id)),
    );
    setHideAddedCompanies(!hideAddedCompanies);
  };

  const getUniqueValues = (
    data: CompanyListData[],
    key: keyof CompanyListData,
  ) => {
    return Array.from(new Set(data.map((item) => item[key]))).map((value) => ({
      label: String(value),
      value: String(value),
    }));
  };

  const sectorFilters = getUniqueValues(companyList, "sector");
  const headquartersFilters = getUniqueValues(companyList, "headquarters");

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ overflow: "auto", height: "auto" }}
    >
      <Box sx={style}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <CatalogSubHeading
          title={"Add New Companies"}
          style={{ padding: "0.625rem 0" }}
        />
        <Box sx={tableContainerStyle}>
          {addedCompanies.length > 0 && (
            <div className="toggleContainer">
              <Switch
                checked={hideAddedCompanies}
                onChange={() => handleHideAddedCompanies()}
              />
              <span>Hide Added Companies</span>
              <Tooltip
                title="Hide all companies that have already been added to your plan"
                arrow
                placement="top-start"
                componentsProps={{
                  tooltip: {
                    sx: {
                      fontSize: "15px",
                      fontFamily: "Roboto",
                      fontWeight: 500,
                      color: "#02273C",
                      lineHeight: "16px",
                      maxWidth: 500,
                      borderRadius: "6px",
                      bgcolor: "#DFE9EF",
                      "& .MuiTooltip-arrow": {
                        color: "#DFE9EF",
                      },
                    },
                  },
                }}
              >
                <IconButton>
                  <InfoIcon color="primary" />
                </IconButton>
              </Tooltip>
            </div>
          )}
          <Table
            title={"Company List"}
            data={displayData}
            id={"Company Table"}
            showSearchFilter
            showPagination
            columns={[
              {
                title: (
                  <Checkbox
                    sx={{
                      color: "#f6fdff !important",
                    }}
                    checked={selectedRows.length === displayData.length}
                    onChange={() => {
                      if (selectedRows.length === displayData.length) {
                        setSelectedRows([]);
                      } else setSelectedRows(displayData.map((row) => row.id));
                    }}
                  />
                ),
                render: (_, row: CompanyListData) => (
                  <Checkbox
                    sx={{
                      color: "#0a85c7",
                    }}
                    checked={
                      addedCompanies.includes(row.id)
                        ? true
                        : selectedRows.includes(row.id)
                    }
                    onChange={() => handleSelectCompany(row.id)}
                    disabled={addedCompanies.includes(row.id)}
                  />
                ),
              },
              {
                title: "Company Name",
                width: "30%",
                dataIndex: "name",
                sorter: true,
                render: (name: string, row: CompanyListData) => (
                  <>
                    {addedCompanies.includes(row.id) ? (
                      <Tooltip
                        title="This company is already added to the plan. Use the toggle above the table to hide companies that have already been added"
                        arrow
                        placement="top-start"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              fontSize: "15px",
                              fontWeight: 500,
                              color: "var(--neutralBlack)",
                              lineHeight: "16px",
                              maxWidth: 500,
                              borderRadius: "6px",
                              bgcolor: "var(--neutral200)",
                              "& .MuiTooltip-arrow": {
                                color: "var(--neutral200)",
                              },
                            },
                          },
                        }}
                      >
                        <div className="addedCompany">{name}</div>
                      </Tooltip>
                    ) : (
                      name
                    )}
                  </>
                ),
              },
              {
                title: "Sector",
                dataIndex: "sector",
                sorter: true,
                filters: sectorFilters,
                showFilterOnLeft: true,
                onFilter: (value, record) => record.sector === value,
              },
              {
                title: "Headquarters",
                dataIndex: "headquarters",
                sorter: true,
                showFilterOnLeft: true,
                filters: headquartersFilters,
                onFilter: (value, record) => record.headquarters === value,
              },
            ]}
          />
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 2 }}
        >
          <Button id={"cancel"} variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            id={"addCompanies"}
            variant="default"
            onClick={handleAddSelectedCompanies}
            isDisabled={noNewCompanies(selectedRows, addedCompanies)}
          >
            Add Companies
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default CompanyModal;
