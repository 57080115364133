import { MarkerType } from "reactflow";
import { Database, DoubleCheck, Crop, HourGlass } from "./icons";

export const getAlgoRiskNodes = (offsetWidth) => [
  {
    id: "getGoPricingData",
    type: "node",
    position: { x: offsetWidth - 300, y: 20 },
    data: {
      Icon: Database,
      title: "Get GoPricing Data",
      subTitle: "Data Job",
    },
  },
  {
    id: "getGoGenevaData",
    type: "node",
    position: { x: offsetWidth + 100, y: 20 },
    data: {
      Icon: Database,
      title: "Get GoGeneva Data",
      subTitle: "Data Job",
    },
  },
  {
    id: "getGoCSMData",
    type: "node",
    position: { x: offsetWidth + 100, y: 200 },
    data: {
      Icon: Database,
      title: "Get GoCSM Data",
      subTitle: "Data Job",
    },
  },
  {
    id: "validatingPreviousActions",
    type: "node",
    position: { x: offsetWidth - 100, y: 400 },
    data: {
      Icon: DoubleCheck,
      title: "Validating Previous Actions",
      subTitle: "Dependency Check",
    },
  },
  {
    id: "createMDAS",
    type: "node",
    position: { x: offsetWidth - 100, y: 600 },
    data: {
      Icon: Crop,
      title: "Create MDAS Input Format",
      subTitle: "Transform",
    },
  },
  {
    id: "putMDASInputFiles",
    type: "node",
    position: { x: offsetWidth - 100, y: 800 },
    data: {
      Icon: Database,
      title: "Put MDAS Input files on MDAS SFTP Site",
      subTitle: "Data Job",
    },
  },
  {
    id: "waitForMDASContent",
    type: "node",
    position: { x: offsetWidth - 100, y: 1000 },
    data: {
      Icon: HourGlass,
      title: "Wait for MDAS Content on Data Mesh COS",
      subTitle: "Dependency Wait",
    },
  },
  {
    id: "putMDASReports",
    type: "node",
    position: { x: offsetWidth - 100, y: 1200 },
    data: {
      Icon: Database,
      title: "Put MDAS Reports on Data Mesh",
      subTitle: "Generage Reports",
    },
  },
];
export const getAlgoRiskEdges = () => [
  {
    id: "getGoPricingData-validatingPreviousActions",
    source: "getGoPricingData",
    target: "validatingPreviousActions",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "getGoGenevaData-getGoCSMData",
    source: "getGoGenevaData",
    target: "getGoCSMData",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "getGoCSMData-validatingPreviousActions",
    source: "getGoCSMData",
    target: "validatingPreviousActions",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "validatingPreviousActions-createMDAS",
    source: "validatingPreviousActions",
    target: "createMDAS",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "createMDAS-putMDASInputFiles",
    source: "createMDAS",
    target: "putMDASInputFiles",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "putMDASInputFiles-waitForMDASContent",
    source: "putMDASInputFiles",
    target: "waitForMDASContent",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "waitForMDASContent-putMDASReports",
    source: "waitForMDASContent",
    target: "putMDASReports",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
];
