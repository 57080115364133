import { FC } from "react";
import GridViewSharpIcon from "@mui/icons-material/GridViewSharp";
import ViewListSharpIcon from "@mui/icons-material/ViewListSharp";
import styled from "styled-components";

export type ListTogglerValue = "list" | "table";

type Props = {
  id: string;
  value: any;
  onChange: (value: ListTogglerValue) => void;
};

const TableListTogglerWrapper = styled.div`
  display: flex;
`;

const ToggleButton = styled.button<{ active: boolean; isMirror?: boolean }>`
  border: ${(props) =>
    props.active
      ? "0.125rem solid var(--primary)"
      : "0.125rem solid var(--neutral600)"};
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-left: 0.063rem solid var(--neutral600);
  transform: ${(props) => (!props.isMirror ? "rotateY(180deg)" : "none")};
  display: flex;
  padding: 0.5rem 0.75rem;
  background: ${(props) =>
    props.active ? "var(--blue100)" : "var(--neutral100)"};
  cursor: pointer;

  svg {
    color: ${(props) =>
      props.active ? "var(--primary)" : "var(--neutral600)"};
    font-size: 1.75rem;
  }
`;

export const TableListToggler: FC<Props> = ({ id, value, onChange }) => {
  return (
    <TableListTogglerWrapper id={id}>
      <ToggleButton
        data-testid={"test-id-btn-left"}
        onClick={() => onChange("list")}
        value="list"
        active={value === "list"}
      >
        <GridViewSharpIcon />
      </ToggleButton>
      <ToggleButton
        data-testid={"test-id-btn-right"}
        onClick={() => onChange("table")}
        isMirror={true}
        value="table"
        active={value === "table"}
      >
        <ViewListSharpIcon />
      </ToggleButton>
    </TableListTogglerWrapper>
  );
};
