import {
  ActiveStepTwoDiv,
  ApiDescription,
  ApiTitle,
  ChipOne,
  ChipThree,
  ChipTwo,
  Description,
  DetailsContainer,
  DetailsInnerContainer,
  DetailsLogoContainer,
  PublishContainer,
  SecondImage,
  ServiceInnerDiv,
  ServiceName,
  ServiceOuterDiv,
  Title,
} from "../publishApi.styles";

export const DetailsSection = ({
  logoName,
  uploadImage,
  imageList,
  serviceName,
  functionTag,
  serviceTypeTag,
  industryTag,
  title,
  description,
}) => {
  return (
    <PublishContainer>
      <DetailsContainer>
        <DetailsInnerContainer>
          <Title>{title}</Title>
          <Description>
            Kindly review the details below, generated from your input on the
            previous screen, before confirming and publishing your API to the
            Private API Catalog
          </Description>
        </DetailsInnerContainer>
        <DetailsLogoContainer>
          <SecondImage
            alt={"imageList"}
            src={uploadImage ?? imageList[logoName]}
          />
          <ServiceOuterDiv>
            <ActiveStepTwoDiv>
              <ServiceInnerDiv>
                <ServiceName>{serviceName}</ServiceName>
                <ChipOne label={functionTag} />
                <ChipTwo label={industryTag} />
                <ChipThree label={serviceTypeTag} />
              </ServiceInnerDiv>
              <ApiTitle>This API is private to your organization</ApiTitle>
            </ActiveStepTwoDiv>
            <ApiDescription>{description}</ApiDescription>
          </ServiceOuterDiv>
        </DetailsLogoContainer>
      </DetailsContainer>
    </PublishContainer>
  );
};
