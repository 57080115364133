import { useState, createContext } from "react";

type CurrentViewContextType = {
  currentView: string;
  setCurrentView: (value: string) => void;
  viewData: any;
  setViewData: (value: string) => void;
};

const INIT_CURRENT_VIEW = {
  currentView: "Home",
  setCurrentView: () => {},
  viewData: "",
  setViewData: () => {},
};

export const CurrentViewContext =
  createContext<CurrentViewContextType>(INIT_CURRENT_VIEW);

const ComponentWithContext = ({ children, initState }) => {
  const [viewData, setViewData] = useState(initState);
  const [currentView, setCurrentView] = useState("Home");

  return (
    <CurrentViewContext.Provider
      value={{
        currentView,
        setCurrentView,
        viewData,
        setViewData,
      }}
    >
      {children}
    </CurrentViewContext.Provider>
  );
};

export default ComponentWithContext;
