import {
  BarChart as RechartsBarchart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";

export default function BarChart({ data, barfill = "#33BDE9" }) {
  return (
    <RechartsBarchart
      width={340}
      height={200}
      data={data}
      margin={{
        top: 0,
        right: 10,
        left: -10,
        bottom: 0,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Bar dataKey="pv" fill={barfill} />
    </RechartsBarchart>
  );
}
