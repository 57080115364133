import { Callback } from "./callback";
import { Logout } from "./logout";
import { Route, Routes } from "react-router-dom";
import { SilentRenew } from "./silentRenew";
import { PrivateRoute } from "./privateRoute";
import MarketplaceContent from "../main/MarketplaceContent";

export const AppRoutes = (
  <Routes>
    <Route path="/signin-oidc" element={<Callback />} />
    <Route path="/logout" element={<Logout />} />
    <Route path="/silent-renew" element={<SilentRenew />} />
    <Route
      path="*"
      element={
        <PrivateRoute>
          <MarketplaceContent />
        </PrivateRoute>
      }
    />
  </Routes>
);
