import * as React from "react";
import { useState } from "react";
import { Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./PowerBI.css";
import { useNavigate } from "react-router-dom";
import PowerBIOverView from "./PowerBIOverView";
import PowerBITechnicalSpecifications from "./PowerBITechnicalSpecifications";
import PowerBIPlansAndFeatures from "./PowerBIPlansAndFeatures";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

const PowerBI: React.FC = () => {
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/powerbi.png"}
        title={"Microsoft Power BI"}
        contents={
          "Microsoft Power BI is a business analytics tool that allows users to visualize and analyze data from various sources. It provides interactive reports, dashboards, and visualizations to help businesses make informed decisions based on data insights."
        }
        tags={[
          {
            label: "BI",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <>
          <Button
            variant="default"
            width="12rem"
            id={"createInstance"}
            onClick={() => navigate("/public-catalog/powerbi/provision")}
          >
            Create Instance
          </Button>
          <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
            Getting Started
          </Button>
        </>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Technical Specifications" value="3" />
            <Tab label="Documentation" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <PowerBIOverView />
        </TabPanel>

        <TabPanel value="2" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <PowerBIPlansAndFeatures />
        </TabPanel>
        <TabPanel value="3" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <PowerBITechnicalSpecifications />
        </TabPanel>
        <TabPanel
          value="4"
          sx={{ padding: "4.5rem 0 0 0", display: "flex" }}
        ></TabPanel>
      </TabContext>
      <div className="flex-row  justifyContent-center gap1rem">
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() => navigate("/public-catalog/powerbi/provision")}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </div>
    </>
  );
};
export default PowerBI;
