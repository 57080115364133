import {
  Heading,
  Subheading,
  P,
  ListItem,
  Bold,
  GrayHeading,
} from "../Styles/Documentation.styles";
import { OnThisPageHelper } from "../OnThisPageHelper";

const anchorTagsArr = [
  { id: "1", subHeadingString: "SS&C Everywhere:" },
  { id: "2", subHeadingString: "Why SS&C Everywhere Matters" },
  {
    id: "3",
    subHeadingString:
      "Use Case: Hedge Funds to Insurance: Tailored Solutions for All",
  },
  {
    id: "4",
    subHeadingString: "Everywhere Data Mesh: A Glimpse into the Future",
  },
  { id: "5", subHeadingString: "Real-time Dashboards and Reports" },
  { id: "6", subHeadingString: "Communication in the Modern Age" },
  { id: "7", subHeadingString: "Ensuring Global Compliance" },
  { id: "8", subHeadingString: "How Does SS&C Everywhere Integrate SOC2?" },
  { id: "9", subHeadingString: "Benefits to Businesses" },
  { id: "10", subHeadingString: "In Conclusion" },
];

export const EverywhereCopy = () => {
  return (
    <>
      <OnThisPageHelper anchorTagsArr={anchorTagsArr} />
      <Heading>SS&C Everywhere</Heading>
      <i>Last Updated: Aug 2024</i>
      <Subheading id="1">SS&C Everywhere:</Subheading>
      <P>
        In the dynamic world of modern business, seamless integration of
        software solutions is more than just a competitive advantage—it's a
        necessity. SS&C Technologies, a name synonymous with pioneering
        innovation, introduces SS&C Everywhere—the epitome of integration in the
        cloud landscape. As the business representation of the SS&C private
        cloud, Everywhere stands out as the singular hub for accessing SS&C's
        unparalleled software products and services.
      </P>
      <Subheading id="2">Why SS&C Everywhere Matters</Subheading>
      <P>
        Everywhere isn't merely a cloud marketplace. It's the culmination of
        SS&C's best-in-class offerings ranging from data platforms to analytics
        engines, cloud infrastructure to Blue Prism automation, and engaging
        user interfaces for reporting. This expansive suite ensures that
        businesses remain agile, adapt swiftly to market fluctuations, and
        leverage the cost-effective advantages of contemporary cloud technology.
      </P>
      <Subheading id="3">
        Use Case: Hedge Funds to Insurance: Tailored Solutions for All
      </Subheading>
      <P>
        Imagine a hedge fund operating on the SS&C GlobeOp platform. In the
        traditional scenario, integrating this platform with performance metrics
        (from SS&C Sylvan) or risk analysis (via SS&C Algorithmics) can be
        cumbersome. With Everywhere, this tedious process is streamlined.
      </P>
      <P>
        An insurance company using SS&C Singularity for accounting can now
        easily funnel this data into SS&C Sightline, a cutting-edge business
        intelligence tool. Further, integrating this with SS&C Algorithmics
        Stadium—a robust data insights and aggregation platform—means that
        businesses can obtain a 360-degree view of their operations.
      </P>
      <P>
        To foster greater interoperability and efficiency between disparate
        systems, modern cloud architectures play a pivotal role:
      </P>
      <ul>
        <ListItem>
          <Bold>Optimized Data Pipelines:</Bold> Utilizing the likes of Airflow,
          SS&C Everywhere establishes data pipelines that are not only robust
          but incredibly efficient. Airflow's flexibility allows for the
          crafting of dynamic workflows, ensuring tasks are executed in optimal
          sequences. This is complemented by Starburst, which empowers users to
          query data across multiple databases seamlessly. Together, they reduce
          the latency traditionally experienced in data processing, enabling
          faster decision-making and value derivation.
        </ListItem>
        <ListItem>
          <Bold>Efficiency Amplified:</Bold> In the world of finance, where data
          is voluminous and diverse, the coupling of Airflow with Starburst
          ensures that data extraction, transformations, and loading (ETL)
          processes are performed faster than ever before. The modular nature of
          this cloud architecture means tasks are executed concurrently,
          drastically reducing data transit times and allowing businesses to
          stay agile.
        </ListItem>
        <ListItem>
          <Bold>Automated Transformations:</Bold> Traditionally, transforming
          data from platforms like Globe GoGeneva required extensive manual
          intervention, often leading to inefficiencies. With SS&C Everywhere,
          these transformations are automated. The extracted data is formatted,
          cleaned, and transformed to be readily ingested by persistence targets
          within SS&C Datamesh, or even for direct live connections.
        </ListItem>
        <ListItem>
          <Bold>Direct Database Connections:</Bold> Take the instance of the
          Singularity accounting database. Instead of the traditional method of
          batched data extraction, SS&C Everywhere permits a live connection,
          allowing businesses to have real-time insights. This dynamic approach
          reduces the latency between data generation and its actionable use, a
          boon for fast-paced sectors like insurance.
        </ListItem>
        <ListItem>
          <Bold>Speed and Scalability:</Bold> By leveraging the capabilities of
          Kubernetes, Airflow's potential is magnified manifold. When
          calculations are parallelized using Airflow on a Kubernetes platform,
          the processing speed is amplified, allowing for faster data
          computation. This translates to quicker risk analyses, prompt
          performance metric derivations, and more timely business insights.
        </ListItem>
        <ListItem>
          <Bold>Enhanced Time to Value:</Bold> The combination of Airflow's
          dynamic workflows with the scalability of Kubernetes ensures that
          businesses can derive value from their data in record time. Whether
          it's for complex risk assessment models in hedge funds or nuanced
          policy analyses in insurance, the time lag between data input and
          insight derivation is minimized.
        </ListItem>
      </ul>
      <P>
        As the financial world becomes increasingly data-centric, the need for
        efficient, rapid, and reliable data processing frameworks becomes
        paramount. SS&C Everywhere's commitment to integrating modern cloud
        architectures, coupled with tools like Airflow and Starburst, ensures
        businesses are not just keeping pace with the industry's evolution, but
        are also poised to lead it. Whether it's a hedge fund analyzing market
        risks or an insurance company assessing policy premiums, SS&C
        Everywhere's solutions ensure that data is processed swiftly,
        efficiently, and accurately—powering businesses to make informed
        decisions faster than ever before.
      </P>
      <Subheading id="4">
        Everywhere Data Mesh: A Glimpse into the Future
      </Subheading>
      <P>
        Taking pride of place as the first offering on the SS&C Everywhere
        platform, the Data Mesh is a game-changer. Here's why:
      </P>
      <ul>
        <ListItem>
          <Bold>Connectivity:</Bold> Whether it's your local storage or an
          external database, connecting to your data is a breeze.
        </ListItem>
        <ListItem>
          <Bold>Intuitive Querying:</Bold> With the combined prowess of
          Starburst and the assistance of ChatGPT, users can query data across
          multiple database types effortlessly.
        </ListItem>
        <ListItem>
          <Bold>API Generation:</Bold> Publish your query as an API, making data
          accessibility a reality for business users.
        </ListItem>
      </ul>
      <Subheading id="5">Real-time Dashboards and Reports</Subheading>
      <P>
        Following closely is the ability for users to access real-time
        interactive dashboards against report data, ensuring timely risk and
        performance reports are at their fingertips.
      </P>
      <Subheading id="6">Communication in the Modern Age</Subheading>
      <P>
        In today's digital era, efficient communication channels are pivotal.
        With Everywhere, stakeholders can gain instant insights, and users can
        access crucial data securely, irrespective of their location. Whether
        it's via a traditional desktop interface or modern communication tools,
        Everywhere ensures data is available, safe, and compliant.
      </P>
      <Subheading id="7">Ensuring Global Compliance</Subheading>
      <P>
        With businesses operating globally, compliance with regional regulations
        is paramount. SS&C Everywhere prioritizes this by offering region
        isolation, such as US and UK specific zones, ensuring stringent
        adherence to regulations like GDPR.
      </P>
      <P>
        In an age where data breaches and privacy concerns dominate headlines,
        ensuring data protection and compliance on a global scale is more than
        just a requirement—it's a trust pact between businesses and their
        clientele. SS&C Everywhere takes this trust seriously and has infused
        its infrastructure with the gold standards of security protocols. One of
        the most prominent feathers in its cap is the SOC2 compliance.
      </P>
      <P>
        Service Organization Control 2 (SOC2) is a framework that evaluates and
        ensures robust controls within a service organization, particularly in
        terms of security, availability, processing integrity, confidentiality,
        and privacy of customer data. Designed by the American Institute of CPAs
        (AICPA), SOC2 is an essential benchmark for cloud and SaaS providers who
        store customer data, establishing that they maintain strict information
        security policies and procedures.
      </P>
      <Subheading id="8">How Does SS&C Everywhere Integrate SOC2?</Subheading>
      <ul>
        <ListItem>
          <Bold>Enhanced Security:</Bold> At the core of the SOC2 framework is
          the assurance of robust security measures. SS&C Everywhere integrates
          state-of-the-art security protocols to ensure unauthorized access,
          data breaches, and other threats are meticulously guarded against.
        </ListItem>
        <ListItem>
          <Bold>Guaranteed Availability:</Bold> SOC2 ensures that the system is
          available for operation and use, as committed or agreed upon. This
          means SS&C Everywhere offers a platform that's consistently up and
          running, minimizing disruptions to businesses
        </ListItem>
        <ListItem>
          <Bold>Processing Integrity:</Bold> With SOC2's focus on data
          processing integrity, SS&C Everywhere ensures that data processing is
          timely, authorized, and accurate, eliminating risks of data
          manipulation.
        </ListItem>
        <ListItem>
          <Bold>Strict Confidentiality:</Bold> For financial institutions and
          businesses where data confidentiality is paramount, SS&C Everywhere's
          adherence to SOC2 ensures that data designated as confidential remains
          protected and disclosed only to authorized entities.
        </ListItem>
        <ListItem>
          <Bold> End-to-End Privacy:</Bold> Beyond just confidentiality, privacy
          protocols make certain that personal information is collected, used,
          retained, and disclosed in conformity with the platform's privacy
          policies.
        </ListItem>
      </ul>
      <Subheading id="9">Benefits to Businesses</Subheading>
      <ul>
        <ListItem>
          <Bold> Trustworthiness:</Bold> Clients partnering with SS&C Everywhere
          can rest assured about the integrity and safety of their data, thanks
          to the platform's SOC2 compliance.
        </ListItem>
        <ListItem>
          <Bold>Competitive Advantage:</Bold> In a market where clients are
          becoming more aware and demanding about data security, SOC2 compliance
          positions SS&C Everywhere, and by extension its clients, ahead in the
          race.
        </ListItem>
        <ListItem>
          <Bold> Regional Compliances Met:</Bold> Alongside SOC2, SS&C
          Everywhere's commitment to region-specific compliances like GDPR means
          businesses can operate without fretting over regional data laws.
        </ListItem>
      </ul>
      <Subheading id="10">In Conclusion</Subheading>
      <P>
        Incorporating SOC2 into its infrastructure signals SS&C Everywhere’s
        commitment to upholding the highest standards of security and
        operational best practices. Businesses not only benefit from seamless
        integrations and innovative solutions but also from an environment that
        respects, protects, and prioritizes their data.
      </P>
      <GrayHeading>Conclusion</GrayHeading>
      <P>
        SS&C Everywhere is more than just a cloud platform. It's a commitment to
        ensuring businesses have the tools, integrations, and agility they need
        to thrive in a rapidly evolving marketplace. Whether it's integrating
        accounting data with performance metrics or ensuring GDPR compliance,
        SS&C Everywhere promises a seamless, secure, and efficient experience.
        Join us in this journey towards a connected, integrated, and innovative
        future.
      </P>
    </>
  );
};
