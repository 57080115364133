import { AppThunk } from "../store";
import axios from "axios";
import { enqueueSnackbar } from "../snackbars/actions";
import { NotificationLevel } from "../snackbars/notification-level";
import {
  UserData,
  Roles,
  InstancesDetails,
  Projects,
} from "../../common/dataTypes";
import { USER_ENDPOINT_BASE_URL } from "../../constants";

export enum ProjectsManagementActionType {
  SET_PROJECTS = "SET_PROJECTS",
  SET_PROJECTS_LOADING = "SET_PROJECTS_LOADING",
  SET_RESOURCES = "SET_RESOURCES",
  SELECT_PROJECTS = "SELECT_PROJECTS ",
  SHOW_HIDE_PROJECTS_DETAILS = "SHOW_HIDE_PROJECTS_DETAILS",
  SHOW_HIDE_EDIT_PROJECT_DESCRIPTION = "SHOW_HIDE_EDIT_PROJECT_DESCRIPTION",
  SHOW_HIDE_EDIT_PROJECT_NAME = "SHOW_HIDE_EDIT_PROJECT_NAME",
  SET_PROJECT_DETAILS = "SET_PROJECT_DETAILS",
  SET_PROJECT_DETAILS_LOADING = "SET_PROJECT_DETAILS_LOADING",
  SET_PROJECT_RESOURCES = "SET_PROJECT_RESOURCES",
  SET_PROJECT_ROLES = "SET_PROJECT_ROLES",
  SET_PROJECT_USERS = "SET_PROJECT_USERS",
  SHOW_HIDE_CREATE_PROJECT = "SHOW_HIDE_CREATE_PROJECT",
  SHOW_HIDE_DUPLICATE_PROJECT = "SHOW_HIDE_DUPLICATE_PROJECT",
}

export const fetchProjects = (): AppThunk => (dispatch) => {
  dispatch(setProjectsLoading(true));
  axios
    .get(`${USER_ENDPOINT_BASE_URL}/projects`)
    .then((response) => {
      dispatch(setProjectsLoading(false));
      dispatch(
        setProjects(
          response.data.projects.map((i) => {
            return { ...i, id: i.projectId };
          }),
        ),
      );
    })
    .catch(() => {
      dispatch(setProjectsLoading(false));
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve Projects`,
        ),
      );
    });
};

export const deleteProjectById =
  (id: Projects["projectId"]): AppThunk =>
  (dispatch) => {
    dispatch(setProjectOverviewDetailsLoading(true));
    axios
      .delete(`${USER_ENDPOINT_BASE_URL}/projects/${id}`)
      .then(() => {
        dispatch(fetchProjects());
        dispatch(setShowHideProjectsDetails(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Project successfully removed",
          ),
        );
      })
      .catch(() => {
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to delete Project`,
          ),
        );
      });
  };

export const fetchProjectsOverview =
  (id: Projects["projectId"]): AppThunk =>
  (dispatch) => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/projects/${id}`)
      .then((response) => {
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(setProjectsOverviewDetails(response.data));
      })
      .catch(() => {
        dispatch(setShowHideProjectsDetails(false));
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve Project info`,
          ),
        );
      });
  };

export const fetchProjectResources =
  (id: Projects["projectId"]): AppThunk =>
  (dispatch) => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/projects/${id}/resources`)
      .then((response) => {
        dispatch(setProjectsResources(response.data.instances));
        dispatch(setProjectOverviewDetailsLoading(false));
      })
      .catch(() => {
        dispatch(setShowHideProjectsDetails(false));
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve Project Resources`,
          ),
        );
      });
  };

export const updateProjectResources =
  (id: Projects["projectId"], payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setProjectOverviewDetailsLoading(true));
    axios
      .post(`${USER_ENDPOINT_BASE_URL}/projects/${id}/resources`, payload)
      .then(() => {
        dispatch(fetchProjectResources(id));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Project Resources are updated.",
          ),
        );
      })
      .catch(() => {
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to updated Project Resources.`,
          ),
        );
      });
  };

export const deleteProjectResources =
  (id: Projects["projectId"], payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setProjectOverviewDetailsLoading(true));
    axios
      .delete(`${USER_ENDPOINT_BASE_URL}/projects/${id}/resources`, {
        data: payload,
      })
      .then(() => {
        dispatch(fetchProjectResources(id));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Resource successfully removed from project",
          ),
        );
      })
      .catch(() => {
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to updated Project Resources.`,
          ),
        );
      });
  };

export const fetchProjectsRoles =
  (id: Projects["projectId"]): AppThunk =>
  (dispatch) => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/projects/${id}/roles`)
      .then((response) => {
        dispatch(setProjectsRoles(response.data.groups));
        dispatch(setProjectOverviewDetailsLoading(false));
      })
      .catch(() => {
        dispatch(setShowHideProjectsDetails(false));
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve Project Roles`,
          ),
        );
      });
  };

export const updateProjectsRoles =
  (id: Projects["projectId"], payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setProjectOverviewDetailsLoading(true));
    axios
      .post(`${USER_ENDPOINT_BASE_URL}/projects/${id}/roles`, payload)
      .then(() => {
        dispatch(fetchProjectsRoles(id));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Project Roles are updated.",
          ),
        );
      })
      .catch(() => {
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to updated Project Roles.`,
          ),
        );
      });
  };

export const deleteProjectsRoles =
  (id: Projects["projectId"], payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setProjectOverviewDetailsLoading(true));
    axios
      .delete(`${USER_ENDPOINT_BASE_URL}/projects/${id}/roles`, {
        data: payload,
      })
      .then(() => {
        dispatch(fetchProjectsRoles(id));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Role successfully removed from project",
          ),
        );
      })
      .catch(() => {
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to updated Project Roles.`,
          ),
        );
      });
  };

export const fetchProjectsUsers =
  (id: Projects["projectId"]): AppThunk =>
  (dispatch) => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/projects/${id}/users`)
      .then((response) => dispatch(setProjectsUsers(response.data.users)))
      .catch(() => {
        dispatch(setShowHideProjectsDetails(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve Project Users`,
          ),
        );
      });
  };

export const fetchResources = (): AppThunk => (dispatch) => {
  axios
    .get(`${USER_ENDPOINT_BASE_URL}/resources`)
    .then((response) => dispatch(setResources(response.data.instances)))
    .catch(() => {
      dispatch(setShowHideProjectsDetails(false));
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve Project Users`,
        ),
      );
    });
};

export const createProject =
  (payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setProjectOverviewDetailsLoading(true));
    axios
      .post(`${USER_ENDPOINT_BASE_URL}/projects`, payload)
      .then(() => {
        dispatch(fetchProjects());
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Project successfully created",
          ),
        );
        dispatch(setShowCreateProject(false));
      })
      .catch(() => {
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to create Project.`,
          ),
        );
      });
  };

export const duplicateProject =
  (payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setProjectOverviewDetailsLoading(true));
    axios
      .post(`${USER_ENDPOINT_BASE_URL}/projects`, payload)
      .then(() => {
        dispatch(fetchProjects());
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Project successfully duplicated",
          ),
        );
        dispatch(setShowDuplicateProject(false));
        dispatch(setProjectOverviewDetailsLoading(false));
      })
      .catch(() => {
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to Duplicate Project.`,
          ),
        );
      });
  };

export const editProjectById =
  (id: Projects["projectId"], payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setProjectOverviewDetailsLoading(true));
    axios
      .put(`${USER_ENDPOINT_BASE_URL}/projects/${id}`, payload)
      .then(() => {
        dispatch(fetchProjectsOverview(id));
        //dispatch(fetchProjects());
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Project details are updated.",
          ),
        );
      })
      .catch(() => {
        dispatch(fetchProjectsOverview(id));
        dispatch(setShowEditProjectName(false));
        dispatch(setShowEditProjectName(false));
        dispatch(setProjectOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to update Project details.`,
          ),
        );
      });
  };

export const setProjectsLoading = (setLoading: boolean) =>
  ({
    type: ProjectsManagementActionType.SET_PROJECTS_LOADING,
    payload: setLoading,
  }) as const;

export const setProjectOverviewDetailsLoading = (setLoading: boolean) =>
  ({
    type: ProjectsManagementActionType.SET_PROJECT_DETAILS_LOADING,
    payload: setLoading,
  }) as const;

export const setProjects = (projects: Projects[]) =>
  ({
    type: ProjectsManagementActionType.SET_PROJECTS,
    payload: projects,
  }) as const;

export const setSelectProjects = (selectedProject: Projects) =>
  ({
    type: ProjectsManagementActionType.SELECT_PROJECTS,
    payload: selectedProject,
  }) as const;

export const setShowHideProjectsDetails = (showDetails: boolean) =>
  ({
    type: ProjectsManagementActionType.SHOW_HIDE_PROJECTS_DETAILS,
    payload: showDetails,
  }) as const;

export const setShowEditProjectDesc = (showEditProjectDesc: boolean) =>
  ({
    type: ProjectsManagementActionType.SHOW_HIDE_EDIT_PROJECT_DESCRIPTION,
    payload: showEditProjectDesc,
  }) as const;

export const setShowEditProjectName = (showEditProjectName: boolean) =>
  ({
    type: ProjectsManagementActionType.SHOW_HIDE_EDIT_PROJECT_NAME,
    payload: showEditProjectName,
  }) as const;

export const setShowCreateProject = (showCreateProject: boolean) =>
  ({
    type: ProjectsManagementActionType.SHOW_HIDE_CREATE_PROJECT,
    payload: showCreateProject,
  }) as const;

export const setShowDuplicateProject = (showDuplicateProject: boolean) =>
  ({
    type: ProjectsManagementActionType.SHOW_HIDE_DUPLICATE_PROJECT,
    payload: showDuplicateProject,
  }) as const;

export const setProjectsOverviewDetails = (projectOverviewDetails: Projects) =>
  ({
    type: ProjectsManagementActionType.SET_PROJECT_DETAILS,
    payload: projectOverviewDetails,
  }) as const;

export const setProjectsResources = (projectResources: InstancesDetails[]) =>
  ({
    type: ProjectsManagementActionType.SET_PROJECT_RESOURCES,
    payload: projectResources,
  }) as const;

export const setProjectsRoles = (projectRoles: Roles[]) =>
  ({
    type: ProjectsManagementActionType.SET_PROJECT_ROLES,
    payload: projectRoles,
  }) as const;

export const setProjectsUsers = (projectUsers: UserData[]) =>
  ({
    type: ProjectsManagementActionType.SET_PROJECT_USERS,
    payload: projectUsers,
  }) as const;

export const setResources = (resources: InstancesDetails[]) =>
  ({
    type: ProjectsManagementActionType.SET_RESOURCES,
    payload: resources,
  }) as const;

export type ProjectsManagementAction =
  | ReturnType<typeof setProjects>
  | ReturnType<typeof setProjectsLoading>
  | ReturnType<typeof setProjectOverviewDetailsLoading>
  | ReturnType<typeof setSelectProjects>
  | ReturnType<typeof setShowHideProjectsDetails>
  | ReturnType<typeof setShowEditProjectDesc>
  | ReturnType<typeof setShowEditProjectName>
  | ReturnType<typeof setShowDuplicateProject>
  | ReturnType<typeof setShowCreateProject>
  | ReturnType<typeof setProjectsOverviewDetails>
  | ReturnType<typeof setProjectsResources>
  | ReturnType<typeof setProjectsRoles>
  | ReturnType<typeof setProjectsUsers>
  | ReturnType<typeof setResources>;
