import * as React from "react";
import Typography from "@mui/material/Typography";
import Region from "../Regions";
import { useNavigate } from "react-router-dom";
import Carousel from "../../subcomponents/Carousel";
import CardView from "../CardView";
import NorthAmerica from "../../../../assets/NorthAmerica.png";
import CatalogBenefits from "../CatalogBenefits";
import CatalogKeyFeatures from "../CatalogKeyFeatures";
import AlgoPerformancePricing from "./AlgoPerformancePricing";
import CatalogSubHeading from "../CatalogSubHeading";
import Grid from "@mui/material/Grid";
import { Button } from "@ssce/ui-shared-library";
import SSCE_Perf_Workspace from "../../../../assets/AlgoPerformanceReport/SSCE_Perf_Workspace.png";
import SSCE_Perf_Concentration from "../../../../assets/AlgoPerformanceReport/SSCE_Perf_Concentration.png";
import SSCE_Perf_Contribution from "../../../../assets/AlgoPerformanceReport/SSCE_Perf_Contribution.png";
import SSCE_Perf_ExPost from "../../../../assets/AlgoPerformanceReport/SSCE_Perf_ExPost.png";
import SSCE_Perf_Exposure from "../../../../assets/AlgoPerformanceReport/SSCE_Perf_Exposure.png";
import SSCE_Perf_Profile from "../../../../assets/AlgoPerformanceReport/SSCE_Perf_Profile.png";
import SSCE_Perf_Snapshot from "../../../../assets/AlgoPerformanceReport/SSCE_Perf_Snapshot.png";
import SSCE_Perf_Top5 from "../../../../assets/AlgoPerformanceReport/SSCE_Perf_Top5.png";
import TuneIcon from "@mui/icons-material/Tune";
import BalanceIcon from "@mui/icons-material/Balance";
import GradingIcon from "@mui/icons-material/Grading";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import FilterListIcon from "@mui/icons-material/FilterList";

const CARD_DATA = [
  {
    id: 1,
    logo: "/api/assets/datamesh.png",
    serviceName: "SS&C Everywhere Data Mesh",
    shortDescription:
      "The SS&C Everywhere Data Mesh platform provides an interface for users to centralize their data to make it more accessible to the rapidly changing needs of a user’s organization.",
    tags: {
      function: "Cloud Platform",
      industry: "Cross-Industry",
      serviceType: "Data Service",
    },
  },
  {
    id: 2,
    logo: "/api/assets/algoriskreport.png",
    serviceName: "SSCE Risk Service",
    shortDescription:
      "Delivers a seamless end-to-end performance solution from data acquisition and transformation to performance reporting and interactive dashboards",
    tags: {
      function: "Risk Management",
      industry: "Cross-Industry",
      serviceType: "Analytics Service",
    },
  },
];

const AlgoPerformanceOverView: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateTo = (name: string) => {
    if (name === "SS&C Everywhere Data Mesh") {
      return navigate("/public-catalog/data-mesh");
    }
    if (name === "SSCE Risk Service") {
      return navigate("/public-catalog/ss&c-algorithmics-risk-reports");
    }
  };

  return (
    <>
      <div className="flex-col gap3rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="1.5rem"
            fontWeight={600}
            fontStyle={"italic"}
            component="div"
            style={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Powered by SS&C Algorithmics
          </Typography>
          <div>
            <Typography
              variant="h6"
              fontSize="1.25rem"
              sx={{ color: "#02273C", lineHeight: "1.5rem" }}
            ></Typography>
            <Typography
              variant="body1"
              component={"div"}
              sx={{ color: "#000", lineHeight: "1.5rem" }}
            >
              <p>
                The SS&C Performance Service is an outsourced service that can
                be easily appended to an existing SS&C agreement such as
                GlobeOp, Fund Admin, Geneva, EZE and Aloha. ​
              </p>
              <p>
                The Service uses existing holdings data housed within the SS&C
                platform to deliver metrics such as performance, returns and
                return contributions through reports or an interactive and
                intuitive web-based dashboard. Gain deeper insights, make
                informed decisions and enhance investment performance through
                accurate reports and data in a simple and cost-effective
                service.​
              </p>
            </Typography>

            <Carousel
              imageList={[
                {
                  src: SSCE_Perf_Workspace,
                  alt: "SSCE Performance Workspace",
                },
                {
                  src: SSCE_Perf_Concentration,
                  alt: "SSCE Performance Concentration",
                },
                {
                  src: SSCE_Perf_Contribution,
                  alt: "SSCE Performance Contribution",
                },
                {
                  src: SSCE_Perf_ExPost,
                  alt: "SSCE Performance ExPost",
                },
                {
                  src: SSCE_Perf_Exposure,
                  alt: "SSCE Performance Exposure",
                },
                {
                  src: SSCE_Perf_Profile,
                  alt: "SSCE Performance Profile",
                },
                {
                  src: SSCE_Perf_Snapshot,
                  alt: "SSCE Performance Snapshot",
                },
                {
                  src: SSCE_Perf_Top5,
                  alt: "SSCE Performance Top5",
                },
              ]}
            />
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <TuneIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Seamless Integration",
              description:
                "Achieve smooth integration with SS&C systems or other platforms​",
            },

            {
              icon: (
                <FilterListIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Customized",
              description:
                "Tailor the solution to meet your specific needs and objectives​",
            },
            {
              icon: (
                <BalanceIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Balance BAU and Projects",
              description:
                "We manage BAU tasks, allowing you to concentrate on value-creating projects ​",
            },
            {
              icon: (
                <GradingIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Data Quality",
              description:
                "Improves accuracy and reliability, essential for effective performance analysis​",
            },
            {
              icon: (
                <StackedLineChartIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Advanced Modeling",
              description:
                "Employs sophisticated quantitative models for precise performance measurement​",
            },
            {
              icon: (
                <AccountTreeIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Streamlined Processes",
              description:
                "Boosts operational efficiency by integrating seamlessly with SS&C’s systems and third-party platforms, ensuring a unified technological ecosystem​",
            },
            {
              icon: (
                <SupportAgentIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Dedicated Support",
              description:
                "Comprehensive client support and consulting services​",
            },
          ]}
        />
        <CatalogKeyFeatures
          childCount={2}
          listItems={[
            {
              heading: "Measurement",
              description:
                "Time-weighted and money-weighted returns from total fund level down to security level across all asset classes.",
            },
            {
              heading: "Decomposition",
              description:
                "Decomposition of returns (e.g. gross, net, capital, income, FX, etc.)",
            },
            {
              heading: "Contribution",
              description:
                "Disaggregation of returns, contributions to return, P&Ls and exposures by any user-defined categories (e.g. sector, country, region, long /short, asset class, manager, style, currency, market cap, duration band, etc.)",
            },
            {
              heading: "Risk and Return",
              description:
                "Advanced ex-post risk analysis across user-defined buckets and time horizons",
            },
          ]}
        />
        <AlgoPerformancePricing title={"Available Pricing Plans"} />
        <div className={"flex-col gap3rem"}>
          <CatalogSubHeading title={"Region Availability"} />
          <div className={"flex-col gap1rem"}>
            <CatalogSubHeading
              title={"Available Regions"}
              fontSize="1.25rem"
              color="#02273C"
            />
            <div>
              <Grid container spacing={0}>
                <Grid item xs={6} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} md={6}>
                      <li className="regionListli">{"North AmericaSouth"}</li>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <img
                    alt={"northAmerica"}
                    width={400}
                    height={200}
                    src={NorthAmerica}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="specificRegionDetils">
            <CatalogSubHeading
              title={"Specific Region Details"}
              fontSize="1.25rem"
              color="#02273C"
            />

            <div>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Region />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Region Details
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    North America: This region provides low-latency access to
                    customers located in the United States and Canada. It offers
                    high availability and scalability, making it an ideal choice
                    for enterprise customers with large-scale workloads.
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 8,
                    }}
                  >
                    Local Compliance
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    Fully compliant with regulatory requirements, including:
                    <ul>
                      <li>GDPR</li>
                      <li>ISO 27000</li>
                      <li>SOC-2</li>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>

        <div className="flex-col gap2rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{ color: "#0A85C7", lineHeight: "1.875rem" }}
          >
            Related Products
          </Typography>
          <div>
            <CardView
              variant="simple"
              data={CARD_DATA}
              handleNavigateTo={handleNavigateTo}
            />

            <Button
              variant="text"
              id={"viewMore"}
              onClick={() => {
                navigate("/public-catalog");
              }}
            >
              View More
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AlgoPerformanceOverView;
