import { useState } from "react";
import WorkflowCatalogSetup from "./WorkflowCatalogSetup";
import WorkflowCatalogCarousel from "./WorkflowCatalogCarousel";
import WorkflowCatalogCardView from "./WorkflowCatalogCardView";
import { DummyWorkflowViewer } from "../DummyWorkflowViewer";

const WorkflowCatalog = () => {
  const [isCreateALgoRiskClicked, setisCreateAlgoRiskClicked] =
    useState<boolean>();

  if (isCreateALgoRiskClicked === true) {
    return <DummyWorkflowViewer workflowId={"algo-risk"} />;
  } else if (isCreateALgoRiskClicked === false) {
    return <DummyWorkflowViewer workflowId={"algo-performance"} />;
  }
  return (
    <>
      <WorkflowCatalogCarousel
        onCreateClick={(isAlgoRisk) => {
          setisCreateAlgoRiskClicked(isAlgoRisk);
        }}
      />
      <WorkflowCatalogSetup />
      <WorkflowCatalogCardView
        onCreateClick={(isAlgoRisk: boolean) =>
          setisCreateAlgoRiskClicked(isAlgoRisk)
        }
      />
    </>
  );
};

export { WorkflowCatalog };
