import WorkflowCatalogCards from "./WorkflowCatalogCard";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import AlgoPerformance from "../../../../assets/WorkflowCatalog/AlgoPerformanceDark.png";
import AlgoRisk from "../../../../assets/WorkflowCatalog/AlgoRiskDark.png";
import DataMesh from "../../../../assets/WorkflowCatalog/DataMeshLogo.png";
import AlgoLogo from "../../../../assets/WorkflowCatalog/AlgoLogo.png";
import {
  WorkflowCatalogSubtitle,
  WorkflowCatalogCarousel1,
} from "./WorkflowCatalog.styles";

const WL_CATALOG_DATA = [
  {
    image: AlgoPerformance,
    title: "SS&C Performance Workflow",
    description:
      "Delivers a seamless end-to-end performance solution from data acquisition and transformation to performance reporting and interactive dashboards ",
    tags: ["Cloud Platform", "Financial", "Performance"],
    services: [
      {
        link: `/public-catalog/data-mesh`,
        serviceImage: DataMesh,
        serviceText: "Data Mesh",
      },
      {
        link: `/public-catalog/ss&c-algorithmics-performance-reports`,
        serviceImage: AlgoLogo,
        serviceText: "SS&C Performance Service",
      },
    ],
  },
  {
    image: AlgoRisk,
    title: "SS&C Risk Workflow",
    description:
      "Delivers a seamless end-to-end performance solution from data acquisition and transformation to risk reporting and interactive dashboards",
    subTitle: "Ver. 1.0",

    tags: ["Cloud Platform", "Cross-Industry", "Risk"],
    services: [
      {
        link: `/public-catalog/data-mesh`,
        serviceImage: DataMesh,
        serviceText: "Data Mesh",
      },
      {
        link: `/public-catalog/ss&c-algorithmics-risk-reports`,
        serviceImage: AlgoLogo,
        serviceText: "SS&C Risk Service",
      },
    ],
  },
];

const WorkflowCatalogCarousel = ({ onCreateClick }) => {
  return (
    <>
      <WorkflowCatalogSubtitle>Featured Workflows</WorkflowCatalogSubtitle>
      <WorkflowCatalogCarousel1 showThumbs={true} showStatus={false}>
        {WL_CATALOG_DATA.map((item, index) => (
          <div key={index} style={{ width: "100%" }}>
            <WorkflowCatalogCards
              backgroundImage={item.image}
              title={item.title}
              description={item.description}
              subTitle={item.subTitle}
              services={item.services}
              tags={item.tags}
              isAlgoRiskReport={item.title === "SS&C Risk Workflow"}
              inCarousel={true}
              onCreateClick={() =>
                onCreateClick(item.title === "SS&C Risk Workflow")
              }
            />
          </div>
        ))}
      </WorkflowCatalogCarousel1>
    </>
  );
};

export default WorkflowCatalogCarousel;
