import styled from "styled-components";

export const Title = styled.div`
  line-height: 1.6;
  font-size: 2.125rem;
  color: #0a85c7;
  font-weight: 600;
`;
export const SubTitle = styled.div`
  line-height: 1.25rem;
  color: #02273c;
  font-size: 1.875rem;
  font-weight: 500;
  margin: 30px 0 50px 0;
`;
export const Card = styled.div`
  border: 0.063rem solid #cbd9e1;
  border-radius: 0.25rem;
  margin-right: 1.563rem;
  background: var(--neutralWhite);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
`;

export const CardTop = styled.div`
  display: flex;
  margin: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 0.125rem solid #cbd9e1;
  height: 60%;
`;
export const CardBottom = styled.div`
  display: flex;
  margin: 1.25rem;
 padding-bottom: 3.25rem;
`;
export const CardTitle = styled.div`
  line-height: 1.25rem;
  color: #02273c;
  font-size: 1.25rem;
  font-weight: 600;
`;
export const CardContents = styled.div`
  line-height: 1.25rem;
  color: #02273c;
  font-size: 1rem;
  font-weight: 500;
`;
export const CardBottomLabel = styled.div`
  line-height: 1.25rem;
  color: #02273c;
  font-weight: 600;
  font-size: 1.35rem;
`;
export const CardBottomValue = styled.div`
  line-height: 1.25rem;
  color: #02273c;
  font-size: 1.875rem;
  font-weight: 600;
  text-align: center;
  align-self: center;
  background-color: #cfe9f3;
  padding: 0.75rem;
  color: #0a85c7;
  border-radius: 1.25rem;
  margin: 0 12px;
`;
export const Currency = styled.div`
  line-height: 1.25rem;
  color: #02273c;
  font-weight: 600;
  font-size: 3.125rem;
  display: inline-block;
  margin-left: 1.875rem;
`;
export const CurrencyLabel = styled.div`
  line-height: 1.25rem;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(117, 144, 159);
`;
export const CurrencyContainer = styled.div`
  display: flex;
  align-items: baseline;
  margin-top: 1.25rem;
`;
export const NextPaymentDue = styled.div`
  line-height: 1.25rem;
  color: #02273c;
  font-size: 1rem;
  font-weight: 400;
  color: rgb(117, 144, 159);
`;
export const PaymentDueDate = styled.div`
  line-height: 1.25rem;
  color: #02273c;
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 1rem;
`;
export const CardContainer = styled.div`
  width: 40%;
  row-gap: 1rem;
  display: flex;
  flex-direction: column;
`;
