import React from "react";
import Grid from "src/components/main/subcomponents/Grid/Grid";
import styled from "styled-components";

const InstanceItem = styled.div`
  display: block;
`;

const InstanceItemName = styled.div`
  font-size: 0.875rem;
  font-weight: 400;
  color: #577687;
  margin-bottom: 0.625rem;
`;

const InstanceItemValue = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: #02273c;
  margin-bottom: 1.25rem;
`;

type SummaryListProps = {
  data: {
    name: string;
    value: string;
  }[];
};

const SummaryList: React.FC<SummaryListProps> = ({ data }) => {
  return (
    <Grid minMax="22rem">
      {data.map((item, key) => (
        <InstanceItem key={key}>
          <InstanceItemName>{item.name}:</InstanceItemName>
          <InstanceItemValue>{item.value}</InstanceItemValue>
        </InstanceItem>
      ))}
    </Grid>
  );
};

export default SummaryList;
