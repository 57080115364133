import React from "react";
import Typography from "@mui/material/Typography";

export const CatalogSubHeading: React.FC<any> = ({ title, ...otherProps }) => {
  return (
    <>
      <Typography
        variant="h4"
        fontSize="2.125rem"
        fontWeight={600}
        component="div"
        color={"var(--blue500)"}
        lineHeight={"2.25rem"}
        {...otherProps}
      >
        {title}
      </Typography>
    </>
  );
};

export default CatalogSubHeading;
