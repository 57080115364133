import { FC } from "react";
import { DataMeshIntro } from "./dataMeshIntro";
import {
  GeneralContainer,
  DocumentationContainer,
} from "./DataMeshDocumentation";

interface Props {}

export const DocumentationTab: FC<Props> = () => {
  return (
    <div>
      <GeneralContainer>
        <DocumentationContainer>
          <DataMeshIntro />
        </DocumentationContainer>
      </GeneralContainer>
    </div>
  );
};
