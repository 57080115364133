import { useState, useEffect, FC } from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button } from "@ssce/ui-shared-library";
import Accordion from "../Accordion";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmModal from "../ConfirmModal";
import { Projects } from "../../../../common/dataTypes";

import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetcRoleProjects,
  fetcProjects,
  updateRoleProjects,
  deleteRoleProjects,
} from "../../../../redux/rolesManagement/actions";
import Acl from "../../subcomponents/Acl";
import { useAclHooks } from "../../subcomponents/Acl/hooks";
import { PERMISSION_LIST } from "../../../../constants";

const mapStateToProps = (state: AppState) => ({
  selectedRole: state.rolesManagement.selectedRole,
  roleProjects: state.rolesManagement.roleProjects,
  projectsList: state.rolesManagement.projectsList,
});

const mapDispatchToProps = {
  fetcRoleProjects,
  fetcProjects,
  deleteRoleProjects,
  updateRoleProjects,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const ProjectsManagement: FC<Props> = ({
  selectedRole,
  roleProjects,
  projectsList,
  fetcRoleProjects,
  fetcProjects,
  deleteRoleProjects,
  updateRoleProjects,
}) => {
  const [selectedProjects, setSelectedProjects] = useState<Projects[]>([]);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<Projects>({
    id: "",
    name: "",
    description: "",
    createdTimestamp: 0,
    instances: [],
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDeleteClick = () => {
    handleClose();
    deleteRoleProjects(selectedRole.id, [{ id: deleteData.id }]);
  };

  const handleApplyClick = () => {
    let payload = selectedProjects.map((item) => {
      return { id: item.projectId };
    });
    setSelectedProjects([]);
    updateRoleProjects(selectedRole.id, payload);
  };

  useEffect(() => {
    fetcRoleProjects(selectedRole.id);
    fetcProjects();
  }, [fetcRoleProjects, fetcProjects, selectedRole.id]);

  const allowProjectDelete = useAclHooks(PERMISSION_LIST.PROJECTS_EXECUTE);
  const renderAssignedProjects = () => {
    return roleProjects.map((item) => {
      return (
        <Accordion
          key={item.name}
          name={item.name}
          iconHeader={allowProjectDelete && <DeleteForeverIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            handleOpen();
            setDeleteData(item);
          }}
        >
          <Typography
            style={{
              color: "#0979B5",
              fontWeight: 500,
              fontSize: 12,
              marginLeft: 20,
            }}
          >
            Resources Managed
          </Typography>
          <div style={{ marginLeft: 20, fontSize: 16 }}>
            {item.instances.map((i, r, arr) => (
              <span key={i.instanceName}>
                X {i.instanceName} {arr.length - 1 !== r ? "," : ""}{" "}
              </span>
            ))}
          </div>
        </Accordion>
      );
    });
  };

  const renderSelectedProjects = () => {
    return selectedProjects.map((item) => {
      return (
        <Accordion
          key={item.name}
          name={item.name}
          iconHeader={<ClearIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            setSelectedProjects([
              ...selectedProjects.filter((r) => item.projectId !== r.projectId),
            ]);
          }}
        >
          <Typography
            style={{ color: "#0A85C7", fontSize: 12, marginLeft: 20 }}
          >
            Description
          </Typography>
          <Typography
            style={{
              color: "#02273C",
              fontWeight: 400,
              fontSize: 14,
              marginLeft: 20,
            }}
          >
            {item.description}
          </Typography>
          <br></br>
        </Accordion>
      );
    });
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <h1
          style={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 20,
            lineHeight: 0,
            textAlign: "left",
            color: "#02273C",
          }}
        >
          Projects
        </h1>
        <br></br>
        {renderAssignedProjects()}
        <br></br>
      </div>
      <ConfirmModal
        open={open}
        title={"Please Confirm"}
        onRequestCancel={handleClose}
        onRequestConfirm={handleDeleteClick}
      >
        <Typography
          style={{
            margin: 2,
            fontSize: 20,
            fontWeight: 500,
            lineHeight: "1.5rem",
          }}
        >
          You are{" "}
          <span style={{ textDecoration: "underline", fontWeight: 900 }}>
            removing{" "}
          </span>{" "}
          the{" "}
          <span style={{ fontWeight: 700, color: "#0468B1" }}>
            {deleteData.name}
          </span>{" "}
          from the {selectedRole.name} role. You can add {deleteData.name} back
          to this role if you change your mind.{" "}
          <span style={{ fontWeight: 900 }}>
            Are you sure you want to continue?
          </span>
        </Typography>
      </ConfirmModal>
      <Acl permission={PERMISSION_LIST.PROJECTS_WRITE}>
        <div style={{ marginBottom: "10%" }}>
          <h1
            style={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 20,
              lineHeight: 0,
              textAlign: "left",
              color: "#02273C",
            }}
          >
            Assign Projects
          </h1>
          <br></br>
          <div style={{ border: "0.063rem solid #CBD9E1", borderRadius: 4 }}>
            <Stack
              spacing={2}
              sx={{
                margin: 2,
                marginBottom: "0.188rem",
                width: 570,
                backgroundColor: "#F6FDFF",
              }}
            >
              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="medium"
                value={selectedProjects}
                sx={{
                  "& .MuiChip-root": {
                    backgroundColor: "#B3D9EE",
                    color: "#075E8D",
                  },
                  "& .MuiChip-root .MuiChip-deleteIcon": { color: "#0A85C7" },
                }}
                onChange={(_, values) => {
                  setSelectedProjects(values);
                }}
                options={projectsList}
                isOptionEqualToValue={(option, value) =>
                  option.projectId === value.projectId
                }
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Projects" placeholder="" />
                )}
              />
            </Stack>
            <p
              style={{
                marginLeft: 20,
                marginTop: 0,
                color: "#577687",
                backgroundColor: "#EDF4F8",
                fontSize: 12,
              }}
            >
              Please use the dropdown box above or type in a Projects name to
              select it.
            </p>
            <p
              style={{
                margin: "1rem 1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 18,
                textAlign: "left",
                color: "#02273C",
              }}
            >
              Projects Addition Details
            </p>
            {renderSelectedProjects()}
            <div
              style={{
                width: "85%",
                marginLeft: 20,
                marginBottom: 20,
                display: "flex",
                flexDirection: "row",
              }}
            >
              <br></br>
              <Button
                id={"apply"}
                onClick={handleApplyClick}
                variant="default"
                size="medium"
              >
                Apply changes
              </Button>
              <Button
                id={"cancel"}
                onClick={() => setSelectedProjects([])}
                variant="text"
                size="medium"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Acl>
    </>
  );
};

export default connector(ProjectsManagement);
