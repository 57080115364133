import { MarkerType } from "reactflow";
import {
  Database,
  BuildCircle,
  Checklist,
  JoinLeft,
  Output,
  Source,
  CheckCircle,
} from "./icons";
import {
  Edge,
  NodeAPI,
  WorkflowAPIResponse,
  Status,
} from "../WorkflowViewerContent/workflowViewerHook";

export const getStatusColor = (status: Status) => {
  if (status === "success") return "#3CC13B";
  if (status === "failed") return "#F03737";
  if (status === "running") return "#0A85C7";
  if (!status || status === "pending" || status == "queued") return "#B6C7D1";
  return "#B6C7D1";
};

const iconMapping = {
  BuildCircle: BuildCircle,
  Checklist: Checklist,
  JoinLeft: JoinLeft,
  Output: Output,
  Source: Source,
  CheckCircleOutline: CheckCircle,
};

const getNodes = (nodes: NodeAPI[]) => {
  return nodes.map((node) => ({
    id: node.id,
    type: "node",
    position: {
      x: node.position.x,
      y: node.position.y,
    },
    data: {
      Icon: iconMapping[node.icon] || Database,
      ...node,
    },
  }));
};

const getEdges = (edges: Edge[]) => {
  return edges.map((edge) => ({
    ...edge,
    animated: edge.status === "running",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: getStatusColor(edge.status),
    },
    style: {
      stroke: getStatusColor(edge.status),
    },
  }));
};

export const mapApiToUI = (job: WorkflowAPIResponse) => {
  return {
    id: job.id,
    createdAt: job.createdAt,
    status: job.status,
    runningTime: job.runTime,
    activeStep: job.activeStep,
    errors: job.errors,
    runs: job.runs,
    nodes: getNodes(job.viewer.nodes),
    edges: getEdges(job.viewer.edges),
  };
};
