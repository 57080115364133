import {
  ProjectContainer,
  CustomContainer,
  CustomTable,
  CustomTableBody,
  CustomTableCell,
  CustomTableContainer,
  CustomTableHead,
  CustomTableRow,
  Description,
  DetailsContainer,
  DetailsInnerContainer,
  StyledTableContainer,
  TableRowHeader,
  Title,
} from "../publishApi.styles";

export const ProjectSection = ({ projectName, projectDescription }) => {
  return (
    <ProjectContainer>
      <DetailsContainer>
        <DetailsInnerContainer>
          <Title>Project Selected</Title>
          <Description>
            Please ensure that the project selected on the previous screen is
            the correct one for this API.
          </Description>
        </DetailsInnerContainer>
        <CustomContainer>
          <StyledTableContainer>
            <CustomTableContainer>
              <CustomTable>
                <CustomTableHead>
                  <TableRowHeader>
                    <CustomTableCell>Project Name</CustomTableCell>
                    <CustomTableCell>Description</CustomTableCell>
                  </TableRowHeader>
                </CustomTableHead>
                <CustomTableBody>
                  <CustomTableRow key="1">
                    <CustomTableCell>{projectName}</CustomTableCell>
                    <CustomTableCell>{projectDescription}</CustomTableCell>
                  </CustomTableRow>
                </CustomTableBody>
              </CustomTable>
            </CustomTableContainer>
          </StyledTableContainer>
        </CustomContainer>
      </DetailsContainer>
    </ProjectContainer>
  );
};
