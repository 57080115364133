import React, { FC, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Autocomplete, {
  AutocompleteCloseReason,
} from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import PopperComponent from "./StyledAutocompletePopper";
import {
  StyledInput,
  Button,
  StyledPopper,
} from "./StyledPopperButtonandInputs";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
  classes: any;
  labels: string[];
  filterName: string;
  onApplyClk: (val: string[]) => void;
  clearFilter: boolean;
};

const FilterPopover: FC<Props> = ({
  classes,
  labels,
  filterName,
  onApplyClk,
  clearFilter = false,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [value, setValue] = React.useState<string[]>([]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    if (clearFilter) setValue([]);
  }, [clearFilter]);

  const handleClose = () => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  };

  const handleApply = () => {
    handleClose();
    onApplyClk(value);
  };

  const open = Boolean(anchorEl);
  const id = open ? filterName : undefined;

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "auto",
          maxWidth: 280,
          display: "flex",
          borderRadius: "0.313rem",
          border: "0.094rem solid rgb(224 224 224)",
        }}
      >
        <Button
          disableRipple
          id={"All"}
          aria-describedby={id}
          onClick={handleClick}
          sx={{ fontSize: 15, padding: "0.625rem 0 0.625rem 0.625rem" }}
        >
          <div style={{ whiteSpace: "nowrap", fontWeight: 600 }}>
            {filterName}
          </div>
          <div className={classes.filterText}>{"All"}</div>
          <Box sx={{ margin: "auto", padding: "0.125rem 0.625rem 0 0.313rem" }}>
            {" "}
            <ArrowDropDownIcon
              style={{ width: "1.625rem", height: "1.625rem" }}
            />{" "}
          </Box>
        </Button>
      </Box>
      <StyledPopper
        id={id}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        onResize={undefined}
        onResizeCapture={undefined}
      >
        <ClickAwayListener onClickAway={() => {}}>
          <div>
            <Autocomplete
              open
              multiple
              size={"small"}
              sx={{ backgroundColor: "#F6FDFF" }}
              onClose={(_, reason: AutocompleteCloseReason) => {
                if (reason === "escape") {
                  handleClose();
                }
              }}
              value={value}
              id={"autocomplete"}
              onChange={(event, newValue, reason) => {
                if (
                  event.type === "keydown" &&
                  (event as React.KeyboardEvent).key === "Backspace" &&
                  reason === "removeOption"
                ) {
                  return;
                }
                setValue(newValue);
              }}
              disableCloseOnSelect
              PopperComponent={PopperComponent}
              renderTags={() => null}
              noOptionsText={`No ${filterName}`}
              renderOption={(props, option, { selected }) => (
                <li {...props} className={classes.popoverList}>
                  <Checkbox
                    icon={icon}
                    size="small"
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <span
                    style={{
                      margin: "auto 0",
                      fontSize: "0.938rem",
                      textTransform: "capitalize",
                    }}
                  >
                    {option}
                  </span>
                </li>
              )}
              options={labels}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <StyledInput
                  ref={params.InputProps.ref}
                  inputProps={params.inputProps}
                  autoFocus
                  placeholder={`Filter ${filterName}`}
                />
              )}
            />
            {labels.length !== 0 && (
              <div
                id={"Apply"}
                className={classes.clearAndSelectAllBtn}
                onClick={handleApply}
              >
                {"Apply"}
              </div>
            )}
          </div>
        </ClickAwayListener>
      </StyledPopper>
    </React.Fragment>
  );
};

const styles = {
  clearAndSelectAllBtn: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "center",
    padding: 10,
    backgroundColor: "#0A85C7",
    color: "white",
  },
  filterText: {
    width: "auto",
    maxWidth: "7.813rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    paddingLeft: "0.438rem",
    fontWeight: 700,
  },
  popoverList: {
    backgroundColor: "#F6FDFF",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#dfe9ef",
    },
  },
};

export default withStyles(styles)(FilterPopover);
