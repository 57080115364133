import { FC, useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OverviewInfo from "./OverviewInfo";
import CreateIcon from "@mui/icons-material/Create";
import Users from "./Users";
import Projects from "./Projects";
import PermissionsManagement from "./PermissionsManagement";
import BatchPredictionIcon from "@mui/icons-material/BatchPrediction";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetcRolePermissions,
  fetcRoleUsers,
  fetchRolesOverview,
  editRoleById,
  setShowEditRoleName,
  setShowEditRoleDesc,
} from "../../../../redux/rolesManagement/actions";
import TextField from "@mui/material/TextField";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Acl from "../../subcomponents/Acl";
import { PERMISSION_LIST } from "../../../../constants";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";

const mapStateToProps = (state: AppState) => ({
  isRoleDetailsLoading: state.rolesManagement.isRoleDetailsLoading,
  selectedRole: state.rolesManagement.selectedRole,
  roleData: state.rolesManagement.roleOverviewDetails,
  showEditRoleName: state.rolesManagement.showEditRoleName,
});

const mapDispatchToProps = {
  fetcRolePermissions,
  fetcRoleUsers,
  fetchRolesOverview,
  editRoleById,
  setShowEditRoleName,
  setShowEditRoleDesc,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const RoleDetils: FC<Props> = ({
  showEditRoleName,
  selectedRole,
  roleData,
  isRoleDetailsLoading,
  setShowEditRoleDesc,
  fetchRolesOverview,
  editRoleById,
  setShowEditRoleName,
}) => {
  const [value, setValue] = useState("1");
  const [name, setName] = useState(roleData.name);
  const [error, setError] = useState(false);
  const handleChange = (_, newValue: string) => {
    setValue(newValue);
  };

  const fetchOverview = useCallback(() => {
    fetchRolesOverview(selectedRole.id);
  }, [fetchRolesOverview, selectedRole.id]);

  useEffect(() => {
    fetchOverview();
  }, [fetchOverview]);

  useEffect(() => {
    setName(roleData.name);
  }, [roleData, showEditRoleName]);

  useEffect(() => {
    setShowEditRoleName(false);
  }, [setShowEditRoleName]);

  const handleEditSave = () => {
    let payload = { name: name, description: roleData.description };
    if (name.trim().length === 0) {
      setError(true);
    } else {
      setError(false);
      setShowEditRoleName(false);
      editRoleById(selectedRole.id, payload);
    }
  };

  if (isRoleDetailsLoading) return <LoadingAnimation />;

  return (
    <>
      <div style={{ display: "flex", width: "90%" }}>
        <BatchPredictionIcon
          style={{
            color: "#0A85C7",
            fontSize: 32,
            marginTop: 8,
            marginLeft: 20,
          }}
        />
        {!showEditRoleName && (
          <>
            {" "}
            <Typography
              style={{
                fontStyle: "normal",
                fontWeight: 600,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                fontSize: 34,
                lineHeight: 1.5,
                textAlign: "left",
                color: "#0A85C7",
                marginLeft: 10,
                marginBottom: 0,
                marginTop: "auto",
              }}
            >
              {roleData.name}
            </Typography>
            <Acl permission={PERMISSION_LIST.ROLES_WRITE}>
              <CreateIcon
                id={"create"}
                sx={{
                  "&:hover": { color: "#0A85C7" },
                  cursor: "pointer",
                  color: "#02273C",
                }}
                style={{
                  height: 19,
                  width: 19,
                  marginTop: 8,
                  marginLeft: 10,
                }}
                onClick={() => {
                  setShowEditRoleName(true);
                  setShowEditRoleDesc(false);
                }}
              />
            </Acl>
          </>
        )}
        {showEditRoleName && (
          <div style={{ display: "flex" }}>
            <TextField
              style={{
                width: "100%",
                height: 46,
                background: "#F6FDFF",
                margin: "0.313rem 0 0.313rem 0",
              }}
              id="outlined-helperText"
              background-color="white"
              value={name}
              size="small"
              inputProps={{ maxLength: 40 }}
              error={error}
              helperText={error ? "Valid Value is required." : ""}
              onChange={(e) => setName(e.target.value)}
            />
            <div
              style={{
                display: "flex",
                padding: "0.625rem 0.625rem 0 0.625rem",
                columnGap: "0.625rem",
                alignItems: "end",
              }}
            >
              <CheckIcon
                sx={{
                  "&:hover": { color: "#0A85C7" },
                  width: 34,
                  height: 34,
                  cursor: "pointer",
                }}
                id={"editSave"}
                onClick={handleEditSave}
              />
              <CloseIcon
                id={"close"}
                sx={{
                  "&:hover": { color: "#0A85C7" },
                  width: 34,
                  height: 34,
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowEditRoleName(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
      <Box sx={{ width: "100%", height: 700, typography: "body1" }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              style={{ marginLeft: "0" }}
            >
              <Tab label="Role Overview" value="1" />
              <Tab label="Permissions" value="2" />
              <Tab label="Users" value="3" />
              <Tab label="Projects" value="4" />
            </TabList>
          </Box>
          <TabPanel
            value="1"
            style={{
              height: "46.875rem",
              overflowY: "auto",
            }}
          >
            <OverviewInfo />
          </TabPanel>
          <TabPanel
            value="2"
            style={{
              height: "46.875rem",
              overflowY: "auto",
            }}
          >
            <PermissionsManagement />
          </TabPanel>
          <TabPanel
            value="3"
            style={{
              height: "46.875rem",
              overflowY: "auto",
            }}
          >
            <Users />
          </TabPanel>
          <TabPanel
            value="4"
            style={{
              height: "46.875rem",
              overflowY: "auto",
            }}
          >
            <Projects />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default connector(RoleDetils);
