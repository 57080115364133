import * as React from "react";
import Typography from "@mui/material/Typography";
import "./PowerBI.css";

const PowerBITechnicalSpecifications: React.FC = () => {
  const RenderTechDetails = ({ heading, desc }) => {
    return (
      <div>
        <div className="flex-col">
          <Typography
            variant="h6"
            fontSize="1.25rem"
            sx={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {heading}
          </Typography>
        </div>
        <div className="flex-col padding1rem">
          <Typography
            variant="body1"
            sx={{ color: "#000", lineHeight: "1.5rem" }}
          >
            {desc}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex-col gap4rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Technical Specifications
          </Typography>
          <RenderTechDetails
            heading={"Data Storage Capacity"}
            desc={
              "Microsoft Power BI provides data storage based on your subscription plan, offering a default of 10 GB per user for Pro accounts. Larger storage options are available with Power BI Premium, allowing users to scale their capacity."
            }
          />
          <RenderTechDetails
            heading={"Processing Power"}
            desc={
              "Microsoft Power BI utilizes cloud-based processing to handle large datasets. For on-premises solutions, it can integrate with dedicated server environments, offering flexible processing power based on your organization's needs."
            }
          />
          <RenderTechDetails
            heading={"Data Refresh Limits"}
            desc={
              "Pro users are limited to eight data refreshes per day, while Premium users can schedule up to 48 refreshes. These refreshes ensure data is kept up-to-date, pulling from connected data sources."
            }
          />
          <RenderTechDetails
            heading={"Scalability"}
            desc={
              "Microsoft Power BI is highly scalable, with Power BI Premium offering enterprise-level capacity. Organizations can scale their data processing, storage, and performance capabilities as needed, making it suitable for both small teams and large enterprises."
            }
          />
          <RenderTechDetails
            heading={"Uptime and Performance Guarantees"}
            desc={
              "Microsoft guarantees a 99.9% uptime with Power BI Premium, providing reliable service for critical business operations. Performance is optimized to handle high-volume queries and large datasets."
            }
          />
          <RenderTechDetails
            heading={"Data Security and Backup"}
            desc={
              "Microsoft Power BI adheres to strict security protocols, including data encryption, role-based access, and compliance with major standards like GDPR. Backup and redundancy are managed through Microsoft’s cloud infrastructure to ensure data integrity and recovery."
            }
          />
          <RenderTechDetails
            heading={"Limitations or Restrictions"}
            desc={
              "Free tier users are limited to 1 GB per dataset and can only publish reports to the Power BI Service. Premium users benefit from expanded capacity but still have limitations on maximum model size, depending on the SKU purchased."
            }
          />
        </div>

        <div></div>
      </div>
    </>
  );
};

export default PowerBITechnicalSpecifications;
