import * as React from "react";
import { useState, useEffect } from "react";
import { Table } from "@ssce/ui-shared-library";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";
import { usePrivateCatalogHook } from "../PrivateCatalogHook";
import { ErrorNotification } from "@ssce/ui-shared-library";
import {
  ServiceNameCell,
  TagCell,
  CatalogActionCell,
} from "../../subcomponents/Table";
import { uniqBy } from "lodash";

type PCTableViewProps = {
  searchValue: string;
  catalogs: any;
  handleNavigateTo?: (data: any) => void;
};

const PCTableView: React.FC<PCTableViewProps> = ({
  searchValue,
  catalogs,
  handleNavigateTo,
}) => {
  const { isLoading, hasError } = usePrivateCatalogHook();
  const [filteredItems, setFilteredItems] = useState<any>([]);
  useEffect(() => {
    const filtered = catalogs.filter((item: { serviceName: string }) =>
      item.serviceName.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setFilteredItems(filtered);
  }, [catalogs, searchValue]);

  const allTags = filteredItems.reduce((acc, cv) => {
    return [
      ...acc,
      {
        label: cv.tags.function,
        value: cv.tags.function,
      },
      {
        label: cv.tags.industry,
        value: cv.tags.industry,
      },
      {
        label: cv.tags.serviceType,
        value: cv.tags.serviceType,
      },
    ];
  }, []);
  const tagFilter = uniqBy(allTags, "value");
  hasError && <ErrorNotification />;
  return isLoading ? (
    <LoadingAnimation />
  ) : (
    <Table
      id={"table"}
      data={filteredItems}
      showSearchFilter={false}
      columns={[
        {
          title: "Service",
          dataIndex: "serviceName",
          sorter: true,
          searchByValue: (_, rowData) => rowData.serviceName,
          render: (_, rowData) => (
            <ServiceNameCell
              rowData={rowData}
              handleItemSelect={handleNavigateTo}
            />
          ),
        },
        {
          title: "About",
          dataIndex: "shortDescription",
        },
        {
          title: "Tags",
          dataIndex: "tags",
          filters: tagFilter,
          onFilter: (filterValue, selectedData) => {
            const { function: func, industry, serviceType } = selectedData.tags;
            return (
              filterValue === func ||
              filterValue === industry ||
              filterValue === serviceType
            );
          },
          searchByValue: (tags) => Object.values(tags).toString(),
          render: (tags) => <TagCell tags={tags} />,
        },
        {
          title: "Actions",
          render: (_, rowData) => (
            <CatalogActionCell
              data={rowData}
              handleActionClick={handleNavigateTo}
            />
          ),
        },
      ]}
    />
  );
};
export default PCTableView;
