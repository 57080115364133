import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import RolesTab from "./Roles/RolesTab";
import UserManagementTab from "./UserManagement/UserManagementTab";
import ProjectsTab from "./Projects/ProjectsTab";
import OverviewTab from "./Overview/OverviewTab";
import { connect } from "react-redux";
import { AppState } from "../../../redux/store";
import { fetchUsersMe } from "../../../redux/userManagement/actions";
import { useAclHooks } from "../subcomponents/Acl/hooks";
import { PERMISSION_LIST } from "../../../constants";

const mapStateToProps = (state: AppState) => ({
  overviewData: state.userManagement.overviewData,
});

const mapDispatchToProps = {
  fetchUsersMe,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type SettingsTabProps = {
  handleBreadCrumbNickName: (name: string) => void;
  fetchUsersMe: () => void;
  overviewData: any;
  currentPageTabValue?: string;
  setCurrentPageTabValue: (value: string) => void;
};

const SettingsTab = ({
  fetchUsersMe,
  overviewData,
  handleBreadCrumbNickName,
  currentPageTabValue,
  setCurrentPageTabValue,
}: SettingsTabProps) => {
  const handleChange = (event: any, newValue: string) => {
    setCurrentPageTabValue(newValue);
    handleBreadCrumbNickName(event.target.textContent);
  };

  useEffect(() => {
    fetchUsersMe();
  }, [fetchUsersMe]);

  useEffect(() => {
    if (currentPageTabValue === "1") {
      setCurrentPageTabValue(currentPageTabValue);
    }
  }, [currentPageTabValue, setCurrentPageTabValue]);

  return (
    <div>
      <div style={{ display: "flex", flexWrap: "wrap", padding: "1rem 0" }}>
        <div>
          <Typography
            variant="h1"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            align="left"
            lineHeight={1}
            color="#0468B1"
          >
            {overviewData.organisation}
          </Typography>
          <br></br>
          <Typography
            variant="body1"
            fontSize="1rem"
            fontWeight={400}
            component="div"
            lineHeight={1.5}
            color="#000000"
            style={{ width: "11.25rem", marginTop: "0.125rem" }}
          >
            {overviewData.name}
          </Typography>
        </div>
      </div>
      <Box sx={{ width: "100%", typography: "body1", paddingLeft: "0" }}>
        <TabContext value={currentPageTabValue ?? "1"}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={handleChange}
              aria-label="lab API tabs example"
              style={{ marginLeft: "0" }}
            >
              <Tab label="Overview" value="1" />

              {useAclHooks(PERMISSION_LIST.USERS_READ) && (
                <Tab label="Users" value="2" />
              )}
              {useAclHooks(PERMISSION_LIST.ROLES_READ) && (
                <Tab label="Roles" value="3" />
              )}
              {useAclHooks(PERMISSION_LIST.PROJECTS_READ) && (
                <Tab label="Projects" value="4" />
              )}
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: "1.5rem 0" }}>
            <OverviewTab />
          </TabPanel>

          <TabPanel value="2" style={{ padding: "1.5rem 0" }}>
            <UserManagementTab />
          </TabPanel>

          <TabPanel value="3" style={{ padding: "1.5rem 0" }}>
            <RolesTab />
          </TabPanel>

          <TabPanel value="4" style={{ padding: "1.5rem 0" }}>
            <ProjectsTab />
          </TabPanel>
        </TabContext>
      </Box>
    </div>
  );
};

export default connector(SettingsTab);
