import * as React from "react";
import Typography from "@mui/material/Typography";
import "./AlgoPerformanceReport.css";

const AlgoPerformanceTechnicalSpecifications: React.FC = () => {
  const RenderTechDetails = ({ heading, desc }) => {
    return (
      <div>
        <div className="flex-col">
          <Typography
            variant="h6"
            fontSize="1.25rem"
            sx={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {heading}
          </Typography>
        </div>
        <div className="flex-col padding1rem">
          <Typography
            variant="body1"
            sx={{ color: "#000", lineHeight: "1.5rem" }}
          >
            {desc}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex-col gap4rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Technical Specifications
          </Typography>
          <RenderTechDetails
            heading={"Storage capacity"}
            desc={
              "Specify the amount of storage space provided by the product, whether it's in gigabytes (GB), terabytes (TB), or any other applicable unit of measurement. If there are different storage tiers or options available, outline them and their corresponding capacities."
            }
          />
          <RenderTechDetails
            heading={"Processing power"}
            desc={
              "If the product involves computational capabilities, such as virtual machines or server instances, describe the processing power offered. This can be mentioned in terms of CPU cores, clock speed, or any other relevant metric."
            }
          />
          <RenderTechDetails
            heading={"Bandwidth or data transfer limits"}
            desc={
              "If the product involves data transfer or network usage, provide information about the allocated bandwidth or any limitations on data transfer. Mention if there are any tiered plans or additional costs for exceeding the allocated limits."
            }
          />
          <RenderTechDetails
            heading={"Scalability"}
            desc={
              "If the product offers scalability options, explain how users can scale up or down their capacity based on their needs. This could involve expanding storage, adding more processing power, or adjusting bandwidth allowances."
            }
          />
          <RenderTechDetails
            heading={"Performance guarantees"}
            desc={
              "If there are any performance guarantees associated with the product, such as minimum response times or uptime SLAs (Service Level Agreements), mention them in this section."
            }
          />
          <RenderTechDetails
            heading={"Data redundancy and backup"}
            desc={
              "Highlight any features or mechanisms in place to ensure data redundancy, backup, or disaster recovery. This can include details about automatic backups, replication, and data integrity measures."
            }
          />

          <RenderTechDetails
            heading={"Limitations or restrictions"}
            desc={
              "If there are any specific limitations or restrictions regarding capacity, such as maximum file sizes, maximum number of concurrent users, or any other relevant constraints, clearly outline them."
            }
          />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default AlgoPerformanceTechnicalSpecifications;
