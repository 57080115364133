import styled from "styled-components";

export const UtilContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`;

export const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.938rem;
`;

export const LoadingWrapper = styled.div`
  height: 100vh;
`;