import * as React from "react";
import Typography from "@mui/material/Typography";
import Image001 from "./Images/docImage1.png";
import Image002 from "./Images/docImage2.png";
import Image004 from "./Images/docImage4.png";
import Image005 from "./Images/docImage5.png";
import Image007 from "./Images/docImage7.png";
import Image010 from "./Images/docImage10.png";
import Image011 from "./Images/docImage11.png";
import Image012 from "./Images/docImage12.png";
import Image013 from "./Images/docImage13.png";
import Image014 from "./Images/docImage14.png";
import Image015 from "./Images/docImage15.png";
import computerImage from "./Images/Computer.png";

import { useNavigate } from "react-router-dom";
import Grid from "../subcomponents/Grid/Grid";
import DocumentationCard from "./DocumentationCard";

const GETTING_STARTED_DATA = [
  {
    image: Image012,
    title: "What is SS&C Everywhere?",
    description:
      "SS&C Technologies, a name synonmous with pioneering innovation, introduces SS&C Everywhere.",
    subTitle: "Blog",
    link: "/documentation/ss&c-everywhere",
    openInNew: false,
  },
  {
    image: Image007,
    title: "Managing User Access",
    description:
      "Explore the account creation process to kickstart your journey on SS&C Everywhere!",
    subTitle: "Tutorial",
    openInNew: true,
  },
  {
    image: computerImage,
    title: "What is Workflow Catalog",
    description:
      "Discover how Workflows powerfully streamline business processes through pre-defined automation sequences.",
    subTitle: "Blog",
    openInNew: false,
  },
  {
    image: Image010,
    title: "Introducing the SS&C Everywhere Data Mesh",
    description:
      "Learn more about how to get started with SS&C Everywhere Data Mesh!",
    subTitle: "Blog",
    link: "/documentation/introducing-the-ss&c-everywhere-data-mesh",
    openInNew: false,
  },
  {
    image: Image014,
    title: "Using Data Mesh",
    description:
      "Unlock the power of Data Mesh with our comprehensive tutorial. Learn how to manage, integrate, and utilize data across various platforms.",
    subTitle: "Tutorial",
    openInNew: true,
  },
  {
    image: Image015,
    title: "Managing Private APIs",
    description:
      "Refine data insights and then seamlessly convert them into robust APIs, opening the door for business users.",
    subTitle: "Tutorial",
    openInNew: true,
  },
];

const BROWSE_LIBRARY_DATA = [
  {
    image: Image004,
    title: "Getting Started",
    description:
      "Find information related to the services available in the SS&C Everywhere Catalogs.",
    subTitle: "Doc Types",
    link: "/documentation/getting-started",
  },
  {
    image: Image013,
    title: "Tutorials",
    description:
      "View all available Files for SS&C Everywhere APIs & Services.",
    subTitle: "Tutorial docs",
    link: "/documentation/api-docs",
  },
  {
    image: Image001,
    title: "API Docs",
    description:
      "View all available ReDoc Files for SS&C Everywhere APIs & Services.",
    subTitle: "ReDoc",
    link: "/documentation/api-docs",
  },
  {
    image: Image011,
    title: "Code Samples",
    description: "Browse all available code samples.",
    subTitle: "Dev Resources",
    link: "/documentation/code-samples",
  },
  {
    image: Image002,
    title: "Regulatory",
    description:
      "Legal and industry-specific requirements, rules, standards, and policies.",
    subTitle: "Compliance",
    link: "/documentation/regulatory",
  },
  {
    image: Image005,
    title: "Release Notes",
    description: "View log of changes to all SS&C Everywhere Cloud products.",
    subTitle: "Technical",
    link: "/documentation/release-notes",
  },
];

const DocumentationCards: React.FC = () => {
  const navigate = useNavigate();

  return (
    <>
      <div>
        <Typography
          variant="h1"
          color="text.primary"
          fontWeight={400}
          fontFamily="Roboto"
          fontSize={24}
          marginTop={6}
        >
          Getting Started with SS&C Everywhere
        </Typography>
        <Grid padding="3.125rem 0">
          {GETTING_STARTED_DATA.map((item, index) => (
            <DocumentationCard
              key={index}
              openInNew={item.openInNew}
              backgroundImage={item.image}
              title={item.title}
              description={item.description}
              subTitle={item.subTitle}
              link={item.link}
              onClick={() => {
                item.link ? navigate(item.link) : navigate("/coming-soon");
              }}
            />
          ))}
        </Grid>
      </div>
      <div>
        <Typography
          variant="h1"
          color="text.primary"
          fontWeight={400}
          fontFamily="Roboto"
          fontSize={24}
          marginBottom={3}
        >
          Browse Our Documentation Library by Type
        </Typography>
      </div>
      <Grid>
        {BROWSE_LIBRARY_DATA.map((item, index) => (
          <DocumentationCard
            backgroundImage={item.image}
            key={index}
            title={item.title}
            description={item.description}
            subTitle={item.subTitle}
            link={item.link}
            onClick={() => {
              item.link && navigate(item.link);
            }}
          />
        ))}
      </Grid>
    </>
  );
};

export default DocumentationCards;
