import React, { useEffect, useState } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Button, Table, useValidation } from "@ssce/ui-shared-library";
import {
  EndpointData,
  UseCaseContentType,
  UseCaseTableData,
} from "../tryItNowHook";
import { RequestExample, EditorTabContent } from "../rightSide";
import { USE_CASE_ENDPOINT } from "../editorContentData";
import { OperatorSelect, ValueInput } from "./useCaseEndpointUtils";
import "./leftSide.css";
import { FormHelperText } from "@mui/material";
import usePostEventCollection from "src/hooks/usePostEventCollection";

export const UseCaseEndpoint: React.FC<{
  data?: EndpointData;
  productId: string;
  productName: string;
  tableData: UseCaseTableData[];
  handleTryNowClick: (
    limit: string,
    useCaseType: UseCaseContentType,
    tableData: any,
  ) => void;
}> = ({ handleTryNowClick, data, productId, productName, tableData }) => {
  const [showErrors, setShowErrors] = useState(false);
  const [limitValue, setLimitValue] = useState("100");
  const [useCaseType, setUseCaseType] = useState<UseCaseContentType>("JSON");
  const [editableTableData, setEditableTableData] = useState<
    UseCaseTableData[]
  >([]);

  useEffect(() => {
    setEditableTableData(tableData);
  }, [tableData]);

  const { errors, validateField, clearErrors } = useValidation();
  const getValidators = () => {
    const validators: any[] = [];
    const tableDataColumnNames = tableData.map((data) => data.parameter);
    tableDataColumnNames.forEach((columnName: string) => {
      validators.push({
        [`${columnName}Operand`]: [
          (value) => {
            if (!value) return "Operator is required";
          },
        ],
      });
      validators.push({
        [`${columnName}Value`]: [
          (value) => {
            if (!value) return "Value is required";
          },
        ],
      });
    });
    return validators;
  };
  const validateErrors = (newEditableTableData) => {
    clearErrors();
    let allErrors = [];
    newEditableTableData.forEach((tableData) => {
      const { value: readOnlyValue, operand: readOnlyOperand } =
        tableData.readOnlyOperandValue;
      const { value: editableValue, operand: editableOperand } =
        tableData.editableOperandValue;
      // eslint-disable-next-line
      (!readOnlyValue && !readOnlyOperand) || editableValue
        ? [
            ...validateField(
              `${tableData.parameter}Operand`,
              editableOperand,
              getValidators(),
            ),
          ]
        : [];
      // eslint-disable-next-line
      (tableData.dataType !== "string" &&
        !readOnlyValue &&
        !readOnlyOperand &&
        tableData) ||
      (tableData.dataType !== "string" &&
        editableOperand &&
        readOnlyValue &&
        readOnlyOperand)
        ? [
            ...validateField(
              `${tableData.parameter}Value`,
              editableValue,
              getValidators(),
            ),
          ]
        : [];
    });
    return allErrors;
  };

  const { postEvent } = usePostEventCollection("output-api-click");

  const handleTryItNow = () => {
    setShowErrors(true);
    let allErrors = validateErrors(editableTableData);

    const data = {
      apiName: productName,
      apiId: productId,
      endpoint: "/custom-api",
      endpointParameters: { limitValue, useCaseType, editableTableData },
    };

    postEvent(data);

    if (allErrors.length === 0) {
      handleTryNowClick(limitValue, useCaseType, editableTableData);
    }
  };
  const columns = [
    {
      title: "Parameter",
      dataIndex: "parameter",
    },
    {
      title: "Data Type",
      dataIndex: "dataType",
    },
    {
      title: "Default Value",
      dataIndex: "readOnlyOperandValue",
      render: (cell, row) => {
        if (!cell) return <></>;
        const { value, operand } = row.readOnlyOperandValue;
        return row.optional ? (
          <>
            {operand} {value}
          </>
        ) : (
          "N/A"
        );
      },
    },
    {
      title: "Input (Operator and Value)",
      dataIndex: "editableOperandValue",
      render: (cell, row) => {
        if (!cell) return <></>;
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "0.75rem",
            }}
          >
            <OperatorSelect
              row={row}
              value={row.operand}
              error={errors[`${row.parameter}Operand`]}
              onChange={(e) => {
                const newEditableTableData = editableTableData.map((data) =>
                  data.parameter === row.parameter
                    ? {
                        ...row,
                        editableOperandValue: {
                          ...row.editableOperandValue,
                          operand: e.target.value,
                        },
                      }
                    : data,
                );
                setEditableTableData(newEditableTableData);
                showErrors && validateErrors(newEditableTableData);
              }}
            />
            <ValueInput
              value={row.editableOperandValue.value}
              type={row.dataType === "number" ? "number" : "string"}
              error={errors[`${row.parameter}Value`]}
              onChange={(e) => {
                const newEditableTableData = editableTableData.map((data) =>
                  data.parameter === row.parameter
                    ? {
                        ...row,
                        editableOperandValue: {
                          ...row.editableOperandValue,
                          value: e.target.value,
                        },
                      }
                    : data,
                );
                setEditableTableData(newEditableTableData);
                showErrors && validateErrors(newEditableTableData);
              }}
            />
          </div>
        );
      },
    },
  ];
  return (
    <>
      <div className={"useCaseEndpointContainer"} data-testid={"useCase"}>
        <div className={"title"}>{productName} Endpoint</div>
        <div>
          <p>
            Confirm the intended functionality of the API by interacting with
            the dedicated use case endpoint.
          </p>
          <div className={"parameterContainer"}>
            <span className={"smallText"}>Query Parameters</span>
            <div className="outputTypeContainer">
              <div className="outputTypeLabel">
                <span className={"rotatedT"}>T</span>
                <div className={"boldText"}>Limit</div>
              </div>
              <div>
                <div className={"grayText"}>{`Integer <int64>`}</div>
                <div className={"content"}>
                  Default <span className={"smallGrayBox"}>100000</span>
                </div>
                <div className="content">
                  <div className={"boldText"}>Limit count for pagination</div>
                </div>
              </div>
            </div>
          </div>

          <div className={"parameterContainer"}>
            <div className={"grayBox"} style={{ gap: "1.5rem" }}>
              <div className={"smallTitle"}>Test Use Case Endpoint</div>
              {editableTableData.length > 0 && (
                <Table
                  id={"testUseCaseEndpoint"}
                  data={editableTableData}
                  columns={columns}
                />
              )}
              <Table
                id={"testUseCaseEndpoint"}
                data={[
                  {
                    label: (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <span className={"content"}>Limit</span>{" "}
                      </div>
                    ),
                    value: (
                      <>
                        <OutlinedInput
                          sx={{ width: "100%" }}
                          className={"disabledInput"}
                          placeholder="Default..."
                          type={"number"}
                          value={limitValue}
                          onChange={(event) => {
                            const newValue = event?.target.value;
                            if (
                              newValue === "" ||
                              (Number(newValue) > -1 &&
                                Number.isInteger(Number(newValue)))
                            ) {
                              setLimitValue(newValue);
                            }
                          }}
                        />
                        <FormHelperText>
                          Leave blank for no limit
                        </FormHelperText>
                      </>
                    ),
                  },
                  {
                    label: "Response Type",
                    value: (
                      <Select
                        sx={{ width: "100%" }}
                        onChange={(event) =>
                          setUseCaseType(event?.target.value as any)
                        }
                        value={useCaseType}
                      >
                        <MenuItem value={"JSON"}>JSON</MenuItem>
                        <MenuItem value={"CSV"}>CSV</MenuItem>
                      </Select>
                    ),
                  },
                ]}
                columns={[
                  {
                    dataIndex: "label",
                  },
                  {
                    dataIndex: "value",
                  },
                ]}
              />
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 50,
                  marginTop: 16,
                  width: "100%",
                }}
              >
                <Button onClick={handleTryItNow} id="tryitnow">
                  Try It Now
                </Button>
              </span>

              {data?.isLoading ? (
                <div className={"loadingBanner"}>Loading...</div>
              ) : (
                <>
                  {data?.status && (
                    <div
                      className={
                        data.status === 200 ? "successBanner" : "errorBanner"
                      }
                    >
                      {data.status ?? 500} {data?.statusText ?? "Server Error"}
                    </div>
                  )}
                  {data?.response && (
                    <EditorTabContent
                      isCsv={data.type === "CSV"}
                      content={
                        data.type === "CSV" ? [data.response] : data.response
                      }
                      isJSON={data.type === "JSON"}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <RequestExample
        requestType={"POST"}
        url={`Use Case Endpoint`}
        editorContent={USE_CASE_ENDPOINT(
          productId,
          limitValue,
          useCaseType,
          data?.parameters,
        )}
      />
    </>
  );
};
