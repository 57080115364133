import * as React from "react";
import BluePrismLogo from "../../../assets/BluePrismLogo.svg";
import Everywhere from "../../../assets/Everywhere.svg";
import "./index.scss";

const DWHeader: React.FC = () => {
  return (
    <>
      <section className="BluePrismBanner">
        <div>
          <div className="BluePrismBannerWrapper">
            <div className="LogoWrapper">
              <img
                className="BluePrismLogo"
                src={BluePrismLogo}
                alt="BluePrism"
              ></img>
              <img
                src={Everywhere}
                alt="BluePrismEverywhere"
                className="BluePrismEverywhere"
              ></img>
            </div>
            <h1 className="DigitalWorkerCatalogHeader">
              Digital Worker Catalog
            </h1>
          </div>
        </div>
      </section>
    </>
  );
};
export default DWHeader;
