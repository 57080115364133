import { Button } from "@ssce/ui-shared-library";
import AddIcon from "@mui/icons-material/Add";
import "./Minicard.css";

export const MinicardAddInstance = (props) => {
  return (
    <div className={"minicard-add-instance"}>
      <h1>{props.h1}</h1>
      <h2>{props.h2}</h2>
      <p>{props.p}</p>
      <Button
        variant="default"
        size="medium"
        className={"add-instance-button"}
        onClick={() => {}}
        id="addInstance"
      >
        Add Instance
        <AddIcon />
      </Button>
    </div>
  );
};
