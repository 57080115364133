import { Database, DoubleCheck, HourGlass } from "./icons";
import { MarkerType } from "reactflow";
export const getAlgoPerformanceNodes = (offsetWidth) => [
  {
    id: "startPerformanceWorkflow",
    type: "node",
    position: { x: offsetWidth + 400, y: 20 },
    data: {
      Icon: Database,
      title: "Start Performance Workflow",
      subTitle: "",
      runTime: 0,
      status: "success",
    },
  },
  {
    id: "loadHoldingData",
    type: "node",
    position: { x: offsetWidth + 600, y: 220 },
    data: {
      Icon: Database,
      title: "Load Holding Data",
      subTitle: "",
      runTime: 0,
      status: "success",
    },
  },
  {
    id: "loadTransactionData",
    type: "node",
    position: { x: offsetWidth + 200, y: 220 },
    data: {
      Icon: Database,
      title: "Load Transaction Data",
      subTitle: "",
      runTime: 0,
      status: "success",
    },
  },
  {
    id: "validation",
    type: "node",
    position: { x: offsetWidth + 400, y: 420 },
    data: {
      Icon: DoubleCheck,
      title: "Validation",
      subTitle: "",
      runTime: 0,
      status: "success",
    },
  },
  {
    id: "createAwaPerformanceInputData",
    type: "node",
    position: { x: offsetWidth + 400, y: 620 },
    data: {
      Icon: Database,
      title: "Create AWA Performance Input Data",
      subTitle: "",
      runTime: 0,
      status: "success",
    },
  },
  {
    id: "generatePerformanceReports",
    type: "node",
    position: { x: offsetWidth + 400, y: 820 },
    data: {
      Icon: HourGlass,
      title: "Generate Performance Reports",
      subTitle: "",
      runTime: 0,
      status: "success",
    },
  },
  {
    id: "finishPerformanceWorkflow",
    type: "node",
    position: { x: offsetWidth + 400, y: 1000 },
    data: {
      Icon: DoubleCheck,
      title: "Finish Performance Workflow",
      subTitle: "",
      runTime: "0",
      status: "success",
    },
  },
];
export const getAlgoPerformanceEdges = () => [
  {
    id: "startPerformanceWorkflow-loadHoldingData",
    source: "startPerformanceWorkflow",
    target: "loadHoldingData",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "startPerformanceWorkflow-loadTransactionData",
    source: "startPerformanceWorkflow",
    target: "loadTransactionData",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "loadTransactionData-validation",
    source: "loadTransactionData",
    target: "validation",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "loadHoldingData-validation",
    source: "loadHoldingData",
    target: "validation",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "validation-createAwaPerformanceInputData",
    source: "validation",
    target: "createAwaPerformanceInputData",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "createAwaPerformanceInputData-generatePerformanceReports",
    source: "createAwaPerformanceInputData",
    target: "generatePerformanceReports",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
  {
    id: "generatePerformanceReports-finishPerformanceWorkflow",
    source: "generatePerformanceReports",
    target: "finishPerformanceWorkflow",
    markerEnd: {
      type: MarkerType.ArrowClosed,
      color: "#75909F",
    },
    style: { stroke: "#75909F" },
  },
];
