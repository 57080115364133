import React, { useEffect } from "react";
import { MyServicesContent } from "./myServicesContent";
import { ErrorNotification } from "@ssce/ui-shared-library";
import { LoadingAnimation } from "../subcomponents/LoadingAnimation";
import { PageHeader } from "../../../global.styles";

import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../redux/store";
import { fetchMyservicesList } from "../../../redux/myServices/actions";

const mapStateToProps = (state: AppState) => ({
  items: state.myservices.items,
  isLoading: state.myservices.isLoading,
  hasError: false,
});

const mapDispatchToProps = {
  fetchMyservicesList,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

interface MyServicesProps extends Props {
  handleBreadCrumbNickName?: (name: string) => void;
}

const MyServices: React.FC<MyServicesProps> = ({
  fetchMyservicesList,
  items,
  isLoading,
  hasError,
  handleBreadCrumbNickName,
}) => {
  useEffect(() => {
    fetchMyservicesList();
  }, [fetchMyservicesList]);

  if (isLoading) return <LoadingAnimation />;
  return (
    <>
      {hasError && <ErrorNotification />}
      <PageHeader>My Services</PageHeader>
      <MyServicesContent
        items={items}
        handleBreadCrumbNickName={handleBreadCrumbNickName}
      />
    </>
  );
};

export default connector(MyServices);
