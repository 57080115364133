import {
  WorkflowHeaderContainer,
  Label,
  Value,
  OverviewTitle,
  OverviewContainer,
  Header,
} from "./workflowHeader.styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import moment from "moment";

export const WorkflowHeader = ({
  handleSelectedRun,
  runName,
  isAlgoRiskReport,
  workflowLastRun,
}) => {
  const schedule = sessionStorage.getItem("workflowSchedule") ?? "N/A";
  const createLabel = (label, value) => {
    return (
      <>
        <Label>{label}</Label>
        <Value isValid={value !== "N/A"}>{value}</Value>
      </>
    );
  };
  const storedWorkflowLastRun: any = sessionStorage.getItem("workflowLastRun");
  return (
    <WorkflowHeaderContainer>
      <Header>{runName ?? "New Workflow"}</Header>
      <OverviewContainer>
        <OverviewTitle>Overview</OverviewTitle>
        {createLabel(
          "Workflow Type:",
          isAlgoRiskReport ? "SS&C Risk Workflow" : "SS&C Performance Workflow",
        )}
        {createLabel(
          "Workflow Created:",
          storedWorkflowLastRun
            ? moment(storedWorkflowLastRun).subtract(5, "days").format("lll")
            : moment().format("lll"),
        )}
        {createLabel("Schedule:", schedule)}
        {createLabel(
          "Selected Run:",
          workflowLastRun ? (
            <Select
              sx={{ width: "100%" }}
              value={workflowLastRun}
              onChange={(e) => {
                handleSelectedRun(e.target.value);
              }}
            >
              <MenuItem value={storedWorkflowLastRun}>
                {moment(storedWorkflowLastRun).format("lll")}
              </MenuItem>
              <MenuItem
                value={moment(storedWorkflowLastRun)
                  .subtract(2, "days")
                  .format("lll")}
              >
                {moment(storedWorkflowLastRun)
                  .subtract(2, "days")
                  .format("lll")}
              </MenuItem>
            </Select>
          ) : (
            "N/A"
          ),
        )}
      </OverviewContainer>
    </WorkflowHeaderContainer>
  );
};
