import * as React from "react";
import { useState } from "react";
import { Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Tarvos.css";
import { useNavigate } from "react-router-dom";
import TarvosOverView from "./TarvosOverView";
import TarvosTechnicalSpecifications from "./TarvosTechnicalSpecifications";
import TarvosPlansAndFeatures from "./TarvosPlansAndFeatures";
import TarvosImage from "../../../../assets/Tarvos.png";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

const Tarvos: React.FC = () => {
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={TarvosImage}
        title={"Tarvos LLM-aaS"}
        contents={
          "Tarvos is a cutting-edge Large Language Model (LLM) as a service, hosted securely within your internal infrastructure, giving you full control over your data. With Tarvos, your data never leaves your environment, ensuring the highest standards of privacy while unlocking insights, automating processes, and enhancing decision-making."
        }
        tags={[
          {
            label: "BI",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() => navigate("/public-catalog/Tarvos/provision")}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Technical Specifications" value="3" />
            <Tab label="Documentation" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <TarvosOverView />
        </TabPanel>
        <TabPanel value="2" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <TarvosPlansAndFeatures />
        </TabPanel>
        <TabPanel value="3" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <TarvosTechnicalSpecifications />
        </TabPanel>
        <TabPanel
          value="4"
          sx={{ padding: "4.5rem 0 0 0", display: "flex" }}
        ></TabPanel>
      </TabContext>
      <div className="flex-row  justifyContent-center">
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() => navigate("/public-catalog/Tarvos/provision")}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </div>
    </>
  );
};
export default Tarvos;
