import { FC } from "react";
import { UserAccessManagement } from "./userAccessManagement";
import { TagsSection } from "./tagsSection";
import { LogoSection } from "./logoSection";
import { DescriptionInput } from "./descriptionInput";
import { NameInputApi } from "./nameInputApi";
import { ActiveStepDiv } from "../publishApi.styles";
import { Projects } from "src/common/dataTypes";

export type PublishApiKeys =
  | "description"
  | "functionTag"
  | "industryTag"
  | "serviceTypeTag"
  | "logoName"
  | "projectID"
  | "imageList";

type Props = {
  description: string;
  functionTag: string;
  industryTag: string;
  serviceTypeTag: string;
  logoName: string;
  uploadImage: string;
  projectID: string;
  projects: Projects;
  serviceName: string;
  imageList: ImageList;
  projectError: boolean;
  logoError: boolean;
  setLogoError: any;
  setProjectError: any;
  handleChange: (event: any) => void;
  fetchProjectDetails: () => void;
  addProjectDetails: (projectId: any) => void;
  onFormChange: (key: PublishApiKeys, value: any) => void;
};

type ImageList = {
  ssncLogo: string;
  dataMeshLogo: string;
};
const PublishApiDetails: FC<Props> = ({
  description,
  functionTag,
  industryTag,
  serviceTypeTag,
  logoName,
  uploadImage,
  projectID,
  projects,
  imageList,
  fetchProjectDetails,
  addProjectDetails,
  serviceName,
  onFormChange,
  handleChange,
  projectError,
  logoError,
  setLogoError,
  setProjectError,
}) => {
  return (
    <>
      <ActiveStepDiv>
        <NameInputApi serviceName={serviceName} />
        <DescriptionInput
          description={description}
          onFormChange={onFormChange}
        />
        <LogoSection
          logoName={logoName}
          logoError={logoError}
          setLogoError={setLogoError}
          imageList={imageList}
          uploadImage={uploadImage}
          onFormChange={onFormChange}
          handleChange={handleChange}
        />
        <TagsSection
          functionTag={functionTag}
          industryTag={industryTag}
          serviceTypeTag={serviceTypeTag}
          onFormChange={onFormChange}
        />
        <UserAccessManagement
          projectID={projectID}
          projects={projects}
          projectError={projectError}
          setProjectError={setProjectError}
          onFormChange={onFormChange}
          fetchProjectDetails={fetchProjectDetails}
          addProjectDetails={addProjectDetails}
        />
      </ActiveStepDiv>
    </>
  );
};

export default PublishApiDetails;
