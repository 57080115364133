import { useState, useEffect, FC } from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button } from "@ssce/ui-shared-library";
import Accordion from "./AccordionUsers";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmModal from "../ConfirmModal";
import { UserData } from "../../../../common/dataTypes";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetcRoleUsers,
  updateRoleUsers,
  deleteRoleUsers,
} from "../../../../redux/rolesManagement/actions";
import { fetchUsers } from "../../../../redux/userManagement/actions";
import Acl from "../../subcomponents/Acl";
import { useAclHooks } from "../../subcomponents/Acl/hooks";
import { PERMISSION_LIST } from "../../../../constants";

const mapStateToProps = (state: AppState) => ({
  selectedRole: state.rolesManagement.selectedRole,
  roleUsers: state.rolesManagement.roleUsers,
  users: state.userManagement.usersList,
});

const mapDispatchToProps = {
  fetcRoleUsers,
  fetchUsers,
  updateRoleUsers,
  deleteRoleUsers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const Users: FC<Props> = ({
  selectedRole,
  roleUsers,
  users,
  fetcRoleUsers,
  fetchUsers,
  updateRoleUsers,
  deleteRoleUsers,
}) => {
  const [selectedUser, setSelectedUser] = useState<UserData[]>([]);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<UserData>({
    firstName: "",
    lastName: "",
    id: "",
    email: "",
    createdTimestamp: 0,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDeleteClick = () => {
    handleClose();
    deleteRoleUsers(selectedRole.id, [{ id: deleteData.id }]);
  };

  const handleApplyClick = () => {
    let payload = selectedUser.map((item) => {
      return { id: item.id };
    });
    setSelectedUser([]);
    updateRoleUsers(selectedRole.id, payload);
  };

  useEffect(() => {
    fetcRoleUsers(selectedRole.id);
    fetchUsers();
  }, [fetcRoleUsers, fetchUsers, selectedRole.id]);

  const allowUserDelete = useAclHooks(PERMISSION_LIST.ROLES_EXECUTE);
  const renderUsers = () => {
    return roleUsers.map((item) => {
      return (
        <Accordion
          key={item.firstName}
          email={item.email}
          name={`${item.firstName} ${item.lastName}`}
          iconHeader={allowUserDelete && <DeleteForeverIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            handleOpen();
            setDeleteData(item);
          }}
        >
          <Typography style={{ color: "#0A85C7", fontSize: 12, marginLeft: 2 }}>
            Current Roles
          </Typography>
          <div
            style={{
              color: "#02273C",
              fontWeight: 400,
              display: "grid",
              fontSize: 14,
              marginLeft: 2,
            }}
          >
            {item.roles?.map((r, i, arr) => (
              <span key={r.name}>
                {" "}
                {r.name} {arr.length - 1 !== i ? "," : ""}{" "}
              </span>
            ))}
          </div>
          <br></br>
        </Accordion>
      );
    });
  };

  const renderSelectedUsers = () => {
    return selectedUser.map((item) => {
      return (
        <Accordion
          key={item.id}
          halfWidth={false}
          email={item.email}
          name={`${item.firstName} ${item.lastName}`}
          iconHeader={<ClearIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            setSelectedUser([...selectedUser.filter((r) => item.id !== r.id)]);
          }}
        >
          <Typography style={{ color: "#0A85C7", fontSize: 12, marginLeft: 2 }}>
            Current Roles
          </Typography>
          <div
            style={{
              color: "#02273C",
              fontWeight: 400,
              fontSize: 14,
              marginLeft: 2,
            }}
          >
            {item.roles?.map((r, i, arr) => (
              <span key={r.id}>
                {r.name} {arr.length - 1 !== i ? "," : ""}{" "}
              </span>
            ))}
          </div>
          <br></br>
        </Accordion>
      );
    });
  };

  return (
    <>
      <div>
        <h1
          style={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 20,
            lineHeight: 0,
            textAlign: "left",
            color: "#02273C",
          }}
        >
          Users
        </h1>
        <br></br>
        <div
          style={{ display: "flex", gap: "0.125rem 0.5rem", flexWrap: "wrap" }}
        >
          {renderUsers()}
        </div>
        <br></br>
      </div>
      <ConfirmModal
        open={open}
        title={"Please Confirm"}
        onRequestCancel={handleClose}
        onRequestConfirm={handleDeleteClick}
      >
        <Typography
          style={{
            margin: 2,
            fontSize: 20,
            fontWeight: 500,
            lineHeight: "1.5rem",
          }}
        >
          You are{" "}
          <span style={{ textDecoration: "underline", fontWeight: 900 }}>
            removing{" "}
          </span>{" "}
          the{" "}
          <span style={{ fontWeight: 700, color: "#0468B1" }}>
            {deleteData.firstName} {deleteData.lastName}
          </span>{" "}
          from the {selectedRole.name} role. You can add {deleteData.firstName}{" "}
          {deleteData.lastName} back to this role if you change your mind.{" "}
          <div style={{ fontWeight: 900 }}>
            Are you sure you want to continue?
          </div>
        </Typography>
      </ConfirmModal>
      <Acl permission={PERMISSION_LIST.ROLES_WRITE}>
        <div style={{ marginBottom: "10%" }}>
          <h1
            style={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 20,
              lineHeight: 0,
              textAlign: "left",
              color: "#02273C",
            }}
          >
            Assign Users
          </h1>
          <br></br>
          <div style={{ border: "0.063rem solid #CBD9E1", borderRadius: 4 }}>
            <Stack
              spacing={2}
              sx={{
                margin: 2,
                marginBottom: "0.188rem",
                width: 570,
                backgroundColor: "#F6FDFF",
              }}
            >
              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="medium"
                value={selectedUser}
                sx={{
                  "& .MuiChip-root": {
                    backgroundColor: "#B3D9EE",
                    color: "#075E8D",
                  },
                  "& .MuiChip-root .MuiChip-deleteIcon": { color: "#0A85C7" },
                }}
                onChange={(_, values) => {
                  setSelectedUser(values);
                }}
                options={users}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) =>
                  option.firstName + " " + option.lastName
                }
                renderInput={(params) => (
                  <TextField {...params} label="Users" placeholder="" />
                )}
              />
            </Stack>
            <p
              style={{
                marginLeft: 20,
                marginTop: 0,
                color: "#577687",
                backgroundColor: "#EDF4F8",
                fontSize: 12,
              }}
            >
              Please use the dropdown box above or type in a Users name to
              select it.
            </p>
            <p
              style={{
                margin: "1rem 1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 18,
                textAlign: "left",
                color: "#02273C",
              }}
            >
              Users Addition Details
            </p>
            {renderSelectedUsers()}
            <div
              style={{
                width: "85%",
                marginLeft: 20,
                marginBottom: 20,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {" "}
              <br></br>
              <Button
                id={"apply"}
                onClick={handleApplyClick}
                variant="default"
                size="medium"
              >
                Apply changes
              </Button>
              <Button
                id={"cancel"}
                onClick={() => setSelectedUser([])}
                variant="text"
                size="medium"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Acl>
    </>
  );
};

export default connector(Users);
