import styled from "styled-components";
import { Snackbar } from "@mui/material";

export const AnchorList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.75rem;
`;

export const Anchor = styled.a`
  color: var(--neutralBlack);
  text-decoration: none;
  padding: 1rem 1rem 1rem 0;

  border-bottom: 2px solid var(--neutral300);

  &:active {
    background-color: var(--neutral300);
  }

  &:last-child {
    border-bottom: none;
  }
`;

export const EmailListWrapper = styled.div`
  padding-top: 0.5rem;
  margin-left: -0.5rem;
`;

export const EmailHeading = styled.div`
  font-size: 12px
  font-weight: 500;
`;

export const SubAnchor = styled.a`
  color: var(--neutral800);
  margin: 0 10px;
  text-decoration: none;
  padding: 0.2rem;
  border-bottom: 2px solid var(--neutral300);

  &:hover {
    background-color: var(--neutral300);
  }
`;

export const OnThisPageHelperWrapper = styled.div`
  @media only screen and (max-width: 1240px) {
    display: none;
  }

  position: fixed;
  max-height: 70vh;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid var(--neutralBlack);
  border-radius: 5px;
  z-index: 2;
  background-color: var(--neutral50);
  width: 16.25rem;
  position: fixed;
  top: 8.25rem;
  right: 3.125rem;
  box-shadow: var(--boxShadow);
`;

export const Button = styled.button`
  border: 4px solid var(--primary);
  padding: 4px 6px;
  cursor: pointer;
  border-radius: 4px;
  background-color: white;

  &:active {
    transform: scale(0.9);
  }

  svg {
    fill: var(--primary);
  }
`;
export const ChipList = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  gap: 0.5rem;
`;

export const Tag = styled.div<{ isValid: boolean }>`
  background: ${(props) =>
    props.isValid ? "var(--neutral300)" : "var(--alert100)"};
  border-radius: 1rem;
  padding: 0 1rem;
  line-height: 2;
  display: flex;

  svg {
    fill: ${(props) =>
      props.isValid ? "var(--neutral700)" : "var(--alert600)"};
    vertical-align: middle;
    padding-left: 0.5rem;
    cursor: pointer;
    &:hover {
      transform: scale(1.1);
    }
  }
`;

export const StyledSnackbar = styled(Snackbar)<{ position: boolean }>`
  padding-bottom: ${(props) => (props.position ? "6rem" : "0")};
`;

export const RedChipList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  padding-top: 1rem;
`;

export const RedChip = styled(Tag)`
  border: 1px solid #5f2120;
  background: none;
  width: fit-content;
`;

export const EmailBody = styled.div`
  padding-bottom: 1.5rem;
`;
