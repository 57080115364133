import { useEffect, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { USER_ENDPOINT_BASE_URL, baseUrl } from "../../../../constants";
import { useValidation } from "@ssce/ui-shared-library";

export type Project = {
  projectId: string;
  id: string;
  name: string;
  description: string;
  createdTimestamp: number;
  instances: any[];
};

export type Instance = {
  instanceName: string;
  projectId: string;
  catalogId: string;
  region: string;
  applicationName: string;
};

export type FundListData = {
  id: number;
  name: string;
  fundType: string;
  netAssetValue: string;
  annualFee: string;
  action: string;
};

export type CompanyListData = {
  id: number;
  name: string;
  sector: string;
  headquarters: string;
};
function createCompanyData(
  id: number,
  name: string,
  sector: string,
  headquarters: string,
): CompanyListData {
  return {
    id,
    name,
    sector,
    headquarters,
  };
}

function createData(
  id: number,
  name: string,
  fundType: string,
  netAssetValue: string,
  annualFee: string,
  action: string,
): FundListData {
  return {
    id,
    name,
    fundType,
    netAssetValue,
    annualFee,
    action,
  };
}

export const fundList = [
  createData(1, "Global Equity Fund", "Equity", " ", "", ""),
  createData(2, "Global Bond Fund", "Bond", " ", "", ""),
  createData(3, "US Treasury Fund", "Bond", "", "", ""),
  createData(4, "Global Sovereign Bond Fund", "Bond", "", "", ""),
  createData(5, "US Large-Cap Equity Fund", "Equity", "", "", ""),
  createData(6, "US Small-Cap Equity Fund", "Equity", "", "", ""),
  createData(7, "International Equity Fund", "Equity", "", "", ""),
  createData(8, "High-Yield European Bond Fund", "Bond", "", "", ""),
  createData(9, "Investment-Grad European Bond Fund", "Bond", "", "", ""),
  createData(10, "U.S. Municipal Bond Fund", "Muni Bond", "", "", ""),
];
export const companyList = [
  createCompanyData(1, "Saudi Aramco", "Energy", "SA"),
  createCompanyData(2, "Samsung Electronics", "Technology", "KR"),
  createCompanyData(3, "Marriot International", "Hospitality", "US"),
  createCompanyData(4, "IKEA", "Retail", "NL"),
  createCompanyData(5, "Brookfield Corporation", "Financial", "CA"),
];

const INITIAL_PROJECT = [
  {
    projectId: "",
    id: "",
    name: "",
    description: "",
    createdTimestamp: 0,
    instances: [],
  },
];

export const useProvisionDataMeshHooks = (): {
  projects: Project[];
  hasError: boolean;
  catalogId: string;
  instanceName: string;
  projectId: string;
  hasInstanceNameError: string;
  hasProjectIdError: boolean;
  isLoading: boolean;
  setInstanceName: (value: string) => void;
  setProjectId: (value: string) => void;
  setIsLoading: (value: boolean) => void;
  createInstances: (
    instance: Instance,
  ) => Promise<AxiosResponse<any, any> | undefined>;
  fetchCatalogID: (application_name: string) => void;
} => {
  const [hasError, setHasError] = useState(false);
  const [catalogId, setCatalogId] = useState("");
  const [projects, setProjects] = useState(INITIAL_PROJECT);

  const [instanceName, setInstanceName] = useState("");
  const [projectId, setProjectId] = useState("");
  const [hasInstanceNameError, setHasInstanceNameError] = useState("");
  const [hasProjectIdError, setHasProjectIdError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { validateForm, errors } = useValidation();
  const regex = /^[a-zA-Z 0-9_-]*$/;
  const validation = [
    {
      instanceName: [
        (value) => {
          if (!value || value.trim().length === 0) {
            return "Instance Name is required.";
          }
          if (!value?.match(regex)) {
            return "The name should only include letters, numbers, underscores, and dashes.";
          }
        },
      ],
      projectId: [
        (value) => {
          if (!value) {
            return "Project is required.";
          }
        },
      ],
    },
  ];

  const fetchCatalogID = (serviceName: string) => {
    const applicationName =
      serviceName === "data_mesh_platform" ? "&application_name=data_mesh" : "";
    axios
      .get(
        `${baseUrl}/api/mktplc-catalog-api/v0/public/services/${serviceName}?context=setStatus&version=1.0${applicationName}`,
      )
      .then((response) => {
        setHasError(false);
        setCatalogId(response.data?.catalogId);
      })
      .catch(() => setHasError(true));
  };

  const fetchProjectDetails = () => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/projects`)
      .then((res) => {
        setProjects(res.data.projects);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
      });
  };
  const createInstances = async ({
    instanceName,
    projectId,
    catalogId,
    region,
    applicationName,
  }: Instance) => {
    setHasInstanceNameError("");
    setHasProjectIdError(false);
    const isValid = validateForm(
      { instanceName: instanceName, projectId: projectId },
      validation,
    );

    if (isValid)
      try {
        setHasError(false);
        setIsLoading(true);
        const service_name =
          applicationName === "data_mesh"
            ? "data_mesh_platform"
            : applicationName;
        return await axios.post(
          `${baseUrl}/api/mktplc-catalog-api/v0/public/services/provision/${service_name}`,
          {
            instanceName,
            version: "1.0",
            projectId,
            catalogId,
            region,
            applicationName,
            settings: {},
          },
        );
      } catch {
        setIsLoading(false);
        setHasError(true);
      }
  };

  useEffect(() => {
    fetchProjectDetails();
  }, []);

  useEffect(() => {
    errors?.instanceName?.length > 0 &&
      setHasInstanceNameError(errors?.instanceName[0]);
    errors?.projectId?.length > 0 && setHasProjectIdError(true);
  }, [errors]);

  return {
    projects,
    hasError,
    catalogId,
    instanceName,
    projectId,
    hasInstanceNameError,
    hasProjectIdError,
    isLoading,
    createInstances,
    setIsLoading,
    fetchCatalogID,
    setInstanceName,
    setProjectId,
  };
};
