import { Reducer } from "redux";
import { RolesManagementAction, RoleManagementActionType } from "./actions";
import { createDefaultState, RolesManagementState } from "./state";

type RolesManagementReducer = Reducer<
  RolesManagementState,
  RolesManagementAction
>;
const reducer: RolesManagementReducer = (
  state = createDefaultState(),
  action,
) => {
  switch (action.type) {
    case RoleManagementActionType.SET_ROLE:
      return { ...state, roleList: action.payload };
    case RoleManagementActionType.SET_ROLE_LOADING:
      return { ...state, isLoading: action.payload };
    case RoleManagementActionType.SELECT_ROLE:
      return { ...state, selectedRole: action.payload };
    case RoleManagementActionType.SET_ROLE_DETAILS:
      return { ...state, roleOverviewDetails: action.payload };
    case RoleManagementActionType.SET_ROLE_DETAILS_LOADING:
      return { ...state, isRoleDetailsLoading: action.payload };
    case RoleManagementActionType.SHOW_HIDE_ROLE_DETAILS:
      return { ...state, showDetails: action.payload };
    case RoleManagementActionType.SET_ROLE_PERMISSIONS:
      return { ...state, rolePermissions: action.payload };
    case RoleManagementActionType.SET_ROLE_USERS:
      return { ...state, roleUsers: action.payload };
    case RoleManagementActionType.SET_ROLE_PROJECTS:
      return { ...state, roleProjects: action.payload };
    case RoleManagementActionType.SET_PERMISSIONS:
      return { ...state, permissionsList: action.payload };
    case RoleManagementActionType.SET_PROJECTS:
      return { ...state, projectsList: action.payload };
    case RoleManagementActionType.SHOW_HIDE_CREATE_ROLE:
      return { ...state, showCreateRole: action.payload };
    case RoleManagementActionType.SHOW_HIDE_EDIT_ROLE_NAME:
      return { ...state, showEditRoleName: action.payload };
    case RoleManagementActionType.SHOW_HIDE_EDIT_ROLE_DESCRIPTION:
      return { ...state, showEditRoleDesc: action.payload };
    case RoleManagementActionType.SHOW_HIDE_DUPLICATE_ROLE:
      return { ...state, showDuplicateRole: action.payload };
    default: {
      return state;
    }
  }
};

export default reducer;
