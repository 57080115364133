import { Carousel } from "react-responsive-carousel";
import "./Catalog.css";
import NewDataConnection from "../../../../assets/DatameshCatalog/NewDataConnection.png";
import ConnectionAddJob from "../../../../assets/DatameshCatalog/ConnectionAddJob.png";
import CreateDataProduct from "../../../../assets/DatameshCatalog/CreateDataProduct.png";
import DataSourceQuery from "../../../../assets/DatameshCatalog/DataSourceQuery.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default function DataMeshServiceCarousel() {
  return (
    <>
      <Carousel>
        <div className="algorisk-slide-holder">
          <img
            alt={"NewDataConnection"}
            className="algorisk-slide-image"
            src={NewDataConnection}
          />
        </div>
        <div className="algorisk-slide-holder">
          <img
            alt="ConnectionAddJob"
            id={"connectionAddJob"}
            className="algorisk-slide-image"
            src={ConnectionAddJob}
          />
        </div>
        <div className="algorisk-slide-holder">
          <img
            alt="CreateDataProduct"
            id={"createDataProduct"}
            className="algorisk-slide-image"
            src={CreateDataProduct}
          />
        </div>
        <div className="algorisk-slide-holder">
          <img
            alt="DataSourceQuery"
            id={"dataSourceQuery"}
            className="algorisk-slide-image"
            src={DataSourceQuery}
          />
        </div>
      </Carousel>
    </>
  );
}
