import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";
import Region from "../Regions";
import { useNavigate } from "react-router-dom";
import CardView from "../CardView";
import NorthAmerica from "../../../../assets/NorthAmerica.png";
import CatalogBenefits from "../CatalogBenefits";
import SpeedIcon from "@mui/icons-material/Speed";
import CodeIcon from "@mui/icons-material/CodeOutlined";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUserOutlined";
import ExtensionIcon from "@mui/icons-material/ExtensionOutlined";
import CatalogKeyFeatures from "../CatalogKeyFeatures";
import PostgresqlPricing from "./PostgresqlPricing";
import CatalogSubHeading from "../CatalogSubHeading";
import Grid from "@mui/material/Grid";

const CARD_DATA = [
  {
    id: 1,
    logo: "/api/assets/datamesh.png",
    serviceName: "SS&C Everywhere Data Mesh",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "The SS&C Everywhere Data Mesh platform provides an interface for users to centralize their data to make it more accessible to the rapidly changing needs of a user’s organization.",
    tags: {
      function: "Cloud Platform",
      industry: "Cross-Industry",
      serviceType: "Data Service",
    },
  },
  {
    id: 2,
    logo: "/api/assets/algoriskreport.png",
    serviceName: "SS&C Algorithmics Risk Reports",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "Generate world-class risk reports leveraging award and access powerful reporting dashboards preintegrated with your data.",
    tags: {
      function: "Risk Management",
      industry: "Cross-Industry",
      serviceType: "Analytics Service",
    },
  },
];

const PostgresqlOverView: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateTo = (name: string) => {
    if (name === "SS&C Everywhere Data Mesh") {
      return navigate("/public-catalog/data-mesh");
    }
    if (name === "SS&C Algorithmics Risk Reports") {
      return navigate("/public-catalog/ss&c-algorithmics-risk-reports");
    }
  };

  return (
    <>
      <div className="flex-col gap3rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Overview
          </Typography>
          <div>
            <Typography
              variant="h6"
              fontSize="1.25rem"
              sx={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              About This Service
            </Typography>
            <Typography
              variant="body1"
              component={"div"}
              sx={{ color: "#000", lineHeight: "1.5rem" }}
            >
              <div style={{ display: "flex", maxWidth: "90%" }}>
                <ul>
                  <li>
                    {" "}
                    PostgreSQL supports diverse data types, including JSON,
                    arrays, and custom types.{" "}
                  </li>
                  <li>
                    {" "}
                    It ensures data integrity with full ACID compliance for
                    reliable transactions.{" "}
                  </li>
                  <li>
                    {" "}
                    PostgreSQL executes complex queries like subqueries, joins,
                    and window functions.{" "}
                  </li>
                  <li>
                    {" "}
                    The database is highly extensible, allowing custom data
                    types and functions.{" "}
                  </li>
                  <li>
                    {" "}
                    It uses Multi-Version Concurrency Control (MVCC) for
                    efficient transaction handling.{" "}
                  </li>
                  <li>
                    {" "}
                    PostgreSQL offers strong security features, including SSL
                    and role-based permissions.{" "}
                  </li>
                  <li>
                    {" "}
                    It runs on all major operating systems, offering
                    cross-platform flexibility.{" "}
                  </li>
                  <li>
                    {" "}
                    PostgreSQL has a vibrant community and rich ecosystem for
                    seamless integration and scaling.{" "}
                  </li>
                </ul>
              </div>
            </Typography>
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <CodeIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Flexibility",
              description:
                "PostgreSQL supports a wide range of data types and allows for the creation of custom data types, functions, and operators, making it highly adaptable to different applications and use cases.",
            },
            {
              icon: (
                <VerifiedUserIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Reliability",
              description:
                "With full ACID compliance, PostgreSQL ensures data integrity and consistency, making it a reliable choice for handling critical transactions and complex workloads.",
            },
            {
              icon: (
                <ExtensionIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Extensibility",
              description:
                "PostgreSQL’s open architecture allows for extensive customization and integration with various extensions and tools, enabling developers to enhance its functionality to meet specific needs.",
            },
            {
              icon: (
                <SpeedIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Performance",
              description:
                "PostgreSQL offers advanced performance optimization features such as indexing, partitioning, and parallel query execution, ensuring efficient handling of large datasets and high-throughput operations.",
            },
          ]}
        />

        <CatalogKeyFeatures
          childCount={2}
          listItems={[
            {
              heading: "Advanced Data Types",
              description:
                "PostgreSQL supports a wide range of advanced data types, including JSON, XML, arrays, hstore, and geometric types, allowing for complex data modeling and storage.",
            },
            {
              heading: "Extensibility",
              description:
                "PostgreSQL’s architecture allows for extensive customization, enabling the creation of custom functions, operators, data types, and even new languages, making it highly adaptable to specific requirements.",
            },
            {
              heading: "Full-Text Search",
              description:
                "PostgreSQL offers powerful full-text search capabilities, allowing for efficient searching of text-based data with support for complex queries, ranking, and stemming.",
            },
            {
              heading: "Concurrency Control",
              description:
                "PostgreSQL uses Multi-Version Concurrency Control (MVCC) to handle multiple simultaneous transactions, ensuring data consistency and high performance without locking.",
            },
          ]}
        />

        <PostgresqlPricing title={"Available Pricing Plans"} />
        <div className={"flex-col gap3rem"}>
          <CatalogSubHeading title={"Region Availability"} />
          <div className={"flex-col gap1rem"}>
            <CatalogSubHeading
              title={"Available Regions"}
              fontSize="1.25rem"
              color="#02273C"
            />
            <div>
              <Grid container spacing={0}>
                <Grid item xs={6} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} md={6}>
                      <li className="regionListli">{"North America"}</li>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <img
                    alt={"northAmerica"}
                    width={400}
                    height={200}
                    src={NorthAmerica}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="specificRegionDetils">
            <CatalogSubHeading
              title={"Specific Region Details"}
              fontSize="1.25rem"
              color="#02273C"
            />

            <div>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Region />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Region Details
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    North America: This region provides low-latency access to
                    customers located in the United States and Canada. It offers
                    high availability and scalability, making it an ideal choice
                    for enterprise customers with large-scale workloads.
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Local Compliance
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    Fully compliant with regulatory requirements, including:
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{
                      color: "#577687",
                      lineHeight: "1.5rem",
                      paddingLeft: 2,
                    }}
                  >
                    <ul>
                      <li>GDPR</li>
                      <li>ISO 27000</li>
                      <li>SOC-2</li>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Typography
          variant="h4"
          fontSize="2.125rem"
          fontWeight={600}
          component="div"
          sx={{ color: "#0A85C7", lineHeight: "1.875rem" }}
        >
          Related Products
        </Typography>
        <div style={{ maxWidth: "80%" }}>
          <CardView
            variant="simple"
            data={CARD_DATA}
            handleNavigateTo={handleNavigateTo}
          />
          <Button
            variant="text"
            id={"viewMore"}
            onClick={() => {
              navigate("/public-catalog");
            }}
          >
            View More
          </Button>
        </div>
      </div>
    </>
  );
};

export default PostgresqlOverView;
