import styled from "styled-components";

export const WorkflowViewerTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.625rem;
  min-height: 70vh;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.313rem;
  color: #0a85c7;
  margin-top: 1.875rem;
`;

export const Header = styled.div`
  font-size: 2.125rem;
  font-weight: 600;
`;

export const SubHeader = styled.div`
  font-size: 1rem;
  font-weight: 300;
`;
