import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Tarvos.css";
import TarvosPricing from "./TarvosPricing";

const TarvosPlansAndFeatures: React.FC = () => {
  return (
    <>
      <div className={"gap4rem flex-col"}>
        <div className={"gap1rem flex-col"}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Pricing Plans and Features
          </Typography>
          <div className={"gap2rem flex-row"}>
            <div className={"gap1rem flex-col"}>
              <Typography
                variant="h6"
                fontSize="1.25rem"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                Tarvos includes
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                {" "}
                <ul>
                  <li>Extensive Data Source Connectivity</li>
                  <li>Drag-and-Drop Visualization Builder</li>
                  <li>Interactive Dashboards and Reports</li>
                  <li>Advanced Analytical Functions and Calculations</li>
                  <li>Real-time Data Processing and Analysis</li>
                  <li>Data Blending and Joining Across Sources</li>
                  <li>Collaborative Sharing and Publishing</li>
                  <li>Customizable Filters and Parameters</li>
                </ul>
              </Typography>
            </div>
          </div>
          <TarvosPricing title={"Plan Options & Details"} />
        </div>
      </div>
    </>
  );
};

export default TarvosPlansAndFeatures;
