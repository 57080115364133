import * as React from "react";
import LoadingImage from "../../../assets/SSCELoading.gif";

const centerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
};

export const LoadingAnimation: React.FC = () => {
  return (
    <div style={{ width: "100%" }}>
      <div style={centerStyle}>
        <img
          src={LoadingImage}
          alt="Running"
          className="running-image"
          width={450}
        />
      </div>
    </div>
  );
};
