import * as React from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";
import Region from "../Regions";
import { useNavigate } from "react-router-dom";
import Carousel from "../../subcomponents/Carousel";
import CardView from "../CardView";
import NorthAmerica from "../../../../assets/NorthAmerica.png";
import CatalogBenefits from "../CatalogBenefits";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CatalogKeyFeatures from "../CatalogKeyFeatures";
import TableauPricing from "./TableauPricing";
import CatalogSubHeading from "../CatalogSubHeading";
import DatasetOutlinedIcon from "@mui/icons-material/DatasetOutlined";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import Grid from "@mui/material/Grid";
import Tableau01 from "../../../../assets/Tableau/tableau01.png";
import Tableau02 from "../../../../assets/Tableau/tableau02.png";
import Tableau03 from "../../../../assets/Tableau/tableau03.png";

const CARD_DATA = [
  {
    id: 1,
    logo: "/api/assets/datamesh.png",
    serviceName: "SS&C Everywhere Data Mesh",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "The SS&C Everywhere Data Mesh platform provides an interface for users to centralize their data to make it more accessible to the rapidly changing needs of a user’s organization.",
    tags: {
      function: "Cloud Platform",
      industry: "Cross-Industry",
      serviceType: "Data Service",
    },
  },
  {
    id: 2,
    logo: "/api/assets/algoriskreport.png",
    serviceName: "SS&C Algorithmics Risk Reports",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "Generate world-class risk reports leveraging award and access powerful reporting dashboards preintegrated with your data.",
    tags: {
      function: "Risk Management",
      industry: "Cross-Industry",
      serviceType: "Analytics Service",
    },
  },
];

const TableauOverView: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateTo = (name: string) => {
    if (name === "SS&C Everywhere Data Mesh") {
      return navigate("/public-catalog/data-mesh");
    }
    if (name === "SS&C Algorithmics Risk Reports") {
      return navigate("/public-catalog/ss&c-algorithmics-risk-reports");
    }
  };

  return (
    <>
      <div className="flex-col gap3rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Overview
          </Typography>
          <div>
            <Typography
              variant="h6"
              fontSize="1.25rem"
              sx={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              About This Service
            </Typography>
            <Typography
              variant="body1"
              component={"div"}
              sx={{ color: "#000", lineHeight: "1.5rem" }}
            >
              <div style={{ display: "flex", maxWidth: "90%" }}>
                <ul>
                  <li>
                    {" "}
                    Tableau features an intuitive drag-and-drop interface,
                    making it accessible for users of all skill levels to create
                    complex visualizations without coding.{" "}
                  </li>
                  <li>
                    {" "}
                    It seamlessly integrates with various data sources,
                    including SQL databases, spreadsheets, cloud services, and
                    big data platforms.{" "}
                  </li>
                  <li>
                    {" "}
                    Tableau allows for real-time data analysis and updates,
                    enabling users to work with the most current data available.{" "}
                  </li>
                  <li>
                    {" "}
                    Users can create interactive and shareable dashboards that
                    allow for deep data exploration and insights.{" "}
                  </li>
                  <li>
                    {" "}
                    Tableau provides advanced analytics capabilities, including
                    trend analysis, predictive analysis, and statistical
                    modeling, enhancing data insights.{" "}
                  </li>
                  <li>
                    {" "}
                    Tableau boasts a robust user community and extensive support
                    resources, including forums, training materials, and
                    customer support.{" "}
                  </li>
                </ul>
              </div>
            </Typography>

            <Carousel
              imageList={[
                {
                  src: Tableau01,
                  alt: "Tableau01",
                },
                {
                  src: Tableau02,
                  alt: "Tableau02",
                },
                {
                  src: Tableau03,
                  alt: "Tableau03",
                },
              ]}
            />
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <DatasetOutlinedIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Enhanced Data Visualization",
              description:
                "Tableau allows users to create detailed and visually appealing charts, graphs, and dashboards, making complex data easier to understand and analyze.",
            },

            {
              icon: (
                <TipsAndUpdatesOutlinedIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Improved Decision Making",
              description:
                "With real-time data analysis and interactive dashboards, users can make informed, data-driven decisions quickly.",
            },
            {
              icon: (
                <TrendingUpIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Increased Productivity",
              description:
                "Its intuitive interface and drag-and-drop functionality reduce the time and effort needed to generate reports and visualizations, boosting overall productivity.",
            },
            {
              icon: (
                <QueryStatsIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Scalability and Flexibility",
              description:
                "Tableau can handle large datasets and integrates with various data sources, making it suitable for businesses of all sizes and industries.",
            },
          ]}
        />
        <CatalogKeyFeatures
          childCount={2}
          listItems={[
            {
              heading: "Intuitive Design",
              description:
                "Tableau's user-friendly interface allows for easy navigation and quick learning, enabling users to create complex visualizations without deep technical knowledge.",
            },
            {
              heading: "Data Blending Capabilities",
              description:
                "Tableau can combine data from multiple sources, providing a comprehensive view and enabling more insightful analysis.",
            },
            {
              heading: "Interactive Exploration",
              description:
                " Users can interact with their data through dynamic dashboards, allowing for deeper exploration and the ability to drill down into specifics.",
            },
            {
              heading: "Collaborative Analytics",
              description:
                "Tableau facilitates collaboration by enabling users to share dashboards and reports easily, fostering a data-driven culture within teams and organizations.",
            },
          ]}
        />
        <TableauPricing title={"Available Pricing Plans"} />
        <div className={"flex-col gap3rem"}>
          <CatalogSubHeading title={"Region Availability"} />
          <div className={"flex-col gap1rem"}>
            <CatalogSubHeading
              title={"Available Regions"}
              fontSize="1.25rem"
              color="#02273C"
            />
            <div>
              <Grid container spacing={0}>
                <Grid item xs={6} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} md={6}>
                      <li className="regionListli">{"North America"}</li>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <img
                    alt={"northAmerica"}
                    width={400}
                    height={200}
                    src={NorthAmerica}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="specificRegionDetils">
            <CatalogSubHeading
              title={"Specific Region Details"}
              fontSize="1.25rem"
              color="#02273C"
            />

            <div>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Region />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Region Details
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    North America: This region provides low-latency access to
                    customers located in the United States and Canada. It offers
                    high availability and scalability, making it an ideal choice
                    for enterprise customers with large-scale workloads.
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Local Compliance
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    Fully compliant with regulatory requirements, including:
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{
                      color: "#577687",
                      lineHeight: "1.5rem",
                      paddingLeft: 2,
                    }}
                  >
                    <ul>
                      <li>GDPR</li>
                      <li>ISO 27000</li>
                      <li>SOC-2</li>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Typography
          variant="h4"
          fontSize="2.125rem"
          fontWeight={600}
          component="div"
          sx={{ color: "#0A85C7", lineHeight: "1.875rem" }}
        >
          Related Products
        </Typography>
        <div style={{ maxWidth: "80%" }}>
          <CardView
            variant="simple"
            data={CARD_DATA}
            handleNavigateTo={handleNavigateTo}
          />
          <Button
            variant="text"
            id={"viewMore"}
            onClick={() => {
              navigate("/public-catalog");
            }}
          >
            View More
          </Button>
        </div>
      </div>
    </>
  );
};

export default TableauOverView;
