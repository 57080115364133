import { useState, useEffect, FC } from "react";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Button } from "@ssce/ui-shared-library";
import Accordion from "../Accordion";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ClearIcon from "@mui/icons-material/Clear";
import ConfirmModal from "../ConfirmModal";
import { Divider } from "@mui/material";
import PermissionTable from "../PermissionTable";

import { Roles } from "../../../../common/dataTypes";

import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetchProjectsRoles,
  updateProjectsRoles,
  deleteProjectsRoles,
} from "../../../../redux/projectsManagement/actions";
import { fetchRoles } from "../../../../redux/rolesManagement/actions";
import Acl from "../../subcomponents/Acl";
import { useAclHooks } from "../../subcomponents/Acl/hooks";
import { PERMISSION_LIST } from "../../../../constants";

const mapStateToProps = (state: AppState) => ({
  selectedProject: state.projectsManagement.selectedProject,
  projectRoles: state.projectsManagement.projectRoles,
  roles: state.rolesManagement.roleList,
});

const mapDispatchToProps = {
  fetchProjectsRoles,
  fetchRoles,
  updateProjectsRoles,
  deleteProjectsRoles,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const RolesManagement: FC<Props> = ({
  selectedProject,
  projectRoles,
  roles,
  fetchRoles,
  updateProjectsRoles,
  deleteProjectsRoles,
}) => {
  const [selectedRoles, setSelectedRoles] = useState<Roles[]>([]);
  const [open, setOpen] = useState(false);
  const [deleteData, setDeleteData] = useState<Roles>({
    name: "",
    description: "",
    id: 0,
    permissionTable: [],
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDeleteClick = () => {
    handleClose();
    deleteProjectsRoles(selectedProject.projectId, [{ id: deleteData.id }]);
  };

  const handleApplyClick = () => {
    let payload = selectedRoles.map((item) => {
      return { id: item.id };
    });
    setSelectedRoles([]);
    updateProjectsRoles(selectedProject.projectId, payload);
  };

  useEffect(() => {
    fetchRoles();
  }, [fetchRoles]);

  const allowRoleDelete = useAclHooks(PERMISSION_LIST.ROLES_EXECUTE);
  const renderAssignedRoles = () => {
    return projectRoles.map((item) => {
      return (
        <Accordion
          key={item.name}
          name={item.name}
          iconHeader={allowRoleDelete && <DeleteForeverIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            handleOpen();
            setDeleteData(item);
          }}
        >
          <Typography
            style={{ color: "#0A85C7", fontSize: 12, marginLeft: 20 }}
          >
            Description
          </Typography>
          <Typography
            style={{
              color: "#02273C",
              fontWeight: 400,
              fontSize: 14,
              marginLeft: 20,
            }}
          >
            {item.description}
          </Typography>
          <Divider></Divider>
          <br></br>
          <Typography
            style={{
              color: "#0979B5",
              fontWeight: 500,
              fontSize: 12,
              marginLeft: 20,
            }}
          >
            List of Permissions and Scope
          </Typography>
          <PermissionTable data={item.permissionTable} />
        </Accordion>
      );
    });
  };

  const renderSelectedRoles = () => {
    return selectedRoles.map((item) => {
      return (
        <Accordion
          key={item.name}
          name={item.name}
          iconHeader={<ClearIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            setSelectedRoles([
              ...selectedRoles.filter((r) => item.id !== r.id),
            ]);
          }}
        >
          <Typography
            style={{ color: "#0A85C7", fontSize: 12, marginLeft: 20 }}
          >
            Description
          </Typography>
          <Typography
            style={{
              color: "#02273C",
              fontWeight: 400,
              fontSize: 14,
              marginLeft: 20,
            }}
          >
            {item.description}
          </Typography>
          <Divider></Divider>
          <br></br>
          <Typography
            style={{
              color: "#0979B5",
              fontWeight: 500,
              fontSize: 12,
              marginLeft: 20,
            }}
          >
            List of Permissions and Scope
          </Typography>
          <PermissionTable data={item.permissionTable} />
        </Accordion>
      );
    });
  };

  return (
    <>
      <div style={{ width: "100%" }}>
        <h1
          style={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 20,
            lineHeight: 0,
            textAlign: "left",
            color: "#02273C",
          }}
        >
          Assigned Roles
        </h1>
        <br></br>
        {renderAssignedRoles()}
        <br></br>
      </div>
      <ConfirmModal
        open={open}
        title={"Please Confirm"}
        onRequestCancel={handleClose}
        onRequestConfirm={handleDeleteClick}
      >
        <Typography
          style={{
            margin: 2,
            fontSize: 20,
            fontWeight: 500,
            lineHeight: "1.5rem",
          }}
        >
          You are{" "}
          <span style={{ textDecoration: "underline", fontWeight: 900 }}>
            removing{" "}
          </span>{" "}
          the{" "}
          <span style={{ fontWeight: 700, color: "#0468B1" }}>
            {deleteData.name}
          </span>{" "}
          from the {selectedProject.name}. You can re-add this {deleteData.name}{" "}
          if you change your mind.
          <div style={{ fontWeight: 900 }}>
            Are you sure you want to continue?
          </div>
        </Typography>
      </ConfirmModal>
      <Acl permission={PERMISSION_LIST.ROLES_WRITE}>
        <div>
          <h1
            style={{
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: 20,
              lineHeight: 0,
              textAlign: "left",
              color: "#02273C",
            }}
          >
            Assign Roles
          </h1>
          <br></br>
          <div style={{ border: "0.063rem solid #CBD9E1", borderRadius: 4 }}>
            <Stack
              spacing={2}
              sx={{
                margin: 2,
                marginBottom: "0.188rem",
                width: 570,
                backgroundColor: "#F6FDFF",
              }}
            >
              <Autocomplete
                multiple
                id="size-small-outlined-multi"
                size="medium"
                value={selectedRoles}
                sx={{
                  "& .MuiChip-root": {
                    backgroundColor: "#B3D9EE",
                    color: "#075E8D",
                  },
                  "& .MuiChip-root .MuiChip-deleteIcon": { color: "#0A85C7" },
                }}
                onChange={(_, values) => {
                  setSelectedRoles(values);
                }}
                options={roles}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField {...params} label="Roles" placeholder="" />
                )}
              />
            </Stack>
            <p
              style={{
                marginLeft: 20,
                marginTop: 0,
                color: "#577687",
                backgroundColor: "#EDF4F8",
                fontSize: 12,
              }}
            >
              Please use the dropdown box above or type in a Roles name to
              select it.
            </p>
            <p
              style={{
                margin: "1rem 1.25rem",
                fontStyle: "normal",
                fontWeight: 600,
                fontSize: 18,
                textAlign: "left",
                color: "#02273C",
              }}
            >
              Role Addition Details
            </p>
            {renderSelectedRoles()}
            <div
              style={{
                width: "85%",
                marginLeft: 20,
                marginBottom: 20,
                display: "flex",
                flexDirection: "row",
              }}
            >
              {" "}
              <br></br>
              <Button
                id={"apply"}
                onClick={handleApplyClick}
                variant="default"
                size="medium"
              >
                Apply changes
              </Button>
              <Button
                id={"cancel"}
                onClick={() => setSelectedRoles([])}
                variant="text"
                size="medium"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Acl>
    </>
  );
};

export default connector(RolesManagement);
