import Stack from "@mui/material/Stack";
import Carousel from "react-elastic-carousel";
import { FABButton } from "@ssce/ui-shared-library";
import { MinicardAddInstance } from "../PrivateCatalog/Instances/MinicardAddInstance";
import DWMinicard from "./DWMiniCard";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];

function DWCarousel() {
  return (
    <div>
      <Stack
        direction="row"
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "0.25rem 0 .5rem 0",
        }}
      >
        <FABButton onClick={() => {}} showAddIcon>
          Add Environment
        </FABButton>
      </Stack>

      <div className="carousel-wrapper">
        <Carousel breakPoints={breakPoints}>
          <DWMinicard
            h1="Miracle HR BPE"
            h3="Blue Prism: Kansas City"
            p="15 Workers Available"
            span="15 / 30 Workers Used"
            val="50"
          />
          <DWMinicard
            h1="Miracle Sales BPE US"
            h3="Blue Prism: Kansas City"
            p="22 Workers Available"
            span="28/50 Workers Used"
            val="50"
          />
          <DWMinicard
            h1="Miracle Sales BPE UK"
            h3="Blue Prism: London"
            p="25 Workers Available"
            span="15/40 Workers Used"
            val="50"
          />
          <MinicardAddInstance
            h1="Need Another Environment?"
            p="Create another environment with the button below."
            val="40"
          />
        </Carousel>
      </div>
    </div>
  );
}
export default DWCarousel;
