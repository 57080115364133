import { AppThunk } from "../store";
import axios from "axios";
import { enqueueSnackbar } from "../snackbars/actions";
import { NotificationLevel } from "../snackbars/notification-level";
import {
  UserData,
  UserOverViewData,
  Roles,
  Projects,
  OverViewData,
  UserInfo,
} from "../../common/dataTypes";
import { USER_ENDPOINT_BASE_URL } from "../../constants";

export enum UserManagementActionType {
  SET_USER = "SET_USER",
  SET_LOADING = "SET_LOADING",
  SET_DETAILS_LOADING = "SET_DETAILS_LOADING",
  SET_CURRENT_USER = "SET_CURRENT_USER",
  SELECT_USER = "SELECT_USER",
  SHOW_HIDE_USER_DETAILS = "SHOW_HIDE_USER_DETAILS",
  SET_USER_OVERVIEW_DETAILS = "SET_USER_OVERVIEW_DETAILS",
  SET_USER_ROLES = "SET_USER_ROLES",
  SET_USER_PROJECTS = "SET_USER_PROJECTS",
  SET_USER_INFO = "SET_USER_INFO",
}

export const fetchUsers = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(`${USER_ENDPOINT_BASE_URL}/users`)
    .then((response) => {
      dispatch(setUsers(response.data.users));
      dispatch(setLoading(false));
    })
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve users`,
        ),
      );
    });
};

export const fetchUsersMe = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(`${USER_ENDPOINT_BASE_URL}/users/me`)
    .then((response) => {
      dispatch(setCurrentUser(response.data));
      dispatch(setLoading(false));
    })
    .catch(() => {
      dispatch(setLoading(false));
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve Current user`,
        ),
      );
    });
};

export const fetchUserInfo = (): AppThunk => (dispatch) => {
  axios
    .get(`${USER_ENDPOINT_BASE_URL}/users/info`)
    .then((response) => dispatch(setUserInfo(response.data)))
    .catch(() =>
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve Current user`,
        ),
      ),
    );
};

export const fetchUserOverview =
  (id: string): AppThunk =>
  (dispatch) => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/users/${id}`)
      .then((response) => {
        dispatch(setUserOverviewDetails(response.data));
        dispatch(setOverviewDetailsLoading(false));
      })
      .catch(() => {
        dispatch(setShowHideUsersDetails(false));
        dispatch(setOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve user info`,
          ),
        );
      });
  };

export const fetchUserRoles =
  (id: string): AppThunk =>
  (dispatch) => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/users/${id}/roles`)
      .then((response) => {
        dispatch(setUserRoles(response.data.groups));
        dispatch(setOverviewDetailsLoading(false));
      })
      .catch(() => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve user Roles`,
          ),
        );
      });
  };

export const updateUserRoles =
  (id: string, payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setOverviewDetailsLoading(true));
    axios
      .post(`${USER_ENDPOINT_BASE_URL}/users/${id}/roles`, payload)
      .then(() => {
        dispatch(fetchUserRoles(id));
        dispatch(
          enqueueSnackbar(NotificationLevel.SUCCESS, "User roles are updated."),
        );
      })
      .catch(() => {
        dispatch(setOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to update user Roles`,
          ),
        );
      });
  };

export const deleteUserRoles =
  (id: string, payload: any): AppThunk =>
  (dispatch) => {
    dispatch(setOverviewDetailsLoading(true));
    axios
      .delete(`${USER_ENDPOINT_BASE_URL}/users/${id}/roles`, { data: payload })
      .then(() => {
        dispatch(fetchUserRoles(id));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Role successfully removed from user",
          ),
        );
      })
      .catch(() => {
        dispatch(setOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to update user Roles`,
          ),
        );
      });
  };

export const fetchUserProjects =
  (id: string): AppThunk =>
  (dispatch) => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/users/${id}/projects`)
      .then((response) => dispatch(setUserProjects(response.data.projects)))
      .catch(() => {
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to retrieve user projects`,
          ),
        );
      });
  };

export const deleteUserById =
  (id: string): AppThunk =>
  (dispatch) => {
    dispatch(setOverviewDetailsLoading(true));
    axios
      .delete(`${USER_ENDPOINT_BASE_URL}/users/${id}`)
      .then(() => {
        dispatch(setShowHideUsersDetails(false));
        dispatch(fetchUsers());
        dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "User successfully deleted",
          ),
        );
      })
      .catch(() => {
        dispatch(setOverviewDetailsLoading(false));
        dispatch(
          enqueueSnackbar(
            NotificationLevel.ERROR,
            `Warning: Unable to delete user`,
          ),
        );
      });
  };

export const setLoading = (setLoading: boolean) =>
  ({
    type: UserManagementActionType.SET_LOADING,
    payload: setLoading,
  }) as const;

export const setOverviewDetailsLoading = (setLoading: boolean) =>
  ({
    type: UserManagementActionType.SET_DETAILS_LOADING,
    payload: setLoading,
  }) as const;

export const setUsers = (UserData: UserData[]) =>
  ({
    type: UserManagementActionType.SET_USER,
    payload: UserData,
  }) as const;

export const setCurrentUser = (OverViewData: OverViewData) =>
  ({
    type: UserManagementActionType.SET_CURRENT_USER,
    payload: OverViewData,
  }) as const;

export const setUserInfo = (UserInfo: UserInfo) =>
  ({
    type: UserManagementActionType.SET_USER_INFO,
    payload: UserInfo,
  }) as const;

export const setSelectUsers = (selectedUser: UserData) =>
  ({
    type: UserManagementActionType.SELECT_USER,
    payload: selectedUser,
  }) as const;

export const setShowHideUsersDetails = (showDetails: boolean) =>
  ({
    type: UserManagementActionType.SHOW_HIDE_USER_DETAILS,
    payload: showDetails,
  }) as const;

export const setUserOverviewDetails = (userOverviewDetails: UserOverViewData) =>
  ({
    type: UserManagementActionType.SET_USER_OVERVIEW_DETAILS,
    payload: userOverviewDetails,
  }) as const;

export const setUserRoles = (userRoles: Roles[]) =>
  ({
    type: UserManagementActionType.SET_USER_ROLES,
    payload: userRoles,
  }) as const;

export const setUserProjects = (userProjects: Projects[]) =>
  ({
    type: UserManagementActionType.SET_USER_PROJECTS,
    payload: userProjects,
  }) as const;

export type UserManagementAction =
  | ReturnType<typeof setUsers>
  | ReturnType<typeof setCurrentUser>
  | ReturnType<typeof setUserInfo>
  | ReturnType<typeof setSelectUsers>
  | ReturnType<typeof setShowHideUsersDetails>
  | ReturnType<typeof setUserOverviewDetails>
  | ReturnType<typeof setUserRoles>
  | ReturnType<typeof setUserProjects>
  | ReturnType<typeof setLoading>
  | ReturnType<typeof setOverviewDetailsLoading>;
