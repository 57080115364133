import { FC, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Table, Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import {
  dataMeshRedirectURL,
  USER_ENDPOINT_BASE_URL,
} from "../../../../../constants";
import { CurrentViewContext } from "../../../ComponentWithContext";
import { LoadingAnimation } from "../../../subcomponents/LoadingAnimation";
import { ErrorNotification, CopyIconButton } from "@ssce/ui-shared-library";
import moment from "moment";

type Props = {};

type ApiKeys = {
  id: string;
  keyName: string;
  keyValue: string;
  createDate: string;
  expireDate: string;
};

const PrivateApiSettingsTab: FC<Props> = () => {
  const navigate = useNavigate();
  const [apiKeys, setApiKeys] = useState<ApiKeys[]>([]);
  const { viewData } = useContext(CurrentViewContext);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    getApiKeys();
  }, []);

  const getApiKeys = () => {
    setLoading(true);
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/keys`)
      .then((res) => {
        setApiKeys(res.data.keys);
        setLoading(false);
      })
      .catch(() => {
        setHasError(true);
        setLoading(false);
      });
  };
  return (
    <>
      {loading ? <LoadingAnimation /> : <></>}
      <div
        className="myservices-settings"
        style={{
          display: !loading ? "flex" : "none",
        }}
      >
        {hasError && <ErrorNotification />}
        <div className="myservices-settings-content">
          <Typography className="myservices-setting-header">
            Settings
          </Typography>

          <Typography className="myservices-setting-titles">
            Data Product API Query URL
          </Typography>

          <Table
            id={"table"}
            data={[
              {
                queryUrl: `${dataMeshRedirectURL}/output-api/view/custom-api?responseType=Basic`,
              },
            ]}
            columns={[
              {
                title: "Query URL",
                dataIndex: "queryUrl",
                sorter: true,
                render: (queryUrl) => (
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <CopyIconButton id={"copyIcon"} copyText={queryUrl} />
                    <div className="myservices-datamesh-instance-id">
                      {queryUrl}
                    </div>
                  </Box>
                ),
              },
            ]}
          />

          <div className="myservices-settings-content">
            <Typography className="myservices-setting-titles">
              Data Product API
            </Typography>

            <Table
              id={"Data Mesh ID"}
              data={[
                {
                  instanceName: viewData.instanceName,
                },
              ]}
              columns={[
                {
                  title: "Data Product API Name",
                  dataIndex: "instanceName",
                  render: (cell) => (
                    <span style={{ fontWeight: "700" }}>{cell}</span>
                  ),
                },
                {
                  title: "Data Mesh ID",
                  render: () => (
                    <Box className="myservices-datamesh-id">
                      <CopyIconButton
                        id={"copyIcon"}
                        copyText={viewData.instanceId}
                      />
                      <div className="myservices-datamesh-instance-id">
                        {viewData.instanceId}
                      </div>
                    </Box>
                  ),
                },
              ]}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="myservices-setting-titles">
              User API Keys
            </Typography>
            <Button
              variant="default"
              id={"manageApiKey"}
              onClick={() => navigate("/apikeys")}
              className="myservices-APIkey-tablebody-button"
              size="medium"
            >
              Manage API Keys
            </Button>
          </div>
          <Table
            showSearchFilter
            id={"API Key"}
            data={apiKeys}
            columns={[
              {
                title: "API Key Name",
                dataIndex: "keyName",
                sorter: true,
                render: (cell) => (
                  <span style={{ fontWeight: "700" }}>{cell}</span>
                ),
              },
              {
                title: "Created",
                dataIndex: "createDate",
                sorter: true,
                render: (cell) => moment(cell).format("MMM DD, YYYY hh:mma"),
              },
              {
                title: "Expiry",
                dataIndex: "expireDate",
                sorter: true,
                render: (cell) => moment(cell).format("MMM DD, YYYY hh:mma"),
              },
              {
                title: "Key",
                render: () => (
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <CopyIconButton id={"copyIcon"} isDisabled />
                    <div className="myservices-datamesh-instance-id">
                      Unavailable
                    </div>
                  </Box>
                ),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};
export default PrivateApiSettingsTab;
