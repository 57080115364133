import {
  CustomErrorIcon,
  ErrorBox,
  ErrorContainer,
  ErrorMessage,
  ErrorText,
  ErrorTitle,
  StyledErrorIcon,
} from "../publishApi.styles";

export const ErrorSection = ({ publishError }) => {
  return publishError !== "" ? (
    <ErrorContainer>
      <ErrorBox>
        <CustomErrorIcon>
          <StyledErrorIcon />
        </CustomErrorIcon>
        <ErrorText>
          <ErrorTitle>Error</ErrorTitle>
          <ErrorMessage>{publishError}</ErrorMessage>
        </ErrorText>
      </ErrorBox>
    </ErrorContainer>
  ) : null;
};
