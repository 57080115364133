import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Nifi.css";
import NifiPricing from "./NifiPricing";

const NifiPlansAndFeatures: React.FC = () => {
  return (
    <>
      <div className={"gap4rem flex-col"}>
        <div className={"gap1rem flex-col"}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Pricing Plans and Features
          </Typography>
          <div className={"gap2rem flex-row"}>
            <div className={"gap1rem flex-col"}>
              <Typography
                variant="h6"
                fontSize="1.25rem"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                Apache Nifi includes
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                {" "}
                <ul>
                  <li>Data Provenance Tracking</li>
                  <li>Dynamic Prioritization</li>
                  <li>Back Pressure Control</li>
                  <li>Runtime Flow Modification</li>
                  <li>Browser-Based User Interface</li>
                  <li>Secure Communication</li>
                  <li>Multi-Tenant Authorization</li>
                  <li>Loss-Tolerant and Guaranteed Delivery</li>
                  <li>Scalable Architecture</li>
                  <li>Customizable Workflows</li>
                  <li>Real-Time Monitoring</li>
                  <li>Comprehensive Logging and Auditing</li>
                </ul>
              </Typography>
            </div>
          </div>
          <NifiPricing title={"Plan Options & Details"} />
        </div>
      </div>
    </>
  );
};

export default NifiPlansAndFeatures;
