import * as React from "react";
import { useState, useEffect } from "react";
import "./provisionService.css";
import {
  InstanceNameProvision,
  SelectRegionProvision,
  UserManagementProvision,
  SetupBillingProvision,
  ProvisionInstanceButton,
} from "./provisionDataMeshComponents";
import { ProvisionSuccessfulPage } from "./provisionSuccessfulPage";
import { useProvisionDataMeshHooks } from "./provisionDataMeshHooks";
import { ErrorNotification } from "@ssce/ui-shared-library";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

const regions = [
  { label: "Canada", value: "Canada" },
  { label: "UK", value: "UK" },
  { label: "Europe", value: "Europe" },
  { label: "CentralUS", value: "CentralUS" },
];

const ProvisionDataMeshContainer: React.FC = () => {
  const {
    createInstances,
    fetchCatalogID,
    setInstanceName,
    setProjectId,
    setIsLoading,
    hasError,
    catalogId,
    instanceName,
    projectId,
    projects,
    hasProjectIdError,
    hasInstanceNameError,
    isLoading,
  } = useProvisionDataMeshHooks();

  const [selectedRegion, setSelectedRegion] = useState("CentralUS");

  const [plan, setPlan] = useState("Large");
  const [billing, setBilling] = useState("Quarterly");
  const [isProvisionSuccessful, setIsProvisionSuccessful] = useState(false);

  const handleProvisionInstance = async () => {
    const success = await createInstances({
      instanceName,
      projectId,
      catalogId,
      applicationName: "data_mesh",
      region: selectedRegion,
    });
    const successAsBool = success as unknown as boolean;
    setIsLoading(false);
    setIsProvisionSuccessful(!!successAsBool);
  };

  useEffect(() => {
    fetchCatalogID("data_mesh_platform");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCreateAnotherInstance = () => {
    setIsProvisionSuccessful(false);
    setInstanceName("");
    setSelectedRegion("All Regions");
    setProjectId("");
    setPlan("Large");
    setBilling("Quarterly");
  };

  return (
    <>
      <div className={"provisionDataMesh"}>
        {hasError && <ErrorNotification />}
        {!isProvisionSuccessful ? (
          !isLoading ? (
            <>
              <MyServicesCardHeader
                imageSource={"/api/assets/datamesh.png"}
                title={"Provision Service"}
                subTitle="SS&C Everywhere Data Mesh"
                contents={
                  "Unleash the potential of your data with our robust Data Mesh solution, specifically designed for financial services and SS&C products. Connect effortlessly to multiple data sources, execute cross-queries with ease, and publish private APIs. Our cloud-based platform ensures optimal security and scalability, enhancing your operational efficiency. Gain unparalleled insights, drive informed decisions and excel in the competitive landscape."
                }
              ></MyServicesCardHeader>
              <InstanceNameProvision
                value={instanceName}
                hasInstanceNameError={hasInstanceNameError}
                handleInstanceNameChange={(value: string) =>
                  setInstanceName(value)
                }
              />
              <SelectRegionProvision
                regions={regions}
                handleRegionChange={(value: string) => setSelectedRegion(value)}
                selectedRegion={selectedRegion}
              />
              <UserManagementProvision
                handleProjectChange={(projectId: string) =>
                  setProjectId(projectId)
                }
                projects={projects}
                projectId={projectId}
                hasProjectIdError={hasProjectIdError}
              />
              <SetupBillingProvision
                plan={plan}
                handlePlanChange={(value: string) => setPlan(value)}
                handleBillingChange={(value: string) => setBilling(value)}
                billing={billing}
              />
              <ProvisionInstanceButton
                handleProvisionInstance={handleProvisionInstance}
              />
            </>
          ) : (
            <LoadingAnimation />
          )
        ) : (
          <ProvisionSuccessfulPage
            onCreateAnotherInstance={onCreateAnotherInstance}
          />
        )}
      </div>
    </>
  );
};

export default ProvisionDataMeshContainer;
