import { FC } from "react";
import { useEffect } from "react";
import { Table } from "@ssce/ui-shared-library";
import Typography from "@mui/material/Typography";
import { Modal } from "../Modal";
import UserDetails from "./UserDetails";
import moment from "moment";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetchUsers,
  setSelectUsers,
  setShowHideUsersDetails,
  setOverviewDetailsLoading,
} from "../../../../redux/userManagement/actions";
import { useDispatch } from "react-redux";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";

const mapStateToProps = (state: AppState) => ({
  users: state.userManagement.usersList,
  showDetails: state.userManagement.showDetails,
  isLoading: state.userManagement.isLoading,
});

const mapDispatchToProps = {
  fetchUsers,
  setOverviewDetailsLoading,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const UserManagementTab: FC<Props> = ({
  fetchUsers,
  setOverviewDetailsLoading,
  users,
  showDetails,
  isLoading,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  if (isLoading) return <LoadingAnimation />;
  return (
    <>
      <div
        style={{
          marginTop: "0.625rem",
          display: "flex",
          flex: "100%",
          flexWrap: "wrap",
          gap: "100%",
          marginBottom: "3.125rem",
        }}
      >
        <Modal
          showModal={showDetails}
          setShowModal={() => dispatch(setShowHideUsersDetails(false))}
        >
          <UserDetails />
        </Modal>
        <div
          style={{
            marginBottom: "1.25rem",
            display: "flex",
            flex: "1 1 0",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", gap: "2%" }}>
            <Typography
              variant="h3"
              marginBottom={"1.25rem"}
              style={{ fontSize: "1.563rem", color: "#02273C" }}
            >
              Users
            </Typography>
            <Typography
              style={{
                color: "#90A8B5",
                fontSize: "0.875rem",
                fontWeight: "500",
                marginTop: "0.5rem",
                width: "25rem",
              }}
            >
              A total of <b style={{ color: "#0468B1" }}>{users.length}</b>
            </Typography>
          </div>
        </div>

        <Table
          id={"table"}
          columns={[
            {
              title: "First Name",
              dataIndex: "firstName",
              defaultSortOrder: "asc",
              sorter: true,
            },
            {
              title: "Last Name",
              dataIndex: "lastName",
              sorter: true,
            },
            {
              title: "Email",
              dataIndex: "email",
              sorter: true,
            },
            {
              title: "Date Added",
              dataIndex: "createdTimestamp",
              sorter: true,
              searchByValue: (cell) => moment(cell).format("ll"),
              render: (cell) => moment(cell).format("ll"),
            },
          ]}
          data={users}
          showSearchFilter
          onRowClick={(row) => {
            dispatch(setSelectUsers(row));
            dispatch(setShowHideUsersDetails(true));
            dispatch(setOverviewDetailsLoading(true));
          }}
          showPagination
        />
      </div>
    </>
  );
};

export default connector(UserManagementTab);
