import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Region from "../Regions";
import { useNavigate } from "react-router-dom";
import CardView from "../CardView";
import NorthAmerica from "../../../../assets/NorthAmerica.png";
import CatalogBenefits from "../CatalogBenefits";
import InsightsIcon from "@mui/icons-material/InsightsOutlined";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import AnalyticsIcon from "@mui/icons-material/AnalyticsOutlined";
import ShareIcon from "@mui/icons-material/ShareOutlined";
import CatalogKeyFeatures from "../CatalogKeyFeatures";
import TarvosPricing from "./TarvosPricing";
import CatalogSubHeading from "../CatalogSubHeading";
import Grid from "@mui/material/Grid";

const CARD_DATA = [
  {
    id: 1,
    logo: "/api/assets/datamesh.png",
    serviceName: "SS&C Everywhere Data Mesh",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "The SS&C Everywhere Data Mesh platform provides an interface for users to centralize their data to make it more accessible to the rapidly changing needs of a user’s organization.",
    tags: {
      function: "Cloud Platform",
      industry: "Cross-Industry",
      serviceType: "Data Service",
    },
  },
  {
    id: 2,
    logo: "/api/assets/algoriskreport.png",
    serviceName: "SS&C Algorithmics Risk Reports",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "Generate world-class risk reports leveraging award and access powerful reporting dashboards preintegrated with your data.",
    tags: {
      function: "Risk Management",
      industry: "Cross-Industry",
      serviceType: "Analytics Service",
    },
  },
];

const TarvosOverView: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateTo = (name: string) => {
    if (name === "SS&C Everywhere Data Mesh") {
      return navigate("/public-catalog/data-mesh");
    }
    if (name === "SS&C Algorithmics Risk Reports") {
      return navigate("/public-catalog/ss&c-algorithmics-risk-reports");
    }
  };

  return (
    <>
      <div className="flex-col gap3rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Overview
          </Typography>
          <div>
            <Typography
              variant="h6"
              fontSize="1.25rem"
              sx={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              About This Service
            </Typography>
            <Typography
              variant="body1"
              component={"div"}
              sx={{ color: "#000", lineHeight: "1.5rem" }}
            >
              <div style={{ display: "flex", maxWidth: "90%" }}>
                <ul>
                  <li>
                    {" "}
                    Connects to a wide range of data sources, including
                    databases, cloud services, and spreadsheets, making it
                    versatile for various data integration needs.{" "}
                  </li>
                  <li>
                    {" "}
                    Allows users to create interactive dashboards where they can
                    filter, highlight, and drill down into data dynamically.{" "}
                  </li>
                  <li>
                    {" "}
                    Features a drag-and-drop interface that simplifies the
                    process of building complex visualizations without requiring
                    coding skills.{" "}
                  </li>
                  <li>
                    {" "}
                    Supports real-time data analytics, enabling users to view
                    and analyze data as it is updated, which is crucial for
                    timely decision-making.{" "}
                  </li>
                  <li>
                    {" "}
                    Combines data from multiple sources using data blending and
                    join functionalities, offering comprehensive reporting
                    capabilities.{" "}
                  </li>
                  <li>
                    {" "}
                    Offers a variety of visualization options like bar charts,
                    line graphs, scatter plots, and heat maps to represent data
                    in diverse ways.{" "}
                  </li>
                  <li>
                    {" "}
                    Includes advanced analytics features such as trend lines,
                    forecasting, and custom calculations for deeper data
                    insights.{" "}
                  </li>
                  <li>
                    {" "}
                    Allows easy sharing of dashboards and reports through its
                    server or online platform, promoting collaboration and
                    data-driven decision-making.{" "}
                  </li>
                </ul>
              </div>
            </Typography>
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <InsightsIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Comprehensive Data Integration",
              description:
                "Tarvos connects seamlessly to various data sources, enabling a unified view of data across databases, cloud services, and files.",
            },
            {
              icon: (
                <DashboardIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Interactive Dashboards",
              description:
                "Create dynamic and interactive dashboards that allow users to filter, drill down, and visualize data in real-time, enhancing data exploration.",
            },
            {
              icon: (
                <AnalyticsIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Advanced Analytics",
              description:
                "Incorporate trend analysis, forecasting, and statistical tools to gain deeper insights from your data, supporting better decision-making.",
            },
            {
              icon: (
                <ShareIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Efficient Collaboration",
              description:
                "Easily share dashboards and reports through Tarvos's online platform, enabling teams to collaborate and make data-driven decisions together.",
            },
          ]}
        />
        <CatalogKeyFeatures
          childCount={2}
          listItems={[
            {
              heading: "Data Connectivity and Integration",
              description:
                "Tarvos connects to a wide range of data sources, including databases, cloud services, and spreadsheets, enabling seamless data integration for comprehensive analysis.",
            },
            {
              heading: "Drag-and-Drop Visualization",
              description:
                "Create complex visualizations easily with Tarvos's intuitive drag-and-drop interface, allowing users to build charts, graphs, and dashboards without needing coding skills.",
            },
            {
              heading: "Interactive Dashboards and Stories",
              description:
                "Tarvos empowers users to build interactive dashboards and data stories, enabling dynamic data exploration with filtering, drill-downs, and real-time insights.",
            },
            {
              heading: "Advanced Analytics and Calculations",
              description:
                "Perform complex calculations, statistical analysis, and forecasting with Tarvos's advanced analytics features, helping users derive deeper insights from their data.",
            },
          ]}
        />

        <TarvosPricing title={"Available Pricing Plans"} />
        <div className={"flex-col gap3rem"}>
          <CatalogSubHeading title={"Region Availability"} />
          <div className={"flex-col gap1rem"}>
            <CatalogSubHeading
              title={"Available Regions"}
              fontSize="1.25rem"
              color="#02273C"
            />
            <div>
              <Grid container spacing={0}>
                <Grid item xs={6} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} md={6}>
                      <li className="regionListli">{"North America"}</li>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <img
                    alt={"northAmerica"}
                    width={400}
                    height={200}
                    src={NorthAmerica}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="specificRegionDetils">
            <CatalogSubHeading
              title={"Specific Region Details"}
              fontSize="1.25rem"
              color="#02273C"
            />

            <div>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Region />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Region Details
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    North America: This region provides low-latency access to
                    customers located in the United States and Canada. It offers
                    high availability and scalability, making it an ideal choice
                    for enterprise customers with large-scale workloads.
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Local Compliance
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    Fully compliant with regulatory requirements, including:
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{
                      color: "#577687",
                      lineHeight: "1.5rem",
                      paddingLeft: 2,
                    }}
                  >
                    <ul>
                      <li>GDPR</li>
                      <li>ISO 27000</li>
                      <li>SOC-2</li>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Typography
          variant="h4"
          fontSize="2.125rem"
          fontWeight={600}
          component="div"
          sx={{ color: "#0A85C7", lineHeight: "1.875rem" }}
        >
          Related Products
        </Typography>
        <div style={{ maxWidth: "80%" }}>
          <CardView
            variant="simple"
            data={CARD_DATA}
            handleNavigateTo={handleNavigateTo}
          />
          <Link
            sx={{
              marginLeft: "1.875rem",
              fontWeight: 600,
              marginTop: "-1.875rem",
              cursor: "pointer",
            }}
            id={"viewMore"}
            onClick={() => {
              navigate("/public-catalog");
            }}
          >
            View More
          </Link>
        </div>
      </div>
    </>
  );
};

export default TarvosOverView;
