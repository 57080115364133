import loader from "./loader.gif";
import styled from "styled-components";

const LoginLoaderWrapper = styled.div`
  font-size: 2.125rem;
  font-weight: 800;
  line-height: normal;
  padding: 1.5rem 0;

  div {
    text-align: center;
  }
`;

export default function LoginLoader({ showLoader }: { showLoader: boolean }) {
  return (
    <>
      {showLoader && (
        <LoginLoaderWrapper>
          Taking you to your organization’s sign-in portal
          <div>
            <img src={loader} alt="loader" style={{ width: "50%" }} />
          </div>
        </LoginLoaderWrapper>
      )}
    </>
  );
}
