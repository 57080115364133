import { Button } from "@ssce/ui-shared-library";
import moment from "moment";
import { FC, useState, useEffect } from "react";
import ConfirmModal from "../ConfirmModal";
import Typography from "@mui/material/Typography";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import {
  fetchUserRoles,
  deleteUserById,
} from "../../../../redux/userManagement/actions";
import Acl from "../../subcomponents/Acl";
import { PERMISSION_LIST } from "../../../../constants";

const mapStateToProps = (state: AppState) => ({
  userData: state.userManagement.userOverviewDetails,
  selectedUser: state.userManagement.selectedUser,
});

const mapDispatchToProps = {
  fetchUserRoles,
  deleteUserById,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const OverviewInfo: FC<Props> = ({
  userData,
  selectedUser,
  fetchUserRoles,
  deleteUserById,
}) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const onRequestDeleteConfirm = () => {
    deleteUserById(selectedUser.id);
  };

  useEffect(() => {
    fetchUserRoles(selectedUser.id);
  }, [fetchUserRoles, selectedUser.id]);

  return (
    <>
      <div style={{ gap: "1%" }}>
        <ConfirmModal
          open={open}
          title={"Please Confirm"}
          onRequestCancel={handleClose}
          onRequestConfirm={onRequestDeleteConfirm}
        >
          <Typography
            style={{
              margin: 2,
              fontSize: 20,
              fontWeight: 500,
              lineHeight: "1.5rem",
            }}
          >
            You are{" "}
            <span style={{ textDecoration: "underline", fontWeight: 900 }}>
              removing
            </span>{" "}
            the{" "}
            <span style={{ fontWeight: 700, color: "#0468B1" }}>
              {userData.firstName} {userData.lastName}
            </span>{" "}
            from the platform. This action cannot be undone and user will need
            to be readded to the platform by your company’s IAM if you change
            your mind.{" "}
            <span style={{ fontWeight: 900 }}>
              Are you sure you want to continue?
            </span>
          </Typography>
        </ConfirmModal>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <p
              style={{
                letterSpacing: 0.15,
                fontWeight: 400,
                fontSize: 14,
                color: "#355263",
              }}
            >
              Full Name:
            </p>
            <p
              style={{
                fontWeight: 600,
                lineHeight: 0.1,
                fontSize: 14,
                marginBottom: 30,
              }}
            >
              {userData.firstName} {userData.lastName}
            </p>
          </div>
          <div>
            <p
              style={{
                letterSpacing: 0.15,
                fontWeight: 400,
                fontSize: 14,
                color: "#355263",
              }}
            >
              Company:
            </p>
            <p
              style={{
                fontWeight: 600,
                lineHeight: 0.1,
                fontSize: 14,
                marginBottom: 30,
              }}
            >
              {userData.organisation}
            </p>
          </div>
          <div>
            <p
              style={{
                letterSpacing: 0.15,
                fontWeight: 400,
                fontSize: 14,
                color: "#355263",
              }}
            >
              Date Added:
            </p>
            <p
              style={{
                fontWeight: 600,
                lineHeight: 0.1,
                fontSize: 14,
                marginBottom: 30,
              }}
            >
              {moment(userData.createdTimestamp).format("lll")}{" "}
            </p>
          </div>
          <br></br>
        </div>
        <div style={{ display: "flex" }}>
          <div>
            <p
              style={{
                letterSpacing: 0.15,
                fontWeight: 400,
                fontSize: 14,
                color: "#355263",
              }}
            >
              Email:
            </p>
            <p
              style={{
                fontWeight: 600,
                lineHeight: 0.1,
                fontSize: 14,
                marginBottom: 30,
              }}
            >
              {userData.email}
            </p>{" "}
          </div>
          <br></br>
        </div>
      </div>
      <Acl permission={PERMISSION_LIST.USERS_EXECUTE}>
        <div
          style={{ alignContent: "center", width: "100%", marginLeft: "40%" }}
        >
          <Button onClick={handleOpen} id={"deleteUser"} status="alert">
            Delete User
          </Button>
        </div>
      </Acl>
    </>
  );
};

export default connector(OverviewInfo);
