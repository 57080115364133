import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Tableau.css";
import TableauPricing from "./TableauPricing";

const TableauPlansAndFeatures: React.FC = () => {
  return (
    <>
      <div className={"gap4rem flex-col"}>
        <div className={"gap1rem flex-col"}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Pricing Plans and Features
          </Typography>
          <div className={"gap2rem flex-row"}>
            <div className={"gap1rem flex-col"}>
              <Typography
                variant="h6"
                fontSize="1.25rem"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                Tableau includes
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                {" "}
                <ul>
                  <li>Drag-and-drop interface</li>
                  <li>Real-time data updates</li>
                  <li>Integration with various data sources</li>
                  <li>Interactive dashboards</li>
                  <li>Advanced charting options</li>
                  <li>Data blending capabilities</li>
                  <li>Geographic mapping</li>
                  <li>Mobile accessibility</li>
                  <li>Predictive analytics</li>
                  <li>Strong community support</li>
                </ul>
              </Typography>
            </div>
          </div>
          <TableauPricing title={"Plan Options & Details"} />
        </div>
      </div>
    </>
  );
};

export default TableauPlansAndFeatures;
