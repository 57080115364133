import { useState } from "react";
import { ErrorNotification, Button } from "@ssce/ui-shared-library";
import Grid from "src/components/main/subcomponents/Grid/Grid";
import { debounce } from "lodash";
import {
  DashboardCardWrapper,
  ImageContainer,
  NewButton,
  Heading,
  Description,
  Footer,
  StyledCard,
} from "./serviceCards.styles";
import { useNavigate } from "react-router-dom";

import { Body, H6 } from "@ssce/ui-shared-library";

type ServicesProps = {
  cards: any;
  hasError: boolean;
};

const getLink = (id: number) => {
  switch (id) {
    case 1:
      return "/public-catalog/data-mesh";
    case 2:
      return "/public-catalog/ss&c-algorithmics-performance-reports";
    case 3:
      return "https://www.ssctech.com/products/tax-brightline";
    default:
      return "/public-catalog";
  }
};

const replacementTitle = "SS&C Performance Service";
const replacementText =
  "Announcing the launch of the SS&C Performance Service: Your key to comprehensive performance reporting using battle tested financial models to stay ahead of challenges in today's dynamic financial landscape";

const DashboardCard = ({ item, mainItem, prevId }) => {
  const navigate = useNavigate();

  return (
    <StyledCard
      key={item.id}
      isMain={mainItem}
      id={item.id}
      className={"card" + item.id}
      prevId={prevId}
      mainItem={mainItem}
    >
      <ImageContainer>
        <img src={item.image} alt="api" />
      </ImageContainer>

      <Heading>
        <H6>
          {item.id === 2 ? replacementTitle : item.title}
          {item.id === 1 && (
            <NewButton>
              <p>NEW</p>
            </NewButton>
          )}
        </H6>
      </Heading>
      <Description>
        <Body >
          {item.id === 2 ? replacementText : item.description}
        </Body>
      </Description>
      <Footer>
        {item.id === 3 ? (
          <Button
            id={"learnMore"}
            variant="text"
            onClick={() =>
              window.open(getLink(item.id), "_blank", "noopener,noreferrer")
            }
            size="medium"
          >
            Learn More {">>"}
          </Button>
        ) : (
          <Button
            id={"learnMore"}
            variant="text"
            onClick={() => navigate(getLink(item.id))}
            size="medium"
          >
            Learn More {">>"}
          </Button>
        )}
      </Footer>
    </StyledCard>
  );
};

export const ServiceCards = ({ cards, hasError }: ServicesProps) => {
  const [mainItem, setMainItem] = useState(2);
  const [prevId, setPrevId] = useState(2);

  const debouncedHandleCardId = debounce((e, id) => handleCardId(e, id), 150);

  const handleCardId = (_, item) => {
    if (mainItem !== item) {
      setPrevId(mainItem);
    }
    setMainItem(item);
  };

  return (
    <>
      {hasError && <ErrorNotification />}
      <DashboardCardWrapper>
        <Grid minMax="8rem">
          {cards.map(
            (
              item: {
                id: number;
                image: any;
                title: string;
                description: string;
                button: string;
              },
              key: number,
            ) => {
              return (
                <div
                  onMouseLeave={(e) => {
                    debouncedHandleCardId(e, 2);
                  }}
                  onMouseOver={(e) => {
                    debouncedHandleCardId(e, item.id);
                  }}
                >
                  <DashboardCard
                    key={key}
                    item={item}
                    mainItem={mainItem}
                    prevId={prevId}
                  />
                </div>
              );
            },
          )}
        </Grid>
      </DashboardCardWrapper>
    </>
  );
};
