import SelentiaChart from "./Chart";
import NewSelentiaPieChart from "./NewPieChart";
import SalentiaLineChart from "./SalentiaLineChart";
import Typography from "@mui/material/Typography";
import SubcategoryInfo from "./SubcategoryInfo";

const Subcategory: React.FC = () => {
  return (
    <>
      <div>
        <Typography
          variant="h1"
          color="primary"
          style={{
            marginLeft: "1.25rem",
            marginTop: "1.25rem",
            fontSize: "1.625rem",
            fontWeight: "600",
          }}
        >
          Salentia Elements Billing Summary; February 26, 2022
        </Typography>
        <SubcategoryInfo />
        <Typography
          variant="h1"
          color="primary"
          style={{
            marginLeft: "1.25rem",
            marginTop: "1.875rem",
            fontSize: "1.625rem",
            fontWeight: "600",
          }}
        >
          Billing and Usage Statistics
        </Typography>
        <div
          style={{
            marginLeft: "0",
            display: "flex",
            flex: "0%",
            flexWrap: "wrap",
            gap: "2%",
          }}
        >
          <div style={{ marginTop: "-1.25rem", marginLeft: "0" }}>
            <NewSelentiaPieChart />
            <Typography
              variant="h1"
              color="black"
              style={{
                marginLeft: "5.625rem",
                marginTop: "-22.5rem",
                fontSize: "1.5rem",
                fontWeight: "600",
              }}
            >
              Top Users
            </Typography>
          </div>
          <div style={{ marginLeft: "-2.5rem" }}>
            <Typography
              variant="h1"
              color="black"
              style={{
                marginLeft: "2.5rem",
                marginTop: "1.25rem",
                marginBottom: "1.25rem",
                fontSize: "1.5rem",
                fontWeight: "600",
              }}
            >
              Billing Costs (Last 6 Months)
            </Typography>
            <SelentiaChart />
          </div>
          <div>
            <Typography
              variant="h1"
              color="black"
              style={{
                marginLeft: "0.625rem",
                marginTop: "1.25rem",
                marginBottom: "1.25rem",
                fontSize: "1.5rem",
                fontWeight: "600",
              }}
            >
              Line Graph Title
            </Typography>
            <SalentiaLineChart />
          </div>
        </div>
        <Typography
          variant="h1"
          color="primary"
          style={{
            marginLeft: "1.25rem",
            marginTop: "1.875rem",
            marginBottom: "2.5rem",
            fontSize: "1.625rem",
            fontWeight: "600",
          }}
        >
          Recent Invoices
        </Typography>
      </div>
    </>
  );
};

export default Subcategory;
