import * as React from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { Button as MUIButton } from "@mui/material";
import { Button } from "@ssce/ui-shared-library";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Region from "../Regions";
import DocArrow from "../../../../assets/DocArrow.png";
import { useNavigate } from "react-router-dom";
import DeviceHubIcon from "@mui/icons-material/DeviceHub";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import RemoveIcon from "@mui/icons-material/Remove";
import ShieldIcon from "@mui/icons-material/Shield";
import AddIcon from "@mui/icons-material/Add";
import CardMedia from "@mui/material/CardMedia";
import Image1 from "../../../../assets/img1.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import DataUsageIcon from "@mui/icons-material/DataUsage";
import ApiIcon from "@mui/icons-material/Api";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarIcon from "@mui/icons-material/Star";
import StarHalfIcon from "@mui/icons-material/StarHalf";
import BarChartIcon from "@mui/icons-material/BarChart";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import GavelIcon from "@mui/icons-material/Gavel";
import CloudObject from "../../../../assets/DatameshCatalog/CloudObject.png";
import Iceberg from "../../../../assets/DatameshCatalog/Iceberg.png";
import PostgreSQL from "../../../../assets/DatameshCatalog/PostgreSQL.png";
import MongoDB from "../../../../assets/DatameshCatalog/MongoDB.png";
import DataMeshServiceCarousel from "./DataMeshServiceCarousel";
import ResourcesAPIs from "../../../../assets/DatameshCatalog/ResourcesAPIs.png";
import ResourcesQueries from "../../../../assets/DatameshCatalog/ResourcesQueries.png";
import "./Catalog.css";
import CardView from "../CardView";
import AllRegion from "../../../../assets/AllRegion.png";
import { Grid } from "@ssce/ui-shared-library";
import DocumentationCard from "../../Documentation/DocumentationCard";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

const CARD_DATA = [
  {
    id: 1,
    logo: require("../../../../assets/DatameshCatalog/AlgoLogo.png"),
    serviceName: "SS&C Algorithmics Risk Reports",
    subtitle: "Ver. 1.0",
    shortDescription:
      "Generate world-class risk reports leveraging award and access powerful reporting dashboards preintegrated with your data.",
    tags: {
      function: "Cloud Platform",
      industry: "Financial",
      serviceType: "Risk",
    },
    plan: "Free Trial Available",
  },
  {
    id: 2,
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/SS%26C_Technologies_logo.svg/1200px-SS%26C_Technologies_logo.svg.png",
    title: "SS&C Tax Brightline",
    serviceName: "Ver. 1.0",
    shortDescription:
      "The comprehensive, technology-powered solution for tax compliance, reporting and analytics that enables fund managers to manage their tax exposure year-round in real-time.",
    plan: "Pay-as-you-Go",
    tags: {
      function: "Tax",
      industry: "Cross-Industry",
      serviceType: "Analytics Service",
    },
  },
];

const DatameshServices = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState("1");

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const handleAChange = (panel: string) => (_, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleChange = (_, newValue: string) => {
    setValue(newValue);
  };

  const handleNavigateTo = (name: string) => {
    if (name === "SS&C Algorithmics Risk Reports") {
      return navigate("/public-catalog/ss&c-algorithmics-risk-reports");
    }
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/datamesh.png"}
        title={"Data Mesh"}
        contents={
          "Unleash the potential of your data with our robust Data Mesh solution, specifically designed for financial services and SS&C products. Connect effortlessly to multiple data sources, execute cross-queries with ease, and publish private APIs. Our cloud-based platform ensures optimal security and scalability, enhancing your operational efficiency. Gain unparalleled insights, drive informed decisions and excel in the competitive landscape."
        }
      >
        <Button
          variant="default"
          id={"createInstances"}
          onClick={() => navigate("/public-catalog/data-mesh/provision")}
        >
          Create Instance
        </Button>
      </MyServicesCardHeader>

      <Box
        sx={{
          width: "calc(100% - 2.5rem)",
          typography: "body1",
          marginLeft: "1.25rem",
          paddingBottom: "3.125rem",
        }}
      >
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              id={"tabList"}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Overview" value="1" />
              <Tab label="Plans and Features" value="2" />
              <Tab label="Technical Specifications" value="3" />
              <Tab label="Documentation" value="4" />
            </TabList>
          </Box>
          <TabPanel value="1" sx={{ marginTop: "4.5rem", padding: 0 }}>
            <div
              style={{ display: "flex", gap: "3rem", flexDirection: "column" }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{
                    color: "#0A85C7",
                    lineHeight: "2.25rem",
                    padding: "0.625rem 0",
                  }}
                >
                  Overview
                </Typography>
                <div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem 0",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize="1.25rem"
                      style={{ color: "#02273C", lineHeight: "1.5rem" }}
                    >
                      About This Service
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ color: "#000", lineHeight: "1.5rem" }}
                    >
                      Unleash the potential of your data with our robust Data
                      Mesh solution, specifically designed for financial
                      services and SS&C products. Connect effortlessly to
                      multiple data sources, execute cross-queries with ease,
                      and publish private APIs. Our cloud-based platform ensures
                      optimal security and scalability, enhancing your
                      operational efficiency. Gain unparalleled insights, drive
                      informed decisions and excel in the competitive landscape.
                    </Typography>
                    <br />
                    <Typography
                      variant="body1"
                      style={{ color: "#000", lineHeight: "1.5rem" }}
                    >
                      Data Mesh is a comprehensive platform designed to
                      streamline data operations. It encompasses various
                      functionalities such as querying, extracting, publishing,
                      provisioning, and managing data products. Additionally, it
                      facilitates the aggregation and normalization of data, as
                      well as enables seamless integration with external data
                      sources, enhancing the overall efficiency of data
                      management processes.
                    </Typography>
                    <br />
                    <DataMeshServiceCarousel />
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2.5rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                >
                  Platform Benefits
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "1.5rem",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "4.375rem" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "30%",
                      }}
                    >
                      <AccessTimeIcon
                        color="primary"
                        sx={{ width: "4.875rem", height: "4.875rem" }}
                      />
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Intuitive Querying
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        With the combined prowess of Starburst and the
                        assistance of ChatGPT, users can perform queries on
                        data, enabling efficient data retrieval and analysis
                        across multiple database types effortlessly.
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "30%",
                      }}
                    >
                      <DataUsageIcon
                        color="primary"
                        sx={{ width: "4.875rem", height: "4.875rem" }}
                      />
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Extracting
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Data Mesh enables the extraction of data from various
                        sources, ensuring the availability of diverse datasets
                        for analysis and processing.
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "30%",
                      }}
                    >
                      <DeviceHubIcon
                        color="primary"
                        sx={{ width: "4.875rem", height: "4.875rem" }}
                      />
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Connectivity
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Whether it's your local storage or an external database,
                        connecting to your data is a breeze.
                      </Typography>
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "4.375rem" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        flex: "1 1 50%",
                      }}
                    >
                      <ApiIcon
                        color="primary"
                        sx={{ width: "4.875rem", height: "4.875rem" }}
                      />
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        API Generation
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Publish your query as an API, making data accessibility
                        a reality for business users.
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        flex: "1 1 50%",
                      }}
                    >
                      <BarChartIcon
                        color="primary"
                        sx={{ width: "4.875rem", height: "4.875rem" }}
                      />
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Aggregation and Normalization
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        The platform offers capabilities to aggregate and
                        normalize data, allowing for consistent and unified data
                        representation across different sources.
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2.5rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                >
                  Benefits to Our Clients
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "1.5rem",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "4.375rem" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "30%",
                      }}
                    >
                      <ShieldIcon
                        color="primary"
                        sx={{ width: "4.875rem", height: "4.875rem" }}
                      />
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Trustworthiness
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Clients partnering with SS&C Everywhere can rest assured
                        about the integrity and safety of their data, thanks to
                        the platform's SOC2 compliance.
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "30%",
                      }}
                    >
                      <TrendingUpIcon
                        color="primary"
                        sx={{ width: "4.875rem", height: "4.875rem" }}
                      />
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Competitive Advantage
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        In a market where clients are becoming more aware and
                        demanding about data security, SOC2 compliance positions
                        SS&C Everywhere, and by extension its clients, ahead in
                        the race.
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "30%",
                      }}
                    >
                      <GavelIcon
                        color="primary"
                        sx={{ width: "4.875rem", height: "4.875rem" }}
                      />
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Regional Compliances Met
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Alongside SOC2, SS&C Everywhere's commitment to
                        region-specific compliances like GDPR means businesses
                        can operate without fretting over regional data laws.
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "3rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                >
                  Key Features
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "2rem",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "1.813rem" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        flex: "1 1 20%",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize="2.125rem"
                        style={{ color: "#75909F", lineHeight: "2.25rem" }}
                      >
                        01
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Optimized Data Pipelines
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Utilizing the likes of Airflow, SS&C Everywhere
                          establishes data pipelines that are not only robust
                          but incredibly efficient. This is complemented by
                          Starburst, which empowers users to query data across
                          multiple databases seamlessly. Together, they reduce
                          the latency traditionally experienced in data
                          processing, enabling faster decision-making and value
                          derivation.
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        flex: "1 1 30%",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize="2.125rem"
                        style={{ color: "#75909F", lineHeight: "2.25rem" }}
                      >
                        02
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Automated Transformations
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Traditionally, transforming data from platforms like
                          Globe GoGeneva required extensive manual intervention,
                          often leading to inefficiencies. With SS&C Everywhere,
                          these transformations are automated. The extracted
                          data is formatted, cleaned, and transformed to be
                          readily ingested by persistence targets within SS&C
                          Everywhere Data Mesh, or even for direct live
                          connections.
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        flex: "1 1 30%",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize="2.125rem"
                        style={{ color: "#75909F", lineHeight: "2.25rem" }}
                      >
                        03
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Direct Database Connections
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Take the instance of the Singularity accounting
                          database. Instead of the traditional method of batched
                          data extraction, SS&C Everywhere permits a live
                          connection, allowing businesses to have real-time
                          insights. This dynamic approach reduces the latency
                          between data generation and its actionable use, a boon
                          for fast-paced sectors like insurance.
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "1.813rem" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        flex: "1 1 20%",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize="2.125rem"
                        style={{ color: "#75909F", lineHeight: "2.25rem" }}
                      >
                        04
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Integration with External Data Sources
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          The platform seamlessly integrates with external data
                          sources, allowing for the incorporation of data from
                          diverse external systems or services.
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        flex: "1 1 30%",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize="2.125rem"
                        style={{ color: "#75909F", lineHeight: "2.25rem" }}
                      >
                        05
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Data Products
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Data mesh facilitates the management of data products,
                          including organizing, maintaining, and ensuring the
                          quality of the data within the platform. Users can
                          publish data products and make them accessible within
                          Marketplace.
                        </Typography>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        flex: "1 1 30%",
                        width: "30%",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize="2.125rem"
                        style={{ color: "#75909F", lineHeight: "2.25rem" }}
                      >
                        06
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Real-time Dashboards and Reports
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Following closely is the ability for users to access
                          real-time interactive dashboards against report data,
                          ensuring timely risk and performance reports are at
                          their fingertips.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* //TODO: Move the following commented out code to shared repo */}

              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                >
                  Available Pricing Plans
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "3rem",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <MUIButton
                      variant="outlined"
                      style={{
                        fontWeight: "700",
                        width: "6.875rem",
                        fontSize: "0.875rem",
                        borderRadius: "0.25rem 0 0 0.25rem",
                        padding: "0.75rem 1.5rem",
                        color: "#06496D",
                        backgroundColor: "#A1E1F5",
                        border: "0.063rem solid #A1E1F5",
                      }}
                    >
                      Monthly
                    </MUIButton>
                    <MUIButton
                      variant="outlined"
                      style={{
                        fontWeight: "700",
                        width: "6.875rem",
                        fontSize: "0.875rem",
                        borderRadius: "0 0.25rem 0.25rem 0",
                        padding: "0.75rem 1.5rem",
                        color: "#355263",
                        backgroundColor: "#DFE9EF",
                        border: "0.063rem solid #DFE9EF",
                      }}
                    >
                      Yearly
                    </MUIButton>
                  </div>
                  <div style={{ display: "flex", gap: "1.813rem" }}>
                    <Card
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginBottom: "1.25rem",
                        gap: "2.625rem",
                        padding: "0.625rem 0",
                        flex: "100%",
                        flexWrap: "wrap",
                        boxShadow: "none",
                        position: "relative",
                      }}
                    >
                      <Card
                        sx={{
                          ":hover": { bgcolor: "#C0EBF8" },
                          width: "19.063rem",
                          padding: "1.5rem 1rem",
                          boxShadow:
                            "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                          border: "0.063rem solid #CBD9E1",
                          borderRadius: "0.25rem",
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 1 auto",
                          alignSelf: "self-end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: "1.25rem",
                            justifyContent: "center",
                          }}
                        >
                          <StarBorderIcon
                            color="primary"
                            sx={{ width: "2.188rem", height: "2.188rem" }}
                          />
                          <div>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                              align="left"
                              fontWeight={600}
                              lineHeight={1}
                              style={{
                                fontSize: "2.125rem",
                                display: "inline-block",
                              }}
                            >
                              Small
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="div"
                          align="left"
                          fontWeight={500}
                          lineHeight={1.5}
                          style={{
                            marginTop: "0",
                            fontSize: "1rem",
                            display: "flex",
                            paddingBottom: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          Perfect for getting started
                        </Typography>
                        <div style={{ display: "flex", gap: "0.5rem" }}></div>
                        <List>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                10 tb
                              </span>
                              of Storage Capacity.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                1,000 hours
                              </span>{" "}
                              vCPU time.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                Plan Add-Ons
                              </span>
                              available.
                            </Typography>
                          </ListItem>
                        </List>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="default"
                            onClick={() => {}}
                            id="allFeatures"
                          >
                            View All Features
                          </Button>
                        </div>
                      </Card>

                      <Card
                        sx={{
                          ":hover": {
                            bgcolor: "#C0EBF8",
                          },
                          width: "19.063rem",
                          padding: "1.5rem 1rem",
                          boxShadow:
                            "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                          border: "0.063rem solid #CBD9E1",
                          borderRadius: "0.25rem",
                          paddingBottom: "0.625rem",
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 1 auto",
                          alignSelf: "self-end",
                        }}
                      >
                        <div>
                          <Chip
                            label="Popular"
                            sx={{
                              "&": {
                                position: "absolute",
                                top: 5,
                                marginLeft: "10rem",
                                padding: "0.25rem 0.625rem",
                                fontFamily: "Roboto Medium",
                                color: "#F6FDFF",
                                fontSize: "0.75rem",
                                backgroundColor: "#3CC13B",
                              },
                            }}
                            color="success"
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "1.875rem",
                            gap: "0.5rem",
                            justifyContent: "center",
                          }}
                        >
                          <StarHalfIcon
                            color="primary"
                            sx={{ width: "2.188rem", height: "2.188rem" }}
                          />
                          <div>
                            <Typography
                              gutterBottom
                              fontWeight={600}
                              lineHeight={1}
                              style={{
                                fontSize: "2.125rem",
                                display: "inline-block",
                              }}
                            >
                              Medium
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="div"
                          align="left"
                          fontWeight={500}
                          lineHeight={1.5}
                          style={{
                            marginTop: "0",
                            fontSize: "1rem",
                            display: "flex",
                            paddingBottom: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          Perfect for getting started
                        </Typography>
                        <div style={{ display: "flex", gap: "0.5rem" }}></div>
                        <List>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                100 tb
                              </span>
                              of Storage Capacity.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                10,000 hours
                              </span>{" "}
                              vCPU time.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                Plan Add-Ons
                              </span>
                              available.
                            </Typography>
                          </ListItem>
                          <ListItem></ListItem>
                        </List>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="default"
                            onClick={() => {}}
                            id="allFeatures"
                          >
                            View All Features
                          </Button>
                        </div>
                      </Card>

                      <Card
                        sx={{
                          ":hover": { bgcolor: "#C0EBF8" },
                          width: "19.063rem",
                          padding: "1.5rem 1rem",
                          boxShadow:
                            "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                          border: "0.063rem solid #CBD9E1",
                          borderRadius: "0.25rem",
                          paddingBottom: "0.625rem",
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 1 auto",
                          alignSelf: "self-end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: "1.25rem",
                            gap: "0.5rem",
                            justifyContent: "center",
                          }}
                        >
                          <StarIcon
                            color="primary"
                            sx={{ width: "2.188rem", height: "2.188rem" }}
                          />
                          <div>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                              align="left"
                              fontWeight={600}
                              lineHeight={1}
                              style={{
                                fontSize: "2.125rem",
                                display: "inline-block",
                              }}
                            >
                              Large
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="div"
                          align="left"
                          fontWeight={500}
                          lineHeight={1.5}
                          style={{
                            marginTop: "0",
                            fontSize: "1rem",
                            display: "flex",
                            paddingBottom: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          Perfect for getting started
                        </Typography>
                        <div style={{ display: "flex", gap: "0.5rem" }}></div>

                        <List>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                500 tb
                              </span>
                              of Storage Capacity.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                50,000 hours
                              </span>{" "}
                              vCPU time.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                Plan Add-Ons
                              </span>
                              available.
                            </Typography>
                          </ListItem>
                          <ListItem></ListItem>
                          <ListItem></ListItem>
                        </List>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="default"
                            onClick={() => {}}
                            id="allFeatures"
                          >
                            View All Features
                          </Button>
                        </div>
                      </Card>
                    </Card>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="default"
                      onClick={() => {}}
                      id="allFeatures"
                    >
                      View Full Pricing Information
                    </Button>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "2rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                >
                  Region Availability
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "2rem",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize="1.25rem"
                      fontWeight={600}
                      component="div"
                      style={{ color: "#02273C", lineHeight: "2.25rem" }}
                    >
                      Available Regions
                    </Typography>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "20.438rem",
                        }}
                      >
                        <ul>
                          <li
                            style={{
                              color: "#577687",
                              fontSize: "1rem",
                              padding: "0.5rem 0.313rem",
                            }}
                          >
                            North America
                          </li>
                          <li
                            style={{
                              color: "#577687",
                              fontSize: "1rem",
                              padding: "0.5rem 0.313rem",
                            }}
                          >
                            South America
                          </li>
                          <li
                            style={{
                              color: "#577687",
                              fontSize: "1rem",
                              padding: "0.5rem 0.313rem",
                            }}
                          >
                            Europe
                          </li>
                          <li
                            style={{
                              color: "#577687",
                              fontSize: "1rem",
                              padding: "0.5rem 0.313rem",
                            }}
                          >
                            Asia
                          </li>
                          <li
                            style={{
                              color: "#577687",
                              fontSize: "1rem",
                              padding: "0.5rem 0.313rem",
                            }}
                          >
                            Africa
                          </li>
                        </ul>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "20.438rem",
                        }}
                      >
                        <ul>
                          <li
                            style={{
                              color: "#577687",
                              fontSize: "1rem",
                              padding: "0.5rem 0.313rem",
                            }}
                          >
                            Oceania
                          </li>
                          <li
                            style={{
                              color: "#577687",
                              fontSize: "1rem",
                              padding: "0.5rem 0.313rem",
                            }}
                          >
                            Middle East
                          </li>
                          <li
                            style={{
                              color: "#577687",
                              fontSize: "1rem",
                              padding: "0.5rem 0.313rem",
                            }}
                          >
                            Caribbean
                          </li>
                          <li
                            style={{
                              color: "#577687",
                              fontSize: "1rem",
                              padding: "0.5rem 0.313rem",
                            }}
                          >
                            Central America
                          </li>
                          <li
                            style={{
                              color: "#577687",
                              fontSize: "1rem",
                              padding: "0.5rem 0.313rem",
                            }}
                          >
                            South Pacific
                          </li>
                        </ul>
                      </div>
                      <div style={{ marginRight: "0.938rem" }}>
                        <img
                          alt={"allRegion"}
                          width={400}
                          height={200}
                          src={AllRegion}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "1.5rem",
                      flexDirection: "column",
                      padding: "1rem 1.5rem",
                      backgroundColor: "#EDF4F8",
                      border: "0.063rem solid #CBD9E1",
                      borderRadius: "0.25rem",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize="1.25rem"
                      fontWeight={600}
                      component="div"
                      style={{ color: "#02273C", lineHeight: "2.25rem" }}
                    >
                      Specific Region Details
                    </Typography>
                    <div style={{ display: "flex", gap: "1.5rem" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "20.438rem",
                        }}
                      >
                        <Region />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "20.438rem",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            variant="body1"
                            fontSize="1rem"
                            fontWeight={700}
                            component="div"
                            style={{
                              color: "#02273C",
                              lineHeight: "1.5rem",
                              paddingBottom: 8,
                            }}
                          >
                            Region Details
                          </Typography>
                          <Typography
                            variant="body1"
                            fontSize="1rem"
                            fontWeight={400}
                            component="div"
                            style={{ color: "#577687", lineHeight: "1.5rem" }}
                          >
                            North America: This region provides low-latency
                            access to customers located in the United States and
                            Canada. It offers high availability and scalability,
                            making it an ideal choice for enterprise customers
                            with large-scale workloads.
                          </Typography>
                        </div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          width: "20.438rem",
                        }}
                      >
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Typography
                            variant="body1"
                            fontSize="1rem"
                            fontWeight={700}
                            component="div"
                            style={{
                              color: "#02273C",
                              lineHeight: "1.5rem",
                              paddingBottom: 8,
                            }}
                          >
                            Local Compliance
                          </Typography>
                          <Typography
                            variant="body1"
                            fontSize="1rem"
                            fontWeight={400}
                            component="div"
                            style={{ color: "#577687", lineHeight: "1.5rem" }}
                          >
                            Fully compliant with regulatory requirements,
                            including:
                            <ul>
                              <li>GDPR</li>
                              <li>ISO 27000</li>
                              <li>SOC-2</li>
                            </ul>
                          </Typography>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "3rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                >
                  More Information
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "2rem",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "1.813rem" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        width: "30%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Support
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Need Assistance? Our dedicated support team is here to
                          help! Feel free to reach out if you have any questions
                          or require assistance with this product. Rest assured,
                          this product includes comprehensive support to ensure
                          a seamless experience for you.
                        </Typography>
                        <Link
                          style={{ fontWeight: 600, cursor: "pointer" }}
                          onClick={() => navigate("/support")}
                          id={"goToSupportPage"}
                        >
                          Go to Support Page &#8827;
                        </Link>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        width: "30%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Security
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Service Organization Control 2 (SOC2) is a framework
                          that evaluates and ensures robust controls within a
                          service organization, particularly in terms of
                          security, availability, processing integrity,
                          confidentiality, and privacy of customer data. SOC2 is
                          an essential benchmark for cloud and SaaS providers
                          who store customer data, establishing that they
                          maintain strict information security policies and
                          procedures.
                        </Typography>
                        <Link style={{ fontWeight: 600, cursor: "pointer" }}>
                          Learn more &#8827;
                        </Link>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        width: "30%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Global Compliance
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          With businesses operating globally, compliance with
                          regional regulations is paramount. SS&C Everywhere
                          prioritizes this by offering region isolation, such as
                          US and UK specific zones, ensuring stringent adherence
                          to regulations like GDPR.
                        </Typography>
                        <Link style={{ fontWeight: 600, cursor: "pointer" }}>
                          Learn more &#8827;
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1.5rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="1.5rem"
                  fontWeight={600}
                  component="div"
                  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                >
                  FAQs
                </Typography>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Accordion
                    expanded={expanded === "panel1"}
                    id={"panel1"}
                    onChange={handleAChange("panel1")}
                    sx={{
                      "&": { borderBottom: "0.063rem solid #B6C7D1" },
                      "&.Mui-expanded": { margin: "0" },
                    }}
                    style={{ boxShadow: "none", backgroundColor: "inherit" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel1" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls="panel1a-content"
                      id="faq-1"
                      sx={{ padding: 0 }}
                    >
                      <Typography variant="h6">
                        How do I access my data?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        Use of this API requires an API key which can be
                        obtained in the Settings tab. Refer to the API
                        Documentation tab to best understand how to drive this
                        API and browse code samples, quick start guides, and
                        release notes.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleAChange("panel2")}
                    id={"panel2"}
                    sx={{
                      "&": { borderBottom: "0.063rem solid #B6C7D1" },
                      "&.Mui-expanded": { margin: "0" },
                    }}
                    style={{ boxShadow: "none", backgroundColor: "inherit" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel2" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls="panel1a-content"
                      id="faq-2"
                      sx={{ padding: 0 }}
                    >
                      <Typography variant="h6">
                        Can anyone else access my data?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        This is a private API published from a Data Mesh
                        instance owned by your organization. Users from other
                        organizations will not be able to view or access this
                        service. To best protect access your data, ensure that
                        you handle any responses from this API with care and
                        never share your API key with anyone.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleAChange("panel3")}
                    id={"panel3"}
                    sx={{
                      "&": { borderBottom: "0.063rem solid #B6C7D1" },
                      "&.Mui-expanded": { margin: "0" },
                    }}
                    style={{ boxShadow: "none", backgroundColor: "inherit" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel3" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls="panel1a-content"
                      id="faq-3"
                      sx={{ padding: 0 }}
                    >
                      <Typography variant="h6">
                        What is the difference between Marketplace and Data
                        Mesh?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        The Data Mesh is a core service offering on the
                        Marketplace that allows users to federate their
                        organization’s sources of data and publish access points
                        to it for others in their organization to make use of.
                        The marketplace is a service catalog in which users
                        belonging to an organization can view Data Mesh APIs
                        that have been made available, as well as other products
                        and services which may make use of data feeds from the
                        Data Mesh platform.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel4"}
                    id={"panel4"}
                    onChange={handleAChange("panel4")}
                    sx={{
                      "&": { borderBottom: "0.063rem solid #B6C7D1" },
                      "&.Mui-expanded": { margin: "0" },
                    }}
                    style={{ boxShadow: "none", backgroundColor: "inherit" }}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel4" ? <RemoveIcon /> : <AddIcon />
                      }
                      aria-controls="panel1a-content"
                      id="faq-4"
                      sx={{ padding: 0 }}
                    >
                      <Typography variant="h6">
                        How do I get started?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        If you are able to view this page, then you are able to
                        request access and start using this API today! If you do
                        not yet have an instance of the Data Mesh, please visit
                        the Data Mesh catalog listing on the Marketplace to
                        purchase a subscription.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
              <div
                style={{
                  gap: "2rem",
                  flexDirection: "column",
                  marginBottom: "2.5rem",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                >
                  Relevant Resources
                </Typography>
                <div
                  style={{ display: "flex", gap: "1.5rem", flexWrap: "wrap" }}
                >
                  <Card sx={{ display: "flex", width: "30%", height: 192 }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 106, height: 192 }}
                      image={Image1}
                    />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "0.938rem",
                            }}
                          >
                            <div>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                                align="left"
                                fontWeight={500}
                                lineHeight={1}
                                style={{
                                  fontSize: "1.25rem",
                                  display: "inline-block",
                                }}
                              >
                                Introducing Data Mesh
                              </Typography>
                              <Typography
                                gutterBottom
                                // variant='h6'
                                component="div"
                                align="left"
                                fontWeight={400}
                                lineHeight={1.5}
                                style={{
                                  marginTop: "0.313rem",
                                  fontSize: "0.875rem",
                                }}
                              >
                                SS&C Technologies, a name synonymous with
                                pioneering innovation, introduces the SS&C
                                Everywhere Data Mesh.
                              </Typography>
                            </div>
                            <div>
                              <img src={DocArrow} alt="arrow"></img>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Box>
                  </Card>
                  <Card sx={{ display: "flex", width: "30%", height: 192 }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 106, height: 192 }}
                      image={ResourcesQueries}
                    />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "0.938rem",
                            }}
                          >
                            <div>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                                align="left"
                                fontWeight={500}
                                lineHeight={1}
                                style={{
                                  fontSize: "1.25rem",
                                  display: "inline-block",
                                }}
                              >
                                Mastering Queries with Data Mesh
                              </Typography>
                              <Typography
                                gutterBottom
                                component="div"
                                align="left"
                                fontWeight={400}
                                lineHeight={1.5}
                                style={{
                                  marginTop: "0.313rem",
                                  fontSize: "0.875rem",
                                }}
                              >
                                Mastering Queries with SS&C Technology's Data
                                Mesh: Uniting Data Like Never Before!
                              </Typography>
                            </div>
                            <div>
                              <img src={DocArrow} alt="arrow1"></img>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Box>
                  </Card>
                  <Card sx={{ display: "flex", width: "30%", height: 192 }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 106, height: 192 }}
                      image={ResourcesAPIs}
                    />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <CardContent>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              gap: "0.938rem",
                            }}
                          >
                            <div>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                                align="left"
                                fontWeight={500}
                                lineHeight={1}
                                style={{
                                  fontSize: "1.25rem",
                                  display: "inline-block",
                                }}
                              >
                                Publishing APIs with Data Mesh
                              </Typography>
                              <Typography
                                gutterBottom
                                component="div"
                                align="left"
                                fontWeight={400}
                                lineHeight={1.5}
                                style={{
                                  marginTop: "0.313rem",
                                  fontSize: "0.875rem",
                                }}
                              >
                                Publishing Your Data: Transforming Queries into
                                Accessible APIs
                              </Typography>
                            </div>
                            <div>
                              <img src={DocArrow} alt="arrow2"></img>
                            </div>
                          </div>
                        </div>
                      </CardContent>
                    </Box>
                  </Card>
                </div>
              </div>

              <div>
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                >
                  Related Products?
                </Typography>
                <Typography
                  fontSize="1rem"
                  component="div"
                  style={{ marginTop: "1.25rem", marginBottom: "1.25rem" }}
                >
                  Discover similar products and recommendations to enhance your
                  shopping experience.
                </Typography>
                <CardView
                  variant="simple"
                  data={CARD_DATA}
                  handleNavigateTo={handleNavigateTo}
                />
              </div>
            </div>
          </TabPanel>

          <TabPanel value="2" sx={{ marginTop: "4.5rem", padding: 0 }}>
            <div
              style={{
                display: "flex",
                gap: "4.5rem",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{
                    color: "#0A85C7",
                    lineHeight: "2.25rem",
                    padding: "0.625rem 0",
                  }}
                >
                  Pricing Plans and Features
                </Typography>
                <div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem 0",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      fontSize="1.625rem"
                      fontWeight={400}
                      style={{ color: "#02273C", lineHeight: "1.5rem" }}
                    >
                      Summary
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ color: "#000", lineHeight: "1.5rem" }}
                    >
                      The features listed below are included in every plan. In
                      addition to the standardized features outlined below, each
                      plan distinguishes itself primarily in terms of data
                      storage capacity and computing power, which is determined
                      by VCPU hours allocation. This delineation ensures that
                      clients can select a plan that precisely aligns with their
                      specific operational requirements and scalability needs.
                    </Typography>
                  </div>
                </div>
                <Typography
                  variant="h4"
                  fontSize="1.625rem"
                  fontWeight={400}
                  component="div"
                  style={{}}
                >
                  Features & Details
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "2rem",
                    flexDirection: "column",
                  }}
                >
                  <div style={{ display: "flex", gap: "1.813rem" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        width: "33%",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize="2.125rem"
                        style={{ color: "#75909F", lineHeight: "2.25rem" }}
                      >
                        01
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Data Sources Include
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          With the Data Mesh offering, you're in control. We
                          support a variety of data source types to make your
                          data connection seamless. Here are the types you can
                          connect to:
                        </Typography>
                        <ul>
                          <li className="Datamesh-feature-list">
                            S3 Cloud Object Storage
                          </li>
                          <li className="Datamesh-feature-list">API</li>
                          <li className="Datamesh-feature-list">SFTP</li>
                          <li className="Datamesh-feature-list">
                            SQL Database
                          </li>
                          <li className="Datamesh-feature-list">
                            No SQL Database
                          </li>
                          <li className="Datamesh-feature-list">
                            SS&C Data Sources
                          </li>
                          <p className="Datamesh-feature-details">
                            Specially tailored for our in-built data sources.
                          </p>
                        </ul>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        width: "33%",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize="2.125rem"
                        style={{ color: "#75909F", lineHeight: "2.25rem" }}
                      >
                        02
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Querying Includes
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Users can query data across multiple database types
                          effortlessly regardless of the technology used to
                          store it.
                        </Typography>
                        <ul>
                          <li className="Datamesh-feature-list">
                            Starburst Powered Query Editor
                          </li>
                          <li className="Datamesh-feature-list">
                            Built-in ChatGPT Assistance
                          </li>
                          <li className="Datamesh-feature-list">
                            Kubernetes Integration
                          </li>
                          <li className="Datamesh-feature-list">
                            Cross-database Querying
                          </li>
                          <p className="Datamesh-feature-details">
                            With direct database connections.
                          </p>
                          <li className="Datamesh-feature-list">
                            Job Scheduling & Persistence
                          </li>
                          <li className="Datamesh-feature-list">
                            Automated Transformations
                          </li>
                          <li className="Datamesh-feature-list">
                            Real-time Reporting Tools
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1.5rem",
                        alignItems: "flex-start",
                        width: "33%",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize="2.125rem"
                        style={{ color: "#75909F", lineHeight: "2.25rem" }}
                      >
                        03
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Publishing Includes
                        </Typography>
                        <Typography
                          variant="body1"
                          style={{ color: "#02273C", lineHeight: "1.5rem" }}
                        >
                          Publishing with SS&C Everywhere’s Data Mesh is more
                          than just sharing data; it's about empowering your
                          entire organization with refined, accessible, and
                          meaningful insights.
                        </Typography>
                        <ul>
                          <li className="Datamesh-feature-list">
                            Data Product Creation
                          </li>
                          <p className="Datamesh-feature-details">
                            Transform Queries into data products.
                          </p>
                          <li className="Datamesh-feature-list">
                            Launch to Marketplace
                          </li>
                          <p className="Datamesh-feature-details">
                            Publish data products to Marketplace as APIs.
                          </p>
                          <li className="Datamesh-feature-list">
                            Access & Integration
                          </li>
                          <p className="Datamesh-feature-details">
                            Users can integrate these APIs seamlessly into their
                            daily operations.
                          </p>
                          <li className="Datamesh-feature-list">
                            Comprehensive Control
                          </li>
                          <p className="Datamesh-feature-details">
                            Govern permissions and accessibility for users.
                          </p>
                          <li className="Datamesh-feature-list">
                            Security and Reliance
                          </li>
                          <p className="Datamesh-feature-details">
                            Your data stays within your domain, always.
                          </p>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    gap: "1.5rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h4"
                    fontSize="1.625rem"
                    fontWeight={400}
                    component="div"
                    style={{}}
                  >
                    Supported Database:
                  </Typography>
                  <Typography
                    fontSize="1rem"
                    fontWeight={500}
                    component="div"
                    style={{ marginBottom: "1.25rem" }}
                  >
                    Every instance of the SS&C Everywhere Datamesh comes with
                    four data storage types:
                  </Typography>
                  <div style={{ display: "flex", gap: "1.875rem" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <img
                        alt={"MongoDB"}
                        src={MongoDB}
                        className="Datamesh-supported-database-icons"
                      ></img>
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        MongoDB
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        A popular NoSQL database
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <img
                        alt={"postgreSQL"}
                        src={PostgreSQL}
                        className="Datamesh-supported-database-icons"
                      ></img>
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        PostgreSQL
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#02273C",
                          lineHeight: "1.5rem",
                          marginLeft: "20%",
                        }}
                      >
                        A powerful, open-source object-relational database
                        system.
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <img
                        alt={"iceberg"}
                        src={Iceberg}
                        className="Datamesh-supported-database-icons"
                      ></img>
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Iceberg
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#02273C",
                          lineHeight: "1.5rem",
                          marginLeft: "20%",
                        }}
                      >
                        A table format for large, slow-moving tabular data.
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: "1rem",
                        alignItems: "center",
                        flexDirection: "column",
                        width: "25%",
                      }}
                    >
                      <img
                        alt={"cloudObjectStore"}
                        src={CloudObject}
                        className="Datamesh-supported-database-icons"
                      ></img>
                      <Typography
                        variant="h6"
                        style={{ color: "#02273C", lineHeight: "1.5rem" }}
                      >
                        Cloud Object Store
                      </Typography>
                      <Typography
                        variant="body1"
                        style={{
                          color: "#02273C",
                          lineHeight: "1.5rem",
                          marginLeft: "20%",
                        }}
                      >
                        Perfect for unstructured data, this offers scalability
                        and distributed storage.
                      </Typography>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem 0",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="h6"
                    fontSize="1.25rem"
                    style={{ color: "#02273C", lineHeight: "1.5rem" }}
                  >
                    Add-ons
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: "0.625rem",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{ color: "#000", lineHeight: "1.5rem" }}
                  >
                    Included in every Data Mesh plan is the option to
                    incorporate add-ons. These supplementary features give you
                    the flexibility to customize your plan to your specific
                    requirements. There are two categories of add-ons to choose
                    from: the capacity to enhance data storage by additional
                    terabytes or to enhance computing power through additional
                    VCPU hours. These add-ons grant increased potency to your
                    plan at an additional cost. Should you choose to upgrade
                    your plan to a larger plan, and the new plan encompasses
                    your existing add-ons, your plan will seamlessly transition
                    to the upgraded version, obviating the need for
                    supplementary fees unless you opt to add further
                    enhancements to the new plan.
                  </Typography>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1.625rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{ color: "#0A85C7", lineHeight: "2.25rem" }}
                >
                  Our Pricing Plans
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Typography
                    variant="body1"
                    style={{
                      color: "#000",
                      lineHeight: "1.5rem",
                      padding: "0.625rem 0",
                    }}
                  >
                    Please refer below for an overview of our pricing plans
                    along with their respective features.
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "3.5rem",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", gap: 18 }}>
                    <div>
                      <MUIButton
                        variant="outlined"
                        style={{
                          fontWeight: "700",
                          width: "6.875rem",
                          fontSize: "0.875rem",
                          borderRadius: "0.25rem 0 0 0.25rem",
                          padding: "0.75rem 1.5rem",
                          color: "#06496D",
                          backgroundColor: "#A1E1F5",
                          border: "0.063rem solid #A1E1F5",
                        }}
                      >
                        Monthly
                      </MUIButton>
                      <MUIButton
                        variant="outlined"
                        style={{
                          fontWeight: "700",
                          width: "6.875rem",
                          fontSize: "0.875rem",
                          borderRadius: "0 0.25rem 0.25rem 0",
                          padding: "0.75rem 1.5rem",
                          color: "#355263",
                          backgroundColor: "#DFE9EF",
                          border: "0.063rem solid #DFE9EF",
                        }}
                      >
                        Yearly
                      </MUIButton>
                    </div>

                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        variant="body2"
                        component="div"
                        fontWeight={500}
                        style={{
                          color: "#2EACD4",
                          textAlign: "center",
                          fontSize: "1rem",
                          lineHeight: "1rem",
                          fontStyle: "italic",
                          transform: "rotate(-10.057deg)",
                        }}
                      >
                        16% off
                      </Typography>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="28"
                        height="28"
                        viewBox="0 0 28 28"
                        fill="none"
                      >
                        <path
                          d="M25.9873 2.48584C24.8084 10.4858 18.3578 26.4858 1.98731 26.4858"
                          stroke="#0A85C7"
                          strokeWidth="3"
                          strokeLinecap="round"
                        />
                      </svg>
                    </div>
                  </div>
                  <div style={{ display: "flex", gap: "1.813rem" }}>
                    <Card
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        marginBottom: "1.25rem",
                        gap: "2.625rem",
                        padding: "0.625rem 0",
                        flex: "100%",
                        flexWrap: "wrap",
                        boxShadow: "none",
                        position: "relative",
                      }}
                    >
                      <Card
                        sx={{
                          ":hover": { bgcolor: "#C0EBF8" },
                          width: "19.063rem",
                          padding: "1.5rem 1rem",
                          boxShadow:
                            "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                          border: "0.063rem solid #CBD9E1",
                          borderRadius: "0.25rem",
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 1 auto",
                          alignSelf: "self-end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: "1.25rem",
                            justifyContent: "center",
                          }}
                        >
                          <StarBorderIcon
                            color="primary"
                            sx={{ width: "2.188rem", height: "2.188rem" }}
                          />
                          <div>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                              align="left"
                              fontWeight={600}
                              lineHeight={1}
                              style={{
                                fontSize: "2.125rem",
                                display: "inline-block",
                              }}
                            >
                              Small
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="div"
                          align="left"
                          fontWeight={500}
                          lineHeight={1.5}
                          style={{
                            marginTop: "0",
                            fontSize: "1rem",
                            display: "flex",
                            paddingBottom: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          Perfect for getting started
                        </Typography>
                        <div style={{ display: "flex", gap: "0.5rem" }}></div>
                        <List>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                10 tb
                              </span>
                              of Storage Capacity.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                1,000 hours
                              </span>{" "}
                              vCPU time.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                Plan Add-Ons
                              </span>
                              available.
                            </Typography>
                          </ListItem>
                        </List>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="default"
                            onClick={() => {}}
                            id="allFeatures"
                          >
                            View All Features
                          </Button>
                        </div>
                      </Card>

                      <Card
                        sx={{
                          ":hover": {
                            bgcolor: "#C0EBF8",
                          },
                          width: "19.063rem",
                          padding: "1.5rem 1rem",
                          boxShadow:
                            "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                          border: "0.063rem solid #CBD9E1",
                          borderRadius: "0.25rem",
                          paddingBottom: "0.625rem",
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 1 auto",
                          alignSelf: "self-end",
                        }}
                      >
                        <div>
                          <Chip
                            label="Popular"
                            sx={{
                              "&": {
                                position: "absolute",
                                top: 5,
                                marginLeft: "10rem",
                                padding: "0.25rem 0.625rem",
                                fontFamily: "Roboto Medium",
                                color: "#F6FDFF",
                                fontSize: "0.75rem",
                                backgroundColor: "#3CC13B",
                              },
                            }}
                            color="success"
                          />
                        </div>
                        <div
                          style={{
                            display: "flex",
                            marginTop: "1.875rem",
                            gap: "0.5rem",
                            justifyContent: "center",
                          }}
                        >
                          <StarHalfIcon
                            color="primary"
                            sx={{ width: "2.188rem", height: "2.188rem" }}
                          />
                          <div>
                            <Typography
                              gutterBottom
                              fontWeight={600}
                              lineHeight={1}
                              style={{
                                fontSize: "2.125rem",
                                display: "inline-block",
                              }}
                            >
                              Medium
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="div"
                          align="left"
                          fontWeight={500}
                          lineHeight={1.5}
                          style={{
                            marginTop: "0",
                            fontSize: "1rem",
                            display: "flex",
                            paddingBottom: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          Perfect for getting started
                        </Typography>
                        <div style={{ display: "flex", gap: "0.5rem" }}></div>
                        <List>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                100 tb
                              </span>
                              of Storage Capacity.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                10,000 hours
                              </span>{" "}
                              vCPU time.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                Plan Add-Ons
                              </span>
                              available.
                            </Typography>
                          </ListItem>
                          <ListItem></ListItem>
                        </List>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="default"
                            onClick={() => {}}
                            id="allFeatures"
                          >
                            View All Features
                          </Button>
                        </div>
                      </Card>

                      <Card
                        sx={{
                          ":hover": { bgcolor: "#C0EBF8" },
                          width: "19.063rem",
                          padding: "1.5rem 1rem",
                          boxShadow:
                            "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                          border: "0.063rem solid #CBD9E1",
                          borderRadius: "0.25rem",
                          paddingBottom: "0.625rem",
                          display: "flex",
                          flexDirection: "column",
                          flex: "1 1 auto",
                          alignSelf: "self-end",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: "1.25rem",
                            gap: "0.5rem",
                            justifyContent: "center",
                          }}
                        >
                          <StarIcon
                            color="primary"
                            sx={{ width: "2.188rem", height: "2.188rem" }}
                          />
                          <div>
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="div"
                              align="left"
                              fontWeight={600}
                              lineHeight={1}
                              style={{
                                fontSize: "2.125rem",
                                display: "inline-block",
                              }}
                            >
                              Large
                            </Typography>
                          </div>
                        </div>
                        <Typography
                          gutterBottom
                          variant="body2"
                          component="div"
                          align="left"
                          fontWeight={500}
                          lineHeight={1.5}
                          style={{
                            marginTop: "0",
                            fontSize: "1rem",
                            display: "flex",
                            paddingBottom: "1rem",
                            justifyContent: "center",
                          }}
                        >
                          Perfect for getting started
                        </Typography>
                        <div style={{ display: "flex", gap: "0.5rem" }}></div>
                        <List>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                500 tb
                              </span>
                              of Storage Capacity.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                50,000 hours
                              </span>{" "}
                              vCPU time.
                            </Typography>
                          </ListItem>
                          <ListItem>
                            <CheckCircleIcon
                              color="primary"
                              sx={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginRight: "1.25rem",
                              }}
                            />
                            <Typography
                              variant="body1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              lineHeight={1}
                            >
                              <span
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#0A85C7",
                                  lineHeight: "1rem",
                                  marginRight: "0.188rem",
                                }}
                              >
                                Plan Add-Ons
                              </span>
                              available.
                            </Typography>
                          </ListItem>
                          <ListItem></ListItem>
                          <ListItem></ListItem>
                        </List>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            variant="default"
                            onClick={() => {}}
                            id="allFeatures"
                          >
                            View All Features
                          </Button>
                        </div>
                      </Card>
                    </Card>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </TabPanel>
          <TabPanel value="3" sx={{ marginTop: "4.5rem", padding: 0 }}>
            <div
              style={{
                display: "flex",
                gap: "4.5rem",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{
                    color: "#0A85C7",
                    lineHeight: "1.625rem",
                    padding: "0.625rem 0",
                  }}
                >
                  Technical Specifications
                </Typography>
                <Typography
                  variant="h4"
                  fontSize="1.625rem"
                  // fontWeight={600}
                  component="div"
                  style={{}}
                >
                  How Does SS&C Everywhere Integrate Data Protection Best
                  Practices?
                </Typography>
                <div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem 0",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize="1.25rem"
                      style={{ color: "#02273C", lineHeight: "1.5rem" }}
                    >
                      Enhanced Security
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ color: "#000", lineHeight: "1.5rem" }}
                    >
                      At the core of the SOC2 framework is the assurance of
                      robust security measures. SS&C Everywhere integrates
                      state-of-the-art security protocols to ensure unauthorized
                      access, data breaches, and other threats are meticulously
                      guarded against.
                    </Typography>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem 0",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize="1.25rem"
                      style={{ color: "#02273C", lineHeight: "1.5rem" }}
                    >
                      Guaranteed Availability
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ color: "#000", lineHeight: "1.5rem" }}
                    >
                      SOC2 ensures that the system is available for operation
                      and use, as committed or agreed upon. This means SS&C
                      Everywhere offers a platform that's consistently up and
                      running, minimizing disruptions to businesses.
                    </Typography>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem 0",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize="1.25rem"
                      style={{ color: "#02273C", lineHeight: "1.5rem" }}
                    >
                      Processing Integrity
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ color: "#000", lineHeight: "1.5rem" }}
                    >
                      With SOC2's focus on data processing integrity, SS&C
                      Everywhere ensures that data processing is timely,
                      authorized, and accurate, eliminating risks of data
                      manipulation.
                    </Typography>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem 0",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize="1.25rem"
                      style={{ color: "#02273C", lineHeight: "1.5rem" }}
                    >
                      Strict Confidentiality
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ color: "#000", lineHeight: "1.5rem" }}
                    >
                      For financial institutions and businesses where data
                      confidentiality is paramount, SS&C Everywhere's adherence
                      to SOC2 ensures that data designated as confidential
                      remains protected and disclosed only to authorized
                      entities.
                    </Typography>
                  </div>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem 0",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h6"
                      fontSize="1.25rem"
                      style={{ color: "#02273C", lineHeight: "1.5rem" }}
                    >
                      End-to-End Privacy
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      padding: "0.625rem",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="body1"
                      style={{ color: "#000", lineHeight: "1.5rem" }}
                    >
                      Beyond just confidentiality, privacy protocols make
                      certain that personal information is collected, used,
                      retained, and disclosed in conformity with the platform's
                      privacy policies.
                    </Typography>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </TabPanel>
          <TabPanel value="4" sx={{ padding: "4.5rem 0 0 0", display: "flex" }}>
            <div style={{ width: "50rem", position: "relative" }}>
              <Typography
                variant="h3"
                color="primary"
                marginBottom={"1.25rem"}
                style={{ fontSize: "2.125rem", fontWeight: 600 }}
              >
                Documentation
              </Typography>

              <div
                id="release-notes"
                style={{ marginLeft: "0.625rem", marginTop: "1.25rem" }}
              ></div>
              <div>
                <Grid>
                  <DocumentationCard
                    image={CARD_DATA[1]?.logo}
                    service={"Getting Started"}
                    title={"Data Mesh - An Introduction"}
                    date={"10/01/2024"}
                  />

                  <DocumentationCard
                    image={CARD_DATA[1]?.logo}
                    service={"For Intermediates"}
                    title={"Data Mesh - Hints and Tips"}
                    date={"01/02/2024"}
                  />
                </Grid>
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default DatameshServices;
