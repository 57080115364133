import * as React from "react";
import { useState } from "react";
import { Button } from "@ssce/ui-shared-library";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./ChorusEverywhere.css";
import { useNavigate } from "react-router-dom";
import ChorusEverywhereOverView from "./ChorusEverywhereOverView";
import ChorusEverywhereTechnicalSpecifications from "./ChorusEverywhereTechnicalSpecifications";
import ChorusEverywherePlansAndFeatures from "./ChorusEverywherePlansAndFeatures";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

const ChorusEverywhere: React.FC = () => {
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/ssnc.png"}
        title={"SS&C Chorus Everywhere"}
        contents={
          "SS&C Chorus Everywhere is an enterprise-level business process SS&C Chorus Everywhere is an enterprise-level reporting and analytics solution that utilizes data from Chorus to optimize workflows and automate customer-facing processes. It integrates AI-powered orchestration, connecting people, processes, data, and systems for efficient task distribution. The suite includes capabilities for digital process automation, case management, omni-channel interactions, and operational analytics. By leveraging data from Chorus, it enhances productivity, improves customer experiences, and reduces operational risks."
        }
        tags={[
          {
            label: "Automation",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Analytics Service",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <>
          <Button
            variant="default"
            width="12rem"
            id={"createInstance"}
            onClick={() =>
              navigate("/public-catalog/ss&c-chorus-everywhere/provision")
            }
          >
            Create Instance
          </Button>
          <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
            Getting Started
          </Button>
        </>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Technical Specifications" value="3" />
            <Tab label="Documentation" value="4" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <ChorusEverywhereOverView />
        </TabPanel>

        <TabPanel value="2" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <ChorusEverywherePlansAndFeatures />
        </TabPanel>
        <TabPanel value="3" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <ChorusEverywhereTechnicalSpecifications />
        </TabPanel>
        <TabPanel
          value="4"
          sx={{ padding: "4.5rem 0 0 0", display: "flex" }}
        ></TabPanel>
      </TabContext>
      <div className="flex-row  justifyContent-center gap1rem">
        <Button
          variant="default"
          width="12rem"
          id={"createInstance"}
          onClick={() =>
            navigate("/public-catalog/ss&c-chorus-everywhere/provision")
          }
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </div>
    </>
  );
};
export default ChorusEverywhere;
