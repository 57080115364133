import { Reducer } from "redux";
import { UserAction, UserActionType } from "./actions";
import { createDefaultState, UserState } from "./state";

type UserReducer = Reducer<UserState, UserAction>;
const reducer: UserReducer = (state = createDefaultState(), action) => {
  switch (action.type) {
    case UserActionType.SUCCESSFUL_LOGIN: {
      return { ...state, loggedIn: true, ...action.payload };
    }

    case UserActionType.USER_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        token: null,
        username: null,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
