import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Postgresql.css";
import PostgresqlPricing from "./PostgresqlPricing";

const PostgresqlPlansAndFeatures: React.FC = () => {
  return (
    <>
      <div className={"gap4rem flex-col"}>
        <div className={"gap1rem flex-col"}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Pricing Plans and Features
          </Typography>
          <div className={"gap2rem flex-row"}>
            <div className={"gap1rem flex-col"}>
              <Typography
                variant="h6"
                fontSize="1.25rem"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                PostgreSQL includes
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                {" "}
                <ul>
                  <li>
                    Support for various advanced data types such as JSON, XML,
                    arrays, hstore, and geometric types.
                  </li>
                  <li>
                    Full adherence to ACID principles, ensuring reliable
                    transaction processing and data integrity.
                  </li>
                  <li>
                    The ability to create custom functions, operators, data
                    types, and even new procedural languages.
                  </li>
                  <li>
                    Powerful full-text search capabilities with support for
                    complex queries, ranking, and stemming.
                  </li>
                  <li>
                    Efficient handling of multiple simultaneous transactions
                    without locking through MVCC.
                  </li>
                  <li>
                    Support for stored procedures and triggers for automated
                    execution of code in response to specific events.
                  </li>
                  <li>
                    Built-in support for replication and high availability,
                    ensuring data redundancy and fault tolerance.
                  </li>
                  <li>
                    The ability to query and integrate data from external
                    sources through the use of foreign data wrappers.
                  </li>
                </ul>
              </Typography>
            </div>
          </div>
          <PostgresqlPricing title={"Plan Options & Details"} />
        </div>
      </div>
    </>
  );
};

export default PostgresqlPlansAndFeatures;
