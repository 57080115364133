import * as React from "react";
import { useState, useEffect } from "react";
import {
  useProvisionDataMeshHooks,
  fundList,
} from "../ProvisionService/provisionDataMeshHooks";
import { InstanceNameProvision } from "../ProvisionService/provisionDataMeshComponents";
import {
  SelectRegionProvision,
  UserManagementProvision,
  SolutionConfigurationProvision,
  ReviewBillingProvision,
  ProvisionInstanceButton,
} from "../ProvisionService/provisionGeneralComponents";
import { ProvisionSuccessfulPage } from "../ProvisionService/provisionSuccessfulPage";
import { ErrorNotification } from "@ssce/ui-shared-library";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";
import CatalogSubHeading from "../CatalogSubHeading";
import Divider from "@mui/material/Divider";
import MsftsqlImage from "../../../../assets/Msftsql.png";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";

type FundListData = {
  id: number;
};

type TableFundList = {
  id: number;
}[];

const MsftsqlProvision: React.FC = () => {
  const {
    createInstances,
    fetchCatalogID,
    setInstanceName,
    setProjectId,
    setIsLoading,
    hasError,
    catalogId,
    instanceName,
    projectId,
    projects,
    hasProjectIdError,
    hasInstanceNameError,
    isLoading,
  } = useProvisionDataMeshHooks();

  const [isProvisionSuccessful, setIsProvisionSuccessful] = useState(false);
  const [selectedRegion, setSelectedRegion] = useState("NorthAmerica");
  const [_, setAddFundList] = useState<FundListData[]>([]);
  const [tableFundList] = useState<TableFundList>([]);

  const handleProvisionInstance = async () => {
    const success = await createInstances({
      instanceName,
      projectId,
      catalogId,
      applicationName: "msftsql",
      region: selectedRegion,
    });
    let successAsBool = success as unknown as boolean;
    setIsLoading(false);
    setIsProvisionSuccessful(!!successAsBool);
  };

  useEffect(() => {
    fetchCatalogID("msftsql");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updateData = fundList.filter(
      (fund) => !tableFundList.some((item) => item.id === fund.id),
    );

    setAddFundList(updateData);
  }, [tableFundList]);

  const onCreateAnotherInstance = () => {
    setIsProvisionSuccessful(false);
    setInstanceName("");
    setSelectedRegion("NorthAmerica");
    setProjectId("");
  };

  return (
    <>
      <div className="algo-performance-provision-container">
        {hasError && <ErrorNotification />}
        {!isProvisionSuccessful ? (
          !isLoading ? (
            <>
              <>
                <div className="catalogSuperset">
                  <CatalogSubHeading
                    title={"Provision Service"}
                    sx={{ padding: "0.625rem 0" }}
                  />
                  <div>
                    <Divider />
                  </div>
                </div>
                <MyServicesCardHeader
                  imageSource={MsftsqlImage}
                  title={"Microsoft SQL Server 2019"}
                  contents={
                    "Microsoft SQL Server 2019 is a relational database management system (RDBMS) that provides advanced capabilities for enterprise data management, big data integration, and intelligent performance tuning. It supports high availability and disaster recovery solutions, ensuring continuous business operations. Additionally, it offers comprehensive security features, including data encryption and access control, to protect sensitive information."
                  }
                  tags={[
                    {
                      label: "Data Management",
                      style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
                    },
                    {
                      label: "Analytics",
                      style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
                    },
                    {
                      label: "Web Server",
                      style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
                    },
                  ]}
                ></MyServicesCardHeader>
              </>

              <InstanceNameProvision
                value={instanceName}
                hasInstanceNameError={hasInstanceNameError}
                handleInstanceNameChange={(value: string) =>
                  setInstanceName(value)
                }
              />
              <SelectRegionProvision
                selectedRegion={selectedRegion}
                handleRegionChange={(value: string) => setSelectedRegion(value)}
                regions={[{ label: "North America", value: "NorthAmerica" }]}
              />
              <UserManagementProvision
                handleProjectChange={(projectId: string) =>
                  setProjectId(projectId)
                }
                projects={projects}
                projectId={projectId}
                hasProjectIdError={hasProjectIdError}
              />
              <SolutionConfigurationProvision isGlobal={false} />
              <ReviewBillingProvision />
              <ProvisionInstanceButton
                handleProvisionInstance={handleProvisionInstance}
              />
            </>
          ) : (
            <LoadingAnimation />
          )
        ) : (
          <ProvisionSuccessfulPage
            onCreateAnotherInstance={onCreateAnotherInstance}
          />
        )}
      </div>
    </>
  );
};

export default MsftsqlProvision;
