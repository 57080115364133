import { FC } from "react";
import Image1 from "../../../../../assets/Image0001.png";
import Image2 from "../../../../../assets/DataMeshImage.png";
import DocArrow from "../../../../../assets/SquaredArrow.png";
import {
  MainTitle,
  Section,
  Subtitle,
  StyledCardContent,
  CardTitle,
  Description,
  CardDescription,
  Arrow,
  StyledCard,
  StyledBox,
  CardsGeneralSection,
  StyledCardMedia,
  CardSection,
  DocArrowImg,
} from "./DataMeshDocumentation";

interface Props {}

export const DataMeshIntro: FC<Props> = () => {
  return (
    <div>
      <MainTitle>Documentation</MainTitle>
      <Section>
        <Subtitle>Introduction</Subtitle>
        <Description>
          In the dynamic world of modern business, seamless integration of
          software solutions is more than just a competitive advantage—it's a
          necessity. SS&C Technologies, a name synonymous with pioneering
          innovation, introduces the SS&C Everywhere Data Mesh - a cloud
          solution to streamline management of data from SS&C products and
          services, third party data, and your own data wherever it lives. The
          SS&C Everywhere Data Mesh platform provides an interface for users to
          centralize their data to make it more accessible to the rapidly
          changing needs of a user’s organization
        </Description>
      </Section>

      <CardsGeneralSection>
        <StyledCard>
          <StyledCardMedia image={Image1} />
          <StyledBox>
            <StyledCardContent>
              <CardSection>
                <StyledCardContent
                  onClick={() =>
                    (window.location.href =
                      "/documentation/introducing-the-ss&c-everywhere-data-mesh")
                  }
                >
                  <div>
                    <CardTitle>
                      Introducing <br></br>Data Mesh
                    </CardTitle>
                    <CardDescription>
                      SS&C Technologies, a name synonymous with pioneering
                      innovation, introduces the SS&C Everywhere Data Mesh
                    </CardDescription>
                  </div>
                  <Arrow>
                    <DocArrowImg src={DocArrow} alt="arrow"></DocArrowImg>
                  </Arrow>
                </StyledCardContent>
              </CardSection>
            </StyledCardContent>
          </StyledBox>
        </StyledCard>
      </CardsGeneralSection>

      <Section>
        <Subtitle>Getting Started</Subtitle>
        <Description>
          Welcome to Data Mesh! This section will guide you through the
          essential steps to quickly set up and start using our service. Whether
          you're a new user exploring the functionalities or an experienced
          developer integrating with our API, these steps will ensure a smooth
          onboarding process.
        </Description>
      </Section>

      <CardsGeneralSection>
        <StyledCard>
          <StyledCardMedia image={Image2} />
          <StyledBox>
            <StyledCardContent>
              <CardSection>
                <StyledCardContent
                  onClick={() =>
                    (window.location.href =
                      "/documentation/introducing-the-ss&c-everywhere-data-mesh")
                  }
                >
                  <div>
                    <CardTitle>Getting Started</CardTitle>
                    <CardDescription>
                      Welcome to SS&C Technology's revolutionary Data Mesh
                      offering, an integral part of the SS&C Everywhere
                      platform.
                    </CardDescription>
                  </div>
                  <Arrow>
                    <DocArrowImg src={DocArrow} alt="arrow"></DocArrowImg>
                  </Arrow>
                </StyledCardContent>
              </CardSection>
            </StyledCardContent>
          </StyledBox>
        </StyledCard>
      </CardsGeneralSection>
    </div>
  );
};
