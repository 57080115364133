import { useState, useEffect, FC, useCallback } from "react";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Button } from "@ssce/ui-shared-library";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import Accordion from "../Accordion";
import { InstancesDetails, Roles } from "../../../../common/dataTypes";
import InfoIcon from "@mui/icons-material/Info";
import CreateIcon from "@mui/icons-material/Create";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import PermissionTable from "../PermissionTable";
import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import { fetchRoles } from "../../../../redux/rolesManagement/actions";
import {
  fetchResources,
  setShowCreateProject,
  createProject,
} from "../../../../redux/projectsManagement/actions";
import { LoadingAnimation } from "../../subcomponents/LoadingAnimation";

const mapStateToProps = (state: AppState) => ({
  roles: state.rolesManagement.roleList,
  isProjectDetailsLoading: state.projectsManagement.isProjectDetailsLoading,
  resourcesList: state.projectsManagement.resourcesList,
});

const mapDispatchToProps = {
  fetchResources,
  fetchRoles,
  setShowCreateProject,
  createProject,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const CreateProjects: FC<Props> = ({
  resourcesList,
  roles,
  isProjectDetailsLoading,
  setShowCreateProject,
  fetchResources,
  fetchRoles,
  createProject,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedResources, setSelectedResources] = useState<
    InstancesDetails[]
  >([]);
  const [selectedRoles, setSelectedRoles] = useState<Roles[]>([]);

  const handleNext = () => {
    if (name.trim().length === 0) {
      setError(true);
      return;
    }
    setError(false);
    if (activeStep !== steps.length - 1)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCnfm = () => {
    console.log(
      "selectedRoles,selectedResources",
      selectedRoles,
      selectedResources,
    );

    const payload = {
      name,
      description,
      roles: selectedRoles.map((item) => {
        return { id: item.id };
      }),
      resources: selectedResources.map((item) => {
        return { id: item.instanceId };
      }),
    };
    console.log("payload", payload);
    createProject(payload);
  };

  const handleBack = () => {
    if (activeStep !== 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const fetchRolesCallback = useCallback(fetchRoles, [fetchRoles]);
  const fetchResourcesCallback = useCallback(fetchResources, [fetchResources]);

  useEffect(() => {
    fetchRolesCallback();
    fetchResourcesCallback();
  }, [fetchRolesCallback, fetchResourcesCallback]);

  const renderSelectedResources = () => {
    return selectedResources.map((item) => {
      return (
        <Accordion
          key={item.instanceName}
          name={item.instanceName}
          iconHeader={<ClearIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            setSelectedResources([
              ...selectedResources.filter(
                (r) => item.instanceId !== r.instanceId,
              ),
            ]);
          }}
        >
          <Typography
            style={{ color: "#0A85C7", fontSize: 12, marginLeft: 20 }}
          >
            Description
          </Typography>
          <Typography
            style={{
              color: "#02273C",
              fontWeight: 400,
              fontSize: 14,
              marginLeft: 20,
            }}
          >
            {item.description}
          </Typography>
          <br></br>
        </Accordion>
      );
    });
  };
  const renderAssignResources = () => {
    return (
      <div
        style={{
          border: "0.063rem solid #CBD9E1",
          width: 928,
          padding: "1rem 1.5rem",
          borderRadius: 4,
        }}
      >
        <Stack spacing={2} sx={{ width: 880, backgroundColor: "#F6FDFF" }}>
          <Autocomplete
            multiple
            id="create-projects-assign-Resources"
            size="medium"
            value={selectedResources}
            sx={{
              "& .MuiChip-root": {
                backgroundColor: "#B3D9EE",
                color: "#075E8D",
              },
              "& .MuiChip-root .MuiChip-deleteIcon": { color: "#0A85C7" },
            }}
            isOptionEqualToValue={(option, value) =>
              option.instanceId === value.instanceId
            }
            onChange={(_, values) => {
              setSelectedResources(values);
            }}
            options={resourcesList}
            getOptionLabel={(option) => option.instanceName}
            renderInput={(params) => (
              <TextField {...params} label="Resources" placeholder="" />
            )}
          />
        </Stack>
        <p
          style={{
            color: "#577687",
            marginTop: 0,
            marginBottom: "1rem",
            fontSize: 12,
          }}
        >
          Please use the dropdown box above or type in a Resources name to
          select it.
        </p>
        <Typography
          variant="subtitle2"
          style={{
            marginBottom: "1rem",
            color: "#02273C",
            fontWeight: 500,
            fontSize: "1.125rem",
          }}
        >
          Resources Addition Details
        </Typography>
        {renderSelectedResources()}
      </div>
    );
  };

  const renderSelectedRoles = () => {
    return selectedRoles.map((item) => {
      return (
        <Accordion
          key={item.name}
          name={item.name}
          iconHeader={<ClearIcon />}
          iconHeaderClickhandler={(e) => {
            e.stopPropagation();
            setSelectedRoles([
              ...selectedRoles.filter((r) => item.id !== r.id),
            ]);
          }}
        >
          <Typography
            style={{
              color: "#0A85C7",
              fontSize: 12,
              marginLeft: 20,
              marginBottom: 10,
            }}
          >
            Description
          </Typography>
          <Typography
            style={{
              color: "#02273C",
              fontWeight: 400,
              fontSize: 14,
              marginLeft: 20,
              marginBottom: 10,
            }}
          >
            {item.description}
          </Typography>
          <Divider></Divider>
          <br></br>
          <Typography
            style={{
              color: "#0979B5",
              fontWeight: 500,
              fontSize: 12,
              marginLeft: 20,
            }}
          >
            List of Permissions and Scope
          </Typography>
          <PermissionTable data={item.permissionTable} />
        </Accordion>
      );
    });
  };

  const renderAssignRoles = () => {
    return (
      <div
        style={{
          border: "0.063rem solid #CBD9E1",
          width: 928,
          padding: "1rem 1.5rem",
          borderRadius: 4,
        }}
      >
        <Stack spacing={2} sx={{ width: 880, backgroundColor: "#F6FDFF" }}>
          <Autocomplete
            multiple
            id="create-role-assign-roles"
            size="medium"
            value={selectedRoles}
            sx={{
              "& .MuiChip-root": {
                backgroundColor: "#B3D9EE",
                color: "#075E8D",
              },
              "& .MuiChip-root .MuiChip-deleteIcon": { color: "#0A85C7" },
            }}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_, values) => {
              setSelectedRoles(values);
            }}
            options={roles}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField {...params} label="Roles" placeholder="" />
            )}
          />
        </Stack>
        <p
          style={{
            color: "#577687",
            marginTop: 0,
            marginBottom: "1rem",
            fontSize: 12,
          }}
        >
          Please use the dropdown box above or type in a Roles name to select
          it.
        </p>
        <Typography
          variant="subtitle2"
          style={{
            marginBottom: "1rem",
            color: "#02273C",
            fontWeight: 500,
            fontSize: "1.125rem",
          }}
        >
          Roles Addition Details
        </Typography>
        {renderSelectedRoles()}
      </div>
    );
  };

  const renderReview = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 18,
          width: 763,
          height: 507,
          overflow: "auto",
          padding: "0.625rem 0 0",
        }}
      >
        <div style={{ height: 36, width: "100%", display: "flex" }}>
          <Typography
            style={{
              color: "#0A85C7",
              fontSize: 34,
              fontWeight: 600,
              lineHeight: "2.25rem",
            }}
          >
            <>
              {name.length < 30 ? (
                name
              ) : (
                <Tooltip
                  title={<span style={{ fontSize: "1.125rem" }}>{name}</span>}
                >
                  <span>{name.substring(0, 30) + "..."}</span>
                </Tooltip>
              )}
            </>
          </Typography>
          <CreateIcon
            id={"activeStep"}
            sx={{
              "&:hover": { color: "#0A85C7" },
              cursor: "pointer",
              color: "#02273C",
            }}
            style={{ width: 19, height: 19 }}
            onClick={() => setActiveStep(0)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 1.5rem",
            border: "0.063rem solid #CBD9E1",
            borderRadius: 4,
            width: 763,
            gap: 16,
          }}
        >
          <Typography
            variant="h6"
            style={{ fontSize: 18, lineHeight: "1.375rem", color: "#000000" }}
          >
            Resources added
          </Typography>
          <div>{renderSelectedResources()}</div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem 1.5rem",
            border: "0.063rem solid #CBD9E1",
            borderRadius: 4,
            width: 763,
            gap: 16,
          }}
        >
          <Typography
            variant="h6"
            style={{ fontSize: 18, lineHeight: "1.375rem", color: "#000000" }}
          >
            Roles added
          </Typography>
          <div>{renderSelectedRoles()}</div>
        </div>
      </div>
    );
  };

  const showOptionDlg = activeStep !== 0 && activeStep !== steps.length - 1;
  const start = activeStep === 0;
  const end = activeStep === steps.length - 1;

  if (isProjectDetailsLoading)
    return (
      <Box sx={style}>
        <LoadingAnimation />
      </Box>
    );
  return (
    <Box sx={style}>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            padding: "0.625rem 1.25rem",
            height: "4.438rem",
          }}
        >
          <ClearIcon
            id={"clearIcon"}
            onClick={() => setShowCreateProject(false)}
            style={{
              position: "absolute",
              right: "1.25rem",
              bottom: "1.25rem",
              top: "1.25rem",
              cursor: "pointer",
            }}
          />
        </div>
        <div>
          <div style={{ height: "5.5rem" }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step
                  key={label}
                  sx={{
                    "& .MuiStepLabel-root .Mui-completed": {
                      color: "#3CC13B",
                    },
                  }}
                >
                  <StepLabel>
                    <span style={{ color: "#02273C" }}>{label}</span>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </div>
          <div
            style={{
              height: "32.125rem",
              overflow: "auto",
              padding: "0.313rem 3.125rem",
              display: "flex",
              flexDirection: "column",
              gap: 21,
            }}
          >
            {showOptionDlg && (
              <div
                style={{
                  display: "flex",
                  position: "relative",
                  gap: 8,
                  padding: "0.625rem 1rem",
                  height: "5.25rem",
                  width: "56.875rem",
                  alignItems: "center",
                  border: "0.063rem solid #0A85C7",
                  borderRadius: 4,
                }}
              >
                <div style={{ width: 24, height: 24, marginBottom: "auto" }}>
                  <InfoIcon sx={{ color: "#0A85C7" }} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography style={{ fontSize: 16, fontWeight: 700 }}>
                    Optional Step
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    This step in the process is optional and can be skipped for
                    now, with the option to add it later from the{" "}
                    <span style={{ fontWeight: 900 }}>Projects</span> page. To
                    skip this step just press the “Next” button at the bottom of
                    this modal.
                  </Typography>
                </div>
              </div>
            )}
            {activeStep === 0 && (
              <div
                style={{ display: "flex", flexDirection: "column", gap: 40 }}
              >
                <Typography
                  variant="h6"
                  style={{
                    color: "#02273C",
                    fontWeight: 500,
                    fontSize: "1.25rem",
                    height: 24,
                    width: 63,
                  }}
                >
                  Details
                </Typography>
                <TextField
                  style={{ width: "100%", height: 56, background: "#F6FDFF" }}
                  id="outlined-helperText"
                  label="Project Name"
                  placeholder="Project Name"
                  background-color="white"
                  value={name}
                  inputProps={{ maxLength: 40 }}
                  error={error}
                  helperText={error ? "Project Name is required." : ""}
                  onChange={(e) => setName(e.target.value)}
                />
                <TextField
                  sx={{ width: "100%", height: 104, background: "#F6FDFF" }}
                  id="outlined-helperText"
                  minRows={3}
                  multiline={true}
                  label="Description"
                  placeholder="Description"
                  background-color="white"
                  value={description}
                  inputProps={{ maxLength: 200 }}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
            )}

            {activeStep === 1 && (
              <>
                <Typography
                  variant="h6"
                  style={{
                    color: "#02273C",
                    fontWeight: 500,
                    fontSize: "1.25rem",
                  }}
                >
                  Assign Resources
                </Typography>
                {renderAssignResources()}
              </>
            )}
            {activeStep === 2 && (
              <>
                <Typography
                  variant="h6"
                  style={{
                    color: "#02273C",
                    fontWeight: 500,
                    fontSize: "1.25rem",
                  }}
                >
                  Assign Roles
                </Typography>
                {renderAssignRoles()}
              </>
            )}
            <div
              style={{
                width: 900,
                height: 711,
                overflowY: "auto",
                padding: "0.125rem 3.125rem 2rem",
                display: "flex",
                flexDirection: "column",
                gap: 21,
              }}
            >
              {activeStep === 3 && (
                <>
                  {renderReview()}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 21,
                      width: 728,
                      height: "auto",
                      padding: "0.75rem 0",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 10,
                        width: 598,
                        height: 36,
                        padding: "0 0.625rem",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#0468B1",
                          fontWeight: 700,
                          fontSize: "2.125rem",
                        }}
                      >
                        Please Confirm
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        gap: 10,
                        width: 837,
                        height: 68,
                        padding: "0.625rem",
                      }}
                    >
                      <Typography
                        style={{
                          color: "#000000",
                          fontWeight: 500,
                          fontSize: "1.25rem",
                        }}
                      >
                        After carefully reviewing the aforementioned details,
                        you may proceed to finalize by clicking the confirmation
                        button below.{" "}
                        <span style={{ fontWeight: 700 }}>
                          Please click “Confirm” to continue.
                        </span>
                      </Typography>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            background: "#DFE9EF",
            justifyContent: "flex-end",
            display: "flex",
            gap: "0.625rem",
            padding: "0.625rem 1.25rem",
          }}
        >
          {start && (
            <Button
              variant="secondary"
              id={"close"}
              onClick={() => setShowCreateProject(false)}
            >
              Close
            </Button>
          )}
          {!start && (
            <Button id={"back"} onClick={handleBack} variant="secondary">
              Back
            </Button>
          )}
          {!end && (
            <Button id={"next"} onClick={handleNext} variant="default">
              Next
            </Button>
          )}
          {end && (
            <Button onClick={handleCnfm} id={"confirm"} variant="default">
              Confirm
            </Button>
          )}
        </div>
      </div>
    </Box>
  );
};

const steps = ["Details", "Assign Resources", "Assign Roles", "Review"];

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1028,
  height: 756,
  bgcolor: "#EDF4F8",
  border: "0 solid #000",
  boxShadow:
    "0 0.5rem 0.625rem rgba(2, 39, 60, 0.2), 0 0.375rem 1.875rem rgba(2, 39, 60, 0.12), 0 1rem 1.5rem rgba(2, 39, 60, 0.14)",
  p: 10,
  m: 2,
  lineHeight: 2,
  padding: "0 0 0 0",
};

export default connector(CreateProjects);
