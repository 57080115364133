import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Oracle.css";

const OracleTechnicalSpecifications: React.FC = () => {
  const RenderTechDetails = ({ heading, desc }) => {
    return (
      <div>
        <div className="flex-col">
          <Typography
            variant="h6"
            fontSize="1.25rem"
            sx={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {heading}
          </Typography>
        </div>
        <div className="flex-col padding1rem">
          <Typography
            variant="body1"
            sx={{ color: "#000", lineHeight: "1.5rem" }}
          >
            {desc}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex-col gap4rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Technical Specifications
          </Typography>
          <RenderTechDetails
            heading={"Native Vector Data Type"}
            desc={
              "Oracle Database 23ai supports vector embeddings as a native data type, enabling efficient storage and querying of AI-driven data directly within the database."
            }
          />
          <RenderTechDetails
            heading={"AI Smart Scan"}
            desc={
              "Exadata System Software 24ai includes AI Smart Scan, which processes data on storage servers, reducing unnecessary data transfers and accelerating AI Vector Search."
            }
          />
          <RenderTechDetails
            heading={"RAFT-Based Replication"}
            desc={
              "The Globally Distributed Database uses RAFT-based replication to ensure automatic failover with zero data loss in under 10 seconds, enhancing fault tolerance."
            }
          />
          <RenderTechDetails
            heading={"JSON Relational Duality Views"}
            desc={
              "This feature allows simultaneous storage and retrieval of data in both JSON and relational formats, bridging the gap between different data models without compromising consistency."
            }
          />
          <RenderTechDetails
            heading={"ISO Standard SQL/PGQ Syntax"}
            desc={
              "Oracle Database 23ai supports property graph queries using the ISO standard SQL/PGQ syntax, enabling developers to work with graph data seamlessly."
            }
          />
          <RenderTechDetails
            heading={"Transactionally Consistent In-Memory Cache"}
            desc={
              "Oracle True Cache maintains transactionally consistent data in memory, providing real-time data access without the need for custom cache management."
            }
          />
          <RenderTechDetails
            heading={"Autonomous Database Support"}
            desc={
              "Oracle Database 23ai is fully integrated with Oracle's Autonomous Database services, offering self-managing, self-securing, and self-repairing capabilities."
            }
          />
          <RenderTechDetails
            heading={"Built-in Machine Learning Algorithms"}
            desc={
              "The database includes a variety of in-database machine learning algorithms for classification, clustering, ranking, and anomaly detection, eliminating the need for external tools."
            }
          />
          <RenderTechDetails
            heading={"Oracle Data Safe Integration"}
            desc={
              "Oracle Data Safe provides centralized management of multiple SQL firewalls, allowing scalable protection against SQL injection and unauthorized queries."
            }
          />
          <RenderTechDetails
            heading={"High-Performance Middle-Tier Cache"}
            desc={
              "True Cache offers an always-consistent, high-performance mid-tier cache that reduces the load on database servers while maintaining query capabilities like SQL, JSON, and Graph."
            }
          />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default OracleTechnicalSpecifications;
