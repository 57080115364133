import * as React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CurrentViewContext } from "../../../ComponentWithContext";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import { Button } from "@ssce/ui-shared-library";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";

const KafkaReport: React.FC = () => {
  const navigate = useNavigate();
  const { viewData } = useContext(CurrentViewContext);

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/ssnc.png"}
        title={"Apache Kafka"}
        subTitle={`Apache Kafka, ${viewData.region}`}
        contents={
          "Apache Kafka is an open-source distributed event streaming platform used by thousands of companies for high-performance data pipelines, streaming analytics, data integration, and mission-critical applications. It provides a unified, high-throughput, low-latency platform for handling real-time data feeds. Kafka is designed to be fault-tolerant, scalable, and durable, making it ideal for a wide range of use cases."
        }
        tags={[
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Web Application",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs title={"Apache Kafka"} instanceName={"Apache Kafka"} />
    </>
  );
};
export default KafkaReport;
