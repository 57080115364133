import {
  Description,
  PublishContainer,
  TextContainer,
  Title,
  Text,
} from "../publishApi.styles";

export const NameInputApi = ({ serviceName }) => {
  return (
    <PublishContainer>
      <TextContainer>
        <Title>API Name</Title>
        <Description>
          The API name was assigned by the Data Product owner during its
          creation in Data Mesh.
        </Description>
      </TextContainer>
      <Text
        variant="standard"
        id="instance-name"
        placeholder="Type Here.."
        value={serviceName}
      />
    </PublishContainer>
  );
};
