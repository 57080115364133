import * as React from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./PowerBI.css";
import { CurrentViewContext } from "../../../ComponentWithContext";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { Button } from "@ssce/ui-shared-library";

const PowerBIReport: React.FC = () => {
  const navigate = useNavigate();
  const { viewData } = useContext(CurrentViewContext);

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/powerbi.png"}
        title="Microsoft Power BI"
        subTitle={`Microsoft Power BI, ${viewData.region}`}
        contents={
          "Microsoft Power BI is a business analytics tool that allows users to visualize and analyze data from various sources. It provides interactive reports, dashboards, and visualizations to help businesses make informed decisions based on data insights."
        }
        tags={[
          {
            label: "BI",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs title={"Microsoft Power BI"} instanceName={"PowerBI"} />
    </>
  );
};
export default PowerBIReport;
