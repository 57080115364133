import {
  createDefaultState as createDefaultSnackbarState,
  SnackbarState,
} from "./snackbars/state";
import {
  createDefaultState as createDefaultCurrentNavRailState,
  TabType,
} from "./current-navrail/state";
import {
  createDefaultState as createDefaultUserState,
  UserState,
} from "./user/state";
import {
  createDefaultState as createDefaultUserManagement,
  UserManagementState,
} from "./userManagement/state";
import {
  createDefaultState as createDefaultRolesManagement,
  RolesManagementState,
} from "./rolesManagement/state";
import {
  createDefaultState as createDefaultProjectsManagement,
  ProjectsManagementState,
} from "./projectsManagement/state";
import {
  createDefaultMyservicesState,
  MyservicesState,
} from "./myServices/state";

export interface MarketplaceState {
  snackbars: SnackbarState;
  currentNavRail: TabType;
  user: UserState;
  userManagement: UserManagementState;
  rolesManagement: RolesManagementState;
  projectsManagement: ProjectsManagementState;
  myservices:MyservicesState;
}

export const createDefaultState = (): MarketplaceState => ({
  snackbars: createDefaultSnackbarState(),
  currentNavRail: createDefaultCurrentNavRailState(),
  user: createDefaultUserState(),
  userManagement: createDefaultUserManagement(),
  rolesManagement: createDefaultRolesManagement(),
  projectsManagement: createDefaultProjectsManagement(),
  myservices:createDefaultMyservicesState()
});
