import * as React from "react";
import { DummyPlanDetails } from "./dummyPlanDetails";
import SummaryList from "src/components/main/MyServices/Cards/CardTemplate/SummaryList";
import { DummyInvoices } from "./dummyInvoices";
import { DummyReportsTab } from "./dummyReportsTab";

export const DummyOverviewContent: React.FC<{
  title: string;
  instanceName: string;
  subscriptionName?: string;
  reportTableName?: string;
  isRiskThinking?: boolean;
  nextPaymentDueDate?: string;
}> = ({
  title,
  instanceName,
  subscriptionName,
  reportTableName,
  isRiskThinking,
  nextPaymentDueDate,
}) => {
  const DATA = [
    {
      name: "Subscription ID",
      value: "1231232-2323-2123232",
    },
    {
      name: "My role",
      value: "Account admin",
    },
    {
      name: "Current Billing Period",
      value: "Apr 30, 2024 - Oct 31, 2024",
    },
    {
      name: "Subscription Name",
      value: subscriptionName ?? instanceName,
    },
    {
      name: "Status",
      value: "Active",
    },
    {
      name: "Instance Name",
      value: instanceName,
    },
    {
      name: "Plan",
      value: "Premium",
    },
  ];
  return (
    <>
      <DummyPlanDetails
        isRiskThinking={isRiskThinking}
        nextPaymentDueDate={nextPaymentDueDate}
      />
      <DummyReportsTab
        title={title}
        reportTableName={reportTableName}
        isRiskThinking={isRiskThinking}
      />
      <SummaryList data={DATA} />
      <DummyInvoices />
    </>
  );
};
