import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  min-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const UserNoAccessPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Wrapper>
        <div>
          <Typography
            variant="h1"
            color="#0A85C7"
            marginBottom={"1rem"}
            textAlign="center"
            style={{ fontSize: "2.125rem", marginTop: "3.125rem" }}
          >
            You do not currently have access to this page.
          </Typography>
          <Typography
            variant="h1"
            color="#355263"
            fontWeight={500}
            fontFamily="Roboto Medium"
            fontSize={20}
            textAlign="center"
          >
            It looks like this account does not have the necessary permissions
            to view this page yet. Please contact your administrator for further
            assistance.
          </Typography>
          <div style={{ padding: "1.5rem 0 3.875rem 0" }}>
            <Typography
              variant="h1"
              color="#355263"
              fontWeight={100}
              fontFamily="Roboto"
              fontSize={16}
              textAlign="center"
              margin="1.25rem"
              marginTop="0"
              lineHeight={"1.5rem"}
            >
              If you require immediate assistance or have pressing questions,
              feel free to contact our customer service team directly at:
            </Typography>

            <Typography
              variant="h1"
              fontFamily="Roboto"
              fontSize={16}
              textAlign="center"
              margin="1.25rem"
              marginTop="0"
              lineHeight={"1.5rem"}
            >
              <a href="mailto:SSCE_Tech_Support@sscinc.com">
                SSCE_Tech_Support@sscinc.com
              </a>
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: "100%",
              gap: "1rem",
            }}
          >
            <Button
              variant="secondary"
              onClick={() => navigate(-1)}
              id="backToPrevious"
            >
              GO BACK TO PREVIOUS PAGE
            </Button>
            <Button
              variant="default"
              onClick={() => navigate("/")}
              id="toHomepage"
            >
              TAKE ME TO THE HOMEPAGE
            </Button>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default UserNoAccessPage;
