import * as React from "react";
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { InputAdornment } from "@mui/material";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import {} from "react-router-dom";
import { baseUrl } from "../../../constants";
import moment from "moment";
import Grid from "../subcomponents/Grid/Grid";
import DocumentationCard from "./DocumentationCard";

const GettingStarted: React.FC = () => {
  const [items, setItems] = useState<any>([]);
  const [searching, setSearching] = useState<any>("");
  const [scrollTop, setScrollTop] = useState<any>(0);
  const [checked, setChecked] = React.useState([""]);

  useEffect(() => {
    fetch(`${baseUrl}/api/gettingstarted`)
      .then((response) => response.json())
      .then((data) => {
        setItems(data);
      });
  }, []);

  const handleChangeForSearch = (e: { target: { value: any } }) => {
    setSearching(e.target.value);
  };

  const handleDelete = (i: string) => {
    setChecked(checked.filter((item) => item !== i));
  };

  //searching
  const filtered = items.filter((item: { title: string }) =>
    item.title.toLowerCase().includes(searching.toLowerCase()),
  );

  //Virtual Scrolling
  const rowHeight = 30;
  const limit = 30;
  const startNode = Math.ceil(scrollTop / rowHeight);
  const visibleData = filtered.slice(0, startNode + limit);

  return (
    <>
      <Card sx={{ marginLeft: "1.25rem", boxShadow: "none" }}></Card>
      <div>
        <Typography style={{ fontSize: "1rem", marginBottom: "1.875rem" }}>
          Explore this page to access Getting Started documentation related to
          the services available in the SS&C Everywhere Catalogs.
        </Typography>

        <div>
          <Box
            component="form"
            sx={{
              m: 1,
              width: "39.625rem",
              marginLeft: "0.625rem",
              marginRight: 5,
              color: "#0A85C7",
              borderRadius: "0.25rem",
            }}
            noValidate
            autoComplete="off"
          >
            <TextField
              id="outlined-basic123"
              placeholder="Search by name"
              variant="outlined"
              onChange={handleChangeForSearch}
              sx={{
                width: "37.5rem",
                borderRadius: "0.5rem",
                border: "0.063rem solid #B6C7D1",
              }}
              InputProps={{
                style: {
                  height: "3.063rem",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ color: "grey", fontSize: "1.438rem" }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </div>
        <div style={{ marginLeft: "1.25rem" }}>
          {checked.map((item) => {
            if (item !== "") {
              return (
                <Stack
                  direction="row"
                  spacing={1}
                  style={{ display: "inline-block" }}
                >
                  <Chip
                    label={item}
                    style={{
                      backgroundColor: "#DFE9EF",
                      fontSize: "0.875rem",
                      color: "#355263",
                      marginRight: "0.313rem",
                    }}
                    onDelete={() => handleDelete(item)}
                  />
                </Stack>
              );
            }
            return null;
          })}
        </div>
        <Grid
          onScroll={(e) => {
            setScrollTop(e.currentTarget.scrollTop + rowHeight);
          }}
        >
          {visibleData.map((item) => {
            return (
              <DocumentationCard
                image={item.logo}
                service={item.service}
                title={item.title}
                date={moment(item.date).format("MM/DD/YYYY")}
              />
            );
          })}
        </Grid>
      </div>
    </>
  );
};

export default GettingStarted;
