import { dataMeshRedirectURL } from "src/constants";

export const TEST_HEALTH_ENDPOINT = () => ({
  request: {
    cURL: [
      `curl \\`,
      `'${dataMeshRedirectURL}/output-api/health' \\`,
      `--header 'Api-key: <YOUR_API_KEY>' `,
    ],
  },
  response: [`OK`],
});

export const USE_CASE_ENDPOINT = (
  productId,
  limit,
  responseType,
  parameters,
) => {
  return {
    request: {
      cURL: [
        `curl -X 'POST' \\`,
        `'${dataMeshRedirectURL}/output-api/view/custom-api/${productId}?responseType=${responseType}&limit=${limit}' \\`,
        `--header "Content-Type: application/json" \\`,
        `--header 'Api-key: <YOUR_API_KEY>' \\`,
        `--data '${JSON.stringify(parameters)}'`,
      ],
    },
    response: {
      data: [
        { id: "123", name: "John Doe", email: "john@example.com", age: "23" },
        {
          id: "456",
          name: "Otto Mation",
          email: "otto@example.com",
          age: "89",
        },
      ],
    },
  };
};

export const SCHEMA_ENDPOINT = (apiId) => ({
  request: {
    cURL: [
      `curl \\`,
      `'${dataMeshRedirectURL}/output-api/view/schema/explain/${apiId}?responseType=TypeScript' \\`,
      `--header 'Api-key: <YOUR_API_KEY>' `,
    ],
  },
  response: {
    columns: [
      { columnName: "col", dataType: "varchar" },
      { columnName: "col2", dataType: "string" },
    ],
  },
});
