import * as React from "react";
import { useNavigate } from "react-router-dom";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import { DummyCardTabs } from "../CardTemplate/DummyCardContent/index";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import { Button } from "@ssce/ui-shared-library";

const ChorusReport: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/ssnc.png"}
        title={"Chorus Everywhere"}
        subTitle="SS&C Chorus Everywhere"
        contents={
          "SS&C Chorus Everywhere is an enterprise-level business process management solution designed to optimize workflows and automate customer-facing processes. It integrates AI-powered orchestration, connecting people, processes, data, and systems for efficient task distribution. The suite includes digital process automation, case management, omni-channel interactions, and operational analytics, enhancing productivity and improving customer experiences while reducing operational risks."
        }
        tags={[
          {
            label: "Automation",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Analytics Service",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => navigate("/support")}
        >
          Get Support
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <DummyCardTabs
        title={"Chorus"}
        instanceName={"SS&C Chorus Everywhere"}
        isRiskThinking={true}
      />
    </>
  );
};
export default ChorusReport;
