import { useState, useEffect } from "react";
import axios from "axios";
import { baseUrl } from "../../../constants";

export const usePrivateCatalogHook = () => {
  const [catalogs, setCatalogs] = useState<any>([]);
  const [instances, _] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const items = axios.get(
      `${baseUrl}/api/mktplc-catalog-api/v0/private/services/applications/data_mesh`,
    );
    //const publicInstances = axios.get(`${baseUrl}/api/mktplc-catalog/v1/private/services/applications/data_mesh/all/public-instances`,);
    Promise.all([items])
      .then((response) => {
        const [items] = response;
        setCatalogs(items.data);
        //setInstances(instances.data);
        setIsLoading(false);
        setHasError(false);
      })
      .catch(() => {
        setHasError(true);
        setIsLoading(false);
      });
  }, []);
  return {
    isLoading,
    catalogs,
    hasError,
    instances,
  };
};
