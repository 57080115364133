import * as React from "react";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";

export const Description = (props) => {
  return (
    <div className="pc-instances-header-text" style={{}}>
      <Typography variant="h6" fontWeight={500}>
        {props.Typography}
      </Typography>
      <Tooltip title={props.title} className="pc-info-icon">
        <IconButton>
          <InfoIcon color="primary" />
        </IconButton>
      </Tooltip>
    </div>
  );
};
