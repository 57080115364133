import { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import DownloadIcon from "@mui/icons-material/Download";
import FolderIcon from "@mui/icons-material/Folder";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import moment from "moment";
import { ErrorNotification, Table, Button } from "@ssce/ui-shared-library";
import { baseUrl } from "../../../../../constants";
import { CurrentViewContext } from "../../../ComponentWithContext";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import { PUBLIC_CATALOG_APPLICATION_NAME } from "../../../../../constants";

import { GetUserEmail } from "../../../../../redux/user/selectors";
import styled from "styled-components";

const ReportsTableContainer = styled.div`
  margin-bottom: 7rem;
`;

export default function ReportsTable({
  title,
  newReports = false,
  showDashBoardButton = true,
}) {
  const [items, setItems] = useState<any[]>([]);
  const [tableColumnHeader, setTableColumnHeader] = useState<any[]>([]);
  const [selectedPaths, setSelectedPaths] = useState<any[]>([]);
  const [refreshTable, setRefreshTable] = useState(0);
  const [isDownloadDisabled, setIsDownloadDisabled] = useState(true);
  const [hasError, setHasError] = useState(false);
  const email = GetUserEmail();

  const [isProvisioning, setIsProvisioning] = useState(false);
  const { viewData } = useContext(CurrentViewContext);

  const downloadReports = useCallback(
    async (selectedRowPaths: string[]) => {
      try {
        const response = await axios.post(
          `${baseUrl}/mktplc-reports/v0/reports/download_reports?user=${email}&solution_name=${viewData.serviceName}`,
          { paths_list: selectedRowPaths },
          { responseType: "blob" },
        );
        setHasError(false);
        const blob = new Blob([response.data], {
          type: "application/octet-stream",
        });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Reports.zip");
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        setHasError(true);
        console.log({ error: error });
      }
    },
    [email, viewData.serviceName],
  );
  const renderTableHeader = useCallback(
    (data) => {
      const defaultHeader = {
        title: "Action",
        render: (_, row) => (
          <>
            {
              <Button
                id={row.id}
                onClick={(event) => {
                  event.stopPropagation();
                  downloadReports([row.path]);
                }}
              >
                <DownloadIcon />
              </Button>
            }
          </>
        ),
      };
      const tabeleHeaderDataArray = data.map((item) => {
        return {
          title: item,
          dataIndex: item,
          defaultSortOrder: "asc",
          sorter: true,
          ...dynamicTableRender(item),
        };
      });
      tabeleHeaderDataArray.push(defaultHeader);
      setTableColumnHeader(tabeleHeaderDataArray);
    },
    [downloadReports],
  );

  const dynamicTableRender = (index) => {
    switch (index) {
      case "Run Date":
        return { render: (cell) => moment(cell).format("ll") };
      case "Status":
        return {
          render: (_, row) => (
            <>
              {row.Status === "New" ? (
                <Chip
                  label={row.Status}
                  color="success"
                  variant="outlined"
                  sx={{
                    borderRadius: "0.5rem",
                    backgroundColor: "#ECF9EB",
                  }}
                />
              ) : null}
            </>
          ),
        };
    }
  };

  const downloadSelectedReport = () => {
    if (selectedPaths.length) {
      setIsDownloadDisabled(true);
      downloadReports(selectedPaths);
    }
    setRefreshTable((value) => ++value);
  };

  const downloadAllReports = () => {
    const rows = [...items];
    const selectedRowPaths = rows.map((rowData: any) => rowData.path);
    if (selectedRowPaths.length) {
      downloadReports(selectedRowPaths);
    }
  };

  const updateSelectedItem = (ids) => {
    const paths = items
      .filter((item) => ids.includes(item.id))
      .map((item) => item.path);

    setSelectedPaths(paths);
    setIsDownloadDisabled(paths.length === 0);
  };

  useEffect(() => {
    axios
      .get(
        `${baseUrl}/mktplc-reports/v0/reports/table/${viewData.instanceId}?solution_name=${viewData.serviceName}${newReports ? "&status=new" : ""}`,
      )
      .then((res) => {
        setHasError(false);
        if (res.data.data) {
          renderTableHeader(res.data.header);
          setItems(res.data.data);
        }
      })
      .catch(() => {
        setHasError(true);
      });
  }, [
    newReports,
    renderTableHeader,
    viewData.instanceId,
    viewData.serviceName,
  ]);
  const openDashboard = (url) => window.open(url);

  const handleOpenRiskDashboard = () => {
    if (isProvisioning) return false;

    const instance_UUID = viewData.instanceId;

    setIsProvisioning(true);
    axios
      .get(`${baseUrl}/api/mktplc-awa-ims/v1/instance/${instance_UUID}`)
      .then((res) => {
        setHasError(false);
        if (res.data) res.data.url && openDashboard(res.data.url);
        setIsProvisioning(false);
      })
      .catch(() => {
        setIsProvisioning(false);
        setHasError(true);
      });
  };
  const buttonText =
    viewData.applicationName === PUBLIC_CATALOG_APPLICATION_NAME.riskReport
      ? "Risk"
      : "Performance";

  return (
    <>
      <div className="MS-AlgoRisk-csv-report-title-container">
        {hasError && <ErrorNotification />}
        <Typography
          fontSize="1.875rem"
          fontWeight={500}
          className="MS-AlgoRisk-csv-report-title"
        >
          {title}
        </Typography>
        {showDashBoardButton && (
          <Button
            variant="default"
            id={"openRiskDashboard"}
            onClick={handleOpenRiskDashboard}
          >
            {isProvisioning === false ? (
              <>
                Open {buttonText} Dashboard
                <OpenInNewIcon sx={{ marginLeft: "0.625rem" }} />
              </>
            ) : (
              <Stack sx={{ color: "#fff" }} spacing={1} direction="row">
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ marginTop: "0.3125rem" }}
                />
                <span>Starting {buttonText} Dashboard</span>
              </Stack>
            )}
          </Button>
        )}
      </div>
      <div style={{ display: "flex", gap: "1rem" }}>
        <Button
          onClick={downloadSelectedReport}
          id={"downloadReport"}
          isDisabled={isDownloadDisabled || selectedPaths.length === 0}
          variant="secondary"
        >
          Download <DownloadIcon sx={{ marginLeft: "0.625rem" }} />
        </Button>
        <Button
          id={"downloadAll"}
          onClick={downloadAllReports}
          variant="secondary"
        >
          Download All <FolderIcon sx={{ marginLeft: "0.625rem" }} />
        </Button>
      </div>
      <ReportsTableContainer>
        {tableColumnHeader.length > 0 ? (
          <Table
            key={refreshTable}
            id={"Reports Table"}
            title={"Reports"}
            data={items}
            columns={tableColumnHeader}
            showCheckboxes
            showSearchFilter
            showPagination
            onCheckboxChange={(checked) => {
              updateSelectedItem(checked);
            }}
          />
        ) : null}
      </ReportsTableContainer>
    </>
  );
}
