import { FC } from "react";

import Accordionc from "../Accordion";
import Typography from "@mui/material/Typography";

import { connect, ConnectedProps } from "react-redux";
import { AppState } from "../../../../redux/store";
import { fetchProjectsUsers } from "../../../../redux/projectsManagement/actions";

const mapStateToProps = (state: AppState) => ({
  selectedProject: state.projectsManagement.selectedProject,
  projectUsers: state.projectsManagement.projectUsers,
});

const mapDispatchToProps = {
  fetchProjectsUsers,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type Props = ConnectedProps<typeof connector>;

const Users: FC<Props> = ({ projectUsers }) => {
  const renderUsers = () => {
    return projectUsers.map((item) => {
      return (
        <div style={{ flex: "0 49%" }}>
          <Accordionc
            key={item.firstName}
            name={`${item.firstName} ${item.lastName}`}
          >
            <Typography
              style={{
                color: "#02273C",
                fontWeight: 400,
                fontSize: 14,
                marginLeft: 20,
              }}
            >
              Email : {item.email}
            </Typography>
          </Accordionc>
        </div>
      );
    });
  };

  return (
    <>
      <div style={{ width: "90%" }}>
        <h1
          style={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: 20,
            lineHeight: 0,
            textAlign: "left",
            color: "#02273C",
          }}
        >
          Users
        </h1>
        <br></br>
        <div style={{ display: "flex", columnGap: "0.5rem", flexWrap: "wrap" }}>
          {renderUsers()}
        </div>
        <br></br>
      </div>
    </>
  );
};

export default connector(Users);
