import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Kafka.css";
import KafkaPricing from "./KafkaPricing";

const KafkaPlansAndFeatures: React.FC = () => {
  return (
    <>
      <div className={"gap4rem flex-col"}>
        <div className={"gap1rem flex-col"}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Pricing Plans and Features
          </Typography>
          <div className={"gap2rem flex-row"}>
            <div className={"gap1rem flex-col"}>
              <Typography
                variant="h6"
                fontSize="1.25rem"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                Apache Kafka includes
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                {" "}
                <ul>
                  <li>High Throughput and Low Latency</li>
                  <li>Horizontal Scalability</li>
                  <li>Distributed Architecture</li>
                  <li>Data Replication and Durability</li>
                  <li>Real-time Stream Processing</li>
                  <li>Consumer Group Management</li>
                  <li>Partitioned Log Structure</li>
                  <li>Message Retention and Replay</li>
                  <li>Integration with Multiple Data Sources</li>
                  <li>Support for Event-driven Microservices</li>
                </ul>
              </Typography>
            </div>
          </div>
          <KafkaPricing title={"Plan Options & Details"} />
        </div>
      </div>
    </>
  );
};

export default KafkaPlansAndFeatures;
