import { useState, useRef } from "react";
import { CompoundDocumentationNav } from "../CompoundDocumentationNav";
import { IntroDataMeshCopy } from "./IntroDataMesh/IntroDataMeshCopy";
import { MasteringQueriesCopy } from "./IntroDataMesh/MasteringQueriesCopy";
import { PowerOfChatGPTCopy } from "./IntroDataMesh/PowerOfChatGPTCopy";
import { TransformingQueriesCopy } from "./IntroDataMesh/TransformingQueriesCopy";
import { ConnectingYourDataCopy } from "./IntroDataMesh/ConnectingYourDataCopy";
import { HowToCreateDataProduct } from "./IntroDataMesh/HowToCreateDataProduct";
import styled from "styled-components";

const Wrapper = styled.div`
  padding-top: 2.5rem;
`;

export const IntroDataMeshPage = () => {
  const [blogPost, setBlogPost] = useState<JSX.Element | null>(null);
  const scrollRef = useRef<HTMLDivElement>(null);

  const onBlogClick = (blog) => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
    switch (blog) {
      case 1:
        setBlogPost(<ConnectingYourDataCopy />);
        break;
      case 2:
        setBlogPost(<TransformingQueriesCopy />);
        break;
      case 3:
        setBlogPost(<MasteringQueriesCopy />);
        break;
      case 4:
        setBlogPost(<PowerOfChatGPTCopy />);
        break;
      case 5:
        setBlogPost(<HowToCreateDataProduct />);
        break;
      default:
        setBlogPost(null);
    }
  };

  return (
    <>
      <Wrapper ref={scrollRef}>
        <CompoundDocumentationNav onBlogClick={onBlogClick} />
        {!blogPost ? <IntroDataMeshCopy /> : blogPost}
      </Wrapper>
    </>
  );
};
