import styled from "styled-components";

export const ErrorBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 3 / 5;
  padding: 0.313rem;
  font-size: 0.75rem;
  background-color: #fac1c1;
`;

export const ViewError = styled.span`
  margin-left: 0.625rem;
  text-decoration: underline;
  line-height: 1.25rem;
  font-size: 0.875rem;
  cursor: pointer;
`;

export const Header = styled.div`
  font-size: 1.5rem;
  color: #0a85c7;
  font-weight: 600;
  line-height: 1.5rem;
  margin-bottom: 0.625rem;
  grid-column: 1 / -1;
`;

export const WorkflowHeaderContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content 2fr;
  margin-bottom: 3.125rem;
`;

export const OverviewTitle = styled.div`
  font-size: 1.25rem;
  grid-column: 1 / 3;
`;

export const RunActivityTitle = styled.div`
  font-size: 1.25rem;
  grid-column: 1 / -1;
`;

export const Label = styled.div`
  font-size: 1rem;
  font-weight: 300;
`;

export const Value = styled.div<{ isValid: boolean }>`
  font-size: 1rem;
  font-weight: 600;
  color: ${(props) => (props.isValid ? "#355263" : "#75909f")};
`;

export const OverviewContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 3.125rem;
  align-items: center;
  grid-template-rows: max-content max-content max-content;
  grid-row-gap: 0.625rem;
`;

export const RunActivityContainer = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr max-content 1fr;
  column-gap: 3.125rem;
`;

export const HorizontalRule = styled.div`
  border: 0.063rem solid #cbd9e1;
  margin: 0.313rem 3.125rem;
`;
