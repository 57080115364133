import { Route, Routes, Navigate } from "react-router-dom";
import styled from "styled-components";
import UserNoAccessPage from "./subcomponents/UserNoAccessPage";
import DataServices from "./DataServices/DataServices";
import Support from "./Support/Support";
import Subcategory from "./Billing/Subcategory";
import DigitalWorkers from "./DigitalWorkers/DigitalWorkers";
import ComponentWithContext from "./ComponentWithContext";
import { PrivateCatalog, PCDatameshServices } from "./PrivateCatalog";
import {
  DocumentationCards,
  DocumentationContent,
  APIDocs,
  GettingStarted,
  CodeSamples,
  Regulatory,
  ReleaseNotes,
} from "./Documentation";
import {
  Settings,
  ManageListingHomePage,
  ApiKeys,
  PublishApiContainer,
} from "./Settings";
import {
  WorkflowCatalog,
  WorkflowViewer,
  WorkflowViewerTable,
} from "./Workflow";
import {
  RiskThinkingMyservice,
  DataMeshServices,
  MSAlgoRiskReport,
  ChorusReport,
  PowerBIReport,
  PostgresqlReport,
  OracleReport,
  KafkaReport,
  TarvosReport,
  NifiReport,
  MsftsqlReport,
  TableauReport,
  SightlineReport,
  TaxBrightlineReport,
  Superset,
  PrivateApiServices,
} from "./MyServices/Cards";
import {
  UnownedServices,
  DatameshServices,
  ProvisionDataMesh,
  CatalogAlgoRiskReport,
  AlgoPerformance,
  ChorusEverywhere,
  PowerBI,
  Postgresql,
  Oracle,
  Tableau,
  Kafka,
  Nifi,
  Tarvos,
  Msftsql,
  TaxBrightline,
  ChorusEverywhereProvision,
  PostgresqlProvision,
  TableauProvision,
  TaxBrightlineProvision,
  Sightline,
  SightlineProvision,
  AlgoPerformanceProvision,
  CatalogAlgoRiskProvision,
  CatalogSupersetProvision,
  OracleProvision,
  PowerBIProvision,
  Riskthinking,
  CatalogSuperSet,
  MsftsqlProvision,
  TarvosProvision,
  CatalogAlgoRiskSuccess,
  RiskthinkingProvision,
  KafkaProvision,
  NifiProvision,
  AlgoPerformanceAndReports,
} from "./Catalog";
import { ComingSoonPage } from "src/ComingSoonPage";
import { useAclHooks } from "../main/subcomponents/Acl/hooks";
import { PERMISSION_LIST } from "../../constants";
import MyServices from "./MyServices";
import PublicCatalog from "./Catalog/publicCatalog";
import { Dashboard } from "./Dashboard";

const Heading = styled.h1`
  color: var(--primary);
`;

type RoutedComponentsProps = {
  handleBreadCrumbNickName: (name: string) => void;
  handleBreadCrumbClick: (e: any, obj: string) => void;
  currentPageTabValue?: string;
  setCurrentPageTabValue: (value: string) => void;
  setActiveMenuItem: (value: string) => void;
};
const viewDataStorage = JSON.parse(localStorage.getItem("serviceData") ?? "{}");

const RoutedComponents = ({
  handleBreadCrumbNickName,
  handleBreadCrumbClick,
  currentPageTabValue,
  setCurrentPageTabValue,
  setActiveMenuItem,
}: RoutedComponentsProps) => {
  return (
    <ComponentWithContext initState={viewDataStorage}>
      <Routes>
        <Route
          path="/"
          element={
            <Dashboard handleBreadCrumbNickName={handleBreadCrumbNickName} />
          }
        />
        <Route
          path="/dashboard"
          element={
            <Dashboard handleBreadCrumbNickName={handleBreadCrumbNickName} />
          }
        />
        <Route
          path="/my-services"
          element={
            <MyServices handleBreadCrumbNickName={handleBreadCrumbNickName} />
          }
        />
        <Route path="/public-catalog/riskthinking" element={<Riskthinking />} />
        <Route
          path="/public-catalog/riskthinking/provision"
          element={<RiskthinkingProvision />}
        />
        <Route
          path="/my-services/:serviceName"
          element={<PrivateApiServices />}
        />
        <Route
          path="/my-services/data_mesh_platform"
          element={<DataMeshServices />}
        />
        <Route path="/my-services/chorus" element={<ChorusReport />} />
        <Route path="/my-services/powerbi" element={<PowerBIReport />} />
        <Route path="/my-services/msftsql" element={<MsftsqlReport />} />
        <Route path="/my-services/postgresql" element={<PostgresqlReport />} />
        <Route path="/my-services/oracle" element={<OracleReport />} />
        <Route path="/my-services/kafka" element={<KafkaReport />} />
        <Route path="/my-services/tarvos" element={<TarvosReport />} />
        <Route path="/my-services/nifi" element={<NifiReport />} />
        <Route
          path="/my-services/tax_brightline"
          element={<TaxBrightlineReport />}
        />
        <Route path="/my-services/tableau" element={<TableauReport />} />
        <Route path="/my-services/sightline" element={<SightlineReport />} />
        <Route
          path="/my-services/algorithmics-reports"
          element={<MSAlgoRiskReport />}
        />
        <Route path="/my-services/superset" element={<Superset />} />
        <Route
          path="/my-services/risk_thinking"
          element={<RiskThinkingMyservice />}
        />
        <Route
          path="/public-catalog"
          element={
            <PublicCatalog
              handleBreadCrumbNickName={handleBreadCrumbNickName}
            />
          }
        />
        <Route
          path="/public-catalog/msftsql/provision"
          element={<MsftsqlProvision />}
        />
        <Route path="/public-catalog/msftsql" element={<Msftsql />} />
        <Route
          path="/public-catalog/msftsql/provision"
          element={<MsftsqlProvision />}
        />
        <Route path="/public-catalog/msftsql" element={<Msftsql />} />
        <Route
          path="/public-catalog/data-mesh"
          element={<DatameshServices />}
        />
        <Route
          path="/public-catalog/apache-superset"
          element={<CatalogSuperSet />}
        />
        <Route
          path="/public-catalog/apache-superset/provision"
          element={<CatalogSupersetProvision />}
        />
        <Route
          path="/public-catalog/ssnc-advent-geneva-api"
          element={<UnownedServices />}
        />
        <Route
          path="/public-catalog/data-mesh/provision"
          element={<ProvisionDataMesh />}
        />
        <Route
          path="/public-catalog/ss&c-algorithmics-risk-reports"
          element={<CatalogAlgoRiskReport />}
        />
        <Route
          path="/public-catalog/ss&c-algorithmics-risk-reports/provision"
          element={<CatalogAlgoRiskProvision />}
        />
        <Route
          path="/public-catalog/ss&c-algorithmics-risk-reports/provision/success"
          element={<CatalogAlgoRiskSuccess />}
        />
        <Route
          path="/public-catalog/ss&c-risk-&-performance-service"
          element={<AlgoPerformanceAndReports />}
        />

        <Route path="/private-api-catalog" element={<PrivateCatalog />} />
        <Route path="/digital-workers" element={<DigitalWorkers />} />
        <Route
          path="/public-catalog/ss&c-algorithmics-performance-reports"
          element={<AlgoPerformance />}
        />
        <Route
          path="/public-catalog/ss&c-chorus-everywhere"
          element={<ChorusEverywhere />}
        />
        <Route path="/public-catalog/powerbi" element={<PowerBI />} />
        <Route path="/public-catalog/postgresql" element={<Postgresql />} />
        <Route path="/public-catalog/oracle" element={<Oracle />} />
        <Route path="/public-catalog/kafka" element={<Kafka />} />
        <Route path="/public-catalog/tarvos" element={<Tarvos />} />
        <Route path="/public-catalog/nifi" element={<Nifi />} />
        <Route
          path="/public-catalog/ss&c-tax-brightline-everywhere"
          element={<TaxBrightline />}
        />
        <Route path="/public-catalog/tableau" element={<Tableau />} />
        <Route
          path="/public-catalog/ss&c-sightline-everywhere"
          element={<Sightline />}
        />
        <Route
          path="/public-catalog/ss&c-algorithmics-performance-reports/provision"
          element={<AlgoPerformanceProvision />}
        />
        <Route
          path="/public-catalog/ss&c-sightline-everywhere/provision"
          element={<SightlineProvision />}
        />
        <Route
          path="/public-catalog/ss&c-chorus-everywhere/provision"
          element={<ChorusEverywhereProvision />}
        />
        <Route
          path="/public-catalog/powerbi/provision"
          element={<PowerBIProvision />}
        />
        <Route
          path="/public-catalog/postgresql/provision"
          element={<PostgresqlProvision />}
        />
        <Route
          path="/public-catalog/oracle/provision"
          element={<OracleProvision />}
        />
        <Route
          path="/public-catalog/kafka/provision"
          element={<KafkaProvision />}
        />
        <Route
          path="/public-catalog/tarvos/provision"
          element={<TarvosProvision />}
        />
        <Route
          path="/public-catalog/nifi/provision"
          element={<NifiProvision />}
        />
        <Route
          path="/public-catalog/tableau/provision"
          element={<TableauProvision />}
        />
        <Route
          path="/public-catalog/ss&c-tax-brightline-everywhere/provision"
          element={<TaxBrightlineProvision />}
        />
        <Route
          path="/private-api-catalog/:catalogId"
          element={
            <PCDatameshServices
              handleBreadCrumbNickName={handleBreadCrumbNickName}
              handleBreadCrumbClick={handleBreadCrumbClick}
            />
          }
        />
        <Route path="/dataservices" element={<DataServices />} />
        <Route path="/my-workflows" element={<WorkflowViewerTable />} />
        <Route
          path="/workflow-catalog"
          element={
            <>
              <Heading>Workflow Catalog</Heading>
              <WorkflowCatalog />
            </>
          }
        />
        <Route
          path="/workflow-catalog/:workflowId"
          element={
            <WorkflowViewer
              handleBreadCrumbNickName={handleBreadCrumbNickName}
            />
          }
        />
        <Route
          path="/my-workflows/:workflowId"
          element={
            <WorkflowViewer
              handleBreadCrumbNickName={handleBreadCrumbNickName}
            />
          }
        />
        <Route path="/support" element={<Support />} />
        <Route
          path="/manage-listings"
          element={
            <>
              <Heading>Manage Listings</Heading>
              <ManageListingHomePage />
            </>
          }
        />
        <Route
          path="/manage-listings/publish-api"
          element={
            <>
              <PublishApiContainer setActiveMenuItem={setActiveMenuItem} />
            </>
          }
        />
        <Route path="/billing" element={<UserNoAccessPage />} />
        <Route
          path="/billing/all-equities-portfolio"
          element={<Subcategory />}
        />
        <Route
          path="/documentation"
          element={
            <>
              <Heading>Documentation</Heading>
              <DocumentationCards />
            </>
          }
        />
        <Route
          path="/documentation/getting-started"
          element={<GettingStarted />}
        />
        <Route path="/documentation/api-docs" element={<APIDocs />} />
        <Route path="/documentation/code-samples" element={<CodeSamples />} />
        <Route path="/documentation/regulatory" element={<Regulatory />} />
        <Route path="/documentation/release-notes" element={<ReleaseNotes />} />
        <Route
          path="documentation/introducing-the-ss&c-everywhere-data-mesh"
          element={
            <DocumentationContent copyToDisplay="introducing-the-ss&c-everywhere-data-mesh" />
          }
        />
        <Route
          path="documentation/announcing-the-launch-of-ss&c-algo-risk-reports-solution-in-everywhere-marketplace"
          element={
            <DocumentationContent copyToDisplay="announcing-the-launch-of-ss&c-algo-risk-reports-solution-in-everywhere-marketplace" />
          }
        />
        <Route
          path="documentation/ss&c-everywhere"
          element={<DocumentationContent copyToDisplay="ss&c-everywhere" />}
        />
        <Route path="/coming-soon" element={<ComingSoonPage setActiveMenuItem={setActiveMenuItem}/>} />
        <Route path="/dataservices" element={<DataServices />} />
        <Route path="/support" element={<Support />} />
        <Route
          path="/user-access-management"
          element={
            <Settings
              handleBreadCrumbNickName={handleBreadCrumbNickName}
              currentPageTabValue={currentPageTabValue}
              setCurrentPageTabValue={setCurrentPageTabValue}
            />
          }
        />
        <Route
          path="/apikeys"
          element={
            useAclHooks(PERMISSION_LIST.API_KEYS_READ) ? (
              <>
                <Heading>API Keys</Heading>
                <ApiKeys />
              </>
            ) : (
              <Navigate to="/" replace />
            )
          }
        />
      </Routes>
    </ComponentWithContext>
  );
};

export default RoutedComponents;
