import * as React from "react";
import { Table, Column } from "@ssce/ui-shared-library";
import { ServiceNameCell, TagCell } from "../../subcomponents/Table";
import { uniqBy } from "lodash";

type MyServicesTableViewProps = {
  filteredItems: any[];
  handleItemSelect?: (item: any) => void;
};

const MyservicesTableView: React.FC<MyServicesTableViewProps> = ({
  filteredItems,
  handleItemSelect,
}) => {
  const allTags = filteredItems.reduce((acc, cv) => {
    return [
      ...acc,
      {
        label: cv.tags.function,
        value: cv.tags.function,
      },
      {
        label: cv.tags.industry,
        value: cv.tags.industry,
      },
      {
        label: cv.tags.serviceType,
        value: cv.tags.serviceType,
      },
    ];
  }, []);
  const tagFilter = uniqBy(allTags, "value");
  const columns: Column[] = [
    {
      title: "Instance Name",
      dataIndex: "instanceName",
      sorter: true,
      searchByValue: (_, item) => item.instanceName,
      render: (_, item) => (
        <ServiceNameCell
          rowData={item}
          handleItemSelect={handleItemSelect}
          showInstanceName={true}
        />
      ),
    },
    {
      title: "Service Name",
      sorter: true,
      dataIndex: "serviceName",
    },
    {
      title: "Region",
      dataIndex: "region",
    },
    {
      title: "Plan",
      dataIndex: "plan",
      render: () => "N/A",
    },
    {
      title: "Tags",
      dataIndex: "tags",
      sorter: (a, b) => {
        // Sort by function, industry, serviceType, in that order
        if (a.tags.function) {
          if (a.tags.function > b.tags.function) {
            return 1;
          } else if (a.tags.function < b.tags.function) {
            return -1;
          }
        }
        if (a.tags.industry) {
          if (a.tags.industry > b.tags.industry) {
            return 1;
          } else if (a.tags.industry < b.tags.industry) {
            return -1;
          }
        }
        if (a.tags.serviceType) {
          if (a.tags.serviceType > b.tags.serviceType) {
            return 1;
          } else if (a.tags.serviceType < b.tags.serviceType) {
            return -1;
          }
        } else return 0;
      },
      filters: tagFilter,
      showFilterOnLeft: true,
      onFilter: (filterValue, selectedData) => {
        const { function: func, industry, serviceType } = selectedData.tags;
        return (
          filterValue === func ||
          filterValue === industry ||
          filterValue === serviceType
        );
      },
      searchByValue: (tags) => Object.values(tags).toString(),
      render: (tags) => <TagCell tags={tags} />,
    },
  ];

  return (
    <Table
      id={"table"}
      showSearchFilter={false}
      data={filteredItems}
      columns={columns}
    />
  );
};
export default MyservicesTableView;
