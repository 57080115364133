import * as React from "react";
import { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import Check from "../../../assets/Check.png";
import Users from "../../../assets/Users.png";
import CheckCircle from "../../../assets/CheckCircle.png";
import UnCheckCircle from "../../../assets/UnCheckCircle.png";
import Up from "../../../assets/Up.png";
import Twenty from "../../../assets/Twenty.png";
import Region from "./Regions";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { MyServicesCardHeader } from "../MyServices/Cards/CardTemplate/myServicesCardHeader";

const UnownedServices: React.FC = () => {
  const [value, setValue] = useState("1");
  const [items, setItems] = useState<any>([]);

  useEffect(() => {
    fetch("/api/services")
      .then((response) => response.json())
      .then((data) => {
        setItems(data);
      });
  }, []);

  const handleChange = (_, newValue: string) => {
    setValue(newValue);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));
  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(1rem)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "0.375rem solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <>
      {
        <>
          {items.map(() => {
            return (
              <>
                <MyServicesCardHeader
                  imageSource={"/api/assets/ssnc.png"}
                  title={"Apache Kafka"}
                  contents={
                    "Apache Kafka is an open-source distributed event streaming platform used by thousands of companies for high-performance data pipelines, streaming analytics, data integration, and mission-critical applications. It provides a unified, high-throughput, low-latency platform for handling real-time data feeds. Kafka is designed to be fault-tolerant, scalable, and durable, making it ideal for a wide range of use cases."
                  }
                  tags={[
                    {
                      label: "Cross-Industry",
                      style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
                    },
                    {
                      label: "Data & Analytics",
                      style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
                    },
                    {
                      label: "Web Application",
                      style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
                    },
                  ]}
                ></MyServicesCardHeader>

                <Box
                  sx={{
                    width: "64.063rem",
                    typography: "body1",
                    marginLeft: "16.063rem",
                  }}
                >
                  <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <TabList
                        id={"tabList"}
                        onChange={handleChange}
                        aria-label="lab API tabs example"
                      >
                        <Tab label="General" value="1" />
                        <Tab label="Capacity" value="2" />
                        <Tab label="Document" value="3" />
                        <Tab label="FAQs" value="4" />
                      </TabList>
                    </Box>
                    <TabPanel value="1">
                      <div style={{ display: "flex" }}>
                        <div style={{ width: "32%", marginRight: "0.938rem" }}>
                          <Typography
                            variant="h6"
                            fontSize="1.25rem"
                            fontWeight={600}
                            component="div"
                            style={{
                              color: "#02273C",
                              marginBottom: "0.313rem",
                            }}
                          >
                            Avalable Regions
                          </Typography>

                          <div
                            style={{ width: "32%", marginRight: "0.938rem" }}
                          >
                            <Region />
                          </div>
                        </div>

                        <div style={{ width: "32%", marginRight: "0.938rem" }}>
                          <Typography
                            variant="h6"
                            fontSize="1.25rem"
                            fontWeight={600}
                            component="div"
                            style={{
                              color: "#02273C",
                              marginBottom: "0.313rem",
                            }}
                          >
                            Location
                          </Typography>
                          <Typography
                            variant="body1"
                            fontSize="1rem"
                            fontWeight={400}
                            component="div"
                            align="left"
                            lineHeight={1.5}
                          >
                            The SS&C Advent Geneva API runs on the SS&C Private
                            Cloud. Choose a geography to deploy to that best
                            meets your organization's needs - whether to
                            minimize latency for performance, or meet your
                            jurisdiction's data regulations and best practices.
                          </Typography>
                        </div>
                        <div style={{ width: "32%" }}>
                          <Typography
                            variant="h6"
                            fontSize="1.25rem"
                            fontWeight={600}
                            component="div"
                            style={{
                              color: "#02273C",
                              marginBottom: "0.313rem",
                            }}
                          >
                            Security and Privacy First
                          </Typography>
                          <Typography
                            variant="body1"
                            fontSize="1rem"
                            fontWeight={400}
                            component="div"
                            align="left"
                            lineHeight={1.5}
                          >
                            All software and hardware solutions are built with
                            our client's security and privacy in mind. Our
                            solutions meet a global suite of regulations and
                            best practices to ensure your data is safe and
                            secure on our cloud.
                          </Typography>
                        </div>
                      </div>
                      <Box
                        style={{
                          marginTop: "3.125rem",
                          marginBottom: "6.25rem",
                        }}
                      >
                        <Typography
                          fontSize="2.125rem"
                          fontWeight={600}
                          component="div"
                          style={{
                            color: "#0A85C7",
                            marginLeft: "21.875rem",
                          }}
                        >
                          Ready to get started?
                        </Typography>
                        <div
                          style={{
                            marginTop: "2.5rem",
                            marginLeft: "6.25rem",
                          }}
                        >
                          <img
                            src={Check}
                            alt="15DayTrial"
                            style={{ display: "inline-block" }}
                          ></img>
                          <Typography
                            variant="body1"
                            fontSize="1.25rem"
                            fontWeight={500}
                            component="div"
                            align="left"
                            lineHeight={1.5}
                            style={{
                              display: "inline-block",
                              marginLeft: "1.563rem",
                              marginRight: "5.313rem",
                              color: "#000000",
                            }}
                          >
                            Free 15-day trial
                          </Typography>
                          <img
                            src={Check}
                            alt="immediatelyAccessible"
                            style={{ display: "inline-block" }}
                          ></img>
                          <Typography
                            variant="body1"
                            fontSize="1.25rem"
                            fontWeight={500}
                            component="div"
                            align="left"
                            lineHeight={1.5}
                            style={{
                              display: "inline-block",
                              marginLeft: "1.563rem",
                              marginRight: "5.313rem",
                              color: "#000000",
                            }}
                          >
                            Immediately Accessible
                          </Typography>
                          <img
                            src={Check}
                            alt="cancelAnytime"
                            style={{ display: "inline-block" }}
                          ></img>
                          <Typography
                            variant="body1"
                            fontSize="1.25rem"
                            fontWeight={500}
                            component="div"
                            align="left"
                            lineHeight={1.5}
                            style={{
                              display: "inline-block",
                              marginLeft: "1.563rem",
                              marginRight: "5.313rem",
                              color: "#000000",
                            }}
                          >
                            Cancel Anytime
                          </Typography>
                        </div>
                        <Typography
                          variant="body1"
                          fontSize="0.875rem"
                          fontWeight={400}
                          component="div"
                          align="center"
                          lineHeight={1.5}
                          color="#000000"
                          style={{
                            width: "60.125rem",
                            marginTop: "1.25rem",
                            marginLeft: "1.875rem",
                          }}
                        >
                          Whether you want to test out a free trial, use the
                          service periodically only when the need arises, or
                          integrate the capabilities into daily workflows,
                          there's a pricing solution that meets your needs!
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          marginTop: "3.125rem",
                          marginBottom: "6.25rem",
                        }}
                      >
                        <Typography
                          fontSize="1.25rem"
                          fontWeight={600}
                          style={{}}
                        >
                          Our pricing plans
                        </Typography>
                        <img
                          src={Twenty}
                          alt="twenty"
                          style={{
                            marginLeft: "21.875rem",
                            marginRight: "0.625rem",
                            width: "4.375rem",
                          }}
                        ></img>
                        <Stack
                          direction="row"
                          spacing={1}
                          alignItems="center"
                          style={{ marginBottom: "1.875rem" }}
                        >
                          <img
                            src={Up}
                            alt="up"
                            style={{
                              marginLeft: "23.75rem",
                              marginBottom: "1.25rem",
                              marginRight: "0.625rem",
                              width: "1.25rem",
                            }}
                          ></img>
                          <Typography fontSize="0.875rem" fontWeight={600}>
                            Billed Yearly
                          </Typography>
                          <FormControlLabel
                            control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
                            label=""
                          />
                          <Typography
                            fontSize="0.875rem"
                            fontWeight={600}
                            style={{}}
                          >
                            Billed Monthly
                          </Typography>
                        </Stack>
                      </Box>

                      <Card
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          marginBottom: "1.25rem",
                          marginTop: "-1.25rem",
                          gap: "1.25rem",
                          flex: "100%",
                          flexWrap: "wrap",
                          boxShadow: "none",
                        }}
                      >
                        <Card
                          sx={{
                            ":hover": { bgcolor: "#C0EBF8" },
                            width: "18.438rem",
                            height: "25.563rem",
                            boxShadow:
                              "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                            border: "0.063rem solid #CBD9E1",
                            borderRadius: "0.25rem",
                            paddingBottom: "0.625rem",
                            marginRight: "1.563rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "80%",
                              marginTop: "1.25rem",
                            }}
                          >
                            <img
                              src={Users}
                              alt="Users"
                              style={{
                                width: "1.813rem",
                                height: "1.25rem",
                                marginRight: "0.625rem",
                                marginLeft: "1.875rem",
                                marginTop: "0.5rem",
                              }}
                            ></img>
                            <div>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                                align="left"
                                fontWeight={600}
                                lineHeight={1}
                                style={{
                                  fontSize: "2.125rem",
                                  display: "inline-block",
                                }}
                              >
                                Lite
                              </Typography>
                            </div>
                          </div>
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            align="left"
                            fontWeight={500}
                            lineHeight={1.5}
                            style={{
                              marginTop: "0",
                              fontSize: "1rem",
                              marginLeft: "1.875rem",
                            }}
                          >
                            Perfect for getting started
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            align="center"
                            fontWeight={600}
                            style={{
                              marginTop: "3.75rem",
                              fontSize: "3.75rem",
                              marginBottom: "1.563rem",
                            }}
                          >
                            $0
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            align="center"
                            fontWeight={400}
                            style={{
                              fontSize: "0.875rem",
                              marginBottom: "1.25rem",
                            }}
                          >
                            per user, per month
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="secondary"
                              id={"getStarted"}
                              onClick={() => {}}
                            >
                              GET STARTED
                            </Button>
                          </div>

                          <List style={{ marginLeft: "3.125rem" }}>
                            <ListItem>
                              <img
                                src={CheckCircle}
                                alt="Free to Use"
                                style={{ marginRight: "0.625rem" }}
                              ></img>
                              <Typography
                                variant="body1"
                                fontSize="0.75rem"
                                fontWeight={500}
                                lineHeight={1}
                              >
                                Free to Use
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <img
                                src={CheckCircle}
                                alt="15DayCommitmentFreeTrial"
                                style={{ marginRight: "0.625rem" }}
                              ></img>
                              <Typography
                                variant="body1"
                                fontSize="0.75rem"
                                fontWeight={500}
                                lineHeight={1}
                              >
                                15-Day Commitment-free Trial
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <img
                                src={CheckCircle}
                                alt="Simulated, Realistic Data"
                                style={{ marginRight: "0.625rem" }}
                              ></img>
                              <Typography
                                variant="body1"
                                fontSize="0.75rem"
                                fontWeight={500}
                                lineHeight={1}
                              >
                                Simulated, Realistic Data
                              </Typography>
                            </ListItem>
                          </List>
                        </Card>
                        <Card
                          sx={{
                            ":hover": {
                              bgcolor: "#C0EBF8",
                            },
                            width: "18.438rem",
                            height: "25.563rem",
                            boxShadow:
                              "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                            border: "0.063rem solid #CBD9E1",
                            borderRadius: "0.25rem",
                            paddingBottom: "0.625rem",
                            marginRight: "1.563rem",
                          }}
                        >
                          <div
                            style={{ display: "flex", marginTop: "1.25rem" }}
                          >
                            <img
                              src={Users}
                              alt="Pay as you go"
                              style={{
                                width: "1.813rem",
                                height: "1.25rem",
                                marginRight: "0.625rem",
                                marginLeft: "0.625rem",
                                marginTop: "0.5rem",
                              }}
                            ></img>
                            <div>
                              <Typography
                                gutterBottom
                                fontWeight={600}
                                lineHeight={1}
                                style={{
                                  fontSize: "2.125rem",
                                  display: "inline-block",
                                }}
                              >
                                Pay as you go
                              </Typography>
                            </div>
                          </div>
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            align="left"
                            fontWeight={500}
                            lineHeight={1.5}
                            style={{
                              marginTop: "0",
                              fontSize: "1rem",
                              marginLeft: "1.875rem",
                            }}
                          >
                            For the infrequent workflow
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            align="center"
                            fontWeight={600}
                            style={{
                              marginTop: "3.75rem",
                              fontSize: "3.75rem",
                              marginBottom: "1.563rem",
                            }}
                          >
                            $125.00
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            align="center"
                            fontWeight={400}
                            style={{
                              fontSize: "0.875rem",
                              marginBottom: "1.25rem",
                            }}
                          >
                            per user, per month
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="secondary"
                              id={"getStarted"}
                              onClick={() => {}}
                            >
                              GET STARTED
                            </Button>
                          </div>

                          <List style={{ marginLeft: "3.125rem" }}>
                            <ListItem>
                              <img
                                src={CheckCircle}
                                alt="No miniums of thresholds"
                                style={{ marginRight: "0.625rem" }}
                              ></img>
                              <Typography
                                variant="body1"
                                fontSize="0.75rem"
                                fontWeight={500}
                                lineHeight={1}
                              >
                                No miniums of thresholds
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <img
                                src={CheckCircle}
                                alt="Pay only for what you use"
                                style={{ marginRight: "0.625rem" }}
                              ></img>
                              <Typography
                                variant="body1"
                                fontSize="0.75rem"
                                fontWeight={500}
                                lineHeight={1}
                              >
                                Pay only for what you use
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <img
                                src={CheckCircle}
                                alt="Add users anytime"
                                style={{ marginRight: "0.625rem" }}
                              ></img>
                              <Typography
                                variant="body1"
                                fontSize="0.75rem"
                                fontWeight={500}
                                lineHeight={1}
                              >
                                Add users anytime
                              </Typography>
                            </ListItem>
                          </List>
                        </Card>
                        <Card
                          sx={{
                            ":hover": { bgcolor: "#C0EBF8" },
                            width: "18.438rem",
                            height: "25.563rem",
                            boxShadow:
                              "0 0.063rem 0.313rem rgba(2, 39, 60, 0.2), 0 0.188rem 0.063rem rgba(2, 39, 60, 0.12), 0 0.125rem 0.125rem rgba(2, 39, 60, 0.14)",
                            border: "0.063rem solid #CBD9E1",
                            borderRadius: "0.25rem",
                            paddingBottom: "0.625rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "80%",
                              marginTop: "1.25rem",
                            }}
                          >
                            <img
                              src={Users}
                              alt="Subscription"
                              style={{
                                width: "1.813rem",
                                height: "1.25rem",
                                marginRight: "0.625rem",
                                marginLeft: "1.875rem",
                                marginTop: "0.5rem",
                              }}
                            ></img>
                            <div>
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="div"
                                align="left"
                                fontWeight={600}
                                lineHeight={1}
                                style={{
                                  fontSize: "2.125rem",
                                  display: "inline-block",
                                }}
                              >
                                Subscription
                              </Typography>
                            </div>
                          </div>
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            align="left"
                            fontWeight={500}
                            lineHeight={1.5}
                            style={{
                              marginTop: "0",
                              fontSize: "1rem",
                              marginLeft: "1.875rem",
                            }}
                          >
                            Ideal for Enterprise
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            align="center"
                            fontWeight={600}
                            style={{
                              marginTop: "3.75rem",
                              fontSize: "3.75rem",
                              marginBottom: "1.563rem",
                            }}
                          >
                            $25,000
                          </Typography>
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            align="center"
                            fontWeight={400}
                            style={{
                              fontSize: "0.875rem",
                              marginBottom: "1.25rem",
                            }}
                          >
                            up to 10 users
                          </Typography>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              variant="secondary"
                              id={"getStarted"}
                              onClick={() => {}}
                            >
                              GET STARTED
                            </Button>
                          </div>

                          <List style={{ marginLeft: "3.125rem" }}>
                            <ListItem>
                              <img
                                src={CheckCircle}
                                alt="Unlimited Access"
                                style={{ marginRight: "0.625rem" }}
                              ></img>
                              <Typography
                                variant="body1"
                                fontSize="0.75rem"
                                fontWeight={500}
                                lineHeight={1}
                              >
                                Unlimited Access
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <img
                                src={CheckCircle}
                                alt="Dedicated Infrastructure"
                                style={{ marginRight: "0.625rem" }}
                              ></img>
                              <Typography
                                variant="body1"
                                fontSize="0.75rem"
                                fontWeight={500}
                                lineHeight={1}
                              >
                                Dedicated Infrastructure
                              </Typography>
                            </ListItem>
                            <ListItem>
                              <img
                                src={CheckCircle}
                                alt="Our most performant solution"
                                style={{ marginRight: "0.625rem" }}
                              ></img>
                              <Typography
                                variant="body1"
                                fontSize="0.75rem"
                                fontWeight={500}
                                lineHeight={1}
                              >
                                Our most performant solution
                              </Typography>
                            </ListItem>
                          </List>
                        </Card>
                      </Card>

                      <TableContainer
                        component={Paper}
                        style={{ boxShadow: "none" }}
                      >
                        <Table
                          sx={{ width: 1000 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell> </StyledTableCell>
                              <StyledTableCell align="center">
                                <p
                                  style={{
                                    fontSize: "1.125rem",
                                    fontWeight: 400,
                                  }}
                                >
                                  Free Trial
                                </p>{" "}
                                <br></br>{" "}
                                <p
                                  style={{
                                    fontSize: "2.125rem",
                                    marginTop: "-1.25rem",
                                  }}
                                >
                                  FREE
                                </p>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <p
                                  style={{
                                    fontSize: "1.125rem",
                                    fontWeight: 400,
                                  }}
                                >
                                  Pay-as-you-Go
                                </p>{" "}
                                <br></br>{" "}
                                <p
                                  style={{
                                    fontSize: "2.125rem",
                                    marginTop: "-1.25rem",
                                  }}
                                >
                                  $125.00
                                </p>
                              </StyledTableCell>
                              <StyledTableCell align="center">
                                <p
                                  style={{
                                    fontSize: "1.125rem",
                                    fontWeight: 400,
                                  }}
                                >
                                  Subscription
                                </p>{" "}
                                <br></br>{" "}
                                <p
                                  style={{
                                    fontSize: "2.125rem",
                                    marginTop: "-1.25rem",
                                  }}
                                >
                                  $25,000
                                </p>
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <StyledTableRow key="Lorem ipsum dolor sit amet">
                              {" "}
                              <p style={{ marginLeft: "1.25rem" }}>
                                Rich API Documentation
                              </p>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Rich API Documentation1"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Rich API Documentation2"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Rich API Documentation3"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="Lorem ipsum dolor sit amet">
                              {" "}
                              <p style={{ marginLeft: "1.25rem" }}>
                                Access to templated Workflows
                              </p>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Access to templated Workflows1"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>{" "}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Access to templated Workflows2"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Access to templated Workflows3"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="Lorem ipsum dolor sit amet">
                              {" "}
                              <p style={{ marginLeft: "1.25rem" }}>
                                Secure and Compliant
                              </p>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Secure and Compliant1"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>{" "}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Secure and Compliant2"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Secure and Compliant3"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="Lorem ipsum dolor sit amet">
                              {" "}
                              <p style={{ marginLeft: "1.25rem" }}>
                                Performant access to client data
                              </p>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Performant access to client data1"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>{" "}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Performant access to client data2"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Performant access to client data3"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="Lorem ipsum dolor sit amet">
                              {" "}
                              <p style={{ marginLeft: "1.25rem" }}>
                                Favorable Pricing
                              </p>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={UnCheckCircle}
                                  alt="Favorable Pricing1"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>{" "}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Favorable Pricing2"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Favorable Pricing3"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="Lorem ipsum dolor sit amet">
                              {" "}
                              <p style={{ marginLeft: "1.25rem" }}>
                                Available in Multiple Cloud Geographies
                              </p>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={UnCheckCircle}
                                  alt="Available in Multiple Cloud Geographies1"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>{" "}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Available in Multiple Cloud Geographies2"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Available in Multiple Cloud Geographies3"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="Lorem ipsum dolor sit amet">
                              {" "}
                              <p style={{ marginLeft: "1.25rem" }}>
                                Dedicated Infrastructure and Unlimited Use
                              </p>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={UnCheckCircle}
                                  alt="Dedicated Infrastructure and Unlimited Use1"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>{" "}
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={UnCheckCircle}
                                  alt="Dedicated Infrastructure and Unlimited Use2"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <img
                                  src={CheckCircle}
                                  alt="Dedicated Infrastructure and Unlimited Use3"
                                  style={{
                                    width: "1.063rem",
                                    height: "1.063rem",
                                  }}
                                ></img>
                              </StyledTableCell>
                            </StyledTableRow>
                            <StyledTableRow key="Lorem ipsum dolor sit amet">
                              {" "}
                              <p> </p>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <Button
                                  variant="secondary"
                                  id={"getStarted"}
                                  onClick={() => {}}
                                >
                                  GET STARTED
                                </Button>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <Button
                                  variant="secondary"
                                  id={"getStarted"}
                                  onClick={() => {}}
                                >
                                  GET STARTED
                                </Button>
                              </StyledTableCell>
                              <StyledTableCell
                                align="center"
                                style={{ border: "none" }}
                              >
                                <Button
                                  variant="secondary"
                                  id={"getStarted"}
                                  onClick={() => {}}
                                >
                                  GET STARTED
                                </Button>
                              </StyledTableCell>
                            </StyledTableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </TabPanel>

                    <TabPanel
                      value="2"
                      style={{ display: "flex", marginTop: "1.25rem" }}
                    >
                      <div style={{ width: "32%", marginRight: "0.938rem" }}>
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          fontWeight={600}
                          component="div"
                          style={{
                            color: "#02273C",
                            marginBottom: "0.313rem",
                          }}
                        >
                          Enterprise Scale System
                        </Typography>
                        <Typography
                          variant="body1"
                          fontSize="1rem"
                          fontWeight={400}
                          component="div"
                          align="left"
                          lineHeight={1.5}
                        >
                          With the SS&C Advent Geneva API, you don't need to
                          deploy any infrastructure as the service runs in a
                          scalable cluster. Ask us about deploying custom,
                          dedicated infrastructure!
                        </Typography>
                      </div>
                      <div style={{ width: "32%" }}>
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          fontWeight={600}
                          component="div"
                          style={{
                            color: "#02273C",
                            marginBottom: "0.313rem",
                          }}
                        >
                          Your Data at Your Speed
                        </Typography>
                        <Typography
                          variant="body1"
                          fontSize="1rem"
                          fontWeight={400}
                          component="div"
                          align="left"
                          lineHeight={1.5}
                        >
                          Your data is cached through time allowing for more
                          efficient queries and data aggregation operations.
                        </Typography>
                      </div>
                      <div
                        style={{ width: "32%", marginRight: "0.938rem" }}
                      ></div>
                    </TabPanel>
                    <TabPanel value="3" style={{ display: "flex" }}>
                      <div
                        style={{
                          width: "18.75rem",
                          position: "fixed",
                          marginLeft: "55rem",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{ marginLeft: "6.25rem" }}
                        >
                          {" "}
                          <b> On this page</b>
                        </Typography>
                        <Box
                          sx={{
                            width: "100%",
                            maxWidth: 200,
                            bgcolor: "background.paper",
                            marginLeft: "6.25rem",
                          }}
                        >
                          <List>
                            <ListItem disablePadding>
                              <ListItemButton href="#get-started">
                                <ListItemText primary="Get Started" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton href="#guides">
                                <ListItemText primary="Guides" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton component="a" href="#resources">
                                <ListItemText primary="Resources" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton
                                component="a"
                                href="#code-samples"
                              >
                                <ListItemText primary="Code Samples" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton
                                component="a"
                                href="#api-reference"
                              >
                                <ListItemText primary="API Reference" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton component="a" href="#support">
                                <ListItemText primary="Support" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton
                                component="a"
                                href="#terms-and-notices"
                              >
                                <ListItemText primary="Terms and Notices" />
                              </ListItemButton>
                            </ListItem>
                            <ListItem disablePadding>
                              <ListItemButton
                                component="a"
                                href="#release-notes"
                              >
                                <ListItemText primary="Release Notes" />
                              </ListItemButton>
                            </ListItem>
                          </List>
                        </Box>
                      </div>
                    </TabPanel>
                    <TabPanel
                      value="4"
                      style={{ display: "flex", marginTop: "-5.625rem" }}
                    >
                      <div style={{ width: "48%", marginRight: "0.938rem" }}>
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          fontWeight={600}
                          component="div"
                          style={{
                            color: "#02273C",
                            marginBottom: "0.313rem",
                          }}
                        >
                          Do I need SS&C Advent Geneva?
                        </Typography>
                        <Typography
                          variant="body1"
                          fontSize="1rem"
                          fontWeight={400}
                          component="div"
                          align="left"
                          lineHeight={1.5}
                        >
                          Use of this solution is predicated on the assumption
                          that you have an existing implementation of SS&C
                          Advent Geneva or similar. If you'd like to learn more
                          about how to get started with SS&C Advent, please
                          reach out to sales@sscinc.com.
                        </Typography>
                      </div>
                      <div style={{ width: "48%", marginRight: "0.938rem" }}>
                        <Typography
                          variant="h6"
                          fontSize="1.25rem"
                          fontWeight={600}
                          component="div"
                          style={{
                            color: "#02273C",
                            marginBottom: "0.313rem",
                          }}
                        >
                          How is usage of the platform monitored?
                        </Typography>
                        <Typography
                          variant="body1"
                          fontSize="1rem"
                          fontWeight={400}
                          component="div"
                          align="left"
                          lineHeight={1.5}
                        >
                          Use of the SS&C Advent Geneva API is billed on usage
                          per API call, or by purchasing a subscription allowing
                          for unlimited usage over a time window.
                        </Typography>
                      </div>
                    </TabPanel>
                  </TabContext>
                </Box>
              </>
            );
          })}
        </>
      }
    </>
  );
};

export default UnownedServices;
