import * as React from "react";
import { useState, useContext } from "react";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { styled } from "@mui/material/styles";
import LinearProgress from "@mui/material/LinearProgress";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckCircle from "../../../../../assets/CheckCircle.png";
import UnCheckCircle from "../../../../../assets/UnCheckCircle.png";
import AllInvoicesDataMesh from "../Invoices/AllInvoicesDataMesh";
import { CurrentViewContext } from "../../../ComponentWithContext";
import CodeIcon from "@mui/icons-material/Code";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import BarChart from "../Charts/Chart";
import Divider from "@mui/material/Divider";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ApiIcon from "@mui/icons-material/Api";
import FolderIcon from "@mui/icons-material/Folder";
import Alert from "@mui/material/Alert";
import { dataMeshRedirectURL } from "../../../../../constants";
import PrivateApiSettingsTab from "../PrivateAPI/PrivateApiSettingsTab";
import "./DataMeshServices.css";
import Grid from "src/components/main/subcomponents/Grid/Grid";
import SummaryList from "src/components/main/MyServices/Cards/CardTemplate/SummaryList";
import { DocumentationTab } from "./DocumentationTab";
import {
  BannerWarningNotification,
  FeatureToggle,
  Button,
} from "@ssce/ui-shared-library";
import {
  SmallButton,
  Title,
  SubTitle,
  Wrapper,
  LightText,
  Italics,
  LightSubHeading,
  Flex,
  BannerWrapper,
} from "./DataMeshServices.styles";
import { useNavigate } from "react-router-dom";
import { MyServicesCardHeader } from "../CardTemplate/myServicesCardHeader";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import MyServiceInstanceManagement from "../../myServiceInstanceManagement";

const BILLING_SUMMARY_LIST_DATA = [
  {
    name: "Subscription ID",
    value: "2387-99746-2938462",
  },
  {
    name: "Directory",
    value: "Default Directory (admin@companyname.com)",
  },
  {
    name: "My role",
    value: "Account admin",
  },
  {
    name: "Offer",
    value: "Monthly Subscription",
  },
  {
    name: "Instance ID",
    value: "SSNC-Sal-MRP-39485",
  },
  {
    name: "Subscription Name",
    value: "Subscription Name",
  },
  {
    name: "Status",
    value: "Active",
  },
  {
    name: "Secure Source",
    value: "Not Available",
  },
  {
    name: "Current Billing Period",
    value: "Sept 15, 2023 - Oct 15, 2023",
  },
  {
    name: "Currency",
    value: "USD",
  },
  {
    name: "Last Bill Amount",
    value: "$10,000",
  },
  {
    name: "Current Bill Amount",
    value: "$10,000",
  },
];

const Barchart1 = [
  {
    name: "Tue",
    pv: 2.5,
    amt: 0,
  },
  {
    name: "Wed",
    pv: 3,
    amt: 25,
  },
  {
    name: "Thr",
    pv: 3.5,
    amt: 50,
  },
  {
    name: "Fri",
    pv: 4,
    amt: 75,
  },
  {
    name: "Sat",
    pv: 4.5,
    amt: 75,
  },
  {
    name: "Sun",
    pv: 4.5,
    amt: 75,
  },
  {
    name: "Mon",
    pv: 5,
    amt: 100,
  },
];

const Barchart2 = [
  {
    name: "Tue",
    pv: 350,
    amt: 0,
  },
  {
    name: "Wed",
    pv: 250,
    amt: 25,
  },
  {
    name: "Thr",
    pv: 500,
    amt: 50,
  },
  {
    name: "Fri",
    pv: 750,
    amt: 75,
  },
  {
    name: "Sat",
    pv: 550,
    amt: 75,
  },
  {
    name: "Sun",
    pv: 800,
    amt: 75,
  },
  {
    name: "Mon",
    pv: 550,
    amt: 100,
  },
];

const DataMeshServices: React.FC = () => {
  const [value, setValue] = useState("1");
  const { viewData } = useContext(CurrentViewContext);
  const navigate = useNavigate();

  const handleChange = (_, newValue: string) => {
    setValue(newValue);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#F6FDFF",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(() => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "#DFE9EF",
    },
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/datamesh.png"}
        title={viewData.instanceName}
        subTitle={`Data Mesh, ${viewData.region === "NorthAmerica" ? "North America" : viewData.region}`}
        contents={
          "A customizable API call for all your firm's holdings data. Investment Portfolio provides users with the ability to query their portfolio holdings data across all SS&C products and services. As input to any analytics calculation, Investment Portfolio provides users with information on identifiers, quantities, transaction fees, important dates, and any additional data available on financial securities, all in an easy-to-consume format."
        }
        tags={[
          {
            label: viewData.tags.function,
            style: { backgroundColor: "#efe6fd", color: "#6200ee" },
          },
          {
            label: viewData.tags.industry,
            style: { backgroundColor: "#e7f3f9", color: "#0a85c7" },
          },
          {
            label: viewData.tags.serviceType,
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="secondary"
          id={"getSupport"}
          onClick={() => window.open(`${dataMeshRedirectURL}/datamesh/`)}
        >
          Open Data Mesh
          <ContactSupportIcon />
        </Button>
      </MyServicesCardHeader>
      <Box className="my-services-datamesh-tablist">
        <TabContext value={value}>
          <Box
            className="my-service-datamesh"
            sx={{ borderBottom: 1, borderColor: "divider" }}
          >
            <TabList
              id={"tabList"}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab label="Overview" value="1" />
              <Tab label="Manage Plan" value="2" />
              <Tab label="Analytics" value="3" />
              <Tab label="Billing" value="4" />
              <Tab label="Settings" value="5" />
              <Tab label="Documentation" value="6" />
            </TabList>
          </Box>
          <TabPanel value="1" style={{ padding: "3.25rem 0" }}>
            <div className="myservices-datamesh-overview">
              <Typography className="myservices-datamesh-overview-headline">
                Overview
              </Typography>
              <Typography className="myservices-datamesh-overview-titles">
                Plan Details
              </Typography>
              <Grid minMax="22rem">
                <Card className="myservices-datamesh-my-plan">
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <Typography className="myservices-datamesh-my-plan-title">
                          My Plan
                        </Typography>
                        <Button
                          id={"small"}
                          onClick={() => {}}
                          variant="default"
                          size="small"
                          isDisabled
                          className="myservices-datamesh-my-plan-button"
                        >
                          small
                        </Button>
                      </div>
                      <div>
                        <Typography className="myservices-datamesh-my-plan-price">
                          $10,000
                        </Typography>
                        <Typography className="myservices-datamesh-my-plan-price-plan">
                          /a month
                        </Typography>
                      </div>
                    </div>
                    <Divider className="myservices-datamesh-my-plan-divider" />
                    <div className="myservices-datamesh-my-plan-availability">
                      <Box sx={{ flexGrow: 1 }}>
                        <Typography className="myservices-datamesh-my-plan-tb-availability">
                          4 TB Available
                        </Typography>
                        <LinearProgress
                          className="myservices-datamesh-my-plan-tb-progress"
                          variant="determinate"
                          value={60}
                        />
                        <Typography className="myservices-datamesh-my-plan-tb-progress-des">
                          6 TB / 10 TB Used
                        </Typography>
                      </Box>
                      <Box sx={{ flexGrow: 1, marginLeft: "1rem" }}>
                        <Typography className="myservices-datamesh-my-plan-tb-availability">
                          400 Hours Available
                        </Typography>
                        <LinearProgress
                          variant="determinate"
                          value={60}
                          color={"inherit"}
                          className="myservices-datamesh-my-plan-hours-progress"
                        />
                        <Typography className="myservices-datamesh-my-plan-tb-progress-des">
                          {" "}
                          600 / 1,000 CPU Hours Used
                        </Typography>
                      </Box>
                      <Button
                        variant="default"
                        className="myservices-datamesh-my-plan-manage-btn"
                        id="managePlan"
                        onClick={() => {}}
                      >
                        Manage Plan
                      </Button>
                    </div>
                  </CardContent>
                </Card>
                <Card className="myservices-datamesh-next-payment">
                  <CardContent className="myservices-datamesh-next-payment-card-content">
                    <Typography className="myservices-datamesh-next-payment-title">
                      Next payment
                    </Typography>
                    <Typography fontSize="1.25rem" fontWeight={500}>
                      Dec 31, 2024
                    </Typography>
                    <Divider className="myservices-datamesh-my-plan-divider" />
                    <div className="myservices-datamesh-next-payment-billing">
                      <Button
                        id={"downloadLastInvoice"}
                        onClick={() => {}}
                        variant={"text"}
                        size={"medium"}
                      >
                        Download Last Invoice
                        <FileDownloadIcon />
                      </Button>
                      <Button
                        variant="secondary"
                        size={"medium"}
                        id="viewBilling"
                        onClick={() => {}}
                      >
                        View Billing
                      </Button>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
              <Alert severity="warning">
                Your Data Usage amount is at the upper limit. You should
                consider upgrading soon to increase available space.
              </Alert>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2.4375rem",
              }}
            >
              <Typography
                variant="h5"
                fontSize="1.5rem"
                style={{ color: "#000000", lineHeight: "1.5rem" }}
              >
                Region Information
              </Typography>
              <div className="PC-service-overview">
                <div className="PC-service-overview-region">
                  <div className="PC-overview-central">
                    <Typography
                      variant="h4"
                      fontSize="1.25rem"
                      fontWeight={600}
                      component="div"
                      lineHeight={"1.5rem"}
                    >
                      Region
                    </Typography>
                    <Typography
                      variant="h6"
                      fontSize="0.875rem"
                      fontWeight={400}
                      color={"#02273C"}
                      lineHeight={4}
                    >
                      Central US
                    </Typography>
                    <img
                      alt={"centralUs"}
                      style={{ width: "90%" }}
                      src="/api/assets/CentralUS.png"
                    ></img>
                  </div>

                  <div className="PC-overview-central">
                    <Typography
                      variant="h4"
                      fontSize="1.25rem"
                      fontWeight={600}
                      component="div"
                      lineHeight={"1.5rem"}
                    >
                      Region Details
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="1rem"
                      lineHeight={"1.5rem"}
                      color={"#577687"}
                    >
                      This product is currently hosted in one region:{" "}
                      <b>Central US</b>
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="1rem"
                      lineHeight={"1.5rem"}
                      color={"#577687"}
                      style={{ marginTop: "1.25rem" }}
                    >
                      This region provides low-latency access to customers
                      located in central United States. It offers high
                      availability and scalability, making it an ideal choice
                      for enterprise customers with large-scale workloads.
                    </Typography>
                  </div>

                  <div className="PC-overview-central">
                    <Typography
                      variant="h4"
                      fontSize="1.5rem"
                      fontWeight={600}
                      component="div"
                      lineHeight={2}
                    >
                      Compliance
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="1.125rem"
                      lineHeight={2}
                    >
                      Fully compliant with regulatory requirements, including:
                      <ul>
                        <li>
                          <b>ISO</b>
                        </li>
                        <li>
                          <b>ISO 27000</b>
                        </li>
                        <li>
                          <b>SOC-2</b>
                        </li>
                      </ul>
                    </Typography>
                    <Typography
                      variant="body1"
                      fontSize="1.125rem"
                      lineHeight={2}
                    ></Typography>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1.5rem",
              }}
            >
              <Typography
                variant="h5"
                fontSize="1.5rem"
                style={{ color: "#000000", lineHeight: "1.5rem" }}
              >
                Metrics
              </Typography>
              <Grid>
                <div className="metricsCard">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        backgroundColor: "#C0EBF880",
                        borderRadius: "50%",
                        height: "2.75rem",
                        width: "2.75rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ApiIcon
                        sx={{ color: "#0A85C7" }}
                        height={"1.5rem"}
                        width={"1.5rem"}
                      />
                    </div>
                    <InfoOutlinedIcon
                      sx={{ color: "#0A85C7" }}
                      height={"1.5rem"}
                      width={"1.5rem"}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.1875rem",
                    }}
                  >
                    <Typography
                      variant="h1"
                      fontSize="3rem"
                      fontWeight={700}
                      component="div"
                      fontFamily="Roboto Bold"
                      style={{ color: "#0A85C7" }}
                    >
                      80
                    </Typography>
                    <Typography
                      variant="h4"
                      fontSize="1rem"
                      fontWeight={400}
                      component="div"
                      style={{
                        color: "#355263",
                        lineHeight: "1.5rem",
                        opacity: "0.5",
                      }}
                    >
                      Published APIs
                    </Typography>
                  </div>
                </div>

                <div className="metricsCard">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        backgroundColor: "#C0EBF880",
                        borderRadius: "50%",
                        height: "2.75rem",
                        width: "2.75rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CodeIcon
                        sx={{ color: "#0A85C7" }}
                        height={"1.5rem"}
                        width={"1.5rem"}
                      />
                    </div>
                    <InfoOutlinedIcon
                      sx={{ color: "#0A85C7" }}
                      height={"1.5rem"}
                      width={"1.5rem"}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.1875rem",
                    }}
                  >
                    <Typography
                      variant="h1"
                      fontSize="3rem"
                      fontWeight={700}
                      component="div"
                      fontFamily="Roboto Bold"
                      style={{ color: "#0A85C7" }}
                    >
                      108
                    </Typography>
                    <Typography
                      variant="h4"
                      fontSize="1rem"
                      fontWeight={400}
                      component="div"
                      style={{
                        color: "#355263",
                        lineHeight: "1.5rem",
                        opacity: "0.5",
                      }}
                    >
                      Queries Run
                    </Typography>
                  </div>
                </div>

                <div className="metricsCard">
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        backgroundColor: "#C0EBF880",
                        borderRadius: "50%",
                        height: "2.75rem",
                        width: "2.75rem",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <SourceOutlinedIcon
                        sx={{ color: "#0A85C7" }}
                        height={"1.5rem"}
                        width={"1.5rem"}
                      />
                    </div>
                    <InfoOutlinedIcon
                      sx={{ color: "#0A85C7" }}
                      height={"1.5rem"}
                      width={"1.5rem"}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.1875rem",
                    }}
                  >
                    <Typography
                      variant="h1"
                      fontSize="3rem"
                      fontWeight={700}
                      component="div"
                      fontFamily="Roboto Bold"
                      style={{ color: "#0A85C7" }}
                    >
                      45
                    </Typography>
                    <Typography
                      variant="h4"
                      fontSize="1rem"
                      fontWeight={400}
                      component="div"
                      style={{
                        color: "#355263",
                        lineHeight: "1.5rem",
                        opacity: "0.5",
                      }}
                    >
                      Connections
                    </Typography>
                  </div>
                </div>
              </Grid>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2.4375rem",
              }}
            >
              <Typography
                variant="h5"
                fontSize="1.5rem"
                style={{ color: "#000000", lineHeight: "1.5rem" }}
              ></Typography>

              <Grid minMax="21.875rem">
                <div className="metricsCard">
                  <Typography
                    variant="h4"
                    fontSize="1.25rem"
                    fontFamily="Roboto Medium"
                    fontWeight={400}
                    component="div"
                    style={{
                      color: "#02273B",
                      lineHeight: "1.5rem",
                      padding: "0.3125rem 0 1.625rem 1rem",
                    }}
                  >
                    Data Usage This Week
                  </Typography>
                  <BarChart data={Barchart1} barfill={"#0A85C7"} />
                </div>
                <div className="metricsCard">
                  <Typography
                    variant="h4"
                    fontSize="1.25rem"
                    fontFamily="Roboto Medium"
                    fontWeight={400}
                    component="div"
                    style={{
                      color: "#02273B",
                      lineHeight: "1.5rem",
                      padding: "0.3125rem 0 1.625rem 1rem",
                    }}
                  >
                    CPU Usage This Week
                  </Typography>
                  <BarChart data={Barchart2} />
                </div>

                <div className="metricsCard">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "column",
                      gap: "2.5rem",
                      padding: "0 1rem",
                    }}
                  >
                    <Typography
                      variant="h4"
                      fontSize="1.25rem"
                      fontFamily="Roboto Medium"
                      fontWeight={400}
                      component="div"
                      style={{ color: "#02273B", lineHeight: "1.5rem" }}
                    >
                      Top Performing API
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "0.625rem",
                      }}
                    >
                      <div style={{ display: "flex", gap: "0.75rem" }}>
                        <div
                          style={{
                            backgroundColor: "#C0EBF880",
                            borderRadius: "50%",
                            height: "2.75rem",
                            width: "2.75rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h1"
                            fontSize="1.25rem"
                            fontWeight={500}
                            component="div"
                            fontFamily="Roboto Medium"
                            style={{ color: "#0A85C7" }}
                          >
                            1
                          </Typography>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="h1"
                            fontSize="1rem"
                            fontWeight={700}
                            component="div"
                            fontFamily="Roboto Bold"
                            style={{ color: "#0A85C7" }}
                          >
                            Data Product Name
                          </Typography>
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <div
                          style={{ display: "flex", paddingLeft: "3.1875rem" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.25rem",
                              width: "6.4375rem",
                            }}
                          >
                            <Typography
                              variant="h1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              component="div"
                              fontFamily="Roboto Medium"
                              style={{ color: "#0A85C7" }}
                            >
                              Owner
                            </Typography>
                            <Typography
                              variant="h1"
                              fontSize="0.875rem"
                              fontWeight={400}
                              component="div"
                              style={{ color: "#02273C" }}
                            >
                              @ayoung3
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.25rem",
                            }}
                          >
                            <Typography
                              variant="h1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              component="div"
                              fontFamily="Roboto Medium"
                              style={{ color: "#0A85C7" }}
                            >
                              Added to My Services
                            </Typography>
                            <Typography
                              variant="h1"
                              fontSize="0.875rem"
                              fontWeight={400}
                              component="div"
                              style={{ color: "#02273C" }}
                            >
                              50 times
                            </Typography>
                          </div>
                        </div>

                        <div
                          style={{ display: "flex", paddingLeft: "3.1875rem" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.25rem",
                              width: "6.4375rem",
                            }}
                          >
                            <Typography
                              variant="h1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              component="div"
                              fontFamily="Roboto Medium"
                              style={{ color: "#0A85C7" }}
                            >
                              Owner
                            </Typography>
                            <Typography
                              variant="h1"
                              fontSize="0.875rem"
                              fontWeight={400}
                              component="div"
                              style={{ color: "#02273C" }}
                            >
                              @ayoung3
                            </Typography>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.25rem",
                            }}
                          >
                            <Typography
                              variant="h1"
                              fontSize="0.75rem"
                              fontWeight={500}
                              component="div"
                              fontFamily="Roboto Medium"
                              style={{ color: "#0A85C7" }}
                            >
                              Added to My Services
                            </Typography>
                            <Typography
                              variant="h1"
                              fontSize="0.875rem"
                              fontWeight={400}
                              component="div"
                              style={{ color: "#02273C" }}
                            >
                              50 times
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Divider
                          style={{ height: "1.0625rem", color: "#CBD9E1" }}
                        />
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            width: "100%",
                            paddingTop: ".5rem",
                          }}
                        >
                          <Button
                            variant="text"
                            size="medium"
                            id={"viewMore"}
                            onClick={() => {
                              navigate("/public-catalog");
                            }}
                          >
                            View More
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Grid>
            </div>
            {false && (
              <FeatureToggle feature={"hardCoded"}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2.4375rem",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontSize="1.5rem"
                    style={{ color: "#000000", lineHeight: "1.5rem" }}
                  >
                    Data Mesh Billing Summary; Aug 15, 2023
                  </Typography>
                  <Box
                    sx={{
                      "& > :not(style)": {
                        m: "0.0625rem",
                        padding: "1rem",
                        backgroundColor: "#F6FDFF",
                        border: "0.0625rem solid #DDD",
                        borderRadius: "0.875rem",
                      },
                    }}
                  >
                    <Paper>
                      <SummaryList data={BILLING_SUMMARY_LIST_DATA} />
                    </Paper>
                  </Box>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "2.4375rem",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontSize="1.5rem"
                    style={{ color: "#000000", lineHeight: "1.5rem" }}
                  >
                    All Invoices
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      "& > :not(style)": {
                        m: 1,
                        padding: "1rem",
                        backgroundColor: "#F6FDFF",
                      },
                    }}
                  >
                    <AllInvoicesDataMesh />
                  </Box>
                </div>
              </FeatureToggle>
            )}
          </TabPanel>
          <TabPanel value="2">
            <div>
              <Title>Manage Plan</Title>
              <Wrapper>
                <div>
                  <SubTitle> Your Plan </SubTitle>
                  <LightText>
                    This displays your currently selected plan along with its
                    associated monthly costs.
                  </LightText>
                </div>
                <div>
                  <div>
                    <Flex>
                      <SmallButton>Small</SmallButton>
                    </Flex>
                  </div>
                  <Italics>
                    Price is based on your plan selection + any plan add-ons.
                    Please scroll down to view your plan and cost breakdown.
                  </Italics>
                </div>
              </Wrapper>
              <Wrapper>
                <div>
                  <LightSubHeading>Primary Plan Features</LightSubHeading>
                  <LightText>
                    This list displays features exclusive to your plan
                  </LightText>
                </div>
                <div style={{ display: "flex", gap: "2rem", flex: "1 1 auto" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.25rem",
                      flex: "1 1 auto",
                    }}
                  >
                    <LightSubHeading>Data</LightSubHeading>
                    <Typography
                      fontSize="1rem"
                      fontWeight={500}
                      color={"#355263"}
                    >
                      4 TB Available
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={60}
                      style={{
                        marginTop: "0.625rem",
                        height: "0.3125rem",
                        borderRadius: "0.3125rem",
                        margin: "0.3125rem 0",
                      }}
                    />
                    <Typography
                      fontSize="0.75rem"
                      fontWeight={400}
                      color={"#355263"}
                    >
                      6 TB / 10 TB Used
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0.25rem",
                      flex: "1 1 auto",
                    }}
                  >
                    <LightSubHeading>CPU Hours</LightSubHeading>
                    <Typography
                      fontSize="1rem"
                      fontWeight={500}
                      color={"#355263"}
                    >
                      400 Hours Available
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={60}
                      color={"inherit"}
                      sx={{
                        marginTop: "0.625rem",
                        height: "0.3125rem",
                        borderRadius: "0.3125rem",
                        margin: "0.3125rem 0",
                        color: "#6200EE",
                      }}
                    />
                    <Typography
                      fontSize="0.75rem"
                      fontWeight={400}
                      color={"#355263"}
                    >
                      600 / 1,000 CPU Hours Used
                    </Typography>
                  </div>
                </div>
              </Wrapper>

              <BannerWrapper>
                <BannerWarningNotification>
                  Your Data Usage amount is at the upper limit. You should
                  consider upgrading soon to increase available space.
                </BannerWarningNotification>
              </BannerWrapper>
            </div>

            <div>
              <TableContainer
                component={Paper}
                style={{ boxShadow: "none", backgroundColor: "inherit" }}
              >
                <Table
                  sx={{ width: 1085, borderCollapse: "unset" }}
                  aria-label="customized table"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell> </StyledTableCell>
                      <StyledTableCell align="center">
                        <p
                          style={{ fontSize: "1.125rem", fontWeight: 400 }}
                        ></p>
                        <br />
                        <p
                          style={{
                            fontSize: "2.125rem",
                            marginTop: "-1.25rem",
                          }}
                        ></p>
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        style={{
                          borderTop: "0.25rem solid #0A85C7",
                          borderRight: "0.25rem solid #0A85C7",
                          borderLeft: "0.25rem solid #0A85C7",
                          borderRadius: "0.5rem 0.5rem 0 0",
                        }}
                      >
                        <p
                          style={{
                            fontSize: "1.25rem",
                            fontFamily: "Roboto Medium",
                            fontWeight: 500,
                            color: "#0A85C7",
                          }}
                        >
                          Your Plan
                        </p>
                        <p
                          style={{
                            fontSize: "1.125rem",
                            fontWeight: 400,
                            color: "#02273C",
                          }}
                        >
                          Small
                        </p>
                        <br />
                        <p
                          style={{
                            fontSize: "2.125rem",
                            marginTop: "-1.25rem",
                            color: "#043854",
                            fontWeight: 600,
                          }}
                        >
                          $10,000
                        </p>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <p
                          style={{
                            fontSize: "1.125rem",
                            fontWeight: 400,
                            color: "#0A85C7",
                          }}
                        >
                          {"."}
                        </p>
                        <p
                          style={{
                            fontSize: "1.125rem",
                            fontWeight: 400,
                            color: "#02273C",
                          }}
                        >
                          Medium
                        </p>
                        <br />
                        <p
                          style={{
                            fontSize: "2.125rem",
                            marginTop: "-1.25rem",
                            color: "#043854",
                            fontWeight: 600,
                          }}
                        >
                          $20,000
                        </p>
                      </StyledTableCell>

                      <StyledTableCell align="center">
                        <p
                          style={{
                            fontSize: "1.125rem",
                            fontWeight: 400,
                            color: "#0A85C7",
                          }}
                        >
                          {" ."}
                        </p>
                        <p
                          style={{
                            fontSize: "1.125rem",
                            fontWeight: 400,
                            color: "#02273C",
                          }}
                        >
                          Large
                        </p>
                        <br />
                        <p
                          style={{
                            fontSize: "2.125rem",
                            marginTop: "-1.25rem",
                            color: "#043854",
                            fontWeight: 600,
                          }}
                        >
                          $50,000
                        </p>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <StyledTableRow>
                      <p
                        style={{ marginLeft: "1.25rem", marginRight: "5rem" }}
                      ></p>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          borderRight: "0.25rem solid #0A85C7",
                          borderLeft: "0.25rem solid #0A85C7",
                        }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <p style={{ marginLeft: "1.25rem" }}></p>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          borderRight: "0.25rem solid #0A85C7",
                          borderLeft: "0.25rem solid #0A85C7",
                        }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <p style={{ marginLeft: "1.25rem" }}></p>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          borderRight: "0.25rem solid #0A85C7",
                          borderLeft: "0.25rem solid #0A85C7",
                        }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <p style={{ marginLeft: "1.25rem" }}></p>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          borderRight: "0.25rem solid #0A85C7",
                          borderLeft: "0.25rem solid #0A85C7",
                        }}
                      >
                        <img
                          src={UnCheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <p style={{ marginLeft: "1.25rem" }}></p>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          borderRight: "0.25rem solid #0A85C7",
                          borderLeft: "0.25rem solid #0A85C7",
                        }}
                      >
                        <img
                          src={UnCheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <p style={{ marginLeft: "1.25rem" }}></p>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          borderRight: "0.25rem solid #0A85C7",
                          borderLeft: "0.25rem solid #0A85C7",
                        }}
                      >
                        <img
                          src={UnCheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      {" "}
                      <p style={{ marginLeft: "1.25rem" }}></p>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          borderRight: "0.25rem solid #0A85C7",
                          borderLeft: "0.25rem solid #0A85C7",
                        }}
                      >
                        <img
                          src={UnCheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={UnCheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      >
                        <img
                          src={CheckCircle}
                          alt="Check Circle"
                          style={{ width: "1.0625rem", height: "1.0625rem" }}
                        ></img>
                      </StyledTableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      {" "}
                      <p> </p>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{
                          borderRight: "0.25rem solid #0A85C7",
                          borderLeft: "0.25rem solid #0A85C7",
                          borderBottom: "0.25rem solid #0A85C7",
                          borderRadius: "0 0 0.5rem 0.5rem",
                        }}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      ></StyledTableCell>
                      <StyledTableCell
                        align="center"
                        style={{ border: "none" }}
                      ></StyledTableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  margin: "2% 5%",
                }}
              >
                <Button variant="default" onClick={() => {}} id="changePlan">
                  Change Plan
                </Button>
              </div>
            </div>
          </TabPanel>

          <TabPanel value="3" style={{ padding: "3.25rem 0" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "2.625rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "1.5rem",
                  flexDirection: "column",
                }}
              >
                <Typography
                  variant="h4"
                  fontSize="2.125rem"
                  fontWeight={600}
                  component="div"
                  style={{
                    color: "#0A85C7",
                    lineHeight: "2.25rem",
                    padding: "0.625rem 0",
                  }}
                >
                  Analytics
                </Typography>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  <Typography
                    variant="h5"
                    fontSize="1.5rem"
                    style={{ color: "#000000", lineHeight: "1.5rem" }}
                  >
                    Metrics
                  </Typography>
                  <div style={{ display: "flex", gap: "2rem" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        "& > :not(style)": {
                          m: 1,
                          width: 320,
                          height: 203,
                          padding: "1rem",
                          backgroundColor: "#F6FDFF",
                          border: "0.0625rem solid #DDD",
                          borderRadius: "0.875rem",
                        },
                      }}
                    >
                      <Paper>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            gap: "2.5rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#C0EBF880",
                                borderRadius: "50%",
                                height: "2.75rem",
                                width: "2.75rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <ApiIcon
                                sx={{ color: "#0A85C7" }}
                                height={"1.5rem"}
                                width={"1.5rem"}
                              />
                            </div>
                            <InfoOutlinedIcon
                              sx={{ color: "#0A85C7" }}
                              height={"1.5rem"}
                              width={"1.5rem"}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.1875rem",
                            }}
                          >
                            <Typography
                              variant="h1"
                              fontSize="3rem"
                              fontWeight={700}
                              component="div"
                              fontFamily="Roboto Bold"
                              style={{ color: "#0A85C7" }}
                            >
                              80
                            </Typography>
                            <Typography
                              variant="h4"
                              fontSize="1rem"
                              fontWeight={400}
                              component="div"
                              style={{
                                color: "#355263",
                                lineHeight: "1.5rem",
                                opacity: "0.5",
                              }}
                            >
                              Published APIs
                            </Typography>
                          </div>
                        </div>
                      </Paper>

                      <Paper>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            gap: "2.5rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#C0EBF880",
                                borderRadius: "50%",
                                height: "2.75rem",
                                width: "2.75rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <CodeIcon
                                sx={{ color: "#0A85C7" }}
                                height={"1.5rem"}
                                width={"1.5rem"}
                              />
                            </div>
                            <InfoOutlinedIcon
                              sx={{ color: "#0A85C7" }}
                              height={"1.5rem"}
                              width={"1.5rem"}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.1875rem",
                            }}
                          >
                            <Typography
                              variant="h1"
                              fontSize="3rem"
                              fontWeight={700}
                              component="div"
                              fontFamily="Roboto Bold"
                              style={{ color: "#0A85C7" }}
                            >
                              108
                            </Typography>
                            <Typography
                              variant="h4"
                              fontSize="1rem"
                              fontWeight={400}
                              component="div"
                              style={{
                                color: "#355263",
                                lineHeight: "1.5rem",
                                opacity: "0.5",
                              }}
                            >
                              Queries Run
                            </Typography>
                          </div>
                        </div>
                      </Paper>

                      <Paper>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            flexDirection: "column",
                            gap: "2.5rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              style={{
                                backgroundColor: "#C0EBF880",
                                borderRadius: "50%",
                                height: "2.75rem",
                                width: "2.75rem",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <SourceOutlinedIcon
                                sx={{ color: "#0A85C7" }}
                                height={"1.5rem"}
                                width={"1.5rem"}
                              />
                            </div>
                            <InfoOutlinedIcon
                              sx={{ color: "#0A85C7" }}
                              height={"1.5rem"}
                              width={"1.5rem"}
                            />
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "0.1875rem",
                            }}
                          >
                            <Typography
                              variant="h1"
                              fontSize="3rem"
                              fontWeight={700}
                              component="div"
                              fontFamily="Roboto Bold"
                              style={{ color: "#0A85C7" }}
                            >
                              45
                            </Typography>
                            <Typography
                              variant="h4"
                              fontSize="1rem"
                              fontWeight={400}
                              component="div"
                              style={{
                                color: "#355263",
                                lineHeight: "1.5rem",
                                opacity: "0.5",
                              }}
                            >
                              Connections
                            </Typography>
                          </div>
                        </div>
                      </Paper>
                    </Box>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </TabPanel>
          <TabPanel value="4" style={{ padding: "3.25rem 0" }}>
            {false && (
              <FeatureToggle feature={"hardCoded"}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "4rem",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "2rem",
                      flexDirection: "column",
                    }}
                  >
                    {" "}
                    <div
                      style={{
                        display: "flex",
                        gap: "2rem",
                        flexDirection: "column",
                      }}
                    >
                      <Typography
                        variant="h4"
                        fontSize="2.125rem"
                        fontWeight={600}
                        component="div"
                        style={{
                          color: "#0A85C7",
                          lineHeight: "2.25rem",
                          padding: "0.625rem 0",
                        }}
                      >
                        Billing
                      </Typography>
                      <Typography
                        variant="h5"
                        fontSize="1.5rem"
                        style={{ color: "#000000", lineHeight: "1.5rem" }}
                      >
                        Data Mesh Billing Summary; Aug 15, 2023
                      </Typography>
                    </div>
                    <div style={{ marginLeft: "-2.1875rem" }}>
                      <SummaryList data={BILLING_SUMMARY_LIST_DATA} />
                    </div>
                    <div></div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "1.5rem",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        variant="h5"
                        fontSize="1.5rem"
                        style={{ color: "#000000", lineHeight: "1.5rem" }}
                      >
                        All Invoices
                      </Typography>
                      <div>
                        <Button
                          variant="secondary"
                          id="downloadAll"
                          onClick={() => {}}
                        >
                          Download All
                          <FolderIcon />
                        </Button>
                      </div>
                    </div>
                    <div style={{ marginLeft: "-1.25rem" }}>
                      <AllInvoicesDataMesh />
                    </div>
                    <div></div>
                  </div>
                </div>
              </FeatureToggle>
            )}
          </TabPanel>
          <TabPanel value="5" style={{ padding: "3.25rem 0" }}>
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4rem" }}
            >
              <PrivateApiSettingsTab />
              <MyServiceInstanceManagement />
            </div>
          </TabPanel>
          <TabPanel
            value="6"
            style={{ display: "flex", marginTop: "-3.125rem" }}
          >
            <DocumentationTab />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default DataMeshServices;
