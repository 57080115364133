import * as React from "react";
import { Button } from "@ssce/ui-shared-library";
import DatameshAPI from "../../../../../assets/DatameshAPIlogo.png";
import "./PrivateAPIHeader.css";

const PrivateAPIHeader: React.FC = () => {
  return (
    <section className="sectionContainer">
      <div className="PC-header">
        <div className="PC-headerContainer">
          <div className="imgContainer">
            <img
              src={DatameshAPI}
              alt="datameshApi"
              className="datameshApiLogo"
            />
          </div>
          <div>
            <h1 className="apiCatalogHeader">Data Mesh API Catalog</h1>
          </div>
        </div>

        <div className="apiCatalogHeaderMainTextContainer">
          <div>
            <h2 className="apiCatalogSubHeader">
              Unlock Your Data Potential - Discover Our Data Mesh Solution Now!
            </h2>
            <div className="apiCatalogBody">
              Unleash the potential of your data with our robust Data Mesh
              solution, specifically designed for financial services and SS&C
              products. Connect effortlessly to multiple data sources, execute
              cross-queries with ease, and publish private APIs. Our cloud-based
              platform ensures optimal security and scalability, enhancing your
              operational efficiency. Gain unparalleled insights, drive informed
              decisions and excel in the competitive landscape.
            </div>
          </div>
          <div className="learnMoreButtonContainer">
            <Button variant="default" onClick={() => {}} id="learnMore">
              Learn More
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default PrivateAPIHeader;
