import {
  Heading,
  Subheading,
  P,
  ListItem,
  Bold,
} from "../../Styles/Documentation.styles";
import { OnThisPageHelper } from "../../OnThisPageHelper";

const anchorTagsArr = [
  {
    id: "1",
    subHeadingString:
      "What is a Data Mesh and how is that different from a Data Lake?",
  },
  { id: "2", subHeadingString: "The Data Mesh Workflow:" },
  { id: "3", subHeadingString: "Ensuring Global Compliance" },
  {
    id: "4",
    subHeadingString:
      "How Does SS&C Everywhere Integrate Data Protection Best Practices?",
  },
  { id: "5", subHeadingString: "Benefits to Our Clients" },
  { id: "6", subHeadingString: "Leveraging the best in Cloud Technologies:" },
];

export const IntroDataMeshCopy = () => {
  return (
    <>
      <OnThisPageHelper anchorTagsArr={anchorTagsArr} />
      <Heading>Introducing the SS&C Everywhere Data Mesh</Heading>
      <i>Last Updated: Aug 2024</i>
      <P>
        In the dynamic world of modern business, seamless integration of
        software solutions is more than just a competitive advantage—it's a
        necessity. SS&C Technologies, a name synonymous with pioneering
        innovation, introduces the SS&C Everywhere Data Mesh - a cloud solution
        to streamline management of data from SS&C products and services, third
        party data, and your own data wherever it lives. The SS&C Everywhere
        Data Mesh platform provides an interface for users to centralize their
        data to make it more accessible to the rapidly changing needs of a
        user’s organization
      </P>
      <P>
        The SS&C Everywhere Data Mesh is the first offering to be listed on the
        SS&C Everywhere Marketplace and is a game-changer in standardizing and
        automating ingestion of data, exposing it safely to an organization, and
        using it to integrate new capabilities.
      </P>
      <Subheading id="1">
        What is a Data Mesh and how is that different from a Data Lake? 
      </Subheading>
      <P>
        In the ever-evolving world of data, two terms have recently emerged and
        garnered attention in the business community: Data Mesh and Data Lake.
        At a high level, both aim to address the complexities of handling large
        volumes of data, but they approach the problem in distinct ways.
      </P>
      <P>
        A <Bold>Data Lake</Bold> is best imagined as a vast reservoir where
        organizations pour their data, both structured and unstructured, in its
        native format. Picture it as a massive storage solution, a singular
        point where data from various sources accumulates, awaiting processing
        or analysis. On the other hand, a Data Mesh challenges the centralized
        data paradigm. Instead of a single repository, a Data Mesh promotes a
        decentralized approach, treating data as a product. In this model,
        individual domains or teams within an organization take ownership of
        their data, ensuring its quality, accessibility, and usability. By
        distributing the responsibility, Data Mesh aims to foster a more agile
        and resilient data ecosystem, responding to the growing intricacies of
        modern data landscapes. 
      </P>
      <P>
        The SS&C Everywhere Data Mesh leverages this decentralized approach to
        accelerate the pace clients can connect to their data sources. Users of
        the platform do not need to make copies, schedule batched data exports
        and imports, or worry about data synchronization if a data source is
        already in a structured database; an organization can federate to it
        using this decentralized approach instead. Understanding that not all
        data resides in this form, the platform also supports the traditional
        approach of ingesting data.
      </P>
      <Subheading id="2">The Data Mesh Workflow:</Subheading>
      <P>
        The Data Mesh platform breaks down the workflow of making use of new
        data source into three easy steps:{" "}
        <Bold>
          Connecting to Data Sources, Querying your Data, <Bold>and</Bold>{" "}
          Publishing Access to your Data.
        </Bold>
      </P>
      <P>
        <Bold>Connecting to Your Data:</Bold> Users can define the workflow that
        is needed to access and onboard data on a periodic or ad-hoc basis. For
        more information, read our guide on [how to connect to your data].
      </P>
      <P>
        <Bold>Querying Your Data:</Bold> With the combined prowess of Starburst
        and the assistance of ChatGPT, users can query data across multiple
        database types effortlessly regardless of the technology used to store
        it.
      </P>
      <P>
        <Bold>Publish Your Data:</Bold> Publish your query as an API, making
        data accessibility a reality for business users. Control access within
        your organization and flag use cases for sensitive data to adhere to
        data controls.
      </P>
      <Subheading id="3">Ensuring Global Compliance </Subheading>
      <P>
        With businesses operating globally, compliance with regional regulations
        is paramount. SS&C Everywhere prioritizes this by offering region
        isolation, such as US and UK specific zones, ensuring stringent
        adherence to regulations like GDPR.
      </P>
      <P>
        In an age where data breaches and privacy concerns dominate headlines,
        ensuring data protection and compliance on a global scale is more than
        just a requirement—it's ensures trust between businesses and their
        clientele. SS&C Everywhere takes this trust seriously and has infused
        its infrastructure with the standards of security protocols.
      </P>
      <P>
        Service Organization Control 2 (SOC2) is a framework that evaluates and
        ensures robust controls within a service organization, particularly in
        terms of security, availability, processing integrity, confidentiality,
        and privacy of customer data. SOC2 is an essential benchmark for cloud
        and SaaS providers who store customer data, establishing that they
        maintain strict information security policies and procedures.
      </P>
      <Subheading id="4">
        How Does SS&C Everywhere Integrate Data Protection Best Practices? 
      </Subheading>
      <ul>
        <ListItem>
          <Bold>Enhanced Security:</Bold> At the core of the SOC2 framework is
          the assurance of robust security measures. SS&C Everywhere integrates
          state-of-the-art security protocols to ensure unauthorized access,
          data breaches, and other threats are meticulously guarded against.
        </ListItem>
        <ListItem>
          <Bold>Guaranteed Availability:</Bold> SOC2 ensures that the system is
          available for operation and use, as committed or agreed upon. This
          means SS&C Everywhere offers a platform that's consistently up and
          running, minimizing disruptions to businesses.
        </ListItem>
        <ListItem>
          <Bold>Processing Integrity:</Bold> With SOC2's focus on data
          processing integrity, SS&C Everywhere ensures that data processing is
          timely, authorized, and accurate, eliminating risks of data
          manipulation.
        </ListItem>
        <ListItem>
          <Bold>Strict Confidentiality:</Bold> For financial institutions and
          businesses where data confidentiality is paramount, SS&C Everywhere's
          adherence to SOC2 ensures that data designated as confidential remains
          protected and disclosed only to authorized entities.
        </ListItem>
        <ListItem>
          <Bold>End-to-End Privacy:</Bold> Beyond just confidentiality, privacy
          protocols make certain that personal information is collected, used,
          retained, and disclosed in conformity with the platform's privacy
          policies.
        </ListItem>
      </ul>
      <Subheading id="5">Benefits to Our Clients</Subheading>
      <ul>
        <ListItem>
          <Bold>Trustworthiness:</Bold> Clients partnering with SS&C Everywhere
          can rest assured about the integrity and safety of their data, thanks
          to the platform's SOC2 compliance.
        </ListItem>
        <ListItem>
          <Bold>Competitive Advantage:</Bold> In a market where clients are
          becoming more aware and demanding about data security, SOC2 compliance
          positions SS&C Everywhere, and by extension its clients, ahead in the
          race.
        </ListItem>
        <ListItem>
          <Bold>Regional Compliances Met:</Bold> Alongside SOC2, SS&C
          Everywhere's commitment to region-specific compliances like GDPR means
          businesses can operate without fretting over regional data laws.
        </ListItem>
      </ul>
      <P>
        Incorporating SOC2 into its infrastructure signals SS&C Everywhere’s
        commitment to upholding the highest standards of security and
        operational best practices. Businesses not only benefit from seamless
        integrations and innovative solutions but also from an environment that
        respects, protects, and prioritizes their data.{" "}
      </P>
      <Subheading id="6">Leveraging the best in Cloud Technologies:</Subheading>
      <P>
        To foster greater interoperability and efficiency between disparate
        systems, modern cloud architectures play a pivotal role:
      </P>
      <ul>
        <ListItem>
          <Bold>Optimized Data Pipelines:</Bold> Utilizing the likes of Airflow,
          SS&C Everywhere establishes data pipelines that are not only robust
          but incredibly efficient. Airflow's flexibility allows for the
          crafting of dynamic workflows, ensuring tasks are executed in optimal
          sequences. This is complemented by Starburst, which empowers users to
          query data across multiple databases seamlessly. Together, they reduce
          the latency traditionally experienced in data processing, enabling
          faster decision-making and value derivation.
        </ListItem>
        <ListItem>
          <Bold>Efficiency Amplified:</Bold> In the world of finance, where data
          is voluminous and diverse, the coupling of Airflow with Starburst
          ensures that data extraction, transformations, and loading (ETL)
          processes are performed faster than ever before. The modular nature of
          this cloud architecture means tasks are executed concurrently,
          drastically reducing data transit times and allowing businesses to
          stay agile.
        </ListItem>
        <ListItem>
          <Bold>Automated Transformations:</Bold> Traditionally, transforming
          data from platforms like Globe GoGeneva required extensive manual
          intervention, often leading to inefficiencies. With SS&C Everywhere,
          these transformations are automated. The extracted data is formatted,
          cleaned, and transformed to be readily ingested by persistence targets
          within SS&C Everywhere Data Mesh, or even for direct live connections.
        </ListItem>
        <ListItem>
          <Bold>Direct Database Connections:</Bold> Take the instance of the
          Singularity accounting database. Instead of the traditional method of
          batched data extraction, SS&C Everywhere permits a live connection,
          allowing businesses to have real-time insights. This dynamic approach
          reduces the latency between data generation and its actionable use, a
          boon for fast-paced sectors like financial services.
        </ListItem>
        <ListItem>
          <Bold>Speed and Scalability:</Bold> By leveraging the capabilities of
          Kubernetes, Airflow's potential is magnified manifold. When
          calculations are parallelized using Airflow on a Kubernetes platform,
          the processing speed is amplified, allowing for faster data
          computation. This translates to quicker risk analyses, prompt
          performance metric derivations, and more timely business insights.
        </ListItem>
        <ListItem>
          <Bold>Enhanced Time to Value:</Bold> The combination of Airflow's
          dynamic workflows with the scalability of Kubernetes ensures that
          businesses can derive value from their data in record time. Whether
          it's for complex risk assessment models in hedge funds or nuanced
          policy analyses in insurance, the time lag between data input and
          insight derivation is minimized.
        </ListItem>
      </ul>
      <P>
        As the financial world becomes increasingly data-centric, the need for
        efficient, rapid, and reliable data processing frameworks becomes
        paramount. SS&C Everywhere's commitment to integrating modern cloud
        architectures, coupled with tools like Airflow and Starburst, ensures
        businesses are not just keeping pace with the industry's evolution, but
        are also poised to lead it. Whether it's a hedge fund analyzing market
        risks or an insurance company assessing policy premiums, the SS&C
        Everywhere Data Mesh ensures that data is processed swiftly,
        efficiently, and accurately—powering businesses to make informed
        decisions faster than ever before.
      </P>
    </>
  );
};
