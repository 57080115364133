import { keyframes } from "styled-components";

export const fadeInFromUp = keyframes`
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
    `;

export const fadeIn = keyframes`
  0% {  
  transform: scale(0.9);
  }

  30% {  
  transform: scale(0.85);
  }

  100% {
    transform: scale(1.1);
  }
`;

export const fadeOut = keyframes`
  0% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.9);
  }
`;

export const slideIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-80%);
  }

  100% {
    opacity: 1;
   transform: translateY(0%);
  }
`;

export const slideOut = keyframes`
  0% {
    opacity: 1;
    transform: translateY(0%);
  }

  100% {
    opacity: 0;
    transform: translateY(-80%);
  }
`;
