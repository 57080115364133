import {
  Projects,
  InstancesDetails,
  Roles,
  UserData,
} from "../../common/dataTypes";

export type ProjectsManagementState = ProjectsManagement;

export interface ProjectsManagement {
  projectsList: Projects[];
  selectedProject: Projects;
  projectsOverviewDetails: Projects;
  showDetails: Boolean;
  showEditProjectDesc: boolean;
  showDuplicateProject: boolean;
  showEditProjectName: boolean;
  showCreateProject: boolean;
  projectResources: InstancesDetails[];
  projectRoles: Roles[];
  projectUsers: UserData[];
  resourcesList: InstancesDetails[];
  isLoading:boolean;
  isProjectDetailsLoading:boolean;
}

export const createDefaultState = (): ProjectsManagementState => {
  return {
    projectsList: [],
    isLoading:false,
    isProjectDetailsLoading:false,
    showDetails: false,
    showCreateProject: false,
    showEditProjectName: false,
    showEditProjectDesc: false,
    showDuplicateProject: false,
    projectsOverviewDetails: {
      projectId: "",
      id: "",
      name: "",
      description: "",
      createdTimestamp: 0,
      instances: [],
    },
    selectedProject: {
      projectId: "",
      id: "",
      name: "",
      description: "",
      createdTimestamp: 0,
      instances: [],
    },
    projectResources: [],
    projectRoles: [],
    projectUsers: [],
    resourcesList: [],
  };
};
