import React from "react";
import {
  HealthEndpoint,
  UseCaseEndpoint,
  SchemaEndpoint,
  ApiKey,
} from "./LeftSide/index";
import { useTryItNow } from "./tryItNowHook";
import { ErrorNotification } from "@ssce/ui-shared-library";

export const TryItNow: React.FC<{
  catalogId: string;
  productId: string;
  productName: string;
}> = ({ catalogId, productId, productName }) => {
  const {
    hasError,
    healthEndpointData,
    useCaseData,
    schemaData,
    instanceId,
    useCaseTableParameters,
    getHealthEndpoint,
    getUseCaseEndpoint,
    getSchemaEndpoint,
  } = useTryItNow(catalogId, productId);

  return (
    <div className={"tryItNow"}>
      {hasError && <ErrorNotification />}
      <ApiKey />
      <HealthEndpoint
        data={healthEndpointData}
        apiId={instanceId}
        handleTryNowClick={() => {
          getHealthEndpoint();
        }}
      />
      <UseCaseEndpoint
        data={useCaseData}
        productId={productId}
        productName={productName}
        tableData={useCaseTableParameters}
        handleTryNowClick={async (limit, responseType, tableData) => {
          await getUseCaseEndpoint({
            limit,
            tableData,
            responseType,
          });
        }}
      />
      <SchemaEndpoint
        data={schemaData}
        apiId={productId}
        handleTryNowClick={async (schemaType) => {
          await getSchemaEndpoint(schemaType);
        }}
      />
    </div>
  );
};
