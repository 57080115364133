import React from "react";
import TextField from "@mui/material/TextField";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

export const OperatorSelect = ({ row, value, error, onChange }) => {
  const dataTypeMapper = {
    datetime: ["<", "<=", "=", ">", ">="],
    number: ["<", "<=", "=", ">", ">="],
    string: ["LIKE", "="],
    text: ["LIKE"],
    boolean: ["="],
  };
  const createMenuItems = (dataTypes) => {
    return dataTypes.map((data) => (
      <MenuItem key={data} value={data}>
        {data}
      </MenuItem>
    ));
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <InputLabel id="select-label" error={error}>
        Operator
      </InputLabel>
      <Select
        labelId="select-label"
        label="Operator"
        value={value}
        onChange={(e) => onChange(e)}
        error={error}
      >
        <MenuItem value="">None</MenuItem>
        {row.dataType && createMenuItems(dataTypeMapper[row.dataType])}
      </Select>
      <FormHelperText style={{ margin: 0, color: "red" }}>
        {error}
      </FormHelperText>
    </FormControl>
  );
};

type ValueInputProps = {
  value: string;
  error?: boolean;
  onChange: (e: any) => void;
  type?: string;
};

export const ValueInput: React.FC<ValueInputProps> = ({
  value,
  error,
  onChange,
  type,
}) => {
  return (
    <FormControl sx={{ width: "100%" }}>
      <TextField
        type={type ?? "string"}
        value={value}
        label="Value"
        onChange={(e) => onChange(e)}
        error={error}
      />
      <FormHelperText
        style={{
          margin: 0,
          color: "red",
        }}
      >
        {error}
      </FormHelperText>
    </FormControl>
  );
};
