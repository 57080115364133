import {
  HeaderContainer,
  SvgContainer,
  ExecutionLogContainer,
} from "../WorkflowViewerContent/workflowViewerContent.styles";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DownloadIcon from "@mui/icons-material/Download";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

export const ExecutionLog = () => {
  const onDownloadClick = () => {};
  return (
    <ExecutionLogContainer>
      <HeaderContainer>
        <TextField
          data-testid={"searchLog"}
          label="search log..."
          type="search"
          size="small"
          onChange={() => {}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <SvgContainer>
          <FileCopyIcon
            sx={{
              padding: "0.313rem",
              fontSize: "2.188rem",
              cursor: "pointer",
            }}
            color={"primary"}
            onClick={() => {}}
          />
        </SvgContainer>
        <SvgContainer>
          <DownloadIcon
            sx={{
              padding: "0.313rem",
              fontSize: "2.188rem",
              cursor: "pointer",
            }}
            color={"primary"}
            onClick={onDownloadClick}
          />
        </SvgContainer>
      </HeaderContainer>
    </ExecutionLogContainer>
  );
};
