import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Tableau.css";

const TableauTechnicalSpecifications: React.FC = () => {
  const RenderTechDetails = ({ heading, desc }) => {
    return (
      <div>
        <div className="flex-col">
          <Typography
            variant="h6"
            fontSize="1.25rem"
            sx={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {heading}
          </Typography>
        </div>
        <div className="flex-col padding1rem">
          <Typography
            variant="body1"
            sx={{ color: "#000", lineHeight: "1.5rem" }}
          >
            {desc}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex-col gap4rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Technical Specifications
          </Typography>
          <RenderTechDetails
            heading={"Data Connectivity"}
            desc={
              "Tableau supports connectivity to a wide range of data sources including SQL databases, cloud-based data services, spreadsheets, and big data platforms. This enables users to integrate and visualize data from multiple sources seamlessly."
            }
          />
          <RenderTechDetails
            heading={"Interactive Dashboards"}
            desc={
              "Tableau allows users to create interactive and shareable dashboards that provide insights at a glance. These dashboards support a variety of chart types, filters, and interactive elements to enhance data exploration."
            }
          />
          <RenderTechDetails
            heading={"Real-Time Analytics"}
            desc={
              "Tableau provides real-time data analysis capabilities, allowing users to connect to live data sources and see updates as they occur. This feature is essential for time-sensitive decision-making processes."
            }
          />
          <RenderTechDetails
            heading={"Data Blending"}
            desc={
              "Tableau offers data blending capabilities, enabling users to combine data from different sources within a single visualization. This helps in creating comprehensive insights by merging disparate datasets."
            }
          />
          <RenderTechDetails
            heading={"Geographic Mapping"}
            desc={
              "Tableau includes robust geographic mapping features, allowing users to create maps that display data across various geographical regions. This is useful for visualizing location-based data and uncovering spatial patterns."
            }
          />
          <RenderTechDetails
            heading={"Mobile Accessibility"}
            desc={
              "Tableau's mobile app provides access to dashboards and visualizations on mobile devices. This ensures that users can stay informed and interact with their data on the go."
            }
          />
          <RenderTechDetails
            heading={"Scalability"}
            desc={
              "Tableau is designed to scale with the needs of the user, from individual analysts to large organizations. It supports scaling up data processing capabilities and managing large volumes of data efficiently."
            }
          />
          <RenderTechDetails
            heading={"Security"}
            desc={
              "Tableau provides robust security features, including user authentication, permissions management, and data encryption. This ensures that data is protected and access is controlled within the organization."
            }
          />
          <RenderTechDetails
            heading={"Integration with Advanced Analytics"}
            desc={
              "Tableau integrates with advanced analytics tools and programming languages such as R and Python. This allows users to apply sophisticated statistical models and machine learning algorithms to their data."
            }
          />
          <RenderTechDetails
            heading={"Community and Support"}
            desc={
              "Tableau has a strong user community and extensive support resources. Users can access forums, training materials, and customer support to enhance their skills and resolve any issues they encounter."
            }
          />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default TableauTechnicalSpecifications;
