import React from "react";
import { Button } from "@ssce/ui-shared-library";
import { Button as MUIButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Region from "../Regions";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MenuItem from "@mui/material/MenuItem";
import { Project } from "./provisionDataMeshHooks";
import DownloadIcon from "@mui/icons-material/Download";
import ButtonGroup from "@mui/material/ButtonGroup";
import "./provisionService.css";

export const InstanceNameProvision: React.FC<{
  value: string;
  handleInstanceNameChange: (value: string) => void;
  hasInstanceNameError: string;
}> = ({ handleInstanceNameChange, value, hasInstanceNameError }) => {
  return (
    <div className={"nameInstanceContainer"}>
      <div>
        <div className={"sectionTitle"}>Name Your Instance</div>
        <div className={"sectionSubheader"}>
          Please choose a name to associate with instance.
        </div>
      </div>
      <TextField
        fullWidth
        id="instance-name"
        placeholder="Instance Name *"
        autoComplete="off"
        value={value}
        required
        error={hasInstanceNameError !== ""}
        helperText={hasInstanceNameError}
        onChange={(e) => handleInstanceNameChange(e.target.value)}
        sx={{ "& .MuiOutlinedInput-root": { borderRadius: "0.25rem" } }}
      />
    </div>
  );
};

export const SelectRegionProvision: React.FC<{
  regions: { label: string; value: string }[];
  selectedRegion: string;
  handleRegionChange: (value: string) => void;
}> = ({ regions, handleRegionChange, selectedRegion }) => {
  const isGlobal = regions.length === 1 && regions[0]?.value === "Global";

  return (
    <div className={"regionContainer"}>
      <div>
        <div className={"sectionTitle"}>
          {isGlobal ? "Region" : "Select Region"}
        </div>
        {!isGlobal && (
          <div className={"sectionSubheader"}>
            Select the region where you'd like this instance to operate.
          </div>
        )}
      </div>
      {!isGlobal && (
        <div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedRegion}
            fullWidth
            onChange={(e) => handleRegionChange(e.target.value)}
            sx={{ "&": { borderRadius: "0.25rem" } }}
          >
            {regions.map((region) => (
              <MenuItem key={region.value} value={region.value}>
                {region.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <div className={"regionDetailContainer"}>
        <Region selectedRegion={isGlobal ? "Global" : selectedRegion} />
        <div>
          <div className={"boldTitle"}>Region Details</div>
          <div className={"sectionSubheader"}>
            {isGlobal
              ? "This region is available to customers globally. It offers high availability and scalability, making it an ideal choice for enterprise customers with large-scale workloads."
              : "North America: This region provides low-latency access to customers located in the United States and Canada. It offers high availability and scalability, making it an ideal choice for enterprise customers with large-scale workloads."}
          </div>
        </div>
        <div>
          <div className={"boldTitle"}>Local Compliance</div>
          <div className={"sectionSubheader"}>
            Fully compliant with regulatory requirements, including:
            <ul>
              <li>GDPR</li>
              <li>ISO 27000</li>
              <li>SOC-2</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export const UserManagementProvision: React.FC<{
  handleProjectChange: (value: string) => void;
  projectId: string;
  projects: Project[];
  hasProjectIdError: boolean;
}> = ({ projectId, projects, handleProjectChange, hasProjectIdError }) => {
  return (
    <div className={"userAccessManagementContainer"}>
      <div>
        <div className={"sectionTitle"}>User Access Management</div>
        <div className={"sectionSubheader"}>
          To grant user access, associate this instance with a relevant project.
          Create a new project using the link below the dropdown or in User
          Access Management under Settings if needed.
        </div>
      </div>
      <div>
        <div className={"projectContainer"}>
          <div>
            <div className={"boldTitle"}>Choose Project</div>
            <div className={"sectionSubheader"}>
              Use the dropdown to the right to select a project
            </div>
          </div>
          <FormControl
            required
            sx={{ m: 1, minWidth: "100%" }}
            error={hasProjectIdError}
          >
            <InputLabel id="publish-api-project">Choose Project</InputLabel>
            <Select
              labelId="publish-api-project"
              value={projectId}
              fullWidth
              label="Choose Project"
              placeholder="Choose Project"
              id={"selectProject"}
              required
              onChange={(e) => {
                handleProjectChange(e.target.value);
              }}
              sx={{ "&": { borderRadius: "0.25rem" } }}
            >
              {projects.map((item) => (
                <MenuItem key={item.projectId} value={item.projectId}>
                  {item.name}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            className={"refresh"}
            id="refreshBtn"
            variant="text"
            onClick={() => {}}
            size="medium"
          >
            Refresh
            <RefreshIcon />
          </Button>
          <Button
            className={"addProjectButton"}
            id="refreshBtn"
            variant="default"
            onClick={() => {}}
            size="medium"
          >
            Add Project
          </Button>
        </div>
      </div>
    </div>
  );
};

export const SetupBillingProvision: React.FC<{
  billing: string;
  plan: string;
  handlePlanChange: (value: string) => void;
  handleBillingChange: (value: string) => void;
}> = ({ billing, plan, handleBillingChange, handlePlanChange }) => {
  return (
    <div className={"setupBillingContainer"}>
      <div>
        <div className={"sectionTitle"}>Setup Billing</div>
        <div className={"sectionSubheader"}>
          Please use the options below to select a plan and configure your
          billing preferences for this instance.
        </div>
      </div>
      <div className={"projectContainer"}>
        <div>
          <div className={"boldTitle"}>Plan</div>
          <div className={"sectionSubheader"}>
            Please select your desired plan and view details below.
          </div>
        </div>
        <Select
          labelId="demo-simple-select-label"
          value={plan}
          fullWidth
          id={"selectPlan"}
          onChange={(e) => handlePlanChange(e.target.value)}
          sx={{ "&": { borderRadius: "0.25rem" } }}
        >
          <MenuItem value={"Small"}>Small</MenuItem>
          <MenuItem value={"Medium"}>Medium</MenuItem>
          <MenuItem value={"Large"}>Large</MenuItem>
        </Select>
      </div>
      <div className={"projectContainer"}>
        <div>
          <div className={"boldTitle"}>Billing Frequency</div>
          <div className={"sectionSubheader"}>
            Please select your desired billing frequency.
          </div>
        </div>
        <Select
          labelId="demo-simple-select-label"
          value={billing}
          id={"selectPeriod"}
          fullWidth
          onChange={(e) => handleBillingChange(e.target.value)}
          sx={{ "&": { borderRadius: "0.25rem" } }}
        >
          <MenuItem value={"Monthly"}>Monthly </MenuItem>
          <MenuItem value={"Quarterly"}>Quarterly </MenuItem>
          <MenuItem value={"Yearly"}>Yearly </MenuItem>
        </Select>
      </div>
    </div>
  );
};

export const SolutionConfigurationProvision: React.FC<{
  isGlobal: boolean;
}> = ({ isGlobal }) => {
  const shouldHidePlanSection = isGlobal;
  return (
    <div
      style={{
        display: "flex",
        gap: "4rem",
        flexDirection: "column",
        padding: "1rem 1.5rem",
        borderRadius: "0.25rem",
        border: "0.063rem solid #B6C7D1",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div className={"sectionTitle"}>Solution Configuration</div>
          <div className={"sectionSubheader"}>
            Start building a solution by configuring the options below.
          </div>
        </div>
        <div style={{ width: "100%" }}></div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "2rem",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            width: "100%",
          }}
        >
          {!shouldHidePlanSection && (
            <div>
              <div
                style={{
                  display: "flex",
                  gap: "5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div className={"boldTitle"}>Choose a Plan</div>
                  <div className={"sectionSubheader"}>
                    Please select your desired plan and view details below.
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    aria-label="Disabled elevation buttons"
                  >
                    <MUIButton className="algorisk-standard-button">
                      Standard
                    </MUIButton>
                    <MUIButton className="algorisk-premium-button">
                      Premium
                    </MUIButton>
                  </ButtonGroup>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "53%",
                }}
              >
                <Button variant="text" onClick={() => {}} id="allPlanDetails">
                  View ALL Plan Details
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "0.5rem",
          flexDirection: "column",
          width: "100%",
          borderBottom: "0.063rem solid #CBD9E1",
          paddingBottom: "3.75rem",
        }}
      >
        <div
          style={{
            marginTop: "1.875rem",
            display: "flex",
            marginLeft: "52.5%",
          }}
        >
          <Typography
            variant="body1"
            fontSize="1rem"
            fontFamily={"Roboto Light"}
            fontWeight={600}
            component="div"
            align="left"
            lineHeight={"1.5rem"}
            color="#02273C"
          ></Typography>

          <div
            style={{
              width: "50%",
              marginLeft: "7.188rem",
            }}
          >
            <Typography
              variant="body1"
              fontSize="1.313rem"
              fontFamily={"Roboto Light"}
              fontWeight={600}
              component="div"
              align="left"
              lineHeight={"1.5rem"}
              color="#02273C"
            ></Typography>
          </div>
        </div>
      </div>
      {shouldHidePlanSection && (
        <div style={{ display: "flex", gap: "5rem" }}>
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div className={"boldTitle"}>Adjusted Pricing Calculation</div>
            <div className={"sectionSubheader"}>
              This provides an overview of your costs, factoring in selected
              funds, along with adjusted costs reflecting current discounts or
              promotions.
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: "1.875rem", display: "flex" }}>
              <Typography
                variant="body1"
                fontSize="1rem"
                fontFamily={"Roboto Light"}
                fontWeight={300}
                component="div"
                align="left"
                lineHeight={"1.5rem"}
                color="#02273C"
              ></Typography>

              <div
                style={{
                  borderBottom: "0.125rem solid",
                  width: "50%",
                  marginLeft: "7.188rem",
                }}
              >
                <Typography
                  variant="body1"
                  fontSize="1.313rem"
                  fontFamily={"Roboto Light"}
                  fontWeight={300}
                  component="div"
                  align="left"
                  lineHeight={"1.5rem"}
                  color="#02273C"
                ></Typography>
              </div>
            </div>
            <div
              style={{
                marginTop: "3.75rem",
                display: "flex",
                borderBottom: "0.063rem solid #CBD9E1",
                paddingBottom: "2.5rem",
              }}
            >
              <Typography
                variant="body1"
                fontSize="1rem"
                fontFamily={"Roboto Light"}
                fontWeight={300}
                component="div"
                align="left"
                lineHeight={"1.5rem"}
                color="#02273C"
              >
                Discount
              </Typography>

              <div
                style={{
                  borderBottom: "0.125rem solid",
                  width: "50%",
                  marginLeft: "9.375rem",
                }}
              >
                <Typography
                  variant="body1"
                  fontSize="1.313rem"
                  fontFamily={"Roboto Light"}
                  fontWeight={300}
                  component="div"
                  align="left"
                  lineHeight={"1.5rem"}
                  color="#02273C"
                ></Typography>
              </div>
            </div>
            <div style={{ marginTop: "6.25rem", display: "flex" }}>
              <Typography
                variant="body1"
                fontSize="1rem"
                fontFamily={"Roboto Light"}
                fontWeight={600}
                component="div"
                align="left"
                lineHeight={"1.5rem"}
                color="#02273C"
                sx={{ marginRight: "4.375rem" }}
              >
                New Annual Total
              </Typography>

              <div style={{ borderBottom: "0.125rem solid", width: "50%" }}>
                <Typography
                  variant="body1"
                  fontSize="1.313rem"
                  fontFamily={"Roboto Light"}
                  fontWeight={600}
                  component="div"
                  align="left"
                  lineHeight={"1.5rem"}
                  color="#02273C"
                ></Typography>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const ProvisionInstanceButton: React.FC<{
  handleProvisionInstance: (event: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ handleProvisionInstance }) => {
  return (
    <div className={"provisionButtonContainer"}>
      <Button
        variant="default"
        className={"provisionButton"}
        id={"provisionInstance"}
        onClick={handleProvisionInstance}
      >
        Provision Instance
        <CheckCircleIcon />
      </Button>
    </div>
  );
};

export const ReviewBillingProvision: React.FC<{
  isRiskthinking?: boolean;
}> = ({ isRiskthinking }) => {
  const shouldchangeDesc = isRiskthinking;
  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "4rem",
          flexDirection: "column",
          padding: "1rem 1.5rem",
          borderRadius: "0.25rem",
          border: "0.063rem solid #B6C7D1",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div className={"sectionTitle"}>Review Billing</div>
            <div className={"sectionSubheader"}>
              {shouldchangeDesc
                ? "Review your billing details below."
                : "Please use the options below to select a plan and configure your billing preferences for this instance."}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", gap: "5rem" }}>
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className={"boldTitle"}>Monthly Bill</div>
              <div className={"sectionSubheader"}>
                These numbers reflect both your official total annual and your
                total monthly costs.
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={{ marginTop: "1.875rem", display: "flex" }}>
                <Typography
                  variant="body1"
                  fontSize="1rem"
                  fontFamily={"Roboto Light"}
                  fontWeight={300}
                  component="div"
                  align="left"
                  lineHeight={"1.5rem"}
                  color="#02273C"
                >
                  {shouldchangeDesc ? "Annual Total" : "Adjusted Annual Total"}
                </Typography>
                &nbsp;&nbsp;
                <div
                  style={{
                    borderBottom: "0.125rem solid",
                    width: "50%",
                    marginLeft: shouldchangeDesc ? "6.5rem" : "1.25rem",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize="1.313rem"
                    fontFamily={"Roboto Light"}
                    fontWeight={300}
                    component="div"
                    align="left"
                    lineHeight={"1.5rem"}
                    color="#02273C"
                  ></Typography>
                </div>
              </div>
              <div style={{ marginTop: "6.25rem", display: "flex" }}>
                <Typography
                  variant="body1"
                  fontSize="1"
                  fontFamily={"Roboto Light"}
                  fontWeight={600}
                  component="div"
                  align="left"
                  lineHeight={"1.5rem"}
                  color="#02273C"
                  sx={{ marginRight: "5.313rem" }}
                >
                  Monthly Total
                </Typography>

                <div
                  style={{
                    borderBottom: "0.125rem solid",
                    width: "50%",
                    marginLeft: "1.25rem",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize="1.313rem"
                    fontFamily={"Roboto Light"}
                    fontWeight={600}
                    component="div"
                    align="left"
                    lineHeight={"1.5rem"}
                    color="#02273C"
                  ></Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", gap: "5rem" }}>
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className={"boldTitle"}>View Pricing Breakdown</div>
              <div className={"sectionSubheader"}>
                This detailed cost breakdown is derived from your plan
                selection, accumulated fund fees, and adjustments. Feel free to
                download it for your records.
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <Button
                variant="secondary"
                id="downloadCostSheet"
                onClick={() => {}}
              >
                Download Cost Sheet
                <DownloadIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
