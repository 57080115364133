import { Reducer } from "redux";
import { combineReducers } from "redux";
import { SnackbarAction } from "./snackbars/actions";
import snackbarsReducer from "./snackbars/reducer";
import navRailReducer from "./current-navrail/reducer";
import userReducer from "./user/reducer";
import userManagementReducer from "./userManagement/reducer";
import rolesManagementReducer from "./rolesManagement/reducer";
import projectsManagementReducer from "./projectsManagement/reducer";
import myserviceReducer from "./myServices/reducer";

import { MarketplaceState } from "./state";

type MarketplaceAction = SnackbarAction;
type MarketplaceReducer = Reducer<MarketplaceState, MarketplaceAction>;
const reducer: MarketplaceReducer = combineReducers({
  snackbars: snackbarsReducer,
  currentNavRail: navRailReducer,
  user: userReducer,
  userManagement: userManagementReducer,
  rolesManagement: rolesManagementReducer,
  projectsManagement: projectsManagementReducer,
  myservices:myserviceReducer
});

export default reducer;
