import * as React from "react";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Region from "../Regions";
import { useNavigate } from "react-router-dom";
import CardView from "../CardView";
import NorthAmerica from "../../../../assets/NorthAmerica.png";
import CatalogBenefits from "../CatalogBenefits";
import SecurityIcon from "@mui/icons-material/Security";
import ScaleIcon from "@mui/icons-material/Scale";
import ArchitectureIcon from "@mui/icons-material/Architecture";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CatalogKeyFeatures from "../CatalogKeyFeatures";
import NifiPricing from "./NifiPricing";
import CatalogSubHeading from "../CatalogSubHeading";
import Grid from "@mui/material/Grid";

const CARD_DATA = [
  {
    id: 1,
    logo: "/api/assets/datamesh.png",
    serviceName: "SS&C Everywhere Data Mesh",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "The SS&C Everywhere Data Mesh platform provides an interface for users to centralize their data to make it more accessible to the rapidly changing needs of a user’s organization.",
    tags: {
      function: "Cloud Platform",
      industry: "Cross-Industry",
      serviceType: "Data Service",
    },
  },
  {
    id: 2,
    logo: "/api/assets/algoriskreport.png",
    serviceName: "SS&C Algorithmics Risk Reports",
    minorVersion: 0,
    majorVersion: 1,
    shortDescription:
      "Generate world-class risk reports leveraging award and access powerful reporting dashboards preintegrated with your data.",
    tags: {
      function: "Risk Management",
      industry: "Cross-Industry",
      serviceType: "Analytics Service",
    },
  },
];

const NifiOverView: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigateTo = (name: string) => {
    if (name === "SS&C Everywhere Data Mesh") {
      return navigate("/public-catalog/data-mesh");
    }
    if (name === "SS&C Algorithmics Risk Reports") {
      return navigate("/public-catalog/ss&c-algorithmics-risk-reports");
    }
  };

  return (
    <>
      <div className="flex-col gap3rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Overview
          </Typography>
          <div>
            <Typography
              variant="h6"
              fontSize="1.25rem"
              sx={{ color: "#02273C", lineHeight: "1.5rem" }}
            >
              About This Service
            </Typography>
            <Typography
              variant="body1"
              component={"div"}
              sx={{ color: "#000", lineHeight: "1.5rem" }}
            >
              <div style={{ display: "flex", maxWidth: "90%" }}>
                <ul>
                  <li>
                    {" "}
                    Capable of handling large volumes of data with low latency,
                    making it ideal for real-time analytics and data processing.{" "}
                  </li>
                  <li>
                    {" "}
                    Easily scalable both horizontally and vertically to
                    accommodate increasing data loads and user demands.{" "}
                  </li>
                  <li>
                    {" "}
                    Designed to handle failures seamlessly with data replication
                    across multiple brokers, ensuring reliability and
                    durability.{" "}
                  </li>
                  <li>
                    {" "}
                    Operates as a distributed system, enabling high availability
                    and redundancy across clusters.{" "}
                  </li>
                  <li>
                    {" "}
                    Facilitates real-time data streaming and event processing,
                    allowing for immediate insights and actions.{" "}
                  </li>
                  <li>
                    {" "}
                    Acts as a messaging backbone, decoupling data producers and
                    consumers, allowing for independent scaling and development.{" "}
                  </li>
                </ul>
              </div>
            </Typography>
          </div>
        </div>
        <CatalogBenefits
          listItems={[
            {
              icon: (
                <TrendingUpIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "High Performance and Low Latency",
              description:
                "Capable of handling large volumes of data with minimal delay, making it ideal for real-time analytics and processing. This ensures that critical data is available instantly, enabling prompt decision-making.",
            },
            {
              icon: (
                <ScaleIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Scalability",
              description:
                "Easily scalable both horizontally and vertically to manage growing data loads and user demands. This adaptability ensures the system can expand as your needs evolve, maintaining high performance without compromise.",
            },
            {
              icon: (
                <SecurityIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Reliability and Durability",
              description:
                "Designed to handle failures seamlessly, with data replication across multiple brokers. This ensures data integrity and availability even in the face of hardware or network issues, providing robust reliability.",
            },
            {
              icon: (
                <ArchitectureIcon
                  color="primary"
                  sx={{ width: "4.875rem", height: "4.875rem" }}
                />
              ),
              heading: "Distributed Architecture",
              description:
                "Operates as a distributed system, offering high availability and redundancy. This architecture ensures continuous operation and resilience, distributing workloads across multiple nodes to avoid single points of failure.",
            },
          ]}
        />

        <CatalogKeyFeatures
          childCount={2}
          listItems={[
            {
              heading: "High Throughput",
              description:
                "Nifi can handle large volumes of data, making it ideal for scenarios where high data ingestion rates are required, such as log aggregation and real-time analytics.",
            },
            {
              heading: "Durability",
              description:
                "With data replication and partitioning, Nifi ensures that data is stored reliably across the cluster, making it durable even in the event of hardware failures.",
            },
            {
              heading: "Stream Processing",
              description:
                "Nifi enables real-time stream processing with tools like Nifi Streams and ksqlDB, allowing businesses to process and analyze data as it arrives.",
            },
            {
              heading: "Flexible Data Consumption",
              description:
                "Nifi allows multiple consumers to read data independently at their own pace, supporting a wide variety of applications, from real-time monitoring to batch processing.",
            },
          ]}
        />
        <NifiPricing title={"Available Pricing Plans"} />
        <div className={"flex-col gap3rem"}>
          <CatalogSubHeading title={"Region Availability"} />
          <div className={"flex-col gap1rem"}>
            <CatalogSubHeading
              title={"Available Regions"}
              fontSize="1.25rem"
              color="#02273C"
            />
            <div>
              <Grid container spacing={0}>
                <Grid item xs={6} md={6}>
                  <Grid container spacing={0}>
                    <Grid item xs={6} md={6}>
                      <li className="regionListli">{"North America"}</li>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6} md={6}>
                  <img
                    alt={"northAmerica"}
                    width={400}
                    height={200}
                    src={NorthAmerica}
                  />
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="specificRegionDetils">
            <CatalogSubHeading
              title={"Specific Region Details"}
              fontSize="1.25rem"
              color="#02273C"
            />

            <div>
              <Grid container spacing={2}>
                <Grid item xs={4} md={4}>
                  <Region />
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Region Details
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    North America: This region provides low-latency access to
                    customers located in the United States and Canada. It offers
                    high availability and scalability, making it an ideal choice
                    for enterprise customers with large-scale workloads.
                  </Typography>
                </Grid>
                <Grid item xs={4} md={4}>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={700}
                    component="div"
                    sx={{
                      color: "#02273C",
                      lineHeight: "1.5rem",
                      paddingBottom: 1,
                    }}
                  >
                    Local Compliance
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{ color: "#577687", lineHeight: "1.5rem" }}
                  >
                    Fully compliant with regulatory requirements, including:
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="1rem"
                    fontWeight={400}
                    component="div"
                    sx={{
                      color: "#577687",
                      lineHeight: "1.5rem",
                      paddingLeft: 2,
                    }}
                  >
                    <ul>
                      <li>GDPR</li>
                      <li>ISO 27000</li>
                      <li>SOC-2</li>
                    </ul>
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
        <Typography
          variant="h4"
          fontSize="2.125rem"
          fontWeight={600}
          component="div"
          sx={{ color: "#0A85C7", lineHeight: "1.875rem" }}
        >
          Related Products
        </Typography>
        <div style={{ maxWidth: "80%" }}>
          <CardView
            variant="simple"
            data={CARD_DATA}
            handleNavigateTo={handleNavigateTo}
          />
          <Link
            sx={{
              marginLeft: "1.875rem",
              fontWeight: 600,
              marginTop: "-1.875rem",
              cursor: "pointer",
            }}
            id={"viewMore"}
            onClick={() => {
              navigate("/public-catalog");
            }}
          >
            View More
          </Link>
        </div>
      </div>
    </>
  );
};

export default NifiOverView;
