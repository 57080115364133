import { useEffect, useState } from "react";
import axios from "axios";
import { AxiosError } from "axios";
import { Projects } from "../../../../../common/dataTypes";
import { USER_ENDPOINT_BASE_URL, baseUrl } from "../../../../../constants";

export const steps = ["Details", "Review"];

export function usePublishApiHooks() {
  const [isLoading, setIsLoading] = useState(false);
  const [publishError, setPublishError] = useState("");
  const [imageList, setImageList] = useState({});
  const [hasError, setHasError] = useState(false);

  const [projects, setProjects] = useState<Projects[]>([
    {
      projectId: "",
      id: "",
      name: "",
      description: "",
      createdTimestamp: 0,
      instances: [],
    },
  ]);

  const fetchProjectDetails = () => {
    axios
      .get(`${USER_ENDPOINT_BASE_URL}/projects`)
      .then((res) => {
        setProjects(res.data.projects);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  const fetchLogoList = () => {
    axios
      .get(
        `${baseUrl}/api/mktplc-catalog-api/v0/private/services/applications/data_mesh/my-logos`,
      )
      .then((res) => {
        setImageList(res.data);
      })
      .catch(() => {
        setHasError(true);
      });
  };

  useEffect(() => {
    fetchProjectDetails();
    fetchLogoList();
  }, []);

  const createPublishApi = (payload: any, urlData) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      const url = `${baseUrl}/api/mktplc-catalog-api/v0/private/services/publish/${urlData.serviceName}?version=${urlData.majorVersion}.${urlData.minorVersion}`;
      axios
        .post(url, payload)
        .then((res) => {
          setIsLoading(false);
          resolve({ success: true, data: res.data });
        })
        .catch((error: AxiosError) => {
          setIsLoading(false);
          if (error.response && error.response.status === 403) {
            setPublishError("Unauthorized: Unable to Publish API");
            setHasError(true);
          } else {
            setPublishError("Unable to Publish API");
            setHasError(true);
          }
          reject({ success: false, error: error.message });
        });
    });
  };

  const uploadLogo = (payload: any, urlData) => {
    return new Promise((resolve, reject) => {
      setIsLoading(true);
      const url = `${baseUrl}/api/mktplc-catalog-api/v0/services/upload-file/${urlData.serviceName}?version=${urlData.majorVersion}.${urlData.minorVersion}&service_type=private&file_type=logo&application_name=data_mesh`;

      axios
        .post(url, payload)
        .then((res) => {
          setIsLoading(false);
          resolve({ success: true, data: res.data });
        })
        .catch((error: AxiosError) => {
          setIsLoading(false);
          if (error.response && error.response.status === 403) {
            setPublishError("Unauthorized: Unable to Upload Logo");
          } else {
            setPublishError("Unable to Upload Logo");
          }
          reject({ success: false, error: error.message });
        });
    });
  };

  return {
    createPublishApi,
    uploadLogo,
    fetchProjectDetails,
    projects,
    imageList,
    isLoading,
    publishError,
    hasError,
  };
}
