import * as React from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import { Button } from "@ssce/ui-shared-library";

import Box from "@mui/material/Box";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useNavigate } from "react-router-dom";
import CatalogSuperSetOverView from "./CatalogSupersetOverView";
import CatalogKeyFeatures from "../CatalogKeyFeatures";

import Grid from "@mui/material/Grid";
import WavingHandOutlinedIcon from "@mui/icons-material/WavingHandOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";

import ApiIcon from "@mui/icons-material/Api";

import CatalogRelevantResources from "../CatalogRelevantResources";
import "./Superset.css";
import { MyServicesCardHeader } from "../../MyServices/Cards/CardTemplate/myServicesCardHeader";
const CatalogSuperSet: React.FC = () => {
  const [value, setValue] = useState("1");

  const navigate = useNavigate();

  const handleChangeTabs = (_, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <MyServicesCardHeader
        imageSource={"/api/assets/superset.png"}
        title={"Apache Superset"}
        subTitle={`Ver. 1.0`}
        contents={
          "Apache Superset is an open-source data exploration and visualization platform that enables you to create interactive and shareable dashboards. Whether you're a data analyst, data scientist, or business user, Superset provides a user-friendly interface for exploring and visualizing your data."
        }
        tags={[
          {
            label: "BI",
            style: { backgroundColor: "#E7F3F9", color: "#0A85C7" },
          },
          {
            label: "Cross-Industry",
            style: { backgroundColor: "#EFE6FD", color: "#6200EE" },
          },
          {
            label: "Data & Analytics",
            style: { backgroundColor: "#FFE5F0", color: "#D72F77" },
          },
        ]}
      >
        <Button
          variant="default"
          id={"createInstance"}
          width="12rem"
          onClick={() => navigate("/public-catalog/apache-superset/provision")}
        >
          Create Instance
        </Button>
      </MyServicesCardHeader>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            id={"tabs"}
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="Overview" value="1" />
            <Tab label="Plans and Features" value="2" />
            <Tab label="Documentation" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <CatalogSuperSetOverView />
        </TabPanel>

        <TabPanel value="2" sx={{ marginTop: "4.5rem", padding: 0 }}>
          <CatalogKeyFeatures
            childCount={2}
            title="Plans and Features"
            listItems={[
              {
                heading: "Features",
                description:
                  "With the Data Mesh offering, you're in control. We support a variety of data source types to make your data connection seamless. Here are the types you can connect to:",
                moreContent: (
                  <ul className="plansFeaturesList">
                    <li>
                      An intuitive interface for visualizing datasets and
                      crafting interactive dashboards
                    </li>
                    <li>
                      A wide array of beautiful visualizations to showcase your
                      data
                    </li>
                    <li>
                      Code-free visualization builder to extract and present
                      datasets
                    </li>
                    <li>
                      A world-class SQL IDE for preparing data for
                      visualization, including a rich metadata browser
                    </li>

                    <li>
                      A lightweight semantic layer which empowers data analysts
                      to quickly define custom dimensions and metrics
                    </li>
                    <li>
                      Out-of-the-box support for most SQL-speaking databases
                    </li>
                    <li>
                      Seamless, in-memory asynchronous caching and queries
                    </li>
                    <li>
                      An extensible security model that allows configuration of
                      very intricate rules on who can access which product
                      features and datasets.
                    </li>
                    <li>
                      Integration with major authentication backends (database,
                      OpenID, LDAP, OAuth, REMOTE_USER, etc)
                    </li>
                    <li>The ability to add custom visualization plugins</li>
                    <li>An API for programmatic customization</li>
                    <li>
                      A cloud-native architecture designed from the ground up
                      for scale
                    </li>
                  </ul>
                ),
              },
              {
                heading: "Backend Technology",
                description:
                  "Users can query data across multiple database types effortlessly regardless of the technology used to store it.",
                moreContent: (
                  <ul className="plansFeaturesList">
                    <li>Web server (Gunicorn, Nginx, Apache),</li>
                    <li>
                      Metadata database engine (PostgreSQL, MySQL, MariaDB),
                    </li>
                    <li>Message queue (Celery, Redis, RabbitMQ, SQS, etc.),</li>
                    <li>Results backend (Redis, S3, Memcached, etc.),</li>
                    <li>Caching layer (Redis, Memcached, etc.)</li>
                  </ul>
                ),
              },
            ]}
          />
        </TabPanel>
        <TabPanel value="3" sx={{ padding: "4.5rem 0 0 0", display: "flex" }}>
          <Grid
            container
            direction="column"
            justifyContent="center"
            gap={"3rem"}
          >
            <Typography
              variant="h3"
              color="primary"
              className="documentationHeading"
            >
              Documentation
            </Typography>
            <Typography
              variant="body1"
              component={"div"}
              marginBottom={"1.25rem"}
              className="documentationDescription"
            >
              Explore the world of Apache Superset right here on our cloud
              marketplace offerings. Whether you're eager to dive into the
              documentation to get started or in search of valuable resources,
              you can find all you need on Apache Superset's official
              documentation site. For your convenience, here are the direct
              links to access the documentation and uncover the full potential
              of Apache Superset for your data exploration and visualization
              requirements.
            </Typography>
            <CatalogRelevantResources
              title=""
              listItems={[
                {
                  icon: (
                    <WavingHandOutlinedIcon
                      color="primary"
                      sx={{
                        width: "3.5rem",
                        padding: "0.625rem",
                        height: "3.5rem",
                        borderRadius: "6.25rem",
                        backgroundColor: "#A1E1F5",
                      }}
                    />
                  ),
                  heading: "Introduction",
                  description:
                    "Welcome to Apache Superset, a modern, enterprise-ready business intelligence web application.",
                },
                {
                  icon: (
                    <LibraryBooksOutlinedIcon
                      color="primary"
                      sx={{
                        width: "3.5rem",
                        padding: "0.625rem",
                        height: "3.5rem",
                        borderRadius: "6.25rem",
                        backgroundColor: "#A1E1F5",
                      }}
                    />
                  ),
                  heading: "Developer Tools",
                  description:
                    "Discover our developer tools and resources in the documentation to kickstart your journey.",
                },
                {
                  icon: (
                    <ApiIcon
                      color="primary"
                      sx={{
                        width: "3.5rem",
                        padding: "0.625rem",
                        height: "3.5rem",
                        borderRadius: "6.25rem",
                        backgroundColor: "#A1E1F5",
                      }}
                    />
                  ),
                  heading: "API Docs",
                  description:
                    "Superset's public REST API follows the OpenAPI specification, and is documented here.",
                },
              ]}
            />
            <div></div>
          </Grid>
        </TabPanel>
      </TabContext>
      <div className="createInstanceBtn">
        <Button
          variant="default"
          id={"createInstance"}
          width="12rem"
          onClick={() => navigate("/public-catalog/superset/provision")}
        >
          Create Instance
        </Button>
        <Button variant="secondary" id={"gettingStarted"} onClick={() => {}}>
          Getting Started
        </Button>
      </div>
    </>
  );
};
export default CatalogSuperSet;
