import React from "react";
import { Table, Column } from "@ssce/ui-shared-library";
import IconButton from "@mui/material/IconButton";
import VpnKeyOffIcon from "@mui/icons-material/VpnKeyOff";
import Box from "@mui/material/Box";
import moment from "moment";
import { ApiKey } from "./apiKeys";
import { NewApiKey } from "./apiKeysHooks";
import { CopyIconButton } from "@ssce/ui-shared-library";
import { useAclHooks } from "../../subcomponents/Acl/hooks";
import { PERMISSION_LIST } from "../../../../constants";

export const ApiKeysTable: React.FC<{
  apiKeys: ApiKey[];
  newApiKey?: NewApiKey;
  onCopyClick: (id: string, name: string) => void;
}> = ({ apiKeys, onCopyClick, newApiKey }) => {
  const isNewApiKey = (apiKeyName) => newApiKey?.key === apiKeyName;
  const columns: Column[] = [
    {
      title: "API Key Name",
      dataIndex: "keyName",
      sorter: true,
    },
    {
      title: "Created",
      dataIndex: "createDate",
      sorter: true,
      searchByValue: (date) => moment(date).format("ll"),
      render: (date) => moment(date).format("ll"),
    },
    {
      title: "Expiry",
      dataIndex: "expireDate",
      sorter: true,
      searchByValue: (date) => moment(date).format("ll"),
      render: (date) => moment(date).format("ll"),
    },
    {
      title: "Key",
      dataIndex: "keyName",
      render: (keyName) => (
        <Box sx={{ display: "flex", alignItems: "flex-end" }}>
          <CopyIconButton
            isDisabled={!isNewApiKey(keyName)}
            copyText={isNewApiKey(keyName) ? (newApiKey?.value as string) : ""}
          />
          <div className={"keyValue"}>
            {isNewApiKey(keyName) ? newApiKey?.value : "Unavailable"}
          </div>
        </Box>
      ),
    },
  ];
  const Protected_column = [
    {
      title: "Actions",
      render: (_, row) => (
        <div className={"actions"}>
          <IconButton
            aria-label="Copy"
            size="large"
            id={"copy"}
            onClick={() => onCopyClick(row.id, row.keyName)}
          >
            <VpnKeyOffIcon sx={{ color: "var(--primary)" }} />
          </IconButton>
        </div>
      ),
    },
  ];
  const final_colums = useAclHooks(PERMISSION_LIST.API_KEYS_EXECUTE)
    ? [...columns, ...Protected_column]
    : columns;
  return (
    <Table
      id={"table"}
      data={apiKeys}
      columns={final_colums}
      showSearchFilter
    />
  );
};
