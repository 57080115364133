import { IDENTITY_CONFIG } from "../../constants";
import {
  UserManager,
  UserManagerSettings,
  WebStorageStateStore,
  Log,
} from "oidc-client-ts";
import { cookieStorage } from "../../common/utils";

export default class AuthService {
  UserManager: UserManager;
  UserManagerSettings: UserManagerSettings;

  constructor(
    updateUserDetails: (token: string, username: string, email: string) => void,
  ) {
    //@ts-ignore
    this.UserManagerSettings = {
      ...IDENTITY_CONFIG,
      userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    };
    this.UserManager = new UserManager(this.UserManagerSettings);
    // Logger
    //console.log('IDENTITY_CONFIG',IDENTITY_CONFIG)
    Log.setLogger(console);
    Log.setLevel(Log.INFO);

    const loadUser = (user: any) => {
      const { name, preferred_username } = user.profile;
      updateUserDetails(user.access_token, name, preferred_username);
    };

    this.UserManager.events.addUserLoaded((user) => {
      loadUser(user);
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        //document.cookie = 'login=false';
        cookieStorage.setItem("login", "false");
        this.navigateToScreen();
      }
    });
    this.UserManager.getUser().then((user) => {
      if (null !== user) {
        loadUser(user);
      }
    });
    this.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
      this.logout();
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      console.log("token expired");
      this.signinSilent();
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback()
      .then(() => {
        ("");
      })
      .catch((error) => {
        console.log("error", error === "ErrorResponse: login_required", error);
        if (error === "ErrorResponse: login_required") {
          cookieStorage.removeItem("login");
          window.location.replace("/");
        }
      });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  signinRedirect = (params = { extraQueryParams: { kc_idp_hint: "" } }) => {
    sessionStorage.setItem("redirectUri", window.location.pathname);
    localStorage.setItem(
      "idp_alias_name",
      params?.extraQueryParams?.kc_idp_hint,
    );
    this.UserManager.signinRedirect({ ...params });
  };

  navigateToScreen = () => {
    window.location.replace(sessionStorage.getItem("redirectUri") || "/");
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      sessionStorage.getItem(
        `oidc.user:${IDENTITY_CONFIG.authority}:${IDENTITY_CONFIG.client_id}`,
      ) as string,
    );
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  signinSilent = (params = {}) => {
    this.UserManager.signinSilent({ ...params })
      .then((user) => {
        console.log("signed in", user);
        window.location.reload();
      })
      .catch((err) => {
        cookieStorage.removeItem("login");
        console.log("signinSilent failed");
        console.log(err);
        window.location.replace(sessionStorage.getItem("redirectUri") || "/");
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback().then((user) => {
      console.log("signinSilentCallback", user);
    });
  };

  createSigninRequest = () => {
    //@ts-ignore
    return this.UserManager.createSigninRequest();
  };

  logout = async() => {
    document.cookie = "login=false; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    const user = await this.UserManager.getUser();
    this.UserManager.signoutRedirect({id_token_hint:user?.id_token});

    this.UserManager.clearStaleState();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      window.location.replace("/");
    });
    this.UserManager.clearStaleState();
  };
}
