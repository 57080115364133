import styled from "styled-components";
import { Link } from "react-router-dom";
import { fadeIn, fadeOut } from "src/globalAnimations.styles";

export const NewButton = styled.button`
  background-color: #da3232;
  color: white;
  border: none;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0.9375rem;
  margin: 0;
  float: right;
  border-top-left-radius: 0.5rem;
  font-weight: 900;
`;

export const ImageContainer = styled.div`
  text-align: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
`;

export const Heading = styled.div`
  margin: 0.625rem;
  text-align: center;
  place-content: center;
  align-items: center;
  display: flex;
`;

export const Footer = styled.footer`
  width: 100%;
  padding-right: 0.625rem;
  padding-bottom: 0.625rem;
  display: flex;
  justify-content: flex-end;
`;

export const Description = styled.div`
  text-align: center;
  height: 6.25rem;
  overflow: hidden;
  padding: 0 1.25rem;
  line-height: 1.5rem;
`;

export const StyledCard = styled.div<{
  isMain: boolean;
  id: string;
  mainItem: number;
  prevId: number;
}>`
  background-color: #fafcff;
  position: relative;
  transform: scale(0.9);
  border: 0.03125rem solid var(--neutral400);
  box-sizing: border-box;
  margin: 0 -1.875rem;
  border-radius: 0.5rem;

  &.card1 {
    position: relative;
    animation: ${(props) =>
      props.mainItem === 1
        ? fadeIn
        : props.prevId === 1
          ? fadeOut
          : null} 0.3s ease-in-out forwards;

    ${(props) =>
      props.mainItem === 1 &&
      `
      z-index: 1;   
      position: relative;
      box-shadow: 0 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.25);  
    `}
  }

  &.card2 {
    animation: ${(props) =>
      props.mainItem === 2
        ? fadeIn
        : props.prevId === 2
          ? fadeOut
          : null} 0.3s ease-in-out forwards;
    ${(props) =>
      props.mainItem === 2 &&
      `
      z-index: 1;   
      position: relative;   
      box-shadow: 0 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.25);   
    `}
  }

  &.card3 {
    animation: ${(props) =>
      props.mainItem === 3
        ? fadeIn
        : props.prevId === 3
          ? fadeOut
          : null} 0.3s ease-in-out forwards;
    ${(props) =>
      props.mainItem === 3 &&
      `
      z-index: 1;  
      position: relative; 
      box-shadow: 0 0.625rem 1.25rem 0 rgba(0, 0, 0, 0.25);  
    `}
`;

export const StyledLink = styled(Link)`
  color: var(--primary);
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  opacity: 0.8;
  &:hover {
    border-bottom: 0.0625rem solid var(--primary);
    opacity: 1;
  }
`;

export const StyledExtenalLink = styled.a`
  color: var(--primary);
  text-decoration: none;
  font-weight: 900;
  font-size: 0.9rem;
  opacity: 0.8;
  &:hover {
    border-bottom: 0.0625rem solid var(--primary);
    opacity: 1;
  }
`;

export const DashboardCardWrapper = styled.div`
  place-content: center;
  animation: ${fadeIn} 0.3s ease-in-out forwards;
  padding-top: 5%;
  padding-left: 10%;
  padding-right: 10%;
`;
