import PublishApiDetails from "../PublishApiDetails/publishApiDetails";

export const PublishApiForm = ({
  publishApi,
  onFormChange,
  imageList,
  fetchProjectDetails,
  addProjectDetails,
  serviceName,
  projects,
  handleChange,
  projectError,
  logoError,
  setLogoError,
  setProjectError,
}) => {
  return (
    <PublishApiDetails
      {...publishApi}
      onFormChange={onFormChange}
      imageList={imageList}
      fetchProjectDetails={fetchProjectDetails}
      addProjectDetails={addProjectDetails}
      serviceName={serviceName}
      projects={projects}
      handleChange={handleChange}
      projectError={projectError}
      logoError={logoError}
      setLogoError={setLogoError}
      setProjectError={setProjectError}
    />
  );
};
