import * as React from "react";
import Box from "@mui/material/Box";
import { Button } from "@ssce/ui-shared-library";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import AccessRequest from "../../../../../assets/AccessRequest.png";
import Alert from "@mui/material/Alert";
import "./PCAccessRequest.css";

const PCAccessRequest: React.FC = () => {
  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "0.125rem solid #000",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal-container">
          <div className="modal-content">
            <div className="top-line">
              <p className="access-request-header">Access Request</p>
              <img
                className="access-request-img"
                alt={"accessRequest"}
                src={AccessRequest}
              ></img>
            </div>
            <Alert severity="warning" className="pc-approvel-required">
              <Typography className="approval-required-header">
                Approval Required
              </Typography>
              <Typography className="approval-required-text">
                <b>You need further approval to access this API.</b> In order to
                continue with the process, you will need to request access from
                the dedicated representative of this API's service team. They
                handle all access requests and can assist you in the process
              </Typography>
              <br></br>
              <Typography className="approval-required-text">
                Here is the contact detail:
                <ul>
                  <li>
                    <b>Email</b>: EverywhereTechTeam@sscinc.com
                  </li>
                </ul>
              </Typography>
            </Alert>
          </div>
          <Box className="ar-button-container">
            <Stack
              spacing={2}
              direction="row"
              style={{
                width: "15.625rem",
                marginLeft: "auto",
                paddingTop: "0.625rem",
              }}
            >
              <Button
                variant="secondary"
                id={"getSupport"}
                onClick={handleClose}
              >
                Get Support
              </Button>
              <Button variant="default" onClick={handleClose} id={"close"}>
                Close
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default PCAccessRequest;
