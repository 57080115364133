const SubcategoryInfo: React.FC = () => {
  return (
    <>
      <div
        style={{
          marginLeft: "1.25rem",
          marginTop: "1.875rem",
          display: "flex",
          flex: "0%",
          flexWrap: "wrap",
          gap: "15%",
        }}
      >
        <div>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            Subscription ID:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            2387-99746-2938462
          </p>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            Directory:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            Default Directory (admin@companyname.com)
          </p>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            My Role:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            Account Admin
          </p>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            Offer:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            Pay-As-You-Go
          </p>
        </div>
        <div>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            Offer ID:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            SSNC-Sal-MRP-39485
          </p>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            Subscription Name:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            Subscription name
          </p>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            Status:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            Active
          </p>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            Secure Source:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            Not Available
          </p>
        </div>
        <div>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            Current Billing Period:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            Jan 12, 2022 - Feb 11, 2022
          </p>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            Currency:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            USD
          </p>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            Last Bill Amount:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            $229.01
          </p>
          <p style={{ fontWeight: 400, fontSize: 14, color: "#577687" }}>
            Current Bill Amount:
          </p>
          <p
            style={{
              fontWeight: 400,
              lineHeight: 0.1,
              fontSize: 14,
              marginBottom: 30,
            }}
          >
            $174.50
          </p>
        </div>
      </div>
    </>
  );
};

export default SubcategoryInfo;
