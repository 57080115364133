import React from "react";
import styled from "styled-components";

const GridContainer = styled.section<{ minMax: string; padding: string }>`
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(${(props) => props.minMax}, 1fr)
  );
  height: max-content;
  gap: 1.25rem;
  padding: ${(props) => props.padding};
  width: 100%;
`;

type GridItem = {
  children: React.ReactNode;
  onScroll?: (event: React.UIEvent<HTMLDivElement, UIEvent>) => void;
  minMax?: string;
  padding?: string;
};

const Grid = ({
  children,
  minMax = "21.25rem",
  padding = "1.875rem 0",
  onScroll,
}: GridItem) => {
  return (
    <GridContainer minMax={minMax} padding={padding} onScroll={onScroll}>
      {children}
    </GridContainer>
  );
};

export default Grid;
