import * as React from "react";
import Typography from "@mui/material/Typography";
import "./Kafka.css";

const KafkaTechnicalSpecifications: React.FC = () => {
  const RenderTechDetails = ({ heading, desc }) => {
    return (
      <div>
        <div className="flex-col">
          <Typography
            variant="h6"
            fontSize="1.25rem"
            sx={{ color: "#02273C", lineHeight: "1.5rem" }}
          >
            {heading}
          </Typography>
        </div>
        <div className="flex-col padding1rem">
          <Typography
            variant="body1"
            sx={{ color: "#000", lineHeight: "1.5rem" }}
          >
            {desc}
          </Typography>
        </div>
      </div>
    );
  };
  return (
    <>
      <div className="flex-col gap4rem">
        <div className="flex-col gap1rem">
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Technical Specifications
          </Typography>
          <RenderTechDetails
            heading={"Storage capacity"}
            desc={
              "Kafka stores data in a distributed log format across multiple brokers. The storage capacity is determined by the disk space available on each broker and can be scaled by adding more brokers to the cluster."
            }
          />
          <RenderTechDetails
            heading={"Processing power"}
            desc={
              "Kafka's processing power is influenced by the number of brokers, CPU cores, and memory allocated to the Kafka cluster. It supports high-throughput data processing, with the ability to handle millions of messages per second depending on the hardware configuration."
            }
          />
          <RenderTechDetails
            heading={"Bandwidth or data transfer limits"}
            desc={
              "Kafka does not impose hard limits on bandwidth or data transfer. However, the throughput depends on the network infrastructure and the configuration of producers, consumers, and brokers. Tuning network settings and leveraging compression can optimize data transfer efficiency."
            }
          />
          <RenderTechDetails
            heading={"Scalability"}
            desc={
              "Kafka is highly scalable, allowing horizontal scaling by adding more brokers to a cluster. Partitioning data across multiple brokers enables the system to handle increased load and maintain performance as the dataset grows."
            }
          />
          <RenderTechDetails
            heading={"Performance guarantees"}
            desc={
              "Kafka provides strong durability guarantees through data replication across brokers, ensuring data availability even in case of failures. However, specific performance metrics like latency and throughput depend on cluster configuration and hardware."
            }
          />
          <RenderTechDetails
            heading={"Data redundancy and backup"}
            desc={
              "Kafka ensures data redundancy through replication, where data is copied across multiple brokers. This mechanism protects against data loss in case of broker failure. Kafka also supports log compaction to retain the latest state of each record for data integrity."
            }
          />
          <RenderTechDetails
            heading={"Limitations or restrictions"}
            desc={
              "Kafka's performance may be affected by factors such as the number of partitions per topic, disk I/O, and network bandwidth. While Kafka can handle large-scale data processing, careful configuration is required to avoid bottlenecks."
            }
          />
          <RenderTechDetails
            heading={"Latency"}
            desc={
              "Kafka is designed to provide low-latency message delivery, typically in the order of milliseconds. However, actual latency depends on network conditions, message size, and system configuration."
            }
          />
          <RenderTechDetails
            heading={"Retention policies"}
            desc={
              "Kafka allows configurable retention policies, enabling data to be stored for a specified duration or until a certain storage size is reached. This flexibility supports both long-term storage and real-time processing needs."
            }
          />
          <RenderTechDetails
            heading={"Security features"}
            desc={
              "Kafka offers robust security features, including SSL/TLS encryption for data in transit, SASL for authentication, and ACLs (Access Control Lists) for fine-grained access control to topics and resources."
            }
          />
        </div>
        <div></div>
      </div>
    </>
  );
};

export default KafkaTechnicalSpecifications;
