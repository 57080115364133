import Everywhere from "./everywhere-blue-no-text.svg";
import styled from "styled-components";

const LoginHeaderContainer = styled.section`
  display: flex;
  border-bottom: 0.063rem solid var(--neutral300);
`;

const LoginHeaderText = styled.div`
  color: var(--primary);
  font-family: "Roboto Medium";
  font-size: 1.25rem;
  line-height: 1.5rem;
  padding-left: 0.5rem;
  align-self: center;
  letter-spacing: normal;
`;

const Header = () => {
  return (
    <LoginHeaderContainer className={"loginHeadGrid"}>
      <img src={Everywhere} alt="everywhere" />
      <LoginHeaderText>SS&C Everywhere: Marketplace</LoginHeaderText>
    </LoginHeaderContainer>
  );
};

export default Header;
