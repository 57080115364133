import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import FormHelperText from "@mui/material/FormHelperText";
import { Button } from "@ssce/ui-shared-library";
import "./CatalogAlgoRiskReport.css";

export default function CatalogAlgoRiskFunds({
  data,
  updateSelectedFund,
  isGlobal,
}: any) {
  const [selectedFund, setSelectedFund] = useState<any[]>([]);

  return (
    <Stack spacing={1} sx={{ width: "90%" }}>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={data}
        getOptionLabel={(option) => option.name}
        value={selectedFund}
        filterSelectedOptions
        sx={{
          '& + .muiautocomplete-inputroot[class*="muioutlinedinput-root': {
            backgroundColor: "red",
          },
        }}
        onChange={(_, values) => {
          setSelectedFund(values);
        }}
        renderInput={(params) => (
          <TextField {...params} label={isGlobal ? "Companies" : "Funds"} />
        )}
      />
      <FormHelperText>
        Please use the dropdown box above or type in a{" "}
        {isGlobal ? "company" : "fund"} name to select it.
      </FormHelperText>
      <Stack spacing={2} direction="row">
        <Button
          id={"addFunds"}
          onClick={() => {
            updateSelectedFund(selectedFund);
            setSelectedFund([]);
          }}
          variant="default"
          className="catalog-algorisk-add-funds"
          size="medium"
        >
          Add {isGlobal ? "Companies" : "Funds"}
        </Button>
        <Button
          id={"clear"}
          onClick={() => setSelectedFund([])}
          variant="secondary"
          className="catalog-algorisk-clear"
          size="medium"
        >
          Clear
        </Button>
        <Button
          variant="text"
          id="openAdvancedSearch"
          className="catalog-algorisk-open-search"
          onClick={() => {}}
          size="medium"
        >
          Open Advanced Search
        </Button>
      </Stack>
    </Stack>
  );
}
