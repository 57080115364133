import React from "react";
import FilterDropdown from "../Dropdown/FilterDropdown";
import { TextField } from "@ssce/ui-shared-library";
import { ListTogglerValue, TableListToggler } from "./tableListToggler";
import styled, { keyframes } from 'styled-components';

const slideUp = keyframes`
  from {
    transform: translateY(31.25rem);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const TextFieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.938rem;
  animation: ${slideUp} 0.2s ease-out;
`;

type CardFilterHeaderProps = {
  toggleValue: string;
  searchValue: string;
  handleToggleChange: (value: ListTogglerValue) => void;
  handleChangeForSearch: (e: any) => void;
};

export const CardFilterHeader: React.FC<CardFilterHeaderProps> = ({
  toggleValue,
  searchValue,
  handleToggleChange,
  handleChangeForSearch,
}) => {
  return (
    <TextFieldWrapper>
      <TableListToggler
        id={"tableListToggler"}
        value={toggleValue}
        onChange={handleToggleChange}
      />
      <TextField
        placeholder="Search..."
        autoFocus
        value={searchValue}
        onChange={handleChangeForSearch}
      />
      <FilterDropdown />
    </TextFieldWrapper>
  );
};
