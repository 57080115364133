import React from "react";
import Typography from "@mui/material/Typography";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import Chip from "@mui/material/Chip";
import "./table.scss";

type ServiceNameCellProps = {
  rowData: any;
  handleItemSelect?: (item: any) => void;
  showInstanceName?: boolean;
};

export const ServiceNameCell: React.FC<ServiceNameCellProps> = ({
  rowData,
  showInstanceName,
  handleItemSelect,
}) => {
  return (
    <div className="catalog-tableview-service">
      <img
        className={"catalog-tableview-service-logo"}
        src={rowData.logo}
        alt="SS&C logo"
      ></img>
      <div className={"catalog-tableview-service-title-container"}>
        {rowData.plan && (
          <div
            style={{ display: "flex", gap: "0.438rem", alignItems: "center" }}
          >
            <Typography fontWeight={600} className={"catalog-tableview-plan"}>
              {rowData.plan}
            </Typography>
            <NotificationImportantIcon
              className={"catalog-tableview-service-alert"}
            />
          </div>
        )}

        <Typography
          gutterBottom
          component="div"
          align="left"
          fontWeight={700}
          lineHeight={1}
          style={{
            color: "var(--primary)",
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => handleItemSelect && handleItemSelect(rowData)}
        >
          {showInstanceName ? rowData.instanceName : rowData.serviceName}
        </Typography>
        <div className="catalog-tableview-service-subtitle-container">
          {rowData.ribbon &&
            (rowData.ribbon === "Experimental" ? (
              <Chip
                label="Experimental"
                className="catalog-tableview-service-chip-yellow"
              ></Chip>
            ) : (
              <Chip
                label="Popular"
                className="catalog-tableview-service-chip-green"
              ></Chip>
            ))}
        </div>
      </div>

      <Typography
        gutterBottom
        variant="body2"
        component="div"
        align="left"
        fontWeight={500}
        lineHeight={1.5}
        style={{ marginTop: "0.75rem", fontSize: "0.875rem" }}
      ></Typography>
    </div>
  );
};
