import { useState, useEffect, useContext } from "react";
import MyservicesTableView from "./Cards/MyServicesTableView";
import { ListTogglerValue } from "../subcomponents/CardFilterHeader/tableListToggler";
import MyServicesListView from "./Cards/myServicesListView";
import { useNavigate } from "react-router-dom";
import { CurrentViewContext } from "../ComponentWithContext";
import styled from "styled-components";
import { CardFilterHeader } from "../subcomponents/CardFilterHeader";

const MyServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
const TableContainer = styled.div`
  min-height: 30rem;
`;

type MyServicesProps = {
  handleBreadCrumbNickName?: (name: string) => void;
  items: any;
};

export const MyServicesContent = ({
  handleBreadCrumbNickName,
  items,
}: MyServicesProps) => {
  const { setCurrentView, setViewData } = useContext(CurrentViewContext);
  const navigate = useNavigate();

  const handleNavigateTo = (name: string) => {
    return navigate(`/my-services/${name}`);
  };

  const [searchValue, setSearchValue] = useState<string>("");
  const [listTogglerValue, setListTogglerValue] =
    useState<ListTogglerValue>("list");
  const [filteredItems, setFilteredItems] = useState(items);
  const handleToggleChange = (newAlignment: ListTogglerValue) => {
    setListTogglerValue(newAlignment);
  };

  useEffect(() => {
    const filtered = items.filter(
      (item: { serviceName: string; instanceName: string }) =>
        item.serviceName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.instanceName.toLowerCase().includes(searchValue.toLowerCase()),
    );
    setFilteredItems(filtered);
  }, [items, searchValue]);

  const handleChangeForSearch = (e: { target: { value: any } }) => {
    setSearchValue(e.target.value);
  };
  const handleItemSelect = (item: any) => {
    if (item.catalogType === "private") {
      setViewData(item);
      localStorage.setItem("serviceData", JSON.stringify(item));
      handleNavigateTo(item.serviceName);
    } else if (
      item.catalogType === "public" &&
      (item.applicationName === "data_mesh" ||
        item.serviceName === "superset" ||
        item.serviceName === "chorus" ||
        item.serviceName === "tableau" ||
        item.serviceName === "powerbi" ||
        item.serviceName === "sightline" ||
        item.serviceName === "tarvos" ||
        item.serviceName === "nifi" ||
        item.serviceName === "kafka" ||
        item.serviceName === "oracle" ||
        item.serviceName === "msftsql" ||
        item.serviceName === "postgresql" ||
        item.serviceName === "tax_brightline" ||
        item.serviceName === "risk_thinking")
    ) {
      setViewData(item);
      handleNavigateTo(item.serviceName);
      handleBreadCrumbNickName && handleBreadCrumbNickName(item.instanceName);
      localStorage.setItem("serviceData", JSON.stringify(item));
    } else if (
      item.catalogType === "public" &&
      (item.applicationName === "arr_asset_mgmt" ||
        item.applicationName === "arr_performance" ||
        item.applicationName === "arr_hedge_funds")
    ) {
      setViewData(item);
      handleBreadCrumbNickName && handleBreadCrumbNickName(item.instanceName);
      handleNavigateTo("algorithmics-reports");
      localStorage.setItem("serviceData", JSON.stringify(item));
    } else {
      setCurrentView("Home");
    }
  };

  return (
    <>
      <MyServicesContainer>
        <CardFilterHeader
          toggleValue={listTogglerValue}
          searchValue={searchValue}
          handleToggleChange={handleToggleChange}
          handleChangeForSearch={handleChangeForSearch}
        />

        {listTogglerValue === "list" && (
          <MyServicesListView
            filteredItems={filteredItems}
            handleItemSelect={handleItemSelect}
          />
        )}
        {listTogglerValue === "table" && (
          <TableContainer>
            {listTogglerValue === "table" && (
              <MyservicesTableView
                filteredItems={filteredItems}
                handleItemSelect={handleItemSelect}
              />
            )}
          </TableContainer>
        )}
      </MyServicesContainer>
    </>
  );
};
