import * as React from "react";
import Typography from "@mui/material/Typography";
import "./ChorusEverywhere.css";
import ChorusEverywherePricing from "./ChorusEverywherePricing";

const ChorusEverywherePlansAndFeatures: React.FC = () => {
  return (
    <>
      <div className={"gap4rem flex-col"}>
        <div className={"gap1rem flex-col"}>
          <Typography
            variant="h4"
            fontSize="2.125rem"
            fontWeight={600}
            component="div"
            sx={{
              color: "#0A85C7",
              lineHeight: "2.25rem",
              padding: "0.625rem 0",
            }}
          >
            Pricing Plans and Features
          </Typography>
          <div className={"gap2rem flex-row"}>
            <div className={"gap1rem flex-col"}>
              <Typography
                variant="h6"
                fontSize="1.25rem"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                SS&C Chorus Everywhere includes
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "#02273C", lineHeight: "1.5rem" }}
              >
                {" "}
                <ul>
                  <li>AI-Powered Process Orchestration</li>
                  <li>Content Management Capabilities</li>
                  <li>Rules Management Tools</li>
                  <li>Role-Based Workspaces</li>
                  <li>Multi-Experience Communication Module</li>
                  <li>Operational Analytics</li>
                  <li>Case Management</li>
                  <li>Omni-Channel Interactions</li>
                  <li>Automated Decision-Making</li>
                  <li>Integrated Application Support</li>
                </ul>
              </Typography>
            </div>
          </div>
          <ChorusEverywherePricing title={"Plan Options & Details"} />
        </div>
      </div>
    </>
  );
};

export default ChorusEverywherePlansAndFeatures;
