import React from "react";
import { Button } from "@ssce/ui-shared-library";
import { Button as MUIButton } from "@mui/material";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Region from "../Regions";
import RefreshIcon from "@mui/icons-material/Refresh";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import MenuItem from "@mui/material/MenuItem";
import { Project } from "./provisionDataMeshHooks";
import DownloadIcon from "@mui/icons-material/Download";
import ButtonGroup from "@mui/material/ButtonGroup";
import Alert from "@mui/material/Alert";
import CatalogAlgoRiskFunds from "../CatalogAlgoRisk/CatalogAlgoriskFunds";
import CatalogAlgoRiskFundTable from "../CatalogAlgoRisk/CatalogAlgoRiskFundTable";
import RiskthinkingSelectedCompTable from "../Riskthinking/RiskthinkingCompanyTable";
import "./provisionService.css";

export const InstanceNameProvision: React.FC<{
  value: string;
  handleInstanceNameChange: (value: string) => void;
  hasInstanceNameError: string;
}> = ({ handleInstanceNameChange, value, hasInstanceNameError }) => {
  return (
    <div className={"nameInstanceContainer"}>
      <div>
        <div className={"sectionTitle"}>Name Your Instance</div>
        <div className={"sectionSubheader"}>
          Please choose a name to associate with instance.
        </div>
      </div>
      <TextField
        fullWidth
        id="instance-name"
        placeholder="Instance Name *"
        autoComplete="off"
        value={value}
        required
        error={hasInstanceNameError !== ""}
        helperText={
          hasInstanceNameError !== ""
            ? hasInstanceNameError
            : "The name should only include letters, numbers, underscores, and dashes."
        }
        onChange={(e) => handleInstanceNameChange(e.target.value)}
        sx={{
          "& .MuiOutlinedInput-root": { borderRadius: "0.25rem" },
          "& .MuiFormHelperText-root": { marginLeft: 0, marginRight: 0 },
        }}
      />
    </div>
  );
};

export const SelectRegionProvision: React.FC<{
  regions: { label: string; value: string }[];
  selectedRegion: string;
  handleRegionChange: (value: string) => void;
}> = ({ regions, handleRegionChange, selectedRegion }) => {
  const isGlobal = regions.length === 1 && regions[0]?.value === "Global";

  return (
    <div className={"regionContainer"}>
      <div>
        <div className={"sectionTitle"}>
          {isGlobal ? "Region" : "Select Region"}
        </div>
        {!isGlobal && (
          <div className={"sectionSubheader"}>
            Select the region where you'd like this instance to operate.
          </div>
        )}
      </div>
      {!isGlobal && (
        <div>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedRegion}
            fullWidth
            onChange={(e) => handleRegionChange(e.target.value)}
            sx={{ "&": { borderRadius: "0.25rem" } }}
          >
            {regions.map((region) => (
              <MenuItem key={region.value} value={region.value}>
                {region.label}
              </MenuItem>
            ))}
          </Select>
        </div>
      )}
      <div className={"regionDetailContainer"}>
        <Region selectedRegion={isGlobal ? "Global" : selectedRegion} />
        <div>
          <div className={"boldTitle"}>Region Details</div>
          <div className={"sectionSubheader"}>
            {isGlobal
              ? "This region is available to customers globally. It offers high availability and scalability, making it an ideal choice for enterprise customers with large-scale workloads."
              : "North America: This region provides low-latency access to customers located in the United States and Canada. It offers high availability and scalability, making it an ideal choice for enterprise customers with large-scale workloads."}
          </div>
        </div>
        <div>
          <div className={"boldTitle"}>Local Compliance</div>
          <div className={"sectionSubheader"}>
            Fully compliant with regulatory requirements, including:
            <ul>
              <li>GDPR</li>
              <li>ISO 27000</li>
              <li>SOC-2</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export const UserManagementProvision: React.FC<{
  handleProjectChange: (value: string) => void;
  projectId: string;
  projects: Project[];
  hasProjectIdError: boolean;
  hideButtons?: boolean;
}> = ({
  projectId,
  projects,
  handleProjectChange,
  hasProjectIdError,
  hideButtons,
}) => {
  return (
    <div className={"userAccessManagementContainer"}>
      <div>
        <div className={"sectionTitle"}>User Access Management</div>
        <div className={"sectionSubheader"}>
          To grant user access, associate this instance with a relevant project.
          Create a new project using the link below the dropdown or in User
          Access Management under Settings if needed.
        </div>
      </div>
      <div>
        <div className={"projectContainer"}>
          <div>
            <div className={"boldTitle"}>Choose Project</div>
            <div className={"sectionSubheader"}>
              Use the dropdown to the right to select a project
            </div>
          </div>
          <FormControl
            required
            sx={{ m: 1, minWidth: "100%" }}
            error={hasProjectIdError}
          >
            <InputLabel id="publish-api-project">Choose Project</InputLabel>
            <Select
              labelId="publish-api-project"
              value={projectId}
              fullWidth
              label="Choose Project"
              placeholder="Choose Project"
              id={"selectProject"}
              required
              onChange={(e) => {
                handleProjectChange(e.target.value);
              }}
              sx={{ "&": { borderRadius: "0.25rem" } }}
            >
              {projects.map((item) => (
                <MenuItem key={item.projectId} value={item.projectId}>
                  {item.name}{" "}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        {!hideButtons && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="text"
              size="medium"
              className={"refresh"}
              id="refreshButton"
              onClick={() => {}}
            >
              Refresh
              <RefreshIcon />
            </Button>
            <Button
              className={"addProjectButton"}
              size="medium"
              variant="default"
              id="addProjectButton"
              onClick={() => {}}
            >
              Add Project
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export const SetupBillingProvision: React.FC<{
  billing: string;
  plan: string;
  handlePlanChange: (value: string) => void;
  handleBillingChange: (value: string) => void;
}> = ({ billing, plan, handleBillingChange, handlePlanChange }) => {
  return (
    <div className={"setupBillingContainer"}>
      <div>
        <div className={"sectionTitle"}>Setup Billing</div>
        <div className={"sectionSubheader"}>
          Please use the options below to select a plan and configure your
          billing preferences for this instance.
        </div>
      </div>
      <div className={"projectContainer"}>
        <div>
          <div className={"boldTitle"}>Plan</div>
          <div className={"sectionSubheader"}>
            Please select your desired plan and view details below.
          </div>
        </div>
        <Select
          labelId="demo-simple-select-label"
          value={plan}
          fullWidth
          id={"selectPlan"}
          onChange={(e) => handlePlanChange(e.target.value)}
          sx={{ "&": { borderRadius: "0.25rem" } }}
        >
          <MenuItem value={"Small"}>Small</MenuItem>
          <MenuItem value={"Medium"}>Medium</MenuItem>
          <MenuItem value={"Large"}>Large</MenuItem>
        </Select>
      </div>
      <div className={"projectContainer"}>
        <div>
          <div className={"boldTitle"}>Billing Frequency</div>
          <div className={"sectionSubheader"}>
            Please select your desired billing frequency.
          </div>
        </div>
        <Select
          labelId="demo-simple-select-label"
          value={billing}
          id={"selectPeriod"}
          fullWidth
          onChange={(e) => handleBillingChange(e.target.value)}
          sx={{ "&": { borderRadius: "0.25rem" } }}
        >
          <MenuItem value={"Monthly"}>Monthly </MenuItem>
          <MenuItem value={"Quarterly"}>Quarterly </MenuItem>
          <MenuItem value={"Yearly"}>Yearly </MenuItem>
        </Select>
      </div>
    </div>
  );
};

export const SolutionConfigurationProvision: React.FC<{
  addFundList: any;
  tableFundList: any;
  updateSelectedFund: (selectedFund: any[]) => void;
  updateRows: (rows: any) => void;
  isGlobal: boolean;
}> = ({
  addFundList,
  tableFundList,
  updateSelectedFund,
  updateRows,
  isGlobal,
}) => {
  const shouldHidePlanSection = isGlobal;
  return (
    <div
      style={{
        display: "flex",
        gap: "4rem",
        flexDirection: "column",
        padding: "1rem 1.5rem",
        borderRadius: "0.25rem",
        border: "0.063rem solid #B6C7D1",
      }}
    >
      <div
        style={{
          display: "flex",
          gap: "5rem",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "0.5rem",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <div className={"sectionTitle"}>
            {isGlobal ? "Add Companies" : "Solution Configuration"}
          </div>
          <div className={"sectionSubheader"}>
            {isGlobal
              ? "Use the table below to add companies to this plan. You can continue adding or removing companies as needed."
              : "Start building a solution by configuring the options below."}
          </div>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "2rem",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            width: "100%",
          }}
        >
          {!shouldHidePlanSection && (
            <div>
              <div
                style={{
                  display: "flex",
                  gap: "5rem",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    gap: "0.5rem",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <div className={"boldTitle"}>Choose a Plan</div>
                  <div className={"sectionSubheader"}>
                    Please select your desired plan and view details below.
                  </div>
                </div>
                <div style={{ width: "100%" }}>
                  <ButtonGroup
                    disableElevation
                    variant="contained"
                    aria-label="Disabled elevation buttons"
                  >
                    <MUIButton className="algorisk-standard-button">
                      Standard
                    </MUIButton>
                    <MUIButton className="algorisk-premium-button">
                      Premium
                    </MUIButton>
                  </ButtonGroup>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "55%",
                }}
              >
                <div>
                  <div>
                    <Typography
                      variant="body1"
                      fontSize="1rem"
                      fontWeight={700}
                      align="left"
                      lineHeight={"1.5rem"}
                      color="#0A85C7"
                    >
                      Premium:
                    </Typography>
                    <div style={{ display: "flex", marginBottom: "1.25rem" }}>
                      <CheckCircleIcon
                        color="primary"
                        style={{ marginRight: "0.938rem" }}
                      />
                      <Typography
                        variant="body1"
                        fontSize="1rem"
                        fontWeight={300}
                        align="left"
                        lineHeight={"1.5rem"}
                        color="#355263"
                      >
                        CSV Risk Report
                      </Typography>
                      <InfoIcon
                        color="primary"
                        style={{ marginLeft: "0.938rem" }}
                      />
                    </div>
                    <div style={{ display: "flex" }}>
                      <CheckCircleIcon
                        color="primary"
                        style={{ marginRight: "0.938rem" }}
                      />
                      <Typography
                        variant="body1"
                        fontSize="1rem"
                        fontWeight={300}
                        align="left"
                        lineHeight={"1.5rem"}
                        color="#355263"
                      >
                        Risk Dashboard
                      </Typography>
                      <InfoIcon
                        color="primary"
                        style={{ marginLeft: "0.938rem" }}
                      />
                    </div>
                  </div>
                  <Button
                    id={"viewAllPlanDetails"}
                    onClick={() => {}}
                    variant="text"
                  >
                    View ALL Plan Details
                  </Button>
                </div>
              </div>
              <Alert severity="info" style={{ fontSize: "1.125rem" }}>
                The chosen plan impacts prices for your selected funds. Toggle
                between plan selections to observe price effects below. Add
                funds to view pricing impact.
              </Alert>
            </div>
          )}
          {!isGlobal ? (
            <div
              style={{
                display: "flex",
                gap: "5rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "0.5rem",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div className={"boldTitle"}>Add funds to this Plan</div>
                <div className={"sectionSubheader"}>
                  {isGlobal
                    ? ""
                    : "Use the dropdown to the right to select your desired fund to add to this solution."}
                </div>
              </div>
              {!isGlobal ? (
                <div style={{ width: "100%" }}>
                  <CatalogAlgoRiskFunds
                    data={addFundList}
                    updateSelectedFund={updateSelectedFund}
                    isGlobal={isGlobal}
                  />
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          gap: "0.5rem",
          flexDirection: "column",
          width: "100%",
          borderBottom: "0.063rem solid #CBD9E1",
          paddingBottom: "3.75rem",
        }}
      >
        <div className={"boldTitle"}>{isGlobal ? "" : "Fund List"} </div>
        <div className={"sectionSubheader"}>
          {isGlobal
            ? ""
            : "Below, find your current selected fund and their details. If you wish to make changes, you can remove one or more fund"}
        </div>
        {!isGlobal ? (
          <CatalogAlgoRiskFundTable
            rows={tableFundList}
            updateRows={updateRows}
            isGlobal={isGlobal}
          />
        ) : (
          <RiskthinkingSelectedCompTable
            rows={tableFundList}
            updateRows={updateRows}
          />
        )}
      </div>
      {!shouldHidePlanSection && (
        <div style={{ display: "flex", gap: "5rem" }}>
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div className={"boldTitle"}>Adjusted Pricing Calculation</div>
            <div className={"sectionSubheader"}>
              This provides an overview of your costs, factoring in selected
              funds, along with adjusted costs reflecting current discounts or
              promotions.
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div style={{ marginTop: "1.875rem", display: "flex" }}>
              <Typography
                variant="body1"
                fontSize="1rem"
                fontFamily={"Roboto Light"}
                fontWeight={300}
                component="div"
                align="left"
                lineHeight={"1.5rem"}
                color="#02273C"
              >
                Annual Total
              </Typography>

              <div
                style={{
                  borderBottom: "0.125rem solid",
                  width: "50%",
                  marginLeft: "7.188rem",
                }}
              >
                <Typography
                  variant="body1"
                  fontSize="1.313rem"
                  fontFamily={"Roboto Light"}
                  fontWeight={300}
                  component="div"
                  align="left"
                  lineHeight={"1.5rem"}
                  color="#02273C"
                ></Typography>
              </div>
            </div>
            <div
              style={{
                marginTop: "3.75rem",
                display: "flex",
                borderBottom: "0.063rem solid #CBD9E1",
                paddingBottom: "2.5rem",
              }}
            >
              <Typography
                variant="body1"
                fontSize="1rem"
                fontFamily={"Roboto Light"}
                fontWeight={300}
                component="div"
                align="left"
                lineHeight={"1.5rem"}
                color="#02273C"
              >
                Discount
              </Typography>

              <div
                style={{
                  borderBottom: "0.125rem solid",
                  width: "50%",
                  marginLeft: "9.375rem",
                }}
              >
                <Typography
                  variant="body1"
                  fontSize="1.313rem"
                  fontFamily={"Roboto Light"}
                  fontWeight={300}
                  component="div"
                  align="left"
                  lineHeight={"1.5rem"}
                  color="#02273C"
                ></Typography>
              </div>
            </div>
            <div style={{ marginTop: "6.25rem", display: "flex" }}>
              <Typography
                variant="body1"
                fontSize="1rem"
                fontFamily={"Roboto Light"}
                fontWeight={600}
                component="div"
                align="left"
                lineHeight={"1.5rem"}
                color="#02273C"
                sx={{ marginRight: "4.375rem" }}
              >
                New Annual Total
              </Typography>

              <div style={{ borderBottom: "0.125rem solid", width: "50%" }}>
                <Typography
                  variant="body1"
                  fontSize="1.313rem"
                  fontFamily={"Roboto Light"}
                  fontWeight={600}
                  component="div"
                  align="left"
                  lineHeight={"1.5rem"}
                  color="#02273C"
                ></Typography>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export const ProvisionInstanceButton: React.FC<{
  handleProvisionInstance: (event: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ handleProvisionInstance }) => {
  return (
    <div className={"provisionButtonContainer"}>
      <Button
        variant="default"
        className={"provisionButton"}
        onClick={handleProvisionInstance}
        id="provisionInstance"
      >
        Provision Instance
        <CheckCircleIcon />
      </Button>
    </div>
  );
};

export const ReviewBillingProvision: React.FC<{
  isRiskthinking?: boolean;
}> = ({ isRiskthinking }) => {
  const shouldchangeDesc = isRiskthinking;
  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "4rem",
          flexDirection: "column",
          padding: "1rem 1.5rem",
          borderRadius: "0.25rem",
          border: "0.063rem solid #B6C7D1",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "0.5rem",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <div className={"sectionTitle"}>Review Billing</div>
            <div className={"sectionSubheader"}>
              {shouldchangeDesc
                ? "Review your billing details below."
                : "Please use the options below to select a plan and configure your billing preferences for this instance."}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", gap: "5rem" }}>
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className={"boldTitle"}>Monthly Bill</div>
              <div className={"sectionSubheader"}>
                These numbers reflect both your official total annual and your
                total monthly costs.
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <div style={{ marginTop: "1.875rem", display: "flex" }}>
                <Typography
                  variant="body1"
                  fontSize="1rem"
                  fontFamily={"Roboto Light"}
                  fontWeight={300}
                  component="div"
                  align="left"
                  lineHeight={"1.5rem"}
                  color="#02273C"
                >
                  {shouldchangeDesc ? "Annual Total" : "Adjusted Annual Total"}
                </Typography>
                &nbsp;&nbsp;
                <div
                  style={{
                    borderBottom: "0.125rem solid",
                    width: "50%",
                    marginLeft: shouldchangeDesc ? "6.5rem" : "1.25rem",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize="1.313rem"
                    fontFamily={"Roboto Light"}
                    fontWeight={300}
                    component="div"
                    align="left"
                    lineHeight={"1.5rem"}
                    color="#02273C"
                  ></Typography>
                </div>
              </div>
              <div style={{ marginTop: "6.25rem", display: "flex" }}>
                <Typography
                  variant="body1"
                  fontSize="1"
                  fontFamily={"Roboto Light"}
                  fontWeight={600}
                  component="div"
                  align="left"
                  lineHeight={"1.5rem"}
                  color="#02273C"
                  sx={{ marginRight: "5.313rem" }}
                >
                  Monthly Total
                </Typography>

                <div
                  style={{
                    borderBottom: "0.125rem solid",
                    width: "50%",
                    marginLeft: "1.25rem",
                  }}
                >
                  <Typography
                    variant="body1"
                    fontSize="1.313rem"
                    fontFamily={"Roboto Light"}
                    fontWeight={600}
                    component="div"
                    align="left"
                    lineHeight={"1.5rem"}
                    color="#02273C"
                  ></Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1.5rem",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", gap: "5rem" }}>
            <div
              style={{
                display: "flex",
                gap: "0.5rem",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div className={"boldTitle"}>View Pricing Breakdown</div>
              <div className={"sectionSubheader"}>
                This detailed cost breakdown is derived from your plan
                selection, accumulated fund fees, and adjustments. Feel free to
                download it for your records.
              </div>
            </div>
            <div style={{ width: "100%" }}>
              <Button
                variant="secondary"
                id="downloadCostSheet"
                onClick={() => {}}
              >
                Download Cost Sheet <DownloadIcon />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
