import "./DualButtons.css";
import { Button } from "@ssce/ui-shared-library";

/**
 * Builds a Grid with two horizontal buttons
 * @param {object} props
 * @param {string} props.leftClassName
 * @param {function} props.leftOnClick
 * @param {string} props.leftOnClickVal
 * @param {string} props.leftBtnTxt
 * @param {boolean} props.leftDisabled
 * @param {string} props.rightClassName
 * @param {function} props.rightOnClick
 * @param {string} props.rightOnClickVal
 * @param {string} props.rightBtnTxt
 * @param {boolean} props.rightDisabled
 * @returns {JSX.Element}
 * @constructor
 */

// TODO - add sm md xl
export const DualButtons = (props) => {
  return (
    <div className="buttons-container" style={props.style}>
      {props.leftBtnTxt && (
        <Button
          variant="secondary"
          onClick={() => {
            if (props.leftOnClick) {
              props.leftOnClick(
                props.leftOnClickVal ? props.leftOnClickVal : null,
              );
            }
          }}
          isDisabled={props.leftDisabled}
          id="leftButton"
        >
          {props.leftBtnTxt}
        </Button>
      )}

      {props.rightBtnTxt && (
        <Button
          variant="default"
          onClick={() => {
            if (props.rightOnClick) {
              props.rightOnClick(
                props.rightOnClickVal ? props.rightOnClickVal : null,
              );
            }
          }}
          isDisabled={props.rightDisabled}
          id="rightButton"
        >
          {props.rightBtnTxt}
        </Button>
      )}
    </div>
  );
};
