import { Button } from "@ssce/ui-shared-library";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { NumberOfFilters } from "../../Documentation/Styles/Documentation.styles";

const FilterDropdown = () => {
  return (
    <Button variant="default" id={"FILTERS"} onClick={() => {}}>
      <NumberOfFilters>0</NumberOfFilters>
      Filters
      <ExpandMoreRoundedIcon />
    </Button>
  );
};

export default FilterDropdown;
