import { Button } from "@ssce/ui-shared-library";
import {
  Container,
  ActionButtonsContainer,
  StatusLabelContainer,
  StatusLabel,
  StatusIcon,
} from "./WorkflowViewerContent/workflowViewerContent.styles";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import SettingsIcon from "@mui/icons-material/Settings";

export const MidSection = () => {
  return (
    <Container>
      <StatusLabelContainer>
        <StatusIcon type={"small"} status={"success"}></StatusIcon>{" "}
        <StatusLabel>Success </StatusLabel>
        <StatusIcon type={"small"} status={"failed"}></StatusIcon>{" "}
        <StatusLabel>Failed</StatusLabel>
        <StatusIcon type={"small"} status={"running"}></StatusIcon>{" "}
        <StatusLabel>Running</StatusLabel>
        <StatusIcon type={"small"} status={"pending"}></StatusIcon>{" "}
        <StatusLabel>Pending</StatusLabel>
        <StatusIcon type={"small"} status={"blocked"}></StatusIcon>{" "}
        <StatusLabel>Blocked</StatusLabel>
      </StatusLabelContainer>
      <ActionButtonsContainer>
        <Button
          width={"9.875rem"}
          variant={"secondary"}
          onClick={() => {}}
          id="supportButton"
        >
          <div style={{ paddingBottom: "0.188rem" }}>
            <ContactSupportIcon /> Support
          </div>
        </Button>
        <Button
          width={"9.875rem"}
          variant={"default"}
          onClick={() => {}}
          id="scheduleButton"
        >
          <div style={{ paddingBottom: "0.188rem" }}>
            <AccessTimeIcon /> Schedule
          </div>
        </Button>
        <Button
          width={"9.875rem"}
          variant={"default"}
          onClick={() => {}}
          id="manageButton"
        >
          <div style={{ paddingBottom: "0.188rem" }}>
            <SettingsIcon /> Manage
          </div>
        </Button>
      </ActionButtonsContainer>
    </Container>
  );
};
