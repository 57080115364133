import { AppThunk } from "../store";
import axios from "axios";
import { enqueueSnackbar } from "../snackbars/actions";
import { NotificationLevel } from "../snackbars/notification-level";
import { baseUrl } from "../../constants";



export enum MyservicesActionType {
  SET_MYSERVICES_DATA = "SET_MYSERVICES_DATA",
  SET_IS_LOADING = "SET_IS_LOADING",
  SET_IS_DELETE_LOADING = "SET_IS_DELETE_LOADING",
  SET_HAS_ERROR = "SET_HAS_ERROR",
}

export const fetchMyservicesList = (): AppThunk => (dispatch) => {
  dispatch(setLoading(true));
  axios
    .get(`${baseUrl}/api/mktplc-catalog-api/v0/private/services/all/my-services`)
    .then((response) =>{
      dispatch(setLoading(false));
      dispatch(setMyservices(response.data),
      )
})
    .catch(() =>{
      dispatch(setLoading(false));
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to retrieve My Service List`,
        ),
      )
});
};


export const deleteInstance = (serviceName:string,id:string, cb:any): AppThunk => (dispatch) => {
  dispatch(setDeleteLoading(true));
  axios
    .delete(`${baseUrl}/api/mktplc-catalog-api/v0/public/services/deprovision/${serviceName}/instances/${id}`)
    .then(() =>{
      dispatch(setDeleteLoading(false));
      cb()
      dispatch(setMyservices([]))
      dispatch(setLoading(true));
      dispatch(
          enqueueSnackbar(
            NotificationLevel.SUCCESS,
            "Instance successfully Deleted.",
          ),
        );
})
    .catch(() =>{
      dispatch(setDeleteLoading(false));
      dispatch(
        enqueueSnackbar(
          NotificationLevel.ERROR,
          `Warning: Unable to Delte Instance.`,
        ),
      )
});
};

export const setDeleteLoading = (setLoading: boolean) =>
  ({
    type: MyservicesActionType.SET_IS_DELETE_LOADING,
    payload: setLoading,
  }) as const;

export const setLoading = (setLoading: boolean) =>
  ({
    type: MyservicesActionType.SET_IS_LOADING,
    payload: setLoading,
  }) as const;

  export const setMyservices = (data: []) =>
  ({
    type: MyservicesActionType.SET_MYSERVICES_DATA,
    payload: data,
  }) as const;

export type MyservicesAction =
  | ReturnType<typeof setLoading>
  | ReturnType<typeof setDeleteLoading>
  | ReturnType<typeof setMyservices>;
