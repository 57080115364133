import { styled } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import InputBase from "@mui/material/InputBase";
import Popper from "@mui/material/Popper";

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  borderBottom: `0.063rem solid ${theme.palette.mode === "light" ? "#eaecef" : "#30363d"}`,
  "& input": {
    borderRadius: 4,
    backgroundColor: theme.palette.mode === "light" ? "#fff" : "#0d1117",
    padding: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `0.063rem solid ${theme.palette.mode === "light" ? "#eaecef" : "#30363d"}`,
    fontSize: 14,
    "&:focus": {
      boxShadow: `0 0 0 0.188rem ${
        theme.palette.mode === "light"
          ? "rgba(3, 102, 214, 0.3)"
          : "rgb(12, 45, 107)"
      }`,
      borderColor: theme.palette.mode === "light" ? "#0366d6" : "#388bfd",
    },
  },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  fontSize: 12,
  width: "100%",
  textAlign: "left",
  margin: "0.188rem 0.188rem 0.063rem 0.188rem",
  color: theme.palette.mode === "light" ? "#586069" : "#8b949e",
  "& span": {
    width: "100%",
  },
  "& svg": {
    width: 16,
    height: 16,
  },
}));

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `0.063rem solid ${theme.palette.mode === "light" ? "#e1e4e8" : "#30363d"}`,
  boxShadow: `0 0.5rem 1.5rem ${
    theme.palette.mode === "light" ? "rgba(149, 157, 165, 0.2)" : "rgb(1, 4, 9)"
  }`,
  borderRadius: 6,
  width: 300,
  zIndex: 10,
  fontSize: 13,
  color: theme.palette.mode === "light" ? "#24292e" : "#c9d1d9",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#1c2128",
}));

export { StyledInput, Button, StyledPopper };
